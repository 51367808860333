import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';

import _ from 'lodash';

import ResponsivePieChart from '@components/Charts/PieChart';
import useTranslation from '@hooks/useTranslation';

import { useTopCategory } from '../../util/useTopCategory';
import { useSettings } from '../../util/useSettings';
import Card from '../../Components/WidgetCard';

const initData = [
    { key: 'ASSET', name: 'Asset', value: 50 },
    { key: 'PEOPLE', name: 'People', value: 20 },
    { key: 'SENSOR', name: 'Sensor', value: 30 },
];

const CategoryChart = ({ children, widgetInfo, ...restProps }) => {
    const t = useTranslation('CategoryChart');
    const { config } = widgetInfo;
    const settings = useSettings(config);
    const { categoryStatus } = useSelector(state => state.TagInfo);
    const { mode } = useSelector(state => state.DashboardFrame);
    const categoryList = useTopCategory();
    const [status, setStatus] = useState(initData);

    useEffect(() => {
        if (categoryList) {
            setStatus(
                categoryList.map(v => {
                    return {
                        key: v.value,
                        name: v.label,
                        value: _.random(1, 10),
                    };
                }),
            );
        }
    }, [categoryList]);

    useEffect(() => {
        let tempStatus = [];
        if (categoryList.length) {
            if (settings.categoryType && settings.categoryType.length > 0) {
                const filterType = settings.categoryType;
                tempStatus = filterType.reduce((acc, curr) => {
                    const categoryName = categoryList.find(v => {
                        return v.value === curr;
                    });
                    acc.push({
                        key: curr,
                        name: categoryName && categoryName.label,
                        value: categoryStatus[curr] && categoryStatus[curr].length,
                    });
                    return acc;
                }, []);
            } else {
                for (let category of categoryList) {
                    const categoryInfo = categoryStatus[category.value];

                    tempStatus.push({
                        key: category.value,
                        name: category.label,
                        value: categoryInfo ? categoryInfo.length : 0,
                    });
                }
            }
            setStatus(tempStatus);
        }
    }, [categoryStatus, settings, categoryList]);

    const data = useMemo(() => {
        return mode === 'edit' ? initData : status;
    }, [mode, status]);

    return (
        <Card
            widgetInfo={widgetInfo}
            subTitle={
                settings.categoryType && settings.categoryType.length < categoryList.length
                    ? settings.categoryType[0] + t('and') + ' ' + (settings.categoryType.length - 1) + ' ' + t('more')
                    : t('All Category Type')
            }
            {...restProps}
        >
            <div className={'scrollbar-container'}>
                <PerfectScrollbar>
                    <ResponsivePieChart data={data} settings={settings} />
                </PerfectScrollbar>
            </div>
            {children}
        </Card>
    );
};

export default CategoryChart;
