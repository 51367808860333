import React from 'react';

import { useTranslation } from '@hooks';

import styled from 'styled-components';

const ApprovalTable = ({ section = 2 }) => {
    const t = useTranslation('Patrol Log');

    const size = section < 2 ? 2 : section;
    const sectionArray = Array.from(Array(size), () => 0);

    return (
        <table>
            <thead>
                <tr>
                    <StyledHeader rowSpan="2">{t('Approval')}</StyledHeader>
                    {sectionArray.map((_, idx) => {
                        return <StyledHeader key={`header_${idx}`}></StyledHeader>;
                    })}
                </tr>
                <tr>
                    {sectionArray.map((_, idx) => {
                        return <StyledSecondTd key={`secondTd_${idx}`}></StyledSecondTd>;
                    })}
                </tr>
                {/*<tr>*/}
                {/*    {sectionArray.map((_, idx) => {*/}
                {/*        return <StyledTd key={`td_${idx}`}>/</StyledTd>;*/}
                {/*    })}*/}
                {/*</tr>*/}
            </thead>
        </table>
    );
};

const StyledTd = styled.td`
    border-bottom: 1px solid black;
    border-right: 1px solid black;
    width: 4rem;
    height: 1.4rem;
    text-align: center;
    background-color: white;
`;

const StyledSecondTd = styled(StyledTd)`
    height: 2.8rem;
`;

const StyledHeader = styled(StyledTd)`
    background-color: lightgrey;
    width: 1rem;
    padding: 0.4rem;

    color: black;
    font-size: 1rem;
    border-top: 1px solid black;
    :first-child {
        border-left: 1px solid black;
    }
`;

export default ApprovalTable;
