import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { getSelectedAssetInfoApi } from 'api/asset';
import { getCategoryPropertiesListApi } from 'api/common';
import { getStatusListInfoApi } from 'api/status';
import { getStatisticsLogApi } from 'api/dashboard';

import { useAsync, useTranslation } from 'util/hooks';
import { returnCategoryProperty } from 'util/common/util';

import { Tab, Button, Page } from 'Components/Common';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';

import LocationTab from '../../Assets/AssetDetail/Components/LocationTab';
import AssetDetailInformation from '../../Assets/AssetDetail/Components/AssetDetailInformation';
import StatusJournalTab from '../../Assets/AssetDetail/Components/StatusJournalTab';
import InspectionLogTab from '../../Assets/AssetDetail/Components/InspectionLogTab';

import styled from 'styled-components';

const AssetInspectionScheduledDetail = () => {
    const { assetNum } = useParams();
    const history = useHistory();

    const t = useTranslation('Asset Inspection Detail');

    const [open, setOpen] = useState(false);
    const [assetInfo, setAssetInfo] = useState({});
    const [assetCategoryPropertyInfo, setAssetCategoryPropertyInfo] = useState([]);
    const [statusLogInfo, setStatusLogInfo] = useState([]);
    const [statusColorInfo, setStatusColorInfo] = useState({});
    const [assetInspectionPage, setAssetInspectionPage] = useState(false);
    const [openedTab, setOpenedTab] = useState('inspectionLog');

    useAsync({
        promise: getStatusListInfoApi,
        fixedParam: { isAll: 'Y' },
        immediate: true,
        resolve: ({ rows }) => {
            const statusToColor = {};
            if (rows) {
                rows.forEach(row => {
                    statusToColor[row.statusNum] = row.displayColor;
                });
            }
            setStatusColorInfo(statusToColor);
        },
    });

    const { promise: getAssetDetail } = useAsync({
        promise: getSelectedAssetInfoApi,
        fixedParam: { targetKey: assetNum },
        immediate: true,
        deps: [assetNum],
        resolve: res => {
            setAssetInfo(res);
        },
    });

    const { promise: getCategoryPropertyInfo } = useAsync({
        promise: getCategoryPropertiesListApi,
        fixedParam: { isAll: 'Y' },
        resolve: res => {
            const filteredCategoryPropertyList = res.rows.filter(prop => prop.isListProperty === 'Y');

            if (assetInfo && assetInfo.properties && !!filteredCategoryPropertyList.length) {
                const result_map = Object.entries(assetInfo.properties).map(([key, value]) => ({ id: key, value }));
                const tempList = returnCategoryProperty(result_map, filteredCategoryPropertyList);
                setAssetCategoryPropertyInfo(tempList);
            }
        },
    });

    const { promise: getAssetStatusLog } = useAsync({
        promise: getStatisticsLogApi,
        fixedParam: { isAll: 'Y' },
        resolve: res => {
            setStatusLogInfo(res.rows || []);
        },
    });

    useEffect(() => {
        if (!assetInfo) {
            return;
        }

        const { categoryCode, targetNum } = assetInfo;

        if (categoryCode) {
            getCategoryPropertyInfo({ categoryCode });
        }

        if (targetNum) {
            getAssetStatusLog({ targetNum });
        }
    }, [assetInfo]);

    const click = () => {
        setOpen(!open);
    };

    const handleOpenAddAssetInspectionPage = open => {
        if (!open) {
            setAssetInspectionPage(false);
        }
    };

    return (
        <Page menuPath={[t('Asset Inspection Management', 'Menu'), t('Asset Inspection Detail', 'Menu')]}>
            <div className="card grid-layout-container-for-asset-inspection-detail">
                <div className="card-header">
                    <div className="card-header__title">
                        <div className="title-wrap">
                            <h3 className="title__main">{t('Asset scheduled for inspection')}</h3>
                        </div>
                    </div>
                    <div className="card-header__function">
                        <Button className="pnt-btn btn-gray" onClick={() => history.goBack()}>
                            {t('Back')}
                        </Button>
                        <Dropdown isOpen={open} toggle={click}>
                            <DropdownToggle className="pnt-btn btn-darkgray d-flex align-items-center gap-2">
                                {t('Action')}
                                <span className="icon-small icon-down" />
                            </DropdownToggle>
                            <DropdownMenu right className="dropdown-menu-sm">
                                <DropdownItem
                                    style={{ whiteSpace: 'normal' }}
                                    onClick={() => {
                                        setOpenedTab('inspectionLog');
                                        setAssetInspectionPage(true);
                                    }}
                                >
                                    {t('Add asset inspection')}
                                </DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    </div>
                </div>
                <div className="card-body d-flex w-100 gap-2 d-column-under-900" style={{ height: '100%' }}>
                    <div className="asset-information-width margin-bottom-under-770">
                        <AssetDetailInformation
                            assetInfo={assetInfo}
                            assetCategoryPropertyInfo={assetCategoryPropertyInfo}
                        />
                    </div>
                    <TabContainer className="asset-tab-width">
                        <Tab
                            handleTabChange={tabId => {
                                setOpenedTab(tabId);
                                if (tabId !== 'inspectionLog') {
                                    setAssetInspectionPage(false);
                                }
                            }}
                            activeTabId={openedTab}
                            styles={{ header: { justifyContent: 'center' }, headerItem: { width: '50%' } }}
                            tabs={[
                                {
                                    tabId: 'location',
                                    title: t('Location'),
                                    content: <LocationTab assetInfo={assetInfo} />,
                                },
                                {
                                    tabId: 'statusJournal',
                                    title: t('Status journal'),
                                    content: (
                                        <StatusJournalTab
                                            statusColorInfo={statusColorInfo}
                                            statusLogInfo={statusLogInfo}
                                        />
                                    ),
                                },
                                {
                                    tabId: 'inspectionLog',
                                    title: t('Inspection Log'),
                                    content: (
                                        <InspectionLogTab
                                            assetInfo={assetInfo}
                                            assetNum={assetNum}
                                            getNewAssetInfo={getAssetDetail}
                                            goToAddAssetInspectionPage={assetInspectionPage}
                                            handleOpenAddAssetInspectionPage={handleOpenAddAssetInspectionPage}
                                        />
                                    ),
                                },
                            ]}
                        />
                    </TabContainer>
                </div>
            </div>
        </Page>
    );
};

const TabContainer = styled.div`
    .card-body {
        height: 100%;
        @media (max-width: 1150px) {
            height: unset;
        }
    }
`;

export default AssetInspectionScheduledDetail;
