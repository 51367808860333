import moment from 'moment';

export const division = ({ ...restProps } = {}) => {
    return {
        Header: 'Division',
        headerClassName: 'text-ellipsis',
        accessor: 'approveResult',
        className: 'text-ellipsis',
        width: 80,
        ...restProps,
    };
};

export const eventDate = ({ ...restProps } = {}) => {
    return {
        Header: 'Event Date',
        headerClassName: 'text-ellipsis',
        accessor: 'approveDate',
        className: 'text-ellipsis',
        Cell: ({ value }) => {
            return value ? moment.unix(value).format('YYYY/MM/DD HH:mm:ss') : '-';
        },
        formatTitle: ({ value }) => {
            return value ? moment.unix(value).format('YYYY/MM/DD HH:mm:ss') : '-';
        },
        ...restProps,
    };
};

export const approver = ({ ...restProps } = {}) => {
    return {
        Header: 'Approver',
        headerClassName: 'text-ellipsis',
        accessor: 'approverName',
        className: 'text-ellipsis',
        ...restProps,
    };
};

export const authority = ({ ...restProps } = {}) => {
    return {
        Header: 'Approval Authority',
        headerClassName: 'text-ellipsis',
        accessor: 'authorityType',
        className: 'text-ellipsis',
        ...restProps,
    };
};

export const registrationDate = ({ ...restProps } = {}) => {
    return {
        Header: 'Registration Date',
        headerClassName: 'text-ellipsis',
        accessor: 'authorityDate',
        className: 'text-ellipsis',
        Cell: ({ value }) => {
            return value ? moment.unix(value).format('YYYY/MM/DD HH:mm:ss') : '-';
        },
        formatTitle: ({ value }) => {
            return value ? moment.unix(value).format('YYYY/MM/DD HH:mm:ss') : '-';
        },
        ...restProps,
    };
};
