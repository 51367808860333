import React from 'react';
import { ResponsiveContainer, Pie, PieChart, Cell, Label } from 'recharts';

const CustomPieChart = ({
    width,
    height,
    fontColor,
    graphColor,
    totalNum,
    usedNum,
    fontSize,
    innerRadius,
    outerRadius,
}) => {
    const data = [
        { name: 'value', value: usedNum },
        { name: 'remainValue', value: totalNum - usedNum },
    ];

    return (
        <ResponsiveContainer width={'100%'}>
            <PieChart>
                <Pie
                    data={data}
                    dataKey="value"
                    cx="50%"
                    cy="50%"
                    innerRadius={innerRadius}
                    outerRadius={outerRadius}
                    fill="#e3e3e3"
                    startAngle={90}
                    endAngle={480}
                    cornerRadius={100}
                >
                    <Cell fill={graphColor} />
                    <Label
                        value={`${Math.ceil((usedNum / totalNum) * 100)}%`}
                        position="center"
                        style={{
                            fill: fontColor,
                            fontSize: fontSize,
                            fontWeight: 'bold',
                        }}
                    />
                </Pie>
            </PieChart>
        </ResponsiveContainer>
    );
};

export default CustomPieChart;
