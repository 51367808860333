import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';

import EditView from './EditView';
import ShowView from './ShowView';
import { FilterDetailStateContext } from '../index';

const DetailView = () => {
    const { filterNum } = useParams();
    const { editMode } = useContext(FilterDetailStateContext);
    return !filterNum || editMode ? <EditView /> : <ShowView />;
};

export default DetailView;
