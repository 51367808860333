import React from 'react';
import styled from 'styled-components';
import { useTranslation } from '@hooks';

const TakeOverTable = ({ cnt = 3 }) => {
    const t = useTranslation('Patrol Log');
    return (
        <table>
            <thead>
                <tr>
                    <StyledHeader>{t('Handover Of Duty')}</StyledHeader>
                    <StyledHeader>{t('RFID Operation Status')}</StyledHeader>
                    <StyledHeader>{t('Handover And Suggestions')}</StyledHeader>
                    <StyledHeader>{t('Supplier')}</StyledHeader>
                    <StyledHeader>{t('Receiver')}</StyledHeader>
                </tr>
            </thead>
            <tbody>
                {Array(cnt)
                    .fill(0)
                    .map((_, idx) => {
                        return (
                            <tr key={`takeOver_${idx}`}>
                                <StyledTd>{`${idx + 1}${t('Shift')}`}</StyledTd>
                                <StyledTd />
                                <TextAlignStart />
                                <TextAlignStart>
                                    <span className="color-black">{`${t('Rank')}/${t('Name')}: `}</span>
                                    <span className="color-black">{`(${t('Signature')})`}</span>
                                </TextAlignStart>
                                <TextAlignStart>
                                    <span className="color-black">{`${t('Rank')}/${t('Name')}: `}</span>
                                    <span className="color-black">{`(${t('Signature')})`}</span>
                                </TextAlignStart>
                            </tr>
                        );
                    })}
            </tbody>
        </table>
    );
};

const StyledTd = styled.td`
    border-bottom: 1px solid black;
    border-right: 1px solid black;
    text-align: center;
    font-size: 0.6rem;
    background-color: white;
    word-break: break-all;
    white-space: pre-wrap;
    position: relative;
    min-width: 6rem;
    padding: 0.5rem 0;
    color: black;

    :first-child {
        border-left: 1px solid black;
    }

    :nth-child(4),
    :last-child {
        > span:nth-child(2) {
            position: absolute;
            right: 0.4rem;
        }
    }
`;

const TextAlignStart = styled(StyledTd)`
    text-align: start;
    padding: 2px;
`;

const StyledHeader = styled(StyledTd)`
    padding: 2px;
    color: black;

    :nth-child(3) {
        width: 40%;
    }
    :nth-child(4),
    :last-child {
        width: 25%;
    }
`;

export default TakeOverTable;
