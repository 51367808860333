import React, { useEffect, useMemo, useState } from 'react';

import { ModalBody, ModalFooter } from 'reactstrap';
import { Label, Modal, Select, TimePicker, Button } from '@components';
import { useTranslation } from '@hooks';

import moment from 'moment';
import { ONE_THOUSAND, TWENTY_FOUR_HOURS, TWENTY_FOUR_HOURS_TO_MINUTES } from '../../utils';

const approvalsOptions = [
    { value: 2, label: 2 },
    { value: 3, label: 3 },
    { value: 4, label: 4 },
];

const patrolIntervalOptions = [
    { value: 1, label: 1 },
    { value: 2, label: 2 },
];

const PatrolReportModal = ({ initModal, toggleModal, handlePatrolReportForm, request, data }) => {
    const t = useTranslation('Patrol Log');

    const { startDate, endDate } = request || {};
    const maxEndDate = moment(endDate * ONE_THOUSAND).get('minutes')
        ? moment(endDate * ONE_THOUSAND)
              .add(1, 'hours')
              .valueOf()
        : endDate * ONE_THOUSAND;

    const patrolOptions = useMemo(() => {
        if (!data.rows.length) return [];
        return data.rows.reduce((acc, cur) => {
            const findId = acc.find(({ value }) => value === cur.targetId);
            if (!findId && !!cur.targetName) {
                acc.push({ value: cur.targetId, label: cur.targetName });
            }
            return acc;
        }, []);
    }, [data]);

    const [selectedPatrol, setSelectedPatrol] = useState(patrolOptions[0]);
    const handleSelectedPatrol = selected => {
        setSelectedPatrol(selected);
    };

    useEffect(() => {
        if (!selectedPatrol && patrolOptions.length) {
            setSelectedPatrol(patrolOptions[0]);
        }
    }, [patrolOptions]);

    const divisionOptions = [
        { value: 'safety watch', label: t('Safety Watch') },
        { value: 'armed patrol', label: t('Armed Patrol') },
    ];

    const [selectedDivision, setSelectedDivision] = useState(divisionOptions[0]);
    const handleSelectedDivision = selected => {
        setSelectedDivision(selected);
    };

    const [selectedApprovals, setSelectedApprovals] = useState(approvalsOptions[0]);
    const handleSelectedApprovals = selected => {
        setSelectedApprovals(selected);
    };

    const [selectedPatrolInterval, setSelectedPatrolInterval] = useState(patrolIntervalOptions[0]);
    const handleSelectedPatrolInterval = selected => {
        setSelectedPatrolInterval(selected);
    };

    const [startTime, setStartTime] = useState(startDate * ONE_THOUSAND);
    const handleStartTime = selected => {
        const diff = moment(endTime).diff(selected, 'minutes');
        if (diff > TWENTY_FOUR_HOURS_TO_MINUTES) {
            const end = moment(selected).add(TWENTY_FOUR_HOURS, 'hours');
            setEndTime(Math.min(endDate * ONE_THOUSAND, end.valueOf()));
        }
        setStartTime(selected);
    };

    const [endTime, setEndTime] = useState(() => {
        const end = moment(startDate * ONE_THOUSAND).add(TWENTY_FOUR_HOURS, 'hours');
        return Math.min(endDate * ONE_THOUSAND, end.valueOf());
    });
    const handleEndTime = selected => {
        const diff = moment(selected).diff(startTime, 'minutes');
        if (diff > TWENTY_FOUR_HOURS_TO_MINUTES) {
            const start = moment(selected).subtract(TWENTY_FOUR_HOURS, 'hours');
            setStartTime(Math.max(startDate * ONE_THOUSAND, start.valueOf()));
        }
        setEndTime(selected);
    };

    return (
        <Modal
            initModal={initModal}
            toggleModal={toggleModal}
            headerTitle={t('Patrol Report')}
            modalFooter={
                <ModalFooter>
                    <Button className="color-secondary" onClick={toggleModal}>
                        {t('Cancel', 'ConfirmModal')}
                    </Button>
                    <Button
                        className="btn-secondary"
                        disabled={!patrolOptions.length}
                        onClick={() => {
                            const end =
                                startTime === endTime ? endTime : moment(endTime).subtract(1, 'seconds').valueOf();
                            handlePatrolReportForm({
                                patrol: selectedPatrol,
                                division: selectedDivision,
                                approvals: selectedApprovals.value,
                                interval: selectedPatrolInterval.value,
                                startDate: moment(startTime).unix(),
                                endDate: moment(end).unix(),
                            });
                        }}
                    >
                        {t('OK', 'ConfirmModal')}
                    </Button>
                </ModalFooter>
            }
        >
            <ModalBody className="d-flex flex-column gap-2">
                <Label
                    name={t('Start Time')}
                    labelGroupClassName="gap-5"
                    labelValueClassName="label-info"
                    infoBoxValue={t('Select Up To 24 Hours')}
                    value={
                        <TimePicker
                            value={startTime}
                            handleChange={handleStartTime}
                            minDate={startDate * ONE_THOUSAND}
                            maxDate={endTime || maxEndDate}
                            valueType="ms"
                            showTimeInput
                            withoutMinute
                        />
                    }
                />
                <Label
                    name={t('End Time')}
                    labelGroupClassName="gap-5"
                    value={
                        <TimePicker
                            value={endTime}
                            handleChange={handleEndTime}
                            minDate={startTime || startDate * ONE_THOUSAND}
                            maxDate={maxEndDate}
                            valueType="ms"
                            showTimeInput
                            withoutMinute
                        />
                    }
                />
                <Label
                    name={t('Patrol Name')}
                    labelGroupClassName="gap-5"
                    value={
                        <Select
                            name="patrolName"
                            className="w-80"
                            value={selectedPatrol}
                            options={patrolOptions}
                            onChange={handleSelectedPatrol}
                            customOptionStyles={{ width: '100%' }}
                            customControlStyles={{ width: '100%' }}
                            customMenuStyles={{ width: '100%' }}
                            disabled={!patrolOptions.length}
                        />
                    }
                />
                <Label
                    name={t('Division')}
                    labelGroupClassName="gap-5"
                    value={
                        <Select
                            name="patrolDivision"
                            className="w-80"
                            value={selectedDivision}
                            options={divisionOptions}
                            onChange={handleSelectedDivision}
                            customOptionStyles={{ width: '100%' }}
                            customControlStyles={{ width: '100%' }}
                            customMenuStyles={{ width: '100%' }}
                        />
                    }
                />
                <Label
                    name={t('Approvals')}
                    labelGroupClassName="gap-5"
                    value={
                        <Select
                            name="approvals"
                            className="w-80"
                            value={selectedApprovals}
                            options={approvalsOptions}
                            onChange={handleSelectedApprovals}
                            customOptionStyles={{ width: '100%' }}
                            customControlStyles={{ width: '100%' }}
                            customMenuStyles={{ width: '100%' }}
                        />
                    }
                />
                <Label
                    name={t('Patrol Interval')}
                    labelGroupClassName="gap-5"
                    value={
                        <Select
                            name="patrolInterval"
                            className="w-80"
                            value={selectedPatrolInterval}
                            options={patrolIntervalOptions}
                            onChange={handleSelectedPatrolInterval}
                            customOptionStyles={{ width: '100%' }}
                            customControlStyles={{ width: '100%' }}
                            customMenuStyles={{ width: '100%' }}
                        />
                    }
                />
            </ModalBody>
        </Modal>
    );
};

export default PatrolReportModal;
