import React from 'react';

import { useColumns, useTranslation } from '@hooks';

import CompoundCard from '../../../Components/CompoundCard';

import * as column from './column';

const SmartWatchTable = ({ tableData, loading = false }) => {
    const t = useTranslation('Smart Watch Health Monitoring');

    const columns = useColumns([column.department(), column.name(), column.status(), column.changedTime()], t);

    return (
        <CompoundCard className="d-flex flex-column" style={{ height: 'calc(100% - 21rem)' }}>
            <CompoundCard.header>{t('Details Of People')}</CompoundCard.header>
            <CompoundCard.body className="h-100">
                <CompoundCard.body.table
                    containerClassName="h-100"
                    data={tableData}
                    paging={false}
                    columns={columns}
                    loading={loading}
                />
            </CompoundCard.body>
        </CompoundCard>
    );
};

export default SmartWatchTable;
