import React, { useState, useEffect, useMemo } from 'react';
import Card from '../../Components/WidgetCard';
import { useSettings } from '../../util/useSettings';
import { useSelector } from 'react-redux';
import ResponsiveLineChart from '@components/Charts/RealTimeLineChart';

const defaultData = {
    Example1: [4977],
    Example2: [817, 935, 818],
    Example3: [],
};

const getCategory = data => {
    const category = {};
    for (let key in data) {
        category[key] = data[key].length;
    }
    return category;
};

const RealTimeItemMonitoring = ({ children, widgetInfo, ...restProps }) => {
    const { categoryStatus } = useSelector(state => state.TagInfo);
    const { mode } = useSelector(state => state.DashboardFrame);
    const { config } = widgetInfo;
    const settings = useSettings(config);

    const data = useMemo(() => {
        return mode === 'edit' ? defaultData : categoryStatus;
    }, [mode, categoryStatus]);

    const initialList = useMemo(() => {
        return getCategory(data);
    }, []);

    const [categoryList, setCategoryList] = useState(initialList);

    useEffect(() => {
        setCategoryList(getCategory(data));
    }, [data]);

    // 신호가 끊겼을때 오류처럼 보이지 않게 설정
    useEffect(() => {
        const timer = setInterval(() => {
            setCategoryList({ ...categoryList });
        }, 2000);
        return () => clearInterval(timer);
    }, [categoryList]);

    return (
        <Card widgetInfo={widgetInfo} {...restProps}>
            <ResponsiveLineChart
                data={categoryList}
                tagOption={Number(settings.tagOption)}
                dataInteger={settings.DataInteger}
            />
            {children}
        </Card>
    );
};

export default RealTimeItemMonitoring;
