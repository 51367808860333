import React, { useEffect, useState } from 'react';
import DashboardFrame from '../DashboardFrame';
import useWidgetGenerator from '../util/useWidgetGenerator';
import { useDispatch, useSelector } from 'react-redux';
import { setMode, setFullScreen } from '@reducer/Dashboards/DashboardFrame';
import { Link } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import useAsync from '@hooks/useAsync';
import { getDashboardApi } from '@api/dashboard';
import { useTranslation } from 'react-i18next';
import DashboardTitle from './DashboardTitle';
import Button from '../../../Common/Button';
import { widgetInfoExport } from '../util';

const CustomDashboard = ({ match }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [dashboard, setDashboard] = useState({});
    const { layouts, Widgets } = useWidgetGenerator(dashboard.widgetInfo);
    const { bookmarkedMenu } = useSelector(state => state.CustomMenu);
    const { fullScreen } = useSelector(state => state.DashboardFrame);
    const [menuInfo, setMenuInfo] = useState({});
    const [exportWidgetInfo, setExportWidgetInfo] = useState(null);
    const [filename, setFilename] = useState('');
    const { promise: getDashboard, state: getDashboardAsyncInfo } = useAsync({
        promise: getDashboardApi,
        param: { menuNum: Number(match.params.menuNum) },
        resolve: response => {
            try {
                //내보내기에 필요한 위젯 정보를 파일명과 widgetInfo 데이터를 관리한다
                const { menuName, widgetInfo } = response;
                setFilename(`${menuName}.json`);
                setExportWidgetInfo(JSON.parse(widgetInfo));
                response.widgetInfo = JSON.parse(widgetInfo);
            } catch (e) {
                setExportWidgetInfo({});
                response.widgetInfo = {};
            }
            setDashboard(response);
        },
    });

    const toggleFullScreen = () => {
        if (!document.fullscreenElement) {
            const dashboardEl = document.getElementsByClassName('app-main__inner')[0];
            dashboardEl.requestFullscreen();
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            }
        }
    };

    const handleFullscreenChange = () => {
        if (document.fullscreenElement) {
            dispatch(setFullScreen(true));
        } else {
            dispatch(setFullScreen(false));
        }
    };

    useEffect(() => {
        dispatch(setMode('monitoring'));
        if (dashboard.menuNum !== Number(match.params.menuNum) && !getDashboardAsyncInfo.isLoading) {
            getDashboard();
        }

        const dashboardEl = document.getElementsByClassName('app-main__inner')[0];
        dashboardEl.addEventListener('fullscreenchange', handleFullscreenChange);

        return () => {
            dashboardEl.removeEventListener('fullscreenchange', handleFullscreenChange);
        };
    }, [match.url]);

    useEffect(() => {
        let selectedMenu;
        let menuNum = match.params.menuNum;

        selectedMenu = bookmarkedMenu.find(menu => menu.menuNum === parseInt(menuNum));
        setMenuInfo(selectedMenu);
    }, [match.url, bookmarkedMenu]);

    // api 연동시 loading 걸고 dashboard 정보에 의해서 컴포넌트 결정
    return match.params.menuNum ? (
        <>
            {fullScreen ? (
                <>
                    <DashboardTitle title={menuInfo.menuName} />
                    <DashboardFrame layouts={layouts}>{Widgets}</DashboardFrame>
                </>
            ) : (
                <DashboardFrame layouts={layouts}>{Widgets}</DashboardFrame>
            )}
            {!isMobile && (
                <>
                    {!fullScreen && (
                        <>
                            {/*내보내기에 필요한 WidgetInfo가 없을 시 내보내기 버튼 hidden*/}
                            {exportWidgetInfo && (
                                <Button
                                    className={'btn-dashboard-export btn-gray form-h-small'}
                                    onClick={() => widgetInfoExport({ filename, exportWidgetInfo })}
                                >
                                    {t('Dashboard;Export')}
                                </Button>
                            )}
                            <Button
                                className={'btn-dashboard-fullscreen btn-gray form-h-small'}
                                onClick={toggleFullScreen}
                            >
                                {t('Dashboard;Full screen')}
                            </Button>
                            <Link to={`/dashboards/edit/${dashboard.menuNum}`}>
                                <Button className={'btn-dashboard-edit btn-gray form-h-small'}>
                                    {t('Dashboard;Edit')}
                                </Button>
                            </Link>
                        </>
                    )}
                </>
            )}
        </>
    ) : (
        <div
            className={'flex-center'}
            style={{
                flexDirection: 'column',
                width: '100%',
                height: 'calc(100vh - 80px)',
                fontSize: '2rem',
                color: '#777',
            }}
        >
            {t('Dashboard;The dashboard does not exist.')}
            <Link to={'/dashboards/list'}>
                <Button>{t('Dashboard;Go to list')}</Button>
            </Link>
        </div>
    );
};

export default CustomDashboard;
