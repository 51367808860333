import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { useAsync, useTranslation } from '@hooks';

import { fetchCategory } from 'api/asset';
import {
    getFlowSchemeInfoApi,
    getStatusSchemeInfoApi,
    getStatusFlowCategoryMapInfoApi,
    getMappedStatusListInfoApi,
    getStatusSchemeListInfoApi,
    createStatusFlowCategoryMapInfoApi,
    deleteStatusFlowCategoryMapInfoApi,
} from 'api/status';

import { ConfirmModal, TreeSelect, Button, Card, Label, ToggleButton } from 'Components/Common';
import { StyledStatusLabel } from 'Components/MainPages/Components/Grid/column';

import { useDispatch, useSelector } from 'react-redux';
import { setCategoryList } from 'reducers/Common/CategoryInfo';
import {
    setMappedStatusListInfo,
    setStatusFlowCategoryMapInfo,
    setStatusSchemeListInfo,
} from 'reducers/Common/StatusInfo';

const initialData = {
    flowName: '',
    description: '',
    isActive: 'N',
    assignedCount: 0,
    schemeNum: 0,
    schemeName: '',
    flowDrawJson: '',
    schemeDescription: '',
};

const AllocateAssetsView = () => {
    const dispatch = useDispatch();

    const history = useHistory();

    const params = useParams();
    const { flowNum } = params;

    const t = useTranslation('Status');

    const { statusSchemeListInfo, flowCategoryMappedStatusInfo } = useSelector(state => state.StatusInfo);
    const { serviceCategory, categoryList } = useSelector(state => state.CategoryInfo);

    const [options, setOptions] = useState(categoryList || []);
    const [mappedSelectedList, setMappedSelectedList] = useState([]);
    const [selected, setSelected] = useState([]);
    const [viewData, setViewData] = useState(initialData);
    const { flowName, description, isActive, assignedCount, schemeNum, schemeName, schemeDescription } = viewData;

    useAsync({
        promise: fetchCategory,
        fixedParam: { parentCode: serviceCategory },
        immediate: true,
        resolve: res => {
            dispatch(setCategoryList(res.rows));
        },
    });

    useAsync({
        promise: getStatusSchemeListInfoApi,
        immediate: true,
        resolve: res => {
            dispatch(setStatusSchemeListInfo(res));
        },
    });

    const { promise: getSelectedTarget } = useAsync({
        promise: getFlowSchemeInfoApi,
        resolve: ({ flowName, description, isActive, scheme, schemeNum, flowDrawJson }) => {
            setViewData(prevState => {
                return { ...prevState, flowName, description, isActive, scheme, schemeNum, flowDrawJson };
            });
            getStatusScheme({ schemeNum });
        },
    });

    const { promise: getStatusFlowCategoryMapInfo } = useAsync({
        promise: getStatusFlowCategoryMapInfoApi,
        fixedParam: { isAll: 'Y' },
        resolve: res => {
            const { rows } = res;
            const assignedCount = rows
                .filter(({ flowNum: resFlowNum }) => {
                    return resFlowNum ? resFlowNum.toString() === flowNum : null;
                })
                .reduce((acc, cur) => {
                    return acc + cur.itemCount;
                }, 0);
            setViewData(prevState => {
                return { ...prevState, assignedCount };
            });
            dispatch(setStatusFlowCategoryMapInfo(res));
        },
    });

    const { promise: getStatusScheme } = useAsync({
        promise: getStatusSchemeInfoApi,
        resolve: ({ schemeName, description }) => {
            setViewData(prevState => {
                return { ...prevState, schemeName, schemeDescription: description };
            });
        },
    });

    const { promise: getMappedStatusListInfo } = useAsync({
        promise: getMappedStatusListInfoApi,
        resolve: res => {
            dispatch(setMappedStatusListInfo(res));
        },
    });

    const { promise: deleteStatusFlowCategory } = useAsync({
        promise: deleteStatusFlowCategoryMapInfoApi,
        resolve: () => toggleSaveConfirmModal(),
        reject: () => toggleSaveRejectModal(),
    });

    const { promise: postStatusFlowCategory } = useAsync({
        promise: createStatusFlowCategoryMapInfoApi,
        resolve: () => {
            toggleSaveConfirmModal();
            getStatusFlowCategoryMapInfo({ isAll: 'Y' });
        },
        reject: () => {
            toggleSaveRejectModal();
        },
    });

    useEffect(() => {
        getMappedStatusListInfo({ isAll: 'Y' });
        getStatusFlowCategoryMapInfo({ isAll: 'Y' });
    }, []);

    useEffect(() => {
        getSelectedTarget({ flowNum });
        getStatusFlowCategoryMapInfo({ isAll: 'Y' });
    }, [flowNum]);

    useEffect(() => {
        const selectedList = [];

        flowCategoryMappedStatusInfo.rows
            .filter(category => category.flowNum === parseInt(flowNum))
            .forEach(v => selectedList.push(v.categoryCode));

        setMappedSelectedList(selectedList);
        setOptions(categoryList);
    }, [categoryList, flowCategoryMappedStatusInfo.rows]);

    useEffect(() => {
        setSelected(mappedSelectedList);
    }, [mappedSelectedList]);

    const [saveModal, setSaveModal] = useState(false);
    const toggleSaveModal = () => setSaveModal(!saveModal);

    const [saveConfirmModal, setSaveConfirmModal] = useState(false);
    const toggleSaveConfirmModal = () => setSaveConfirmModal(!saveConfirmModal);

    const [saveRejectModal, setSaveRejectModal] = useState(false);
    const toggleSaveRejectModal = () => setSaveRejectModal(!saveRejectModal);

    const handleSelectChange = list => setSelected(list);

    const singleStatusScheme = useMemo(
        () => statusSchemeListInfo.rows.find(el => el.schemeNum === schemeNum),
        [statusSchemeListInfo, schemeNum],
    );

    return (
        <>
            <Card
                header={{
                    titleIcon: 'icon-view',
                    title: `${t('Allocate Assets')} - ${t('View')}`,
                    action: (
                        <>
                            <Button className="btn-gray" onClick={() => history.push('/status/allocate')}>
                                {t('Cancel')}
                            </Button>
                            <Button className="btn-brand fs-6" iconName="save" onClick={toggleSaveModal}>
                                {t('Save')}
                            </Button>
                        </>
                    ),
                }}
            >
                <div style={{ height: '43rem' }}>
                    <div className="mb-3 font-weight-bold font-size-lg">{`${t('Status')} ${t('Detail')}`}</div>
                    <div>
                        <div className="p-0 d-flex row pnt-label-7">
                            <Label
                                name={t('Name')}
                                labelValueClassName={'label-dot color-brand'}
                                labelGroupClassName={'col-xl-6 mb-3 col-lg-12'}
                                value={<>{flowName}</>}
                            />
                            <Label
                                name={t('Status Scheme')}
                                labelValueClassName={'label-dot color-brand'}
                                labelGroupClassName={'col-xl-6 mb-3 col-lg-12'}
                                value={<>{schemeName || t("There's no Status Scheme.")}</>}
                            />
                            <Label
                                name={t('Activation')}
                                labelValueClassName={'label-dot color-brand'}
                                labelGroupClassName={'col-xl-6 mb-3 col-lg-12'}
                                value={<ToggleButton checked={isActive === 'Y'} disabled />}
                            />
                            <Label
                                name={t('assigned assets')}
                                labelValueClassName={'label-dot label-main'}
                                labelGroupClassName={'col-xl-6 mb-3 col-lg-12'}
                                value={<div>{assignedCount}</div>}
                            />
                            <Label
                                name={t('Description')}
                                labelValueClassName={'label-dot label-main'}
                                labelGroupClassName={'col-xl-6 mb-3 col-lg-12'}
                                value={<div>{description || t("There's no description.")}</div>}
                            />
                        </div>
                    </div>
                    <div className="pnt-border border-w" />
                    <div className="mt-4 mb-3 font-weight-bold font-size-lg">{t('Set Assets')}</div>
                    <div className="p-0 d-flex row pnt-label-7">
                        <Label
                            name={t('Description')}
                            labelValueClassName={'label-dot label-main'}
                            labelGroupClassName={'col-xl-6 mb-3 col-lg-12'}
                            value={<div>{schemeDescription || t("There's no description.")}</div>}
                        />
                        <Label
                            name={t('Status scheme')}
                            labelValueClassName={'label-dot label-main'}
                            labelGroupClassName={'col-xl-6 mb-3 col-lg-12'}
                            value={
                                <div>
                                    {singleStatusScheme && singleStatusScheme.mappedStatus
                                        ? singleStatusScheme.mappedStatus.map((v, i) => (
                                              <StyledStatusLabel
                                                  key={v.flowNum + i}
                                                  statusName={v.statusName}
                                                  displayColor={v.displayColor}
                                                  className={'table-cell-middle'}
                                              />
                                          ))
                                        : null}
                                </div>
                            }
                        />
                        <Label
                            name={t('Category')}
                            labelValueClassName={'label-dot label-main'}
                            labelGroupClassName={'col-xl-6 mb-3 col-lg-12'}
                            value={
                                <TreeSelect
                                    data={options}
                                    title={t('Category')}
                                    valueKey={'categoryCode'}
                                    parentKey={'parentCode'}
                                    labelKey={'categoryNamePath'}
                                    emptyText={t('Not Selected')}
                                    selectedAllText={t('All Selected')}
                                    selected={selected}
                                    onChange={handleSelectChange}
                                />
                            }
                        />
                    </div>
                </div>
            </Card>
            <ConfirmModal
                initModal={saveModal}
                toggleModal={() => setSaveModal(!saveModal)}
                confirmText={t('Do you want to save?')}
                okCallback={() => {
                    const intFlowNum = parseInt(flowNum);
                    const categoryCodes = selected
                        .reduce((acc, cur) => {
                            return acc + cur + ',';
                        }, '')
                        .slice(0, -1);
                    if (!categoryCodes) {
                        deleteStatusFlowCategory({ flowNums: flowNum });
                    } else {
                        postStatusFlowCategory({ categoryCodes, flowNum: intFlowNum });
                    }
                }}
            />
            <ConfirmModal
                initModal={saveConfirmModal}
                toggleModal={toggleSaveConfirmModal}
                confirmText={t('ConfirmModal;Save has been completed.')}
                removeCancel
            />
            <ConfirmModal
                initModal={saveRejectModal}
                toggleModal={toggleSaveRejectModal}
                header={{ title: t('ConfirmModal;Notification') }}
                confirmText={t('ConfirmModal;The category is already mapping with other Flow Scheme.')}
                removeCancel
            />
        </>
    );
};

export default AllocateAssetsView;
