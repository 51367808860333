import React, { useEffect, useMemo, useState } from 'react';

import { useSelector } from 'react-redux';
import { initialFilterInfo } from 'reducers/FilterInfo';

import { Page, Button, Select as ReactSelect } from '@components';
import useTranslation from '@hooks/useTranslation';

import SearchAutocomplete from './Components/SearchAutocomplete';
import SearchGroup from '@components/FilterSearchGroup/Components/SearchGroup';
import SearchButtonArea from '@components/FilterSearchGroup/Components/SearchButtonArea';

import { makeFilterParam } from 'Components/MainPages/Filter/reducer';
import FilterWidget from 'Components/MainPages/Filter/FilterWidget';
import FilterSearchGroup from '@components/FilterSearchGroup';
import { InputGroup, InputWrap, SearchWrap, SelectGroup } from '@components/FilterSearchGroup/Components/Part';

import AssetDetail from '../AssetDetail';

const QuickSearch = () => {
    const t = useTranslation('Search');
    const filterT = useTranslation('Filter');

    const { topCategoryList } = useSelector(state => state.CategoryInfo);
    const filteredCategoryList = useMemo(
        () =>
            topCategoryList
                .filter(({ value }) => value !== 'PEOPLE')
                .map(({ value }) => value)
                .join(','),
        [topCategoryList],
    );

    const searchOptions = useMemo(() => {
        return [
            { value: 'targetName', label: filterT('Name') },
            { value: 'targetId', label: filterT('ID') },
        ];
    }, [filterT]);

    const [filterInfo, setFilterInfo] = useState(initialFilterInfo);
    const [filterParam, setFilterParam] = useState({});

    const [selectedOption, setSelectedOption] = useState(searchOptions[0]);
    const [targetInfo, setTargetInfo] = useState(null);
    const [targetNum, setTargetNum] = useState(0);

    const clickSearchBtn = () => {
        if (targetInfo && targetInfo.targetNum) {
            setTargetNum(targetInfo.targetNum);
        }
    };

    const handleSelect = e => {
        setSelectedOption(e);
    };

    const handleTargetNum = selected => {
        if (selected.length > 0) {
            setTargetInfo(selected[0]);
        }
    };

    /**
     * ### SM에서는 최상위 코드만 불러오지 않고 전체에서 서칭할 수 있게 주석
     *
     * @author minchan-kim
     */
    // const { serviceCategoryCodes } = useSelector(state => state.CategoryInfo);
    // const enrichCategoryCodes = filterInfo => {
    //     if (!filterInfo.categoryCodes || !filterInfo.categoryCodes.length) {
    //         filterInfo.categoryCodes = serviceCategoryCodes;
    //     }
    //     return filterInfo;
    // };

    const handleFilterConditionChange = selected => {
        const changedFilterInfoCondition = filterInfo.filterInfoCondition.map(conditionInfo => {
            if (conditionInfo.conditionId === selected.conditionId) {
                return { ...conditionInfo, conditionValues: selected.conditionValues };
            }
            return conditionInfo;
        });
        setFilterInfo({ ...filterInfo, filterInfoCondition: changedFilterInfoCondition });
    };

    useEffect(() => {
        if (!!topCategoryList.length) {
            setFilterParam({ ...filterParam, categoryCodes: filteredCategoryList });
        }
    }, [topCategoryList]);

    useEffect(() => {
        if (filterInfo.filterInfoCondition) {
            const param = makeFilterParam(filterInfo.filterInfoCondition);
            if (!param.categoryCodes) {
                param.categoryCodes = filteredCategoryList;
            }
            setFilterParam(param);
        }
    }, [filterInfo.filterInfoCondition]);

    return (
        <Page menuPath={[t('Assets', 'Menu'), t('Quick Search', 'Menu')]} className={'h-100'}>
            <FilterSearchGroup>
                <FilterWidget
                    filterInfo={{
                        ...filterInfo,
                        categoryCodes: filteredCategoryList,
                    }}
                    withContainer={false}
                    changeableFilter={false}
                    filterEditMode
                    handleFilterConditionChange={handleFilterConditionChange}
                />
                <SearchGroup label={t('Search')} className={'bg-brand'}>
                    <SearchWrap>
                        <SelectGroup>
                            <ReactSelect
                                name={'selectInput'}
                                placeholder={t('Search Option')}
                                value={selectedOption}
                                options={searchOptions}
                                onChange={handleSelect}
                                customControlStyles={{ width: '100%' }}
                                customMenuStyles={{ width: '100%' }}
                                customOptionStyles={{ width: '100%' }}
                            />
                        </SelectGroup>
                        <InputWrap>
                            <InputGroup>
                                <SearchAutocomplete
                                    option={selectedOption}
                                    handleSelectedKeyword={handleTargetNum}
                                    // filterParam={enrichCategoryCodes(makeFilterParam(filterInfo.filterInfoCondition))}
                                    filterParam={filterParam}
                                    disabled={!filteredCategoryList}
                                />
                            </InputGroup>
                        </InputWrap>
                        <Button className="btn-brand btn-icon" iconName="search" onClick={clickSearchBtn}>
                            {t('Search')}
                        </Button>
                    </SearchWrap>
                </SearchGroup>
            </FilterSearchGroup>
            <AssetDetail givenTargetNum={targetNum} />
        </Page>
    );
};

export default QuickSearch;
