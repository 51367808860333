import React from 'react';
import Card from '@components/Card';
import useTranslation from '@hooks/useTranslation';
import ItemCount from './ItemCount';
import { Row } from 'reactstrap';

// 전체 IoT 아이템 & 센서 수
const SensorAndIotCount = ({ targetCountData }) => {
    const t = useTranslation('SensorStatus');

    return (
        <Card className={'sensor-count-card'} header={{ title: t('Total number of IoT items & sensors') }}>
            <Row className={'flex-center'} style={{ height: '100%' }}>
                <div style={{ width: '100%', display: 'flex', alignItems: 'start' }}>
                    <ItemCount
                        start={targetCountData.prev.mappedSensorCount}
                        end={targetCountData.curr.mappedSensorCount}
                        label={t('Number of sensors mapped')}
                    />
                    <ItemCount
                        start={targetCountData.prev.mappedTargetCount}
                        end={targetCountData.curr.mappedTargetCount}
                        label={t('Number of IoT items mapped')}
                    />
                </div>
            </Row>
        </Card>
    );
};

export default React.memo(SensorAndIotCount);
