import React, { useState } from 'react';
import DropdownTreeSelect from 'react-dropdown-tree-select';
import 'react-dropdown-tree-select/dist/styles.css';
import useAsync from '../../../../../../util/hooks/useAsync';
import { getOwnershipListApi } from '../../../../../../api/asset';
import styled from 'styled-components';
import useTranslation from '../../../../../../util/hooks/useTranslation';
import { useSelector } from 'react-redux';

const TreeSelect = styled(DropdownTreeSelect)`
    .tag-item > .search {
        border-bottom: none;
    }

    .tag-list .tag-item:not(:last-child) {
        display: none;
    }

    .dropdown {
        width: 100%;
        &::after {
            ${props =>
                props.validation &&
                `
                    display: block;
                    content: '*';
                    position: absolute;
                    top: -0.8rem;
                    left: -0.3rem;
                    color: #ff7800ff;
                `}
        }
    }

    .dropdown-trigger {
        border-radius: 0.25rem;
        color: #495057;
        width: 100%;

        .tag-item {
            width: 95%;
        }
        border: 1px solid ${props => (props.validation ? '#FF7800FF' : '#b9b9b9')} !important;
    }

    .tag-list .tag-item {
        line-height: 1;
        width: 80%;
    }

    .no-matches {
        display: inline-block;
        margin: 8px 4px;
    }

    .dropdown-trigger.arrow {
        &.bottom:after,
        &.top:after {
            //content: 'ᐯ' !important;
            color: hsl(0, 0%, 80%) !important;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 10px;
        }
    }

    .node.disabled {
        cursor: default;
    }

    .node.disabled > * {
        cursor: default;
    }

    .node.disabled > i {
        cursor: pointer;
        color: #555;
    }

    .node > label {
        width: 95%;
        padding: 0.16rem 0;
    }

    .node > label > input[type='radio'] {
        display: none;
    }

    .dropdown-content {
        min-width: 100%;
        max-height: 300px;
        overflow-y: auto;
    }
`;

function addLeafNode(treeData, dataToAdd, valueKey, parentKey) {
    const parent = dataToAdd[parentKey];
    let find = false;

    // 자식 탐색
    treeData.every(v => {
        if (v[valueKey] === parent) {
            if (v.children) {
                v.children.push(dataToAdd);
            } else {
                v.children = [dataToAdd];
            }
            find = true;
            return false;
        }
        return true;
    });

    // 자식 탐색 실패시 자식의 자식 탐색
    if (!find) {
        treeData.every(v => {
            if (v.children) {
                find = addLeafNode(v.children, dataToAdd, valueKey, parentKey);
                if (find) {
                    return false;
                }
            }
            return true;
        });
    }
    return find;
}
function makeTreeData(treeData = [], flattenData, valueKey, parentKey) {
    const dataToAdd = [...flattenData];
    const restData = [];

    if (treeData.length) {
        while (dataToAdd.length > 0) {
            const curr = dataToAdd.shift();
            const found = addLeafNode(treeData, curr, valueKey, parentKey);
            if (!found) {
                restData.push(curr);
            }
        }
    }

    if (restData.length > 0) {
        makeTreeData(treeData, restData, valueKey, parentKey);
    }
    return treeData;
}

const DepartmentSingleTreeSelect = ({ onChange, validation }) => {
    const t = useTranslation('AssetRentalRequest');
    const [options, setOptions] = useState([]);
    const [selectedLabel, setSelectedLabel] = useState('');
    const { groupNum } = useSelector(state => state.UserInfo.userInfo);

    useAsync({
        promise: getOwnershipListApi,
        fixedParam: { isAll: 'Y' },
        deps: [groupNum],
        immediate: true,
        resolve: res => {
            let { rows } = res;

            rows = rows.filter(department => groupNum !== department.groupNum);

            const flattenData = rows.map(v => ({
                ...v,
                value: v.groupId,
                label: v.groupName,
                disabled: false,
            }));

            const treeData = makeTreeData(
                flattenData.filter(v => !v['parentGroupId']),
                flattenData.filter(v => !!v['parentGroupId']),
                'groupId',
                'parentGroupId',
            );

            setOptions(
                treeData.map(v => {
                    if (!v.children) {
                        return { ...v, disabled: false };
                    } else {
                        return { ...v, disabled: true };
                    }
                }),
            );
        },
    });

    const handleSelectedChange = selected => {
        onChange(selected);

        setSelectedLabel(selected.label);
    };

    return (
        <div className="pnt-select--group w-100">
            <TreeSelect
                validation={validation}
                mode={'radioSelect'}
                data={options}
                onChange={handleSelectedChange}
                showPartiallySelected={true}
                keepTreeOnSearch
                texts={{
                    placeholder: selectedLabel || t('Select the department'),
                    noMatches: t('No matches found', 'TreeSelect'),
                }}
            />
        </div>
    );
};

export default DepartmentSingleTreeSelect;
