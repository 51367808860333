import React, { createContext, useReducer, useRef, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';

import { fetchExcelLogApi, fetchGeofenceExcelLogExport, fetchGeofenceLogList } from 'api/log';
import { fetchFloorList, fetchGeofenceList } from 'api/common';

import { Button, Card, Table, Page } from '@components';
import { useAsync, useConfirmModal, useTranslation, useColumns } from '@hooks';

import { useDispatch } from 'react-redux';
import { setFloorList } from 'reducers/Common/FloorInfo';
import geofenceLogReducer, { initialState, setGeofenceList } from './geofenceLogReducer';

import Search from './Components/Search';
import { excelExport } from '../util/util';
import * as column from '../../util/grid/column';

import moment from 'moment';

export const GeofenceLogDispatchContext = createContext();
export const GeofenceLogStateContext = createContext(null);

const INTERVAL_TIME = 1000;

const GeofenceLog = () => {
    const t = useTranslation('Geofence Log');
    const columnT = useTranslation('CommonColumn');

    const searchRef = useRef();
    const excelTimerRef = useRef();

    const match = useRouteMatch();

    const storeDispatch = useDispatch();

    const [state, dispatch] = useReducer(geofenceLogReducer, initialState);
    const { startDate, endDate } = state;

    useAsync({
        promise: fetchFloorList,
        resolve: response => {
            storeDispatch(setFloorList(response));
        },
        immediate: true,
        deps: [match.params.menuNum],
    });

    useAsync({
        promise: fetchGeofenceList,
        resolve: response => {
            dispatch(setGeofenceList(response));
        },
        immediate: true,
    });

    const columns = useColumns([
        column.number(),
        column.categoryName(),
        column.targetName(),
        column.targetId(),
        column.floor(),
        column.geofence(),
        column.authorizedNotAuthorized({
            width: 100,
            accessor: 'permittedStr',
            formatTitle: ({ value }) => t(value),
            Cell: ({ value }) => t(value),
        }),
        column.inDate(),
        column.inScannerName(),
        column.outDate(),
        column.outScannerName(),
    ]);

    const [geofenceLogData, setGeofenceLogData] = useState({
        totalCount: 0,
        totalPage: 1,
        rows: [],
    });

    const { promise: getGeofenceLogList, state: geofenceLogListInfo } = useAsync({
        promise: fetchGeofenceLogList,
        keepState: true,
        fixedParam: { pageSize: 15 },
        immediate: true,
        param: {
            startDate: moment(startDate).unix(),
            endDate: moment(endDate).unix(),
        },
        resolve: res => {
            const data = res.rows.map(log => {
                return {
                    comNum: log.comNum,
                    categoryCode: log.categoryCode,
                    targetName: log.targetName,
                    targetId: log.targetId,
                    floorIdPath: log.floorIdPath,
                    fcName: log.fcName,
                    permitted: log.permitted,
                    inDate: log.inDate,
                    inScannerName: log.inScannerName,
                    outDate: log.outDate,
                    outScannerName: log.outScannerName,
                    logNum: log.logNum,
                    ...log,
                };
            });
            setGeofenceLogData({ ...res, rows: data });
        },
    });

    const { promise: createNewLogFile } = useAsync({
        promise: fetchGeofenceExcelLogExport,
        fixedParam: {
            zipFileName: 'Geofence_Log',
            fileName: 'Geofence_Log',
            startDate: startDate ? moment(startDate).unix() : 0,
            endDate: endDate ? moment(endDate).unix() : moment().unix(),
            columnMetas: [
                {
                    key: 'logNum',
                    name: columnT('No.'),
                },
                {
                    key: 'categoryCode',
                    name: columnT('Category Name'),
                },
                {
                    key: 'targetName',
                    name: columnT('Target Name'),
                },
                {
                    key: 'targetId',
                    name: columnT('Target ID'),
                },
                {
                    key: 'floorName',
                    name: columnT('Floor'),
                },
                {
                    key: 'fcName',
                    name: columnT('Geofence'),
                },
                {
                    key: 'permittedStr',
                    name: columnT('Authorized Not Authorized'),
                },
                {
                    key: 'inDate',
                    isDateFormat: 'Y',
                    name: columnT('In Date'),
                },
                {
                    key: 'inScannerName',
                    name: columnT('In Scanner Name'),
                },
                {
                    key: 'outDate',
                    isDateFormat: 'Y',
                    name: columnT('Out Date'),
                },
                {
                    key: 'outScannerName',
                    name: columnT('Out Scanner Name'),
                },
            ],
        },
        resolve: res => {
            const { filePath: url, reqNum } = res;
            if (url) {
                downloadWithURL(url, reqNum);
            }
        },
        reject: err => {
            toggleExcelModal();
            console.error(err);
        },
    });

    const { promise: fetchExcelLog } = useAsync({
        promise: fetchExcelLogApi,
        resolve: res => {
            const { dataSettingStatusType, filePath: url, fileName } = res;
            if (dataSettingStatusType === 'COMPLETED') {
                clearExcelTimer();
                excelExport(url, fileName);
            } else if (dataSettingStatusType === 'FAILED') {
                clearExcelTimer();
                toggleExcelDownloadResultModal();
            }
        },
    });

    const downloadWithURL = (url, reqNum) => {
        clearExcelTimer();
        excelTimerRef.current = setInterval(() => {
            fetchExcelLog({ reqNum: reqNum });
        }, INTERVAL_TIME);
    };

    const clearExcelTimer = () => {
        if (!!excelTimerRef.current) {
            clearInterval(excelTimerRef.current);
            excelTimerRef.current = null;
        }
    };

    const handlePageChange = pageIndex => {
        if (
            geofenceLogListInfo.request &&
            (geofenceLogListInfo.request.keyword ||
                geofenceLogListInfo.request.startDate ||
                geofenceLogListInfo.request.endDate)
        ) {
            getGeofenceLogList({ ...geofenceLogListInfo.request, page: pageIndex });
        } else {
            getGeofenceLogList({ page: pageIndex });
        }
    };

    const handleExportFileClick = () => {
        if (!geofenceLogData.rows || !geofenceLogData.rows.length) {
            toggleExcelDownloadNoDataModal();
            return;
        }

        const { request } = geofenceLogListInfo;
        delete request.page;
        delete request.pageSize;
        request.isAll = 'Y';
        createNewLogFile({ ...request });
    };

    const { Modal: ExcelDownloadResultModal, toggleModal: toggleExcelDownloadResultModal } = useConfirmModal({
        initModal: false,
        header: { title: t('Result', 'ConfirmModal') },
        confirmText: t('Failed to download the excel.', 'ConfirmModal'),
    });

    const { toggleModal: toggleExcelModal, Modal: ExcelModal } = useConfirmModal({
        initModal: false,
        header: {
            title: t('Excel Download', 'Geofence Log List'),
        },
        confirmText: t(
            'You can see and download the Excel File that you have requested under `Report > Geofence Log List` menu.',
            'Geofence Log List',
        ),
        removeCancel: true,
    });

    const { Modal: ExcelDownloadNoDataModal, toggleModal: toggleExcelDownloadNoDataModal } = useConfirmModal({
        initModal: false,
        header: { title: t('Notification', 'ConfirmModal') },
        confirmText: t('There is no data.', 'ConfirmModal'),
        removeCancel: true,
    });

    return (
        <GeofenceLogDispatchContext.Provider value={dispatch}>
            <GeofenceLogStateContext.Provider value={state}>
                <Page menuPath={[t('Report', 'Menu'), t('Geofence Log', 'Menu')]} className="h-100">
                    <Search getGeofenceLogList={getGeofenceLogList} />
                    <Card
                        innerRef={searchRef}
                        className="report-container"
                        header={{
                            title: t('Geofence Log List'),
                            action: (
                                <Button className="btn-secondary" onClick={handleExportFileClick}>
                                    {t('Export', 'Button')}
                                </Button>
                            ),
                        }}
                    >
                        <div style={{ height: '35rem' }}>
                            <Table
                                data={{ ...geofenceLogData, pageSize: 15 }}
                                columns={columns}
                                onPageChange={handlePageChange}
                                loading={geofenceLogListInfo.isLoading}
                            />
                        </div>
                    </Card>
                    <ExcelModal />
                    <ExcelDownloadResultModal />
                    <ExcelDownloadNoDataModal />
                </Page>
            </GeofenceLogStateContext.Provider>
        </GeofenceLogDispatchContext.Provider>
    );
};

export default GeofenceLog;
