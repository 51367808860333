import React from 'react';
import { Row, Col } from 'reactstrap';
import cx from 'classnames';
import styles from '@asset/main/module/PatientAbnormalSigns.module.scss';
import PerfectScrollbar from 'react-perfect-scrollbar';

const mapRiskClass = {
    Low: 'low',
    'Low-medium': 'lowMedium',
    Medium: 'medium',
    High: 'high',
};

const PatientCard = React.memo(({ abnormalSignsInfo: data, widgetWidth }) => {
    return (
        <Col xl={widgetWidth > 7 ? 4 : 6} style={{ paddingBottom: '15px' }}>
            <div className={cx(styles['patient-card'])}>
                <Row>
                    <Col xs={8} className={cx(styles['patient-name'], 'text-ellipsis')}>
                        {`${data.patName}(${data.patAge})`}
                    </Col>
                    <Col xs={4} className={styles['abnormal-score']}>
                        <span className={cx(styles['risk-level'], styles[mapRiskClass[data.risk]])}>
                            {data.warningScore}
                        </span>
                    </Col>
                </Row>
                <Row>
                    <Col className={styles['monitoring-message']}>{data.response}</Col>
                </Row>
            </div>
        </Col>
    );
});

const WardFrame = ({ data, widgetWidth }) => {
    return (
        <PerfectScrollbar>
            <div className={styles['patient-card-wrapper']}>
                {data.map(abnormalSignsInfo => (
                    <PatientCard
                        key={abnormalSignsInfo.patNum}
                        abnormalSignsInfo={abnormalSignsInfo}
                        widgetWidth={widgetWidth}
                    />
                ))}
            </div>
        </PerfectScrollbar>
    );
};

export default React.memo(WardFrame);
