import React, { Fragment, useMemo } from 'react';

import { useTranslation } from '@hooks';

import { dateToFormat } from '@util/common/util';

import moment from 'moment';

import styled from 'styled-components';

const IS_PROBLEM = 'Y';

const PatrolSignificantTable = ({ data }) => {
    const t = useTranslation('Patrol Log');

    const filteredData = useMemo(
        () =>
            data
                .map(({ beaconName, beaconId, patrolCompleteDates }) => {
                    return {
                        beaconId,
                        beaconName,
                        dates: patrolCompleteDates
                            .flatMap(({ dates }) => dates)
                            .filter(({ problem }) => problem === IS_PROBLEM),
                    };
                })
                .filter(({ dates }) => dates.length),
        [data],
    );

    // 특이 사항이 없는 경우, 테이블 미표시
    if (!filteredData.length) return null;

    return (
        <table>
            <thead>
                <tr>
                    <StyledHeader>{t('Location')}</StyledHeader>
                    <StyledHeader>{t('Time')}</StyledHeader>
                    <StyledHeader>{t('Check List')}</StyledHeader>
                    <StyledHeader>{t('Significant')}</StyledHeader>
                </tr>
            </thead>
            <tbody>
                {filteredData.map(({ beaconId, beaconName, dates }) => {
                    const cnt = dates.reduce((acc, cur) => acc + Math.max(cur.checkResults.length, 1), 0);
                    return (
                        <Fragment key={beaconId}>
                            {dates.map(({ date, checkResults }, idx) => {
                                return checkResults.map(({ checkList, checkResult }, resultIdx) => {
                                    return (
                                        <tr key={`${beaconId}_${idx}_${resultIdx}`}>
                                            {!idx && !resultIdx && (
                                                <StyledFirstRow rowSpan={cnt} $width="15%">
                                                    {beaconName}
                                                </StyledFirstRow>
                                            )}
                                            {!resultIdx && (
                                                <StyledTd rowSpan={checkResults.length} $width="10%">
                                                    {dateToFormat(moment.unix(date), 'HH:mm:ss')}
                                                </StyledTd>
                                            )}
                                            <StyledTd $width="20%">{checkList}</StyledTd>
                                            <StyledStartTd>{checkResult}</StyledStartTd>
                                        </tr>
                                    );
                                });
                            })}
                        </Fragment>
                    );
                })}
            </tbody>
        </table>
    );
};

const StyledTd = styled.td`
    border-bottom: 1px solid black;
    border-right: 1px solid black;
    width: ${({ $width }) => ($width ? $width : '3rem')};
    height: 1rem;
    text-align: center;
    background-color: ${({ $selected }) => ($selected ? 'lightcoral' : 'white')};
    font-size: 0.6rem;
    font-weight: bold;
    color: black;
    padding: 2px;
    word-break: break-all;
`;

const StyledFirstRow = styled(StyledTd)`
    border-left: 1px solid black;
`;

const StyledStartTd = styled(StyledTd)`
    text-align: start;
`;

const StyledHeader = styled.th`
    border-bottom: 1px solid black;
    border-right: 1px solid black;
    width: 3rem;
    height: 1rem;
    text-align: center;
    font-weight: bold;
    color: black;

    background-color: lightgrey;
    padding: 4px 0;
    font-size: 0.6rem;
    border-top: 1px solid black;
    :first-child {
        border-left: 1px solid black;
    }
`;

export default PatrolSignificantTable;
