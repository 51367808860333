import React from 'react';

import { Route } from 'react-router-dom';
import { ErrorHandleSwitch } from '../Components/Router';

import QuickSearch from './QuickSearch';
import AssetDetail from './AssetDetail';
import ItemList from './ItemList';

const Assets = ({ match }) => {
    return (
        <ErrorHandleSwitch>
            <Route exact path={`${match.path}/quickSearch`} component={QuickSearch} />
            <Route exact path={`${match.path}/list/:filterNum([0-9]+)`} component={ItemList} />
            <Route exact path={`${match.path}/detail/:targetNum([0-9]+)`} component={AssetDetail} />
        </ErrorHandleSwitch>
    );
};

export default Assets;
