import React, { useEffect, useMemo, useState } from 'react';

import { useDispatch } from 'react-redux';
import { editWidgetSettings } from '@reducer/Dashboards/DashboardEdit';

import { Label, Select as ReactSelect } from '@components';
import useTranslation from '@hooks/useTranslation';
import WidgetSettingModal from '../../Components/WidgetSettingModal';

const StatusBySetting = ({ widgetInfo, ...restProps }) => {
    const t = useTranslation('WidgetSetting');

    const { config } = widgetInfo;
    const { settings: customSettings } = config;

    const dispatch = useDispatch();

    const [updatedWidgetInfo, setUpdatedWidgetInfo] = useState({});
    const [changeOption, setChangeOption] = useState(false);
    const [selectedOption, setSelectedOption] = useState(
        customSettings && customSettings.selectedOption ? customSettings.selectedOption : null,
    );

    const handleSubmitClick = data => {
        let name;
        name = changeOption ? `상태 - ${selectedOption.label}` : data.name;
        if (customSettings) {
            dispatch(
                editWidgetSettings({
                    id: widgetInfo.id,
                    settings: { ...customSettings, ...data, ...updatedWidgetInfo, selectedOption, name },
                }),
            );
        } else {
            dispatch(
                editWidgetSettings({
                    id: widgetInfo.id,
                    settings: { ...data, ...updatedWidgetInfo, selectedOption, name },
                }),
            );
        }
    };

    const handleCancelClick = () => {
        setUpdatedWidgetInfo({});
    };
    const options = useMemo(() => {
        return [
            { value: 'category', label: t('Category') },
            { value: 'department', label: t('Department') },
        ];
    }, [t]);

    const handleChange = e => {
        setSelectedOption(e);
        setChangeOption(true);
    };

    useEffect(() => {
        let foundOne;
        if (customSettings && customSettings.selectedOption) {
            foundOne = options.find(option => option.value === customSettings.selectedOption.value);
        }
        setSelectedOption(foundOne);
    }, [customSettings]);

    useEffect(() => {
        if (selectedOption) {
            setSelectedOption(options.find(v => v.value === selectedOption.value));
        }
    }, [options]);

    return (
        <WidgetSettingModal
            headerTitle={t('Status By')}
            widgetInfo={widgetInfo}
            hiddenHeader
            okCallback={handleSubmitClick}
            cancelCallback={handleCancelClick}
            {...restProps}
        >
            <div className="pnt-select--group w-100">
                <Label
                    name={t('Option')}
                    labelGroupClassName={'w-100'}
                    classname={'w-100'}
                    value={
                        <ReactSelect
                            name={'statusByOptions'}
                            options={options}
                            value={selectedOption}
                            onChange={handleChange}
                            className={'w-100'}
                            customOptionStyles={{ width: '100%' }}
                            customControlStyles={{ width: '100%' }}
                            customMenuStyles={{ width: '100%' }}
                        />
                    }
                />
            </div>
        </WidgetSettingModal>
    );
};

export default StatusBySetting;
