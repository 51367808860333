import React, { useEffect, useState, useRef } from 'react';

import cx from 'classnames';

import { useTranslation, useAsync } from '@hooks';
import { Button, Select } from '@components';
import { createPatrolDailyRuleAPI, getPatrolDailyPatroldivAPI, getPatrolDailyRuleAPI } from '@api/military';

import GridLayoutCard from '../../Components/GridLayoutCard';
import WidgetConfirmModal from '../../Components/WidgetConfirmModal';

import styled from 'styled-components';

const PatrolRulesOfDuty = ({ children, widgetInfo, ...restProps }) => {
    const t = useTranslation('Patrol Search');

    const patrolInputRef = useRef('');

    const [inputValue, setInputValue] = useState('');

    const [selectOptions, setSelectOptions] = useState([]);

    const [selectedOption, setSelectedOption] = useState({});

    const [isUpdate, setIsUpdate] = useState(false);

    // 모달 상태들
    const [successModal, setSuccessModal] = useState(false);
    const [failModal, setFailModal] = useState(false);

    useAsync({
        promise: getPatrolDailyPatroldivAPI,
        immediate: true,
        resolve: res => {
            const { rows } = res;
            setSelectOptions(
                rows.map(({ patrolDiv, patrolDivName }) => {
                    return {
                        label: patrolDivName,
                        value: patrolDiv,
                    };
                }),
            );
        },
        reject: err => {
            console.error(err);
        },
    });

    const { promise: createPatrolDailyRule } = useAsync({
        promise: createPatrolDailyRuleAPI,
        resolve: () => {
            toggleSuccessModal();
            getPatrolDailyRule({
                patrolDiv: selectedOption.value,
            });
        },
        reject: err => {
            console.error(err);
        },
    });

    const { promise: getPatrolDailyRule, state: patrolDailyRuleState } = useAsync({
        promise: getPatrolDailyRuleAPI,
        resolve: res => {
            const { rule } = res;
            setInputValue(rule || '');
            setIsUpdate(rule !== null);

            // 새로고침시 inputArea 초기화
            const {
                request: { patrolDiv },
            } = patrolDailyRuleState;
            if (patrolDiv === selectOptions[0].value && rule) {
                patrolInputRef.current = rule;
            }
        },
        reject: err => {
            console.error(err);
        },
    });

    useEffect(() => {
        if (selectedOption.label) {
            getPatrolDailyRule({
                patrolDiv: selectedOption.value,
            });
        }
    }, [selectedOption]);

    const handleInputValue = event => {
        const { value } = event.target;
        setInputValue(value.slice(0, 500));
    };

    const handleSaveClick = () => {
        createPatrolDailyRule({
            rule: inputValue,
            patrolDiv: selectedOption.value,
        });
    };

    const handleRefreshClick = () => {
        if (selectOptions.length) {
            setSelectedOption(selectOptions[0]);
        }
        setInputValue(patrolInputRef.current);
    };

    const toggleSuccessModal = () => {
        setSuccessModal(!successModal);
    };

    const toggleFailModal = () => {
        setFailModal(!failModal);
    };

    useEffect(() => {
        if (selectOptions.length) {
            setSelectedOption(selectOptions[0]);
        }
    }, [selectOptions]);

    return (
        <>
            <GridLayoutCard
                widgetInfo={widgetInfo}
                {...restProps}
                headerAction={
                    <>
                        <Button onClick={handleRefreshClick} className="btn-danger btn-icon-only" iconName="refresh" />
                        <div className="pnt-border border-h" />
                        <Button
                            onClick={handleSaveClick}
                            className={cx(isUpdate ? 'btn-lightgray' : 'btn-secondary', 'btn-icon')}
                            iconName={isUpdate ? 'edit' : 'save'}
                        >
                            {t(isUpdate ? 'Edit' : 'Save')}
                        </Button>
                    </>
                }
            >
                <Select
                    name={'status'}
                    value={selectedOption}
                    options={selectOptions}
                    onChange={selected => {
                        setSelectedOption(selected);
                    }}
                />
                <InputArea value={inputValue} onChange={handleInputValue} className="pnt-input" />
                <span>{`${inputValue.length} / 500`}</span>
                {children}
            </GridLayoutCard>
            <WidgetConfirmModal
                initModal={successModal}
                toggleModal={toggleSuccessModal}
                header={{ title: t('Notification', 'ConfirmModal') }}
                confirmText={t('Your request was successful.')}
                removeCancel
            />
            <WidgetConfirmModal
                initModal={failModal}
                toggleModal={toggleFailModal}
                header={{ title: t('Notification', 'ConfirmModal') }}
                confirmText={t('Your request was failed.')}
                removeCancel
            />
        </>
    );
};

const InputArea = styled.textarea`
    width: 100%;
    height: calc(100% - 4.6rem);
    padding: 0.5rem;
    resize: none;
    margin-top: 0.5rem;
`;

export default PatrolRulesOfDuty;
