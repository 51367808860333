import React, { useEffect, useRef } from 'react';
import { Route } from 'react-router-dom';

import { ErrorHandleSwitch } from '../Components/Router';

import IntegratedMonitor from './IntegratedMonitor';
import DrowningMonitoring from './DrowningMonitoring';

import { useDispatch } from 'react-redux';
import { setTagInfos } from 'reducers/TagInfo';

import useSocket from 'util/socket/hooks/useSocket';

const RENDERING_TIME = 0;

const Monitoring = ({ match }) => {
    const { socket, setSocketEvent } = useSocket();
    const dispatch = useDispatch();

    const socketBuffer = useRef({
        interval: null,
        buffer: [],
    });
    const flushData = () => {
        // current buffer 어레이가 있는 경우에만 실행
        if (socketBuffer.current.buffer.length > 0) {
            dispatch(setTagInfos(socketBuffer.current.buffer.splice(0)));
        }
    };
    const flushDataInterval = () => {
        clearInterval(socketBuffer.current.interval);
        socketBuffer.current.interval = setInterval(() => {
            flushData();
        }, RENDERING_TIME);
    };
    const stopFlushDataInterval = () => {
        flushData();
        clearInterval(socketBuffer.current.interval);
        socketBuffer.current.interval = null;
    };

    useEffect(() => {
        flushDataInterval();
        if (socket) {
            setSocketEvent('updateMarker', function (data) {
                socketBuffer.current.buffer.push(data);
            });
        }

        return () => {
            stopFlushDataInterval();
            if (socket) {
                setSocketEvent('updateMarker');
            }
        };
    }, [socket]);

    return (
        <ErrorHandleSwitch>
            <Route exact path={`${match.path}/integrated`} component={IntegratedMonitor} />
            <Route exact path={`${match.path}/drowning`} component={DrowningMonitoring} />
        </ErrorHandleSwitch>
    );
};

export default Monitoring;
