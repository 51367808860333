import React from 'react';

import { CardHeaderProps } from './type';

import { CompoundCardHeaderContainer, CompoundCardLabel } from './styles';

const CompoundCardHeader = ({
    children,
    label = true,
    title,
    className,
    labelClassName = 'bg-secondary',
}: CardHeaderProps) => {
    return (
        <CompoundCardHeaderContainer className={className} title={title}>
            {label && <CompoundCardLabel className={labelClassName} />}
            {children}
        </CompoundCardHeaderContainer>
    );
};

export default CompoundCardHeader;
