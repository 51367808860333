import React, { useRef, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';

import { fetchFloorList } from '@api/common';
import { fetchExcelLogExport, fetchLocationLogList } from '@api/log';

import { Button, Page, Card, Table } from '@components';
import { useConfirmModal, useTranslation, useColumns, useAsync } from '@hooks';

import Search from './Components/Search';

import { useDispatch } from 'react-redux';
import { setFloorList } from 'reducers/Common/FloorInfo';
import { setSelectedAlertInfo } from 'reducers/Notification';

import * as column from '../../util/grid/column';

const LocationLog = () => {
    const t = useTranslation('Location Log');
    const columnT = useTranslation('CommonColumn');

    const searchRef = useRef();
    const dispatch = useDispatch();
    const match = useRouteMatch();

    useAsync({
        promise: fetchFloorList,
        resolve: response => {
            dispatch(setFloorList(response));
        },
        immediate: true,
        deps: [match.params.menuNum],
    });

    const handleLatLngClick = log => {
        dispatch(setSelectedAlertInfo(log));
    };

    const columns = useColumns([
        column.number(),
        column.targetName(),
        column.deviceName(),
        column.floor(),
        column.location({
            handleLatLngClick,
            t: columnT,
            width: 350,
        }),
        column.registeredDate(),
    ]);

    const [locationLogData, setLocationLogData] = useState({
        totalCount: 0,
        totalPage: 1,
        rows: [],
    });

    const { promise: getLocationLogList, state: locationLogListInfo } = useAsync({
        promise: fetchLocationLogList,
        keepState: true,
        fixedParam: { pageSize: 15 },
        resolve: res => {
            const data = res.rows.map(log => {
                return {
                    logNum: log.logNum,
                    targetName: log.targetName,
                    tagName: log.tagName,
                    floorId: log.floorId,
                    latLng: [log.lat, log.lng],
                    regDate: log.regDate,
                    ...log,
                };
            });

            setLocationLogData({ ...res, rows: data });
        },
    });

    const { promise: createNewLogFile } = useAsync({
        promise: fetchExcelLogExport,
        fixedParam: {
            zipFileName: 'Location_Log',
            fileName: 'Location_Log',
            columnMetas: [
                {
                    key: 'logNum',
                    name: t('No.'),
                },
                {
                    key: 'targetName',
                    name: t('Target Name'),
                },
                {
                    key: 'beaconName',
                    name: t('Device Name'),
                },
                {
                    key: 'floor',
                    name: t('Floor'),
                },
                {
                    key: 'lat',
                    name: t('Latitude'),
                },
                {
                    key: 'lng',
                    name: t('Longitude'),
                },
            ],
        },
        resolve: () => {
            toggleExcelModal();
        },
        reject: err => {
            console.error(err);
        },
    });

    const handlePageChange = pageIndex => {
        if (
            locationLogListInfo.request &&
            (locationLogListInfo.request.keyword ||
                locationLogListInfo.request.startDate ||
                locationLogListInfo.request.endDate)
        ) {
            getLocationLogList({ ...locationLogListInfo.request, page: pageIndex });
        } else {
            getLocationLogList({ page: pageIndex });
        }
    };

    const handleExportFileClick = () => {
        if (!locationLogData.rows || !locationLogData.rows.length) {
            toggleExcelDownloadNoDataModal();
            return;
        }

        const { request } = locationLogListInfo;

        delete request.page;
        delete request.pageSize;

        request.isAll = 'Y';

        createNewLogFile({ ...request });
    };

    const { toggleModal: toggleExcelModal, Modal: ExcelModal } = useConfirmModal({
        initModal: false,
        header: {
            title: t('Excel Download', 'Location Log List'),
        },
        confirmText: t(
            'You can see and download the Excel File that you have requested under `Report > Location Log List` menu.',
            'Location Log List',
        ),
        removeCancel: true,
    });

    const { Modal: ExcelDownloadNoDataModal, toggleModal: toggleExcelDownloadNoDataModal } = useConfirmModal({
        initModal: false,
        header: { title: t('Notification', 'ConfirmModal') },
        confirmText: t('There is no data.', 'ConfirmModal'),
        removeCancel: true,
    });

    return (
        <Page menuPath={[t('Report'), t('Location Log')]} className="h-100">
            <Search getLocationLogList={getLocationLogList} />
            <Card
                innerRef={searchRef}
                className="report-container"
                header={{
                    title: t('Location Log List'),
                    action: (
                        <Button className="btn-secondary" onClick={handleExportFileClick}>
                            {t('Export', 'Button')}
                        </Button>
                    ),
                }}
            >
                <div style={{ height: '33rem' }}>
                    <Table
                        data={{ ...locationLogData, pageSize: 15 }}
                        columns={columns}
                        onPageChange={handlePageChange}
                        loading={locationLogListInfo.isLoading}
                    />
                </div>
            </Card>
            <ExcelModal />
            <ExcelDownloadNoDataModal />
        </Page>
    );
};

export default LocationLog;
