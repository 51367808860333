import React from 'react';

import useTranslation from '@hooks/useTranslation';

import Label from '@components/Label';
import { falsyToHyphen } from '@util/common/util';
import { LoadingBlock } from '@components';

const AssetDetailInformation = ({ assetInfo, assetCategoryPropertyInfo, isLoading }) => {
    const t = useTranslation('Asset Detail Information');

    const { properties, targetNum, targetName, targetId, statusName, flowName, schemeName, categoryNamePath } =
        assetInfo;

    const { picture, jobtitle, rank, operator } = properties || {};

    return (
        <div className="card h-100">
            <div className="card-header">
                <div className="card-header__title">
                    <div className={'title-wrap'}>
                        <h3 className="title__main">{t('Asset Information')}</h3>
                    </div>
                </div>
            </div>
            <div className="card-body">
                <LoadingBlock blocking={isLoading}>
                    <div className="flx-row gap-3">
                        <div className="am__asset-picture">
                            {picture ? (
                                <img src={picture} alt={targetId} style={{ width: '100%', height: '100%' }} />
                            ) : (
                                <span className="icon-picture" />
                            )}
                        </div>
                        <div className="d-grid flx-row gap-2">
                            <Label
                                labelValueClassName="label-dot color-brand"
                                name={t('Number')}
                                value={<>{falsyToHyphen(targetNum)}</>}
                            />
                            <Label
                                labelValueClassName="label-dot color-brand"
                                name={t('Name')}
                                value={<>{falsyToHyphen(targetName)}</>}
                            />
                            <Label
                                labelValueClassName="label-dot color-brand"
                                name={t('ID')}
                                value={<>{falsyToHyphen(targetId)}</>}
                            />
                            <Label
                                labelValueClassName="label-dot color-brand"
                                name={t('Operator')}
                                value={
                                    <>{`${falsyToHyphen(jobtitle)} / ${falsyToHyphen(rank)} / ${falsyToHyphen(
                                        operator,
                                    )}`}</>
                                }
                            />
                            <Label
                                labelValueClassName="label-dot color-brand"
                                name={t('Status')}
                                value={<>{falsyToHyphen(statusName)}</>}
                            />
                        </div>
                    </div>
                    <div className="divider mb-1" />
                    <div className="flx-col gap-3 mt-3">
                        <Label
                            labelValueClassName="label-dot min-w-8rem"
                            name={t('Status flow scheme')}
                            value={<>{falsyToHyphen(flowName)}</>}
                        />
                        <Label
                            labelValueClassName="label-dot min-w-8rem"
                            name={t('Status scheme')}
                            value={<>{falsyToHyphen(schemeName)}</>}
                        />
                        <Label
                            labelValueClassName="label-dot min-w-8rem"
                            name={t('Category')}
                            value={<>{falsyToHyphen(categoryNamePath)}</>}
                        />
                        {assetCategoryPropertyInfo.map(props => (
                            <Label
                                key={props.id}
                                labelValueClassName="label-dot min-w-8rem"
                                name={props.name}
                                value={<>{falsyToHyphen(props.value)}</>}
                            />
                        ))}
                    </div>
                </LoadingBlock>
            </div>
        </div>
    );
};

export default AssetDetailInformation;
