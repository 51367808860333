import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { CustomMenuState, BookMarkedMenu } from './types';

const initialState: CustomMenuState = {
    bookmarkedMenu: [],
};

const { actions, reducer } = createSlice({
    name: 'customMenu',
    initialState,
    reducers: {
        setBookmarkMenu: (state, action: PayloadAction<BookMarkedMenu>) => {
            state.bookmarkedMenu = [...action.payload].sort((a, b) => a.order - b.order);
        },
    },
});

export const { setBookmarkMenu } = actions;
export default reducer;
