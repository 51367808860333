import React, { useMemo, useRef, useState } from 'react';

import { useColumns, useTranslation, useAsync, useConfirmModal } from '../../../../util/hooks';
import { Card, Page, Button, Table } from '../../../Common';

import { fetchExcelLogApi } from '../../../../api/log';
import { getLocationApprovalLogAPI, postLocationApprovalLogExportAPI } from '../../../../api/military';

import Search from './Components/Search';
import { excelExport } from '../util/util';

import * as column from './column';

import moment from 'moment';

const INTERVAL_TIME = 1000;
const PAGE_SIZE = 13;

interface TableData {
    rows: never[];
    page: number;
    totalCount: number;
    totalPage: number;
}

interface ExcelExportResponse {
    comNum: number;
    dataSettingStatusType: string;
    exportLogType: string;
    fileName: string;
    filePath: string;
    regDate: number;
    reqNum: number;
}

const LocationApprovalLog = () => {
    const t = useTranslation('Location Approval Log');

    const excelTimerRef = useRef<ReturnType<typeof setInterval> | null>(null);

    const [tableData, setTableData] = useState({ rows: [], page: 1, totalCount: 0, totalPage: 1, pageSize: PAGE_SIZE });

    const [startDate, setStartDate] = useState(moment().startOf('day').valueOf());
    const [endDate, setEndDate] = useState(moment.now().valueOf());

    const handleDateCallback = ({ selected, type }: { selected: number; type: string }) => {
        switch (type) {
            case 'startDate':
                setStartDate(selected);
                break;
            case 'endDate':
                setEndDate(selected);
                break;
            case 'yesterday':
                const yesterday = moment().subtract(1, 'day');
                setStartDate(yesterday.startOf('day').valueOf());
                setEndDate(yesterday.endOf('day').valueOf());
                break;
            case 'reset':
                setStartDate(moment().startOf('day').valueOf());
                setEndDate(moment.now().valueOf());
                break;
            default:
                break;
        }
    };

    const handleExportFileClick = () => {
        if (!tableData.rows.length) {
            toggleExcelDownloadNoDataModal();
            return;
        }

        const { request } = getLocationApprovalLogState;
        if (request) {
            delete request.page;
            delete request.pageSize;
            request.isAll = 'Y';
            postLocationApprovalLogExport({ ...request });
        }
    };

    const handlePageChange = (page: number) => {
        const { request } = getLocationApprovalLogState;

        if (request) {
            getLocationApprovalLog({ ...request, page });
        } else {
            getLocationApprovalLog({ page });
        }
    };

    const columns = useColumns(
        [column.division(), column.eventDate(), column.approver(), column.authority(), column.registrationDate()],
        t,
    );

    const columnMetas = useMemo(() => {
        return columns.map(({ Header, accessor }) => {
            if (accessor.includes('Date')) {
                return { key: accessor + 'Str', name: Header };
            }
            return { key: accessor, name: Header };
        });
    }, [columns]);

    const { promise: getLocationApprovalLog, state: getLocationApprovalLogState } = useAsync({
        promise: getLocationApprovalLogAPI,
        immediate: true,
        param: {},
        fixedParam: {
            startDate: moment(startDate).unix(),
            endDate: moment(endDate).unix(),
            pageSize: PAGE_SIZE,
        },
        resolve: (res: TableData) => {
            setTableData({ ...tableData, ...res, pageSize: PAGE_SIZE });
        },
        reject: (err: ErrorEvent) => {
            console.error(err);
        },
    });

    const { promise: postLocationApprovalLogExport } = useAsync({
        promise: postLocationApprovalLogExportAPI,
        param: {},
        fixedParam: {
            startDate: moment(startDate).unix(),
            endDate: moment(endDate).unix(),
            zipFileName: 'Location_Approval_Log',
            fileName: 'Location_Approval_Log',
            columnMetas,
        },
        resolve: (res: ExcelExportResponse) => {
            const { filePath: url, reqNum } = res;
            if (url) {
                downloadWithURL(url, reqNum);
            }
        },
        reject: (err: ErrorEvent) => {
            console.error(err);
        },
    });

    const { promise: fetchExcelLog } = useAsync({
        promise: fetchExcelLogApi,
        param: {},
        resolve: (res: ExcelExportResponse) => {
            const { dataSettingStatusType, filePath: url, fileName } = res;
            if (dataSettingStatusType === 'COMPLETED') {
                clearExcelTimer();
                excelExport(url, fileName);
            } else if (dataSettingStatusType === 'FAILED') {
                clearExcelTimer();
                toggleExcelDownloadResultModal();
            }
        },
        reject: (err: ErrorEvent) => {
            console.error(err);
        },
    });

    const downloadWithURL = (url: string, reqNum: number) => {
        clearExcelTimer();
        excelTimerRef.current = setInterval(() => {
            fetchExcelLog({ reqNum });
        }, INTERVAL_TIME);
    };

    const clearExcelTimer = () => {
        if (!!excelTimerRef.current) {
            clearInterval(excelTimerRef.current);
            excelTimerRef.current = null;
        }
    };

    const { Modal: ExcelDownloadResultModal, toggleModal: toggleExcelDownloadResultModal } = useConfirmModal({
        initModal: false,
        header: { title: t('Result', 'ConfirmModal') },
        confirmText: t('Failed to download the excel.', 'ConfirmModal'),
        removeCancel: true,
    });

    const { Modal: ExcelDownloadNoDataModal, toggleModal: toggleExcelDownloadNoDataModal } = useConfirmModal({
        initModal: false,
        header: { title: t('Notification', 'ConfirmModal') },
        confirmText: t('There is no data.', 'ConfirmModal'),
        removeCancel: true,
    });

    return (
        <Page menuPath={[t('Report', 'Menu'), t('Location Approval Log', 'Menu')]}>
            <Search
                startDate={startDate}
                endDate={endDate}
                handleDateCallback={handleDateCallback}
                getLocationApprovalLog={getLocationApprovalLog}
            />
            <Card
                className="report-container"
                header={{
                    title: t('Location Approval Log', 'Menu'),
                    action: (
                        <Button className="btn-brand" onClick={handleExportFileClick}>
                            {t('Export', 'Button')}
                        </Button>
                    ),
                }}
            >
                <div style={{ height: 'calc(100vh - 21rem)', minHeight: '25rem' }}>
                    {/*@ts-ignore*/}
                    <Table
                        data={tableData}
                        columns={columns}
                        onPageChange={handlePageChange}
                        loading={getLocationApprovalLogState.isLoading}
                    />
                </div>
            </Card>
            <ExcelDownloadResultModal />
            <ExcelDownloadNoDataModal />
        </Page>
    );
};

export default LocationApprovalLog;
