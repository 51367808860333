import React, { useEffect, useRef, useState } from 'react';

import Draggable from 'Components/Common/Draggable';
import { Toast, ToastBody, ToastHeader } from 'reactstrap';

import cx from 'classnames';

const DraggableToast = ({
    children,
    initModal = false,
    toggleModal,
    innerRef,
    className,
    headerClassName,
    bodyClassName,
    title,
    bounds = 'parent',
}) => {
    const popupRef = useRef();

    const [position, setPosition] = useState({
        x: 0,
        y: 0,
    });

    useEffect(() => {
        const widgetPosition = getWidgetPosition();
        const popupSize = getPopupSize();

        const x = (widgetPosition.width - (popupSize.width ? popupSize.width : 350)) / 2;
        const y = -widgetPosition.height + 200;

        setPosition({
            x: Math.ceil(x),
            y: Math.ceil(y),
        });
    }, [popupRef.current]);

    const getWidgetPosition = () => {
        const widgetPosition = { x: 0, y: 0 };
        if (innerRef && innerRef.current) {
            const searchContainer = innerRef.current;
            const { offsetWidth: width, offsetHeight: height } = searchContainer;

            widgetPosition.width = width;
            widgetPosition.height = height;
        }
        return widgetPosition;
    };
    const getPopupSize = () => {
        const popupSize = { width: 0, height: 0 };
        if (popupRef.current) {
            const popupContainer = popupRef.current;
            const { offsetWidth: width, offsetHeight: height } = popupContainer;

            popupSize.width = width;
            popupSize.height = height;
        }
        return popupSize;
    };

    return (
        <Draggable
            key={JSON.stringify(position)}
            bounds={bounds}
            defaultPosition={position}
            backgroundMapElement={innerRef && innerRef.current ? innerRef.current.leafletElement : null}
        >
            <Toast isOpen={initModal} className={cx('popup-open', 'toast-z-index', className)} innerRef={popupRef}>
                <ToastHeader toggle={toggleModal} className={headerClassName}>
                    {title}
                </ToastHeader>
                <ToastBody className={bodyClassName}>{children}</ToastBody>
            </Toast>
        </Draggable>
    );
};

export default DraggableToast;
