import React, { useEffect, useMemo, useState } from 'react';
import DropdownTreeSelect from 'react-dropdown-tree-select';
import 'react-dropdown-tree-select/dist/styles.css';
import styled from 'styled-components';
import useTranslation from '../../../../../../../util/hooks/useTranslation';
import { warn } from 'recharts/lib/util/LogUtils';

const TreeSelect = styled(DropdownTreeSelect)`
    .tag-item > .search {
        border-bottom: none;
    }

    .tag-list .tag-item:not(:last-child) {
        display: none;
    }

    .dropdown {
        width: 100%;
    }

    .dropdown-trigger {
        border-radius: 0.25rem;
        background-color: #fff;
        color: #495057;
        width: 100%;

        .tag-item {
            width: 95%;
        }
        border: 1px solid hsl(0, 0%, 90%);
        ${props =>
            props.disabled &&
            `
                background-color: hsl(0,0%,95%);
                cursor: default !important;
            `}
    }
    &.input-warning .dropdown {
        .dropdown-trigger {
            border: 2px solid #ff7800;
        }
    }

    .tag-list .tag-item {
        line-height: 1;
        width: 80%;
    }

    .no-matches {
        display: inline-block;
        margin: 8px 4px;
    }

    .dropdown-trigger.arrow {
        &.bottom:after,
        &.top:after {
            //content: 'ᐯ' !important;
            color: hsl(0, 0%, 80%) !important;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 10px;
        }
    }

    .node.disabled {
        cursor: default;
    }

    .node.disabled > * {
        cursor: default;
    }

    .node.disabled > i {
        cursor: pointer;
        color: #555;
    }

    .node > label {
        width: 95%;
        padding: 0.16rem 0;
    }

    .node > label > input[type='radio'] {
        display: none;
    }

    .dropdown-content {
        min-width: 100%;
        max-height: 300px;
        overflow-y: auto;
    }
`;

function addLeafNode(treeData, dataToAdd, valueKey, parentKey) {
    const parent = dataToAdd[parentKey];
    let find = false;

    // 자식 탐색
    treeData.every(v => {
        if (v[valueKey] === parent) {
            if (v.children) {
                v.children.push(dataToAdd);
            } else {
                v.children = [dataToAdd];
            }
            find = true;
            return false;
        }
        return true;
    });

    // 자식 탐색 실패시 자식의 자식 탐색
    if (!find) {
        treeData.every(v => {
            if (v.children) {
                find = addLeafNode(v.children, dataToAdd, valueKey, parentKey);
                if (find) {
                    return false;
                }
            }
            return true;
        });
    }
    return find;
}
function makeTreeData(treeData = [], flattenData, valueKey, parentKey) {
    const dataToAdd = [...flattenData];
    const restData = [];

    if (treeData.length) {
        while (dataToAdd.length > 0) {
            const curr = dataToAdd.shift();
            const found = addLeafNode(treeData, curr, valueKey, parentKey);
            if (!found) {
                restData.push(curr);
            }
        }
    }

    if (restData.length > 0) {
        makeTreeData(treeData, restData, valueKey, parentKey);
    }
    return treeData;
}

const GroupSingleTreeSelect = ({
    onChange,
    warning,
    disabled,
    originData,
    valueKey = 'groupNum',
    labelKey = 'groupName',
    parentKey = 'parentGroupId',
    childKey = 'groupId',
}) => {
    const t = useTranslation('Status');
    // const [options, setOptions] = useState([]);
    const options = useMemo(() => {
        const data = originData.map(v => ({
            ...v,
            value: v[valueKey],
            label: v[labelKey],
            disabled: false,
        }));

        // setFlattenData(data);

        const treeData = makeTreeData(
            data.filter(v => !v[parentKey]),
            data.filter(v => !!v[parentKey]),
            childKey,
            parentKey,
        );

        return treeData.map(v => {
            if (!v.children) {
                return { ...v, disabled: false };
            } else {
                return { ...v, disabled: true };
            }
        });
    }, [originData]);
    const [selectedLabel, setSelectedLabel] = useState('');

    // useAsync({
    //     promise: getOwnershipListApi,
    //     fixedParam: { isAll: 'Y' },
    //     immediate: true,
    //     resolve: res => {
    //         let { rows } = res;
    //         // console.log(rows);
    //
    //         const data = rows.map(v => ({
    //             ...v,
    //             value: v.groupNum,
    //             label: v.groupName,
    //             disabled: false,
    //         }));
    //
    //         // setFlattenData(data);
    //
    //         const treeData = makeTreeData(
    //             data.filter(v => !v['parentGroupId']),
    //             data.filter(v => !!v['parentGroupId']),
    //             'groupId',
    //             'parentGroupId',
    //         );
    //
    //         setOptions(
    //             treeData.map(v => {
    //                 if (!v.children) {
    //                     return { ...v, disabled: false };
    //                 } else {
    //                     return { ...v, disabled: true };
    //                 }
    //             }),
    //         );
    //     },
    // });

    // 선택된 리스트에 있을 경우에는 옵션에서 제외
    // useEffect(() => {
    //     let data = flattenData;
    //
    //     if (selectedList) {
    //         console.log(selectedList);
    //         data = data.filter(group => {
    //             return !selectedList.find(selected => selected.target.groupId === group.groupId);
    //         });
    //
    //         const treeData = makeTreeData(
    //             data.filter(v => !v['parentGroupId']),
    //             data.filter(v => !!v['parentGroupId']),
    //             'groupId',
    //             'parentGroupId',
    //         );
    //
    //         setOptions(
    //             treeData.map(v => {
    //                 if (!v.children) {
    //                     return { ...v, disabled: false };
    //                 } else {
    //                     return { ...v, disabled: true };
    //                 }
    //             }),
    //         );
    //     }
    // }, [selectedList]);

    const handleSelectedChange = selected => {
        onChange(selected);

        setSelectedLabel(selected.label);
    };

    useEffect(() => {
        setSelectedLabel('');
    }, [originData]);

    return (
        <div className="pnt-select--group group-single-tree-select">
            <TreeSelect
                disabled={disabled}
                className={warning ? 'input-warning' : ''}
                mode={'radioSelect'}
                data={options}
                onChange={handleSelectedChange}
                showPartiallySelected={true}
                keepTreeOnSearch
                texts={{
                    placeholder: selectedLabel || t('Select the group'),
                    noMatches: t('No matches found', 'TreeSelect'),
                }}
            />
        </div>
    );
};

export default GroupSingleTreeSelect;
