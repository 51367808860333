import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';

import { useAsync, useTranslation } from '@hooks';

import { getDashboardMonitoringStatisticsAPI, getDashboardMonitoringTagStatisticsAPI } from '@api/military';

import CompoundCard from 'Components/Widgets/Components/CompoundCard';

import { useSelector } from 'react-redux';
import { IntegratedMonitorStatusContext, IntegratedMonitorDispatchContext } from '../index';
import { setSelectedTag, setTagStatus } from '../IntegratedMonitorReducer';

import { TAG_LABELS, TAG_CLASSNAME, ABNORMAL_LABELS } from '../utils';

import styled from 'styled-components';

const TIMEOUT_TIME = 3 * 1000;

const IntegratedTable = ({ toggleModal }) => {
    const t = useTranslation('Integrated Monitor');

    const timeoutRef = useRef(null);

    const [tagStatusByFloor, setTagStatusByFloor] = useState({});

    const { floorInfo, geofenceList, tagStatus } = useContext(IntegratedMonitorStatusContext);
    const dispatch = useContext(IntegratedMonitorDispatchContext);

    const filteredGeofenceList = useMemo(
        () => geofenceList.filter(({ floor }) => floor === floorInfo.floorId),
        [floorInfo, geofenceList],
    );

    const { tagListByFloor } = useSelector(state => state.TagInfo);
    const { floorList } = useSelector(state => state.FloorInfo);

    useEffect(() => {
        return () => {
            deleteTimeout();
        };
    }, []);

    useEffect(() => {
        if (floorInfo && floorInfo.floorId === 'entire') {
            const result = {};
            Object.entries(tagListByFloor).forEach(([key, value]) => {
                const floor = floorList.find(({ floorId }) => floorId === key);
                if (!!floor && floor.floorName) {
                    result[floor.floorName] = value.length;
                }
            });
            setTagStatusByFloor(result);
        }
    }, [tagListByFloor, floorList, floorInfo]);

    useEffect(() => {
        handleTimeoutCallback();
    }, [floorInfo]);

    const handleTimeoutCallback = () => {
        getDashboardMonitoringTagStatistics();
        if (floorInfo && floorInfo.floorId !== 'entire') {
            getDashboardMonitoringStatistics();
        }
    };

    const deleteTimeout = () => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
            timeoutRef.current = null;
        }
    };

    const createTimeout = () => {
        if (timeoutRef.current) {
            deleteTimeout();
        }
        timeoutRef.current = setTimeout(handleTimeoutCallback, TIMEOUT_TIME);
    };

    // tagStatus의 label을 번역하는 변수
    const translatedTagStatus = useMemo(() => {
        return TAG_LABELS.map(status => {
            const { id, label } = status;
            const value = tagStatus && tagStatus[id] ? tagStatus[id].length : 0;
            return { id, value, label: t(label) };
        });
    }, [t, tagStatus]);

    const translatedSosStatus = useMemo(() => {
        return ABNORMAL_LABELS.map(status => {
            const { id, label } = status;
            const value = tagStatus && tagStatus[id] ? tagStatus[id].length : 0;
            return { id, value, label: t(label) };
        });
    }, [t, tagStatus]);

    const handleClickableTableClick = data => {
        const { id, value } = data;

        if (!value) return;

        toggleModal(true);
        dispatch(setSelectedTag(id));
    };

    const { promise: getDashboardMonitoringTagStatistics } = useAsync({
        promise: getDashboardMonitoringTagStatisticsAPI,
        immediate: true,
        resolve: res => {
            dispatch(setTagStatus(res));
            createTimeout();
        },
        reject: err => {
            console.error(err);
            dispatch(setTagStatus({}));
        },
    });

    const { promise: getDashboardMonitoringStatistics } = useAsync({
        promise: getDashboardMonitoringStatisticsAPI,
        param: { floorId: floorInfo.floorId },
        resolve: res => {
            const { rows } = res;
            const result = {};
            if (rows) {
                rows.forEach(row => {
                    const { fcName, restrictionCnt, permissionCnt } = row;
                    if (fcName) {
                        result[fcName] = `${permissionCnt || 0} / ${restrictionCnt || 0}`;
                    }
                });
            }
            setTagStatusByFloor(result);
            createTimeout();
        },
        reject: err => {
            console.error(err);
            setTagStatusByFloor({});
        },
    });

    return (
        <>
            <StatusTable>
                <CompoundCard>
                    <CompoundCard.header>{`${t('Tag Status')} (${t('Count', 'Common')})`}</CompoundCard.header>
                    <CompoundCard.body>
                        <CompoundCard.body.clickableTable
                            handleClick={handleClickableTableClick}
                            data={translatedTagStatus}
                            bodyClassName={TAG_CLASSNAME}
                        />
                    </CompoundCard.body>
                </CompoundCard>
                <CompoundCard>
                    <CompoundCard.header>{`${t('Abnormal Status')} (${t('Count', 'Common')})`}</CompoundCard.header>
                    <CompoundCard.body>
                        <CompoundCard.body.clickableTable
                            handleClick={handleClickableTableClick}
                            data={translatedSosStatus}
                            bodyClassName={TAG_CLASSNAME}
                        />
                    </CompoundCard.body>
                </CompoundCard>
            </StatusTable>

            <MonitoringTable>
                {/* 지오펜스 전체 */}
                {floorInfo && floorInfo.floorId !== 'entire' && (
                    <CompoundCard key="total">
                        <CompoundCard.header key="total">
                            <ContentsContainer title={`${t('Total')} (${t('Authorized')} / ${t('UnAuthorized')}`}>
                                {`${t('Total')} (${t('Authorized')} / ${t('UnAuthorized')})`}
                            </ContentsContainer>
                        </CompoundCard.header>
                        <CompoundCard.body>
                            <CompoundCard.body.contents>
                                <ContentsContainer title={tagStatusByFloor.total || '0 / 0'}>
                                    {tagStatusByFloor.total || '0 / 0'}
                                </ContentsContainer>
                            </CompoundCard.body.contents>
                        </CompoundCard.body>
                    </CompoundCard>
                )}

                {/* 각 지오펜스 별 */}
                {!!filteredGeofenceList.length && floorInfo && floorInfo.floorId === 'entire' ? (
                    <CompoundCard.body.clickableTable
                        handleClick={handleClickableTableClick}
                        data={filteredGeofenceList.map(({ fcName }) => {
                            return { id: fcName, value: tagStatusByFloor[fcName] || 0, label: fcName };
                        })}
                    />
                ) : (
                    filteredGeofenceList.map(({ fcName }) => {
                        const value = tagStatusByFloor[fcName] || '0 / 0';
                        return (
                            <CompoundCard key={fcName}>
                                <CompoundCard.header key={fcName} title={fcName}>
                                    <ContentsContainer title={fcName}>{fcName}</ContentsContainer>
                                </CompoundCard.header>
                                <CompoundCard.body>
                                    <CompoundCard.body.contents>
                                        <ContentsContainer title={value}>{value}</ContentsContainer>
                                    </CompoundCard.body.contents>
                                </CompoundCard.body>
                            </CompoundCard>
                        );
                    })
                )}
            </MonitoringTable>
        </>
    );
};

const StatusTable = styled.div`
    display: grid;
    width: 100%;
    gap: 0.1rem;
    margin-bottom: 0.1rem;
    @media (min-width: 1124px) {
        grid-template-columns: 7fr 3fr;
    }
`;

const MonitoringTable = styled.div`
    display: grid;
    gap: 0.1rem;
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
`;

const ContentsContainer = styled.div`
    display: block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`;

export default IntegratedTable;
