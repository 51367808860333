import React, { useContext, useEffect, useState } from 'react';

import { useTranslation } from '@hooks';
import { TimePicker, TextInput, Button } from '@components';

import { SelectGroup, FilterList, SearchWrap } from '@components/FilterSearchGroup/Components/Part';
import FilterSearchGroup from '@components/FilterSearchGroup';
import FilterGroup from '@components/FilterSearchGroup/Components/FilterGroup';
import FilterButtonArea from '@components/FilterSearchGroup/Components/FilterButtonArea';

import { useSelector } from 'react-redux';
import { DrowningMonitoringDispatchContext, DrowningMonitoringStatusContext } from '../index';
import { setFloorInfo } from '../DrowningMonitoringReducer';

import moment from 'moment';

import cx from 'classnames';

const Search = ({ getScannerStateLogList }) => {
    const t = useTranslation('Drowning Monitoring');

    const { playerState, play } = useContext(DrowningMonitoringStatusContext);
    const dispatch = useContext(DrowningMonitoringDispatchContext);

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const { floorList } = useSelector(state => state.FloorInfo);

    const [floorInfoState, setFloorInfoState] = useState({ floorId: '' });

    const handleSearchClick = () => {
        if (!getScannerStateLogList) {
            return;
        }

        getScannerStateLogList({
            startDate: startDate ? moment(startDate).unix() : null,
            endDate: endDate ? moment(endDate).unix() : null,
            floorIds: floorInfoState && floorInfoState.floorId ? floorInfoState.floorId : null,
            floorInfoState,
        });
    };

    useEffect(() => {
        if (floorList && floorList.length) {
            const floor = floorList.find(({ floorId }) => floorId === 'pnt_outdoor');
            dispatch(setFloorInfo(floor));
            setFloorInfoState(floor);
        }
    }, [floorList]);

    return (
        <FilterSearchGroup>
            <FilterGroup className="bg-secondary" label={t('Filter', 'Filter')}>
                <FilterList>
                    <TextInput name="selectCategory" value={`${t('Category')}: People`} readOnly disabled />
                    <TextInput name="selectFloor" value={t('Outside Of Ship')} disabled readOnly />
                    <SelectGroup className="datePicker-container">
                        <TimePicker
                            value={startDate}
                            valueType="ms"
                            handleChange={selected => setStartDate(selected)}
                            maxDate={endDate || moment.now()}
                            showTimeInput
                            readOnly={play || playerState === 'real-time'}
                        />
                    </SelectGroup>
                    <div className="text-center">~</div>
                    <SelectGroup className="datePicker-container">
                        <TimePicker
                            value={endDate}
                            valueType="ms"
                            handleChange={selected => setEndDate(selected)}
                            minDate={startDate}
                            maxDate={moment.now()}
                            showTimeInput
                            readOnly={play || playerState === 'real-time'}
                        />
                    </SelectGroup>
                    <Button
                        className={cx(
                            'btn-icon',
                            playerState === 'real-time' || play ? 'btn-lightgray cursor-default' : 'btn-secondary',
                        )}
                        onClick={handleSearchClick}
                        disabled={playerState === 'real-time' || play}
                        iconName="search"
                    >
                        {t('Search', 'Search')}
                    </Button>
                </FilterList>
                <FilterButtonArea />
            </FilterGroup>
        </FilterSearchGroup>
    );
};
export default Search;
