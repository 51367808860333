import React, { memo, useCallback } from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import useToggleLegend from 'Components/Widgets/util/useToggleLegend';
import { CHART_COLORS } from '../config';

const CustomizedAxisTick = memo(({ x, y, stroke, payload, width, visibleTicksCount, ...restProps }) => {
    return (
        <g transform={`translate(${x},${y})`}>
            <foreignObject
                x={0}
                y={0}
                dy={16}
                height={30}
                textAnchor="middle"
                fill="#666"
                style={{ overflow: 'visible' }}
            >
                <div
                    className={'text-ellipsis'}
                    title={payload.value}
                    style={{ transform: 'translate(-50%)', width: width / visibleTicksCount - 5, textAlign: 'center' }}
                >
                    {payload.value}
                </div>
            </foreignObject>
            {/*<text x={0} y={0} dy={16} textAnchor="middle" fill="#666" textLength={width / visibleTicksCount}>*/}
            {/*    {payload.value}*/}
            {/*</text>*/}
        </g>
    );
});
const CustomTooltip =({payload})=>{

    return(
        <div className={'custom-tooltip'}>
            {payload && payload.length ? payload.map(item=>{
                return (
                    <>
                        <span key={item.name}> {item.name} : {item.value} </span><br/>
                    </>
                )
            }) : null}
        </div>
    )
}
const ResponsiveBarChart = memo(({ settings, meta, data }) => {
    const { ToggleLegendRender, filteredData, disable } = useToggleLegend({ data });
    const getColor = useCallback((metaInfo, idx) => {
        return metaInfo.color || CHART_COLORS[idx % CHART_COLORS.length];
    }, []);
    return (
        <ResponsiveContainer width={'100%'}>
            <BarChart
                data={filteredData}
                margin={{
                    right: 40,
                }}
            >
                <XAxis interval={0} tick={<CustomizedAxisTick />} dataKey="name" />
                <YAxis domain={[0, 'dataMax + 1']} allowDecimals={false} />
                <Tooltip
                     content={<CustomTooltip/>}
                />
                <Legend
                    content={ToggleLegendRender}
                    payload={meta.dataSet.map((v, i) => ({
                        value: v.name,
                        id: v.key,
                        color: getColor(v, i),
                    }))}
                />
                {meta.dataSet.map((v, i) => {
                    return !disable[v.key] && <Bar key={v.key} name={v.name} dataKey={v.key} fill={getColor(v, i)} />;
                })}
                {/*<Bar dataKey="unAuthorized" fill="#ff7f0e" />*/}
                {/*<Bar dataKey="authorized" fill="#1f77b4" />*/}
            </BarChart>
        </ResponsiveContainer>
    );
});

export default ResponsiveBarChart;
