import React, { useContext } from 'react';
import { components } from 'react-select';
import { SearchableSelectContext } from '../../../../../../Common/Select/SearchableSelect';
import useTranslation from '@hooks/useTranslation';
import { CustomSearchableSelectDispatchContext, CustomSearchableSelectContext } from './index';
import cx from 'classnames';
import { setEditable } from '../reducer';

export const MenuList = ({ children, getValue, setValue, options, filterEditMode, ...restProps }) => {
    const selected = getValue();
    const t = useTranslation('TreeSelect');
    const filterT = useTranslation('Filter');
    const { valueKey, labelKey } = useContext(SearchableSelectContext);
    const dispatch = useContext(CustomSearchableSelectDispatchContext);
    const { isEditable } = useContext(CustomSearchableSelectContext);
    const handleChange = e => {
        const { checked } = e.currentTarget;
        setValue(checked ? options : []);
    };

    return (
        <components.MenuList {...restProps}>
            <div className={'mb-2'}>
                <div className={'select-group-title'}>{t('Selected')}</div>
                {selected.length ? (
                    selected.map(v => (
                        <div
                            key={v[valueKey]}
                            className={cx(
                                'styled-option',
                                isEditable !== 'Y' && !filterEditMode && 'disabled-select-option',
                            )}
                        >
                            <div
                                onClick={e => {
                                    setValue(
                                        selected.filter(selectedOption => selectedOption[valueKey] !== v[valueKey]),
                                    );
                                }}
                            >
                                <label className={'text-ellipsis w-100 mb-0'} title={v[labelKey]}>
                                    <input type="checkbox" value={v[valueKey]} checked onChange={e => null} />
                                    {v[labelKey]}
                                </label>
                            </div>
                        </div>
                    ))
                ) : (
                    <div className={'styled-option-label'}>{t('Not Selected')}</div>
                )}
            </div>
            <div>
                <div
                    className={cx(
                        'select-group-title py-0',
                        isEditable !== 'Y' && !filterEditMode && 'disabled-select-option',
                    )}
                >
                    <label className={'text-ellipsis mb-0'} title="All Items">
                        <input type="checkbox" onChange={handleChange} checked={selected.length === options.length} />
                        {t('All')}
                    </label>
                </div>
                {children}
            </div>
            <div className={cx(!filterEditMode && 'disabled-option')}>
                <div className={'select-group-title'}>{filterT('Option')}</div>
                <div className={'styled-option'}>
                    <label className={'text-ellipsis'} title={filterT('Permission to change')}>
                        <input
                            type="checkbox"
                            checked={isEditable === 'Y'}
                            onChange={e => {
                                dispatch(setEditable(e.currentTarget.checked ? 'Y' : 'N'));
                            }}
                        />
                        {filterT('Permission to change')}
                    </label>
                </div>
            </div>
        </components.MenuList>
    );
};

export const Option = function ({ label, isSelected, filterEditMode, ...restProps }) {
    const { isEditable } = useContext(CustomSearchableSelectContext);
    return (
        <div className={cx('select-option', isEditable !== 'Y' && !filterEditMode && 'disabled-select-option')}>
            <components.Option {...restProps}>
                <label className={'text-ellipsis mb-0 select-label w-100'} title={label}>
                    <input type="checkbox" checked={isSelected} onChange={e => null} />
                    {label}
                </label>
                {/*<label style={{ marginBottom: '4px' }}>{label}</label>*/}
            </components.Option>
        </div>
    );
};
