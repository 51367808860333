import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    assetList: {
        rows: [],
        totalPage: 1,
        totalCount: 0,
    },
    addedAssetList: {
        rows: [],
        totalPage: 1,
        totalCount: 0,
        pageSize: 20,
    },
    selectedAssetRentalProcess: null,
    checkedInAssetCount: null,
};

const calculatePageSize = state => {
    if (state.addedAssetList.rows.length % state.addedAssetList.pageSize === 0) {
        state.addedAssetList.totalPage = Math.trunc(state.addedAssetList.rows.length / state.addedAssetList.pageSize);
    } else {
        state.addedAssetList.totalPage =
            Math.trunc(state.addedAssetList.rows.length / state.addedAssetList.pageSize) + 1;
    }
};

const { actions, reducer } = createSlice({
    name: 'assetRentalProcess',
    initialState,
    reducers: {
        setAssetList: (state, action) => {
            state.assetList = {
                ...action.payload,
                rows: action.payload.rows.map(asset => {
                    return { ...asset, type: 'loanable' };
                }),
            };
        },
        addLoanableAsset: (state, action) => {
            state.addedAssetList.rows.push({ ...action.payload, type: 'selected' });
            state.addedAssetList.totalCount += 1;
            calculatePageSize(state);
        },
        deleteLoanableAsset: (state, action) => {
            const index = state.addedAssetList.rows.findIndex(asset => asset.targetNum === action.payload.targetNum);
            state.addedAssetList.rows.splice(index, 1);
            state.addedAssetList.totalCount -= 1;
            calculatePageSize(state);
        },
        setSelectedAssetRentalProcess: (state, action) => {
            state.selectedAssetRentalProcess = action.payload;
        },
        setBorrowedAsset: (state, action) => {
            const rows = action.payload.rows.map(asset => {
                return { ...asset, type: 'selected' };
            });
            state.addedAssetList = { ...action.payload, rows: rows, pageSize: 20 };
            state.checkedInAssetCount = action.payload.rows.filter(v => v.status === 'check_in').length;

            calculatePageSize(state);
        },
    },
});

export const { setAssetList, addLoanableAsset, deleteLoanableAsset, setSelectedAssetRentalProcess, setBorrowedAsset } =
    actions;
export default reducer;
