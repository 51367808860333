import React, { forwardRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import cx from 'classnames';
import { Card, CardBody, CardHeader } from 'reactstrap';

import { Button, ConfirmModal } from '@components';
import useTranslation from '@hooks/useTranslation';
import { useSettings } from '../util/useSettings';
import { getUsableWidgetInfo } from 'Components/MainPages/Dashboards/util';

import { deleteWidget } from 'reducers/Dashboards/DashboardEdit';
import { SCREEN_MODE_EDIT } from 'reducers/Dashboards/DashboardFrame';

const HeaderButton = ({ children, ...restProps }) => {
    return (
        <Button className="btn-lightgray btn-icon-only ms-2" iconName="menu" {...restProps}>
            {children}
        </Button>
    );
};

const GridLayoutCard = (
    {
        subTitle,
        children,
        selectedElement = false,
        handleGoBackClick,
        searchFilter,
        widgetInfo,
        headerAction,
        select,
        bodyClassName,
        className,
        ...restProps
    },
    ref,
) => {
    children = [...children];
    const { id, type, config, name } = widgetInfo;
    const t = useTranslation('Widget');
    const resizeHandler = children.pop();
    const { settings: customSettings } = config;
    const settings = useSettings(config);
    const { SettingComponent } = getUsableWidgetInfo(type);
    const dispatch = useDispatch();
    const { mode } = useSelector(state => state.DashboardFrame);

    const [deleteModal, setDeleteModal] = useState(false);
    const toggleDeleteModal = () => {
        setDeleteModal(!deleteModal);
    };
    const handleDelete = e => {
        dispatch(deleteWidget(id));
    };

    const [settingModal, setSettingModal] = useState(false);
    const toggleSettingModal = () => {
        setSettingModal(!settingModal);
    };

    const displayName = () => {
        const { name: settingName } = settings;
        if (customSettings && customSettings.name && customSettings.name !== t(settingName)) {
            return customSettings.name;
        } else {
            return type !== 'temp' ? t(name) : t('New widget');
        }
    };

    return (
        <>
            <Card {...restProps} className={cx(className, 'widget-card')} innerRef={ref}>
                <CardHeader
                    className={cx(
                        'widget-header',
                        mode !== SCREEN_MODE_EDIT && settings.hiddenHeader && 'hidden-header',
                        !!headerAction && 'action-header',
                    )}
                >
                    <div className={'card-header__title'}>
                        <div className={'title-wrap'}>
                            <h3 className={'title__main'}>{displayName()}</h3>
                            <p className={'title__sub'}>{subTitle}</p>
                        </div>
                    </div>
                    {(headerAction || handleGoBackClick) && (
                        <div className={'card-header__function'}>
                            {headerAction}
                            {handleGoBackClick && (
                                <Button className={'btn-brand'} onClick={handleGoBackClick}>
                                    {t('Back', 'Button')}
                                </Button>
                            )}
                        </div>
                    )}
                    {mode === SCREEN_MODE_EDIT && (
                        <div className={'widget-edit-btn'}>
                            {SettingComponent && <HeaderButton onClick={toggleSettingModal} />}
                            <Button
                                className="btn-lightgray btn-icon-only ms-2"
                                iconClassName="icon-close"
                                iconName="icon-close"
                                onClick={toggleDeleteModal}
                            />
                        </div>
                    )}
                </CardHeader>
                <div className={'widget-search'}>{searchFilter}</div>
                <CardBody className={bodyClassName}>{children}</CardBody>
                {resizeHandler}
            </Card>
            {mode === SCREEN_MODE_EDIT && (
                <ConfirmModal
                    initModal={deleteModal}
                    toggleModal={toggleDeleteModal}
                    header={{ title: t('Confirm', 'ConfirmModal') }}
                    confirmText={t('Do you want to delete the widget?')}
                    okCallback={handleDelete}
                />
            )}
            {SettingComponent && (
                <SettingComponent widgetInfo={widgetInfo} modal={settingModal} toggleModal={toggleSettingModal} />
            )}
        </>
    );
};

export default forwardRef(GridLayoutCard);
