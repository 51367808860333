import React, { useState, useEffect, useCallback } from 'react';
import { ReactTableDefaults } from 'react-table';
import Card from '../../Components/WidgetCard';
import _ from 'lodash';

import { WindowDataGrid } from '@components/DataGrid/DataGrid';
import { useSelector } from 'react-redux';
import { useSettings } from '../../util/useSettings';

const columns = [
    {
        className: 'flex-center',
        expander: true,
        Expander: ({ isExpanded, original }) => {
            if (original.subRows.length > 0) {
                return <ReactTableDefaults.ExpanderComponent isExpanded={isExpanded} />;
            }
            return null;
        },
    },
    {
        Header: '층 이름',
        className: 'px-3 f-3-p',
        accessor: 'floorName',
        headerClassName: 'f-3-p',
        Cell: ({ value, nestingPath }) => {
            return (
                <span>
                    <strong>{Array.from({ length: nestingPath.length - 1 }, () => '- ').join('')}</strong>
                    {value}
                </span>
            );
        },
    },
    {
        Header: '인가',
        className: 'flex-center f-1-p',
        accessor: 'authorized',
        headerClassName: 'f-1-p',
    },
    {
        Header: '비인가',
        className: 'flex-center f-1-p',
        accessor: 'unAuthorized',
        headerClassName: 'f-1-p',
    },
];

// const findNode = (path, node) => {
//     if (path.length > 1) {
//         const currPath = path.shift();
//         return findNode(
//             path,
//             node.subRows.find(node => node.floorId === currPath),
//         );
//     } else {
//         return node;
//     }
// };
// const findParentFloorId = (floorList, floorId) => {
//     const thisNode = floorList.find(v => v.floorId === floorId);
//     return thisNode ? thisNode.upperFloorId : null;
// };
// const findParentNode = (floorList, floorId) => {
//     const parentFloorId = findParentFloorId(floorList, floorId);
//     return parentFloorId ? floorList.find(v => v.floorId === parentFloorId) : null;
// };

// const findAncestors = (floorList, upperFloorId, parents = []) => {
//     const node = floorList.find(v => v.floorId === upperFloorId);
//     if (node) {
//         parents.push(node);
//         if (node.upperFloorId) {
//             findAncestors(floorList, node.upperFloorId, parents);
//         }
//     }
//     return parents;
// };

const findChildren = (floorList, floorId, children = []) => {
    children.push(floorList.find(v => v.floorId === floorId));
    const nodes = floorList.filter(v => v.upperFloorId === floorId);
    children.concat(nodes);
    nodes.forEach(node => {
        findChildren(floorList, node.floorId, children);
    });
    return children;
};

const findParentNodeSubRows = (path, searchList) => {
    if (path.length > 1) {
        let currPath = path.shift();
        const currNode = searchList.find(node => node.floorId === currPath);
        const nextSearchList = currNode ? currNode.subRows : searchList;
        return findParentNodeSubRows(path, nextSearchList);
    } else {
        return searchList;
    }
};

const makeHierarchyFloorInfo = floorInfo => {
    const hierarchyFloorInfo = floorInfo.reduce((acc, floor) => {
        const expandableFloor = { authorized: 0, unAuthorized: 0, ...floor, subRows: [] };
        if (floor.levelNo === 0) {
            acc.push(expandableFloor);
        } else {
            const path = floor.floorIdPath.split('>');
            const subRows = findParentNodeSubRows(path, acc);
            if (subRows) {
                subRows.push(expandableFloor);
            } else {
                acc.push(expandableFloor);
            }
        }
        return acc;
    }, []);

    return hierarchyFloorInfo;
};
const setParentsAuthCnt = (node, floorList, authInfo) => {
    if (node) {
        node.authorized = node.authorized || 0;
        node.unAuthorized = node.unAuthorized || 0;
        node.authorized += authInfo.authorized;
        node.unAuthorized += authInfo.unAuthorized;
        if (node.upperFloorId) {
            setParentsAuthCnt(
                floorList.find(v => v.floorId === node.upperFloorId),
                floorList,
                authInfo,
            );
        }
    }
};

const FloorSummary = ({ children, widgetInfo, ...restProps }) => {
    const { config } = widgetInfo;
    const settings = useSettings(config);
    const { floorList } = useSelector(state => state.FloorInfo);
    const { floorInOutState } = useSelector(state => state.TagInfo);
    const [filteredFloorList, setFilteredFloorList] = useState([]);
    const [expanded, setExpanded] = useState({});
    const [floorInfoList, setFloorInfoList] = useState([]);

    const getTrProps = useCallback((state, rowInfo, column) => {
        return {
            onMouseDown: e => {
                e.stopPropagation();
            },
            onClick: e => {},
        };
    }, []);

    const handleExpand = newExpanded => {
        setExpanded(newExpanded);
    };

    useEffect(() => {
        if (floorList.length) {
            const selectedFloor = settings.floor ? settings.floor.split(',') : [];
            let tempFilteredFloorList = [];
            if (selectedFloor.length > 0) {
                selectedFloor.forEach(floorId => {
                    const childSubRows = findChildren(floorList, floorId);
                    tempFilteredFloorList = tempFilteredFloorList.concat(childSubRows);
                    // childSubRows.forEach(node => {
                    //     if (!tempFilteredFloorList.find(v => v.nodeId === node.nodeId)) {
                    //         tempFilteredFloorList.push(node);
                    //     }
                    // });
                });
                tempFilteredFloorList.sort((a, b) => a.levelNo - b.levelNo);
            } else {
                tempFilteredFloorList = floorList;
            }
            setFilteredFloorList(tempFilteredFloorList);
            setFloorInfoList(makeHierarchyFloorInfo(tempFilteredFloorList));
        }
    }, [floorList]);

    useEffect(() => {
        if (filteredFloorList.length) {
            const floorList = _.cloneDeep(filteredFloorList);
            for (let nodeId in floorInOutState) {
                const inList = floorInOutState[nodeId];
                const authorizedCnt = inList.authorized.length;
                const unAuthorizedCnt = inList.unAuthorized.length;
                if (authorizedCnt || unAuthorizedCnt) {
                    const thisNode = floorList.find(v => v.nodeId === nodeId);
                    setParentsAuthCnt(thisNode, floorList, {
                        authorized: authorizedCnt,
                        unAuthorized: unAuthorizedCnt,
                    });
                }
            }
            setFloorInfoList(makeHierarchyFloorInfo(floorList));
        }
    }, [floorInOutState]);

    return (
        <>
            <Card widgetInfo={widgetInfo} {...restProps}>
                <WindowDataGrid
                    subRowsKey={'subRows'}
                    // clickableRow={true}
                    columns={columns}
                    data={floorInfoList}
                    // defaultPageSize={tempData.length}
                    pageSize={floorInfoList.length}
                    showPagination={false}
                    style={{ height: '100%' }}
                    getTrProps={getTrProps}
                    expanded={expanded}
                    onExpandedChange={handleExpand}
                />
                {children}
            </Card>
        </>
    );
};

export default FloorSummary;
