import React, { useMemo } from 'react';
import SearchableSelect from 'Components/Common/Select/SearchableSelect';
import useTranslation from 'util/hooks/useTranslation';

const ApplicationStatusSelect = ({ onChange, selectedOption }) => {
    const t = useTranslation('AssetRentalRequest');

    const options = useMemo(() => {
        return [
            { value: 'APPLY', label: t('Application') },
            { value: 'CANCEL', label: t('Cancel') },
            { value: 'REFUSAL', label: t('Refusal') },
            { value: 'APPROVE', label: t('Approval') },
            { value: 'RENTAL', label: t('Occupied') },
            { value: 'RETURN', label: t('Return') },
        ];
    }, [t]);

    const handleSelectedOptionChange = selected => {
        onChange(selected.map(v => v.value));
    };

    return (
        <SearchableSelect
            onChange={handleSelectedOptionChange}
            data={options}
            selected={selectedOption}
            title={t('Application Status')}
        />
    );
};

export default ApplicationStatusSelect;
