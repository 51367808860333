import React, { useMemo } from 'react';

import useTranslation from '@hooks/useTranslation';

import CompoundCard from '../../../Components/CompoundCard';

const tableLabels = [
    { id: 'embarkation', label: 'Embarkation' },
    { id: 'businessTrip', label: 'Business Trip' },
    { id: 'vacation', label: 'Vacation' },
    { id: 'goingOut', label: 'Going Out' },
    { id: 'stayOutOvernight', label: 'Stay Out Overnight' },
    { id: 'dispatch', label: 'Dispatch' },
    { id: 'training', label: 'Training' },
    { id: 'entry', label: 'Entry' },
    { id: 'onDuty', label: 'On Duty' },
    { id: 'offDuty', label: 'Off Duty' },
    { id: 'work', label: 'Work' },
    { id: 'etc', label: 'Etc' },
];

const AbsenceStatus = ({ data, selected, handleClick }) => {
    const t = useTranslation('Smart Watch Health Monitoring');

    // absenceStatus label을 번역하는 변수
    const translatedClickableTableHeader = useMemo(() => {
        return tableLabels.map(({ id, label }) => {
            return { id, value: data[id] || 0, label: t(label) };
        });
    }, [t, data]);

    const handleClickableTableClick = data => {
        const { value } = data;

        if (!value) return;

        if (typeof handleClick === 'function') {
            handleClick(data);
        }
    };

    return (
        <CompoundCard className="mb-1">
            <CompoundCard.header>{t('Absence Status')}</CompoundCard.header>
            <CompoundCard.body>
                <CompoundCard.body.clickableTable
                    clicked={selected && selected.id}
                    handleClick={handleClickableTableClick}
                    data={translatedClickableTableHeader}
                />
            </CompoundCard.body>
        </CompoundCard>
    );
};

export default AbsenceStatus;
