import React, { useMemo } from 'react';
import { useLeaflet, Marker } from 'react-leaflet';
import DriftMarker from 'react-leaflet-drift-marker';
import L from 'leaflet';
import { useSelector } from 'react-redux';

const divIcon = L.divIcon;

// 마커 랜더링 여부 체크
// 리턴 boolean 값에 따라 마커 랜더링 여부 결정
// false: 다른 컴포넌트로 인식 => 랜더링 필요, true: 같은 컴포넌트로 인식 => 랜더링 필요 X
const checkLoc = (
    { className: preClassName, targetInfo: preTargetInfo, zoom: preZoom },
    { className, targetInfo, zoom },
) => {
    // rssi : 파장 색깔
    // lat, lng : 위치
    const { location: preLocation } = preTargetInfo;
    const { location } = targetInfo;
    return !(
        preClassName !== className ||
        preTargetInfo.scannerRssi !== targetInfo.scannerRssi ||
        preLocation.lat !== location.lat ||
        preLocation.lng !== location.lng ||
        preZoom !== zoom
    );
};

const AniMarker = ({ targetInfo, className: containerClassName, children, ...restProps }) => {
    const { map } = useLeaflet();
    const zoom = map.getZoom();
    const { markerConfig, markerConfigValue } = useSelector(state => state.AppConfig);
    const { categoryToImg } = useSelector(state => state.CategoryInfo);

    const icon = useMemo(() => {
        const { className, defaultSize, minSize, maxSize } = markerConfigValue;
        const { target, scannerRssi } = targetInfo;
        let pulseClassName = '';
        let inLineStyle = '';
        if (markerConfig.markerPulseActive === 'Y') {
            pulseClassName = className;
            if (scannerRssi !== null && scannerRssi >= markerConfig.markerPulseRssi) {
                pulseClassName += ' rssi';
                inLineStyle = `style="color: ${markerConfig.markerPulseColor}"`;
            }
        }
        let imgStr = `<div class="marker-img replace-img ${pulseClassName}" ${inLineStyle}><div></div></div>`;
        if (categoryToImg[target.categoryCode]) {
            imgStr = `<img class="marker-img ${pulseClassName}" alt="markerCategoryImage" src="${
                categoryToImg[target.categoryCode]
            }" ${inLineStyle}>`;
        }

        let size = defaultSize * Math.pow(1 / 2, 18 - zoom);
        size = Math.min(Math.max(size, minSize), maxSize);

        return divIcon({
            className: 'category-marker ' + (containerClassName ? containerClassName : ''),
            html: `${imgStr}<div class="marker-label">${target.targetName}</div>`,
            iconSize: [size, size],
            iconAnchor: [size / 2, size],
        });
    }, [markerConfig, markerConfigValue, categoryToImg, targetInfo, containerClassName, zoom]);

    return (
        <>
            {markerConfigValue.moveTransitionTime ? (
                <DriftMarker
                    position={targetInfo.location.latLng}
                    duration={markerConfigValue.moveTransitionTime}
                    icon={icon}
                    {...restProps}
                >
                    {children}
                </DriftMarker>
            ) : (
                <Marker position={targetInfo.location.latLng} icon={icon} {...restProps}>
                    {children}
                </Marker>
            )}
        </>
    );
};

export default React.memo(AniMarker, checkLoc);
