import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Card, Label, SearchableSelect, ToggleButton } from '@components';
import { FilterDetailDispatchContext, FilterDetailStateContext } from '../index';
import { IoIosPeople, IoIosPerson } from 'react-icons/io';
import { useSelector, useDispatch } from 'react-redux';
import { setEditMode } from '../reducer';
import { useConfirmModal, useAsync, useTranslation } from '@hooks';
import { deleteFilterApi, getFilterListApi } from 'api/filter';
import { FilterStateContext } from '../../index';
import { getAuthorityGroup } from '../../reducer';
import FilterGenerator from '../../FilterWidget/FilterGenerator';
import { setUpdatedFilterList } from 'reducers/FilterInfo';
import { Button } from '@components';

const AuthNamePlate = ({ auth }) => {
    const t = useTranslation('Filter');
    return (
        <li key={`${auth.section}_${auth.id}`} className={'me-3'}>
            <div className="pnt-nameplate ps-2">
                {auth.section === 'user' ? (
                    <>
                        <IoIosPerson className={'transform-scale-bigger'} />
                        <span className={'ms-2'}>{auth.name}</span>
                    </>
                ) : (
                    <>
                        <IoIosPeople className={'transform-scale-bigger'} />
                        <span className={'ms-2'}>{auth.id === -1 ? t(auth.name) : auth.name}</span>
                    </>
                )}
            </div>
        </li>
    );
};

const ShowView = () => {
    const t = useTranslation('Filter');
    const buttonT = useTranslation('Button');
    const history = useHistory();
    const dispatch = useContext(FilterDetailDispatchContext);
    const storeDispatch = useDispatch();
    const { origin } = useContext(FilterDetailStateContext);
    const { authority } = useContext(FilterStateContext);
    const { userInfo } = useSelector(state => state.UserInfo);
    const { topCategoryList } = useSelector(state => state.CategoryInfo);
    const [writePermission, setWritePermission] = useState(false);
    const [authInfo, setAuthInfo] = useState({ owner: [], accessRight: [] });

    const { promise: getFilterList } = useAsync({
        promise: getFilterListApi,
        fixedParam: { isAll: 'Y' },
        resolve: ({ rows }) => {
            storeDispatch(setUpdatedFilterList(rows || []));
            history.replace('/filter/list');
        },
        reject: err => {
            console.error(err);
        },
    });

    const { promise: deleteFilter } = useAsync({
        promise: deleteFilterApi,
        fixedParam: { filterNum: origin.filterNum },
        resolve: () => {
            getFilterList();
        },
        deps: [origin],
    });

    const { Modal: DeleteFilterModal, toggleModal: toggleDeleteFilterModal } = useConfirmModal({
        confirmText: t('Do you want to delete filter?'),
        okCallback: () => {
            deleteFilter();
        },
    });

    useEffect(() => {
        if (origin.filterInfoAccessRight && userInfo) {
            setWritePermission(
                origin.filterInfoAccessRight.some(filter => {
                    let permission = false;
                    if (
                        filter.accessScope === 'W' &&
                        (filter.userNum === -1 ||
                            filter.groupNum === -1 ||
                            filter.userNum === userInfo.userNum ||
                            userInfo.groupNums.includes(filter.groupNum))
                    ) {
                        permission = true;
                    }
                    return permission;
                }),
            );
        }
    }, [origin, userInfo]);

    useEffect(() => {
        if (origin.filterInfoAccessRight && authority) {
            const { accessRight, owner } = getAuthorityGroup(authority, origin);
            setAuthInfo({ accessRight, owner });
        }
    }, [origin, authority]);

    return (
        <>
            <Card
                header={{
                    title: `${t('Filter')} - ${t('View')}`,
                    titleIcon: 'icon-info',
                    action: (
                        <>
                            <Button className="btn-gray btn-back me-1" onClick={() => history.goBack()}>
                                {buttonT('Back')}
                            </Button>
                            <div className="pnt-border border-h" />
                            <Button
                                className="btn-danger"
                                onClick={toggleDeleteFilterModal}
                                disabled={!writePermission}
                            >
                                {buttonT('Delete')}
                            </Button>
                            <Button
                                className="btn-icon btn-secondary"
                                onClick={() => {
                                    dispatch(setEditMode(true));
                                }}
                                iconName="border_color"
                                disabled={!writePermission}
                            >
                                {buttonT('Edit')}
                            </Button>
                        </>
                    ),
                }}
                bodyClassName={'overflow-visible'}
            >
                <>
                    <div className={'d-flex mb-3 pnt-label--group'}>
                        <Label
                            name={t('Name')}
                            labelValueClassName={'label-dot label-must me-5'}
                            value={origin.filterName}
                        />
                    </div>
                    <div className={'d-flex mb-3 pnt-label--group'}>
                        <Label
                            name={t('Description')}
                            labelValueClassName={'label-dot me-5'}
                            value={origin.description}
                        />
                    </div>
                    <div className={'d-flex pnt-label--group'}>
                        <Label name={t('Menu')} labelValueClassName="label-dot me-5" />
                        <ToggleButton checked={origin.isApplyMenu === 'Y'} disabled />
                        {origin.isApplyMenu === 'Y' ? (
                            <span className={'pnt-txt txt-tiny ms-3'}>{t('Marking as a asset menu')}</span>
                        ) : (
                            <span className={'pnt-txt txt-tiny ms-3'}>{t('Not marking as a asset menu')}</span>
                        )}
                    </div>
                    <div className="pnt-border border-w" />
                    <div className="d-flex">
                        <Label
                            name={t('Filter Type')}
                            infoBoxValue={t(
                                'If more than one filter type is selected, category property filters are disabled and selected category property filters are deleted.',
                            )}
                            labelValueClassName="label-dot label-info me-5 text-nowrap"
                        />
                        <SearchableSelect
                            title={t('Category')}
                            data={topCategoryList}
                            selected={(origin.categoryCodes ? origin.categoryCodes : '').split(',')}
                            disabled
                        />
                    </div>
                    <div className="pnt-border border-w" />
                    <div className="d-flex">
                        <Label name={t('Filter setting')} labelValueClassName="label-dot me-5" />
                        <div className="d-flex justify-content-between w-100 ms-2">
                            <div className={'d-flex flex-wrap'} style={{ gap: '0.5rem' }}>
                                <FilterGenerator filterInfo={origin} filterEditMode={false} />
                            </div>
                        </div>
                    </div>
                    <div className="pnt-border border-w" />
                    <div className="d-flex pnt-label--group">
                        <Label name={t('Owner')} labelValueClassName="label-dot me-5" />
                        <ul className="d-flex flex-wrap my-2">
                            {authInfo.owner.map(auth => (
                                <AuthNamePlate key={`${auth.section}_${auth.id}`} auth={auth} />
                            ))}
                        </ul>
                    </div>
                    <div className="pnt-border border-w" />
                    <div className="d-flex pnt-label--group">
                        <Label name={t('Access Right')} labelValueClassName="label-dot me-5" />
                        <ul className="d-flex flex-wrap my-2">
                            {authInfo.accessRight.map(auth => (
                                <AuthNamePlate key={`${auth.section}_${auth.id}`} auth={auth} />
                            ))}
                        </ul>
                    </div>
                </>
            </Card>
            <DeleteFilterModal />
        </>
    );
};

export default ShowView;
