import React, { createContext, useEffect, useReducer, useState } from 'react';

import { useAsync, useTranslation } from '@hooks';
import { Button } from '@components';

import GridLayoutCard from '../../Components/GridLayoutCard';
import WidgetConfirmModal from '../../Components/WidgetConfirmModal';

import HealthCareMonitoringReducer, {
    initialState,
    initialize,
    setHealthCareList,
    setSelectedTarget,
    setIsHealthCareListLoading,
} from './healthCareMonitoringReducer';

import HealthInformationMonitoringTable from './Components/HealthInformationMonitoringTable';
import HealthCareDetails from './Components/HealthCareDetails';
import Search from './Components/Search';

import { getHealthCareListAPI } from '@api/military';

export const HealthCareMonitoringStatusContext = createContext(null);
export const HealthCareMonitoringDispatchContext = createContext();

const HealthCareMonitoring = ({ children, widgetInfo, ...restProps }) => {
    const t = useTranslation('Health Care Monitoring');

    const [state, dispatch] = useReducer(HealthCareMonitoringReducer, initialState);
    const { isHealthCareListLoading } = state;

    const [showSearchArea, setShowSearchArea] = useState(false);
    const toggleSearchArea = () => {
        setShowSearchArea(!showSearchArea);
    };

    const handleSearchClick = () => {
        setShowSearchArea(false);
        dispatch(setSelectedTarget(null));
    };

    const handleRefreshClick = () => {
        dispatch(initialize());
        getHealthCareList();
    };

    const [refreshModal, setRefreshModal] = useState(false);
    const handleRefreshModal = () => {
        setRefreshModal(!refreshModal);
        setShowSearchArea(false);
    };

    const { promise: getHealthCareList, state: healthCareListState } = useAsync({
        promise: getHealthCareListAPI,
        fixedParam: { isAll: 'Y' },
        resolve: res => {
            const { rows } = res;
            if (rows) {
                dispatch(setHealthCareList({ rows }));
            }
        },
        reject: err => {
            console.error(err);
        },
    });

    useEffect(() => {
        dispatch(setIsHealthCareListLoading(healthCareListState.isLoading));
    }, [healthCareListState.isLoading]);

    return (
        <HealthCareMonitoringDispatchContext.Provider value={dispatch}>
            <HealthCareMonitoringStatusContext.Provider value={state}>
                <GridLayoutCard
                    widgetInfo={widgetInfo}
                    {...restProps}
                    headerAction={
                        <>
                            <Button
                                className="btn-danger btn-icon-only"
                                iconName="refresh"
                                onClick={handleRefreshModal}
                                disabled={isHealthCareListLoading}
                            />
                            <div className="pnt-border border-h" />
                            <Button
                                className="btn-secondary"
                                onClick={toggleSearchArea}
                                disabled={isHealthCareListLoading}
                            >
                                {t('Search', 'Button')}
                            </Button>
                        </>
                    }
                    searchFilter={showSearchArea && <Search handleSearchClick={handleSearchClick} />}
                >
                    <HealthInformationMonitoringTable />
                    <HealthCareDetails />
                    {children}
                </GridLayoutCard>
                <WidgetConfirmModal
                    initModal={refreshModal}
                    toggleModal={handleRefreshModal}
                    okCallback={handleRefreshClick}
                    header={{ title: t('Notification', 'ConfirmModal') }}
                    confirmText={t('Are you sure you want to reset search criteria?')}
                />
            </HealthCareMonitoringStatusContext.Provider>
        </HealthCareMonitoringDispatchContext.Provider>
    );
};

export default HealthCareMonitoring;
