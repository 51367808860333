import React, { useEffect, useMemo, useState } from 'react';

import Select from 'react-dropdown-select';

import useTranslation from '@hooks/useTranslation';

import styled from 'styled-components';

const StyledSelect = styled(Select)`
    background-color: #fff;
    min-width: 85px;
    .react-dropdown-select-item {
        color: black !important;
    }
    .react-dropdown-select-content {
        span {
            color: black !important;
        }
    }
    & .react-dropdown-select-input {
        display: none;
    }
    & .react-dropdown-select-dropdown-handle {
        margin: 0 0 0 5px;
        svg {
            color: black !important;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
`;

const MapOptionSelect = ({ handleChange, value, style = {}, valueKey = 'value', labelKey = 'label' }) => {
    const t = useTranslation('Drowning Monitoring');

    const [selectedValue, setSelectedValue] = useState(value);

    const options = useMemo(
        () => [
            { value: '', label: t('None') },
            { value: 'spaghettiDiagram', label: t('Spaghetti diagram') },
        ],
        [t],
    );

    useEffect(() => {
        if (!!selectedValue.length) {
            const foundOne = options.find(v => v.value === selectedValue[0].value);
            setSelectedValue([foundOne]);
        }
    }, [t]);

    return (
        <StyledSelect
            options={options}
            searchable={false}
            onChange={handleChange}
            values={selectedValue}
            placeholder={t('Select path type')}
            style={style}
            valueField={valueKey}
            labelField={labelKey}
        />
    );
};

export default React.memo(MapOptionSelect);
