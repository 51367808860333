import React, { useEffect, useMemo, useState } from 'react';

import moment from 'moment';

import { fetchUserInfo, getUserGroupInfoApi } from 'api/login';
import { createAssetInspectionApi, getAssetInspectionListApi } from 'api/asset';

import { useTranslation, useColumns, useConfirmModal, useAsync } from '@hooks';
import { Label, Button, TextInput, Table, TimePicker } from '@components';

import * as column from 'Components/MainPages/util/grid/column';

import cx from 'classnames';

const INSPECTION_STATUS = [
    { value: 'normal', label: 'Normal' },
    { value: 'replaced', label: 'Replace' },
    { value: 'repair', label: 'Repair' },
    { value: 'disposal', label: 'Disposal' },
];

const InspectionLogTab = ({
    assetInfo,
    assetNum,
    handleOpenAddAssetInspectionPage,
    getNewAssetInfo,
    goToAddAssetInspectionPage,
}) => {
    const t = useTranslation('Inspection Tab');

    const [buttonStatus, setButtonStatus] = useState(false);
    const [addAssetInspectionPage, setAddAssetInspectionPage] = useState(false);

    const [userGroupInfo, setUserGroupInfo] = useState({});
    const [userInfo, setUserInfo] = useState({});

    const [nextInspectionDate, setNextInspectionDate] = useState('');
    const [inspectionStatus, setInspectionStatus] = useState('');
    const [inspectionDesc, setInspectionDesc] = useState('');

    const [assetInspectionList, setAssetInspectionList] = useState({
        totalCount: 0,
        totalPage: 1,
        rows: [],
    });

    useEffect(() => {
        if (assetNum) {
            getAssetInspectionList({ opt: 'targetSearch', keyword: assetNum });
        }
        return () => {
            setAddAssetInspectionPage(false);
        };
    }, []);

    useEffect(() => {
        if (assetInfo && assetInfo.properties && assetInfo.properties.inspect_interval) {
            setNextInspectionDate(moment().add(parseInt(assetInfo.properties.inspect_interval), 'days').unix());
        }
    }, [assetInfo]);

    useEffect(() => {
        goToAddAssetInspectionPage ? setAddAssetInspectionPage(true) : setAddAssetInspectionPage(false);
    }, [goToAddAssetInspectionPage]);

    useAsync({
        promise: getUserGroupInfoApi,
        immediate: true,
        resolve: res => {
            if (res.rows) {
                setUserGroupInfo(res.rows[0]);
            }
        },
    });

    useAsync({
        promise: fetchUserInfo,
        immediate: true,
        resolve: res => {
            setUserInfo(res);
        },
    });

    const { promise: getAssetInspectionList, state: assetInspectionListInfo } = useAsync({
        promise: getAssetInspectionListApi,
        fixedParam: { pageSize: 10, opt: 'targetSearch', keyword: assetNum },
        keepState: true,
        resolve: res => {
            setAssetInspectionList(res);
        },
    });

    const { promise: createInspectionInfo } = useAsync({
        promise: createAssetInspectionApi,
        resolve: () => {
            toggleSuccessModal();
            setInspectionStatus('');
            setButtonStatus(false);
        },
        reject: () => {
            toggleFailureModal();
            setButtonStatus(false);
        },
    });

    const columns = useColumns([
        column.inspectionDate(),
        column.inspectionStatus({
            Cell: ({ value }) => {
                return <div>{t(value)}</div>;
            },
        }),
        column.regUserName(),
        column.assetDescription(),
    ]);

    const handleAddAssetBtnClick = () => setAddAssetInspectionPage(true);
    const handleRadioBtnClick = selected => setInspectionStatus(selected.target.value);
    const handleDescChange = typed => setInspectionDesc(typed.target.value);

    const handleAddBtnClick = () => {
        if (inspectionStatus) {
            toggleCreateAssetInspectionModal();
            return;
        }
        toggleValidationModal();
    };

    const refreshUserInput = () => {
        setNextInspectionDate('');
        setInspectionDesc('');
        handleOpenAddAssetInspectionPage(false);
    };

    const inspectorInfo = useMemo(() => {
        if (userGroupInfo && userInfo) {
            return `${userGroupInfo.groupName} ${t('department')} > ${userInfo.userName} [${userInfo.userID}]`;
        }
        if (userInfo && userInfo.userName) {
            return `${userInfo.userName} [${userInfo.userID}]`;
        }
        return '-';
    }, [userGroupInfo, userInfo]);

    const { toggleModal: toggleCreateAssetInspectionModal, Modal: AssetInspectionModal } = useConfirmModal({
        initModal: false,
        header: {
            title: t('Confirm'),
        },
        confirmText: t('Do you want to create a new asset inspection?'),
        okCallback: param => {
            createInspectionInfo(param);
            setButtonStatus(true);
        },
        callbackParam: {
            description: inspectionDesc,
            inspectStatus: inspectionStatus,
            targetNum: assetNum,
            nextInspectDate: nextInspectionDate,
            regGroupNum: userGroupInfo && userGroupInfo.groupNum,
        },
    });

    const { toggleModal: toggleValidationModal, Modal: ValidationModal } = useConfirmModal({
        header: {
            title: t('Notification'),
        },
        confirmText: t('Inspection status must be selected'),
        removeCancel: true,
    });

    const { toggleModal: toggleSuccessModal, Modal: SuccessModal } = useConfirmModal({
        header: { title: t('Notification') },
        confirmText: t('Asset inspection was created successfully'),
        removeCancel: true,
        okCallback: () => {
            setAddAssetInspectionPage(false);
            getAssetInspectionList();
            refreshUserInput();
            getNewAssetInfo();
        },
    });

    const { toggleModal: toggleFailureModal, Modal: FailureModal } = useConfirmModal({
        header: { title: t('Notification') },
        confirmText: t('The request failed.', 'ErrorHandler'),
        removeCancel: true,
    });

    return (
        <>
            <div className="d-flex flx-col h-100">
                {!addAssetInspectionPage ? (
                    <>
                        <div className="card-header px-0">
                            <div className="card-header__title justify-content-end">
                                <Button className="btn-brand" onClick={handleAddAssetBtnClick}>
                                    {t('Add asset inspection')}
                                </Button>
                            </div>
                        </div>
                        <div className="card-body">
                            <Table
                                data={{ ...assetInspectionList, pageSize: 10 }}
                                columns={columns}
                                onPageChange={pageIndex => getAssetInspectionList({ page: pageIndex })}
                                loading={assetInspectionListInfo.isLoading}
                            />
                        </div>
                    </>
                ) : (
                    <>
                        <div className="card-header px-0">
                            <div className="card-header__title">
                                <div className={'title-wrap'}>
                                    <h3 className="title__main">{t('Register an asset inspection')}</h3>
                                </div>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className={'pnt-label-7 flx-col gap-3'}>
                                <Label
                                    labelValueClassName={'label-dot'}
                                    name={t('Scheduled Inspection Date')}
                                    value={
                                        assetInfo &&
                                        assetInfo.properties &&
                                        assetInfo.properties.scheduled_inspection_date
                                            ? moment
                                                  .unix(assetInfo.properties.scheduled_inspection_date)
                                                  .format('YYYY-MM-DD')
                                            : t('None')
                                    }
                                />
                                <Label
                                    labelValueClassName={'label-dot'}
                                    name={t('Last Inspection Date')}
                                    value={
                                        assetInfo && assetInfo.properties && assetInfo.properties.last_inspect_date
                                            ? moment.unix(assetInfo.properties.last_inspect_date).format('YYYY-MM-DD')
                                            : t('None')
                                    }
                                />
                                <Label
                                    labelValueClassName={'label-dot'}
                                    name={t('Inspection Date')}
                                    value={moment().format('YYYY-MM-DD')}
                                />
                                <Label
                                    labelValueClassName={'label-dot'}
                                    name={t('Inspector Information')}
                                    value={inspectorInfo}
                                />
                                <div className="pnt-label--group">
                                    <div className="label-main label-dot color-brand">{t('Inspection Status')}</div>
                                    <div className="label-options">
                                        <ul className="pnt-radio-short d-flex ps-0" style={{ marginBottom: '-5px' }}>
                                            {INSPECTION_STATUS.map(({ value, label }) => (
                                                <li className="me-4" key={value}>
                                                    <input
                                                        type="radio"
                                                        id={value}
                                                        value={value}
                                                        name="radio"
                                                        onChange={handleRadioBtnClick}
                                                        checked={inspectionStatus === value}
                                                    />
                                                    <label style={{ cursor: 'auto' }}>{t(label)}</label>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                                <div className="pnt-label--group">
                                    <div className="label-main label-dot">{t('Inspection Description')}</div>
                                    <div className="label-options">
                                        <TextInput
                                            type={'text'}
                                            inputGroupClassName={'w-100'}
                                            placeholder={t('Please enter description')}
                                            handleChange={handleDescChange}
                                            maxlength={200}
                                            value={inspectionDesc}
                                            name={'desc'}
                                        />
                                    </div>
                                </div>
                                <div className="pnt-label--group">
                                    <div className="label-main label-dot">{t('Next Inspection Date')}</div>
                                    <div className="label-options">
                                        <TimePicker
                                            minDate={moment().valueOf()}
                                            value={nextInspectionDate}
                                            handleChange={selected => setNextInspectionDate(selected)}
                                            withoutTime
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex justify-content-end w-100 mt-3">
                                <button
                                    className={cx('pnt-btn btn-gray me-2', buttonStatus && 'form-disable')}
                                    style={{ width: '100px' }}
                                    disabled={buttonStatus}
                                    onClick={() => {
                                        refreshUserInput();
                                        setAddAssetInspectionPage(false);
                                        setInspectionStatus('');
                                    }}
                                >
                                    {t('Cancel')}
                                </button>
                                <button
                                    className={cx('pnt-btn btn-brand', buttonStatus && 'form-disable')}
                                    style={{ width: '100px' }}
                                    disabled={buttonStatus}
                                    onClick={handleAddBtnClick}
                                >
                                    {t('Add')}
                                </button>
                            </div>
                            <div className="mt-4" style={{ height: 'calc(100% - 360px)', minHeight: '230px' }}>
                                <Table
                                    data={{ ...assetInspectionList, pageSize: 10 }}
                                    columns={columns}
                                    onPageChange={pageIndex => getAssetInspectionList({ page: pageIndex })}
                                    loading={assetInspectionListInfo.isLoading}
                                />
                            </div>
                        </div>
                    </>
                )}
            </div>
            <AssetInspectionModal />
            <ValidationModal />
            <SuccessModal />
            <FailureModal />
        </>
    );
};

export default InspectionLogTab;
