import React, { useContext } from 'react';
import { FeatureGroup, Polygon, Tooltip } from 'react-leaflet';

import { PatrolDutyStateContext } from '../index';

const GeofenceLayer = ({ geofenceList }) => {
    const { entireRows } = useContext(PatrolDutyStateContext);

    const mappedGeofenceList = geofenceList.map(geofence => {
        const patrolStatus = entireRows.find(({ floorName }) => {
            return floorName === geofence.fcName;
        });

        return {
            ...geofence,
            ...patrolStatus,
        };
    });

    return (
        <FeatureGroup>
            {mappedGeofenceList.map(geofence => {
                if (!geofence.latLngList || !geofence.latLngList.length) {
                    return null;
                }
                return (
                    <Polygon color="blue" key={geofence.fcNum} positions={geofence.bounds}>
                        <Tooltip
                            key={`tooltip-${geofence.fcNum}`}
                            permanent={true}
                            interactive={true}
                            direction={'center'}
                            className={'geofence-name-label'}
                        >
                            <div className={'map-text'}>{geofence.fcName}</div>
                            {typeof geofence.inCompleteCnt === 'number' && (
                                <div className={'map-text'}>
                                    ({`${geofence.completeCnt || 0} / ${geofence.inCompleteCnt || 0}`})
                                </div>
                            )}
                        </Tooltip>
                    </Polygon>
                );
            })}
        </FeatureGroup>
    );
};
export default GeofenceLayer;
