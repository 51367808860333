import React, { useEffect, createContext, useReducer } from 'react';
import { useSelector } from 'react-redux';
import { filterInfo as filterDrawInfo } from './Option';
import { TYPE_TO_COMPONENT } from './dynamicOption';
import reducer, { setFilterInfo, initialState } from './reducer';

export const FilterGeneratorContext = createContext(null);
export const FilterGeneratorDispatchContext = createContext();

const StaticFilter = ({ handleChange, conditionInfo }) => {
    const { conditionId, conditionValues, isEditable } = conditionInfo;
    const thisFilterInfo = filterDrawInfo.find(v => v.conditionId === conditionId);
    const FilterComponent = thisFilterInfo ? thisFilterInfo.FilterComponent : null;
    return (
        !!FilterComponent && (
            <FilterComponent
                selected={conditionValues}
                isEditable={isEditable}
                handleChange={(selected, isEditable) => {
                    if (typeof handleChange === 'function') {
                        handleChange(selected, isEditable, conditionInfo);
                    }
                }}
            />
        )
    );
};

const DynamicFilter = ({ handleChange, conditionInfo }) => {
    const { searchableCategoryPropertiesList } = useSelector(state => state.CategoryInfo);
    const { conditionId, conditionValues, isEditable } = conditionInfo;
    const thisFilterInfo =
        searchableCategoryPropertiesList.find(categoryProps => categoryProps.propertyId === conditionId) || {};
    // 22.06.23 셀렉트 타입만 지원됨.(CategoryInfo 리듀서의 searchableCategoryPropertiesList 상태 참고)
    // 다른 타입 지원시, 각 타입에 맞는 FilterComponent에 맞춰서 props를 추가해야함
    const FilterComponent = TYPE_TO_COMPONENT[thisFilterInfo.inputType];
    return (
        !!FilterComponent && (
            <FilterComponent
                data={(thisFilterInfo.inputValues || []).map(v => ({ [conditionId]: v.value, label: v.name }))}
                title={thisFilterInfo.displayName}
                valueKey={conditionId}
                selected={conditionValues}
                isEditable={isEditable}
                onChange={(selected, isEditable) => {
                    if (typeof handleChange === 'function') {
                        handleChange(selected, isEditable, conditionInfo);
                    }
                }}
            />
        )
    );
};

const FilterGenerator = ({ filterInfo, handleChange = () => {}, filterEditMode, monitoringMode }) => {
    const [state, dispatch] = useReducer(reducer, { ...initialState, filterInfo, filterEditMode, monitoringMode });

    useEffect(() => {
        dispatch(setFilterInfo(filterInfo));
    }, [filterInfo]);

    return (
        <FilterGeneratorDispatchContext.Provider value={dispatch}>
            <FilterGeneratorContext.Provider value={state}>
                {!!filterInfo.filterInfoCondition &&
                    filterInfo.filterInfoCondition.map(conditionInfo => {
                        const { conditionId, conditionType } = conditionInfo;
                        switch (conditionType) {
                            case 'static':
                                return (
                                    <StaticFilter
                                        key={`${filterInfo.filterNum}_${conditionId}`}
                                        filterInfo={filterInfo}
                                        handleChange={handleChange}
                                        conditionInfo={conditionInfo}
                                    />
                                );
                            case 'dynamic':
                                return (
                                    <DynamicFilter
                                        key={`${filterInfo.filterNum}_${conditionId}`}
                                        filterInfo={filterInfo}
                                        handleChange={handleChange}
                                        conditionInfo={conditionInfo}
                                    />
                                );
                            default:
                                return null;
                        }
                    })}
            </FilterGeneratorContext.Provider>
        </FilterGeneratorDispatchContext.Provider>
    );
};

export default FilterGenerator;
