import React, { useState } from 'react';

import TimeLineInJournal from '@components/TimeLine/TimeLineInJournal';
import Label from '@components/Label';
import useTranslation from '@hooks/useTranslation';
import { falsyToHyphen, secToHms } from '@util/common/util';

import { useSelector } from 'react-redux';

import moment from 'moment';

import styled from 'styled-components';
import { LoadingBlock } from '@components';

const StatusJournalTab = ({ statusLogInfo, statusColorInfo, isLoading }) => {
    const t = useTranslation('Status Journal Tab');
    const { lang } = useSelector(state => state.UserInfo);

    const [selectedLogInfo, setSelectedLogInfo] = useState({});
    const {
        regDate,
        endTime,
        stayTime,
        previousStatusName,
        statusName,
        floorNamePath,
        operator,
        operatorID,
        operatorRank,
        operatorJobTitle,
        statusChanger,
        statusChangerID,
        statusChangerRank,
        statusChangerJobTitle,
    } = selectedLogInfo;

    const handleLogClick = e => {
        const foundOne = statusLogInfo.find(log => log.logNum === e.logNum);
        setSelectedLogInfo(foundOne);
    };

    const labelList = [
        { name: 'Start time', value: regDate ? moment.unix(regDate).locale(lang).format('YYYY-MM-DD A hh:mm') : '-' },
        { name: 'End time', value: endTime ? moment.unix(endTime).locale(lang).format('YYYY-MM-DD A hh:mm') : '-' },
        { name: 'Stay time', value: secToHms(stayTime, true, t) },
        { name: 'Previous status', value: falsyToHyphen(previousStatusName) },
        { name: 'Current status', value: falsyToHyphen(statusName) },
        { name: 'Last location', value: falsyToHyphen(floorNamePath) },
        {
            name: 'Operator',
            value: [falsyToHyphen(operatorJobTitle), falsyToHyphen(operatorRank), falsyToHyphen(operator)].join(' / '),
        },
        {
            name: 'Operator ID',
            value: falsyToHyphen(operatorID),
        },
        {
            name: 'Status Changer',
            value: [
                falsyToHyphen(statusChangerJobTitle),
                falsyToHyphen(statusChangerRank),
                falsyToHyphen(statusChanger),
            ].join(' / '),
        },
        {
            name: 'Status Changer ID',
            value: falsyToHyphen(statusChangerID),
        },
    ];

    return (
        <LoadingBlock blocking={isLoading}>
            <CardContainer>
                <div className="timeline-height flx-1">
                    <div className="card-header mb-2 px-0">
                        <div className="card-header__title">
                            <div className="title-wrap">
                                <h3 className="title__main">{t('Timeline')}</h3>
                            </div>
                        </div>
                    </div>
                    <LoadingBlock blocking={false}>
                        <div className="card-body p-1" style={{ height: 'calc(100% - 40px)' }}>
                            <TimeLineInJournal
                                onClick={handleLogClick}
                                events={statusLogInfo}
                                statusColorInfo={statusColorInfo}
                            />
                        </div>
                    </LoadingBlock>
                </div>
                <div className="flx-1">
                    <div className="card-header mb-2 px-0">
                        <div className="card-header__title">
                            <div className="title-wrap">
                                <h3 className="title__main">{t('Detail')}</h3>
                            </div>
                        </div>
                    </div>
                    <div className="card-body tab-body">
                        <div className="pnt-label-7 flx-col gap-3">
                            {labelList.map(({ name, value }) => {
                                return (
                                    <Label
                                        key={name}
                                        labelValueClassName="label-dot color-brand min-w-8rem"
                                        name={t(name)}
                                        value={<>{value}</>}
                                    />
                                );
                            })}
                        </div>
                    </div>
                </div>
            </CardContainer>
        </LoadingBlock>
    );
};

const CardContainer = styled.div`
    display: flex;
    height: calc(100% - 60px);
    @media (max-width: 1150px) {
        flex-direction: column;
        height: 40rem;
    }
`;

export default StatusJournalTab;
