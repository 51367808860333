import moment from 'moment';

export const division = ({ ...restProps } = {}) => {
    return {
        Header: 'Division',
        headerClassName: 'text-ellipsis',
        accessor: 'patrolDivName',
        className: 'text-ellipsis',
        width: 90,
        ...restProps,
    };
};

export const startDate = ({ ...restProps } = {}) => {
    return {
        Header: 'Start Date',
        headerClassName: 'text-ellipsis',
        accessor: 'startDate',
        className: 'text-ellipsis',
        formatTitle: ({ value }) => {
            return value ? moment.unix(value).format('YYYY-MM-DD HH:mm:ss') : '-';
        },
        Cell: ({ value }) => {
            return value ? moment.unix(value).format('YYYY-MM-DD HH:mm:ss') : '-';
        },
        ...restProps,
    };
};

export const endDate = ({ ...restProps } = {}) => {
    return {
        Header: 'End Date',
        headerClassName: 'text-ellipsis',
        accessor: 'endDate',
        className: 'text-ellipsis',
        formatTitle: ({ value }) => {
            return value ? moment.unix(value).format('YYYY-MM-DD HH:mm:ss') : '-';
        },
        Cell: ({ value }) => {
            return value ? moment.unix(value).format('YYYY-MM-DD HH:mm:ss') : '-';
        },
        ...restProps,
    };
};

export const floor = ({ ...restProps } = {}) => {
    return {
        Header: 'Floor',
        headerClassName: 'text-ellipsis',
        accessor: 'floorName',
        className: 'text-ellipsis',
        width: 90,
        ...restProps,
    };
};

export const patrolArea = ({ ...restProps } = {}) => {
    return {
        Header: 'Patrol Area',
        headerClassName: 'text-ellipsis',
        accessor: 'zoneName',
        className: 'text-ellipsis',
        width: 120,
        ...restProps,
    };
};

export const registrationDate = ({ ...restProps } = {}) => {
    return {
        Header: 'Registration Date',
        headerClassName: 'text-ellipsis',
        accessor: 'patrolRegDate',
        className: 'text-ellipsis',
        width: 160,
        formatTitle: ({ value }) => {
            return value ? moment.unix(value).format('YYYY-MM-DD HH:mm:ss') : '-';
        },
        Cell: ({ value }) => {
            return value ? moment.unix(value).format('YYYY-MM-DD HH:mm:ss') : '-';
        },
        ...restProps,
    };
};

export const modificationDate = ({ ...restProps } = {}) => {
    return {
        Header: 'Modification Date',
        headerClassName: 'text-ellipsis',
        accessor: 'patrolCompleteDate',
        className: 'text-ellipsis',
        width: 160,
        formatTitle: ({ value }) => {
            return value ? moment.unix(value).format('YYYY-MM-DD HH:mm:ss') : '-';
        },
        Cell: ({ value }) => {
            return value ? moment.unix(value).format('YYYY-MM-DD HH:mm:ss') : '-';
        },
        ...restProps,
    };
};

export const patrolId = ({ ...restProps } = {}) => {
    return {
        Header: 'Patrol ID',
        headerClassName: 'text-ellipsis',
        accessor: 'targetId',
        className: 'text-ellipsis',
        width: 80,
        ...restProps,
    };
};

export const patrolName = ({ ...restProps } = {}) => {
    return {
        Header: 'Patrol Name',
        headerClassName: 'text-ellipsis',
        accessor: 'targetName',
        className: 'text-ellipsis',
        width: 100,
        ...restProps,
    };
};

export const checkList = ({ ...restProps } = {}) => {
    return {
        Header: 'Check List',
        headerClassName: 'text-ellipsis',
        accessor: 'checkName',
        className: 'text-ellipsis',
        ...restProps,
    };
};

export const result = ({ ...restProps } = {}) => {
    return {
        Header: 'Result',
        headerClassName: 'text-ellipsis',
        accessor: 'checkResult',
        className: 'text-ellipsis',
        width: 100,
        ...restProps,
    };
};

export const contents = ({ ...restProps } = {}) => {
    return {
        Header: 'Contents',
        headerClassName: 'text-ellipsis',
        accessor: 'checkNote',
        className: 'text-ellipsis',
        width: 180,
        ...restProps,
    };
};
