import React, { ReactNode } from 'react';

import styled from 'styled-components';

import CompoundCard from './index';

// @ts-ignore
import useTranslation from '@hooks/useTranslation';

interface ConditionalRenderProps {
    condition: boolean;
    children: ReactNode;
}

const ConditionalRender = ({ condition, children }: ConditionalRenderProps) => {
    const t = useTranslation('Table');
    return condition ? (
        children
    ) : (
        <CompoundCard style={{ height: '32rem' }}>
            <CompoundCard.body className="h-100">
                <CompoundCard.body.plain>
                    <NoDataWrapper>
                        <NoDataComponent>{t('No data')}</NoDataComponent>
                    </NoDataWrapper>
                </CompoundCard.body.plain>
            </CompoundCard.body>
        </CompoundCard>
    );
};

const NoDataWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const NoDataComponent = styled.span`
    padding: 2rem;
    background-color: rgba(0, 0, 0, 0.1);
`;

export default ConditionalRender;
