import './polyfills';
import React from 'react';
import ReactDOM from 'react-dom';

import * as serviceWorker from './serviceWorker';

import { HashRouter } from 'react-router-dom';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import './assets/base.scss';
import Main from './Components/MainPages';
import configureStore from './store/configureStore';
import { Provider } from 'react-redux';
import './i18n';

export const store = configureStore();
const rootElement = document.getElementById('root');

const renderApp = Component => {
    ReactDOM.render(
        <Provider store={store}>
            <HashRouter>
                <Component />
            </HashRouter>
        </Provider>,
        rootElement,
    );
};

renderApp(Main);

if (module.hot) {
    module.hot.accept('./Components/MainPages', () => {
        const NextApp = require('./Components/MainPages').default;
        renderApp(NextApp);
    });
}
serviceWorker.unregister();
