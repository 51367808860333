import React, { useMemo } from 'react';
import { BarChart, XAxis, YAxis, Bar, Text, CartesianGrid, ResponsiveContainer, Tooltip } from 'recharts';
import { useTranslation } from 'react-i18next';

const color = ['#3E66FB', '#3acc5c', '#ff3333', '#ffc107', '#cccccc'];

const CustomizedYaxis = () => {
    const { t } = useTranslation();
    return (
        <Text x={25} y={115} angle={-89} style={{ fontSize: '0.8rem' }} className={'font-color-fill'}>
            {t('Overview;count of assets')}
        </Text>
    );
};

const StatusByBarChart = ({ data, groupBy = {} }) => {
    // barData : 속성으로 넣어줄 데이터
    // stackData : 막대를 그리기 위한 데이터
    const { barData, stackData } = useMemo(() => {
        const barData = [];
        const stackData = [];
        data.forEach((statusData, i) => {
            const key = `${statusData[groupBy.key]}_${statusData.statusNum}`;
            const exist = barData.find(v => v.groupId === statusData[groupBy.key]);
            if (exist) {
                exist[key] = statusData.statusCount;
            } else {
                barData.push({
                    name: statusData[groupBy.label],
                    groupId: statusData[groupBy.key],
                    [key]: statusData.statusCount,
                });
            }
            stackData.push({ key, displayColor: statusData.displayColor || color[i % 5] });
        });

        return { barData, stackData };
    }, [data, groupBy]);

    return (
        <ResponsiveContainer width={'100%'}>
            <BarChart data={barData} width={350} height={200}>
                <CartesianGrid strokeDasharray="2" />
                <Tooltip cursor={{ fill: 'rgba(191, 196, 255,0.3)' }} />
                <XAxis
                    dataKey="name"
                    dy={13}
                    style={{ fontSize: '0.7rem', fontWeight: 'bold' }}
                    className="font-color-fill"
                    stroke="#b5b5b5"
                    interval={0}
                    tickLine={false}
                    height={40}
                    tick={{ width: 70 }}
                />
                <YAxis label={<CustomizedYaxis />} tickLine={false} style={{ fontSize: '0.7rem' }} stroke="#b5b5b5" />
                {stackData.map(({ key, displayColor }) => (
                    <Bar key={key} dataKey={key} stackId="a" fill={displayColor} barSize={15} />
                ))}
            </BarChart>
        </ResponsiveContainer>
    );
};

export default StatusByBarChart;
