import React from 'react';

import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import PerfectScrollbar from 'react-perfect-scrollbar';

import moment from 'moment';

import { useTranslation } from '@hooks';
import { falsyToHyphen } from '@util/common/util';

import styled from 'styled-components';

const DefaultItemComponent = ({ event, statusColorInfo }) => {
    const t = useTranslation('TimeLine');
    const {
        targetName,
        previousStatusNum,
        previousStatusName,
        statusNum,
        statusName,
        type,
        typeClassName,
        floorNamePath,
        operator,
        operatorRank,
        operatorJobTitle,
        statusChanger,
        statusChangerRank,
        statusChangerJobTitle,
    } = event;

    const operatorName = [falsyToHyphen(operatorJobTitle), falsyToHyphen(operatorRank), falsyToHyphen(operator)].join(
        ' / ',
    );
    const statusChangerName = [
        falsyToHyphen(statusChangerJobTitle),
        falsyToHyphen(statusChangerRank),
        falsyToHyphen(statusChanger),
    ].join(' / ');

    return (
        <div>
            <div>
                <h4 className="timeline-title me-2 cursor-pointer">
                    {targetName}
                    <span
                        className="timeline-title ms-2"
                        style={{ color: statusColorInfo && statusColorInfo[previousStatusNum] }}
                    >
                        {previousStatusName}
                    </span>
                    <span className="icon-next transform-scale-smaller" />
                    <span
                        className="timeline-title ms-2"
                        style={{ color: statusColorInfo && statusColorInfo[statusNum] }}
                    >
                        {statusName}
                    </span>
                </h4>
            </div>

            <b className={typeClassName}>{type}</b>

            <>
                <p>{`${t('Location')} : ${falsyToHyphen(floorNamePath)}`}</p>
                <p>{`${t('Operator')} : ${operatorName}`}</p>
                <p>{`${t('Status Changer')} : ${statusChangerName}`}</p>
                {/*{event.properties && (*/}
                {/*    <p>*/}
                {/*        {t('Owner')} : {falsyToHyphen(event.properties.pic_id)}*/}
                {/*    </p>*/}
                {/*)}*/}
            </>
        </div>
    );
};

const TimeLineInJournal = ({ events = [], onClick, ItemComponent, withDate = true, statusColorInfo }) => {
    const t = useTranslation('TimeLine');

    return events.length ? (
        <PerfectScrollbar>
            <VerticalTimeline layout="1-column">
                {events.map((event, i) => (
                    <VerticalTimelineElement
                        key={i}
                        className="vertical-timeline-item cursor-default"
                        icon={
                            <i
                                style={{ backgroundColor: statusColorInfo && statusColorInfo[event.statusNum] }}
                                className="badge badge-dot badge-dot-xl"
                            >
                                {' '}
                            </i>
                        }
                        date={
                            <>
                                {withDate && (
                                    <>
                                        {/*<span> {event.dateStr}</span>*/}
                                        <span>{moment(event.regDate * 1000).format('YYYY.MM.DD')}</span>
                                        <br />
                                    </>
                                )}
                                <span> {moment(event.regDate * 1000).format('LT')}</span>
                            </>
                        }
                    >
                        <WrappedAlertInfo
                            clickable={typeof onClick === 'function'}
                            onClick={() => {
                                if (typeof onClick === 'function') {
                                    onClick(event);
                                }
                            }}
                        >
                            {ItemComponent ? (
                                <ItemComponent event={event} />
                            ) : (
                                <DefaultItemComponent event={event} statusColorInfo={statusColorInfo} />
                            )}
                        </WrappedAlertInfo>
                    </VerticalTimelineElement>
                ))}
            </VerticalTimeline>
        </PerfectScrollbar>
    ) : (
        <div
            style={{
                color: '#666',
                textAlign: 'center',
                paddingTop: '1.5rem',
            }}
        >
            {t('No new notifications')}
        </div>
    );
};

const WrappedAlertInfo = styled.div`
    display: flex;
    & > div {
        cursor: ${props => (props.clickable ? 'pointer' : 'default')};
    }
`;

export default TimeLineInJournal;
