import React, { useContext } from 'react';

import TextInput from '@components/Input/TextInput';
import useTranslation from 'util/hooks/useTranslation';

import { OperatorSelect, TriggerLabel } from './Parts';

import { TriggerDispatchContext, TriggerStateContext } from './index';
import { checkRestful } from './triggerReducer';

const RestfulApiInputGroup = ({ apiURL }) => {
    const t = useTranslation('Status');

    const dispatch = useContext(TriggerDispatchContext);
    const { restFulOn } = useContext(TriggerStateContext);

    const handleInputChange = () => {
        dispatch(checkRestful({ restFulOn: !restFulOn, apiURL }));
    };

    return (
        <div className={'input-container-group'}>
            <div className={'input-container'}>
                <OperatorSelect value={'||'} disabled />
            </div>
            <div className="input-container api-input">
                <div className="d-flex">
                    <input
                        id={'restFul'}
                        checked={restFulOn}
                        type="checkbox"
                        name={'restFul'}
                        onChange={handleInputChange}
                    />
                    <label htmlFor={'restFul'} className={'input-label'}>
                        <TriggerLabel label={t('Restful API')} />
                    </label>
                </div>
                <TextInput
                    inputGroupClassName="w-100"
                    name={'restfulApiInput'}
                    value={apiURL}
                    readOnly
                    disabled={!restFulOn}
                />
            </div>
        </div>
    );
};

export default RestfulApiInputGroup;
