import React from 'react';
import Card from '@components/Card';
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { useTranslation } from 'react-i18next';

const LineChartByDate = ({ dataForEntryCount }) => {
    const { t } = useTranslation();
    return (
        <Card
            header={{
                title: t('HourlyCongestionStatus;Hourly entries count'),
            }}
        >
            <ResponsiveContainer width={'100%'} height={'100%'}>
                <LineChart data={dataForEntryCount}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" />
                    <YAxis />
                    <Line dot={{ fill: '#1F77B4' }} name="Visit count" dataKey="visitCount" stroke="#1F77B4" />
                    <Line name="Unique count" dataKey="uniqueCount" stroke="#FF7F0E" dot={{ fill: '#FF7F0E' }} />
                    <Legend iconType={'rect'} iconSize={10} />
                    <Tooltip />
                </LineChart>
            </ResponsiveContainer>
        </Card>
    );
};
export default LineChartByDate;
