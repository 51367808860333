import React from 'react';

import { RotatedImageOverlay } from '@components';

const makeRotatedImageOverlay = (mapRef, floorInfo) => {
    if (floorInfo && floorInfo.imgURL && floorInfo.bounds && floorInfo.bounds.length) {
        return (
            <RotatedImageOverlay
                key={floorInfo.floorId}
                url={floorInfo.imgURL}
                deg={floorInfo.deg}
                bounds={floorInfo.bounds}
                onLoad={() => {
                    if (mapRef.current) {
                        const map = mapRef.current.leafletElement;
                        if (map) {
                            map.fitBounds(floorInfo.bounds);
                        }
                    }
                }}
            />
        );
    }

    return null;
};

export default makeRotatedImageOverlay;
