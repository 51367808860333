import React, { useMemo, useState } from 'react';

import { useTranslation, useAppSelector } from '@hooks';

import FilterSearchGroup from '@components/FilterSearchGroup';
import FilterGroup from '@components/FilterSearchGroup/Components/FilterGroup';
import {
    SelectGroup,
    FilterList,
    InputWrap,
    InputGroup,
    SearchWrap,
} from '@components/FilterSearchGroup/Components/Part';
import SearchGroup from '@components/FilterSearchGroup/Components/SearchGroup';
import FilterButtonArea from '@components/FilterSearchGroup/Components/FilterButtonArea';

import { TimePicker, Select as ReactSelect, Button, TextInput } from '@components';

import moment from 'moment';

interface SearchProps {
    startDate: number;
    endDate: number;
    handleDateCallback: Function;
    handleSearchClick: Function;
    getPatrolLog: Function;
}

const SEARCH_INPUT_OPTIONS = [
    { value: 'All', label: 'All' },
    { value: 'patrolDivName', label: 'Division' },
    { value: 'beaconName', label: 'Patrol Area' },
    { value: 'targetId', label: 'Patrol ID' },
    { value: 'targetName', label: 'Patrol Name' },
    { value: 'checkName', label: 'Check List' },
    { value: 'checkResult', label: 'Result' },
    { value: 'checkNote', label: 'Contents' },
];

const Search = ({ startDate, endDate, handleDateCallback, handleSearchClick, getPatrolLog }: SearchProps) => {
    const t = useTranslation('Patrol Log');

    const { floorList } = useAppSelector(state => state.FloorInfo);

    const filteredFloorList = useMemo(() => {
        return [
            { floorId: 'All', floorName: t('All') },
            ...floorList.filter(({ floorId }) => floorId !== 'entire' && floorId !== 'pnt_outdoor'),
        ];
    }, [floorList, t]);
    const [selectedFloorOption, setSelectedFloorOption] = useState(filteredFloorList[0]);

    const filteredSearchInputOptions = useMemo(() => {
        return SEARCH_INPUT_OPTIONS.map(({ value, label }) => ({ value, label: t(label) }));
    }, [t]);
    const [selectedInputOption, setSelectedInputOption] = useState(filteredSearchInputOptions[0]);

    const [searchInput, setSearchInput] = useState('');

    const handleReset = () => {
        handleDateCallback({ type: 'reset' });
        setSelectedFloorOption(filteredFloorList[0]);
        setSelectedInputOption(filteredSearchInputOptions[0]);
        setSearchInput('');
        getPatrolLog({
            startDate: moment().startOf('day').unix().valueOf(),
            endDate: moment().unix().valueOf(),
        });
    };

    return (
        <FilterSearchGroup className="">
            <FilterGroup className="bg-brand" label={t('Filter', 'Filter')}>
                <FilterList className="">
                    <ReactSelect
                        name="selectOptions"
                        value={selectedFloorOption}
                        options={filteredFloorList}
                        // @ts-ignore
                        onChange={setSelectedFloorOption}
                        valueKey="floorId"
                        labelKey="floorName"
                        customControlStyles={{ width: '100%' }}
                        customMenuStyles={{ width: '100%' }}
                        customOptionStyles={{ width: '100%' }}
                    />
                    <SelectGroup className="">
                        <div className="datePicker-container">
                            <TimePicker
                                value={startDate}
                                handleChange={(selected: number) => handleDateCallback({ type: 'startDate', selected })}
                                valueType="ms"
                                minDate={null}
                                maxDate={endDate || moment.now()}
                                showTimeInput
                                withoutTime={false}
                                withoutDay={false}
                                readOnly={false}
                            />
                        </div>
                    </SelectGroup>
                    <div className="text-center">~</div>
                    <SelectGroup className="">
                        <div className="datePicker-container">
                            <TimePicker
                                value={endDate}
                                handleChange={(selected: number) => handleDateCallback({ type: 'endDate', selected })}
                                valueType="ms"
                                minDate={startDate}
                                maxDate={moment.now()}
                                showTimeInput
                                withoutTime={false}
                                withoutDay={false}
                                readOnly={false}
                            />
                        </div>
                    </SelectGroup>
                    <div className="d-flex gap-1 justify-content-end">
                        <Button
                            className="btn-secondary btn-yesterday"
                            style={{ width: 'fit-content' }}
                            onClick={() => handleDateCallback({ type: 'yesterday' })}
                        >
                            {t('Yesterday', 'Button')}
                        </Button>
                        <Button className="btn-danger btn-icon-only" iconName="replay" onClick={handleReset} />
                    </div>
                </FilterList>
                <FilterButtonArea children={null} />
            </FilterGroup>
            <SearchGroup className="bg-secondary" label={t('Search', 'Search')}>
                <SearchWrap className="">
                    <SelectGroup className="">
                        <ReactSelect
                            name="searchSelect"
                            placeholder={t('Search Option', 'Search')}
                            value={selectedInputOption}
                            options={filteredSearchInputOptions}
                            // @ts-ignore
                            onChange={setSelectedInputOption}
                            customControlStyles={{ width: '100%' }}
                            customMenuStyles={{ width: '100%' }}
                            customOptionStyles={{ width: '100%' }}
                        />
                    </SelectGroup>
                    <InputWrap>
                        <InputGroup>
                            <TextInput
                                type="text"
                                name="searchInput"
                                size={60}
                                placeholder={t(
                                    'Please Enter your search keyword after selecting search option.',
                                    'Search',
                                )}
                                value={searchInput}
                                handleChange={e => setSearchInput(e.target.value)}
                            />
                        </InputGroup>
                        <Button
                            className="pnt-btn btn-brand btn-icon"
                            title={t('Search', 'Search')}
                            iconName="search"
                            onClick={() => handleSearchClick({ selectedFloorOption, selectedInputOption, searchInput })}
                        >
                            {t('Search', 'Search')}
                        </Button>
                    </InputWrap>
                </SearchWrap>
            </SearchGroup>
        </FilterSearchGroup>
    );
};

export default Search;
