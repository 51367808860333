import React, { useState, useCallback } from 'react';
import ConfirmModal from '@components/ConfirmModal';

const useConfirmModal = ({ initModal, ...modalProps }) => {
    const [open, setOpen] = useState(initModal);
    const toggleModal = useCallback(() => {
        setOpen(prev => !prev);
    }, [setOpen]);

    const Modal = useCallback(
        () => <ConfirmModal initModal={open} toggleModal={toggleModal} {...modalProps} />,
        [open, toggleModal],
    );

    return {
        toggleModal,
        Modal,
    };
};

export default useConfirmModal;
