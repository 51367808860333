import React from 'react';

import Button from '@components/Button';

import { PaginationProps } from '../../type';

const Pagination = ({ gotoPage, pageIndex, pageSize, totalCount, totalPage }: PaginationProps) => {
    return (
        <div className="pagination">
            <div className="pnt-table--paging__wrap flx-row gap-1">
                <Button className="btn-icon-only btn-tiny btn-lightgray" onClick={() => gotoPage(0)}>
                    <span className="icon-back-double opacity-5" />
                </Button>
                <Button className="btn-icon-only btn-tiny btn-brand" onClick={() => gotoPage(pageIndex - 1)}>
                    <span className="icon-back icon-filter-white" />
                </Button>
                <div className="mx-2">
                    <span className="now">
                        {totalCount
                            ? `${pageIndex * pageSize + 1}-${
                                  (pageIndex + 1) * pageSize > totalCount ? totalCount : (pageIndex + 1) * pageSize
                              }`
                            : '0-0'}
                    </span>
                    <span className="whole">{` / ${totalCount}`}</span>
                </div>
                <Button className="btn-icon-only btn-tiny btn-brand" onClick={() => gotoPage(pageIndex + 1)}>
                    <span className="icon-next icon-filter-white" />
                </Button>
                <Button className="btn-icon-only btn-tiny btn-lightgray" onClick={() => gotoPage(totalPage - 1)}>
                    <span className="icon-next-double opacity-5" />
                </Button>
            </div>
        </div>
    );
};

export default Pagination;
