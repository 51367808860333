import { createSlice } from '@reduxjs/toolkit';
import { InitialState } from './type';

export const searchOptionInitial = [
    { value: 'All', label: 'All' },
    { value: 'manualTitle', label: 'Title' },
    { value: 'manualContent', label: 'Content' },
    { value: 'fileName', label: 'File Name' },
];

export const initialState: InitialState = {
    categoryList: null,
    selectedCategory: searchOptionInitial[0],
    searchOptionList: searchOptionInitial,
    selectedSearchOption: searchOptionInitial[0],
    searchInput: '',
    manualList: null,
    paging: {
        page: 1,
        totalPage: 1,
        totalCount: 0,
        pageSize: 8,
    },
};

const { actions, reducer } = createSlice({
    name: 'technicalManual',
    initialState,
    reducers: {
        setCategoryList: (state, action) => {
            state.categoryList = action.payload;
        },
        setSelectedCategory: (state, action) => {
            state.selectedCategory = action.payload;
        },
        setSelectedSearchOption: (state, action) => {
            state.selectedSearchOption = action.payload;
        },
        setSearchInput: (state, action) => {
            state.searchInput = action.payload;
        },
        setManualList: (state, action) => {
            state.manualList = action.payload;
        },
        setPaging: (state, action) => {
            state.paging = { ...state.paging, ...action.payload };
        },
    },
});

export const {
    setCategoryList,
    setSelectedCategory,
    setSelectedSearchOption,
    setSearchInput,
    setManualList,
    setPaging,
} = actions;
export default reducer;
