import React, { useContext } from 'react';
import Control from 'react-leaflet-control';
import Button from '@components/Button';
import { LayersControl } from 'react-leaflet';
import GeofenceLayer from '@components/Map/Layers/GeofenceLayer';
import FloorDetailPopup from './FloorDetailPopup';
import FilteringPopup from './FilteringPopup';
import { RealTimeLocationStatusContext, RealTimeLocationStatusDispatchContext } from '../index';
import { setFloorInfo, setPopup, setSelectedFloor } from '../realTimeLocationReducer';
import { useTranslation } from 'react-i18next';
import FloorTreeSelect from '../../../Components/FloorTreeSelect';
import { useSelector } from 'react-redux';

const AllTarget = ({ widgetRef, mapRef }) => {
    const { t } = useTranslation();
    const { floorList } = useSelector(state => state.FloorInfo);

    const { selectedFloor, geofenceInfo, popup, realTimeLog } = useContext(RealTimeLocationStatusContext);
    const dispatch = useContext(RealTimeLocationStatusDispatchContext);

    const handleFloorChange = selects => {
        if (selects[0]) {
            dispatch(setSelectedFloor(selects[0].floorId));
            dispatch(setFloorInfo(selects[0]));
        }
    };

    const handlePopupClick = (name, value) => {
        dispatch(setPopup({ [name]: !value }));
    };

    const handleModalToggle = value => {
        dispatch(setPopup({ [value]: !popup[value] }));
    };

    return (
        <>
            <Control position="topleft">
                <FloorTreeSelect
                    floorList={floorList}
                    selectedFloorStr={selectedFloor}
                    mode={'radioSelect'}
                    handleChange={handleFloorChange}
                />
                {/*<FloorSelect selectedFloorId={selectedFloor} handleChange={handleFloorChange} />*/}
            </Control>
            <Control position="topleft" className={'control-container'}>
                <div className={'item-container'}>
                    <span className={'map-text'}>{`${t(
                        'RealTimeLocationStatus;Total Number of Items Detected',
                    )} : `}</span>
                    <span className={'map-text'}>{realTimeLog.count}</span>
                </div>

                <Button
                    iconName="border_color"
                    onClick={() => handlePopupClick('floorDetailPopup', popup.floorDetailPopup)}
                    className={'btn-icon btn-lightgray'}
                >
                    {t('RealTimeLocationStatus;Detail')}
                </Button>

                <Button
                    iconName="search"
                    onClick={() => handlePopupClick('filteringPopup', popup.filteringPopup)}
                    className="btn-icon btn-lightgray"
                >
                    {t('RealTimeLocationStatus;Filter')}
                </Button>
            </Control>

            <LayersControl position={'topright'}>
                <LayersControl.Overlay checked name={t('RealTimeLocationStatus;Geofence')}>
                    <GeofenceLayer geofenceList={geofenceInfo} />
                </LayersControl.Overlay>
            </LayersControl>

            <FloorDetailPopup
                modal={popup.floorDetailPopup}
                toggleModal={() => handleModalToggle('floorDetailPopup')}
                widgetRef={widgetRef}
                mapRef={mapRef}
            />
            <FilteringPopup
                modal={popup.filteringPopup}
                toggleModal={() => handleModalToggle('filteringPopup')}
                widgetRef={widgetRef}
                mapRef={mapRef}
            />
        </>
    );
};

export default AllTarget;
