import React from 'react';
// @ts-ignore
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';

interface BarChartData {
    name: string;
    value: number;
}

interface CustomBarChartProps {
    data: BarChartData[];
}

const CustomBarChart = ({ data = [] }: CustomBarChartProps) => {
    return (
        <ResponsiveContainer width="100%" height="100%">
            <BarChart
                data={data}
                margin={{
                    top: 20,
                    right: 30,
                    left: 0,
                    bottom: 0,
                }}
            >
                <XAxis dataKey="name" />
                <YAxis />
                <Bar dataKey="value" fill="#8884d8" />
                <Tooltip />
            </BarChart>
        </ResponsiveContainer>
    );
};

export default CustomBarChart;
