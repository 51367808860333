import React from 'react';

import cx from 'classnames';

import { CountItemContainer, HeaderContainer, BodyContainer } from '../style';

interface CountItemProps {
    title: string;
    count: number;
    complete?: boolean;
    selected?: boolean;
    handleClick?: Function;
}

const CountItem = ({ title = '', count, complete = false, selected = false, handleClick }: CountItemProps) => {
    const handleItemClick = () => {
        if (typeof handleClick === 'function') {
            handleClick(complete);
        }
    };
    return (
        <CountItemContainer
            className={cx('flx-1 h-100 text-ellipsis', typeof handleClick === 'function' && 'cursor-pointer')}
            onClick={handleItemClick}
            $selected={selected}
        >
            <HeaderContainer $complete={complete}>{title}</HeaderContainer>
            <BodyContainer $complete={complete} $selected={selected}>
                {count}
            </BodyContainer>
        </CountItemContainer>
    );
};

export default CountItem;
