import React, { useContext, useEffect, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FilterSearchGroup from '@components/FilterSearchGroup';
import Button from '@components/Button';
import SingleSelect from '@components/Select/SingleSelect';
import useAsync from '@hooks/useAsync';
import { fetchCategory } from '@api/asset';
import DatePicker from '@components/DatePicker';
import { setSelectedCategory, setSelectedFloor, setStartDate, setEndDate } from '../congestedGeofenceReducer';
import { CongestedGeofenceContext, CongestedGeofenceDispatchContext } from '../index';
import { useSelector } from 'react-redux';
import FloorTreeSelect from '../../../Components/FloorTreeSelect';
import moment from 'moment';
import SearchGroup from '@components/FilterSearchGroup/Components/SearchGroup';
import SearchButtonArea from '@components/FilterSearchGroup/Components/SearchButtonArea';
import FilterGroup from '@components/FilterSearchGroup/Components/FilterGroup';
import { FilterList, SelectGroup } from '@components/FilterSearchGroup/Components/Part';
import FilterButtonArea from '@components/FilterSearchGroup/Components/FilterButtonArea';

const CongestedGeofenceFilterGroup = ({ handleSearchClick }) => {
    const { t } = useTranslation();
    const [categoryListWithAllOption, setCategoryListWithAllOption] = useState([]);
    const { floorList } = useSelector(state => state.FloorInfo);
    const { categoryList } = useSelector(state => state.CategoryInfo);
    const { selectedCategory, selectedFloor, startDate, endDate } = useContext(CongestedGeofenceContext);
    const dispatch = useContext(CongestedGeofenceDispatchContext);

    const handleSelectCategoryChange = e => {
        dispatch(setSelectedCategory(e));
    };

    const handleYesterdayBtnClick = () => {
        dispatch(setStartDate(moment().subtract(1, 'days').startOf('day').valueOf()));
        dispatch(setEndDate(moment().startOf('day').valueOf()));
    };

    const handleTodayBtnClick = () => {
        dispatch(setStartDate(moment().startOf('day').valueOf()));
        dispatch(setEndDate(moment().endOf('day').valueOf()));
    };

    const handleFloorChange = selects => {
        if (selects[0]) {
            dispatch(setSelectedFloor(selects[0].floorId));
        }
    };

    useEffect(() => {
        setCategoryListWithAllOption([{ categoryCode: '', categoryName: 'All' }, ...categoryList]);
    }, [categoryList]);

    return (
        <FilterSearchGroup className={'card absolute-filter'}>
            <SearchGroup label={t('CongestionStatus;Filter')}>
                <SelectGroup>
                    <SingleSelect
                        placeholder={'All'}
                        value={categoryListWithAllOption.length === 0 ? '' : selectedCategory}
                        onChange={handleSelectCategoryChange}
                        options={categoryListWithAllOption}
                        valueKey={'categoryCode'}
                        labelKey={'categoryName'}
                        listStyle={{ height: '200px', overflow: 'auto' }}
                    />
                </SelectGroup>
                <SelectGroup>
                    <FloorTreeSelect
                        key={selectedFloor}
                        floorList={floorList}
                        selectedFloorStr={selectedFloor}
                        mode={'radioSelect'}
                        handleChange={handleFloorChange}
                    />
                </SelectGroup>
            </SearchGroup>
            <SearchGroup label={t('CongestionStatus;Search')}>
                <FilterList>
                    <SelectGroup>
                        <div className={'datePicker-container me-2'}>
                            <DatePicker
                                value={startDate}
                                handleChange={selected => dispatch(setStartDate(selected))}
                                valueType={'ms'}
                                maxDate={endDate || moment().valueOf()}
                                showTimeInput
                            />
                        </div>
                        <div> ~ </div>
                    </SelectGroup>
                    <SelectGroup>
                        <div className={'datePicker-container ms-2'}>
                            <DatePicker
                                value={endDate}
                                handleChange={selected => dispatch(setEndDate(selected))}
                                valueType={'ms'}
                                minDate={startDate}
                                maxDate={moment().valueOf()}
                                showTimeInput
                            />
                        </div>
                    </SelectGroup>
                    <SearchButtonArea>
                        <Button className={'btn-secondary btn-yesterday'} onClick={handleYesterdayBtnClick}>
                            {t('Button;Yesterday')}
                        </Button>
                        <Button className={'btn-secondary btn-yesterday'} onClick={handleTodayBtnClick}>
                            {t('Button;Today')}
                        </Button>
                        <Button className="pnt-btn btn-brand" onClick={handleSearchClick}>
                            {t('CongestionStatus;Search')}
                        </Button>
                    </SearchButtonArea>
                </FilterList>
            </SearchGroup>
        </FilterSearchGroup>
    );
};
export default CongestedGeofenceFilterGroup;
