import React from 'react';

import { useTranslation } from '@hooks';
import { Button } from '@components';
import Card from '../../Components/WidgetCard';

import { useDispatch } from 'react-redux';
import { setTempWidgetLayout } from '@reducer/Dashboards/DashboardEdit';

import styled from 'styled-components';

export const WIDGET_TYPE_TEMP = 'temp';

const TemporaryWidget = ({ children, widgetInfo, ...restProps }) => {
    const { type, layout } = widgetInfo;
    const dispatch = useDispatch();
    const t = useTranslation('Widget');
    const handleAddWidget = e => {
        dispatch(setTempWidgetLayout(layout));
    };

    return type === WIDGET_TYPE_TEMP ? (
        <Card widgetInfo={widgetInfo} {...restProps} className="temp-widget">
            <AddInnerContainer className="flex-center">
                <Button
                    className="btn-brand btn-icon"
                    iconName="add"
                    iconClassName="material-icons-round"
                    onClick={handleAddWidget}
                >
                    {t('Add Widget')}
                </Button>
            </AddInnerContainer>
            {children}
        </Card>
    ) : (
        <Card widgetInfo={widgetInfo} {...restProps}>
            <NotSupportInnerContainer className="flex-center">
                {t('This is not supported widget.')}
            </NotSupportInnerContainer>
            {children}
        </Card>
    );
};

const AddInnerContainer = styled.div`
    border: 1px dashed #3e66fb;
    color: #777;
    height: 100%;
`;

const NotSupportInnerContainer = styled.div`
    height: 95%;
    color: #777;
    font-size: 1.25rem;
`;

export default TemporaryWidget;
