export const TAG_LABELS = [
    { id: 'total', label: 'All' },
    { id: 'inside', label: 'Inside Of Ship' },
    { id: 'outside', label: 'Deck' },
    { id: 'notBoarding', label: 'No Boarding' },
    { id: 'absence', label: 'Absence' },
    { id: 'drowning', label: 'Drowning' },
];

export const ABNORMAL_LABELS = [
    { id: 'sos', label: 'SOS' },
    { id: 'lostSignal', label: 'Lost Signal' },
];

export const TAG_CLASSNAME = {
    sos: 'text-danger',
    outside: 'text-danger',
    drowning: 'text-danger',
    lostSignal: 'text-danger',
};
