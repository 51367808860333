import React from 'react';

import styled from 'styled-components';

const CloseButton = ({ closeToast }) => {
    return (
        <ButtonContainer onClick={closeToast}>
            <span className="icon-close icon-small" />
        </ButtonContainer>
    );
};

const ButtonContainer = styled.button`
    background-color: unset;
    display: flex;
    margin-top: 1px;
`;

export default CloseButton;
