import React, { useMemo, useState } from 'react';
import { Select as ReactSelect, TextInput, Button } from '@components';
import { InputGroup, InputWrap, SearchWrap, SelectGroup } from '@components/FilterSearchGroup/Components/Part';
import SearchButtonArea from '@components/FilterSearchGroup/Components/SearchButtonArea';
import SearchGroup from '@components/FilterSearchGroup/Components/SearchGroup';
import useTranslation from '@hooks/useTranslation';

const Search = ({ handleSearchClick }) => {
    const t = useTranslation('Search');

    const searchOptions = useMemo(() => {
        return [
            { value: null, label: t('Search Option') },
            { value: 'targetName', label: t('Target Name', 'CommonColumn') },
            { value: 'targetId', label: t('Target ID', 'CommonColumn') },
        ];
    }, [t]);

    const [searchInputDisabled, setSearchInputDisabled] = useState(true);
    const [selectedOption, setSelectedOption] = useState(searchOptions[0].value);
    const [keyword, setKeyword] = useState('');

    const handleSelectValueChange = selected => {
        const { value } = selected;
        setSelectedOption(value);
        if (value) {
            setSearchInputDisabled(false);
        } else {
            setSearchInputDisabled(true);
            setKeyword('');
        }
    };

    const handleSearchInputChange = e => {
        const { value } = e.currentTarget;
        setKeyword(value);
    };

    const onSearchClick = () => {
        handleSearchClick({ opt: selectedOption, keyword });
    };

    return (
        <SearchGroup label={t('Search')} className="bg-brand">
            <SearchWrap>
                <SelectGroup>
                    <ReactSelect
                        name={'selectInput'}
                        placeholder={t('Search Option')}
                        value={selectedOption}
                        options={searchOptions}
                        onChange={handleSelectValueChange}
                        customControlStyles={{ width: '100%' }}
                        customMenuStyles={{ width: '100%' }}
                        customOptionStyles={{ width: '100%' }}
                    />
                </SelectGroup>
                <InputWrap>
                    <InputGroup>
                        <TextInput
                            style={{ borderRadius: '5px' }}
                            type="text"
                            size={60}
                            placeholder={t('Please Enter your search keyword after selecting search option.')}
                            name="name"
                            value={keyword}
                            handleChange={handleSearchInputChange}
                            disabled={searchInputDisabled}
                        />
                    </InputGroup>
                </InputWrap>
                <Button className="btn-brand btn-icon" iconName="search" onClick={onSearchClick}>
                    {t('Search')}
                </Button>
            </SearchWrap>
        </SearchGroup>
    );
};

export default Search;
