import React from 'react';

import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import useTranslation from '@hooks/useTranslation';

import cx from 'classnames';

import styled from 'styled-components';

const CustomModalBody = styled(ModalBody)`
    min-height: 90px;
`;

const SubTitle = styled.p`
    line-height: 1.7;
    font-size: 1rem;
    font-weight: 400;
    text-transform: capitalize;
    color: rgba(0, 0, 0, 0.6);
    margin: 0;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-wrap: normal;
    overflow: hidden;
`;

const ValidationModal = ({
    initModal = false,
    toggleModal = function () {
        initModal = !initModal;
    },
    okCallback,
    cancelCallback,
    callbackParam,
    header = { title: 'Validation Fail', subTitle: '', className: '' },
    validationText,
    removeCancel = true,
    okClassName,

    ...restProps
}) => {
    const t = useTranslation('ValidationModal');
    const confirmT = useTranslation('ConfirmModal');

    return (
        <Modal
            container={document.getElementsByClassName('app-container')[0]}
            isOpen={initModal}
            toggle={toggleModal}
            className={'pnt-modal'}
            {...restProps}
        >
            <ModalHeader className={header.className} toggle={toggleModal}>
                {typeof header.title === 'string' ? t(header.title) : header.title}
                {!!header.subTitle && <SubTitle>{header.subTitle}</SubTitle>}
            </ModalHeader>
            <CustomModalBody className={'flex-center'}>
                <ul className="p-0 m-0 pnt-label-8">
                    {validationText &&
                        validationText.map(text => {
                            return (
                                <li key={text}>
                                    <span className="pnt-label--group">
                                        <div
                                            className="label-main label-dot w-100"
                                            style={{ fontWeight: 'bold', fontSize: '0.9rem' }}
                                        >
                                            {t(text)}
                                        </div>
                                    </span>
                                </li>
                            );
                        })}
                </ul>
            </CustomModalBody>
            <ModalFooter className={cx(!removeCancel && 'justify-content-between')}>
                {!removeCancel && (
                    <Button
                        color="secondary"
                        onClick={() => {
                            if (typeof cancelCallback === 'function') {
                                cancelCallback(callbackParam);
                            }
                            toggleModal();
                        }}
                    >
                        {confirmT('Cancel')}
                    </Button>
                )}
                <Button
                    className={okClassName}
                    color="primary"
                    onClick={() => {
                        if (typeof okCallback === 'function') {
                            okCallback(callbackParam);
                        }
                        toggleModal();
                    }}
                >
                    {confirmT('OK')}
                </Button>{' '}
            </ModalFooter>
        </Modal>
    );
};

export default ValidationModal;
