import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import _ from 'lodash';

export const MANUAL_GROUP_KEY = 'manual';
export const RESTFUL_GROUP_KEY = 'restFul';

export const AND = '&&';
export const OR = '||';

export const TRIGGER_TYPE_MANUAL = 'M';
export const TRIGGER_TYPE_RESTFUL = 'RA';
export const TRIGGER_TYPE_LOCATION = 'L';

export const TRIGGER_SUB_TYPE_USER = 'user';
export const TRIGGER_SUB_TYPE_GROUP = 'group';
export const TRIGGER_SUB_TYPE_FLOOR = 'floor';
export const TRIGGER_SUB_TYPE_GEOFENCE = 'geofence';

export const TRIGGER_VALUE_TYPE_INCLUDE = 'include';
export const TRIGGER_VALUE_TYPE_EXCLUDE = 'exclude';
export const TRIGGER_VALUE_TYPE_IN = 'IN';
export const TRIGGER_VALUE_TYPE_OUT = 'OUT';

export const initialState = {
    startStatusNum: null,
    endStatusNum: null,
    manualOn: false,
    restFulOn: false,
    targetStatusTriggers: [],
    triggerRelationJson: { data: {}, order: [] },
    geofenceList: [],
    userList: [],
    userGroupList: [],
};

export const makeSaveData = ({ data, order }) => {
    const targetStatusTriggers = [];
    let triggerFormula = '';
    let triggerRelationJson = order.length
        ? JSON.stringify({
              data: order.reduce((acc, curr) => {
                  const triggerGroupInfo = data[curr];
                  if (triggerGroupInfo) {
                      acc[curr] = triggerGroupInfo;
                  }
                  return acc;
              }, {}),
              order,
          })
        : '';

    order.map(triggerGroupKey => {
        const triggerGroupData = data[triggerGroupKey];
        if (triggerFormula) {
            triggerFormula += triggerGroupData.op;
        }
        triggerFormula += '(';
        triggerGroupData.triggers.forEach((triggerInfo, index) => {
            const { groupKey, triggerValue, ...restInfo } = triggerInfo;
            restInfo.triggerValue = JSON.stringify(triggerValue);
            // restInfo.triggerSubType = restInfo.triggerSubType || '';
            targetStatusTriggers.push(restInfo);
            if (index) {
                triggerFormula += `${OR}${restInfo.triggerKey}`;
            } else {
                triggerFormula += restInfo.triggerKey;
            }
        });
        triggerFormula += ')';
    });

    return { targetStatusTriggers, triggerFormula, triggerRelationJson };
};

const getUpdatedRelationJson = triggerRelationJson => {
    const newJson = _.cloneDeep(triggerRelationJson);
    const hasManual = newJson.order[0] === MANUAL_GROUP_KEY;
    const nextGroupKey = newJson.order[1];
    for (let groupKey in newJson.data) {
        if (newJson.data.hasOwnProperty(groupKey)) {
            newJson.data[groupKey].disabled = false;
        }
    }
    if (hasManual && nextGroupKey) {
        newJson.data[nextGroupKey].op = OR;
        newJson.data[nextGroupKey].disabled = true;
    }
    return newJson;
};

const { actions, reducer } = createSlice({
    name: 'trigger',
    initialState,
    reducers: {
        setTransitionInfo: (state, action) => {
            const transitionInfo = action.payload;
            state.startStatusNum = transitionInfo.startStatusNum;
            state.endStatusNum = transitionInfo.endStatusNum;
            state.targetStatusTriggers = transitionInfo.targetStatusTriggers;

            transitionInfo.triggerRelationJson = getUpdatedRelationJson(transitionInfo.triggerRelationJson);

            state.triggerRelationJson = transitionInfo.triggerRelationJson;
            if (transitionInfo.triggerRelationJson) {
                const relationJson = transitionInfo.triggerRelationJson;
                state.manualOn = relationJson.order.includes(MANUAL_GROUP_KEY);
                state.restFulOn = relationJson.order.includes(RESTFUL_GROUP_KEY);
            }
        },
        checkManual: (state, action) => {
            const checked = !!action.payload;
            state.manualOn = checked;
            if (checked) {
                state.triggerRelationJson.order.unshift(MANUAL_GROUP_KEY);
                if (!state.triggerRelationJson.data.manual) {
                    state.triggerRelationJson.data.manual = { op: null, triggers: [] };
                }
            } else {
                state.triggerRelationJson.order = state.triggerRelationJson.order.filter(v => v !== MANUAL_GROUP_KEY);
            }
            state.triggerRelationJson = getUpdatedRelationJson(state.triggerRelationJson);
        },
        checkRestful: (state, action) => {
            const { restFulOn, apiURL } = action.payload;
            state.restFulOn = restFulOn;
            if (restFulOn) {
                const manualIndex = state.triggerRelationJson.order.indexOf(MANUAL_GROUP_KEY);
                if (manualIndex !== -1) {
                    state.triggerRelationJson.order.splice(manualIndex + 1, 0, RESTFUL_GROUP_KEY);
                } else {
                    state.triggerRelationJson.order.unshift(RESTFUL_GROUP_KEY);
                }
                if (!state.triggerRelationJson.data.restFul) {
                    const triggerInfo = {};
                    const { startStatusNum, endStatusNum } = state;
                    triggerInfo.startStatusNum = startStatusNum;
                    triggerInfo.endStatusNum = endStatusNum;
                    triggerInfo.triggerKey = `$${moment().valueOf().toString(36)}`;
                    triggerInfo.triggerType = TRIGGER_TYPE_RESTFUL;
                    triggerInfo.triggerSubType = null;
                    triggerInfo.triggerValue = { value: apiURL };
                    triggerInfo.groupKey = RESTFUL_GROUP_KEY;

                    state.triggerRelationJson.data.restFul = { op: OR, triggers: [triggerInfo] };
                }
            } else {
                state.triggerRelationJson.order = state.triggerRelationJson.order.filter(v => v !== RESTFUL_GROUP_KEY);
            }
            state.triggerRelationJson = getUpdatedRelationJson(state.triggerRelationJson);
        },
        updateTriggerGroupOperator: (state, action) => {
            const triggerGroupInfo = action.payload;
            const { groupKey, op } = triggerGroupInfo;

            state.triggerRelationJson.data[groupKey].op = op;
        },
        addTriggerGroup: (state, action) => {
            const triggerGroupInfo = action.payload;
            const { groupKey } = triggerGroupInfo;
            let op = AND;
            if (state.triggerRelationJson.order[state.triggerRelationJson.order.length - 1] === MANUAL_GROUP_KEY) {
                op = OR;
            }
            state.triggerRelationJson.data[groupKey] = { op: op, triggers: [] };
            state.triggerRelationJson.order.push(groupKey);
            state.triggerRelationJson = getUpdatedRelationJson(state.triggerRelationJson);
        },
        addTrigger: (state, action) => {
            const triggerInfo = action.payload;
            const { startStatusNum, endStatusNum } = state;
            triggerInfo.startStatusNum = startStatusNum;
            triggerInfo.endStatusNum = endStatusNum;

            triggerInfo.triggerKey = `$${moment().valueOf().toString(36)}`;

            if (state.triggerRelationJson.data[triggerInfo.groupKey]) {
                state.triggerRelationJson.data[triggerInfo.groupKey].triggers.push(triggerInfo);
            }
        },
        deleteTriggerGroup: (state, action) => {
            const triggerGroupKey = action.payload;
            state.triggerRelationJson.data[triggerGroupKey] = null;
            delete state.triggerRelationJson.data[triggerGroupKey];
            state.triggerRelationJson.order = state.triggerRelationJson.order.filter(v => v !== triggerGroupKey);
            state.triggerRelationJson = getUpdatedRelationJson(state.triggerRelationJson);
        },
        deleteTrigger: (state, action) => {
            const { groupKey, triggerKey } = action.payload;
            state.triggerRelationJson.data[groupKey].triggers = state.triggerRelationJson.data[
                groupKey
            ].triggers.filter(v => v.triggerKey !== triggerKey);
        },
        setUserList: (state, action) => {
            state.userList = action.payload || [];
        },
        setUserGroupList: (state, action) => {
            state.userGroupList = action.payload || [];
        },
        setGeofenceList: (state, action) => {
            state.geofenceList = action.payload || [];
        },
    },
});

export const {
    setTransitionInfo,
    checkManual,
    checkRestful,
    updateTriggerGroupOperator,
    addTriggerGroup,
    addTrigger,
    deleteTriggerGroup,
    deleteTrigger,
    setUserList,
    setUserGroupList,
    setGeofenceList,
} = actions;
export default reducer;
