import styled, { css } from 'styled-components';
import { Nullable } from '../../../../util/type';

export const CompoundCardLabel = styled.div`
    width: 7px;
    height: 7px;
    margin-left: 10px;
`;

export const CompoundCardHeaderContainer = styled.div`
    background-color: rgb(229, 227, 243) !important;
    color: black !important;
    display: flex;
    align-items: center;
    gap: 0.4rem;
    padding: 0.6rem 0.4rem;
    font-weight: bold;
`;

export const CompoundCardContainer = styled.div`
    border: 1px solid rgb(229, 227, 243);
`;

export const CompoundCardBodyContainer = styled.div`
    border-top: 1px solid rgb(229, 227, 243);
    overflow: auto;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
`;

export const CompoundCardContentsContainer = styled.div<{
    $clickable: boolean;
    $clicked: boolean;
    $hoverColor: Nullable<string>;
    $clickedColor: Nullable<string>;
}>`
    font-size: 3rem;
    text-align: center;

    ${({ $clickable, $hoverColor }) =>
        $clickable &&
        css`
            :hover {
                background-color: ${$hoverColor || '#ebf0fa'};
                cursor: pointer;
            }
        `};
    ${({ $clicked, $clickedColor }) =>
        $clicked &&
        css`
            background-color: ${$clickedColor || '#ebf0fa'};
        `};
`;

export const CompoundCardTableContainer = styled.div`
    height: 100%;
    min-height: 218px;
`;
