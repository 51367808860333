import React, { useContext, useEffect, useRef } from 'react';

import { Button } from 'reactstrap';

import PlayBar from './PlayBar';
import PlayTimeDisplay from './PlayTimeDisplay';

import {
    setEndTime,
    setPause,
    setPlay,
    setPlayTime,
    setStartTime,
} from 'Components/MainPages/Monitoring/DrowningMonitoring/DrowningMonitoringReducer';

import {
    DrowningMonitoringDispatchContext,
    DrowningMonitoringStatusContext,
} from 'Components/MainPages/Monitoring/DrowningMonitoring';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStepBackward, faStepForward } from '@fortawesome/fontawesome-free-solid';
import {
    faAngleLeft,
    faAngleDoubleLeft,
    faAngleRight,
    faAngleDoubleRight,
    faPlay,
    faPause,
} from '@fortawesome/free-solid-svg-icons';

import styled from 'styled-components';

const PlayController = ({ on }) => {
    const { play, speed, playTime, startTime, endTime } = useContext(DrowningMonitoringStatusContext);
    const dispatch = useContext(DrowningMonitoringDispatchContext);

    const playRef = useRef();

    const start = () => {
        playRef.current = setInterval(() => {
            dispatch(setPlayTime());
        }, 1000 / speed);
    };
    const stop = () => {
        if (playRef.current) {
            clearInterval(playRef.current);
            playRef.current = null;
        }
    };
    useEffect(() => {
        stop();
        if (play) {
            start();
        }
        return () => {
            stop();
        };
    }, [play, speed]);

    useEffect(() => {
        if (playTime >= endTime) {
            dispatch(setPause());
        }
    }, [playTime]);

    return (
        <>
            <PlayBar
                start={start}
                stop={stop}
                speed={speed}
                playTime={playTime}
                startTime={startTime}
                endTime={endTime}
            />
            <PlayTimeDisplay playTime={playTime} startTime={startTime} endTime={endTime} on={on} />
            <ControlWrapper>
                <ControlButton disabled={!on} onClick={() => dispatch(setStartTime({ startTime, playTime }))}>
                    <FontAwesomeIcon icon={faStepBackward} />
                </ControlButton>
                <div>
                    <ControlButton
                        disabled={!on}
                        className={play && speed === 1 / 4 && 'on'}
                        onClick={() => dispatch(setPlay({ speed: 1 / 4, startTime, endTime, playTime }))}
                    >
                        <FontAwesomeIcon icon={faAngleDoubleLeft} />
                    </ControlButton>
                    <ControlButton
                        disabled={!on}
                        className={play && speed === 1 / 2 && 'on'}
                        onClick={() => dispatch(setPlay({ speed: 1 / 2, startTime, endTime, playTime }))}
                    >
                        <FontAwesomeIcon icon={faAngleLeft} />
                    </ControlButton>
                    {play ? (
                        <ControlButton
                            disabled={!on}
                            className={speed === 1 && 'on'}
                            style={{ fontSize: '1rem' }}
                            onClick={() => {
                                dispatch(setPause({ speed: 1 }));
                            }}
                        >
                            <FontAwesomeIcon icon={faPause} />
                        </ControlButton>
                    ) : (
                        <ControlButton
                            disabled={!on}
                            style={{ fontSize: '1rem' }}
                            onClick={() => {
                                dispatch(setPlay({ speed: 1, startTime, endTime, playTime }));
                            }}
                        >
                            <FontAwesomeIcon icon={faPlay} />
                        </ControlButton>
                    )}
                    <ControlButton
                        disabled={!on}
                        className={play && speed === 2 && 'on'}
                        onClick={() => dispatch(setPlay({ speed: 2, startTime, endTime, playTime }))}
                    >
                        <FontAwesomeIcon icon={faAngleRight} />
                    </ControlButton>
                    <ControlButton
                        disabled={!on}
                        className={play && speed === 4 && 'on'}
                        onClick={() => dispatch(setPlay({ speed: 4, startTime, endTime, playTime }))}
                    >
                        <FontAwesomeIcon icon={faAngleDoubleRight} />
                    </ControlButton>
                </div>
                <ControlButton disabled={!on} onClick={() => dispatch(setEndTime({ endTime, playTime }))}>
                    <FontAwesomeIcon icon={faStepForward} />
                </ControlButton>
            </ControlWrapper>
        </>
    );
};

const ControlWrapper = styled.div`
    padding: 0.5rem;
    display: flex;
    justify-content: space-between;
`;

const ControlButton = styled(Button)`
    margin: 0 5px;
    background-color: #e7e7e7;
    color: #000;
    line-height: 1;
    width: 40px;
    height: 40px;
    font-size: 1.25rem;
    border: none;
    &.on {
        background-color: #3e66fb;
        color: #fff;
    }
`;

export default PlayController;
