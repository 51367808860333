import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    statusListInfo: { rows: [] },
    mappedStatusInfo: {},
    flowCategoryMappedStatusInfo: { rows: [] },
    itemCntByFlow: {},
    statusSchemeListInfo: { rows: [] },
    flowSchemeListInfo: { rows: [] },
    statusCategoryListInfo: { rows: [] },
    transitionList: [],
};

const { actions, reducer } = createSlice({
    name: 'common/statusInfo',
    initialState,
    reducers: {
        setStatusListInfo: (state, action) => {
            const statusListInfo = action.payload;
            state.statusListInfo = statusListInfo;

            const { rows } = statusListInfo;
            if (rows && rows.length && state.statusCategoryListInfo.rows.length) {
                const statusCategoryList = state.statusCategoryListInfo.rows;
                state.statusCategoryListInfo.rows = statusCategoryList.map(v => {
                    return {
                        ...v,
                        mappedStatus: rows.filter(status => status.statusCategoryNum === v.statusCategoryNum),
                    };
                });
            }
        },
        setMappedStatusListInfo: (state, action) => {
            const { rows } = action.payload;
            if (rows) {
                state.mappedStatusInfo = rows.reduce((acc, curr) => {
                    const schemeInfo = acc[curr.schemeNum];
                    if (schemeInfo) {
                        schemeInfo.push(curr);
                    } else {
                        acc[curr.schemeNum] = [curr];
                    }
                    return acc;
                }, {});
            } else {
                state.mappedStatusInfo = {};
            }

            // 상태 스킴이 있다면, 매핑된 상태 정보 추가
            if (state.statusSchemeListInfo.rows.length) {
                state.statusSchemeListInfo.rows = state.statusSchemeListInfo.rows.map(v => {
                    return { ...v, mappedStatus: state.mappedStatusInfo[v.schemeNum] };
                });
            }
        },
        setStatusSchemeListInfo: (state, action) => {
            const statusSchemeListInfo = action.payload;

            state.statusSchemeListInfo = statusSchemeListInfo;
            const { rows } = statusSchemeListInfo;

            if (rows && rows.length) {
                // 플로우 스킴이 있다면, 상태 스킴에 매핑된 플로우 스킴 데이터 추가
                if (state.flowSchemeListInfo.rows.length) {
                    const flowSchemeList = state.flowSchemeListInfo.rows;
                    state.statusSchemeListInfo.rows = rows.map(v => {
                        const mappedFlowScheme = flowSchemeList.find(
                            flowScheme => flowScheme.schemeNum === v.schemeNum,
                        );
                        if (mappedFlowScheme) {
                            return { ...v, flowNum: mappedFlowScheme.flowNum, flowName: mappedFlowScheme.flowName };
                        }
                        return v;
                    });

                    state.flowSchemeListInfo.rows = flowSchemeList.map(v => {
                        const mappedStatusScheme = rows.find(statusScheme => statusScheme.schemeNum === v.schemeNum);
                        if (mappedStatusScheme) {
                            return { ...v, schemeName: mappedStatusScheme.schemeName };
                        }
                        return v;
                    });
                }

                // 매핑된 상태 정보가 있다면, 상태 스킴에 추가
                if (Object.keys(state.mappedStatusInfo).length) {
                    state.statusSchemeListInfo.rows = state.statusSchemeListInfo.rows.map(v => {
                        return { ...v, mappedStatus: state.mappedStatusInfo[v.schemeNum] };
                    });
                } else {
                    state.statusSchemeListInfo.rows = state.statusSchemeListInfo.rows.map(v => {
                        return { ...v, mappedStatus: [] };
                    });
                }
            }
        },
        setFlowSchemeListInfo: (state, action) => {
            const flowSchemeListInfo = action.payload;
            flowSchemeListInfo.rows = flowSchemeListInfo.rows.map(v => {
                const itemCount = state.itemCntByFlow[v.flowNum] || 0;
                return { ...v, itemCount };
            });
            state.flowSchemeListInfo = flowSchemeListInfo;
            const { rows } = flowSchemeListInfo;

            // 상태 스킴이 있다면, 상태 스킴에 매핑된 플로우 스킴 데이터 추가
            if (rows && rows.length && state.statusSchemeListInfo.rows.length) {
                const schemeList = state.statusSchemeListInfo.rows;
                state.statusSchemeListInfo.rows = schemeList.map(v => {
                    const mappedFlowScheme = rows.find(flowScheme => flowScheme.schemeNum === v.schemeNum);
                    if (mappedFlowScheme) {
                        return { ...v, flowNum: mappedFlowScheme.flowNum, flowName: mappedFlowScheme.flowName };
                    }
                    return v;
                });
                state.flowSchemeListInfo.rows = rows.map(v => {
                    const mappedStatusScheme = schemeList.find(statusScheme => statusScheme.schemeNum === v.schemeNum);
                    if (mappedStatusScheme) {
                        return { ...v, schemeName: mappedStatusScheme.schemeName };
                    }
                    return v;
                });
            }
        },
        setStatusCategoryListInfo: (state, action) => {
            const statusCategoryListInfo = action.payload;
            state.statusCategoryListInfo = statusCategoryListInfo;
            const { rows } = statusCategoryListInfo;

            if (rows && rows.length && state.statusListInfo.rows.length) {
                const statusList = state.statusListInfo.rows;
                state.statusCategoryListInfo.rows = rows.map(v => {
                    return {
                        ...v,
                        mappedStatus: statusList.filter(status => status.statusCategoryNum === v.statusCategoryNum),
                    };
                });
            }
        },
        setStatusFlowCategoryMapInfo: (state, action) => {
            const flowCategoryMappedStatusInfo = action.payload;
            state.flowCategoryMappedStatusInfo = flowCategoryMappedStatusInfo;

            const { rows } = flowCategoryMappedStatusInfo;
            let itemCntByFlow = {};
            if (rows && rows.length) {
                itemCntByFlow = rows.reduce((acc, curr) => {
                    if (acc[curr.flowNum]) {
                        acc[curr.flowNum] += curr.itemCount;
                    } else {
                        acc[curr.flowNum] = curr.itemCount;
                    }
                    return acc;
                }, {});

                if (state.statusListInfo.rows.length) {
                    const statusList = state.statusListInfo.rows;
                    state.statusCategoryListInfo.rows = rows.map(v => {
                        return {
                            ...v,
                            mappedStatus: statusList.filter(status => status.statusCategoryNum === v.statusCategoryNum),
                        };
                    });
                }

                const flowSchemeList = state.flowSchemeListInfo.rows;
                if (flowSchemeList.length) {
                    state.flowSchemeListInfo.rows = flowSchemeList.map(v => {
                        const itemCount = itemCntByFlow[v.flowNum] || 0;
                        return { ...v, itemCount };
                    });
                }
            }

            state.itemCntByFlow = itemCntByFlow;
        },
        setTransitionList: (state, action) => {
            const transitionListInfo = action.payload;
            if (transitionListInfo.rows) {
                state.transitionList = transitionListInfo.rows;
            } else {
                state.transitionList = [];
            }
        },
    },
});

export const {
    setStatusListInfo,
    setMappedStatusListInfo,
    setStatusSchemeListInfo,
    setFlowSchemeListInfo,
    setStatusCategoryListInfo,
    setStatusFlowCategoryMapInfo,
    setTransitionList,
} = actions;
export default reducer;
