import React, { createContext, useReducer } from 'react';
import { Redirect, Route, useRouteMatch } from 'react-router-dom';

import { ErrorHandleSwitch } from '../Components/Router';

import TechnicalManualSearch from './TechnicalManualSearch';
import TechnicalManualDetails from './TechnicalManualDetails';
import technicalManualReducer, { initialState } from './technicalManualReducer';
import { InitialState } from './type';

export const TechnicalManualDispatchContext = createContext<React.Dispatch<any> | null>(null);
export const TechnicalManualStateContext = createContext<InitialState>(initialState);

const TechnicalManual = () => {
    const { path } = useRouteMatch();

    const [state, dispatch] = useReducer(technicalManualReducer, initialState);

    return (
        <ErrorHandleSwitch>
            <TechnicalManualDispatchContext.Provider value={dispatch}>
                <TechnicalManualStateContext.Provider value={state}>
                    <Route exact path={`${path}/search`} component={TechnicalManualSearch} />
                    <Route exact path={`${path}/details/:manualNum`} component={TechnicalManualDetails} />
                    <Route exact path={'/manual'} render={() => <Redirect to={`/manual/search`} />} />
                </TechnicalManualStateContext.Provider>
            </TechnicalManualDispatchContext.Provider>
        </ErrorHandleSwitch>
    );
};

export default TechnicalManual;
