import React, { useState, useRef, useContext, useEffect } from 'react';
import Control from 'react-leaflet-control';
import cx from 'classnames';
import L from 'leaflet';
import { Marker, Popup } from 'react-leaflet';
import { useSelector } from 'react-redux';

import { Button, Select, makeRotatedImageOverlay, Label } from '@components';
import Map from './Map';
import CompoundCard from '../../../Components/CompoundCard';
import WidgetConfirmModal from '../../../Components/WidgetConfirmModal';
import FloorTreeSelect from '../../../Components/FloorTreeSelect';
import PatrolTime from './PatrolTime';
import CustomGeofenceLayer from './CustomGeofenceLayer';

import { PatrolDutyContainer, PatrolMapContainer, ButtonContainer, TimeContainer } from '../style';
import { PatrolDutyDispatchContext, PatrolDutyStateContext } from '../index';
import {
    setSelectedDivOption,
    setPatrolStatus,
    setPatrolInfo,
    setRecentPatrol,
    setPatrolDashboard,
    setSelectedFloorStr,
} from '../patrolOnDutyReducer';

import { falsyToHyphen } from '@util/common/util';
import { useTranslation, useAsync } from '@hooks';
import { createPatrolEndAPI, createPatrolStartAPI, getPatrolStatusAPI, getPatrolDashboardAPI } from '@api/military';
import { fetchGeofenceList } from '@api/common';

import redDot from '@asset/images/red-dot.svg';
import greenDot from '@asset/images/green-dot.svg';

const RedDot = new L.Icon({
    iconUrl: redDot,
    iconSize: [10, 10],
});

const GreenDot = new L.Icon({
    iconUrl: greenDot,
    iconSize: [10, 10],
});

const PatrolDuty = () => {
    const t = useTranslation('Patrol On Duty');
    const { floorList } = useSelector(state => state.FloorInfo);

    const dispatch = useContext(PatrolDutyDispatchContext);
    const { patrolStatus, selectedDivOption, patrolDivList, patrolInfo, recentPatrol, selectedFloorStr } =
        useContext(PatrolDutyStateContext);

    const mapRef = useRef(null);

    const [entireFloorGeofenceList, setEntireFloorGeofenceList] = useState([]);
    // 모달 상태들
    const [patrolStartModal, setPatrolStartModal] = useState(false);
    const [patrolEmptyModal, setPatrolEmptyModal] = useState(false);
    const [patrolAlreadyStartModal, setPatrolAlreadyStartModal] = useState(false);
    const [patrolAlreadyEndModal, setPatrolAlreadyEndModal] = useState(false);
    const [patrolUnCompletedModal, setPatrolUnCompletedModal] = useState(false);

    const { promise: getPatrolDashboard } = useAsync({
        promise: getPatrolDashboardAPI,
        resolve: res => {
            dispatch(setPatrolDashboard(res));
        },
        reject: err => {
            console.error(err);
        },
    });

    const { promise: createPatrolStart } = useAsync({
        promise: createPatrolStartAPI,
        resolve: res => {
            dispatch(setRecentPatrol(res));
            dispatch(setPatrolStatus('Y'));
        },
        reject: err => {
            const {
                data: { code },
            } = err;
            if (code === '7011') {
                togglePatrolEmptyModal();
            } else if (code === '1101') {
                togglePatrolAlreadyStartModal();
                getPatrolDashboard();
            } else {
                console.error(err);
            }
        },
    });

    const { promise: createPatrolEnd } = useAsync({
        promise: createPatrolEndAPI,
        resolve: () => {
            dispatch(setPatrolStatus('N'));
            getPatrolStatus({
                floor: selectedFloorStr,
                patrolDiv: selectedDivOption.value,
            });
        },
        reject: err => {
            const {
                data: { code },
            } = err;
            if (code === '7002') {
                togglePatrolAlreadyEndModal();
            }
            console.error(err);
        },
    });

    const { promise: getPatrolStatus } = useAsync({
        promise: getPatrolStatusAPI,
        resolve: res => {
            dispatch(setPatrolInfo(res));
        },
        reject: err => {
            console.error(err);
        },
    });

    useAsync({
        promise: fetchGeofenceList,
        immediate: true,
        resolve: res => {
            const entireFloorGeofence = res.rows
                .filter(geofence => {
                    return geofence.floor === 'entire';
                })
                .map(geofence => {
                    return {
                        ...geofence,
                        bounds: [geofence.latLngList.map(({ lat, lng }) => [lat, lng])],
                    };
                });

            setEntireFloorGeofenceList(entireFloorGeofence);
        },
        reject: err => {
            console.error(err);
        },
    });

    useEffect(() => {
        if (!selectedDivOption.value && !selectedDivOption.label && !!patrolDivList.length) {
            dispatch(setSelectedDivOption(patrolDivList[0]));
        }
    }, [selectedDivOption, patrolDivList]);

    const handleEndButton = () => {
        if (patrolStatus === 'N') {
            return;
        }

        const { totalCompleteCnt, totalIncompleteCnt } = patrolInfo;

        if (totalCompleteCnt < totalIncompleteCnt) {
            togglePatrolUnCompletedModal();
            return;
        }

        createPatrolEnd({
            patrolDiv: selectedDivOption.value,
            patrolDivName: selectedDivOption.label,
            patrolNum: recentPatrol.patrolNum,
        });
    };

    useEffect(() => {
        if (!selectedFloorStr && !!floorList.length) {
            dispatch(setSelectedFloorStr(floorList[0].floorId));
        }
    }, [floorList, selectedFloorStr]);

    const handleFloorChange = selected => {
        dispatch(setSelectedFloorStr(selected[0].floorId));
    };

    // 모달 토글 함수들
    const togglePatrolStartModal = () => {
        if (patrolStatus === 'Y') {
            return;
        }

        setPatrolStartModal(!patrolStartModal);
    };

    const togglePatrolEmptyModal = () => {
        setPatrolEmptyModal(!patrolEmptyModal);
    };

    const togglePatrolAlreadyStartModal = () => {
        setPatrolAlreadyStartModal(!patrolAlreadyStartModal);
    };

    const togglePatrolUnCompletedModal = () => {
        setPatrolUnCompletedModal(!patrolUnCompletedModal);
    };

    const togglePatrolAlreadyEndModal = () => {
        setPatrolAlreadyEndModal(!patrolAlreadyEndModal);
    };

    const floorInfo = floorList.find(({ floorId }) => floorId === selectedFloorStr) || { floorId: '' };

    return (
        <>
            <CompoundCard>
                <CompoundCard.header>{t('Patrol On Duty', 'Widget')}</CompoundCard.header>
                <CompoundCard.body className="compound-card-body-height d-flex flex-column">
                    <CompoundCard.body.plain>
                        <PatrolDutyContainer>
                            <Select
                                className="d-flex align-items-center"
                                name={'status'}
                                value={selectedDivOption}
                                options={patrolDivList}
                                onChange={selected => {
                                    dispatch(setSelectedDivOption(selected));
                                }}
                                disabled={patrolStatus === 'Y'}
                            />
                            <ButtonContainer>
                                <Button
                                    onClick={togglePatrolStartModal}
                                    className={cx(
                                        'flx-1 btn-lg h-100 btn-shadow btn-secondary',
                                        patrolStatus === 'Y' && 'form-disable',
                                    )}
                                >
                                    {t('Patrol Start')}
                                </Button>
                                <Button
                                    onClick={handleEndButton}
                                    className={cx(
                                        'flx-1 btn-lg h-100 btn-shadow btn-lightgray',
                                        patrolStatus !== 'Y' && 'form-disable',
                                    )}
                                >
                                    {t('Patrol End')}
                                </Button>
                            </ButtonContainer>
                            <TimeContainer>
                                <div className="text-ellipsis">
                                    {t('Start Time') + ' : ' + falsyToHyphen(patrolInfo.patrolStartDate)}
                                </div>
                                <div className="text-ellipsis">
                                    {t('End Time') + ' : ' + falsyToHyphen(patrolInfo.patrolEndDate)}
                                </div>
                                <PatrolTime />
                            </TimeContainer>
                        </PatrolDutyContainer>
                        <PatrolMapContainer>
                            <Map ref={mapRef} tile={true}>
                                <Control position="topleft">
                                    <FloorTreeSelect
                                        floorList={floorList}
                                        selectedFloorStr={selectedFloorStr || ''}
                                        handleChange={handleFloorChange}
                                        mode="radioSelect"
                                    />
                                </Control>
                                {makeRotatedImageOverlay(mapRef, floorInfo)}
                                {!!patrolInfo.patrolBeaconList &&
                                    !!patrolInfo.patrolBeaconList.length &&
                                    patrolInfo.patrolBeaconList.map(beacon => {
                                        return (
                                            <Marker
                                                position={[beacon.lat, beacon.lng]}
                                                icon={beacon.patrolState === 'Y' ? GreenDot : RedDot}
                                                key={beacon.beaconNum}
                                                title={beacon.beaconName}
                                            >
                                                <Popup>
                                                    <Label
                                                        name={t('Station')}
                                                        value={beacon.beaconName}
                                                        labelValueClassName={'label-dot min-w-none'}
                                                    />
                                                    <Label
                                                        name={t('Patrol Name')}
                                                        value={beacon.targetName ? beacon.targetName : t('InCompleted')}
                                                        labelValueClassName={'label-dot min-w-none'}
                                                    />
                                                </Popup>
                                            </Marker>
                                        );
                                    })}

                                {selectedFloorStr === 'entire' && (
                                    <CustomGeofenceLayer geofenceList={entireFloorGeofenceList} />
                                )}
                            </Map>
                        </PatrolMapContainer>
                    </CompoundCard.body.plain>
                </CompoundCard.body>
            </CompoundCard>
            <WidgetConfirmModal
                initModal={patrolStartModal}
                toggleModal={() => setPatrolStartModal(!patrolStartModal)}
                header={{ title: t('Notification', 'ConfirmModal') }}
                confirmText={
                    <div>
                        <div>{t('Changes to NFC tag information and checklist during patrol are not reflected.')}</div>
                        <div>{t('Would you like to start patrolling?')}</div>
                    </div>
                }
                okCallback={() => {
                    createPatrolStart({
                        patrolDiv: selectedDivOption.value,
                        patrolDivName: selectedDivOption.label,
                    });
                }}
            />
            <WidgetConfirmModal
                initModal={patrolEmptyModal}
                toggleModal={togglePatrolEmptyModal}
                header={{ title: t('Notification', 'ConfirmModal') }}
                confirmText={t("Today's Patrol is empty.")}
                removeCancel
            />
            <WidgetConfirmModal
                initModal={patrolAlreadyStartModal}
                toggleModal={togglePatrolAlreadyStartModal}
                header={{ title: t('Notification', 'ConfirmModal') }}
                confirmText={t('Patrol has already started.')}
                removeCancel
            />
            <WidgetConfirmModal
                initModal={patrolUnCompletedModal}
                toggleModal={togglePatrolUnCompletedModal}
                header={{ title: t('Notification', 'ConfirmModal') }}
                confirmText={
                    <>
                        <div>{t('The patrol area has not been completed.')}</div>
                        <div>{t('Do you still want to end patrol?')}</div>
                    </>
                }
                okCallback={() => {
                    createPatrolEnd({
                        patrolDiv: selectedDivOption.value,
                        patrolDivName: selectedDivOption.label,
                        patrolNum: recentPatrol.patrolNum,
                    });
                }}
            />
            <WidgetConfirmModal
                initModal={patrolAlreadyEndModal}
                toggleModal={togglePatrolAlreadyEndModal}
                header={{ title: t('Notification', 'ConfirmModal') }}
                confirmText={t('Patrol has already end.')}
                removeCancel
            />
        </>
    );
};

export default PatrolDuty;
