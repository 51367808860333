import React, { useState, useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';

import { useTranslation, useAsync, useColumns, useConfirmModal } from '@hooks';
import { Select, Button, Table, Checkbox } from '@components';

import { getAssetListApi } from 'api/asset';
import { updateStatusBatchApi } from 'api/status';

import DetailModalHeader from '../../Components/DetailModalHeader';
import DetailLabel from '../../Components/DetailLabel';

const PAGE_SIZE = 50;
const NO_STATUS = -1;

const initSearchParam = { flowScheme: null, status: null };
const initTransitionParam = { flowNum: null, statusNum: null, targetNums: [] };

const TransitionBatchModal = ({ isOpen, toggle }) => {
    const t = useTranslation('Status');
    const buttonT = useTranslation('Button');
    const tableT = useTranslation('Table');

    const { flowSchemeListInfo, mappedStatusInfo } = useSelector(state => state.StatusInfo);

    const [searchParam, setSearchParam] = useState(initSearchParam);
    const [transitionParam, setTransitionParam] = useState(initTransitionParam);

    const { filteredList, noneStatusList } = useMemo(() => {
        let filteredList = [];
        let noneStatusList = [];
        if (searchParam.flowScheme) {
            const statusList = mappedStatusInfo[searchParam.flowScheme.schemeNum];
            if (statusList) {
                filteredList = statusList.map(v => ({ value: v.statusNum, label: v.statusName }));
            }
            noneStatusList = [{ value: NO_STATUS, label: t('No status') }].concat(filteredList);
        }
        return { filteredList, noneStatusList };
    }, [t, searchParam.flowScheme, mappedStatusInfo]);

    const [assetList, setAssetList] = useState({ rows: [] });

    const { promise: getAssetList, state: getAssetListState } = useAsync({
        promise: getAssetListApi,
        fixedParam: { pageSize: PAGE_SIZE },
        resolve: res => {
            setAssetList({ ...res, pageSize: PAGE_SIZE });
        },
        reject: err => {
            console.error(err);
        },
    });
    const { promise: updateStatusBatch } = useAsync({
        promise: updateStatusBatchApi,
        resolve: () => {
            toggleSaveCompleteModal();
        },
        reject: () => {
            toggleSaveFailureModal();
        },
    });

    const handleHeaderCheckBox = e => {
        const { checked } = e.currentTarget;
        if (checked) {
            setTransitionParam({
                ...transitionParam,
                targetNums: assetList.rows.map(v => v.targetNum),
            });
        } else {
            setTransitionParam({ ...transitionParam, targetNums: [] });
        }
    };

    const handleCheckBox = value => {
        const exist = transitionParam.targetNums.find(v => value === v);
        const targetNums = exist
            ? transitionParam.targetNums.filter(v => v !== value)
            : transitionParam.targetNums.concat(value);

        setTransitionParam({ ...transitionParam, targetNums });
    };

    const assetListColumns = useColumns(
        [
            {
                Header: (
                    <Checkbox
                        inputName="checkAll"
                        type="checkbox"
                        className="m-0"
                        disabled={!assetList.rows.length}
                        checked={!!assetList.rows.length && transitionParam.targetNums.length === assetList.rows.length}
                        handleChecked={handleHeaderCheckBox}
                    />
                ),
                headerClassName: 'flex-center',
                className: 'p-0',
                width: 40,
                accessor: 'selector',
                Cell: ({ row: { original } }) => {
                    const value = original.targetNum;
                    const exist = transitionParam.targetNums.find(v => value === v);
                    const handleChecked = e => {
                        e.stopPropagation();
                        handleCheckBox(value);
                    };
                    return (
                        <Checkbox
                            key={value}
                            inputName="check"
                            type="checkbox"
                            className="m-0"
                            checked={exist}
                            handleChecked={handleChecked}
                        />
                    );
                },
            },
            {
                Header: 'Number',
                accessor: 'targetNum',
            },
            {
                Header: 'Name',
                accessor: 'targetName',
            },
            {
                Header: 'Category',
                accessor: 'categoryNamePath',
            },
        ],
        tableT,
        [assetList, transitionParam],
    );

    const callList = searchParam => {
        const searchParams = {};

        if (searchParam.flowScheme) {
            searchParams.flowNum = searchParam.flowScheme.flowNum;
        }
        if (searchParam.status && searchParam.status.value !== NO_STATUS) {
            if (searchParam.status.value) {
                searchParams.statusNums = searchParam.status.value + '';
            } else {
                searchParams.includeStatus = 'N';
            }
        }
        getAssetList(searchParams);
    };

    const onPageChange = pageIndex => {
        if (getAssetListState.request) {
            getAssetList({ ...getAssetListState.request, page: pageIndex });
        }
    };

    const { toggleModal: toggleSaveConfirmModal, Modal: SaveConfirmModal } = useConfirmModal({
        initModal: false,
        confirmText: t('Do you want to apply bulk changes?'),
        okCallback: () => {
            updateStatusBatch(transitionParam);
        },
    });
    const { toggleModal: toggleSaveCompleteModal, Modal: SaveCompleteModal } = useConfirmModal({
        initModal: false,
        confirmText: t('Save is complete.'),
        removeCancel: true,
        callbackParam: searchParam,
        okCallback: searchParam => {
            callList(searchParam);
            setTransitionParam({ ...transitionParam, targetNums: [] });
        },
    });
    const { toggleModal: toggleSaveFailureModal, Modal: SaveFailureModal } = useConfirmModal({
        initModal: false,
        removeCancel: true,
        confirmText: t('Save is failure.'),
    });

    const { toggleModal: toggleRequestRequiredModal, Modal: RequestRequiredModal } = useConfirmModal({
        initModal: false,
        removeCancel: true,
        confirmText: t('Please select one or more assets.'),
    });

    useEffect(() => {
        return () => {
            setSearchParam(initSearchParam);
            setTransitionParam(initTransitionParam);
            setAssetList({ rows: [] });
        };
    }, [isOpen]);

    return (
        <>
            <Modal
                container={document.getElementsByClassName('app-container')[0]}
                isOpen={isOpen}
                toggle={toggle}
                className={'pnt-modal'}
                size="lg"
            >
                <DetailModalHeader
                    headerTitleStyle={{ maxWidth: '100%' }}
                    title={t('Transition batch')}
                    subTitle={t(
                        'This is a function to collectively change the status of selected assets to other statuses.',
                    )}
                />
                <ModalBody className="w-100 d-flex flex-column gap-3">
                    <DetailLabel
                        required
                        name={t('Status flow scheme')}
                        value={
                            <Select
                                value={searchParam.flowScheme}
                                name="flowNum"
                                options={flowSchemeListInfo.rows || []}
                                className="form-must w-90"
                                onChange={selected => {
                                    setSearchParam({
                                        ...searchParam,
                                        flowScheme: selected,
                                        status: null,
                                    });
                                    setTransitionParam({
                                        ...transitionParam,
                                        flowNum: selected.flowNum,
                                        statusNum: null,
                                        targetNums: [],
                                    });
                                }}
                                disabled={getAssetListState.isLoading}
                                valueKey="flowNum"
                                labelKey="flowName"
                                customControlStyles={{ width: '100%' }}
                                customMenuStyles={{ width: '100%' }}
                                customOptionStyles={{ width: '100%' }}
                            />
                        }
                    />
                    <DetailLabel
                        required
                        name={t('Transition')}
                        value={
                            <div style={{ width: '88%', display: 'grid', gridTemplateColumns: '1fr 30px 1fr' }}>
                                <Select
                                    value={searchParam.status}
                                    name="statusNum"
                                    options={noneStatusList}
                                    disabled={getAssetListState.isLoading || !noneStatusList.length}
                                    className="form-must w-100"
                                    onChange={selected => {
                                        setSearchParam({
                                            ...searchParam,
                                            status: selected,
                                            targetNums: [],
                                        });
                                        callList({ ...searchParam, status: selected });
                                    }}
                                    customControlStyles={{ width: '100%' }}
                                    customMenuStyles={{ width: '100%' }}
                                    customOptionStyles={{ width: '100%' }}
                                />
                                <div className="flex-center">
                                    <span className="material-icons-round">arrow_forward_ios</span>
                                </div>
                                <Select
                                    value={transitionParam}
                                    name={'targetStatusNum'}
                                    options={filteredList}
                                    disabled={getAssetListState.isLoading || !filteredList.length}
                                    className="form-must w-100"
                                    onChange={selected => {
                                        setTransitionParam({ ...transitionParam, statusNum: selected.value });
                                    }}
                                    customControlStyles={{ width: '100%' }}
                                    customMenuStyles={{ width: '100%' }}
                                    customOptionStyles={{ width: '100%' }}
                                />
                            </div>
                        }
                    />
                    <div className="mt-1" style={{ height: 500 }}>
                        <Table
                            data={assetList}
                            columns={assetListColumns}
                            textAlign="center"
                            onPageChange={onPageChange}
                            onTrClick={e => handleCheckBox(e.targetNum)}
                            loading={getAssetListState.isLoading}
                        />
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button className="color-brand" onClick={toggle}>
                        {buttonT('Close')}
                    </Button>
                    <Button
                        className="btn-brand flex-center gap-1"
                        iconName="check"
                        onClick={() => {
                            if (transitionParam.targetNums.length) {
                                toggleSaveConfirmModal();
                            } else {
                                toggleRequestRequiredModal();
                            }
                        }}
                    >
                        {t('Execution')}
                    </Button>
                </ModalFooter>
            </Modal>
            <SaveConfirmModal />
            <SaveCompleteModal />
            <SaveFailureModal />
            <RequestRequiredModal />
        </>
    );
};

export default TransitionBatchModal;
