import React, { useContext, useEffect, useState } from 'react';

import { Button, Label, LoadingBlock, TextInput } from '@components';
import { useAsync, useTranslation } from '@hooks';
import WidgetConfirmModal from '../../../Components/WidgetConfirmModal';

import { getBattleStatusAPI, putBattleModifyAPI } from '@api/military';

import CompoundCard from '../../../Components/CompoundCard';

import cx from 'classnames';

import moment from 'moment';

import { StateConversionDispatchContext, StateConversionStateContext } from '../index';
import { setConjuncture, setConjuncturePersonnelByFloor, setStartTime } from '../stateConversionReducer';

import { nanoid } from '@reduxjs/toolkit';

import {
    DetailsByFloorContainer,
    DetailedContainer,
    DetailedHeader,
    DetailedBody,
    DetailedContent,
    DetailedHeaderWrapper,
    DetailedBodyWrapper,
    DetailedInputWrapper,
    DetailedInput,
    NoDataWrapper,
    NoDataComponent,
} from '../style';

const DetailsByFloor = ({ loading }) => {
    const t = useTranslation('State Conversion');

    const dispatch = useContext(StateConversionDispatchContext);
    const {
        selectedGeofence,
        conjuncturePersonnelByFloor,
        conjunctureStatus,
        lastConjunctureStatus: { battleNum },
    } = useContext(StateConversionStateContext);
    const { complete, incomplete, geofenceEndTime, manualReason } = conjuncturePersonnelByFloor;

    const [placementReason, setPlacementReason] = useState('');
    const handlePlacementReason = e => {
        const { value } = e.target;
        setPlacementReason(value);
    };

    const [manualPlacementDisabled, setManualPlacementDisabled] = useState(false);

    const [failedConjunctureModify, setFailedConjunctureModify] = useState(false);
    const [failedConjunctureModifyForAlreadyDone, setFailedConjunctureModifyForAlreadyDone] = useState(false);

    const handleOKButton = () => {
        if (manualPlacementDisabled) {
            return;
        }
        if (selectedGeofence && selectedGeofence.fcNum) {
            putBattleModify({
                battleNum,
                fcNum: selectedGeofence.fcNum,
                manualReason: placementReason,
            });
        }
    };

    const { promise: putBattleModify } = useAsync({
        promise: putBattleModifyAPI,
        resolve: res => {
            const { manualReason, modDate: geofenceEndTime } = res;
            dispatch(setConjuncturePersonnelByFloor({ geofenceEndTime, complete, incomplete, manualReason }));
            getBattleStatus();
        },
        reject: err => {
            const {
                data: { code },
            } = err;

            if (code === '7002') {
                setFailedConjunctureModifyForAlreadyDone(true);
            } else {
                console.error(err);
                setFailedConjunctureModify(true);
            }
            setPlacementReason('');
        },
    });

    const { promise: getBattleStatus } = useAsync({
        promise: getBattleStatusAPI,
        resolve: res => {
            dispatch(setConjuncture(res));

            if (res.conjunctureStatus === 'Y') {
                dispatch(setStartTime(res.startTime));
            }
        },
        reject: err => {
            console.error(err);
        },
    });

    useEffect(() => {
        if (conjunctureStatus === 'Y' || !selectedGeofence) {
            setPlacementReason('');
            return;
        }
        if (selectedGeofence) {
            setPlacementReason(manualReason || ' ');
        }
    }, [conjuncturePersonnelByFloor]);

    useEffect(() => {
        setManualPlacementDisabled(
            conjunctureStatus === 'N' ||
                !selectedGeofence ||
                geofenceEndTime ||
                (!complete.length && !incomplete.length),
        );
    }, [conjunctureStatus, selectedGeofence, geofenceEndTime, complete, incomplete]);

    return (
        <>
            <DetailsByFloorContainer>
                <CompoundCard.header>{t('Details')}</CompoundCard.header>
                <CompoundCard.body className="h-90">
                    <CompoundCard.body.plain>
                        <LoadingBlock blocking={loading}>
                            <DetailedContainer>
                                <Label
                                    name={`${t('End Time')}:`}
                                    value={
                                        !!selectedGeofence && !!geofenceEndTime
                                            ? moment.unix(geofenceEndTime).format('YYYY-MM-DD HH:mm:ss')
                                            : '-'
                                    }
                                    labelValueClassName="pnt-txt txt-bold"
                                    labelGroupClassName="py-2"
                                />
                                <DetailedHeaderWrapper>
                                    <DetailedHeader title={t('Completed People')} $bgColor="#3e66fb">
                                        {t('Completed People')}
                                    </DetailedHeader>
                                    <DetailedHeader title={t('Incompleted People')} $bgColor="#ff3333">
                                        {t('Incompleted People')}
                                    </DetailedHeader>
                                </DetailedHeaderWrapper>
                                <DetailedBodyWrapper>
                                    {!!complete.length || !!incomplete.length ? (
                                        <>
                                            <DetailedBody>
                                                {!!complete.length &&
                                                    complete.map(name => {
                                                        return (
                                                            <DetailedContent key={nanoid()} title={name}>
                                                                {name}
                                                            </DetailedContent>
                                                        );
                                                    })}
                                            </DetailedBody>
                                            <DetailedBody>
                                                {!!incomplete.length &&
                                                    incomplete.map(name => {
                                                        return (
                                                            <DetailedContent key={nanoid()} title={name}>
                                                                {name}
                                                            </DetailedContent>
                                                        );
                                                    })}
                                            </DetailedBody>
                                        </>
                                    ) : (
                                        <NoDataWrapper>
                                            <NoDataComponent>{t('No data', 'Table')}</NoDataComponent>
                                        </NoDataWrapper>
                                    )}
                                </DetailedBodyWrapper>
                            </DetailedContainer>
                            <DetailedInputWrapper>
                                <DetailedInput>
                                    <label className="cursor-default ms-1">{t('Reason')}</label>
                                    <TextInput
                                        inputGroupClassName="w-80"
                                        placeholder={t('Enter a manual placement reason')}
                                        maxlength={100}
                                        value={geofenceEndTime ? manualReason : placementReason}
                                        handleChange={handlePlacementReason}
                                        disabled={manualPlacementDisabled}
                                    />
                                </DetailedInput>
                                <Button
                                    className={cx('btn-lightgray mb-2', manualPlacementDisabled && 'form-disable')}
                                    onClick={handleOKButton}
                                >
                                    {t('Complete a manual batch')}
                                </Button>
                            </DetailedInputWrapper>
                        </LoadingBlock>
                    </CompoundCard.body.plain>
                </CompoundCard.body>
            </DetailsByFloorContainer>
            <WidgetConfirmModal
                initModal={failedConjunctureModify}
                toggleModal={() => setFailedConjunctureModify(!failedConjunctureModify)}
                confirmText={t('Your request was failed.')}
                removeCancel
            />
            <WidgetConfirmModal
                initModal={failedConjunctureModifyForAlreadyDone}
                toggleModal={() => setFailedConjunctureModifyForAlreadyDone(!failedConjunctureModifyForAlreadyDone)}
                confirmText={t('This has already been done.')}
                removeCancel
            />
        </>
    );
};

export default DetailsByFloor;
