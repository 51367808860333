import React, { useMemo, useState, useContext } from 'react';

import cx from 'classnames';

import { Button, Select as ReactSelect, TextInput } from 'Components/Common';
import useTranslation from '@hooks/useTranslation';

import GroupSingleTreeSelect from './GroupSingleTreeSelect';
import { TriggerLabel } from './Parts';

import { TriggerDispatchContext, TriggerStateContext } from './index';
import {
    addTrigger,
    checkManual,
    deleteTrigger,
    MANUAL_GROUP_KEY,
    TRIGGER_SUB_TYPE_GROUP,
    TRIGGER_SUB_TYPE_USER,
    TRIGGER_TYPE_MANUAL,
    TRIGGER_VALUE_TYPE_EXCLUDE,
    TRIGGER_VALUE_TYPE_INCLUDE,
} from './triggerReducer';

const ManualInputGroup = () => {
    const t = useTranslation('Status');
    const dispatch = useContext(TriggerDispatchContext);
    const { manualOn, triggerRelationJson, userList, userGroupList } = useContext(TriggerStateContext);
    const { triggers } = triggerRelationJson.data[MANUAL_GROUP_KEY]
        ? triggerRelationJson.data[MANUAL_GROUP_KEY]
        : { triggers: [] };

    const manualOptions = useMemo(() => {
        return [
            { value: TRIGGER_VALUE_TYPE_INCLUDE, label: t('Include') },
            { value: TRIGGER_VALUE_TYPE_EXCLUDE, label: t('Exclude') },
        ];
    }, [t]);

    const targetOptions = useMemo(() => {
        return [
            { value: TRIGGER_SUB_TYPE_USER, label: t('User') },
            { value: TRIGGER_SUB_TYPE_GROUP, label: t('Group') },
        ];
    }, [t]);

    const [manualSelectedType, setManualSelectedType] = useState({ value: 'include', label: t('Include') });
    const [manualSelectedTarget, setManualSelectedTarget] = useState({ value: 'user', label: t('User') });

    const [selectedUser, setSelectedUser] = useState(null);
    const [selectedGroup, setSelectedGroup] = useState(null);
    const [focusedSelect, setFocusedSelect] = useState(false);

    const handleAddManualClick = type => {
        if (type === TRIGGER_SUB_TYPE_USER) {
            if (selectedUser) {
                dispatch(
                    addTrigger({
                        groupKey: MANUAL_GROUP_KEY,
                        triggerType: TRIGGER_TYPE_MANUAL,
                        triggerSubType: manualSelectedTarget.value,
                        triggerValue: { type: manualSelectedType.value, value: selectedUser.userNum },
                    }),
                );
                setFocusedSelect(false);
                setSelectedUser(null);
            } else {
                setFocusedSelect(true);
            }
        } else if (type === TRIGGER_SUB_TYPE_GROUP) {
            if (selectedGroup) {
                dispatch(
                    addTrigger({
                        groupKey: MANUAL_GROUP_KEY,
                        triggerType: TRIGGER_TYPE_MANUAL,
                        triggerSubType: manualSelectedTarget.value,
                        triggerValue: { type: manualSelectedType.value, value: selectedGroup.groupNum },
                    }),
                );
                setFocusedSelect(false);
                setSelectedGroup(null);
            } else {
                setFocusedSelect(true);
            }
        }
    };

    return (
        <>
            <div className={'input-container'}>
                <div>
                    <input
                        id={'manual'}
                        checked={!!manualOn}
                        type="checkbox"
                        name={'manual'}
                        onChange={() => {
                            dispatch(checkManual(!manualOn));
                        }}
                    />
                    <label htmlFor={'manual'} className={'input-label'}>
                        <TriggerLabel label={t('Manual')} />
                    </label>
                </div>

                <ReactSelect
                    name={'manualSelectedType'}
                    value={manualSelectedType}
                    options={manualOptions}
                    onChange={selected => setManualSelectedType(selected)}
                    disabled={!manualOn}
                    className="w-100"
                    customControlStyles={{ width: '100%' }}
                    customMenuStyles={{ width: '100%' }}
                    customOptionStyles={{ width: '100%' }}
                />
                <ReactSelect
                    name={'manualSelectedTarget'}
                    value={manualSelectedTarget}
                    options={targetOptions}
                    onChange={selected => setManualSelectedTarget(selected)}
                    disabled={!manualOn}
                    className="w-100"
                    customControlStyles={{ width: '100%' }}
                    customMenuStyles={{ width: '100%' }}
                    customOptionStyles={{ width: '100%' }}
                />

                {manualSelectedTarget.value === TRIGGER_SUB_TYPE_USER ? (
                    <ReactSelect
                        name="userSelect"
                        value={selectedUser}
                        options={userList.filter(user => {
                            return !triggers.find(trigger => {
                                return (
                                    trigger.triggerSubType === TRIGGER_SUB_TYPE_USER &&
                                    trigger.triggerValue.value === user.value
                                );
                            });
                        })}
                        onChange={selected => setSelectedUser(selected)}
                        disabled={!manualOn}
                        className="w-100"
                        customControlStyles={
                            focusedSelect ? { border: '2px solid #FF7800', width: '100%' } : { width: '100%' }
                        }
                        customOptionStyles={{ width: '100%' }}
                        customMenuStyles={{ width: '100%' }}
                    />
                ) : (
                    <GroupSingleTreeSelect
                        warning={focusedSelect}
                        disabled={!manualOn}
                        originData={userGroupList}
                        onChange={selected => {
                            setSelectedGroup(selected);
                        }}
                    />
                )}
                <Button
                    className={cx('btn-secondary btn-icon-only', !manualOn && 'non-active')}
                    iconName="add"
                    disabled={!manualOn}
                    onClick={() => {
                        if (!manualOn) return;
                        if (manualSelectedTarget.value === TRIGGER_SUB_TYPE_USER) {
                            handleAddManualClick(TRIGGER_SUB_TYPE_USER);
                        } else {
                            handleAddManualClick(TRIGGER_SUB_TYPE_GROUP);
                        }
                    }}
                />
            </div>
            {userGroupList &&
                userList &&
                triggers.map(trigger => {
                    const { triggerKey, triggerValue, triggerSubType, groupKey } = trigger;
                    let target;
                    if (triggerSubType === TRIGGER_SUB_TYPE_USER) {
                        const userInfo = userList.find(v => v.userNum === triggerValue.value);
                        target = userInfo ? userInfo.userName : '';
                    } else {
                        const groupInfo = userGroupList.find(v => v.groupNum === triggerValue.value);
                        target = groupInfo ? groupInfo.groupName : '';
                    }
                    return (
                        <div className={'input-container'} key={triggerKey}>
                            <div />
                            <TextInput
                                inputGroupClassName={'width-150px'}
                                type={'text'}
                                name={'manual'}
                                value={manualOptions.find(v => v.value === triggerValue.type).label}
                                disabled
                            />
                            <TextInput
                                inputGroupClassName={'width-150px'}
                                type={'text'}
                                name={'type'}
                                value={targetOptions.find(v => v.value === triggerSubType).label}
                                disabled
                            />
                            <TextInput
                                inputGroupClassName={'width-220px'}
                                type={'text'}
                                name={'target'}
                                value={target}
                                disabled
                            />
                            <Button
                                iconName="close"
                                className="btn-danger btn-icon-only"
                                onClick={() => {
                                    dispatch(deleteTrigger({ groupKey, triggerKey }));
                                }}
                            />
                        </div>
                    );
                })}
        </>
    );
};

export default ManualInputGroup;
