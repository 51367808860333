import React from 'react';
import Tab from '../../../Common/Tab';
import Status from './Status';
import StatusScheme from './StatusScheme';
import StatusCategory from './StatusCategory';
import useTranslation from '../../../../util/hooks/useTranslation';
import useAsync from '../../../../util/hooks/useAsync';
import { fetchCategory } from '../../../../api/asset';
import { setAllCategoryList } from '../../../../reducers/Common/CategoryInfo';
import { useDispatch, useSelector } from 'react-redux';

const Scheme = () => {
    const t = useTranslation('Status');
    const dispatch = useDispatch();
    const { serviceCategory } = useSelector(state => state.CategoryInfo);

    useAsync({
        promise: fetchCategory,
        param: { isAll: 'Y', parentCode: serviceCategory },
        resolve: ({ rows }) => {
            dispatch(setAllCategoryList(rows));
        },
        immediate: true,
    });

    return (
        <Tab
            styles={{ header: { justifyContent: 'center' }, headerItem: { width: '32%' } }}
            activeTabId={'statusScheme'}
            tabs={[
                {
                    title: t('Status Scheme'),
                    tabId: 'statusScheme',
                    content: <StatusScheme />,
                },
                {
                    title: t('Status'),
                    tabId: 'status',
                    content: <Status />,
                },
                {
                    title: t('Status Category'),
                    tabId: 'statusCategory',
                    content: <StatusCategory />,
                },
            ]}
        />
    );
};

export default Scheme;
