import React, { useEffect, useReducer, useRef, useState, useCallback } from 'react';
import GridLayoutCard from '../../Components/GridLayoutCard';
import SensorList from './Components/SensorList';
import sensorStatusReducer, {
    initialState,
    setSelectedSensor,
    setSelectedSensorStatusList,
} from './sensorStatusReducer';
import useMonitor from '../../util/useMonitor';
import { getMappedSensorStatusApi } from '@api/asset';
import { fetchRealTimeSensorMonitoring } from '@api/monitoring';
import SelectedSensorStatusListPopup from './Components/SensorCount/Components/SelectedSensorStatusListPopup';
import MonthlyReportPrintSettingPopup from './Components/MonthlyReportPrintSettingPopup';
import { useAsync, useTranslation } from '@hooks';
import Button from '@components/Button';
import SensorCount from './Components/SensorCount';
import SensorDetailInfo from './Components/SensorDetailInfo';
import LocationPopup from './Components/SensorDetailInfo/Components/LocationPopup';
import cx from 'classnames';

import styled from 'styled-components';

const defaultData = {
    sensorStatusCountStat: {
        normal: 11,
        warning: 11,
        critical: 18,
        outOfRange: 78,
        undefine: 50,
        total: 168,
    },
    sensorTargetCountStat: {
        mappedSensorCount: 52,
        mappedTargetCount: 43,
    },
};

const SensorStatusCardContainer = styled.div`
    display: grid;
    height: 100%;
    width: 100%;
    //grid-template-columns: 300px repeat(auto-fit, minmax(500px, auto));
    grid-gap: 10px;
    grid-template-rows: 210px;

    &.sensor-report-view {
        grid-template-rows: 400px;
    }

    & .card:last-child {
        grid-column: 1 / 3;
    }

    & .sensor-report-card {
        grid-column: 1 / 3;
    }
`;

export const SensorStatusStateContext = React.createContext();
export const SensorStatusDispatchContext = React.createContext();

const SensorStatus = ({ children, widgetInfo, ...restProps }) => {
    const t = useTranslation('SensorStatus');
    const [state, dispatch] = useReducer(sensorStatusReducer, initialState);

    const { selectedStatus, selectedSensor, monthlyPrintPopup } = state;

    const [selectedSensorStatusPopup, setSelectedSensorStatusPopup] = useState(false);

    const widgetRef = useRef();
    const { config } = widgetInfo;

    const [statusCountData, setStatusCountData] = useState({
        prev: defaultData.sensorStatusCountStat,
        curr: defaultData.sensorStatusCountStat,
    });
    const [targetCountData, setTargetCountData] = useState({
        prev: { ...defaultData.sensorTargetCountStat },
        curr: { ...defaultData.sensorTargetCountStat },
    });

    useEffect(() => {
        if (selectedStatus) {
            handleToggleSelectedStatusPopup();
            getRealTimeSensorMonitoring({ status: selectedStatus });
        }
    }, [selectedStatus]);

    const { promise: getRealTimeSensorMonitoring, state: selectedSensorStatusListInfo } = useAsync({
        promise: fetchRealTimeSensorMonitoring,
        fixedParam: { isAll: 'Y' },
        resolve: res => {
            dispatch(setSelectedSensorStatusList(res));
        },
    });

    useMonitor({
        config,
        defaultData,
        fetchData: getMappedSensorStatusApi,
        makeData: ({ newData }) => {
            const { sensorTargetCountStat, sensorStatusCountStat } = newData;
            setTargetCountData(prev => ({
                prev: { ...prev.curr },
                curr: { ...sensorTargetCountStat },
            }));

            setStatusCountData(prev => ({
                prev: { ...prev.curr },
                curr: { ...sensorStatusCountStat },
            }));
        },
    });

    const handleToggleSelectedStatusPopup = useCallback(() => {
        setSelectedSensorStatusPopup(prev => !prev);
    }, []);

    const handleGoBackClick = () => {
        dispatch(setSelectedSensor(null));
    };

    return (
        <SensorStatusDispatchContext.Provider value={dispatch}>
            <SensorStatusStateContext.Provider value={state}>
                <GridLayoutCard
                    ref={widgetRef}
                    widgetInfo={widgetInfo}
                    bodyClassName={'sensor-status-container'}
                    headerAction={
                        <>
                            {selectedSensor && (
                                <Button className={'btn-brand'} onClick={handleGoBackClick}>
                                    {t('Back', 'Button')}
                                </Button>
                            )}
                        </>
                    }
                    {...restProps}
                >
                    {!selectedSensor ? (
                        <>
                            <SensorStatusCardContainer>
                                <SensorCount targetCountData={targetCountData} statusCountData={statusCountData} />
                                <SensorList config={config} />
                            </SensorStatusCardContainer>
                            <SelectedSensorStatusListPopup
                                modal={selectedSensorStatusPopup}
                                toggleModal={handleToggleSelectedStatusPopup}
                                cardRef={widgetRef}
                                selectedSensorStatusListInfo={selectedSensorStatusListInfo}
                            />
                            <MonthlyReportPrintSettingPopup modal={monthlyPrintPopup} cardRef={widgetRef} />
                        </>
                    ) : (
                        <>
                            <SensorStatusCardContainer
                                className={cx(selectedSensor && 'sensor-report-view')}
                                id={'printArea'}
                            >
                                <SensorDetailInfo />
                            </SensorStatusCardContainer>
                            <LocationPopup cardRef={widgetRef} />
                        </>
                    )}

                    {children}
                </GridLayoutCard>
            </SensorStatusStateContext.Provider>
        </SensorStatusDispatchContext.Provider>
    );
};

export default SensorStatus;
