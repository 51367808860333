import React, { useReducer, createContext, useRef, useState, useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';

import { fetchFloorList } from 'api/common';
import {
    fetchExcelLogApi,
    fetchScannerListApi,
    fetchScannerStateExcelLogExport,
    fetchScannerStateLogList,
} from 'api/log';

import { useConfirmModal, useTranslation, useColumns, useAsync } from '@hooks';
import { Table, Card, Page, Button } from '@components';

import { useDispatch } from 'react-redux';
import { setFloorList } from 'reducers/Common/FloorInfo';

import Search from './Components/Search';

import { excelExport } from '../util/util';
import * as column from '../../util/grid/column';

import moment from 'moment';

import scannerLogReducer, { initialState, setScannerList } from './scannerLogReducer';

const INTERVAL_TIME = 1000;

export const ScannerStateLogDispatchContext = createContext();
export const ScannerStateLogStateContext = createContext(null);

const ScannerStateLog = () => {
    const t = useTranslation('Scanner State Log');
    const columnT = useTranslation('CommonColumn');

    const storeDispatch = useDispatch();
    const [state, dispatch] = useReducer(scannerLogReducer, initialState);
    const { startDate, endDate } = state;

    const searchRef = useRef();
    const excelTimerRef = useRef();
    const match = useRouteMatch();

    useAsync({
        promise: fetchFloorList,
        resolve: response => {
            storeDispatch(setFloorList(response));
        },
        immediate: true,
        deps: [match.params.menuNum],
    });

    useAsync({
        promise: fetchScannerListApi,
        resolve: response => {
            dispatch(setScannerList(response));
        },
        immediate: true,
    });

    const columns = useColumns([
        column.number(),
        column.scannerName(),
        column.macAddress(),
        column.startDate(),
        column.endDate(),
    ]);

    const [scannerStateLogData, setScannerStateLogData] = useState({
        totalCount: 0,
        totalPage: 1,
        rows: [],
    });

    const { promise: getScannerStateLogList, state: scannerStateLogListInfo } = useAsync({
        promise: fetchScannerStateLogList,
        keepState: true,
        fixedParam: { pageSize: 15 },
        resolve: res => {
            const data = res.rows.map(log => {
                return {
                    logNum: log.logNum,
                    scannerName: log.scannerName,
                    macAddr: log.macAddr,
                    startDate: log.startDate,
                    endDate: log.endDate,
                    ...log,
                };
            });

            setScannerStateLogData({ ...res, rows: data });
        },
    });

    useEffect(() => {
        getScannerStateLogList({
            startDate: moment(startDate).unix(),
            endDate: moment(endDate).unix(),
        });
    }, []);

    const { promise: createNewLogFile } = useAsync({
        promise: fetchScannerStateExcelLogExport,
        fixedParam: {
            startDate: startDate ? moment(startDate).unix() : 0,
            endDate: endDate ? moment(endDate).unix() : moment().unix(),
            zipFileName: 'Scanner_State_Log',
            fileName: 'Scanner_State_Log',
            isDateFormat: 'Y',
            columnMetas: [
                {
                    key: 'logNum',
                    name: columnT('No.'),
                },
                {
                    key: 'scannerName',
                    name: columnT('Scanner Name'),
                },
                {
                    key: 'macAddr',
                    name: columnT('Mac Address'),
                },
                {
                    key: 'startDate',
                    isDateFormat: 'Y',
                    name: columnT('Start Date'),
                },
                {
                    key: 'endDate',
                    isDateFormat: 'Y',
                    name: columnT('End Date'),
                },
            ],
        },
        resolve: res => {
            const { filePath: url, reqNum } = res;
            if (url) {
                downloadWithURL(url, reqNum);
            }
        },
        reject: res => {
            console.log(res);
            //  toggleExcelModal();
        },
    });

    const { promise: fetchExcelLog } = useAsync({
        promise: fetchExcelLogApi,
        resolve: res => {
            const { dataSettingStatusType, filePath: url, fileName } = res;
            if (dataSettingStatusType === 'COMPLETED') {
                clearExcelTimer();
                excelExport(url, fileName);
            } else if (dataSettingStatusType === 'FAILED') {
                clearExcelTimer();
                toggleExcelDownloadResultModal();
            }
        },
    });

    const downloadWithURL = (url, reqNum) => {
        clearExcelTimer();
        excelTimerRef.current = setInterval(() => {
            fetchExcelLog({ reqNum: reqNum });
        }, INTERVAL_TIME);
    };
    const clearExcelTimer = () => {
        if (!!excelTimerRef.current) {
            clearInterval(excelTimerRef.current);
            excelTimerRef.current = null;
        }
    };

    const handlePageChange = pageIndex => {
        if (
            scannerStateLogListInfo.request &&
            (scannerStateLogListInfo.request.keyword ||
                scannerStateLogListInfo.request.startDate ||
                scannerStateLogListInfo.request.endDate)
        ) {
            getScannerStateLogList({ ...scannerStateLogListInfo.request, page: pageIndex });
        } else {
            getScannerStateLogList({ page: pageIndex });
        }
    };

    const handleExportFileClick = () => {
        if (!scannerStateLogData.rows || !scannerStateLogData.rows.length) {
            toggleExcelDownloadNoDataModal();
            return;
        }

        const { request } = scannerStateLogListInfo;

        delete request.page;
        delete request.pageSize;

        request.isAll = 'Y';

        createNewLogFile({ ...request });
    };

    const { Modal: ExcelDownloadResultModal, toggleModal: toggleExcelDownloadResultModal } = useConfirmModal({
        initModal: false,
        header: { title: t('Result', 'ConfirmModal') },
        confirmText: t('Failed to download the excel.', 'ConfirmModal'),
    });

    const { Modal: ExcelDownloadNoDataModal, toggleModal: toggleExcelDownloadNoDataModal } = useConfirmModal({
        initModal: false,
        header: { title: t('Notification', 'ConfirmModal') },
        confirmText: t('There is no data.', 'ConfirmModal'),
        removeCancel: true,
    });

    // const { toggleModal: toggleExcelModal, Modal: ExcelModal } = useConfirmModal({
    //     initModal: false,
    //     header: {
    //         title: t('Excel Download', 'Location Log List'),
    //     },
    //     confirmText: t(
    //         'You can see and download the Excel File that you have requested under `Report > Location Log List` menu.',
    //         'Location Log List',
    //     ),
    //     removeCancel: true,
    // });

    return (
        <ScannerStateLogDispatchContext.Provider value={dispatch}>
            <ScannerStateLogStateContext.Provider value={state}>
                <Page menuPath={[t('Report', 'Menu'), t('Scanner State Log', 'Menu')]} className="h-100">
                    <Search getScannerStateLogList={getScannerStateLogList} />
                    <Card
                        innerRef={searchRef}
                        className="report-container"
                        header={{
                            title: t('Scanner State Log'),
                            action: (
                                <Button className="btn-secondary" onClick={handleExportFileClick}>
                                    {t('Export', 'Button')}
                                </Button>
                            ),
                        }}
                    >
                        <div style={{ height: '35rem' }}>
                            <Table
                                data={{ ...scannerStateLogData, pageSize: 15 }}
                                columns={columns}
                                onPageChange={handlePageChange}
                                loading={scannerStateLogListInfo.isLoading}
                            />
                        </div>
                    </Card>
                    {/*<ExcelModal />*/}
                    <ExcelDownloadResultModal />
                    <ExcelDownloadNoDataModal />
                </Page>
            </ScannerStateLogStateContext.Provider>
        </ScannerStateLogDispatchContext.Provider>
    );
};

export default ScannerStateLog;
