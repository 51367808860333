import moment from 'moment';

export const department = ({ ...restProps } = {}) => {
    return {
        Header: 'Department',
        accessor: 'departmentName',
        headerClassName: 'text-ellipsis',
        className: 'text-ellipsis',
        ...restProps,
    };
};

export const name = ({ ...restProps } = {}) => {
    return {
        Header: 'Name',
        accessor: 'targetName',
        headerClassName: 'text-ellipsis',
        className: 'text-ellipsis',
        ...restProps,
    };
};

export const status = ({ ...restProps } = {}) => {
    return {
        Header: 'Status',
        accessor: 'status',
        headerClassName: 'text-ellipsis',
        className: 'text-ellipsis',
        ...restProps,
    };
};

export const changedTime = ({ ...restProps } = {}) => {
    return {
        Header: 'Changed Time',
        accessor: 'lastDate',
        headerClassName: 'text-ellipsis',
        className: 'text-ellipsis',
        formatTitle: ({ value }) => {
            if (value) {
                return moment.unix(value).format('YYYY/MM/DD HH:mm:ss');
            }
            return '-';
        },
        Cell: ({ value }) => {
            if (value) {
                return moment.unix(value).format('YYYY/MM/DD HH:mm:ss');
            }
            return '-';
        },
        ...restProps,
    };
};
