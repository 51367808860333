import React, { useState, useEffect, useContext } from 'react';

import { useAsync, useTranslation } from '@hooks';
import { TimePicker, Label } from '@components';

import { getHealthCareListAPI } from '@api/military';

import ConditionalSearch from './ConditionalSearch';

import { HealthCareMonitoringDispatchContext, HealthCareMonitoringStatusContext } from '../index';
import {
    setEndDate,
    setHealthCareList,
    setInitialSensorData,
    setIsHealthCareListLoading,
    setStartDate,
} from '../healthCareMonitoringReducer';

import moment from 'moment';

const Search = ({ handleSearchClick }) => {
    const t = useTranslation('Health Care Monitoring');

    const { startDate, endDate } = useContext(HealthCareMonitoringStatusContext);
    const dispatch = useContext(HealthCareMonitoringDispatchContext);

    const [tempDate, setTempDate] = useState({
        startDate: null,
        endDate: null,
    });

    const handleStartDate = value => {
        if (!value || isNaN(value)) {
            value = moment(endDate).subtract(29, 'days').valueOf();
        }
        const dayDiff = Math.abs(moment(value).diff(moment(tempDate.endDate), 'days'));
        if (dayDiff > 29) {
            const changedEndDate = moment(value).add(29, 'days').valueOf();
            setTempDate(prevState => ({ ...prevState, endDate: changedEndDate }));
        }
        setTempDate(prevState => ({ ...prevState, startDate: value }));
    };

    const handleEndDate = value => {
        if (!value || isNaN(value)) {
            const date = moment(startDate).add(29, 'days').valueOf();
            const now = moment().valueOf();
            value = date > now ? now : date;
        }
        const dayDiff = Math.abs(moment(value).diff(moment(tempDate.startDate), 'days'));
        if (dayDiff > 29) {
            const changedStartDate = moment(value).subtract(29, 'days').valueOf();
            setTempDate(prevState => ({ ...prevState, startDate: changedStartDate }));
        }
        setTempDate(prevState => ({ ...prevState, endDate: value }));
    };

    const handleSearch = (inputOption, inputValue, tempSelectedFilterOption) => {
        const { bookmark, rank, department, jobtitle, status } = tempSelectedFilterOption;
        dispatch(setStartDate(tempDate.startDate));
        dispatch(setEndDate(tempDate.endDate));

        const options = {
            rank: rank && rank['value'] !== 'all' ? rank['value'] : null,
            department: department && department['value'] !== 'all' ? department['value'] : null,
            jobtitle: jobtitle && jobtitle['value'] !== 'all' ? jobtitle['value'] : null,
            status: status && status['value'] !== 'all' ? status['value'] : null,
        };

        getHealthCareList({
            bookmark: bookmark ? 'Y' : 'N',
            options,
            opt: inputOption && inputOption['value'] !== 'all' ? inputOption['value'] : null,
            keyword: inputValue,
        }).finally(() => handleSearchClick());

        dispatch(setInitialSensorData());
    };

    const { promise: getHealthCareList, state: healthCareListState } = useAsync({
        promise: getHealthCareListAPI,
        fixedParam: { isAll: 'Y' },
        resolve: res => {
            const { rows } = res;
            if (rows) {
                dispatch(setHealthCareList({ rows }));
            }
        },
        reject: err => {
            console.error(err);
            dispatch(setHealthCareList({ rows: [] }));
        },
    });

    useEffect(() => {
        dispatch(setIsHealthCareListLoading(healthCareListState.isLoading));
    }, [healthCareListState.isLoading]);

    useEffect(() => {
        setTempDate({ startDate, endDate });
    }, []);

    return (
        <ConditionalSearch handleSearchClick={handleSearch} className="gap-3">
            <Label
                name={t('Date')}
                labelValueClassName="min-w-none label-dot-black label-info text-nowrap"
                infoBoxValue={t('You can search for up to 30 days.')}
                value={
                    <>
                        <div style={{ minWidth: '10rem' }}>
                            <TimePicker
                                value={tempDate.startDate}
                                maxDate={tempDate.endDate || moment().valueOf()}
                                handleChange={handleStartDate}
                                valueType="ms"
                                withoutTime
                            />
                        </div>
                        <div className="mb-2 text-center">~</div>
                        <div style={{ minWidth: '10rem' }}>
                            <TimePicker
                                value={tempDate.endDate}
                                minDate={tempDate.startDate}
                                maxDate={moment().valueOf()}
                                handleChange={handleEndDate}
                                valueType="ms"
                                withoutTime
                            />
                        </div>
                    </>
                }
            />
        </ConditionalSearch>
    );
};

export default Search;
