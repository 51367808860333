import { Margin } from 'react-to-pdf';
import { TWELVE_HOURS, TWENTY_FOUR_HOURS } from '../../utils';

export const TIME_LIST = {
    AtSea: [
        [
            { start: 8, end: 12, time: [2, 2] },
            { start: 12, end: 16, time: [2, 2] },
            { start: 16, end: 20, time: [2, 1, 1] },
            { start: 20, end: 24, time: [1, 1, 1, 1] },
        ],
        [
            { start: 0, end: 4, time: [1, 1, 1, 1] },
            { start: 4, end: 8, time: [1, 1, 2] },
        ],
    ],
    AtAnchor: [
        [
            { start: 18, end: 20, time: [2] },
            { start: 20, end: 2, time: [2, 2, 2] },
            { start: 2, end: 6, time: [2, 2] },
        ],
    ],
    AtOffAtAnchor: [
        [
            { start: 8, end: 14, time: [2, 2, 2] },
            { start: 14, end: 20, time: [2, 2, 2] },
            { start: 20, end: 2, time: [2, 2, 2] },
            { start: 2, end: 8, time: [2, 2, 2] },
        ],
    ],
};

export const EACH_TD_HEIGHT = {
    AtSea: '3.1rem',
    AtAnchor: '4.6rem',
    AtOffAtAnchor: '2.2rem',
};

export const TAKE_OVER_TABLE_COUNT = {
    AtSea: 3,
    AtAnchor: 2,
    AtOffAtAnchor: 2,
};

export const CHECK_NOTE = '이상 없음';

export const PATROL_DIVISION_PAGE_OPTIONS = {
    margin: Margin.SMALL,
    format: [297, 210],
    orientation: 'l',
    unit: 'mm',
};

export const DIVISION_OPTIONS = [
    { value: 'AtSea', label: 'At Sea', start: 8, end: 8 + TWENTY_FOUR_HOURS },
    { value: 'AtAnchor', label: 'At Anchor', start: 18, end: 18 + TWELVE_HOURS },
    { value: 'AtOffAtAnchor', label: 'At Off At Anchor', start: 8, end: 8 + TWENTY_FOUR_HOURS },
];
