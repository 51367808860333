import React, { useState } from 'react';

import { Label, Select as ReactSelect } from '@components';
import { useAsync, useTranslation } from '@hooks';

import { getStatusCategoryInfoApi } from '@api/status';

import { useDispatch } from 'react-redux';
import { editWidgetSettings } from '@reducer/Dashboards/DashboardEdit';

import { useSettings } from '../../util/useSettings';
import WidgetSettingModal from '../../Components/WidgetSettingModal';

const UtilizationSmallChartSetting = ({ widgetInfo, ...restProps }) => {
    const { config } = widgetInfo;
    const settings = useSettings(config);
    const { settings: customSettings } = config;
    const dispatch = useDispatch();
    const t = useTranslation('WidgetSetting');
    const [selectedOption, setSelectedOption] = useState({});
    const [options, setOptions] = useState([]);
    const [updatedWidgetInfo, setUpdatedWidgetInfo] = useState({});

    useAsync({
        promise: getStatusCategoryInfoApi,
        fixedParam: { isAll: 'Y' },
        immediate: true,
        resolve: res => {
            setOptions(
                res.rows.map(({ statusCategoryNum, statusCategoryName }) => {
                    return { value: statusCategoryNum, label: statusCategoryName };
                }),
            );

            if (!res.rows || !res.rows.length) {
                setSelectedOption({});
                return;
            }
            const found =
                res.rows.find(option => option.statusCategoryNum === settings.statusCategoryNum) || res.rows[0];
            const { statusCategoryNum: value, statusCategoryName: label } = found;
            setSelectedOption({ value, label });
        },
    });

    const handleSubmitClick = data => {
        const settings = {
            ...data,
            selectedOption: selectedOption,
            intervalTime: data.intervalTime,
            statusCategoryNum: selectedOption.value,
            ...updatedWidgetInfo,
        };

        if (customSettings) {
            dispatch(
                editWidgetSettings({
                    id: widgetInfo.id,
                    settings: {
                        ...customSettings,
                        ...settings,
                    },
                }),
            );
            return;
        }
        dispatch(editWidgetSettings({ id: widgetInfo.id, settings }));
    };

    const handleCancelClick = () => {
        setUpdatedWidgetInfo({});
    };

    const handleChange = selected => {
        setSelectedOption(selected);
    };

    return (
        <WidgetSettingModal
            headerTitle={t('Utilization')}
            widgetInfo={widgetInfo}
            hiddenHeader
            okCallback={handleSubmitClick}
            cancelCallback={handleCancelClick}
            {...restProps}
        >
            <Label
                name={t('Option')}
                labelGroupClassName={'w-100'}
                className={'w-100'}
                value={
                    <div className={'pnt-label--group w-100'}>
                        <ReactSelect
                            name={'statusCategoryNum'}
                            options={options}
                            value={selectedOption}
                            onChange={handleChange}
                            className={'w-100'}
                            customOptionStyles={{ width: '100%' }}
                            customMenuStyles={{ width: '100%' }}
                            customControlStyles={{ width: '100%' }}
                        />
                    </div>
                }
            />
        </WidgetSettingModal>
    );
};

export default UtilizationSmallChartSetting;
