import React, { useState } from 'react';

import Button from 'Components/Common/Button';
import { useAsync, useConfirmModal, useTranslation } from '@hooks';

import { postDashboardMonitoringSailModifyStatusAPI, getDashboardMonitoringSailStatusAPI } from 'api/military';

import cx from 'classnames';

const SailStatus = () => {
    const t = useTranslation('Integrated Monitor');

    const [status, setStatus] = useState(true);
    const handleSailButton = () => {
        if (!status) {
            toggleSailModifyConfirmModal();
        }
    };
    const handleAnchorButton = () => {
        if (status) {
            toggleSailModifyConfirmModal();
        }
    };

    useAsync({
        promise: getDashboardMonitoringSailStatusAPI,
        immediate: true,
        resolve: res => {
            const { state } = res;
            setStatus(state === 'Y');
        },
        reject: err => {
            console.error(err);
        },
    });

    const { promise: postDashboardMonitoringSailModifyStatus } = useAsync({
        promise: postDashboardMonitoringSailModifyStatusAPI,
        resolve: () => {
            setStatus(!status);
        },
        reject: err => {
            console.error(err);
            toggleSailModifyFailedModal();
        },
    });

    const { toggleModal: toggleSailModifyConfirmModal, Modal: SailModifyConfirmModal } = useConfirmModal({
        initModal: false,
        header: { title: t('Confirm', 'ConfirmModal') },
        confirmText: t('Do you want to change vessel status?'),
        okCallback: () => {
            postDashboardMonitoringSailModifyStatus({ state: status ? 'N' : 'Y' });
        },
    });

    const { toggleModal: toggleSailModifyFailedModal, Modal: SailModifyFailedModal } = useConfirmModal({
        initModal: false,
        header: { title: t('Fail', 'ConfirmModal') },
        confirmText: t('The request failed.', 'ErrorHandler'),
        removeCancel: true,
    });

    return (
        <>
            <div className="d-flex gap-2 mb-2">
                <Button className={cx('px-4', status ? 'btn-secondary' : 'btn-light')} onClick={handleSailButton}>
                    {t('Sailing')}
                </Button>
                <Button className={cx('px-4', !status ? 'btn-secondary' : 'btn-light')} onClick={handleAnchorButton}>
                    {t('At Anchor')}
                </Button>
            </div>
            <SailModifyConfirmModal />
            <SailModifyFailedModal />
        </>
    );
};

export default SailStatus;
