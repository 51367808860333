import { defaultActionCreator } from '../../../../../util/common/util';

const SET_COME_ITEMS = 'SET_COME_ITEMS';
const SET_GO_ITEMS = 'SET_GO_ITEMS';
const GO_ITEM = 'GO_ITEM';
const CHECK_ITEM = 'CHECK_ITEM';
const ADD_COME_ITEMS = 'ADD_COME_ITEMS';

export const setComeItems = param => defaultActionCreator(SET_COME_ITEMS, param);
export const setGoItems = param => defaultActionCreator(SET_GO_ITEMS, param);
export const goItem = param => defaultActionCreator(GO_ITEM, param);
export const checkItem = param => defaultActionCreator(CHECK_ITEM, param);
export const addComeItems = param => defaultActionCreator(ADD_COME_ITEMS, param);

export const initialState = {
    valueKey: 'value',
    labelKey: 'label',
    comeItems: [],
    goItems: [],
};

export const reducer = (state, action) => {
    const { valueKey } = state;
    switch (action.type) {
        case SET_COME_ITEMS:
            const comeItems = action.payload;
            return {
                ...state,
                goItems: state.goItems.filter(go => !comeItems.find(come => come[valueKey] === go[valueKey])),
                comeItems,
            };
        case SET_GO_ITEMS:
            const goItems = action.payload;
            return {
                ...state,
                goItems,
                comeItems: state.comeItems.filter(come => !goItems.find(go => come[valueKey] === go[valueKey])),
            };
        case GO_ITEM:
            const goItem = action.payload;
            return {
                ...state,
                goItems: state.goItems.concat(goItem),
                comeItems: state.comeItems.filter(come => come[valueKey] !== goItem[valueKey]),
            };
        case CHECK_ITEM:
            const checkedId = action.payload;
            return {
                ...state,
                comeItems: state.comeItems,
                goItems: state.goItems.map(v => {
                    if (v[valueKey] === checkedId) {
                        return { ...v, checked: !v.checked };
                    }
                    return v;
                }),
            };
        case ADD_COME_ITEMS:
            const candidate = state.goItems.filter(v => v.checked).map(v => ({ ...v, checked: false }));
            return {
                ...state,
                comeItems: state.comeItems.concat(candidate),
                goItems: state.goItems.filter(v => !v.checked),
            };
        default:
            throw new Error(`Unknown action type: ${action.type}`);
    }
};
