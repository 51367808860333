import React, { useContext, useEffect, useRef, useState } from 'react';

import { fetchGeofenceList } from 'api/common';
import { useAsync, useTranslation } from 'util/hooks';
import { Select as ReactSelect } from 'Components/Common';

import { useSelector } from 'react-redux';

import { setFloorInfo, setGeofenceList, setStateConversionList } from '../IntegratedMonitorReducer';
import { IntegratedMonitorDispatchContext, IntegratedMonitorStatusContext } from '../index';

const BOUNDARY_SETTING = 4;

const FloorSelect = ({ handleChange }) => {
    const t = useTranslation('SmartSafe');

    const { floorInfo, geofenceList, stateConversionList } = useContext(IntegratedMonitorStatusContext);
    const dispatch = useContext(IntegratedMonitorDispatchContext);

    const { selectableFloorList } = useSelector(state => state.FloorInfo);

    const wrapperRef = useRef(null);

    const [menuOpen, setMenuOpen] = useState(false);
    const toggleMenuOpen = () => {
        setMenuOpen(!menuOpen);
    };

    const handleTouchEnd = () => {
        if (!menuOpen) {
            setMenuOpen(true);
        }
    };

    const handleGeofenceList = selected => {
        const { floorId } = selected;

        getGeofenceList({ floor: floorId });
        if (floorId !== 'entire') {
            getStateConversionList({ floor: floorId, field1: BOUNDARY_SETTING });
        }
    };

    const handleSelectChange = selected => {
        if (typeof handleChange === 'function') {
            handleChange();
        }
        dispatch(setFloorInfo(selected));
    };

    useEffect(() => {
        const onClick = e => {
            if (!!wrapperRef.current) {
                const parent = wrapperRef.current;
                if (!parent || !parent.contains(e.target)) {
                    setMenuOpen(false);
                }
            }
        };
        document.addEventListener('click', onClick);
        return () => {
            document.removeEventListener('click', onClick);
        };
    }, []);

    useEffect(() => {
        if (selectableFloorList && !!selectableFloorList.length) {
            dispatch(setFloorInfo(selectableFloorList[0]));
        }
    }, [selectableFloorList]);

    useEffect(() => {
        handleGeofenceList(floorInfo);
    }, [floorInfo]);

    const { promise: getGeofenceList } = useAsync({
        promise: fetchGeofenceList,
        resolve: res => {
            const { rows } = res;
            // 국면 지오펜스를 제외하고 표출
            if (stateConversionList.length) {
                dispatch(
                    setGeofenceList(
                        rows.filter(({ fcNum }) => !stateConversionList.some(({ fcNum: num }) => num === fcNum)),
                    ),
                );
            } else {
                dispatch(setGeofenceList(rows));
            }
        },
        reject: err => {
            console.error(err);
        },
    });

    const { promise: getStateConversionList } = useAsync({
        promise: fetchGeofenceList,
        resolve: res => {
            const { rows } = res;
            dispatch(setStateConversionList(rows));
            // 국면 지오펜스를 제외하고 표출
            dispatch(
                setGeofenceList(geofenceList.filter(({ fcNum }) => !rows.some(({ fcNum: num }) => fcNum === num))),
            );
        },
        reject: err => {
            console.error(err);
        },
    });

    return (
        <div ref={wrapperRef} onClick={toggleMenuOpen} onTouchEnd={handleTouchEnd}>
            <ReactSelect
                name="floor"
                onChange={handleSelectChange}
                options={selectableFloorList}
                value={floorInfo}
                placeholder={t('No registered floor')}
                valueKey="floorId"
                labelKey="floorName"
                customControlStyles={{ width: '100%', minWidth: '9rem' }}
                customOptionStyles={{ width: '100%' }}
                customMenuStyles={{ width: '100%' }}
                disabled={!selectableFloorList.length}
                menuIsOpen={selectableFloorList.length ? menuOpen : undefined}
            />
        </div>
    );
};

export default FloorSelect;
