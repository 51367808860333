import React, { useRef, useContext, useEffect, useState, useCallback } from 'react';

import Map from './Map';

import L from 'leaflet';
import Control from 'react-leaflet-control';
import { Marker } from 'react-leaflet';

import { useAsync, useTranslation } from '@hooks';

import { fetchGeofenceList } from '@api/common';
import { getBattleGeofenceAPI } from '@api/military';

import CompoundCard from '../../../Components/CompoundCard';
import { StateConversionStateContext, StateConversionDispatchContext } from '../index';

import FloorTreeSelect from '../../../Components/FloorTreeSelect';
import CustomGeofenceLayer from './CustomGeofenceLayer';
import ImageOverlay from './ImageOverlay';

import {
    setFloorInfo,
    setGeofenceList,
    setSelectedGeofence,
    setConjuncturePersonnelByFloor,
    INITIAL_CONJUNCTURE_PERSONNEL_BY_FLOOR,
} from '../stateConversionReducer';

import { useSelector } from 'react-redux';

import redDot from '@asset/images/red-dot.svg';
import greenDot from '@asset/images/green-dot.svg';

const RedDot = new L.Icon({
    iconUrl: redDot,
    iconSize: [10, 10],
});

const GreenDot = new L.Icon({
    iconUrl: greenDot,
    iconSize: [10, 10],
});

const StateConversionMap = ({ handleLoading }) => {
    const t = useTranslation('State Conversion');

    const mapRef = useRef(null);

    const { tagListByFloor } = useSelector(state => state.TagInfo);
    const { conjuncture } = useSelector(state => state.Navy);
    const { floorList } = useSelector(state => state.FloorInfo);

    const {
        floorInfo,
        situation,
        geofenceList,
        selectedGeofence,
        conjunctureStatus,
        conjunctureGeofence,
        conjunctureComplete,
        conjunctureInComplete,
        lastConjunctureStatus,
    } = useContext(StateConversionStateContext);
    const { situation: lastSituation, battleNum } = lastConjunctureStatus;
    const dispatch = useContext(StateConversionDispatchContext);

    const [floorInfoState, setFloorInfoState] = useState(floorInfo || { floorId: 'entire' });
    const handleFloorChange = selected => {
        setFloorInfoState(selected[0]);
        dispatch(setFloorInfo(selected[0]));
    };

    const [geofenceInState, setGeofenceInState] = useState(0);

    const handleGeofenceLayerClick = ({ geofence, battleNum }) => {
        const { fcNum } = geofence;

        if (floorInfoState.floorId === 'entire') return;

        if (selectedGeofence && selectedGeofence.fcNum === fcNum) {
            abortBattleGeofence();
            dispatch(setSelectedGeofence(null));
            dispatch(setConjuncturePersonnelByFloor(INITIAL_CONJUNCTURE_PERSONNEL_BY_FLOOR));
            return;
        }

        dispatch(setSelectedGeofence(geofence));
        if (lastSituation.value === situation.value) {
            abortBattleGeofence();
            getBattleGeofence({ fcNum, battleNum });
        } else {
            dispatch(setConjuncturePersonnelByFloor(INITIAL_CONJUNCTURE_PERSONNEL_BY_FLOOR));
        }
    };

    const RealTimeMarker = useCallback(() => {
        if (conjunctureStatus !== 'Y') return null;
        if (!floorInfoState || !tagListByFloor || !tagListByFloor[floorInfoState.floorId]) return null;

        const complete =
            conjuncture && conjuncture['targetInFloor'] && conjuncture['targetInFloor'][floorInfoState.floorId]
                ? conjuncture['targetInFloor'][floorInfoState.floorId]
                : [];

        return tagListByFloor[floorInfoState.floorId].map(tagList => {
            const {
                location: { lat, lng },
                target: { targetId, targetName, targetNum },
            } = tagList;
            const isComplete = !!complete.length && !!complete.find(num => parseInt(num) === targetNum);

            return (
                <Marker key={targetId} title={targetName} position={[lat, lng]} icon={isComplete ? GreenDot : RedDot} />
            );
        });
    }, [conjunctureStatus, tagListByFloor, floorInfoState, conjuncture]);

    const MakeGeofence = useCallback(
        ({ complete, inComplete }) => {
            if (!floorInfoState || !floorInfoState.floorId) return null;

            const { floorId } = floorInfoState;

            let geofence = [];
            if (floorId !== 'entire') {
                geofence = conjunctureGeofence.filter(data => data.floor === floorId);
            } else {
                geofence = geofenceList.map(data => {
                    const { fcName } = data;
                    const floor = floorList.find(list => list.floorName === fcName);
                    const floorId = floor ? floor.floorId : '';
                    return {
                        ...data,
                        authorized: complete[floorId] || 0,
                        unAuthorized: inComplete[floorId] || 0,
                    };
                });
            }

            return (
                <CustomGeofenceLayer
                    authState={floorInfoState.floorId === 'entire'}
                    selectedGeofence={selectedGeofence}
                    geofenceList={geofence}
                    handleClick={geofence => {
                        handleGeofenceLayerClick({ geofence, battleNum });
                    }}
                />
            );
        },
        [floorInfoState, conjunctureGeofence, geofenceList, floorList, selectedGeofence, battleNum],
    );

    const { promise: getGeofenceList, state: getGeofenceListState } = useAsync({
        promise: fetchGeofenceList,
        fixedParam: { floor: floorInfoState.floorId },
        resolve: res => {
            const geofenceList = res.rows.map(geofence => {
                return {
                    ...geofence,
                    bounds: [geofence.latLngList.map(({ lat, lng }) => [lat, lng])],
                };
            });
            dispatch(setGeofenceList(geofenceList));
        },
        reject: err => {
            console.error(err);
        },
    });

    const {
        promise: getBattleGeofence,
        state: getBattleGeofenceState,
        abort: abortBattleGeofence,
    } = useAsync({
        promise: getBattleGeofenceAPI,
        resolve: res => {
            const { inTargetName: complete, notInTargetName: incomplete, geofenceEndTime, manualReason } = res;
            dispatch(setConjuncturePersonnelByFloor({ complete, incomplete, geofenceEndTime, manualReason }));
        },
        reject: err => {
            console.error(err);
        },
    });

    useEffect(() => {
        const { isLoading, error } = getBattleGeofenceState;

        if (!error || error.message !== 'canceled') {
            return handleLoading(isLoading);
        }

        if (selectedGeofence) {
            handleLoading(true);
        } else {
            handleLoading(false);
        }
    }, [getBattleGeofenceState]);

    useEffect(() => {
        if (floorList && !!floorList.length) {
            dispatch(setFloorInfo(floorList[0]));
        }
    }, [floorList]);

    useEffect(() => {
        if (floorInfo.floorId) {
            setFloorInfoState(floorInfo);
        }
    }, [floorInfo.floorId]);

    useEffect(() => {
        if (!floorInfoState || !floorInfoState.floorId || !situation) return;

        dispatch(setFloorInfo(floorInfoState));
        abortBattleGeofence();
        dispatch(setSelectedGeofence(null));
        dispatch(setConjuncturePersonnelByFloor(INITIAL_CONJUNCTURE_PERSONNEL_BY_FLOOR));

        if (floorInfoState.floorId === 'entire' && !getGeofenceListState.isLoading) {
            getGeofenceList();
        }
    }, [floorInfoState, situation]);

    useEffect(() => {
        if (selectedGeofence && conjuncture && conjuncture['geofenceInStatus']) {
            const { fcNum } = selectedGeofence;
            const state = conjuncture['geofenceInStatus'].filter(g => g.fcNum === fcNum);

            if (!!state.length && state[0]['inCount'] !== geofenceInState) {
                abortBattleGeofence();
                getBattleGeofence({ fcNum, battleNum });
                setGeofenceInState(state[0]['inCount'] || 0);
            }
        }
    }, [conjuncture, selectedGeofence]);

    return (
        <CompoundCard className="mt-2">
            <CompoundCard.header>{t('State conversion')}</CompoundCard.header>
            <CompoundCard.body className="state-conversion-map">
                <CompoundCard.body.plain>
                    <Map ref={mapRef}>
                        <Control position="topleft">
                            <FloorTreeSelect
                                floorList={floorList}
                                selectedFloorStr={floorInfoState.floorId}
                                handleChange={handleFloorChange}
                                mode="radioSelect"
                            />
                        </Control>

                        <ImageOverlay mapRef={mapRef} floorInfo={floorInfoState} floorList={floorList} />
                        <MakeGeofence complete={conjunctureComplete} inComplete={conjunctureInComplete} />
                        <RealTimeMarker />
                    </Map>
                </CompoundCard.body.plain>
            </CompoundCard.body>
        </CompoundCard>
    );
};

export default StateConversionMap;
