export const defaultStyle = {
    container: (provided, state) => ({
        ...provided,
        minWidth: '11.5rem',
        maxWidth: '14rem',
    }),
    control: (provided, state) => ({
        ...provided,
        cursor: 'pointer',
        // border: "none",
        // borderBottom: "1px solid white",
        // backgroundColor: "rgba(255, 255, 255, 0.2)"
    }),
    singleValue: (provided, state) => ({
        ...provided,
        lineHeight: 1,
    }),
    option: (provided, state) => {
        return {
            ...provided,
            padding: '4px 8px',
            color: '#000 !important',
            backgroundColor: state.isSelected ? '#FFE3B8' : state.isFocused ? '#EBEBEB' : '#fff',
        };
    },
};

export const groupTitleStyle = {
    borderBottom: '1px solid black',
    padding: '4px 8px',
    fontWeight: 'bold',
};

export const allItemStyle = {
    borderBottom: '1px solid black',
    padding: '0 8px',
    fontWeight: 'bold',
};
