import React, { useContext, useEffect, useRef, useState } from 'react';
import Draggable from '@components/Draggable';
import { useTranslation } from 'react-i18next';
import { Col, Row, Toast, ToastBody, ToastHeader } from 'reactstrap';
import Label from '@components/Label';
import { setBookmark, setSelectedCategory } from '../realTimeLocationReducer';
import { RealTimeLocationStatusContext, RealTimeLocationStatusDispatchContext } from '../index';
import CheckboxTree from 'react-checkbox-tree-enhanced';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCaretSquareDown,
    faCaretSquareRight,
    faCheckSquare,
    faFile,
    faFolder,
    faFolderOpen,
    faMinusSquare,
    faPlusSquare,
    faSquare,
} from '@fortawesome/free-regular-svg-icons';
import PerfectScrollbar from 'react-perfect-scrollbar';
import styled from 'styled-components';
import Checkbox from '@components/Input/Checkbox';

const StyledDiv = styled.div`
    background: transparent;
    border-radius: 3px;
    margin: 0 0.1em;
    padding: 0.25em 0.5em;
    width: 12rem;
    height: 11rem;
`;

const findTree = (tree, categoryCodePathArr, level = 0) => {
    const findNode = tree.find(v => v.categoryCode === categoryCodePathArr[level]);
    if (findNode && !findNode.children) {
        findNode.children = [];
    }
    return findNode ? findTree(findNode.children, categoryCodePathArr, ++level) : tree;
};

const makeTreeData = options => {
    const tempOptions = options;
    const treeData = [];
    if (tempOptions.length) {
        const categoryInfo = tempOptions[0];
        treeData.push({ ...categoryInfo, value: categoryInfo.categoryCodePath, label: categoryInfo.categoryName });
    }
    for (let i = 1, len = tempOptions.length; i < len; i++) {
        const option = tempOptions[i];
        const categoryCodePathArr = option.categoryCodePath.split('>');
        const parent = findTree(treeData, categoryCodePathArr);
        parent.push({ ...option, value: option.categoryCodePath, label: option.categoryName });
    }
    return treeData;
};

const FilteringPopup = ({
    modal = false,
    toggleModal = function () {
        modal = !modal;
    },
    widgetRef,
    mapRef,
}) => {
    const { t } = useTranslation();
    const formRef = useRef();
    const { categoryList, bookmark } = useContext(RealTimeLocationStatusContext);
    const dispatch = useContext(RealTimeLocationStatusDispatchContext);

    const [expanded, setExpanded] = useState([]);
    const [checked, setChecked] = useState([]);
    const [position, setPosition] = useState({
        x: 0,
        y: 0,
    });
    useEffect(() => {
        const widgetPosition = getWidgetPosition();

        const x = (widgetPosition.width - 221) / 2 - 20;
        const y = widgetPosition.height / 2 - widgetPosition.height / 4 - 100;
        setPosition({
            x: x,
            y: y,
        });
    }, []);

    useEffect(() => {
        // 초기 필터링에 카테고리를 전부 선택
        const defaultCheckedCategory = categoryList.map(({ categoryCodePath }) => {
            return categoryCodePath;
        });

        const defaultSelectedCategory = categoryList.map(({ categoryCode }) => {
            return categoryCode;
        });

        setChecked(defaultCheckedCategory);
        dispatch(setSelectedCategory(defaultSelectedCategory));
    }, [categoryList]);

    const getWidgetPosition = () => {
        const widgetPosition = { x: 0, y: 0 };
        if (widgetRef.current) {
            const widget = widgetRef.current;
            const { offsetWidth: width, offsetHeight: height } = widget;
            widgetPosition.width = width;
            widgetPosition.height = height;
        }
        return widgetPosition;
    };

    const handleCheckboxChange = e => {
        dispatch(setBookmark(e.target.checked));
    };

    return (
        <Draggable
            key={JSON.stringify(position)}
            bounds="parent"
            defaultPosition={position}
            backgroundMapElement={mapRef && mapRef.current ? mapRef.current.leafletElement : null}
        >
            <Toast
                isOpen={modal}
                className={'popup-open filtering'}
                onScroll={() => mapRef.current.leafletElement.scrollWheelZoom.disable()}
            >
                <ToastHeader toggle={toggleModal}>{t('RealTimeLocationStatus;IoT Filtering')}</ToastHeader>
                <ToastBody>
                    <Row>
                        <form className="flx-col" ref={formRef}>
                            <Col>
                                <Label name={t('RealTimeLocationStatus;Bookmark Filtering')} />
                                <Checkbox
                                    name={t('RealTimeLocationStatus;Bookmark')}
                                    id={'isBookmark'}
                                    handleChecked={handleCheckboxChange}
                                    checked={bookmark}
                                />
                            </Col>

                            <Col>
                                <Label name={t('RealTimeLocationStatus;Category Filtering')} />
                                <StyledDiv className="scroll-area-sm filtering-container">
                                    <PerfectScrollbar>
                                        <CheckboxTree
                                            nodes={makeTreeData(categoryList)}
                                            showNodeIcon={false}
                                            checked={checked}
                                            expanded={expanded}
                                            onCheck={(checkedNode, targetNode) => {
                                                setChecked(checkedNode);
                                                const checkedCategory = checkedNode.map(node => {
                                                    if (node.includes('>')) {
                                                        const leaf = node.split('>');
                                                        return leaf[leaf.length - 1];
                                                    } else {
                                                        return node;
                                                    }
                                                });
                                                dispatch(setSelectedCategory(checkedCategory));
                                            }}
                                            onExpand={expanded => {
                                                setExpanded(expanded);
                                            }}
                                            icons={{
                                                check: (
                                                    <FontAwesomeIcon
                                                        className="rct-icon rct-icon-check"
                                                        icon={faCheckSquare}
                                                    />
                                                ),
                                                uncheck: (
                                                    <FontAwesomeIcon
                                                        className="rct-icon rct-icon-uncheck"
                                                        icon={faSquare}
                                                    />
                                                ),
                                                halfCheck: (
                                                    <FontAwesomeIcon
                                                        className="rct-icon rct-icon-uncheck"
                                                        icon={faSquare}
                                                    />
                                                ),
                                                expandClose: (
                                                    <FontAwesomeIcon
                                                        className="rct-icon rct-icon-expand-close"
                                                        icon={faCaretSquareRight}
                                                    />
                                                ),
                                                expandOpen: (
                                                    <FontAwesomeIcon
                                                        className="rct-icon rct-icon-expand-open"
                                                        icon={faCaretSquareDown}
                                                    />
                                                ),
                                                expandAll: (
                                                    <FontAwesomeIcon
                                                        className="rct-icon rct-icon-expand-all"
                                                        icon={faPlusSquare}
                                                    />
                                                ),
                                                collapseAll: (
                                                    <FontAwesomeIcon
                                                        className="rct-icon rct-icon-collapse-all"
                                                        icon={faMinusSquare}
                                                    />
                                                ),
                                                parentClose: (
                                                    <FontAwesomeIcon
                                                        className="rct-icon rct-icon-parent-close"
                                                        icon={faFolder}
                                                    />
                                                ),
                                                parentOpen: (
                                                    <FontAwesomeIcon
                                                        className="rct-icon rct-icon-parent-open"
                                                        icon={faFolderOpen}
                                                    />
                                                ),
                                                leaf: (
                                                    <FontAwesomeIcon
                                                        className="rct-icon rct-icon-leaf-close"
                                                        icon={faFile}
                                                    />
                                                ),
                                            }}
                                        />
                                    </PerfectScrollbar>
                                </StyledDiv>
                            </Col>
                        </form>
                    </Row>
                </ToastBody>
            </Toast>
        </Draggable>
    );
};

export default FilteringPopup;
