import React from 'react';
import styled from 'styled-components';

import { getByteCalculation } from '../../util';

import { File } from '../../type';

const FileList = ({ fileList }: { fileList: File[] }) => {
    return (
        <div>
            {fileList.map(({ destUrl, fileName, fileSize, pdfViewerUrl }, index) => (
                <FileContainer key={destUrl || '' + index}>
                    <LabelDot />
                    <FileAnchor className="color-black" type="media_type" href={destUrl || ''} target="_blank">
                        {`${fileName}(${getByteCalculation(fileSize)})`}
                    </FileAnchor>
                    {pdfViewerUrl && (
                        <a className="d-flex align-items-center" target="_blank" href={pdfViewerUrl}>
                            <FileIcon className="material-icons-round">file_open</FileIcon>
                        </a>
                    )}
                </FileContainer>
            ))}
        </div>
    );
};

const LabelDot = styled.div`
    height: 10px;
    width: 10px;
    margin-right: 5px;
    background-color: black;
    border-radius: 50%;
`;

const FileContainer = styled.div`
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: 1.1rem;
`;

const FileAnchor = styled.a`
    width: fit-content;
`;

const FileIcon = styled.span`
    font-size: 2rem !important;
    margin-left: 0.5rem;
`;

export default FileList;
