import React, { createContext, useContext, useEffect, useMemo, useReducer, useState } from 'react';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';

import { getApiURL } from 'api';
import { getOwnershipListApi } from 'api/asset';
import { fetchGeofenceList, getUserInfoApi } from 'api/common';
import { getTransitionListInfoApi, updateTriggerInfoApi } from 'api/status';

import { useTranslation, useAsync, useConfirmModal } from '@hooks';
import { Button, Label, Select as ReactSelect } from 'Components/Common';

import { useDispatch } from 'react-redux';
import { setTransitionList } from 'reducers/Common/StatusInfo';
import { FlowSchemeStateContext } from '../../index';
import triggerReducer, {
    addTriggerGroup,
    deleteTriggerGroup,
    initialState,
    makeSaveData,
    MANUAL_GROUP_KEY,
    RESTFUL_GROUP_KEY,
    setGeofenceList,
    setTransitionInfo,
    setUserGroupList,
    setUserList,
    updateTriggerGroupOperator,
} from './triggerReducer';

import { OperatorSelect } from './Parts';
import ManualInputGroup from './ManualInputGroup';
import RestfulApiInputGroup from './RestfulApiInputGroup';
import LocationInputGroup from './LocationInputGroup';
import DetailModalHeader from '../../../../Components/DetailModalHeader';

import moment from 'moment';

export const TriggerStateContext = createContext();
export const TriggerDispatchContext = createContext();

const TransitionTriggerModal = ({ isOpen, toggle, selectedTransition }) => {
    const storeDispatch = useDispatch();
    const [state, dispatch] = useReducer(triggerReducer, initialState);
    const { triggerRelationJson } = state;

    const triggerRelationJsonOrder = useMemo(() => {
        return triggerRelationJson.order.filter(v => ![MANUAL_GROUP_KEY, RESTFUL_GROUP_KEY].includes(v));
    }, [triggerRelationJson]);

    const { flowStatusList } = useContext(FlowSchemeStateContext);
    const transitionInfo = useMemo(() => {
        const transitionInfo = { ...selectedTransition };
        //triggerRelationJson
        if (transitionInfo.triggerRelationJson) {
            try {
                transitionInfo.triggerRelationJson = JSON.parse(transitionInfo.triggerRelationJson);
            } catch (e) {
                console.error(e);
                transitionInfo.triggerRelationJson = { data: {}, order: [] };
            }
        } else {
            transitionInfo.triggerRelationJson = { data: {}, order: [] };
        }
        if (transitionInfo.startStatusNum) {
            transitionInfo.startStatusName = (
                flowStatusList.find(v => v.statusNum === transitionInfo.startStatusNum) || {}
            ).statusName;
        }
        if (transitionInfo.endStatusNum) {
            transitionInfo.endStatusName = (
                flowStatusList.find(v => v.statusNum === transitionInfo.endStatusNum) || {}
            ).statusName;
        }
        return transitionInfo;
    }, [isOpen, selectedTransition, flowStatusList]);
    const { actionNum, actionName, actionId, description, startStatusName, endStatusName } = transitionInfo || {};

    const t = useTranslation('Status');
    const buttonT = useTranslation('Button');

    const [selectedTriggerList, setSelectedTriggerList] = useState([]);

    const [restfulApiInput, setRestfulApiInput] = useState('');

    const triggerOptions = useMemo(() => {
        return [{ value: 'location', label: t('Location') }];
    }, [t]);

    // 트리거 추가 후 옵션 변경할 경우
    const handleTriggerOptionChange = (selected, index) => {
        let updatedSelectedTrigger = [];

        for (let i = 0; i < selectedTriggerList.length; i++) {
            if (selected && i === index) {
                updatedSelectedTrigger[index] = selected;
            } else {
                updatedSelectedTrigger.push(selectedTriggerList[i]);
            }
        }

        setSelectedTriggerList(updatedSelectedTrigger);
    };

    const { toggleModal: toggleSaveConfirmModal, Modal: SaveConfirmModal } = useConfirmModal({
        initModal: false,
        confirmText: t('Do you want to save?'),
        okCallback: () => {
            const triggerInfo = makeSaveData(triggerRelationJson);
            updateTriggerInfo({
                ...triggerInfo,
                actionNum,
            });
        },
    });

    const { toggleModal: toggleSaveCompleteModal, Modal: SaveCompleteModal } = useConfirmModal({
        initModal: false,
        removeCancel: true,
        confirmText: t('Save is complete.'),
        okCallback: () => {
            toggle();
        },
    });

    const { promise: updateTriggerInfo } = useAsync({
        promise: updateTriggerInfoApi,
        resolve: () => {
            toggleSaveCompleteModal();
            getTransitionListInfo();
        },
        reject: err => {
            console.error(err);
        },
    });

    const { promise: getTransitionListInfo } = useAsync({
        promise: getTransitionListInfoApi,
        fixedParam: { isAll: 'Y' },
        resolve: res => {
            storeDispatch(setTransitionList(res));
        },
        reject: err => {
            console.error(err);
        },
    });

    useAsync({
        promise: getUserInfoApi,
        fixedParam: { isAll: 'Y' },
        immediate: true,
        resolve: res => {
            const { rows } = res;
            const list = rows.map(user => {
                return { ...user, value: user.userNum, label: user.userName };
            });
            dispatch(setUserList(list));
        },
        reject: err => {
            console.error(err);
        },
    });

    useAsync({
        promise: getOwnershipListApi,
        fixedParam: { isAll: 'Y' },
        immediate: true,
        resolve: res => {
            const { rows } = res;
            dispatch(setUserGroupList(rows));
        },
        reject: err => {
            console.error(err);
        },
    });

    useAsync({
        promise: fetchGeofenceList,
        immediate: true,
        fixedParam: { isAll: 'Y' },
        resolve: res => {
            const { rows } = res;
            const list = rows.map(geofence => {
                return { ...geofence, value: geofence.fcNum, label: geofence.fcName };
            });
            dispatch(setGeofenceList(list));
        },
        reject: err => {
            console.error(err);
        },
    });

    useEffect(() => {
        if (transitionInfo) {
            dispatch(setTransitionInfo(transitionInfo));
        }
    }, [transitionInfo]);

    // restful api 경로 호출
    useEffect(() => {
        getApiURL().then(({ wmsApiUrl }) => {
            setRestfulApiInput(`${wmsApiUrl}/asset/{AssetNumber}/transition/${actionId}/execute/{UUID}`);
        });
    }, [actionId]);

    return (
        <TriggerDispatchContext.Provider value={dispatch}>
            <TriggerStateContext.Provider value={state}>
                <Modal
                    container={document.getElementsByClassName('app-container')[0]}
                    className="pnt-modal transition-trigger-container"
                    isOpen={isOpen}
                    toggle={toggle}
                >
                    <DetailModalHeader
                        title={t('Transition Trigger')}
                        subTitle={t('Function to set the execution condition for the transition.')}
                    />
                    <ModalBody>
                        <div className="trigger-description">
                            <Label
                                labelGroupClassName="col-xl-6 col-lg-12"
                                labelValueClassName="label-dot color-brand"
                                name={t('Transition')}
                                value={
                                    <div className="description-value">
                                        {actionName} <span className="description-text">{description}</span>
                                    </div>
                                }
                            />
                            <Label
                                labelGroupClassName="col-xl-6 mb-3 col-lg-12"
                                labelValueClassName="label-dot color-brand"
                                name={t('From → to')}
                                value={
                                    <div className={'description-value'}>
                                        <span>{startStatusName}</span>
                                        <span style={{ margin: '0 5px' }}> → </span>
                                        <span>{endStatusName}</span>
                                    </div>
                                }
                            />
                        </div>
                        <div className="pnt-border border-w mb-3" />
                        <div className="trigger-btn-container">
                            <Button
                                className="btn-icon btn-secondary"
                                iconName="add"
                                onClick={() => {
                                    const groupKey = `$${moment().valueOf().toString(36)}`;
                                    dispatch(addTriggerGroup({ groupKey }));
                                }}
                            >
                                {t('Add trigger')}
                            </Button>
                        </div>
                        <div className="trigger-body-container">
                            <ManualInputGroup />
                            <RestfulApiInputGroup apiURL={restfulApiInput} />
                            {/* 트리거 추가 버튼 클릭시 트리거 카운트 숫자가 올라가면서 갯수에 따른 컴포넌트 추가 */}
                            {triggerRelationJsonOrder.map(groupKey => {
                                const triggerGroupInfo = triggerRelationJson.data[groupKey];
                                return (
                                    <div key={groupKey} className="input-container-group">
                                        <div className="input-container">
                                            <OperatorSelect
                                                value={triggerGroupInfo.op}
                                                disabled={triggerGroupInfo.disabled}
                                                onChange={selected => {
                                                    dispatch(updateTriggerGroupOperator({ groupKey, op: selected }));
                                                }}
                                            />
                                            <Button
                                                iconClassName="icon-close"
                                                iconName="icon-close"
                                                className="btn-danger btn-icon-only"
                                                style={{ gridColumn: '5 / 6' }}
                                                onClick={() => {
                                                    dispatch(deleteTriggerGroup(groupKey));
                                                }}
                                            />
                                        </div>
                                        <div className="input-container trigger-group">
                                            <ReactSelect
                                                name="triggerSelect"
                                                value={
                                                    triggerGroupInfo.triggers[0]
                                                        ? triggerOptions.find(
                                                              v => v.value === triggerGroupInfo.triggers[0].triggerType,
                                                          )
                                                        : triggerOptions[0]
                                                }
                                                options={triggerOptions}
                                                onChange={selected => {
                                                    handleTriggerOptionChange(selected);
                                                }}
                                                disabled={!!triggerGroupInfo.triggers.length}
                                                className="w-100"
                                                customControlStyles={{ width: '100%' }}
                                                customMenuStyles={{ width: '100%' }}
                                                customOptionStyles={{ width: '100%' }}
                                            />

                                            {/* 트리거 타입에 따라 다른 인풋 그룹 랜더링 필요 */}
                                            <LocationInputGroup groupKey={groupKey} />
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button className="color-brand" onClick={toggle}>
                            {buttonT('Cancel')}
                        </Button>
                        <Button
                            className="btn-brand flex-center gap-1"
                            iconName="check"
                            onClick={() => {
                                toggleSaveConfirmModal();
                            }}
                        >
                            {t('Save')}
                        </Button>
                    </ModalFooter>
                </Modal>
                <SaveConfirmModal />
                <SaveCompleteModal />
            </TriggerStateContext.Provider>
        </TriggerDispatchContext.Provider>
    );
};

export default TransitionTriggerModal;
