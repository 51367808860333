import React from 'react';
import Card from '@components/Card';
import useTranslation from '@hooks/useTranslation';
import ItemCount from './ItemCount';
import { Row } from 'reactstrap';

// 전체 상태 별 측정항목 수
const SensorStatusCount = ({ statusCountData }) => {
    const t = useTranslation('SensorStatus');

    return (
        <Card className={'sensor-count-card'} header={{ title: t('Total Number of items by status') }}>
            <Row className={'flex-center'} style={{ height: '100%' }}>
                <div style={{ width: '100%', display: 'flex', alignItems: 'start' }}>
                    <ItemCount
                        start={statusCountData.prev.total}
                        end={statusCountData.curr.total}
                        label={t('Total')}
                        status={'total'}
                    />
                    <ItemCount
                        start={statusCountData.prev.normal}
                        end={statusCountData.curr.normal}
                        label={t('Normal')}
                        status={'normal'}
                        clickable
                    />
                    <ItemCount
                        start={statusCountData.prev.warning}
                        end={statusCountData.curr.warning}
                        label={t('Warning')}
                        status={'warning'}
                        clickable
                    />
                    <ItemCount
                        start={statusCountData.prev.critical}
                        end={statusCountData.curr.critical}
                        label={t('Critical')}
                        status={'critical'}
                        clickable
                    />
                    <ItemCount
                        start={statusCountData.prev.outOfRange}
                        end={statusCountData.curr.outOfRange}
                        label={t('Out of Range')}
                        status={'outOfRange'}
                        clickable
                    />
                    <ItemCount
                        start={statusCountData.prev.undefine}
                        end={statusCountData.curr.undefine}
                        label={t('Unknown')}
                        status={'undefined'}
                        clickable
                    />
                </div>
            </Row>
        </Card>
    );
};

export default React.memo(SensorStatusCount);
