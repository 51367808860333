import React from 'react';

const AppFooter = () => {
    return (
        <>
            <footer className="app-footer">
                <div className="footer-wrap">copyright &copy; 2022. People and technology all right reserved</div>
            </footer>
        </>
    );
};
export default AppFooter;
