import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSocket, setEventHandler } from 'reducers/SocketInfo';

const useSocket = () => {
    const dispatch = useDispatch();
    const { socket } = useSelector(state => state.SocketInfo);

    const setSocketEvent = useCallback(
        (messageType, callback) => {
            dispatch(setEventHandler({ messageType, callback }));
        },
        [dispatch],
    );

    const closeSocket = useCallback(
        socket => {
            if (socket) {
                socket.close();
                dispatch(setSocket(null));
            }
        },
        [dispatch],
    );

    return { socket, setSocketEvent, closeSocket };
};

export default useSocket;
