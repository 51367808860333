import { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import { useSettings } from './useSettings';

import useAsync from '@hooks/useAsync';

const useMonitor = ({
    config,
    paramInfo = [],
    dynamicParam,
    defaultData,
    manualMonitor,
    fetchData,
    makeData = ({ newData }) => {
        return newData;
    },
    errorHandler = ({ error }) => {
        return error;
    },
    // SM 에서만 기본 상태 true, 다른 솔루션의 경우 false
    triggerOnResponse = true,
}) => {
    const { mode } = useSelector(state => state.DashboardFrame);
    const monitorTO = useRef();
    //초기 상탯값을 최초 렌더싱시에만 계산하기위해서 initialState(지연 초기 state, Lazy initial state)를 사용함
    const [data, setData] = useState(() => makeData({ newData: mode === 'monitoring' ? {} : defaultData }));
    const settings = useSettings(config);
    const { state, promise } = useAsync({ promise: fetchData });

    const monitor = () => {
        const param = {};
        paramInfo.forEach(paramKey => {
            if (settings[paramKey]) {
                param[paramKey] = settings[paramKey];
            }
        });
        promise({ ...param, ...dynamicParam }).finally(() => {
            if (triggerOnResponse) {
                start();
            }
        });

        if (!triggerOnResponse) {
            start();
        }
    };

    const start = () => {
        if (monitorTO.current) {
            stop();
        }
        monitorTO.current = setTimeout(monitor, settings.intervalTime);
    };

    const stop = () => {
        clearTimeout(monitorTO.current);
        monitorTO.current = null;
    };

    useEffect(() => {
        stop();
        if (mode === 'monitoring') {
            if (manualMonitor !== true) {
                monitor();
            }
        } else {
            setData(makeData({ newData: defaultData }));
        }
        return () => {
            stop();
        };
    }, [mode, config, dynamicParam]);

    useEffect(() => {
        if (state.response) {
            setData(makeData({ newData: state.response }));
        } else if (state.error) {
            errorHandler({ error: state.error });
        }
    }, [state]);

    return { monitor, stop, data };
};

export default useMonitor;
