import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import moment from 'moment';

import { getAssetListApi } from 'api/asset';
import { getStatusListInfoApi } from 'api/status';

import Search from './Components/Search';
import { Table, Card, Page, TimePicker } from 'Components/Common';
import { useTranslation, useColumns, useAsync } from 'util/hooks';

import InspectionCategorySearchableSelect from './FilterOption/InspectionCategorySearchableSelect';

import * as column from '../util/grid/column';

const initialSelectedSearchOption = {
    // usingDepartmentNum: [],
    categoryCodes: [],
};

const AssetInspectionScheduled = () => {
    const t = useTranslation('Asset Inspection Scheduled');
    const history = useHistory();
    const [tableKey, setTableKey] = useState(0);
    const [assetList, setAssetList] = useState({
        totalCount: 0,
        totalPage: 1,
        rows: [],
    });

    const [selectedSearchOption, setSelectedSearchOption] = useState(initialSelectedSearchOption);
    const [startDate, setStartDate] = useState(moment().valueOf());
    const [endDate, setEndDate] = useState(moment().add(7, 'days').valueOf());
    const [assetStatusList, setAssetStatusList] = useState();

    useAsync({
        promise: getStatusListInfoApi,
        fixedParam: { isAll: 'Y' },
        immediate: true,
        resolve: res => {
            setAssetStatusList(res);
        },
    });

    const handleSearchClick = keyword => {
        const { categoryCodes: category } = selectedSearchOption;
        const categoryCodes = !!category.length ? category.join(',') : 'ASSET';

        // const propertySearches = usingDepartmentNum.reduce((acc, cur, idx) => {
        //     acc[`targetPropertiesSearch.propertySearchs[${idx + 1}].propertyId`] = 'own_dep_id';
        //     acc[`targetPropertiesSearch.propertySearchs[${idx + 1}].values`] = cur;
        //     return acc;
        // }, {});

        if (keyword) {
            getAssetInspectionList({ categoryCodes, opt: 'targetSearch', keyword });
        } else {
            getAssetInspectionList({ categoryCodes });
        }
    };

    const handleRefreshClick = e => {
        const startDate = moment().valueOf();
        const endDate = moment().add(7, 'days').valueOf();

        setStartDate(startDate);
        setEndDate(endDate);

        setTableKey(prevState => prevState + 1);
        setSelectedSearchOption(initialSelectedSearchOption);

        getAssetInspectionList({
            [`targetPropertiesSearch.propertySearchs[0].values[0]`]: moment(startDate).startOf('day').unix(),
            [`targetPropertiesSearch.propertySearchs[0].values[1]`]: moment(endDate)
                .add(1, 'day')
                .startOf('day')
                .unix(),
        });
    };

    const handlePageChange = pageIndex => {
        if (assetListInfo.request) {
            getAssetInspectionList({ ...assetListInfo.request, page: pageIndex });
        }
    };

    const handleChangeStartDate = selected => {
        setStartDate(selected);
    };
    const handleChangeEndDate = selected => {
        setEndDate(selected);
    };

    const { promise: getAssetInspectionList, state: assetListInfo } = useAsync({
        promise: getAssetListApi,
        fixedParam: {
            pageSize: 20,
            categoryCodes: 'ASSET',
            [`targetPropertiesSearch.propertySearchs[0].propertyId`]: 'scheduled_inspection_date',
            [`targetPropertiesSearch.propertySearchs[0].values[0]`]: moment(startDate).startOf('day').unix(),
            [`targetPropertiesSearch.propertySearchs[0].values[1]`]: moment(endDate)
                .add(1, 'day')
                .startOf('day')
                .unix(),
        },
        keepState: true,
        immediate: true,
        resolve: res => {
            setAssetList(res);
        },
    });

    const columns = useColumns(
        [
            column.assetCategorization(),
            column.assetName(),
            column.assetTargetNumber(),
            column.assetLocation(),
            column.scheduledInspectionDate(),
            column.assetStatus({ assetStatusList }),
        ],
        null,
        [assetStatusList],
    );

    return (
        <Page menuPath={[t('Asset Inspection Management', 'Menu'), t('Asset Inspection Scheduled', 'Menu')]}>
            <div className="grid-layout-container">
                <Search
                    searchInputPlaceholder={t('Please enter the asset name or asset id.')}
                    handleSearchClick={handleSearchClick}
                    handleRefreshClick={handleRefreshClick}
                    startDate={startDate}
                    endDate={endDate}
                    handleStartDate={handleChangeStartDate}
                    handleEndDate={handleChangeEndDate}
                >
                    {/* Smart Military Custom - 소유조직 필터 */}
                    {/*<InspectionOwnershipSearchableSelect*/}
                    {/*    handleChange={selected => {*/}
                    {/*        setSelectedSearchOption(prevState => ({ ...prevState, usingDepartmentNum: selected }));*/}
                    {/*    }}*/}
                    {/*    className={'m-0 me-2'}*/}
                    {/*    selected={selectedSearchOption.usingDepartmentNum}*/}
                    {/*/>*/}

                    <InspectionCategorySearchableSelect
                        handleChange={selected => {
                            setSelectedSearchOption(prevState => ({ ...prevState, categoryCodes: selected }));
                        }}
                        className="m-0"
                        selected={selectedSearchOption.categoryCodes}
                    />
                    <TimePicker
                        value={startDate}
                        valueType="ms"
                        handleChange={handleChangeStartDate}
                        maxDate={endDate}
                        withoutTime
                    />
                    <TimePicker
                        value={endDate}
                        valueType="ms"
                        minDate={startDate}
                        handleChange={handleChangeEndDate}
                        withoutTime
                    />
                </Search>
                <Card header={{ title: t('Expect to be checked asset list', 'Menu') }}>
                    <div className="table-container">
                        <Table
                            key={tableKey}
                            data={{ ...assetList, pageSize: 20 }}
                            columns={columns}
                            onPageChange={handlePageChange}
                            onTrClick={info => history.push(`/asset-inspection/detail/${info.targetNum}`)}
                            loading={assetListInfo.isLoading}
                        />
                    </div>
                </Card>
            </div>
        </Page>
    );
};

export default AssetInspectionScheduled;
