import React from 'react';
import { ModalHeader } from 'reactstrap';

const DetailModalHeader = ({ title, subTitle, headerTitleStyle }) => {
    return (
        <ModalHeader className={'card-header'}>
            <div style={headerTitleStyle}>
                <span className="styled-modal-title">{title}</span>
                <p className="styled-modal-subTitle" style={{ textTransform: 'none' }}>
                    {subTitle}
                </p>
            </div>
        </ModalHeader>
    );
};

export default DetailModalHeader;
