import React, { useEffect, useState } from 'react';

import moment from 'moment';

import { useAsync, useTranslation } from '@hooks';
import { fetchGeofenceList, getRealTimePresenceOrLastLogApi } from 'api/common';

import { RotatedImageOverlay, Label, LoadingBlock } from '@components';
import CustomMap from '@components/Map/Components/CustomMap';
import { Popup } from 'react-leaflet';

import { useSelector } from 'react-redux';

import styled from 'styled-components';
import { falsyToHyphen } from '@util/common/util';

const LOCATION_CARD_LIST = [
    { label: 'Location Tag', value: 'beaconName' },
    { label: 'Floor', value: 'floorName' },
    { label: 'Geofence', value: 'fcName' },
    { label: 'Detecting Status', value: 'lostSignalStatus' },
];

const LocationTab = ({ assetInfo, isLoading }) => {
    const t = useTranslation('Location Tab');

    const { lang } = useSelector(state => state.UserInfo);
    const { floorList } = useSelector(state => state.FloorInfo);

    const [floorInfo, setFloorInfo] = useState(null);
    const [geofenceInfo, setGeofenceInfo] = useState(null);

    const [locationInfo, setLocationInfo] = useState({});
    const { lat, lng, lastDate } = locationInfo;

    const { promise: getGeofenceInfo } = useAsync({
        promise: fetchGeofenceList,
        resolve: res => {
            setGeofenceInfo(res);
        },
        reject: err => {
            console.error(err);
        },
    });

    const { promise: getRealTimePresenceOrLastLog } = useAsync({
        promise: getRealTimePresenceOrLastLogApi,
        resolve: res => {
            setLocationInfo(res || {});

            if (res && res.floor) {
                const { floor } = res;
                const floorInfo = floorList.find(list => list.floorId === floor) || null;
                setFloorInfo(floorInfo);
                getGeofenceInfo({ floor });
            }
        },
        reject: err => {
            console.error(err);
        },
    });

    useEffect(() => {
        const { targetNum } = assetInfo;
        if (targetNum) {
            getRealTimePresenceOrLastLog({ targetNum });
        }
    }, [assetInfo]);

    return (
        <>
            <div className="card-header px-0">
                <div className="card-header__title">
                    <div className={'title-wrap'}>
                        <h3 className="title__main">{t('Location Information')}</h3>
                    </div>
                </div>
            </div>
            <div className="card-body p-2" style={{ height: 'calc(100% - 40px)' }}>
                <div className="h-20">
                    <LoadingBlock blocking={isLoading}>
                        <div className="location-information-card">
                            {LOCATION_CARD_LIST.map(({ label, value }) => {
                                return (
                                    <Label
                                        key={label}
                                        labelValueClassName="label-dot color-brand min-w-8rem"
                                        name={t(label)}
                                        value={<>{falsyToHyphen(locationInfo[value])}</>}
                                    />
                                );
                            })}
                            <Label
                                labelGroupClassName="mb-3"
                                labelValueClassName="label-dot color-brand min-w-8rem"
                                name={t('Last Detected')}
                                value={
                                    <>
                                        {lastDate
                                            ? moment.unix(lastDate).locale(lang).format('YYYY-MM-DD A hh:mm:ss')
                                            : '-'}
                                    </>
                                }
                            />
                        </div>
                    </LoadingBlock>
                </div>

                <MapContainer>
                    <CustomMap
                        markerLngLat={[lat, lng]}
                        geofenceList={
                            geofenceInfo &&
                            geofenceInfo.rows &&
                            geofenceInfo.rows.map(v => ({
                                ...v,
                                bounds: v.latLngList.map(({ lat, lng }) => [lat, lng]),
                            }))
                        }
                        markerPopup={
                            <StyledPop>
                                <div className="pnt-label--group">
                                    <div className="label-main label-dot">{assetInfo.targetNum}</div>
                                </div>
                                <div className="pnt-label--group">
                                    <div className="label-main label-dot">{assetInfo.targetName}</div>
                                </div>
                                <div className="pnt-label--group">
                                    <div className="label-main label-dot">{assetInfo.targetId}</div>
                                </div>
                            </StyledPop>
                        }
                    >
                        {!!floorInfo && !!floorInfo.imgURL && (
                            <RotatedImageOverlay
                                key={floorInfo.neLat}
                                url={floorInfo.imgURL}
                                deg={floorInfo.deg}
                                bounds={[
                                    [floorInfo.neLat, floorInfo.neLng],
                                    [floorInfo.swLat, floorInfo.swLng],
                                ]}
                            />
                        )}
                    </CustomMap>
                </MapContainer>
            </div>
        </>
    );
};

const StyledPop = styled(Popup)`
    .leaflet-popup-content-wrapper {
        border-radius: 0;
        //background-color: #525252;
        color: white;
        width: 200px;
        height: 150px;
    }
    .leaflet-popup-tip {
        background-color: #525252;
    }
`;

const MapContainer = styled.div`
    min-height: 380px;
    height: calc(100% - 120px);
    @media (max-width: 900px) {
        height: calc(100% - 215px);
    }
`;

export default LocationTab;
