import React from 'react';
import { Route } from 'react-router-dom';

import AssetInspectionList from './AssetInspectionList';
import AssetInspectionScheduled from './AssetInspectionScheduled';
import AssetInspectionScheduledDetail from './Components/AssetInspectionScheduledDetail';

import { ErrorHandleSwitch } from '../Components/Router';

const AssetInspectionManagement = ({ match }) => {
    return (
        <ErrorHandleSwitch>
            <Route exact path={`${match.path}/schedule`} component={AssetInspectionScheduled} />
            <Route exact path={`${match.path}/list`} component={AssetInspectionList} />
            <Route exact path={`${match.path}/detail/:assetNum([0-9]+)`} component={AssetInspectionScheduledDetail} />
        </ErrorHandleSwitch>
    );
};

export default AssetInspectionManagement;
