import React, { useReducer, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import moment from 'moment';
import { useSelector } from 'react-redux';
import CardModal from '../../../Components/CardModal';
import assetRentalProcessViewReducer, {
    initialState,
    setSelectedAssetRentalProcess,
} from './assetRentalProcessViewReducer';
import AssetRentalProcessList from './Components/AssetRentalProcessList';
import { createAssetRentalApprovalApi, getAssetRentalRequestApi, updateAssetRentalRequestStatusApi } from 'api/asset';
import { useTranslation, useAsync, useConfirmModal } from 'util/hooks';
import { TextInput, Card, Label, Button } from 'Components/Common';

export const AssetRentalProcessViewDispatchContext = React.createContext();
export const AssetRentalProcessViewStateContext = React.createContext();

const AssetRentalProcessView = () => {
    const t = useTranslation('AssetRentalProcess');
    const columnT = useTranslation('CommonColumn');

    const { categoryList } = useSelector(state => state.CategoryInfo);

    const [state, dispatch] = useReducer(assetRentalProcessViewReducer, initialState);
    const { selectedAssetRentalProcess, addedAssetList, assetList, checkedInAssetCount } = state;

    const [refusalModal, setRefusalModal] = useState(false);
    const [refusalReason, setRefusalReason] = useState('');

    const { reqNum } = useParams();
    const history = useHistory();

    useAsync({
        promise: getAssetRentalRequestApi,
        fixedParam: { reqNum },
        immediate: true,
        deps: [reqNum, categoryList],
        resolve: res => {
            const category = categoryList.find(category => category.categoryCode === res.categoryCode);
            dispatch(
                setSelectedAssetRentalProcess({ ...res, categoryNamePath: category ? category.categoryNamePath : '-' }),
            );
        },
    });

    const { promise: updateAssetRentalRequestStatus } = useAsync({
        promise: updateAssetRentalRequestStatusApi,
        resolve: () => {
            history.push('/asset-rental/process');
        },
    });

    const { promise: createAssetRentalApproval } = useAsync({
        promise: createAssetRentalApprovalApi,
        resolve: () => {
            history.push('/asset-rental/process');
        },
    });

    const handleActionClick = () => {
        if (addedAssetList.rows.length === 0) {
            toggleApprovalCheckModal();
        } else {
            toggleApprovalModal();
        }
    };

    const handleGoBackClick = () => {
        dispatch(setSelectedAssetRentalProcess(null));
        history.push('/asset-rental/process');
    };

    const handleRefusalModalToggle = () => {
        setRefusalModal(!refusalModal);
    };

    const { toggleModal: toggleApprovalCheckModal, Modal: ApprovalCheckModal } = useConfirmModal({
        initModal: false,
        header: {
            title: t('Notification'),
        },
        confirmText: t('Please select at least one asset.'),
        removeCancel: true,
    });

    const { toggleModal: toggleApprovalModal, Modal: ApprovalModal } = useConfirmModal({
        initModal: false,
        header: {
            title: t('Confirm', 'ConfirmModal'),
        },
        confirmText: t('Do you want to approve the asset rental request?'),
        removeCancel: true,
        okCallback: createAssetRentalApproval,
        callbackParam: selectedAssetRentalProcess && {
            reqNum: selectedAssetRentalProcess.reqNum,
            targetNums: addedAssetList.rows.map(v => v.targetNum),
        },
    });

    return (
        <>
            <AssetRentalProcessViewDispatchContext.Provider value={dispatch}>
                <AssetRentalProcessViewStateContext.Provider value={state}>
                    <div className={'process-grid-container'}>
                        <Card
                            className={'process-detail-container'}
                            header={{
                                title: t('View of Asset Rental Process'),
                                action: (
                                    <>
                                        <Button className={'btn-gray btn-back me-1'} onClick={handleGoBackClick}>
                                            {t('Back')}
                                        </Button>
                                        {selectedAssetRentalProcess && selectedAssetRentalProcess.status === 'APPLY' && (
                                            <>
                                                <div className="pnt-border border-h" />
                                                <Button className={'btn-brand'} onClick={handleRefusalModalToggle}>
                                                    {t('Refusal', 'AssetRentalRequest')}
                                                </Button>

                                                <Button className={'btn-brand'} onClick={handleActionClick}>
                                                    {t('Approval', 'AssetRentalRequest')}
                                                </Button>
                                            </>
                                        )}
                                    </>
                                ),
                            }}
                        >
                            <div className={'process-contents-container'}>
                                {selectedAssetRentalProcess && (
                                    <>
                                        <Label
                                            name={columnT('Applicant')}
                                            value={selectedAssetRentalProcess.userName}
                                            labelGroupClassName={'details-input-group'}
                                            labelValueClassName={'label-dot'}
                                        />

                                        <Label
                                            name={columnT('Requesting department')}
                                            value={selectedAssetRentalProcess.usingDepNm}
                                            labelGroupClassName={'details-input-group'}
                                            labelValueClassName={'label-dot'}
                                        />

                                        <Label
                                            name={columnT('Application date')}
                                            value={moment.unix(selectedAssetRentalProcess.regDate).format('YYYY-MM-DD')}
                                            labelGroupClassName={'details-input-group'}
                                            labelValueClassName={'label-dot'}
                                        />

                                        <Label
                                            name={columnT('Application status')}
                                            value={t(selectedAssetRentalProcess.status, 'AssetRentalRequest')}
                                            labelGroupClassName={'details-input-group'}
                                            labelValueClassName={'label-dot'}
                                        />

                                        <Label
                                            name={columnT('Lending department')}
                                            value={selectedAssetRentalProcess.ownDepNm}
                                            labelGroupClassName={'details-input-group'}
                                            labelValueClassName={'label-dot'}
                                        />

                                        <Label
                                            name={columnT('Rental period')}
                                            value={
                                                <div>
                                                    {moment
                                                        .unix(selectedAssetRentalProcess.startDate)
                                                        .format('YYYY-MM-DD')}
                                                    ~
                                                    {moment
                                                        .unix(selectedAssetRentalProcess.endDate)
                                                        .format('YYYY-MM-DD')}
                                                </div>
                                            }
                                            labelGroupClassName={'details-input-group'}
                                            labelValueClassName={'label-dot'}
                                        />

                                        <Label
                                            name={columnT('Category')}
                                            value={selectedAssetRentalProcess.categoryNamePath}
                                            labelGroupClassName={'details-input-group'}
                                            labelValueClassName={'label-dot'}
                                        />

                                        <Label
                                            name={t('Quantity')}
                                            value={selectedAssetRentalProcess.count}
                                            labelGroupClassName={'details-input-group'}
                                            labelValueClassName={'label-dot'}
                                        />
                                    </>
                                )}
                            </div>
                        </Card>

                        <AssetRentalProcessList
                            assetList={assetList}
                            addedAssetList={addedAssetList}
                            selectedAssetRentalProcess={selectedAssetRentalProcess}
                            checkedInAssetCount={checkedInAssetCount}
                        />

                        <CardModal
                            initModal={refusalModal}
                            toggleModal={handleRefusalModalToggle}
                            header={{
                                title: t('Refusal', 'AssetRentalRequest'),
                            }}
                            okCallback={updateAssetRentalRequestStatus}
                            callbackParam={{
                                reqNum: selectedAssetRentalProcess && selectedAssetRentalProcess.reqNum,
                                status: 'REFUSAL',
                                description: refusalReason,
                            }}
                        >
                            <Label
                                name={t('Reason')}
                                labelValueClassName={'label-dot'}
                                labelGroupClassName={'w-100'}
                                value={
                                    <TextInput
                                        name="reason"
                                        inputGroupClassName={'w-100'}
                                        type={'text'}
                                        className={'pnt-input pnt-txt w-100'}
                                        handleChange={e => setRefusalReason(e.target.value)}
                                    />
                                }
                            />
                        </CardModal>
                    </div>

                    <ApprovalCheckModal />
                    <ApprovalModal />
                </AssetRentalProcessViewStateContext.Provider>
            </AssetRentalProcessViewDispatchContext.Provider>
        </>
    );
};

export default AssetRentalProcessView;
