import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    editMode: false,
    writePermission: false,
    origin: { filterInfoCondition: [], filterInfoAccessRight: [] },
    editing: { filterInfoCondition: [], filterInfoAccessRight: [] },
};

const { actions, reducer } = createSlice({
    name: 'filterDetailView',
    initialState,
    reducers: {
        setOriginFilter: (state, action) => {
            const filterInfo = action.payload || {};
            state.origin = filterInfo;
            state.editing = filterInfo;
        },
        setEditMode: (state, action) => {
            state.editMode = action.payload;
            state.editing = state.origin;
        },
        updateFilter: (state, action) => {
            state.editing = { ...state.editing, ...action.payload };
        },
    },
});

export const { setOriginFilter, setEditMode, updateFilter } = actions;
export default reducer;
