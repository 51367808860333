import React from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import styled from 'styled-components';

const FontawesomeIcon = styled.i`
    font-weight: 900;
    font-family: 'Font Awesome 5 Free';
`;

export const AlertTooltip = ({ tooltipId, contents }) => {
    return (
        <>
            <i className="fas fa-exclamation-circle ms-2" id={tooltipId} />
            <UncontrolledTooltip placement="auto" target={tooltipId}>
                {contents}
            </UncontrolledTooltip>
        </>
    );
};

export const QuestionTooltip = ({ tooltipId, contents }) => {
    return (
        <>
            <FontawesomeIcon className="fas fa-question-circle ms-2" id={tooltipId} />
            <UncontrolledTooltip placement="auto" target={tooltipId}>
                {contents}
            </UncontrolledTooltip>
        </>
    );
};

const Tooltip = ({ tooltipId, contents, isAlert, Icon }) => {
    return Icon ? (
        <>
            <Icon id={tooltipId} />
            <UncontrolledTooltip placement="auto" target={tooltipId}>
                {contents}
            </UncontrolledTooltip>
        </>
    ) : isAlert ? (
        <AlertTooltip tooltipId={tooltipId} contents={contents} />
    ) : (
        <QuestionTooltip tooltipId={tooltipId} contents={contents} />
    );
};

export default Tooltip;
