import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';

import { Button, TextInput } from '@components';
import { useTranslation, useConfirmModal, useAsync } from '@hooks';

import DetailModalHeader from '../../Components/DetailModalHeader';
import DetailLabel from '../../Components/DetailLabel';
import ComeGoBasket from '../../Components/ComeGoBasket';
import ColorPicker from '../../Components/ColorPicker';
import { trim } from 'util/common/util';

import { setStatusCategoryListInfo, setStatusListInfo } from 'reducers/Common/StatusInfo';
import {
    createStatusCategoryInfoApi,
    getStatusCategoryInfoApi,
    getStatusListInfoApi,
    updateStatusCategoryInfoApi,
} from 'api/status';

const INPUT_WIDTH = 317;

const initStatusCategoryDetailInfo = {
    statusCategoryName: '',
    description: '',
    displayColor: '#000000',
    mappedStatus: [],
};

const StatusCategoryDetail = ({ isOpen, toggle, statusInfo }) => {
    const dispatch = useDispatch();
    const t = useTranslation('Status');
    const buttonT = useTranslation('Button');
    const { serviceCode } = useSelector(state => state.AppConfig);
    const {
        statusListInfo: { rows: statusList },
    } = useSelector(state => state.StatusInfo);
    const [statusCategoryDetailInfo, setStatusCategoryDetailInfo] = useState(initStatusCategoryDetailInfo);

    useEffect(() => {
        if (statusInfo) {
            setStatusCategoryDetailInfo(statusInfo);
        } else {
            setStatusCategoryDetailInfo(initStatusCategoryDetailInfo);
        }
    }, [statusInfo]);

    const { promise: getStatusListInfo } = useAsync({
        promise: getStatusListInfoApi,
        fixedParam: { isAll: 'Y' },
        resolve: res => {
            dispatch(setStatusListInfo(res));
        },
    });

    const { promise: getStatusCategoryInfo } = useAsync({
        promise: getStatusCategoryInfoApi,
        fixedParam: { isAll: 'Y' },
        resolve: res => {
            dispatch(setStatusCategoryListInfo(res));
        },
    });

    const { promise: createStatusCategoryInfo } = useAsync({
        promise: createStatusCategoryInfoApi,
        resolve: res => {
            toggleSaveCompleteModal();
        },
        reject: err => {
            console.log({ err });
        },
    });
    const { promise: updateStatusCategoryInfo } = useAsync({
        promise: updateStatusCategoryInfoApi,
        resolve: res => {
            toggleSaveCompleteModal();
        },
        reject: err => {
            console.log({ err });
        },
    });

    const { toggleModal: toggleSaveConfirmModal, Modal: SaveConfirmModal } = useConfirmModal({
        initModal: false,
        confirmText: t('Do you want to save?'),
        okCallback: () => {
            const {
                //     comNum: 1
                //         description: "자산이 사용중인 상태 유형"
                // displayColor: "#5F9EA0"
                // mappedStatus: []
                // modDate: 0
                // modUserNum: 0
                // regDate: 1635221627
                // regUserNum: 2
                // serviceCode: "AM01"
                // statusCategoryName: "작업"
                // statusCategoryNum: 1
                // statusScheme: [1]
                statusCategoryNum,
                statusCategoryName,
                displayColor,
                description,
                schemeNum,
                comNum,
                modDate,
                modUserNum,
                regDate,
                regUserNum,
                mappedStatus,
                flowNum,
                flowName,
                statusScheme,
                ...detailInfo
            } = statusCategoryDetailInfo;
            if (statusCategoryNum) {
                updateStatusCategoryInfo({
                    statusCategoryNum,
                    statusCategoryName: trim(statusCategoryName),
                    displayColor,
                    description,
                    serviceCode,
                    statusNums: statusScheme.join(','),
                });
            } else {
                createStatusCategoryInfo({
                    statusCategoryName: trim(statusCategoryName),
                    displayColor,
                    description,
                    serviceCode,
                    statusNums: statusScheme.join(','),
                });
            }
        },
    });
    const { toggleModal: toggleSaveCompleteModal, Modal: SaveCompleteModal } = useConfirmModal({
        initModal: false,
        removeCancel: true,
        confirmText: t('Save is complete.'),
        okCallback: () => {
            toggle();
            getStatusCategoryInfo();
            getStatusListInfo();
        },
    });
    const { toggleModal: toggleRequestRequiredModal, Modal: RequestRequiredModal } = useConfirmModal({
        initModal: false,
        removeCancel: true,
        confirmText: t('Please fill in the required fields. ( Name )'),
    });

    const handleChange = e => {
        const { value, name } = e.currentTarget;
        setStatusCategoryDetailInfo({ ...statusCategoryDetailInfo, [name]: value });
    };

    return (
        <>
            <Modal
                container={document.getElementsByClassName('app-container')[0]}
                className={'pnt-modal'}
                isOpen={isOpen}
                toggle={toggle}
            >
                <DetailModalHeader
                    title={`${t('Status Category')} - ${statusInfo ? t('Edit') : t('Add')}`}
                    subTitle={
                        statusInfo
                            ? t('This is a function to change the status category of an asset.')
                            : t('This is a function to add the status category of an asset.')
                    }
                />
                <ModalBody style={{ display: 'grid', rowGap: '5px' }}>
                    <DetailLabel
                        required
                        name={t('Name')}
                        value={
                            // col-sm-10 p-0
                            <TextInput
                                style={{ width: INPUT_WIDTH }}
                                inputGroupClassName={'w-auto form-must'}
                                type={'text'}
                                maxlength={50}
                                name={'statusCategoryName'}
                                handleChange={handleChange}
                                value={statusCategoryDetailInfo.statusCategoryName}
                            />
                        }
                    />
                    <DetailLabel
                        name={t('Description')}
                        value={
                            <TextInput
                                style={{ width: INPUT_WIDTH }}
                                inputGroupClassName={'w-auto'}
                                type={'text'}
                                maxlength={200}
                                name={'description'}
                                handleChange={handleChange}
                                value={statusCategoryDetailInfo.description}
                            />
                        }
                    />
                    <DetailLabel
                        name={t('Display Color')}
                        value={
                            <ColorPicker
                                inputStyle={{ width: INPUT_WIDTH }}
                                inputGroupClassName={'w-auto'}
                                name={'displayColor'}
                                selected={statusCategoryDetailInfo.displayColor}
                                onChange={selected => {
                                    handleChange({ currentTarget: { value: selected, name: 'displayColor' } });
                                }}
                            />
                        }
                    />
                    <DetailLabel
                        name={t('Status setting')}
                        value={
                            <div style={{ width: INPUT_WIDTH }}>
                                <ComeGoBasket
                                    valueKey={'statusNum'}
                                    labelKey={'statusName'}
                                    initComeItems={statusCategoryDetailInfo.mappedStatus}
                                    initGoItems={statusList.filter(
                                        v =>
                                            !(statusCategoryDetailInfo.mappedStatus || []).find(
                                                mapped => mapped.statusNum === v.statusNum,
                                            ),
                                    )}
                                    onChange={({ comeItems }) => {
                                        setStatusCategoryDetailInfo({
                                            ...statusCategoryDetailInfo,
                                            statusScheme: comeItems.map(v => v.statusNum),
                                        });
                                    }}
                                />
                            </div>
                        }
                    />
                </ModalBody>
                <ModalFooter>
                    <Button className="color-brand" onClick={toggle}>
                        {buttonT('Cancel')}
                    </Button>
                    <Button
                        className="btn-brand flex-center gap-1"
                        iconName="check"
                        onClick={() => {
                            if (trim(statusCategoryDetailInfo.statusCategoryName)) {
                                toggleSaveConfirmModal();
                            } else {
                                toggleRequestRequiredModal();
                            }
                        }}
                    >
                        {buttonT('Ok')}
                    </Button>
                </ModalFooter>
            </Modal>
            <SaveConfirmModal />
            <SaveCompleteModal />
            <RequestRequiredModal />
        </>
    );
};

export default StatusCategoryDetail;
