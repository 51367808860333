import { createSlice } from '@reduxjs/toolkit';
import { isSelectableFloor } from 'util/common/util';

const initialState = {
    floorList: [],
    selectableFloorList: [],
};

const { actions, reducer } = createSlice({
    name: 'common/floorInfo',
    initialState,
    reducers: {
        setFloorList: (state, action) => {
            const floorList = action.payload.rows.map(v => ({
                ...v,
                bounds: [
                    [v.swLat, v.swLng],
                    [v.neLat, v.neLng],
                ],
            }));
            state.floorList = floorList;

            state.selectableFloorList = floorList.reduce((acc, floor) => {
                if (isSelectableFloor(floor, floorList)) {
                    acc.push(floor);
                }
                return acc;
            }, []);
        },
    },
});

export const { setFloorList } = actions;
export default reducer;
