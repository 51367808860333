import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    selectedFloorStr: null,
    selectedDivOption: {
        label: null,
        value: null,
    },
    patrolStatus: 'N',
    patrolDivList: [],
    patrolInfo: {
        totalCompleteCnt: 0,
        totalIncompleteCnt: 0,
        floorCompleteCnt: 0,
        floorIncompleteCnt: 0,
        patrolBeaconList: [],
        patrolStartDate: null,
        patrolEndDate: null,
    },
    entireRows: [],
    recentPatrol: {
        patrolNum: null,
    },
};

const { actions, reducer } = createSlice({
    name: 'patrol',
    initialState,
    reducers: {
        setSelectedFloorStr: (state, action) => {
            state.selectedFloorStr = action.payload;
        },
        setSelectedDivOption: (state, action) => {
            state.selectedDivOption = action.payload;
        },
        setPatrolStatus: (state, action) => {
            state.patrolStatus = action.payload;
        },
        setPatrolDashboard: (state, action) => {
            const { patrolDivList, patrolStatus, recentPatrol } = action.payload;

            state.patrolStatus = patrolStatus;

            state.patrolDivList = patrolDivList.map(({ sCD, sName }) => {
                return {
                    label: sName,
                    value: sCD,
                };
            });

            if (!!recentPatrol && !!recentPatrol.smPatrol) {
                const { patrolDiv: value, patrolDivName: label } = recentPatrol.smPatrol;

                state.recentPatrol = recentPatrol.smPatrol;

                if (!!value && !!label) {
                    state.selectedDivOption = { value, label };
                }
            }
        },
        setPatrolInfo: (state, action) => {
            state.patrolInfo = action.payload;
        },
        setEntireRows: (state, action) => {
            state.entireRows = action.payload;
        },
        setRecentPatrol: (state, action) => {
            state.recentPatrol = action.payload;
        },
    },
});

export const {
    setSelectedFloorStr,
    setSelectedDivOption,
    setPatrolStatus,
    setPatrolDashboard,
    setPatrolInfo,
    setEntireRows,
    setRecentPatrol,
} = actions;
export default reducer;
