import React, { useMemo } from 'react';

import { useTranslation } from '@hooks';
import { dateToFormat } from '@util/common/util';
import { LoadingBlock } from '@components';

import PreviewModal from '../PreviewModal';
import ApprovalTable from './ApprovalTable';
import PatrolResultTable from './PatrolResultTable';
import PatrolSignificantTable from './PatrolSignificantTable';

import { MAX_SIZE, TWENTY_FOUR_HOURS, SIXTY_MINUTES, ONE_THOUSAND } from '../../utils';

import moment from 'moment';
import cx from 'classnames';

const PatrolReportPreviewModal = ({ initModal = false, toggleModal, request = {}, data = [], isLoading = false }) => {
    const t = useTranslation('Patrol Log');

    const { division = {}, approvals, patrol = {}, interval, startDate, endDate } = request;

    const divisionOptions = [
        { value: 'safety watch', label: t('Safety Watch') },
        { value: 'armed patrol', label: t('Armed') },
    ];

    const title = divisionOptions.find(({ value }) => value === division.value) || division;

    const diff = moment(endDate * ONE_THOUSAND).diff(moment(startDate * ONE_THOUSAND), 'minutes');
    let maxCol = Math.ceil(diff / SIXTY_MINUTES);
    if (interval) {
        maxCol = Math.ceil(maxCol / interval);
    }

    const timeList = useMemo(() => {
        const startHour = moment(startDate * ONE_THOUSAND).get('hour');
        return Array(maxCol || 0)
            .fill(0)
            .map((_, idx) => {
                const time = startHour + idx * interval;
                return time > TWENTY_FOUR_HOURS - 1 ? time - TWENTY_FOUR_HOURS : time;
            });
    }, [startDate, endDate, interval]);

    const arraySize = maxCol ? Math.ceil(maxCol / MAX_SIZE) : 0;

    return (
        <PreviewModal
            initModal={initModal}
            toggleModal={toggleModal}
            className=""
            bodyClassName={cx(isLoading ? 'h-100' : 'd-flex flex-column gap-1 w-100 px-1')}
            header={{ title: title.label }}
            disabled={isLoading}
        >
            {isLoading ? (
                <LoadingBlock blocking={isLoading} />
            ) : (
                <>
                    <h4 className="font-weight-bold text-center">{`${title.label} ${t('Result')}`}</h4>
                    <div className="d-flex justify-content-end">
                        <ApprovalTable section={approvals} />
                    </div>
                    <div className="d-flex justify-content-between border-bottom mt-3">
                        <span className="font-weight-bold color-black">{patrol.label}</span>
                        <span className="color-black">{`${dateToFormat(
                            startDate * ONE_THOUSAND,
                            'YYYY-MM-DD HH:mm',
                        )} ~ ${dateToFormat(endDate * ONE_THOUSAND, 'YYYY-MM-DD HH:mm')}`}</span>
                    </div>
                    {!data.length ? (
                        <div className="flex-center" style={{ height: 200 }}>
                            <div className="bg-transparent p-4">{t('No data', 'Table')}</div>
                        </div>
                    ) : (
                        <>
                            {Array(arraySize)
                                .fill(0)
                                .map((_, idx) => {
                                    const size =
                                        maxCol - MAX_SIZE * idx > MAX_SIZE ? MAX_SIZE : maxCol - MAX_SIZE * idx;
                                    return (
                                        <PatrolResultTable
                                            key={idx}
                                            size={size}
                                            data={data}
                                            timeList={timeList}
                                            start={idx}
                                            interval={interval}
                                        />
                                    );
                                })}
                            <PatrolSignificantTable data={data} />
                        </>
                    )}
                </>
            )}
        </PreviewModal>
    );
};

export default PatrolReportPreviewModal;
