import React, { createContext, useReducer } from 'react';

import { Page } from 'Components/Common';
import { useConfirmModal, useTranslation } from '@hooks';

import DrowningMonitoringReducer, { initialState } from './DrowningMonitoringReducer';
import Analysis from './Components/Analysis';

export const DrowningMonitoringStatusContext = createContext(null);
export const DrowningMonitoringDispatchContext = createContext();

const DrowningMonitoring = () => {
    const t = useTranslation('Drowning Monitoring');

    const [state, dispatch] = useReducer(DrowningMonitoringReducer, initialState);

    const { toggleModal: toggleSelectDateRangeModal, Modal: SelectDateRangeModal } = useConfirmModal({
        initModal: false,
        confirmText: t('Please select the date first'),
        removeCancel: true,
    });

    return (
        <DrowningMonitoringDispatchContext.Provider value={dispatch}>
            <DrowningMonitoringStatusContext.Provider value={state}>
                <Page menuPath={[t('Monitoring', 'Widget'), t('Drowning Monitoring')]}>
                    <Analysis toggleSelectDateRangeModal={toggleSelectDateRangeModal} />
                </Page>
                <SelectDateRangeModal />
            </DrowningMonitoringStatusContext.Provider>
        </DrowningMonitoringDispatchContext.Provider>
    );
};

export default DrowningMonitoring;
