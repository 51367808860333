import React, { Children, isValidElement, useEffect, useMemo } from 'react';
import { Toast } from 'reactstrap';
import RDraggable, { DraggableProps } from 'react-draggable';
import { Map } from 'leaflet';

interface Props extends DraggableProps {
    backgroundMapElement?: Map;
}

const Draggable = ({ children, backgroundMapElement, onStart, onStop, cancel, onMouseDown, ...restProps }: Props) => {
    const finalCancel = useMemo(() => {
        if (!!cancel && cancel.trim()) {
            return cancel;
        }

        let defaultCancel = '';
        Children.forEach(children, child => {
            if (isValidElement(child) && child.type === Toast) {
                defaultCancel = '.toast-body, .toast-header .close';
                return false;
            }
        });
        return defaultCancel;
    }, [children, cancel]);

    const disableMapDrag = () => {
        if (!!backgroundMapElement) {
            backgroundMapElement.dragging.disable();
        }
    };

    const enableMapDrag = () => {
        if (!!backgroundMapElement) {
            backgroundMapElement.dragging.enable();
        }
    };

    useEffect(() => {
        if (backgroundMapElement) {
            const mouseUpHandler = () => {
                enableMapDrag();
            };
            document.addEventListener('mouseup', mouseUpHandler);
            document.addEventListener('touchend', mouseUpHandler);
            return () => {
                document.removeEventListener('mouseup', mouseUpHandler);
                document.removeEventListener('touchend', mouseUpHandler);
            };
        }
    }, [backgroundMapElement]);

    return (
        <RDraggable
            cancel={finalCancel}
            onMouseDown={event => {
                disableMapDrag();
                if (onMouseDown) {
                    onMouseDown(event);
                }
            }}
            onStop={(event, data) => {
                enableMapDrag();
                if (onStop) {
                    onStop(event, data);
                }
            }}
            onStart={(event, data) => {
                disableMapDrag();
                if (onStart) {
                    onStart(event, data);
                }
            }}
            {...restProps}
        >
            {children}
        </RDraggable>
    );
};

export default Draggable;
