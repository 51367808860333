export const stateOptions = [
    { label: 'Battle Placement', value: '01' },
    { label: 'Passing Through a minefield', value: '02' },
    { label: 'Narrowboat Coastal Sailing', value: '03' },
    { label: 'Low-visibility coastal navigation', value: '04' },
    { label: 'Lifesaving', value: '05' },
    { label: 'Emergency Steering', value: '06' },
    { label: 'Tumyo', value: '07' },
    { label: 'Cultivation', value: '08' },
    { label: 'Forward Air Supply(FAS)', value: '09' },
    { label: 'Tug', value: '10' },
    { label: 'Subject', value: '11' },
    { label: 'Danjing Yanggang', value: '12' },
    { label: 'Helipad', value: '13' },
    { label: 'TASS Operations', value: '14' },
    { label: 'Digestion', value: '15' },
    { label: 'Waterproofing', value: '16' },
    { label: 'NBC', value: '17' },
    { label: 'emergency', value: '18' },
    { label: 'vertical supply', value: '19' },
    { label: 'Organ combat problem solving', value: '20' },
    { label: 'damage control', value: '21' },
    { label: 'a port of entry and departure', value: '22' },
];

const ONE_MINUTE = 60;
const ONE_HOUR = ONE_MINUTE * 60;

export const timeConverter = (time, t) => {
    if (typeof time !== 'number' || time < 0) return `0${t('Seconds')}`;

    const convertedTime = ['Hours', 'Minutes', 'Seconds'];
    const calculatedTime = [
        Math.floor(time / ONE_HOUR),
        Math.floor((time % ONE_HOUR) / ONE_MINUTE),
        Math.floor((time % ONE_HOUR) % ONE_MINUTE),
    ];

    for (let i = 0; i < 3; i++) {
        if (!calculatedTime[i]) continue;
        const slicedTime = calculatedTime.slice(i);
        return slicedTime.map((tmp, idx) => `${String(tmp).padStart(2, '0')}${t(convertedTime[idx + i])}`).join(' ');
    }
    return `0${t('Seconds')}`;
};
