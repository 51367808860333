import React from 'react';
import styled from 'styled-components';
import useTranslation from 'util/hooks/useTranslation';

/**
 * NoDataBlock
 *
 * @param className wrapper classnames
 * @param text custom 'no data' message
 * @param height block height(default 100), unit='%'
 * @param width block width(default 100), unit='%'
 */
const NoDataBlock = ({ className = '', text = '', height = 100, width = 100 }) => {
    const t = useTranslation();
    return (
        <Container className={className ? className : ''} $height={height} $width={width}>
            <NoDataContainer>{text ? text : t('No data', 'Table')}</NoDataContainer>
        </Container>
    );
};

const Container = styled.div`
    width: ${({ $width }) => $width}%;
    height: ${({ $height }) => $height}%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const NoDataContainer = styled.span`
    padding: 2rem;
    background-color: rgba(0, 0, 0, 0.1);
`;

export default NoDataBlock;
