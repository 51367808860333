import React, { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { fetchExcelLogApi } from 'api/log';
import { exportAssetInspectionApi, getAssetInspectionListApi } from 'api/asset';

import { useAsync, useConfirmModal, useColumns, useTranslation } from 'util/hooks';
import { Button, Table, Card, Page, TimePicker } from 'Components/Common';

import Search from './Components/Search';
import InspectionCategorySearchableSelect from './FilterOption/InspectionCategorySearchableSelect';

import * as column from '../util/grid/column';

import moment from 'moment';

const INTERVAL_TIME = 1000;
const PAGE_SIZE = 20;

const initialSelectedSearchOption = {
    usingDepartmentNum: [],
    categoryCodes: [],
};

const AssetInspectionList = () => {
    const t = useTranslation('AssetInspectionList');

    const history = useHistory();

    const excelTimerRef = useRef();

    const [selectedSearchOption, setSelectedSearchOption] = useState(initialSelectedSearchOption);
    const [assetInspectionList, setAssetInspectionList] = useState({
        totalCount: 0,
        totalPage: 1,
        rows: [],
    });

    const [startDate, setStartDate] = useState(moment().subtract(7, 'days').valueOf());
    const [endDate, setEndDate] = useState(moment().valueOf());

    const [keyword, setKeyword] = useState({});

    const columns = useColumns([
        column.assetCategoryName(),
        column.assetName(),
        column.assetTargetNumber(),
        column.registeredDate(),
        column.inspectionStatus({
            Cell: ({ value }) => {
                return <div>{t(value)}</div>;
            },
        }),
        column.assetDescription(),
    ]);

    const { promise: getAssetInspectionList, state: assetInspectionListInfo } = useAsync({
        promise: getAssetInspectionListApi,
        fixedParam: {
            pageSize: PAGE_SIZE,
            startDate: moment(startDate).startOf('day').unix(),
            endDate: moment(endDate).add(1, 'day').startOf('day').unix(),
        },
        keepState: true,
        immediate: true,
        resolve: res => {
            setAssetInspectionList({ ...res, pageSize: PAGE_SIZE });
        },
    });

    const { promise: fetchExcelLog } = useAsync({
        promise: fetchExcelLogApi,
        resolve: res => {
            if (res.dataSettingStatusType === 'COMPLETED') {
                clearExcelTimer();

                const elem = window.document.createElement('a');
                elem.href = res.filePath;
                elem.download = res.fileName;
                document.body.appendChild(elem);
                elem.click();
                document.body.removeChild(elem);
            } else if (res.dataSettingStatusType === 'FAILED') {
                clearExcelTimer();
            }
        },
    });

    const { promise: createAssetInspectionExcel } = useAsync({
        promise: exportAssetInspectionApi,
        fixedParam: {
            zipFileName: 'AssetInspectionList',
            fileName: 'AssetInspectionListExcel',
            columnMetas: [
                {
                    key: 'categoryCode',
                    name: t('Asset Category'),
                },
                {
                    key: 'targetName',
                    name: t('Asset Name'),
                },
                {
                    key: 'targetNum',
                    name: t('Asset Number'),
                },
                {
                    key: 'regDateStr',
                    name: t('Registered Date'),
                },
                {
                    key: 'inspectStatus',
                    name: t('Inspection Status'),
                },
                {
                    key: 'description',
                    name: t('Description'),
                },
            ],
        },
        resolve: res => {
            const { filePath: url, reqNum } = res;

            if (url) {
                downloadWithURL(url, reqNum);
            }
        },
    });

    const handlePageChange = pageIndex => {
        if (assetInspectionListInfo.request) {
            getAssetInspectionList({ ...assetInspectionListInfo.request, page: pageIndex });
            return;
        }
        getAssetInspectionList({ page: pageIndex });
    };

    const handleSearchClick = keyword => {
        const param = setParams(keyword);
        setKeyword({ ...param });
        getAssetInspectionList({ ...param });
    };

    const setParams = keyword => {
        let param = {};
        if (keyword) {
            param = { ...param, opt: 'targetSearch', keyword };
        }
        if (selectedSearchOption.categoryCodes && selectedSearchOption.categoryCodes.length > 0) {
            param = { ...param, categoryCodes: selectedSearchOption.categoryCodes.join(',') };
        }

        // 소유 부서
        // if (selectedSearchOption.usingDepartmentNum && selectedSearchOption.usingDepartmentNum.length > 0) {
        //     param = {
        //         ...param,
        //         ownDepNums: selectedSearchOption.usingDepartmentNum.join(','),
        //     };
        // }
        return param;
    };

    const handleRefreshClick = () => {
        const startDate = moment().subtract(7, 'days').valueOf();
        const endDate = moment().valueOf();

        setStartDate(startDate);
        setEndDate(endDate);

        setKeyword({});
        setSelectedSearchOption(initialSelectedSearchOption);

        getAssetInspectionList({
            startDate: moment(startDate).startOf('day').unix(),
            endDate: moment(endDate).add(1, 'day').startOf('day').unix(),
        });
    };

    const handleDownloadClick = () => {
        if (!assetInspectionList.rows || !assetInspectionList.rows.length) {
            toggleExcelDownloadNoDataModal();
            return;
        }
        handleExcelDownloadToggle();
    };

    const downloadWithURL = (url, reqNum) => {
        excelTimerRef.current = setInterval(() => {
            fetchExcelLog({ reqNum });
        }, INTERVAL_TIME);
    };

    const clearExcelTimer = () => {
        clearInterval(excelTimerRef.current);
        excelTimerRef.current = null;
    };

    const { toggleModal: handleExcelDownloadToggle, Modal: ExcelDownloadConfirmModal } = useConfirmModal({
        initModal: false,
        header: { title: t('Download', 'ConfirmModal') },
        confirmText: t('Do you want to download the excel?'),
        okCallback: createAssetInspectionExcel,
        callbackParam: {
            ...keyword,
            startDate: moment(startDate).startOf('day').unix(),
            endDate: moment(endDate).add(1, 'day').startOf('day').unix(),
            isAll: 'Y',
        },
    });

    const { Modal: ExcelDownloadNoDataModal, toggleModal: toggleExcelDownloadNoDataModal } = useConfirmModal({
        initModal: false,
        header: { title: t('Notification', 'ConfirmModal') },
        confirmText: t('There is no data.', 'ConfirmModal'),
        removeCancel: true,
    });

    return (
        <Page menuPath={[t('Asset Inspection Management', 'Menu'), t('Asset Inspection List', 'Menu')]}>
            <div className="grid-layout-container">
                <Search
                    searchInputPlaceholder={t('Please enter the asset name or the asset id.')}
                    handleSearchClick={handleSearchClick}
                    handleRefreshClick={handleRefreshClick}
                    startDate={startDate}
                    endDate={endDate}
                    handleStartDate={selected => setStartDate(selected)}
                    handleEndDate={selected => setEndDate(selected)}
                >
                    {/* Smart Military Custom - 소유조직 필터 */}
                    {/*<InspectionOwnershipSearchableSelect*/}
                    {/*    handleChange={selected => {*/}
                    {/*        setSelectedSearchOption(prevState => ({ ...prevState, usingDepartmentNum: selected }));*/}
                    {/*    }}*/}
                    {/*    selected={selectedSearchOption.usingDepartmentNum}*/}
                    {/*/>*/}

                    <InspectionCategorySearchableSelect
                        handleChange={selected =>
                            setSelectedSearchOption(prevState => ({ ...prevState, categoryCodes: selected }))
                        }
                        selected={selectedSearchOption.categoryCodes}
                    />
                    <TimePicker
                        value={startDate}
                        valueType="ms"
                        maxDate={endDate || moment().valueOf()}
                        handleChange={selected => setStartDate(selected)}
                        withoutTime
                    />
                    <TimePicker
                        value={endDate}
                        valueType="ms"
                        minDate={startDate}
                        maxDate={moment().valueOf()}
                        handleChange={selected => setEndDate(selected)}
                        withoutTime
                    />
                </Search>

                <Card
                    header={{
                        title: t('Asset Inspection List', 'Menu'),
                        action: (
                            <Button className={'btn-brand'} onClick={handleDownloadClick}>
                                {t('Download')}
                            </Button>
                        ),
                    }}
                >
                    <div className={'table-container'}>
                        <Table
                            data={assetInspectionList}
                            columns={columns}
                            onPageChange={handlePageChange}
                            onTrClick={info => history.push(`/asset-inspection/detail/${info.targetNum}`)}
                            loading={assetInspectionListInfo.isLoading}
                        />
                    </div>
                </Card>
            </div>
            <ExcelDownloadConfirmModal />
            <ExcelDownloadNoDataModal />
        </Page>
    );
};

export default AssetInspectionList;
