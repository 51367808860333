import React, { createContext, useReducer, useState } from 'react';

import { useAsync } from '@hooks';

import { getBattleStatusAPI } from '@api/military';

import Card from '../../Components/WidgetCard';

import SelectBar from './Components/SelectBar';
import StateConversionPersonnel from './Components/StateConversionPersonnel';
import StateConversionMap from './Components/StateConversionMap';

import stateConversionReducer, { initialState, setConjuncture, setStartTime } from './stateConversionReducer';

import { StateCardContainer } from './style';

export const StateConversionStateContext = createContext(null);
export const StateConversionDispatchContext = createContext();

const StateConversion = ({ children, widgetInfo, ...restProps }) => {
    const [state, dispatch] = useReducer(stateConversionReducer, initialState);

    const [loading, setLoading] = useState(false);
    const [currentTime, setCurrentTime] = useState(null);
    const handleLoading = value => setLoading(value);

    const handleStartTime = time => setCurrentTime(time);

    useAsync({
        promise: getBattleStatusAPI,
        immediate: true,
        resolve: res => {
            const { conjunctureStatus, startTime, serverDate } = res;
            if (conjunctureStatus === 'Y') {
                setCurrentTime(serverDate);
                dispatch(setStartTime(startTime));
            }
            dispatch(setConjuncture(res));
        },
        reject: err => {
            console.error(err);
        },
    });

    return (
        <StateConversionDispatchContext.Provider value={dispatch}>
            <StateConversionStateContext.Provider value={state}>
                <Card widgetInfo={widgetInfo} {...restProps}>
                    <SelectBar currentTime={currentTime} handleStartTime={handleStartTime} />
                    <StateCardContainer>
                        <StateConversionPersonnel loading={loading} />
                        <StateConversionMap handleLoading={handleLoading} />
                    </StateCardContainer>
                    {children}
                </Card>
            </StateConversionStateContext.Provider>
        </StateConversionDispatchContext.Provider>
    );
};

export default StateConversion;
