import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';

import { Button, TextInput, Select } from '@components';
import { useTranslation, useConfirmModal, useAsync } from '@hooks';

import { createStatusInfoApi, getStatusCategoryInfoApi, getStatusListInfoApi, updateStatusInfoApi } from 'api/status';
import { setStatusListInfo } from 'reducers/Common/StatusInfo';
import { trim } from 'util/common/util';

import DetailModalHeader from '../../Components/DetailModalHeader';
import DetailLabel from '../../Components/DetailLabel';
import ColorPicker from '../../Components/ColorPicker';

const initStatusDetailInfo = {
    statusName: '',
    statusId: '',
    description: '',
    displayColor: '#000000',
    statusCategoryNum: null,
};

const StatusDetail = ({ isOpen, toggle, statusInfo }) => {
    const dispatch = useDispatch();
    const t = useTranslation('Status');
    const buttonT = useTranslation('Button');
    const { serviceCode } = useSelector(state => state.AppConfig);
    const [statusDetailInfo, setStatusDetailInfo] = useState(initStatusDetailInfo);

    const { promise: getStatusListInfo } = useAsync({
        promise: getStatusListInfoApi,
        fixedParam: { isAll: 'Y' },
        resolve: res => {
            dispatch(setStatusListInfo(res));
        },
    });

    useEffect(() => {
        if (statusInfo && statusInfo.statusNum) {
            setStatusDetailInfo(statusInfo);
        } else {
            setStatusDetailInfo(initStatusDetailInfo);
        }
    }, [statusInfo]);

    const [statusCategoryList, setStatusCategoryList] = useState([]);

    useAsync({
        promise: getStatusCategoryInfoApi,
        fixedParam: { isAll: 'Y' },
        immediate: true,
        resolve: res => {
            if (res && res.rows) {
                setStatusCategoryList(res.rows.map(v => ({ label: v.statusCategoryName, value: v.statusCategoryNum })));
            } else {
                setStatusCategoryList([]);
            }
        },
        reject: err => {
            setStatusCategoryList([]);
            console.error(err);
        },
    });

    // statusId 같아서 실패할 경우 처리 필요함
    const { promise: createStatusInfo } = useAsync({
        promise: createStatusInfoApi,
        resolve: res => {
            toggleSaveCompleteModal();
        },
        reject: err => {
            console.error(err);
            if (err.data) {
                const { code, errorResponse } = err.data;
                if (code === '1101' && errorResponse === 'targetStatus') {
                    toggleDuplicatedIdModal();
                }
            }
        },
    });
    const { promise: updateStatusInfo } = useAsync({
        promise: updateStatusInfoApi,
        resolve: () => {
            toggleSaveCompleteModal();
        },
        reject: err => {
            console.error(err);
        },
    });

    const { toggleModal: toggleSaveConfirmModal, Modal: SaveConfirmModal } = useConfirmModal({
        initModal: false,
        confirmText: t('Do you want to save?'),
        okCallback: () => {
            const {
                statusCategoryName,
                statusNum,
                comNum,
                modDate,
                modUserNum,
                regDate,
                regUserNum,
                schemeMaps,
                statusName,
                statusId,
                ...detailInfo
            } = statusDetailInfo;
            if (statusNum) {
                updateStatusInfo({
                    ...detailInfo,
                    statusName: trim(statusName),
                    statusId: trim(statusId),
                    serviceCode,
                });
            } else {
                createStatusInfo({
                    ...detailInfo,
                    statusName: trim(statusName),
                    statusId: trim(statusId),
                    serviceCode,
                });
            }
        },
    });
    const { toggleModal: toggleSaveCompleteModal, Modal: SaveCompleteModal } = useConfirmModal({
        initModal: false,
        removeCancel: true,
        confirmText: t('Save is complete.'),
        okCallback: () => {
            toggle();
            getStatusListInfo();
        },
    });
    const { toggleModal: toggleRequestRequiredModal, Modal: RequestRequiredModal } = useConfirmModal({
        initModal: false,
        removeCancel: true,
        confirmText: t('Please fill in the required fields. ( Display Name, ID )'),
    });
    const { toggleModal: toggleDuplicatedIdModal, Modal: DuplicatedIdModal } = useConfirmModal({
        initModal: false,
        removeCancel: true,
        confirmText: t('The entered ID is already in use. Please enter a different ID.'),
    });

    const handleChange = e => {
        const { value, name } = e.currentTarget;
        setStatusDetailInfo({ ...statusDetailInfo, [name]: value });
    };

    return (
        <>
            <Modal
                container={document.getElementsByClassName('app-container')[0]}
                isOpen={isOpen}
                toggle={toggle}
                className={'pnt-modal'}
            >
                <DetailModalHeader
                    title={`${t('Status')} - ${statusInfo ? t('Edit') : t('Add')}`}
                    subTitle={t(
                        statusInfo
                            ? 'This is a function to change the status of an asset.'
                            : 'This is a function to add the status of an asset.',
                    )}
                />
                <ModalBody style={{ display: 'grid', rowGap: '10px' }}>
                    <DetailLabel
                        required
                        name={t('Display name')}
                        value={
                            <div className="pnt-input--group">
                                <TextInput
                                    inputGroupClassName={'form-must'}
                                    type={'text'}
                                    maxlength={50}
                                    name={'statusName'}
                                    handleChange={handleChange}
                                    value={statusDetailInfo.statusName}
                                />
                            </div>
                        }
                    />
                    <DetailLabel
                        required
                        name={t('ID')}
                        value={
                            <div className="pnt-input--group">
                                <TextInput
                                    inputGroupClassName={'form-must'}
                                    type={'text'}
                                    maxlength={50}
                                    disabled={!!statusDetailInfo.statusNum}
                                    className={statusDetailInfo.statusNum ? 'disabled' : ''}
                                    style={statusDetailInfo.statusNum ? { backgroundColor: '#e3e3e3' } : {}}
                                    name={'statusId'}
                                    handleChange={handleChange}
                                    value={statusDetailInfo.statusId}
                                />
                            </div>
                        }
                    />
                    <div className={'pnt-border border-w mb-2 mt-2'} />
                    <DetailLabel
                        name={t('Description')}
                        value={
                            <TextInput
                                type={'text'}
                                maxlength={200}
                                name={'description'}
                                handleChange={handleChange}
                                value={statusDetailInfo.description}
                            />
                        }
                    />
                    <DetailLabel
                        name={t('Display Color')}
                        value={
                            <ColorPicker
                                name={'displayColor'}
                                selected={statusDetailInfo.displayColor}
                                onChange={selected => {
                                    handleChange({ currentTarget: { value: selected, name: 'displayColor' } });
                                }}
                            />
                        }
                    />
                    <DetailLabel
                        name={t('Category')}
                        value={
                            <div className="pnt-input--group">
                                <Select
                                    className="w-100"
                                    value={{
                                        value: statusDetailInfo.statusCategoryNum,
                                        label: statusDetailInfo.statusCategoryName,
                                    }}
                                    name={'statusCategoryNum'}
                                    options={statusCategoryList}
                                    customControlStyles={{ width: '100%' }}
                                    customMenuStyles={{ width: '100%' }}
                                    customOptionStyles={{ width: '100%' }}
                                    onChange={selected => {
                                        setStatusDetailInfo({
                                            ...statusDetailInfo,
                                            statusCategoryNum: selected.value,
                                            statusCategoryName: selected.label,
                                        });
                                    }}
                                />
                            </div>
                        }
                    />
                </ModalBody>
                <ModalFooter>
                    <Button className="color-brand" onClick={toggle}>
                        {buttonT('Cancel')}
                    </Button>
                    <Button
                        className="btn-brand flex-center gap-1"
                        iconName="check"
                        onClick={() => {
                            if (trim(statusDetailInfo.statusName) && trim(statusDetailInfo.statusId)) {
                                toggleSaveConfirmModal();
                            } else {
                                toggleRequestRequiredModal();
                            }
                        }}
                    >
                        {buttonT('Ok')}
                    </Button>
                </ModalFooter>
            </Modal>
            <SaveConfirmModal />
            <SaveCompleteModal />
            <RequestRequiredModal />
            <DuplicatedIdModal />
        </>
    );
};

export default StatusDetail;
