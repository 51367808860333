import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer, toast, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { fetchBookmarkList } from '@api/common';
import { fetchSelectedUserAlertInfo } from '@api/alert';
import { setCheckedInterfaceCommandType, setSelectedAlertInfo } from 'reducers/Notification';
import { setBookMarkedList } from 'reducers/Common/BookMarkedItem';

import { useAsync, useConfirmModal, useTranslation } from '@hooks';
import { isDanger } from 'util/mappInfo';
import { Button } from '@components';

import CloseButton from './Components/CloseButton';

import EVT_TYPE from 'util/staticData/eventType';

import GPS from 'assets/sound/gps.mp3';
import OUTSIDE from 'assets/sound/outside.mp3';
import SENSOR from 'assets/sound/sensor.mp3';
import SOS from 'assets/sound/sos.mp3';

const SENSING_TYPE_LEAKING = 'LEAKING';
const SENSING_TYPES = ['DISTANCE', 'CALORIES', 'SLEEP', 'HEARTBEAT', 'STRESS', 'STEP'];
const SENSING_VALUES = {
    DISTANCE: 'Distance',
    CALORIES: 'Calories',
    SLEEP: 'Sleep',
    HEARTBEAT: 'Heart Rate',
    STRESS: 'Stress',
    STEP: 'Step',
};
const SENSING_UNIT = {
    DISTANCE: 'm',
    CALORIES: 'Kcal',
    SLEEP: '%',
    HEARTBEAT: 'bpm',
    STRESS: '%',
    STEP: ' step',
};

const getSoundType = type => {
    if (type === 'SOS_ON') return SOS;
    if (type === 'OUTSIDE_ON') return OUTSIDE;
    if (type === 'GPS_ON') return GPS;

    const sensor = ['SENSOR_WARNING', 'SENSOR_CRITICAL', 'SENSOR_OUTOFRANGE', 'SENSOR_LOSTSIGNAL'];
    if (sensor.includes(type)) return SENSOR;

    return null;
};

const getEvtType = ({ evtTypeMap }) => {
    const evtTypes = [];
    for (let type in evtTypeMap) {
        evtTypes.push({
            type,
            name: evtTypeMap[type],
            typeClassName: isDanger(type) ? 'text-danger' : 'text-primary',
            sound: getSoundType(type),
        });
    }
    return evtTypes;
};

const ToastAlert = () => {
    const t = useTranslation('Toast Alert');

    const history = useHistory();
    const location = useLocation();

    const eventType = getEvtType({ evtTypeMap: EVT_TYPE });

    const dispatch = useDispatch();
    const {
        alert,
        toastConfig: { autoClose, limit, isAlert },
    } = useSelector(state => state.Notification);
    const { bookMarkedList } = useSelector(state => state.BookMarkedItem);
    const {
        userInfo: { userNum },
    } = useSelector(state => state.UserInfo);
    const { locationApproval } = useSelector(state => state.Navy);

    useAsync({
        promise: fetchBookmarkList,
        immediate: true,
        resolve: res => {
            dispatch(setBookMarkedList(res.rows));
        },
    });

    const { promise: getSelectedUserAlertInfo } = useAsync({
        promise: fetchSelectedUserAlertInfo,
        fixedParam: { isAll: 'Y' },
        resolve: res => {
            dispatch(setCheckedInterfaceCommandType(res.alertConfigs));
        },
    });

    useEffect(() => {
        if (userNum) {
            getSelectedUserAlertInfo({ userNum });
        }
    }, [userNum]);

    const { toggleModal: toggleConfirmApprovalModal, Modal: ConfirmApprovalModal } = useConfirmModal({
        initModal: false,
        header: { title: t('Confirm', 'ConfirmModal') },
        confirmText: t('Location view permission required.', 'Integrated Monitor'),
        removeCancel: true,
    });

    const handleAlert = ({ alert }) => {
        const { targetName, interfaceCommandType, alertDate, floorName, lat, lng, sensingType, sensingValue } = alert;

        const event = eventType.find(type => type.type === interfaceCommandType);
        const { typeClassName, name, sound, type } = event;

        let eventName = t(name, 'Event Types');
        if (name.includes(':')) {
            const splitName = name.split(':').map(e => t(e.trim(), 'Event Types'));
            if (sensingType === SENSING_TYPE_LEAKING) {
                splitName[0] = t(sensingType, 'Event Types');
            } else if (SENSING_TYPES.includes(sensingType)) {
                splitName[0] = t(SENSING_VALUES[sensingType], 'Event Types');
            }
            eventName = splitName.join(' : ');
        }

        const alertSound = sound ? new Audio(sound) : null;

        const handleClick = () => {
            if (type.includes('GPS')) {
                history.push('/monitoring/drowning');
                toast.dismiss();
                return;
            }

            if (!locationApproval) {
                toggleConfirmApprovalModal();
            } else {
                dispatch(setSelectedAlertInfo({ ...alert, latLng: [lat, lng] }));
            }
        };

        toast(
            <div className="font-weight-bold">
                <div className="d-flex justify-content-between">
                    <div className={typeClassName}>{eventName}</div>
                    <div>{alertDate}</div>
                </div>
                <div>{`${t('Name')} : ${targetName}`}</div>
                <div>{`${t('Location')} : ${floorName || t('No Info')}`}</div>
                {!!lat && !!lng && <div>{`(${t('Lat')} : ${lat.toFixed(5)}, ${t('Lng')} : ${lng.toFixed(5)})`}</div>}
                {SENSING_TYPES.includes(sensingType) && (
                    <div>{`${t(SENSING_VALUES[sensingType], 'Event Types')} : ${sensingValue}${
                        SENSING_UNIT[sensingType]
                    }`}</div>
                )}
                <div className="d-flex justify-content-center mt-2">
                    <Button
                        className="btn-secondary btn-tiny"
                        style={{ fontSize: '0.8rem' }}
                        onClick={handleClick}
                        disabled={!lat || !lng}
                    >
                        {t('View Location')}
                    </Button>
                </div>
            </div>,
            {
                transition: Zoom,
                onOpen: () => {
                    if (alertSound) alertSound.play();
                },
                onClose: () => {
                    if (alertSound) alertSound.pause();
                },
                // progressClassName: 'bg-danger',
            },
        );
    };

    useEffect(() => {
        if (alert) {
            const { targetNum } = alert;
            const isBookMarked = bookMarkedList.some(target => target.targetNum === targetNum);
            if (isBookMarked) {
                handleAlert({ alert });
            }
        }
    }, [alert]);

    return (
        <>
            {isAlert && location.pathname === '/monitoring/integrated' && (
                <ToastContainer
                    position="bottom-right"
                    limit={limit}
                    autoClose={autoClose}
                    hideProgressBar
                    newestOnTop
                    closeOnClick={false}
                    pauseOnFocusLoss={false}
                    bodyClassName="w-100"
                    toastClassName="p-1"
                    closeButton={CloseButton}
                />
            )}
            <ConfirmApprovalModal />
        </>
    );
};

export default ToastAlert;
