import React, { useState } from 'react';

import { getLoginLogListApi } from '@api/log';

import { Page, Card, Table } from '@components';
import { useColumns, useAsync, useTranslation } from '@hooks';

import Search from './Components/Search';

import * as column from '../../util/grid/column';

const LoginHistory = () => {
    const t = useTranslation('Login History Log');

    const [loginLogData, setLoginLogData] = useState({
        totalCount: 0,
        totalPage: 1,
        rows: [],
    });

    const columns = useColumns([
        column.userId({}),
        column.userName({}),
        column.type({
            formatTitle: ({ value }) => {
                if (value === 'F') {
                    return t('Failure');
                } else if (value === 'S') {
                    return t('Success');
                } else {
                    return '-';
                }
            },
            Cell: ({ value }) => {
                if (value === 'F') {
                    return t('Failure');
                } else if (value === 'S') {
                    return t('Success');
                } else {
                    return '-';
                }
            },
        }),
        column.description({
            formatTitle: ({ value }) => {
                return value || '-';
            },
            Cell: ({ value }) => {
                return value || '-';
            },
            width: 180,
        }),
        column.ip({}),
        column.registeredDate({
            className: 'text-ellipsis',
        }),
    ]);

    const { promise: getLoginLogList, state: loginLogListInfo } = useAsync({
        promise: getLoginLogListApi,
        keepState: true,
        immediate: true,
        fixedParam: { pageSize: 15 },
        resolve: res => {
            setLoginLogData(res);
        },
    });

    const handlePageChange = pageIndex => {
        if (loginLogListInfo.request && (loginLogListInfo.request.keyword || loginLogListInfo.request.type)) {
            getLoginLogList({ ...loginLogListInfo.request, page: pageIndex });
        } else {
            getLoginLogList({ page: pageIndex });
        }
    };

    return (
        <Page menuPath={[t('Report', 'Menu'), t('Login History', 'Menu')]} className={'h-100'}>
            <Search getLoginLogList={getLoginLogList} />
            <Card
                className={'report-container'}
                header={{
                    title: t('Login History', 'Menu'),
                }}
            >
                <div style={{ height: '35rem' }}>
                    <Table
                        data={{ ...loginLogData, pageSize: 15 }}
                        columns={columns}
                        onPageChange={handlePageChange}
                        loading={loginLogListInfo.isLoading}
                    />
                </div>
            </Card>
        </Page>
    );
};

export default LoginHistory;
