import React, { useEffect, useState } from 'react';

import { useAsync } from '@hooks';
import { getWatchStatusCountAPI, getWatchStatusListAPI } from '@api/military';

import Card from '../../Components/WidgetCard';

import SmartWatchStatus from './Components/SmartWatchStatus';
import AbsenceStatus from './Components/AbsenceStatus';
import SmartWatchTable from './Components/SmartWatchTable';
import { watchStateList, absenceStateList } from './Components/util';

import useMonitor from '../../util/useMonitor';

const SmartWatchHealthMonitoring = ({ children, widgetInfo, ...restProps }) => {
    const { config } = widgetInfo;

    const [watchStatus, setWatchStatus] = useState({});
    const [selectedWatchStatus, setSelectedWatchStatus] = useState(null);
    const handleWatchStatusClick = data => {
        setSelectedAbsenceStatus(null);
        if (selectedWatchStatus && data.id === selectedWatchStatus.id) {
            setSelectedWatchStatus(null);
            return;
        }
        setSelectedWatchStatus(data);
    };

    const [absenceStatus, setAbsenceStatus] = useState({});
    const [selectedAbsenceStatus, setSelectedAbsenceStatus] = useState(null);
    const handleAbsenceStatusClick = data => {
        setSelectedWatchStatus(null);
        if (selectedAbsenceStatus && data.id === selectedAbsenceStatus.id) {
            setSelectedAbsenceStatus(null);
            return;
        }
        setSelectedAbsenceStatus(data);
    };

    const [tableData, setTableData] = useState({ rows: [] });

    useMonitor({
        config,
        fetchData: getWatchStatusCountAPI,
        makeData: ({ newData }) => {
            const watch = {};
            if (newData) {
                Object.entries(watchStateList).forEach(([key, value]) => {
                    watch[value] = newData[key] || 0;
                });
            }
            setWatchStatus(watch);

            const absence = {};
            if (newData) {
                Object.entries(absenceStateList).forEach(([key, value]) => {
                    absence[value] = newData[key] || 0;
                });
            }
            setAbsenceStatus(absence);
        },
        errorHandler: err => {
            console.error(err);
        },
    });

    const { promise: getWatchStatusList, state: watchStatusListState } = useAsync({
        promise: getWatchStatusListAPI,
        fixedParam: { isAll: 'Y' },
        resolve: res => {
            const { rows } = res;
            setTableData({ rows: rows || [] });
        },
        reject: err => {
            console.error(err);
        },
    });

    useEffect(() => {
        const param = {};
        if (selectedWatchStatus) {
            Object.entries(watchStateList).forEach(([key, value]) => {
                if (value === selectedWatchStatus.id) {
                    param.watchState = key === 'ALL' ? null : key;
                }
            });
        }
        if (selectedAbsenceStatus) {
            const { id } = selectedAbsenceStatus;
            param.options = { status: id };
        }
        getWatchStatusList(param);
    }, [selectedWatchStatus, selectedAbsenceStatus]);

    return (
        <Card widgetInfo={widgetInfo} {...restProps}>
            <SmartWatchStatus data={watchStatus} selected={selectedWatchStatus} handleClick={handleWatchStatusClick} />
            <AbsenceStatus
                data={absenceStatus}
                selected={selectedAbsenceStatus}
                handleClick={handleAbsenceStatusClick}
            />
            <SmartWatchTable tableData={tableData} loading={watchStatusListState.isLoading} />
            {children}
        </Card>
    );
};

export default SmartWatchHealthMonitoring;
