import React, { useEffect, useMemo, useState } from 'react';

import { useTranslation } from '@hooks';
import useMonitor from '../../../util/useMonitor';

import { getBoardingStatusAPI } from '@api/military';

import CompoundCard from '../../../Components/CompoundCard';

const tableLabels = [
    { id: 'ALL', label: 'All' },
    { id: 'BOARDING_ON', label: 'Boarding' },
    { id: 'BOARDING_OFF', label: 'No Boarding' },
];

const NumberOfPassengers = ({ config, searchOption, handleSearchClick }) => {
    const t = useTranslation('Status Of Entry And Exit Of Vessel');

    const { selectedOption, searchInput } = searchOption;

    const [searchData, setSearchData] = useState({ option: selectedOption, inputValue: searchInput });
    const handleTableClick = ({ id, value, label }) => {
        if (!value) return;
        setSearchData({ option: { value: id, label }, inputValue: '' });
    };

    const [tableData, setTableData] = useState({
        ALL: 0,
        BOARDING_ON: 0,
        BOARDING_OFF: 0,
    });

    // tableData의 label을 번역하는 변수
    const translatedTableData = useMemo(() => {
        return tableLabels.map(({ id, label }) => {
            const value = tableData && tableData[id] ? tableData[id] : 0;
            return { id, value, label: t(label) };
        });
    }, [t, tableData]);

    useEffect(() => {
        const debounce = setTimeout(() => {
            handleSearchClick(searchData);
        }, 400);
        return () => clearTimeout(debounce);
    }, [searchData]);

    useMonitor({
        config,
        fetchData: getBoardingStatusAPI,
        makeData: ({ newData }) => {
            const id = selectedOption ? selectedOption['value'] : null;
            if (id && newData && newData[id] !== tableData[id]) {
                handleSearchClick({ option: selectedOption, inputValue: searchInput });
            }
            setTableData(newData);
        },
        errorHandler: err => {
            console.error(err);
        },
    });

    return (
        <CompoundCard className="mb-1">
            <CompoundCard.header>{t('The Number of Passengers')}</CompoundCard.header>
            <CompoundCard.body>
                <CompoundCard.body.clickableTable
                    data={translatedTableData}
                    handleClick={handleTableClick}
                    clicked={!searchInput && selectedOption ? selectedOption['value'] : null}
                    bodyClassName={{
                        BOARDING_ON: 'color-success',
                        BOARDING_OFF: 'color-danger',
                    }}
                />
            </CompoundCard.body>
        </CompoundCard>
    );
};

export default NumberOfPassengers;
