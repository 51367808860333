import React, { createContext, useReducer, useEffect } from 'react';
import { useSelector } from 'react-redux';

import Card from '../../Components/WidgetCard';

import { useAsync } from '@hooks';
import { getPatrolDashboardAPI, getPatrolStatusAPI } from '@api/military';

import patrolOnDutyReducer, { initialState, setPatrolDashboard, setPatrolInfo } from './patrolOnDutyReducer';

import PatrolStatus from './Components/PatrolStatus';
import PatrolDuty from './Components/PatrolDuty';
import { PatrolMonitoringContainer } from './style';

export const PatrolDutyStateContext = createContext(null);
export const PatrolDutyDispatchContext = createContext();

const PatrolOnDuty = ({ children, widgetInfo, ...restProps }) => {
    const [state, dispatch] = useReducer(patrolOnDutyReducer, initialState);
    const { patrolStatus, selectedDivOption, selectedFloorStr } = state;
    const { patrolZoneState, patrolState } = useSelector(state => state.Navy);

    const { promise: getPatrolDashboard } = useAsync({
        promise: getPatrolDashboardAPI,
        immediate: true,
        resolve: res => {
            dispatch(setPatrolDashboard(res));
        },
        reject: err => {
            console.error(err);
        },
    });

    const { promise: getPatrolStatus } = useAsync({
        promise: getPatrolStatusAPI,
        resolve: res => {
            dispatch(setPatrolInfo(res));
        },
        reject: err => {
            console.error(err);
        },
    });

    useEffect(() => {
        if (!!selectedDivOption.value && !!selectedFloorStr) {
            getPatrolStatus({
                floor: selectedFloorStr,
                patrolDiv: selectedDivOption.value,
            });
        }
    }, [patrolStatus, selectedDivOption, selectedFloorStr, patrolZoneState]);

    useEffect(() => {
        if (patrolState) {
            getPatrolDashboard();
        }
    }, [patrolState]);

    return (
        <PatrolDutyDispatchContext.Provider value={dispatch}>
            <PatrolDutyStateContext.Provider value={state}>
                <Card widgetInfo={widgetInfo} {...restProps}>
                    <PatrolMonitoringContainer>
                        <PatrolStatus />
                        <PatrolDuty />
                    </PatrolMonitoringContainer>
                    {children}
                </Card>
            </PatrolDutyStateContext.Provider>
        </PatrolDutyDispatchContext.Provider>
    );
};

export default PatrolOnDuty;
