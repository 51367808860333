import React from 'react';
import { useTranslation } from 'react-i18next';
import WidgetSettingModal from '../../../Components/WidgetSettingModal';

const HourlyCongestionStatusSetting = ({ widgetInfo, ...restProps }) => {
    const { t } = useTranslation();
    return (
        <WidgetSettingModal
            headerTitle={t('Widget;Hourly congestion status')}
            widgetInfo={widgetInfo}
            socketWidget
            hiddenHeader
            {...restProps}
        />
    );
};

export default HourlyCongestionStatusSetting;
