import React, { useState } from 'react';
import { CardProps } from '../type';
import { Card as RSCard, CardBody, CardFooter, CardHeader, CardTitle } from 'reactstrap';
import Button from '../Button';

const Card = ({
    className,
    bodyClassName,
    header = { title: '' },
    footerTitle,
    overlayOn: on = false,
    overlay = { title: '' },
    children,
    innerRef,
}: CardProps): JSX.Element => {
    const { title, subTitle, action, titleIcon } = header;
    const { title: overlayTitle, action: overlayAction, content } = overlay;
    const [overlayOn, setOverlayOn] = useState(on);
    return (
        <>
            <RSCard className={className} innerRef={innerRef}>
                {!!title && (
                    <CardHeader>
                        <div className={'card-header__title'}>
                            {titleIcon && <span className={`${titleIcon}`} />}
                            <div className="title-wrap">
                                <CardTitle tag={'h3'} className={'title__main'}>
                                    {title}
                                </CardTitle>
                                {subTitle && <p className={'title__sub'}>{subTitle}</p>}
                            </div>
                        </div>

                        {(!!action || !!overlayTitle) && (
                            <div className={'card-header__function'}>
                                {action}
                                {!!overlayTitle && (
                                    <Button
                                        className={'btn-icon-only card-overwrap-btn'}
                                        onClick={e => setOverlayOn(!overlayOn)}
                                        iconClassName={`icon-info`}
                                        iconName={`icon-info`}
                                    />
                                )}
                            </div>
                        )}
                    </CardHeader>
                )}

                <CardBody className={bodyClassName}>{children}</CardBody>

                {footerTitle && <CardFooter>{footerTitle}</CardFooter>}

                {overlayOn && (
                    <div className={`card-overlay on`}>
                        <RSCard>
                            <CardHeader>
                                <div className="card-header__title">
                                    <CardTitle tag={'h3'} className={'title__main'}>
                                        {overlayTitle}
                                    </CardTitle>
                                </div>
                                <div className="card-header__function">
                                    {overlayAction}
                                    <Button
                                        className={'btn-icon-only btn-small p-0 card-overwrap-close-btn'}
                                        onClick={e => setOverlayOn(!overlayOn)}
                                        iconClassName={'icon-close'}
                                        iconName={'icon-close'}
                                    />
                                </div>
                            </CardHeader>

                            <CardBody>{content}</CardBody>
                        </RSCard>
                    </div>
                )}
            </RSCard>
        </>
    );
};

export default Card;
