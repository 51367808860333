import { createSlice } from '@reduxjs/toolkit';
import { stateOptions } from './Components/util';

export const INITIAL_CONJUNCTURE_PERSONNEL_BY_FLOOR = {
    manualReason: '',
    geofenceEndTime: null,
    complete: [],
    incomplete: [],
};

export const initialState = {
    floorInfo: {
        floorId: 'entire',
    },
    geofenceList: [],
    conjunctureGeofence: [],
    selectedGeofence: null,
    situation: null,
    startTime: null,
    lastConjunctureStatus: {
        situation: null,
        battleNum: null,
        startTime: null,
        endTime: null,
    },
    conjunctureStatus: 'N',
    conjunctureComplete: {
        entire: 0,
    },
    conjunctureInComplete: {
        entire: 0,
    },
    conjuncturePersonnel: { rows: [] },
    conjuncturePersonnelByFloor: INITIAL_CONJUNCTURE_PERSONNEL_BY_FLOOR,
};

const { actions, reducer } = createSlice({
    name: 'stateConversion',
    initialState,
    reducers: {
        setFloorInfo: (state, action) => {
            state.floorInfo = action.payload;
        },
        setGeofenceList: (state, action) => {
            state.geofenceList = action.payload;
        },
        setSelectedGeofence: (state, action) => {
            state.selectedGeofence = action.payload;
        },
        setBattleNum: (state, action) => {
            state.lastConjunctureStatus.battleNum = action.payload;
        },
        setSituation: (state, action) => {
            const situation = action.payload;
            const foundSituation = situation.value
                ? situation
                : stateOptions.find(option => option.value === situation);
            state.situation = foundSituation;
        },
        setStartTime: (state, action) => {
            state.startTime = action.payload;
        },
        setConjunctureStatus: (state, action) => {
            state.conjunctureStatus = action.payload;
        },
        setConjunctureAllCount: (state, action) => {
            const { allCompleteCnt, allNotCompleteCnt } = action.payload;
            state.conjunctureComplete['entire'] = allCompleteCnt || 0;
            state.conjunctureInComplete['entire'] = allNotCompleteCnt || 0;
        },
        setConjunctureCount: (state, action) => {
            const { complete, inComplete } = action.payload;
            state.conjunctureComplete = complete || {};
            state.conjunctureInComplete = inComplete || {};
        },
        setConjuncture: (state, action) => {
            const {
                situation,
                battleNum,
                startTime,
                endTime,
                allCompleteCnt,
                allNotCompleteCnt,
                conjunctureStatus,
                floorCompleteCnt,
                floorNotCompleteCnt,
                allGeofences,
                ConjunctureOn: conjunctureOn,
            } = action.payload;

            if (conjunctureOn) {
                return;
            }

            if (situation) {
                const foundSituation = situation.value
                    ? situation
                    : stateOptions.find(option => option.value === situation);
                state.situation = foundSituation;
                state.lastConjunctureStatus = { situation: foundSituation, battleNum, startTime, endTime };
            }

            state.conjunctureStatus = conjunctureStatus;
            if (conjunctureStatus === 'Y' && !!allGeofences) {
                state.conjunctureGeofence = allGeofences.map(row => {
                    return {
                        ...row,
                        bounds: [row.latLngList.map(({ lat, lng }) => [lat, lng])],
                    };
                });
            }

            state.conjunctureComplete = { ...state.conjunctureComplete, ...floorCompleteCnt, entire: allCompleteCnt };
            state.conjunctureInComplete = {
                ...state.conjunctureInComplete,
                ...floorNotCompleteCnt,
                entire: allNotCompleteCnt,
            };
        },
        setConjuncturePersonnel: (state, action) => {
            state.conjuncturePersonnel = action.payload;
        },
        setConjuncturePersonnelByFloor: (state, action) => {
            const { complete, incomplete, geofenceEndTime, manualReason } = action.payload;
            state.conjuncturePersonnelByFloor = {
                complete: complete || [],
                incomplete: incomplete || [],
                geofenceEndTime: geofenceEndTime || null,
                manualReason: manualReason || '',
            };
        },
        setConjunctureGeofence: (state, action) => {
            state.conjunctureGeofence = action.payload;
        },
    },
});

export const {
    setFloorInfo,
    setGeofenceList,
    setSelectedGeofence,
    setBattleNum,
    setSituation,
    setStartTime,
    setConjuncture,
    setConjunctureStatus,
    setConjunctureAllCount,
    setConjunctureCount,
    setConjuncturePersonnel,
    setConjuncturePersonnelByFloor,
    setConjunctureGeofence,
} = actions;

export default reducer;
