import React from 'react';
import { Col } from 'reactstrap';

import cx from 'classnames';

import { useDispatch, useSelector } from 'react-redux';
import { setColorScheme } from 'reducers/ThemeOptions';

import Modal from '@components/Modal';
import useTranslation from '@hooks/useTranslation';

const ThemeSwatch = ({ className }) => {
    const dispatch = useDispatch();
    const { colorScheme } = useSelector(state => state.ThemeOptions);
    return (
        <div
            className={cx('swatch-holder cursor-pointer', className, { active: colorScheme === className })}
            onClick={() => {
                dispatch(setColorScheme(className));
            }}
        />
    );
};

const AppSettingModal = ({ initModal, toggleModal }) => {
    const t = useTranslation('UserBox');
    return (
        <Modal initModal={initModal} toggleModal={toggleModal} headerTitle={t('Application setting')} removeCancel>
            <div className={'modal-body'}>
                <Col>
                    <h6 className="pb-2">{t('Please choose color scheme')}</h6>
                    <div className="theme-settings-swatches">
                        <ThemeSwatch className={'pnt-navy'} />
                        <ThemeSwatch className={'pnt-light'} />
                        <ThemeSwatch className={'pnt-dark'} />
                        <ThemeSwatch className={'pnt-flower'} />
                        <ThemeSwatch className={'pnt-plant'} />
                    </div>
                </Col>
            </div>
        </Modal>
    );
};

export default AppSettingModal;
