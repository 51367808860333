import { createSlice } from '@reduxjs/toolkit';
import { TYPE_TO_SELECT } from 'Components/MainPages/Filter/FilterWidget/dynamicOption';

const initialState = {
    serviceCategory: null, // 없으면 null
    serviceCategoryCodes: '',
    topCategoryList: [],
    categoryList: [],
    categoryPropertiesList: [],
    searchableCategoryPropertiesList: [],
    categoryToImg: {},
};

const { actions, reducer } = createSlice({
    name: 'common/categoryInfo',
    initialState,
    reducers: {
        setTopCategoryList: (state, action) => {
            const categoryList = action.payload
                .filter(category => !category.depth)
                .map(topCategory => {
                    return { value: topCategory.categoryCode, label: topCategory.categoryName };
                });
            state.topCategoryList = categoryList;
        },
        setCategoryList: (state, action) => {
            state.categoryList = [...action.payload];
        },
        setCategoryPropertiesList: (state, action) => {
            const propertiesList = action.payload;
            state.categoryPropertiesList = propertiesList;
            state.searchableCategoryPropertiesList = propertiesList.filter(
                v => v.isSearchable === 'Y' && TYPE_TO_SELECT.includes(v.inputType),
            );
        },
        setAllCategoryList: (state, action) => {
            const categoryList = [...action.payload];

            if (categoryList && Array.isArray(categoryList)) {
                categoryList.sort((a, b) => (a.categoryCodePath > b.categoryCodePath ? 1 : -1));
                state.categoryToImg = {};
                const serviceCategoryCodes = [];
                categoryList.forEach(category => {
                    state.categoryToImg[category.categoryCode] = category.normalIconURL;
                    if (!state.serviceCategory) {
                        if (category.depth === 0) {
                            serviceCategoryCodes.push(category.categoryCode);
                        }
                    } else {
                        if (category.parentCode === state.serviceCategory) {
                            serviceCategoryCodes.push(category.categoryCode);
                        }
                    }
                });
                state.serviceCategoryCodes = serviceCategoryCodes.join(',') || state.serviceCategory;
            } else {
                state.serviceCategoryCodes = state.serviceCategory;
            }

            state.categoryList = categoryList;
            state.topCategoryList = categoryList
                .filter(category => !category.depth)
                .map(topCategory => {
                    return { value: topCategory.categoryCode, label: topCategory.categoryName };
                });
        },
    },
});

export const { setTopCategoryList, setCategoryList, setCategoryPropertiesList, setAllCategoryList } = actions;
export default reducer;
