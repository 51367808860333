import React, { useContext, useState } from 'react';

import { useAsync } from '@hooks';
import { getTargetLocationLogApi } from 'api/log';
import { fetchAlertInfo } from 'api/alert';

import { setFloorInfo, setInitPlayerState } from '../DrowningMonitoringReducer';
import { DrowningMonitoringDispatchContext, DrowningMonitoringStatusContext } from '../index';

import Search from './Search';
import AnalysisByRealTime from './AnalysisByRealTime';
import AnalysisByTrack from './AnalysisByTrack';

const LOST_SIGNAL = 'GPS_LOSTSIGNAL';

const Analysis = ({ toggleSelectDateRangeModal }) => {
    const [targetLocationLog, setTargetLocationLog] = useState([]);
    const [lostSignalLog, setLostSignalLog] = useState([]);

    const { playerState } = useContext(DrowningMonitoringStatusContext);
    const dispatch = useContext(DrowningMonitoringDispatchContext);

    const { promise: getTargetLocationLog } = useAsync({
        promise: getTargetLocationLogApi,
        fixedParam: { isAll: 'Y' },
        resolve: res => {
            const { rows } = res;
            if (rows) {
                let startTime = null;
                let endTime = null;
                rows.forEach(log => {
                    const { regDate } = log;

                    // 플레이어 시간 정보
                    if (!startTime || startTime > regDate) {
                        startTime = regDate;
                    }
                    if (!endTime || endTime < regDate) {
                        endTime = regDate;
                    }
                });

                if (startTime && endTime) {
                    dispatch(setInitPlayerState({ startTime, endTime }));
                }
                setTargetLocationLog(rows || []);
            }
        },
        reject: err => {
            console.error(err);
        },
    });

    const { promise: getAlertInfo } = useAsync({
        promise: fetchAlertInfo,
        fixedParam: { isAll: 'Y' },
        resolve: res => {
            setLostSignalLog(res.rows.filter(({ interfaceCommandType }) => interfaceCommandType === LOST_SIGNAL));
        },
        reject: err => {
            console.error(err);
            setLostSignalLog([]);
        },
    });

    const handleSearch = ({ startDate, endDate, floorIds, floorInfoState }) => {
        if (!startDate || !endDate) {
            toggleSelectDateRangeModal();
            return;
        }

        dispatch(setFloorInfo(floorInfoState));
        getTargetLocationLog({ startDate, endDate, floorIds });
        getAlertInfo({ startDate, endDate, floorIds });
    };

    return (
        <>
            <Search getScannerStateLogList={handleSearch} />
            <div className="analysis-layout">
                {playerState === 'real-time' ? (
                    <AnalysisByRealTime />
                ) : (
                    <AnalysisByTrack targetLocationLog={targetLocationLog} lostSignalLog={lostSignalLog} />
                )}
            </div>
        </>
    );
};

export default Analysis;
