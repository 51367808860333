import { reqGet, reqPost, reqPut } from './index';

//// RMS 순찰 API
// 순찰구분 목록 API
export const getPatrolDailyPatroldivAPI = param => reqGet('/v3/api/sm01/common/patrol/daily/patroldiv', param);

// 순찰관리 등록 API
export const createPatrolDailyRegAPI = param => reqPost('/v3/api/sm01/common/patrol/daily/reg', param);

// 당직근무수칙 조회 API
export const getPatrolDailyRuleAPI = param => reqGet(`/v3/api/sm01/common/patrol/daily/rule/${param.patrolDiv}`, param);
// 당직근무수칙 등록 API
export const createPatrolDailyRuleAPI = param =>
    reqPut(`/v3/api/sm01/common/patrol/daily/rule/${param.patrolDiv}`, param);

// 순찰관리 날짜별 조회 API
export const getPatrolDailySearchAPI = param => reqGet('/v3/api/sm01/common/patrol/daily/search', param);

// 순찰 대시보드 API
export const getPatrolDashboardAPI = param => reqGet('/v3/api/sm01/common/patrol/dashboard', param);

// 순찰 종료 API
export const createPatrolEndAPI = param => reqPost('/v3/api/sm01/common/patrol/end', param);

// 전체층용 순찰 현황 API
export const getPatrolEntireStatusAPI = param => reqGet('/v3/api/sm01/common/patrol/entire/status', param);

// 순찰 시작 API
export const createPatrolStartAPI = param => reqPost('/v3/api/sm01/common/patrol/start', param);

// 층별 순찰 현황 (전체층 제외) API
export const getPatrolStatusAPI = param => reqGet('/v3/api/sm01/common/patrol/status', param);

//// 건강관리 모니터링 API
// 대상 목록 조회
export const getHealthCareListAPI = param => reqGet('/v3/api/sm01/common/health-care/list', param);

// 대상 센서 정보
export const getHealthCareSensorListAPI = param => reqGet('/v3/api/sm01/common/health-care/sensor-list', param);

//// 건강정보 제공현황 API
// 건강정보 검색 목록 조회
export const getHealthMonitoringListAPI = param => reqGet('/v3/api/sm01/common/health/monitoring', param);

// 심박 이상 현황 조회
export const getAbnormalHeartBeatAPI = param => reqGet('/v3/api/sm01/common/health/monitoring/count', param);

// 검색 메뉴 조회
export const getHealthMonitoringSelectAPI = param => reqGet('/v3/api/sm01/common/health/monitoring/select', param);

//// 국면 전환 API
// 국면 시작 API
export const getBattleStartAPI = param => reqGet('/v3/api/sm01/common/battle/start', param);

// 국면 종료 API
export const getBattleEndAPI = param => reqGet('/v3/api/sm01/common/battle/end', param);

// 국면 상태 API
export const getBattleStatusAPI = param => reqGet('/v3/api/sm01/common/battle/status', param);

// 국면 지오펜스 클릭 API
export const getBattleGeofenceAPI = param => reqGet('/v3/api/sm01/common/battle/geofence', param);

// 국면 지오펜스 수동배치 API
export const putBattleModifyAPI = param => reqPut('/v3/api/sm01/common/battle/modify', param);

// 지오펜스 목록 API
export const getBattleGeofenceListAPI = param => reqGet('/v3/api/sm01/common/battle/geofence/list', param);

//// 워치 상태 모니터링 API
// 상태갯수 반환 API
export const getWatchStatusCountAPI = param => reqGet('/v3/api/sm01/common/watch/status/count', param);
// 대상목록 반환 API
export const getWatchStatusListAPI = param => reqGet('/v3/api/sm01/common/watch/status/list', param);

////함정 출입인원 현황 API
// 대상 목록 API
export const getBoardingListAPI = param => reqGet('/v3/api/sm01/common/boarding/list', param);
// 상태별 개수 API
export const getBoardingStatusAPI = param => reqGet('/v3/api/sm01/common/boarding/status', param);

//// 통합 모니터 API
// 위치조회 승인 현재 상태 API
export const getDashboardMonitoringApprovalStatusAPI = param =>
    reqGet('/v3/api/sm01/common/dashboard/monitoring/approval/status', param);
// 층별 인원 현황 API
export const getDashboardMonitoringStatisticsAPI = param =>
    reqGet('/v3/api/sm01/common/dashboard/monitoring/floor/statistics', param);
// 위치 조회 승인/종료 요청 API
export const postDashboardMonitoringLocationApprovalAPI = param =>
    reqPost('/v3/api/sm01/common/dashboard/monitoring/location/approval', param);
// 함정 상태 변경 API
export const postDashboardMonitoringSailModifyStatusAPI = param =>
    reqPost('/v3/api/sm01/common/dashboard/monitoring/sail/modify/status', param);
// 함정 현재 상태 API
export const getDashboardMonitoringSailStatusAPI = param =>
    reqGet('/v3/api/sm01/common/dashboard/monitoring/sail/status', param);
// 태그 상태개요 API
export const getDashboardMonitoringTagStatisticsAPI = param =>
    reqGet('/v3/api/sm01/common/dashboard/monitoring/tag/statistics', param);
// 지오펜스 입장한 대상 정보 API
export const getDashboardMonitoringZoneStatisticsAPI = param =>
    reqGet('/v3/api/sm01/common/dashboard/monitoring/zone/statistics', param);

//// 전자교범 API
// 카테고리 목록 API
export const getManualCategoryAPI = param => reqGet('/v3/api/sm01/common/manual/category', param);
// 상세조회 API
export const getManualInfoAPI = param => reqGet('/v3/api/sm01/common/manual/info', param);
// 목록 조회 API
export const getManualListAPI = param => reqGet('/v3/api/sm01/common/manual/list', param);

//// 로그 API
// 위치조회 승인 로그 API
export const getLocationApprovalLogAPI = param => reqGet('/v3/api/sm01/common/log/approve', param);
// 위치조회 승인 로그 Export API
export const postLocationApprovalLogExportAPI = ({ columnMetas, zipFileName, fileName, ...restParam }) =>
    reqPost('/v3/api/sm01/common/log/approve/export', { columnMetas, zipFileName, fileName }, restParam);
// 순찰 로그 목록 API
export const getPatrolLogAPI = param => reqGet('/v3/api/sm01/common/log/patrol', param);
// 순찰 일지 API
export const getPatrolDailyAPI = param => reqGet('/v3/api/sm01/common/log/patrol/daily', param);
// 순찰 로그 Export API
export const postPatrolLogExportAPI = ({ columnMetas, zipFileName, fileName, ...restParam }) =>
    reqPost('/v3/api/sm01/common/log/patrol/export', { columnMetas, zipFileName, fileName }, restParam);
// 순찰 리포트 API
export const getPatrolReportAPI = param => reqGet('v3/api/sm01/common/log/patrol/report', param);
