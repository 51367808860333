import React, { useContext, useEffect, useMemo, useState } from 'react';

import cx from 'classnames';

import { Col, Row } from 'reactstrap';
import CountUp from 'react-countup';

import { fetchAssetCountStatus } from '@api/asset';
import useTranslation from '@hooks/useTranslation';
import Card from '@components/Card';

import useMonitor from '../../../util/useMonitor';
import { useSettings } from '../../../util/useSettings';

import { useSelector } from 'react-redux';
import { IotItemStatusStateContext, IotItemStatusDispatchContext } from '../index';
import { setIotItemCategoryOptions, setIotItemCountParam, setSelectedIotItemStatus } from '../iotItemStatusReducer';

import { GoArrowRight, GoArrowDown } from 'react-icons/go';

const defaultData = {
    totalCount: 1,
    restrictedCount: 2,
    lostSignalCount: 3,
    lowBatteryCount: 4,
    neverDetectedCount: 5,
};

const CountWrapper = ({ children }) => {
    return <Col>{children}</Col>;
};

const ItemCount = ({ start = 0, end = 0, label, onClick, selectedIotItemStatus }) => {
    const handleLabelClick = e => {
        const { id } = e.target;
        let status;

        switch (id) {
            case '위치 이탈':
                status = 'isUnPermitted';
                break;
            case '신호 상실':
                status = 'isLostSignal';
                break;
            case 'Low Battery':
                status = 'isLowBattery';
                break;
            case '응답 없음':
                status = 'isNeverDetected';
                break;
        }

        onClick(status);
    };

    return (
        <div className={'flex-center'} style={{ flexDirection: 'column', height: '100%' }}>
            <CountUp
                className={'font-weight-bold'}
                style={{ fontSize: '2.5rem' }}
                start={start}
                end={end}
                separator=""
                decimals={0}
                decimal=","
                prefix=""
                useEasing={false}
                suffix=""
                duration="1"
            />
            <h6
                className={cx(
                    'mt-2 color-darkgray status-count-container',
                    selectedIotItemStatus === label && 'bg-secondary color-white',
                    !selectedIotItemStatus &&
                        (label === '등록 자산' || label === '등록 사람') &&
                        'bg-secondary color-white',
                )}
                onClick={handleLabelClick}
                id={label}
            >
                {label}
                {(!selectedIotItemStatus && (label === '등록 자산' || label === '등록 사람')) ||
                selectedIotItemStatus === label ? (
                    <GoArrowDown className={'ms-1'} />
                ) : (
                    <GoArrowRight className={'ms-1'} />
                )}
            </h6>
        </div>
    );
};

const IotItemStatusCount = ({ widgetInfo }) => {
    const t = useTranslation('IotItemStatus');
    const { iotItemCountParam, selectedIotItemStatus, searchInput } = useContext(IotItemStatusStateContext);
    const dispatch = useContext(IotItemStatusDispatchContext);
    const { categoryList } = useSelector(state => state.CategoryInfo);
    const { mode } = useSelector(state => state.DashboardFrame);

    const { config } = widgetInfo;
    const settings = useSettings(config);
    const { categoryCode: parentCategory } = settings;

    const [countData, setCountData] = useState({ prev: defaultData, curr: defaultData });

    const { monitor: iotItemCountStatusMonitor, stop: iotItemCountStatusMonitorStop } = useMonitor({
        config,
        defaultData,
        paramInfo: ['categoryCode'],
        dynamicParam: iotItemCountParam,
        fetchData: fetchAssetCountStatus,
        manualMonitor: true,
        makeData: ({ newData }) => {
            setCountData(prev => ({
                prev: prev.curr,
                curr: newData,
            }));
        },
    });
    useMemo(() => {
        iotItemCountStatusMonitorStop();

        let param = {};
        if (!searchInput.categoryCode) {
            param.categoryCode = parentCategory;
        } else {
            param.categoryCode = searchInput.categoryCode;
        }

        // 자산 상태별로 조회할 때
        if (selectedIotItemStatus) {
            param.status = selectedIotItemStatus;
        } else {
            param.status = '';
        }

        // 검색조건에 검색된 자산이 존재할 때
        if (searchInput.target) {
            param.target = searchInput.target;
        } else {
            param.target = '';
        }

        if (searchInput.floorIds) {
            param.floorIds = searchInput.floorIds;
        } else {
            param.floorIds = '';
        }

        dispatch(setIotItemCountParam(param));
    }, [parentCategory, selectedIotItemStatus, searchInput]);

    useEffect(() => {
        if (categoryList.length) {
            const assetList = categoryList.filter(category => {
                if (category.categoryCode === parentCategory) {
                    return category;
                }
                return category.parentCode === parentCategory;
            });
            dispatch(
                setIotItemCategoryOptions([
                    { categoryCode: '', categoryName: t('All Iot Item'), categoryCodePath: '' },
                    ...assetList,
                ]),
            );
        }
    }, [categoryList, parentCategory]);

    useEffect(() => {
        iotItemCountStatusMonitorStop();
        if (iotItemCountParam.categoryCode && mode === 'monitoring') {
            iotItemCountStatusMonitor();
        }
        return () => {
            iotItemCountStatusMonitorStop();
        };
    }, [iotItemCountParam]);

    const handleLabelClick = selectedStatus => {
        dispatch(setSelectedIotItemStatus(selectedStatus));
    };

    return (
        <Card>
            <div>
                <Row className={'flex-center'} style={{ height: '100%' }}>
                    <div style={{ width: '100%', display: 'flex', alignItems: 'start' }}>
                        <CountWrapper>
                            <ItemCount
                                start={countData.prev.totalCount}
                                end={countData.curr.totalCount}
                                label={parentCategory === 'ASSET' ? t('Registered Asset') : t('Registered People')}
                                onClick={handleLabelClick}
                                selectedIotItemStatus={selectedIotItemStatus && t(selectedIotItemStatus)}
                            />
                        </CountWrapper>
                        <CountWrapper>
                            <ItemCount
                                start={countData.prev.restrictedCount}
                                end={countData.curr.restrictedCount}
                                label={t('Out of Authorized Zone')}
                                onClick={handleLabelClick}
                                selectedIotItemStatus={selectedIotItemStatus && t(selectedIotItemStatus)}
                            />
                        </CountWrapper>
                        <CountWrapper>
                            <ItemCount
                                start={countData.prev.lostSignalCount}
                                end={countData.curr.lostSignalCount}
                                label={t('Lost Signal')}
                                onClick={handleLabelClick}
                                selectedIotItemStatus={selectedIotItemStatus && t(selectedIotItemStatus)}
                            />
                        </CountWrapper>
                        <CountWrapper>
                            <ItemCount
                                start={countData.prev.lowBatteryCount}
                                end={countData.curr.lowBatteryCount}
                                label={t('Low Battery')}
                                onClick={handleLabelClick}
                                selectedIotItemStatus={selectedIotItemStatus && t(selectedIotItemStatus)}
                            />
                        </CountWrapper>
                        <CountWrapper>
                            <ItemCount
                                start={countData.prev.neverDetectedCount}
                                end={countData.curr.neverDetectedCount}
                                label={t('No Response')}
                                onClick={handleLabelClick}
                                selectedIotItemStatus={selectedIotItemStatus && t(selectedIotItemStatus)}
                            />
                        </CountWrapper>
                    </div>
                </Row>
            </div>
        </Card>
    );
};

export default IotItemStatusCount;
