import { useState, useEffect } from 'react';

import { makeLayoutsData, widgetDiscriminator } from 'Components/MainPages/Dashboards/util';

const useWidgetGenerator = widgetInfo => {
    const [layouts, setLayouts] = useState({});
    const [Widgets, setWidgets] = useState(null);

    useEffect(() => {
        if (Array.isArray(widgetInfo)) {
            const layout = widgetInfo.map(info => {
                return info.layout;
            });
            setLayouts(makeLayoutsData(layout));
            setWidgets(widgetInfo.map(info => widgetDiscriminator(info)));
        } else {
            setLayouts({});
            setWidgets(null);
        }
    }, [widgetInfo]);

    return { layouts, Widgets };
};

export default useWidgetGenerator;
