import React, { useEffect, useState } from 'react';
import cx from 'classnames';

const RadioButton = ({ name = '', radioList = [], onChange, value, radioGroupClassName }) => {
    const [radioChecked, setRadioChecked] = useState();

    useEffect(() => {
        setRadioChecked(value);
    }, [value]);

    const handleRadioChange = e => {
        const { value } = e.target;
        setRadioChecked(value);
        onChange(value);
    };

    return (
        <ul className={cx('pnt-radio', radioGroupClassName)}>
            {radioList.map(({ label, value }, index) => {
                return (
                    <li key={value + index} className={cx(radioChecked === value && 'checked')}>
                        <input
                            type={'radio'}
                            id={value + index}
                            value={value}
                            checked={radioChecked === value}
                            name={name}
                            onChange={handleRadioChange}
                        />
                        <label htmlFor={value + index}>{label}</label>
                    </li>
                );
            })}
        </ul>
    );
};

export default RadioButton;
