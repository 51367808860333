import React, { useState, useEffect } from 'react';
import Page from '../../../Common/Page';
import useTranslation from '../../../../util/hooks/useTranslation';
import Card from '../../../Common/Card';
import { Row, Col } from 'reactstrap';
import styled from 'styled-components';
import Tree from '../../../Common/Select/TreeSelect/Tree';
import useAsync from '../../../../util/hooks/useAsync';
import { generateFakeData } from '../../../../util/common/util';
import { useSelector } from 'react-redux';

const Header = styled.div`
    width: 100%;
    height: 45px;
    border-bottom: 1px solid #000;
    padding: 0.5rem 1rem;
    text-align: center;
    font-size: 1rem;
    font-weight: bold;
`;
const Content = styled.div`
    width: 100%;
    height: calc(100% - 45px);
    border-bottom: 1px solid #000;
    padding: 1rem;
    overflow: auto;
`;
const RoleItem = styled.div`
    width: 100%;
    background-color: ${props => (props.selected ? '#6a5acd' : 'transparent')};
    color: ${props => (props.selected ? '#fff !important' : '#000 !important')};
    padding: 0.5rem 1rem;
    border-radius: 20px;
    cursor: pointer;
    text-align: center;
    font-size: 1rem;
    font-weight: bold;
`;

const getRoleApi = generateFakeData({
    page: 1,
    totalCount: 4,
    totalPage: 1,
    rows: [
        {
            roleNum: 297,
            roleName: 'LBS 관리자',
            comNum: 100003,
            comName: 'P&T',
        },
        {
            roleNum: 23,
            roleName: 'RTLS 관리자',
            comNum: 100003,
            comName: 'P&T',
        },
        {
            roleNum: 2,
            roleName: '최고관리자',
            comNum: 100003,
            comName: 'P&T',
        },
    ],
});

const getMenuRightsApi = generateFakeData({
    result: 'success',
    count: null,
    code: '0',
    rows: [
        {
            menuCode: 'MN_dashboard',
            parentCode: null,
            menuName: '대시보드',
            readPermit: 'N',
            writePermit: 'N',
            serviceCode: 'WS01',
        },
        {
            menuCode: 'MN_widget',
            parentCode: 'MN_dashboard',
            menuName: '위젯',
            readPermit: 'N',
            writePermit: 'N',
            serviceCode: 'WS01',
        },
        {
            menuCode: 'MN_report',
            parentCode: null,
            menuName: '리포트',
            readPermit: 'N',
            writePermit: 'N',
            serviceCode: null,
        },
        {
            menuCode: 'MN_sensorState',
            parentCode: 'MN_dashboard',
            menuName: '센서상태목록',
            readPermit: 'N',
            writePermit: 'N',
            serviceCode: 'WS01',
        },
    ],
});

const MenuAccessRights = () => {
    const t = useTranslation('Menu');
    const { userInfo } = useSelector(state => state.UserInfo);
    const [selectedRole, setSelectedRole] = useState(null);
    const {
        promise: getRole,
        state: { response: roleList },
    } = useAsync({
        promise: getRoleApi,
        fixedParam: { isAll: 'Y' },
        immediate: true,
    });
    const {
        promise: getMenuRights,
        state: { response: menuRights },
    } = useAsync({
        promise: getMenuRightsApi,
    });

    useEffect(() => {
        const { roleNum } = userInfo;
        if (roleNum) {
            setSelectedRole(roleNum);
        } else if (roleList && roleList.rows && roleList.rows.length && !selectedRole) {
            // 역할 리스트가 존재하고 선택된 역할이 없을 때
            setSelectedRole(roleList.rows[0].roleNum);
        }
    }, [roleList, userInfo]);

    useEffect(() => {
        if (selectedRole) {
            getMenuRights({ roleNum: selectedRole });
        }
    }, [selectedRole]);

    return (
        <Page menuPath={[t('Settings'), t('Menu Access Rights')]} style={{ height: 'calc(100vh - 4rem - 60px)' }}>
            <Card
                className={'h-100'}
                header={{
                    title: t('Menu Access Rights'),
                }}
            >
                <Row style={{ height: '100%' }}>
                    <Col xl={2}>
                        <div style={{ width: '100%', height: '100%' }}>
                            <Header>역할</Header>
                            <Content style={{ rowGap: '0.5rem', display: 'flex', flexDirection: 'column' }}>
                                {roleList &&
                                    roleList.rows &&
                                    roleList.rows.map(roleInfo => (
                                        <RoleItem
                                            key={roleInfo.roleNum}
                                            selected={roleInfo.roleNum === selectedRole}
                                            onClick={() => {
                                                setSelectedRole(roleInfo.roleNum);
                                            }}
                                        >
                                            {roleInfo.roleName}
                                        </RoleItem>
                                    ))}
                            </Content>
                        </div>
                    </Col>
                    <Col xl={10}>
                        <div style={{ width: '100%', height: '100%' }}>
                            <Header>메뉴</Header>
                            <Content>
                                <Tree data={[]} />
                            </Content>
                        </div>
                    </Col>
                </Row>
            </Card>
        </Page>
    );
};

export default MenuAccessRights;
