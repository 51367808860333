import React, { useState, useContext, useMemo, useEffect } from 'react';
import moment from 'moment';

import CompoundCard from '../../../Components/CompoundCard';
import CountItem from './CountItem';
import EntireStatus from './EntireStatus';

import { useTranslation, useColumns } from '@hooks';
import * as column from './column';

import { PatrolStatusContainer } from '../style';
import { PatrolDutyStateContext } from '../index';

const PatrolStatus = () => {
    const t = useTranslation('Patrol On Duty');

    const { selectedFloorStr, patrolInfo } = useContext(PatrolDutyStateContext);

    const { totalCompleteCnt, totalIncompleteCnt, floorCompleteCnt, floorIncompleteCnt } = patrolInfo;

    const [completeSelected, setCompleteSelected] = useState(true);

    const [tableData, setTableData] = useState({
        rows: [],
    });

    const patrolColumns = useColumns(
        [
            column.station({ width: 80 }),
            column.patrolName({ width: 80 }),
            column.time({
                width: 80,
                formatTitle: ({ value, original }) => {
                    const { patrolState } = original;
                    return patrolState === 'Y' ? moment.unix(value).format('HH:mm') : '-';
                },
                Cell: ({ value, row: { original } }) => {
                    const { patrolState } = original;
                    return patrolState === 'Y' ? moment.unix(value).format('HH:mm') : '-';
                },
            }),
        ],
        t,
    );

    const handleItemClick = complete => {
        setCompleteSelected(complete);
    };

    useEffect(() => {
        const { patrolBeaconList } = patrolInfo;

        if (patrolBeaconList) {
            setTableData({
                rows: patrolBeaconList.filter(beacon => {
                    const completeStatus = completeSelected ? 'Y' : 'N';
                    return beacon.patrolState === completeStatus;
                }),
            });
        }
    }, [patrolInfo, completeSelected]);

    const isEntire = selectedFloorStr === 'entire';

    return (
        <PatrolStatusContainer $isEntire={isEntire}>
            <CompoundCard>
                <CompoundCard.header>{t('Status of Patrol on Duty')}</CompoundCard.header>
                <CompoundCard.body className="compound-card-body-height gap-0">
                    <CompoundCard.body.plain>
                        {!isEntire ? (
                            <>
                                <div className="patrol-body">
                                    <CountItem title={t('Total patrol complete')} complete count={totalCompleteCnt} />
                                    <CountItem title={t('Total patrol incomplete')} count={totalIncompleteCnt} />
                                </div>

                                <div className="patrol-body">
                                    <CountItem
                                        title={t('Current floor patrol complete')}
                                        complete
                                        count={floorCompleteCnt}
                                        selected={completeSelected}
                                        handleClick={handleItemClick}
                                    />
                                    <CountItem
                                        title={t('Current floor patrol incomplete')}
                                        count={floorIncompleteCnt}
                                        selected={!completeSelected}
                                        handleClick={handleItemClick}
                                    />
                                </div>
                            </>
                        ) : (
                            <EntireStatus />
                        )}
                    </CompoundCard.body.plain>
                </CompoundCard.body>
            </CompoundCard>
            {!isEntire && (
                <CompoundCard>
                    <CompoundCard.header>
                        {t(completeSelected ? 'Details of Complete Patrol' : 'Details of Incomplete Patrol')}
                    </CompoundCard.header>
                    <CompoundCard.body className="compound-card-body-height">
                        <CompoundCard.body.table paging={false} columns={patrolColumns} data={tableData} />
                    </CompoundCard.body>
                </CompoundCard>
            )}
        </PatrolStatusContainer>
    );
};

export default PatrolStatus;
