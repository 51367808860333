import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import { useColumns, useAsync, useTranslation } from '@hooks';

import { Page, Table, Card } from '@components';

import { getSelectedFilterApi } from '@api/filter';
import { getAssetListApi } from '@api/asset';
import { getStatusListInfoApi } from '@api/status';

import FilterSearchGroup from '@components/FilterSearchGroup';
import FilterWidget from '../../Filter/FilterWidget';
import { makeFilterParam } from '../../Filter/reducer';

import Search from './Search';

import { StyledStatusLabel } from '../../Components/Grid/column';
import * as col from '../../Components/Grid/column';

import { falsyToHyphen } from '@util/common/util';

const tableHeight = { height: 'calc(100vh - 60px - 4rem - 88px - 3rem - 60px - 4rem - 4px)', minHeight: '25rem' };
export const PAGE_SIZE = 20;

const ItemList = () => {
    const t = useTranslation('Filter');
    const { filterNum } = useParams();
    const history = useHistory();

    const [filterInfo, setFilterInfo] = useState({});
    const [itemList, setItemList] = useState({ rows: [], pageSize: PAGE_SIZE });
    const [listParam, setListParam] = useState({});
    const [colorList, setColorList] = useState({ rows: [] });

    const itemColumns = useColumns(
        [
            col.category(),
            col.name(),
            col.num(),
            col.operator(),
            col.location(),
            col.status({
                Cell: ({ value }) => {
                    const selectedStatus = colorList.rows.find(status => status.statusId === value);
                    return selectedStatus ? <StyledStatusLabel {...selectedStatus} /> : <span />;
                },
                formatTitle: ({ value }) => {
                    const selectedStatus = colorList.rows.find(status => status.statusId === value);
                    return falsyToHyphen(selectedStatus?.statusName);
                },
            }),
        ],
        t,
        [colorList],
    );

    const { promise: getStatusListInfo } = useAsync({
        promise: getStatusListInfoApi,
        fixedParam: { isAll: 'Y' },
        resolve: res => {
            setColorList(res);
        },
    });

    const {
        promise: getIotItemList,
        state: { isLoading: assetListLoading },
    } = useAsync({
        promise: getAssetListApi,
        fixedParam: {
            pageSize: PAGE_SIZE,
            categoryCodes: filterInfo.categoryCodes,
        },
        resolve: res => {
            if (res) {
                setItemList({ ...res, pageSize: PAGE_SIZE });
                getStatusListInfo();
            }
        },
    });

    const { promise: getFilterInfo } = useAsync({
        promise: getSelectedFilterApi,
        resolve: res => {
            setFilterInfo(res);
        },
    });

    const handlePageChange = pageIndex => {
        if (listParam !== null) {
            getIotItemList({
                ...listParam,
                page: pageIndex,
            });
        }
    };

    const handleFilterConditionChange = selected => {
        const changedFilterInfoCondition = filterInfo.filterInfoCondition.map(conditionInfo => {
            if (conditionInfo.conditionId === selected.conditionId) {
                return { ...conditionInfo, conditionValues: selected.conditionValues };
            }
            return conditionInfo;
        });
        setFilterInfo({ ...filterInfo, filterInfoCondition: changedFilterInfoCondition });
    };

    useEffect(() => {
        if (filterNum) {
            getFilterInfo({ filterNum: Number(filterNum) });
        }
    }, [filterNum]);

    useEffect(() => {
        if (filterInfo.filterNum) {
            setListParam({
                ...makeFilterParam(filterInfo.filterInfoCondition, filterInfo.categoryCodes),
                pageSize: PAGE_SIZE,
            });
        }
    }, [filterInfo.filterNum]);

    useEffect(() => {
        if (listParam.pageSize) {
            getIotItemList(listParam);
        }

        return () => {
            setItemList({ rows: [], pageSize: PAGE_SIZE });
        };
    }, [listParam]);

    return (
        <Page menuPath={[t('Filters', 'Menu'), t('Item List', 'Menu')]} className={'h-100'}>
            <FilterSearchGroup>
                <FilterWidget
                    filterInfo={filterInfo}
                    withContainer={false}
                    changeableFilter={false}
                    filterEditMode
                    handleFilterConditionChange={handleFilterConditionChange}
                />
                <Search
                    handleSearchClick={searchOption => {
                        let param = {
                            ...makeFilterParam(filterInfo.filterInfoCondition, filterInfo.categoryCodes),
                            pageSize: PAGE_SIZE,
                        };
                        if (searchOption.opt && searchOption.keyword) {
                            param = { ...param, ...searchOption };
                        }
                        setListParam(param);
                    }}
                />
            </FilterSearchGroup>
            <Card
                header={{
                    title: filterInfo.filterName,
                }}
            >
                <div style={tableHeight}>
                    <Table
                        data={itemList}
                        columns={itemColumns}
                        onTrClick={({ targetNum }) => {
                            history.push(`/assets/detail/${targetNum}`);
                        }}
                        loading={assetListLoading}
                        onPageChange={handlePageChange}
                    />
                </div>
            </Card>
        </Page>
    );
};

export default ItemList;
