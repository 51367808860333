import React, { createContext, useEffect, useReducer } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import Page from '@components/Page';
import { useTranslation, useAsync } from '@hooks';
import { getSelectedFilterApi } from 'api/filter';
import reducer, { initialState, setEditMode, setOriginFilter } from './reducer';
import FilterPreview from './FilterPreview';
import DetailView from './View';

export const FilterDetailStateContext = createContext();
export const FilterDetailDispatchContext = createContext();

const FilterDetail = () => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const { editComplete } = state;
    const t = useTranslation('Filter');
    const { filterNum } = useParams();
    const history = useHistory();

    const { promise: getFilterInfo } = useAsync({
        promise: getSelectedFilterApi,
        resolve: res => {
            dispatch(setOriginFilter(res));
        },
        reject: err => {
            console.error(err);
            if (err.data && err.data.code === '1102') {
                history.push('/filter/list');
            }
        },
    });

    useEffect(() => {
        if (filterNum) {
            getFilterInfo({ filterNum: Number(filterNum) });
        } else {
            dispatch(setOriginFilter(initialState.origin));
            dispatch(setEditMode(true));
        }
        return () => {
            dispatch(setOriginFilter(initialState.origin));
        };
    }, [filterNum]);

    return (
        <FilterDetailDispatchContext.Provider value={dispatch}>
            <FilterDetailStateContext.Provider value={state}>
                <Page menuPath={[t('Filters', 'Menu'), t('Filter Detail', 'Menu')]} className={'h-100'}>
                    <DetailView />
                    <FilterPreview />
                </Page>
            </FilterDetailStateContext.Provider>
        </FilterDetailDispatchContext.Provider>
    );
};

export default FilterDetail;
