import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { editWidgetSettings } from '@reducer/Dashboards/DashboardEdit';
import useTranslation from '@hooks/useTranslation';
import WidgetSettingModal from '../../Components/WidgetSettingModal';

const PatrolOnDutySetting = ({ widgetInfo, ...restProps }) => {
    const t = useTranslation('Patrol On Duty');
    const dispatch = useDispatch();

    const [updatedWidgetInfo, setUpdatedWidgetInfo] = useState({});

    const { config } = widgetInfo;
    const { settings } = config;

    const handleSubmitClick = data => {
        const customSettings = settings || {};
        dispatch(
            editWidgetSettings({
                id: widgetInfo.id,
                settings: { ...customSettings, ...data, ...updatedWidgetInfo },
            }),
        );
    };

    const handleCancelClick = () => {
        setUpdatedWidgetInfo({});
    };

    return (
        <WidgetSettingModal
            headerTitle={t('Patrol On Duty Setting')}
            widgetInfo={widgetInfo}
            socketWidget
            hiddenHeader
            okCallback={handleSubmitClick}
            cancelCallback={handleCancelClick}
            {...restProps}
        />
    );
};

export default PatrolOnDutySetting;
