import React, { Fragment } from 'react';

import { useTranslation } from '@hooks';

import { convertToOnTime, convertToOnTimeWithoutColon } from '../../utils';
import { CHECK_NOTE, EACH_TD_HEIGHT, TIME_LIST } from './utils';
import { falsyToEmptyString, falsyToHyphen } from '@util/common/util';

import styled from 'styled-components';

const PatrolDailyLogTable = ({ patrolDivName = '', bedrooms = [], bilgeSoundings = [], patrolDailyReports = [] }) => {
    const t = useTranslation('Patrol Log');

    const timeList = TIME_LIST[patrolDivName] || [];

    const bedroomTemperature = bedrooms.slice(0, 6);

    const tdHeight = EACH_TD_HEIGHT[patrolDivName];

    const initialTableRow = bilgeSoundings.concat(bedroomTemperature);

    return (
        <Container>
            {timeList.map((time, idx) => {
                return (
                    <StyledTable>
                        <thead>
                            <tr>
                                <StyledFirstHeader rowSpan="2" colSpan="2">
                                    {t('Time')}
                                </StyledFirstHeader>
                                <StyledHeader colSpan="5">{t('Bilge Sounding')}</StyledHeader>
                                <StyledHeader colSpan={bedroomTemperature.length}>
                                    {t('Bedroom Temperature')}
                                </StyledHeader>
                                <StyledHeader rowSpan="2">{t('Result And Contents')}</StyledHeader>
                                <StyledHeader colSpan="2">{t('Safety Watch')}</StyledHeader>
                                <StyledHeader colSpan="2">{t('Duty Officer')}</StyledHeader>
                            </tr>
                            <tr>
                                {bilgeSoundings.map(({ beaconNum, beaconName }) => {
                                    return <StyledHeader key={beaconNum}>{beaconName}</StyledHeader>;
                                })}
                                {bedroomTemperature.map(({ beaconNum }, idx) => {
                                    return <StyledHeader key={beaconNum}>{idx + 1}</StyledHeader>;
                                })}
                                <StyledHeader>{`${t('Rank')} / ${t('Name')}`}</StyledHeader>
                                <StyledHeader>{t('Signature')}</StyledHeader>
                                <StyledHeader>{`${t('Rank')}/${t('Name')}`}</StyledHeader>
                                <StyledHeader>{t('Signature')}</StyledHeader>
                            </tr>
                        </thead>
                        <tbody>
                            {time.map(({ start, end, time }) => {
                                let cnt = start;
                                return (
                                    <Fragment key={start}>
                                        {time.map((gap, index) => {
                                            cnt += gap;
                                            if (cnt > 24) cnt -= 24;

                                            const filteredReports = patrolDailyReports.filter(({ startTime }) => {
                                                if (cnt - gap <= startTime) {
                                                    return cnt - gap < cnt ? startTime < cnt : startTime > cnt;
                                                }
                                                return false;
                                            });

                                            const modifiedReports = filteredReports.reduce(
                                                (acc, { patrolLogs, patrolResults }, idx) => {
                                                    // 빌지 측심과 침실 온도
                                                    if (!idx) {
                                                        acc.log = initialTableRow.flatMap(
                                                            ({ beaconNum }) =>
                                                                patrolLogs.find(log => log.beaconNum === beaconNum) ||
                                                                null,
                                                        );
                                                    } else {
                                                        initialTableRow.forEach(({ beaconNum }, idx) => {
                                                            const foundLog = patrolLogs.find(
                                                                log => log.beaconNum === beaconNum,
                                                            );
                                                            if (foundLog) {
                                                                acc.log[idx] = foundLog;
                                                            }
                                                        });
                                                    }

                                                    // 안전 당직
                                                    patrolLogs.forEach(({ targetId, targetName, rank }) => {
                                                        const duplication = acc.patrol.find(
                                                            dup => dup.targetId === targetId,
                                                        );
                                                        if (!duplication) {
                                                            acc.patrol.push({ targetId, targetName, rank });
                                                        }
                                                    });

                                                    return acc;
                                                },
                                                { log: [], patrol: [] },
                                            );

                                            // 순찰 결과 및 조치사항
                                            const patrolNote = filteredReports
                                                .flatMap(({ patrolResults }) => patrolResults)
                                                .slice(0, 3)
                                                .join('\n');

                                            return (
                                                <tr>
                                                    {!index && (
                                                        <StyledFirstTd
                                                            $width="3%"
                                                            $padding="0"
                                                            rowSpan={time.length}
                                                        >{`${convertToOnTime(start)}\n ~ \n${convertToOnTime(
                                                            end,
                                                        )}`}</StyledFirstTd>
                                                    )}
                                                    <StyledTd
                                                        key={`${gap}_${index}`}
                                                        $width="5%"
                                                        $height={tdHeight}
                                                        $padding="0"
                                                    >{`${convertToOnTimeWithoutColon(
                                                        cnt - gap,
                                                    )}~${convertToOnTimeWithoutColon(cnt)}`}</StyledTd>
                                                    {bilgeSoundings.map(({ beaconNum }, idx) => {
                                                        return (
                                                            <StyledTd
                                                                key={beaconNum + '_td_' + index}
                                                                $width="6%"
                                                                $height={tdHeight}
                                                            >
                                                                {falsyToEmptyString(
                                                                    modifiedReports.log[idx]?.checkResult,
                                                                )}
                                                            </StyledTd>
                                                        );
                                                    })}
                                                    {bedroomTemperature.map(({ beaconNum }, idx) => {
                                                        return (
                                                            <StyledTd
                                                                key={beaconNum + '_td_' + index}
                                                                $height={tdHeight}
                                                            >
                                                                {falsyToEmptyString(
                                                                    modifiedReports.log[bilgeSoundings.length + idx]
                                                                        ?.checkResult,
                                                                )}
                                                            </StyledTd>
                                                        );
                                                    })}
                                                    <StyledTd
                                                        $width="24%"
                                                        $height={tdHeight}
                                                        $textAlign="start"
                                                        $padding="1px"
                                                    >
                                                        {patrolNote}
                                                    </StyledTd>
                                                    <StyledTd $width="6%" $height={tdHeight} $padding="1px">
                                                        {modifiedReports.patrol
                                                            .slice(0, 3)
                                                            .map(
                                                                ({ targetName, rank }) =>
                                                                    `${falsyToHyphen(rank)}/${falsyToHyphen(
                                                                        targetName,
                                                                    )}`,
                                                            )
                                                            .join('\n')}
                                                    </StyledTd>
                                                    <StyledTd $width="5%" $height={tdHeight} />
                                                    <StyledTd $width="6%" $height={tdHeight} $padding="2px" />
                                                    <StyledTd $width="5%" $height={tdHeight} />
                                                </tr>
                                            );
                                        })}
                                    </Fragment>
                                );
                            })}
                        </tbody>
                    </StyledTable>
                );
            })}
        </Container>
    );
};

const Container = styled.div`
    display: grid;
    gap: 0.3rem;
`;

const StyledTable = styled.table`
    border-top: 1px solid black;
    &:first-child {
        border-top: none;
    }
`;

const StyledTd = styled.td`
    font-size: 0.6rem;
    border-bottom: 1px solid black;
    border-right: 1px solid black;
    min-width: 2rem;
    width: ${({ $width }) => $width || '2rem'};
    height: ${({ $height }) => $height || '3.1rem'};
    text-align: ${({ $textAlign }) => $textAlign || 'center'};
    padding: ${({ $padding }) => $padding || '4px'};
    background-color: white;
    word-break: break-all;
    white-space: pre-wrap;
    color: black;
    overflow: hidden;
`;

const StyledHeader = styled(StyledTd)`
    height: 1rem;
    background-color: lightgrey;
    color: black;
    font-weight: bold;
    padding: 2px;
`;

const StyledFirstTd = styled(StyledTd)`
    border-left: 1px solid black;
`;

const StyledFirstHeader = styled(StyledHeader)`
    border-left: 1px solid black;
`;

export default PatrolDailyLogTable;
