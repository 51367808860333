import React, { useEffect, useContext } from 'react';

import { Table } from '@components';
import { useColumns, useTranslation, useAsync } from '@hooks';

import { useSelector } from 'react-redux';

import { EntireContainer } from '../style';
import * as column from './column';

import { getPatrolEntireStatusAPI } from '@api/military';

import { PatrolDutyStateContext, PatrolDutyDispatchContext } from '../index';
import { setEntireRows, setSelectedFloorStr } from '../patrolOnDutyReducer';

const EntireStatus = () => {
    const t = useTranslation('State Conversion');

    const dispatch = useContext(PatrolDutyDispatchContext);
    const { patrolZoneState, patrolState } = useSelector(state => state.Navy);

    const {
        selectedDivOption: { value: patrolDiv },
        entireRows,
    } = useContext(PatrolDutyStateContext);

    const { promise: getPatrolEntireStatus } = useAsync({
        promise: getPatrolEntireStatusAPI,
        resolve: res => {
            const { rows } = res;
            dispatch(setEntireRows(rows));
        },
        reject: err => {
            console.error(err);
        },
    });

    useEffect(() => {
        if (patrolDiv) {
            getPatrolEntireStatus({ patrolDiv });
        }
    }, [patrolDiv, patrolZoneState, patrolState]);

    const columns = useColumns(
        [column.floor({ width: 100 }), column.completion({ width: 60 }), column.incompletion({ width: 60 })],
        t,
    );

    const handleTrClick = trData => {
        const { floor } = trData;
        dispatch(setSelectedFloorStr(floor));
    };

    return (
        <EntireContainer>
            <Table data={{ rows: entireRows }} columns={columns} paging={false} onTrClick={handleTrClick} />
        </EntireContainer>
    );
};

export default EntireStatus;
