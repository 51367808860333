import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import moment from 'moment';

import {
    getAssetRentalRequestApi,
    getBorrowedAssetListApi,
    updateAssetRentalRequestStatusApi,
    updateAssetRentalStatusApi,
} from 'api/asset';

import { Table, Label, Button, Card } from 'Components/Common';

import { useConfirmModal, useAsync, useColumns, useTranslation } from '@hooks';

import { useSelector } from 'react-redux';

import * as column from '../../util/grid/column';

const AssetRentalRequestView = () => {
    const t = useTranslation('AssetRentalRequest');
    const columnT = useTranslation('CommonColumn');

    const { userInfo } = useSelector(state => state.UserInfo);
    const { categoryList } = useSelector(state => state.CategoryInfo);

    const { reqNum } = useParams();
    const history = useHistory();

    const [borrowedAssetList, setBorrowedAssetList] = useState({
        totalCount: 0,
        totalPage: 1,
        rows: [],
    });
    const [selectedAssetRentalRequest, setSelectedAssetRentalRequest] = useState(null);

    const columns = useColumns(
        [
            column.category({
                Cell: ({ value }) => {
                    const category = categoryList.find(({ categoryCode }) => categoryCode === value);
                    if (category) {
                        return <div>{category.categoryNamePath}</div>;
                    } else {
                        return <div>-</div>;
                    }
                },
            }),
            column.assetName(),
            column.assetNumber(),
            column.assetRentalRequestDepart({
                accessor: 'usingDepName',
            }),
            column.assetRentalDepartment({
                accessor: 'ownDepName',
            }),
            column.rentalStatus({
                Cell: ({ value }) => {
                    if (value) {
                        return <div>{t(value)}</div>;
                    } else {
                        return <div>{t('allocation')}</div>;
                    }
                },
            }),
            column.action({
                width: 280,
                Cell: ({ row }) => {
                    const { status, targetNum, reqNum } = row.original;
                    if (status === 'check_out') {
                        return (
                            <div className={'table-btn-container'}>
                                <button
                                    onClick={() =>
                                        updateAssetRentalStatus({
                                            reqNum: reqNum,
                                            targetNum: targetNum,
                                            status: 'confirm_check_out',
                                        })
                                    }
                                    className="pnt-txt txt-border txt-btn-org"
                                    type={'button'}
                                >
                                    {t('confirm_check_out')}
                                </button>
                            </div>
                        );
                    } else if (status === 'confirm_check_out') {
                        return (
                            <div className={'table-btn-container'}>
                                <button
                                    onClick={() =>
                                        updateAssetRentalStatus({
                                            reqNum: reqNum,
                                            targetNum: targetNum,
                                            status: 'check_in',
                                        })
                                    }
                                    className="pnt-txt txt-border txt-btn-org"
                                    type={'button'}
                                >
                                    {t('check_in')}
                                </button>
                            </div>
                        );
                    } else {
                        return <div />;
                    }
                },
            }),
        ],
        null,
        [categoryList],
    );

    const { promise: getAssetRentalRequest } = useAsync({
        promise: getAssetRentalRequestApi,
        fixedParam: { reqNum },
        immediate: true,
        deps: [reqNum, categoryList],
        resolve: res => {
            const category = categoryList.find(category => category.categoryCode === res.categoryCode);
            setSelectedAssetRentalRequest({ ...res, categoryNamePath: category ? category.categoryNamePath : '-' });
        },
    });

    const { promise: getBorrowedAssetList } = useAsync({
        promise: getBorrowedAssetListApi,
        fixedParam: {
            pageSize: 20,
            usingDepNums: userInfo.groupNum,
            reqNum: selectedAssetRentalRequest ? selectedAssetRentalRequest.reqNum : null,
        },
        resolve: res => {
            setBorrowedAssetList(res);
        },
    });

    useEffect(() => {
        if (selectedAssetRentalRequest && selectedAssetRentalRequest.reqNum) {
            getBorrowedAssetList();
        }
    }, [selectedAssetRentalRequest, userInfo.groupNum]);

    const { promise: updateAssetRentalRequestStatus } = useAsync({
        promise: updateAssetRentalRequestStatusApi,
        resolve: () => {
            history.push('/asset-rental/request');
        },
    });

    // 자산 상태 변경
    const { promise: updateAssetRentalStatus } = useAsync({
        promise: updateAssetRentalStatusApi,
        resolve: () => {
            getBorrowedAssetList();
            if (selectedAssetRentalRequest.status === 'APPROVE') {
                getAssetRentalRequest();
            }
        },
    });

    const handleGoBackClick = () => {
        setSelectedAssetRentalRequest(null);
        history.push('/asset-rental/request');
    };

    const handleActionClick = () => {
        toggleRequestCancelModal();
    };

    const handlePageChange = pageIndex => {
        getBorrowedAssetList({ page: pageIndex });
    };

    const { toggleModal: toggleRequestCancelModal, Modal: RequestCancelModal } = useConfirmModal({
        initModal: false,
        header: {
            title: t('Confirm', 'ConfirmModal'),
        },
        confirmText: t('Do you want to cancel the asset rental request?'),
        okCallback: updateAssetRentalRequestStatus,
        callbackParam: selectedAssetRentalRequest && { reqNum: selectedAssetRentalRequest.reqNum, status: 'CANCEL' },
    });

    return (
        <div className={'application-grid-container'}>
            <Card
                header={{
                    title: t('View of Asset Rental Request'),
                    action: (
                        <>
                            <Button className={'btn-gray btn-back me-1'} onClick={handleGoBackClick}>
                                {t('Back')}
                            </Button>
                            {selectedAssetRentalRequest &&
                                (selectedAssetRentalRequest.status === 'APPLY' ||
                                    selectedAssetRentalRequest.status === 'APPROVE') && (
                                    <>
                                        <div className="pnt-border border-h" />
                                        <Button className={'btn-brand'} onClick={handleActionClick}>
                                            {t('Cancel')}
                                        </Button>
                                    </>
                                )}
                        </>
                    ),
                }}
            >
                <div className={'application-contents-container'}>
                    {selectedAssetRentalRequest && (
                        <>
                            <Label
                                name={columnT('Applicant')}
                                value={userInfo.userName}
                                labelGroupClassName={'details-input-group'}
                                labelValueClassName={'label-dot'}
                            />

                            <Label
                                name={columnT('Requesting department')}
                                value={selectedAssetRentalRequest.usingDepNm}
                                labelGroupClassName={'details-input-group'}
                                labelValueClassName={'label-dot'}
                            />

                            <Label
                                name={columnT('Application date')}
                                value={moment.unix(selectedAssetRentalRequest.regDate).format('YYYY-MM-DD')}
                                labelGroupClassName={'details-input-group'}
                                labelValueClassName={'label-dot'}
                            />

                            <Label
                                name={columnT('Application status')}
                                value={t(selectedAssetRentalRequest.status)}
                                labelGroupClassName={'details-input-group'}
                                labelValueClassName={'label-dot'}
                            />

                            <Label
                                name={columnT('Lending department')}
                                value={selectedAssetRentalRequest.ownDepNm}
                                labelGroupClassName={'details-input-group'}
                                labelValueClassName={'label-dot'}
                            />

                            <Label
                                name={columnT('Rental period')}
                                value={
                                    <div>
                                        {moment.unix(selectedAssetRentalRequest.startDate).format('YYYY-MM-DD')} ~
                                        {moment.unix(selectedAssetRentalRequest.endDate).format('YYYY-MM-DD')}
                                    </div>
                                }
                                labelGroupClassName={'details-input-group'}
                                labelValueClassName={'label-dot'}
                            />

                            <Label
                                name={columnT('Category')}
                                value={selectedAssetRentalRequest.categoryNamePath}
                                labelGroupClassName={'details-input-group'}
                                labelValueClassName={'label-dot'}
                            />

                            <Label
                                name={columnT('Quantity')}
                                value={selectedAssetRentalRequest.count}
                                labelGroupClassName={'details-input-group'}
                                labelValueClassName={'label-dot'}
                            />
                        </>
                    )}
                </div>
            </Card>
            <Card
                className={'borrowed-assets-container'}
                header={{
                    title: t('List of borrowed assets'),
                }}
            >
                <div className={'h-100'}>
                    <Table data={{ ...borrowedAssetList }} columns={columns} onPageChange={handlePageChange} />
                </div>
            </Card>

            <RequestCancelModal />
        </div>
    );
};

export default AssetRentalRequestView;
