import React, { useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Badge, Button, Col, ListGroup, ListGroupItem, Row } from 'reactstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import useTranslation from '@hooks/useTranslation';
import { editWidget } from '@reducer/Dashboards/DashboardEdit';
import { TextInput, ConfirmModal } from '@components';
import widgetList from '../../index';

const WidgetList = ({ widgetList, onClick }) => {
    const t = useTranslation('WidgetList');

    const tempWidgetListArr = [];
    const widgetListArr = [];
    let total = 0;
    for (let key in widgetList) {
        const widget = widgetList[key];
        const { category } = widget;

        if (!tempWidgetListArr[category]) {
            tempWidgetListArr[category] = 1;
        } else {
            tempWidgetListArr[category] += 1;
        }

        if (category !== undefined) {
            total += 1;
        }
    }
    widgetListArr.push(
        <ListGroupItem key="total" tag="button" onClick={onClick} value={'All'} className="layout border-0">
            <div>{t('Total')}</div>
            <Badge className="color-white" pill>
                {total}
            </Badge>
        </ListGroupItem>,
    );

    for (let key in tempWidgetListArr) {
        let i = 1;
        if (key !== 'undefined') {
            widgetListArr.push(
                <ListGroupItem tag="button" onClick={onClick} value={key} key={key} className="layout border-0">
                    <div>{t(key)}</div>
                    <Badge className="color-white" pill>
                        {tempWidgetListArr[key]}
                    </Badge>
                </ListGroupItem>,
            );
        }
        i++;
    }
    return widgetListArr;
};

const WidgetPrevList = ({ widgetList, layout, toggleModal }) => {
    const widgetT = useTranslation('Widget');
    const widgetListT = useTranslation('WidgetList');

    const dispatch = useDispatch();

    const WidgetPrevArr = [];

    const handleAddClick = (e, widgetInfo) => {
        dispatch(
            editWidget({
                name: widgetInfo.name,
                category: widgetInfo.category,
                id: layout.i,
                type: widgetInfo.type,
                config: widgetInfo.config,
                layout: { ...layout, ...widgetInfo.layout },
            }),
        );
        toggleModal();
    };

    for (let key in widgetList) {
        const widget = widgetList[key];
        if (widget.type !== 'temp') {
            WidgetPrevArr.push(
                <ListGroupItem key={widget.type} className="border-0">
                    <Row className={'flex-center'}>
                        <Col xs={9}>
                            <strong>{widgetT(widget.name)}</strong>
                        </Col>
                        <Col xs={3}>
                            <Button
                                onClick={e => {
                                    handleAddClick(e, widget);
                                }}
                            >
                                {widgetListT('ADD')}
                            </Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} style={{ marginTop: '1rem' }}>
                            <span>- {widgetT(widget.desc)}</span>
                        </Col>
                    </Row>
                </ListGroupItem>,
            );
        }
    }
    if (!WidgetPrevArr.length) {
        WidgetPrevArr.push(
            <ListGroupItem key={'noItem'} className="border-0">
                <Row>
                    <Col className={'flex-center'}>{widgetListT('There are no search widget')}</Col>
                </Row>
            </ListGroupItem>,
        );
    }
    return WidgetPrevArr;
};

const PalettePopup = ({
    initOpen = false,
    setModal,
    toggleModal = function () {
        initOpen = !initOpen;
    },
    layout,
    ...restProps
}) => {
    const t = useTranslation();
    const [filteredList, setFilteredList] = useState(widgetList);
    const ps = useRef();

    const scrollTop = () => {
        const curr = ps.current;
        if (curr) {
            curr.scrollTop = 0;
        }
    };

    const handleFilterChange = e => {
        const filteredList = {};
        const value = e.currentTarget.value;
        for (let key in widgetList) {
            const widget = widgetList[key];
            if (t(widget.name, 'Widget').toUpperCase().indexOf(value.toUpperCase()) >= 0) {
                filteredList[key] = widget;
            }
        }
        setFilteredList(filteredList);
    };

    const onListClick = e => {
        const value = e.currentTarget.value;
        const filteredList = {};

        if (value === 'All') {
            setFilteredList(widgetList);
        } else {
            for (let key in widgetList) {
                const widget = widgetList[key];
                if (widget.category && widget.category.indexOf(value) >= 0) {
                    filteredList[key] = widget;
                }
            }
            setFilteredList(filteredList);
        }
        scrollTop();
    };

    return (
        <ConfirmModal
            className={'widget-add-popup'}
            initModal={initOpen}
            toggleModal={toggleModal}
            style={{ maxWidth: '45rem' }}
            header={{ title: t('Add Widget', 'WidgetList') }}
            removeCancel
            okButtonTitle={t('Close', 'UserBox')}
            confirmText={
                <Row>
                    <Col xs={5}>
                        <Row>
                            <TextInput
                                type={'text'}
                                name={'dashboard-palette-popup'}
                                placeholder={t('Search', 'Search')}
                                handleChange={handleFilterChange}
                                inputGroupClassName={'mb-2'}
                            />
                        </Row>
                        <Row>
                            <ListGroup flush style={{ width: '100%' }}>
                                <WidgetList widgetList={widgetList} onClick={onListClick} />
                            </ListGroup>
                        </Row>
                    </Col>

                    <Col xs={7} style={{ width: '100%', height: '400px', paddingRight: 0 }}>
                        <PerfectScrollbar containerRef={e => (ps.current = e)}>
                            <ListGroup flush>
                                <WidgetPrevList layout={layout} toggleModal={toggleModal} widgetList={filteredList} />
                            </ListGroup>
                        </PerfectScrollbar>
                    </Col>
                </Row>
            }
            {...restProps}
        />
    );
};

export default PalettePopup;
