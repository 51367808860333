import React, { useState } from 'react';

import PerfectScrollbar from 'react-perfect-scrollbar';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';

import { postDashboardMonitoringLocationApprovalAPI } from 'api/military';

import Button from '@components/Button';
import { ConfirmModal } from '../index';
import { useAsync, useTranslation } from '@hooks';

import { useDispatch, useSelector } from 'react-redux';
import { setSelectedAlertInfo } from 'reducers/Notification';

import styled from 'styled-components';

import cx from 'classnames';

const SENSING_TYPE_LEAKING = 'LEAKING';
const SENSING_TYPES = ['DISTANCE', 'CALORIES', 'SLEEP', 'HEARTBEAT', 'STRESS', 'STEP'];
const SENSING_VALUES = {
    DISTANCE: 'Distance',
    CALORIES: 'Calories',
    SLEEP: 'Sleep',
    HEARTBEAT: 'Heart Rate',
    STRESS: 'Stress',
    STEP: 'Step',
};

const WrappedAlertInfo = styled.div`
    display: flex;
    & > div {
        cursor: ${props => (props.clickable ? 'pointer' : 'default')};
    }
`;

const DefaultItemComponent = ({ event, toggleModal }) => {
    const t = useTranslation('TimeLine');

    const storeDispatch = useDispatch();
    const { locationApproval } = useSelector(state => state.Navy);

    const { type, sensingType, typeClassName, targetName, location, latLng, interfaceCommandType } = event;

    const handleSeeLocationClick = () => {
        //Smart Military Custom
        if (!locationApproval) {
            toggleModal();
            return;
        }
        storeDispatch(setSelectedAlertInfo(event));
    };

    let eventName = t(type, 'Event Types');
    if (type.includes(':')) {
        const splitEvent = type.split(':').map(e => t(e.trim(), 'Event Types'));
        if (sensingType === SENSING_TYPE_LEAKING) {
            splitEvent[0] = t(sensingType, 'Event Types');
        } else if (SENSING_TYPES.includes(sensingType)) {
            splitEvent[0] = t(SENSING_VALUES[sensingType], 'Event Types');
        }
        eventName = splitEvent.join(' : ');
    }

    return (
        <>
            <div className={'alarm-list'}>
                <b className={typeClassName}>{eventName}</b>
                <h4 className="timeline-title me-2" style={{ display: 'inline-block' }}>
                    {targetName}
                </h4>

                {!interfaceCommandType.includes('GPS') && location && (
                    <p>
                        {t('Location')} : {location}
                    </p>
                )}
                {interfaceCommandType.includes('GPS') && latLng && latLng.length && (
                    <p>{`${t('Lat')} : ${latLng[0].toFixed(5)}, ${t('Lng')} : ${latLng[1].toFixed(5)}`}</p>
                )}
            </div>
            <div className={'alarm-tracing-button'}>
                <Button className="btn-secondary" onClick={handleSeeLocationClick}>
                    {t('Location')}
                </Button>
            </div>
        </>
    );
};

const TimeLine = ({ events = [], onClick, ItemComponent, withDate = true }) => {
    const t = useTranslation('TimeLine');

    // Smart Military
    const [approvalModal, setApprovalModal] = useState(false);
    const toggleApprovalConfirmModal = () => {
        setApprovalModal(!approvalModal);
    };

    // Smart Military
    const [requestFailureModal, setRequestFailureModal] = useState(false);
    const toggleRequestFailureModal = () => {
        setRequestFailureModal(!requestFailureModal);
    };

    // Smart Military
    const [requestSuccessModal, setRequestSuccessModal] = useState(false);
    const toggleRequestSuccessModal = () => {
        setRequestSuccessModal(!requestSuccessModal);
    };

    // Smart Military
    const { promise: postDashboardMonitoringLocationApproval } = useAsync({
        promise: postDashboardMonitoringLocationApprovalAPI,
        resolve: () => {
            toggleRequestSuccessModal();
        },
        reject: err => {
            console.error(err);
            toggleRequestFailureModal();
        },
    });

    return events.length ? (
        <>
            <PerfectScrollbar>
                <VerticalTimeline layout="1-column">
                    {events.map((event, i) => (
                        <VerticalTimelineElement
                            key={i}
                            style={{
                                cursor: 'default',
                            }}
                            className="vertical-timeline-item"
                            icon={
                                <i
                                    className={cx(
                                        'badge badge-dot badge-dot-xl',
                                        event.level ? `badge-${[event.level]}` : 'badge-primary',
                                    )}
                                >
                                    {' '}
                                </i>
                            }
                            date={
                                <>
                                    {withDate && (
                                        <>
                                            <span> {event.dateStr}</span>
                                            <br />
                                        </>
                                    )}
                                    <span> {event.timeStr}</span>
                                </>
                            }
                        >
                            <WrappedAlertInfo
                                clickable={typeof onClick === 'function'}
                                onClick={() => {
                                    if (typeof onClick === 'function') {
                                        onClick(event);
                                    }
                                }}
                            >
                                {ItemComponent ? (
                                    <ItemComponent event={event} toggleModal={toggleApprovalConfirmModal} />
                                ) : (
                                    <DefaultItemComponent event={event} toggleModal={toggleApprovalConfirmModal} />
                                )}
                            </WrappedAlertInfo>
                        </VerticalTimelineElement>
                    ))}
                </VerticalTimeline>
            </PerfectScrollbar>
            {/*Smart Military Custom*/}
            <ConfirmModal
                initModal={approvalModal}
                toggleModal={toggleApprovalConfirmModal}
                header={{ title: t('Confirm', 'ConfirmModal') }}
                confirmText={
                    <>
                        <div>{t('View location is unapproved.', 'Integrated Monitor')}</div>
                        <div>{t('Do you want to send an approval request?', 'Integrated Monitor')}</div>
                    </>
                }
                removeCancel={true}
                okButtonTitle={t('Approve Request', 'Integrated Monitor')}
                okCallback={() => {
                    postDashboardMonitoringLocationApproval({ type: 'Y' });
                    toggleApprovalConfirmModal();
                }}
            />
            {/*Smart Military Custom*/}
            <ConfirmModal
                initModal={requestFailureModal}
                toggleModal={toggleRequestFailureModal}
                header={{ title: t('Fail', 'ConfirmModal') }}
                confirmText={t('The request failed.', 'ErrorHandler')}
                removeCancel
            />
            {/*Smart Military Custom*/}
            <ConfirmModal
                initModal={requestSuccessModal}
                toggleModal={toggleRequestSuccessModal}
                header={{ title: t('Confirm', 'ConfirmModal') }}
                confirmText={t('Your request was successful.', 'Patrol Search')}
                removeCancel
            />
        </>
    ) : (
        <div
            style={{
                color: '#666',
                textAlign: 'center',
                paddingTop: '1.5rem',
            }}
        >
            {t('No new notifications')}
        </div>
    );
};

export default TimeLine;
