import React, { createContext, useEffect, useReducer, useState } from 'react';

import { Button } from '@components';
import { useTranslation, useAsync } from '@hooks';

import { getHealthMonitoringListAPI, getHealthMonitoringSelectAPI } from '@api/military';

import GridLayoutCard from '../../Components/GridLayoutCard';
import WidgetConfirmModal from '../../Components/WidgetConfirmModal';

import ConditionalSearch from './Components/ConditionalSearch';
import HealthInformation from './Components/HealthInformation';
import AbnormalHeartbeat from './Components/AbnormalHeartbeat';

import HealthInformationProvisionReducer, {
    initialState,
    initialize,
    setHealthInfoList,
    setFilterOptions,
} from './healthInformationProvisionReducer';

const SELECT_LIST = ['rank', 'department', 'jobtitle', 'status'];
const PAGE_SIZE = 12;

export const HealthInformationProvisionStatusContext = createContext(null);
export const HealthInformationProvisionDispatchContext = createContext();

export const HealthInformationProvision = ({ children, widgetInfo, ...restProps }) => {
    const t = useTranslation('Health Information Provision');

    const { config } = widgetInfo;

    const [state, dispatch] = useReducer(HealthInformationProvisionReducer, initialState);

    const [showSearchArea, setShowSearchArea] = useState(false);
    const toggleSearchArea = () => {
        setShowSearchArea(!showSearchArea);
    };

    const handleSearchClick = ({ filterOption, inputOption, inputValue }) => {
        const { bookmark, rank, department, jobtitle, status } = filterOption;

        setLoading(true);
        setShowSearchArea(false);
        abortHealthMonitoringList();
        getHealthMonitoringList({
            heartbeatEvent: 'N',
            bookmark: bookmark ? 'Y' : 'N',
            options: {
                rank: rank && rank['value'] !== 'all' ? rank['value'] : null,
                department: department && department['value'] !== 'all' ? department['value'] : null,
                jobtitle: jobtitle && jobtitle['value'] !== 'all' ? jobtitle['value'] : null,
                status: status && status['value'] !== 'all' ? status['value'] : null,
            },
            opt: inputOption && inputOption['value'] !== 'all' ? inputOption['value'] : null,
            keyword: inputValue,
        });
    };

    const [refreshModal, setRefreshModal] = useState(false);
    const handleRefreshModal = () => {
        setRefreshModal(!refreshModal);
        setShowSearchArea(false);
    };

    const handleRefreshClick = () => {
        dispatch(initialize());

        setLoading(true);
        abortHealthMonitoringList();
        getHealthMonitoringList();
    };

    const handlePageChange = pageIndex => {
        if (healthMonitoringListState.request) {
            setLoading(true);
            abortHealthMonitoringList();
            return getHealthMonitoringList({ ...healthMonitoringListState.request, page: pageIndex });
        }
    };

    const [loading, setLoading] = useState(false);

    const {
        promise: getHealthMonitoringList,
        state: healthMonitoringListState,
        abort: abortHealthMonitoringList,
    } = useAsync({
        promise: getHealthMonitoringListAPI,
        immediate: true,
        fixedParam: { pageSize: PAGE_SIZE },
        resolve: res => {
            const { rows, page, totalCount, totalPage } = res;
            if (rows) {
                dispatch(setHealthInfoList({ rows, page, totalCount, totalPage, pageSize: PAGE_SIZE }));
            }
            setLoading(false);
        },
        reject: err => {
            if (err && err.message === 'canceled') return;
            console.error(err);
            setLoading(false);
        },
    });

    useAsync({
        promise: getHealthMonitoringSelectAPI,
        immediate: true,
        resolve: res => {
            const { inputVal } = res;

            SELECT_LIST.forEach(element => {
                const list = [{ label: t('All'), value: 'all' }];
                inputVal[element].forEach(({ name, value }) => {
                    list.push({ label: name, value });
                });
                dispatch(setFilterOptions({ key: element, value: list }));
            });
        },
        reject: err => {
            console.error(err);
        },
    });

    useEffect(() => {
        if (state.heartBeat) {
            setLoading(true);
            abortHealthMonitoringList();
            getHealthMonitoringList({ heartbeatEvent: 'Y' });
        } else {
            const { selectedFilterOption, inputOption, inputValue } = state;
            handleSearchClick({
                filterOption: selectedFilterOption,
                inputOption,
                inputValue,
            });
        }
    }, [state.heartBeat]);

    return (
        <HealthInformationProvisionDispatchContext.Provider value={dispatch}>
            <HealthInformationProvisionStatusContext.Provider value={state}>
                <GridLayoutCard
                    widgetInfo={widgetInfo}
                    {...restProps}
                    headerAction={
                        <>
                            <Button
                                className="btn-danger btn-icon-only"
                                iconName="refresh"
                                onClick={handleRefreshModal}
                                disabled={loading}
                            />
                            <div className="pnt-border border-h" />
                            <Button className="btn-secondary" onClick={toggleSearchArea} disabled={loading}>
                                {t('Search', 'Button')}
                            </Button>
                        </>
                    }
                    searchFilter={showSearchArea && <ConditionalSearch handleSearchClick={handleSearchClick} />}
                >
                    <AbnormalHeartbeat config={config} />
                    <HealthInformation isLoading={loading} handlePageChange={handlePageChange} />

                    {children}
                </GridLayoutCard>
                <WidgetConfirmModal
                    initModal={refreshModal}
                    toggleModal={handleRefreshModal}
                    okCallback={handleRefreshClick}
                    header={{ title: t('Notification', 'ConfirmModal') }}
                    confirmText={t('Are you sure you want to reset search criteria?')}
                />
            </HealthInformationProvisionStatusContext.Provider>
        </HealthInformationProvisionDispatchContext.Provider>
    );
};

export default HealthInformationProvision;
