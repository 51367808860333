import React, { useEffect, useMemo, useState } from 'react';

import useTranslation from '@hooks/useTranslation';
import { Button, TextInput, Select as ReactSelect } from '@components';

import { InputGroup, SelectGroup, InputWrap, SearchWrap } from '@components/FilterSearchGroup/Components/Part';
import FilterSearchGroup from '@components/FilterSearchGroup';
import SearchGroup from '@components/FilterSearchGroup/Components/SearchGroup';
import SearchButtonArea from '@components/FilterSearchGroup/Components/SearchButtonArea';

const Search = ({ getIotItemInfoLogList }) => {
    const t = useTranslation('Iot Item Information Log');

    const searchOptions = useMemo(() => {
        return [
            { value: '', label: t('Search Option', 'Search') },
            { value: 'targetNum', label: t('IoT Item No.') },
            { value: 'targetId', label: t('IoT Item ID') },
            { value: 'targetName', label: t('IoT Item Name') },
        ];
    }, [t]);

    const manipulationOptions = useMemo(() => {
        return [
            { value: '', label: t('Type of Data Revision', 'CommonColumn') },
            { value: 'INSERT', label: t('Register') },
            { value: 'UPDATE', label: t('Modify') },
            { value: 'DELETE', label: t('Delete') },
        ];
    }, [t]);

    const [selectedOption, setSelectedOption] = useState(searchOptions[0]);
    const [manipulationType, setManipulationType] = useState(manipulationOptions[0]);
    const [searchInput, setSearchInput] = useState('');
    const [searchInputDisabled, setSearchInputDisabled] = useState(true);

    useEffect(() => {
        if (selectedOption) {
            setSelectedOption(searchOptions.find(v => v.value === selectedOption.value));
        }
    }, [searchOptions]);

    useEffect(() => {
        if (manipulationType) {
            setManipulationType(manipulationOptions.find(v => v.value === selectedOption.value));
        }
    }, [manipulationOptions]);

    const handleSelectValueChange = value => {
        setSelectedOption(value);
        if (value.value !== '') {
            setSearchInputDisabled(false);
        } else {
            setSearchInputDisabled(true);
            setSearchInput('');
        }
    };

    const handleRefreshClick = e => {
        setSelectedOption(searchOptions[0]);
        setManipulationType(manipulationOptions[0]);

        setSearchInput('');

        setSearchInputDisabled(true);
        getIotItemInfoLogList();
    };

    const handleSearchClick = () => {
        if (searchInput) {
            getIotItemInfoLogList({
                manipulationType: manipulationType.value,
                opt: selectedOption.value,
                keyword: searchInput,
            });
        } else {
            getIotItemInfoLogList({
                manipulationType: manipulationType.value,
            });
        }
    };

    const handleInputChange = e => {
        const { value } = e.target;
        setSearchInput(value);
    };

    return (
        <FilterSearchGroup label={t('Search', 'Search')}>
            <SearchGroup className={'bg-brand'} label={t('Search', 'Search')}>
                <div style={{ minWidth: '10rem' }}>
                    <ReactSelect
                        name="manipulationType"
                        placeholder={t('Type of Data Revision', 'CommonColumn')}
                        value={manipulationType}
                        options={manipulationOptions}
                        onChange={selected => {
                            setManipulationType(selected);
                        }}
                        className="w-100"
                        customControlStyles={{ width: '100%' }}
                        customMenuStyles={{ width: '100%' }}
                        customOptionStyles={{ width: '100%' }}
                    />
                </div>
                <SearchWrap>
                    <SelectGroup>
                        <ReactSelect
                            name="selectInput"
                            placeholder={t('Search Option', 'Search')}
                            value={selectedOption}
                            options={searchOptions}
                            onChange={handleSelectValueChange}
                            customControlStyles={{ width: '100%' }}
                            customMenuStyles={{ width: '100%' }}
                            customOptionStyles={{ width: '100%' }}
                        />
                    </SelectGroup>
                    <InputWrap>
                        <InputGroup>
                            <TextInput
                                style={{ borderRadius: '5px' }}
                                type="text"
                                size={60}
                                placeholder={t(
                                    'Please Enter your search keyword after selecting search option.',
                                    'Search',
                                )}
                                name="name"
                                value={searchInput}
                                handleChange={handleInputChange}
                                disabled={searchInputDisabled}
                            />
                        </InputGroup>
                    </InputWrap>
                </SearchWrap>
                <SearchButtonArea>
                    <Button className="btn-brand btn-icon" iconName="search" onClick={handleSearchClick}>
                        {t('Search', 'Search')}
                    </Button>
                    <Button className="btn-danger btn-icon-only" iconName="replay" onClick={handleRefreshClick} />
                </SearchButtonArea>
            </SearchGroup>
        </FilterSearchGroup>
    );
};

export default Search;
