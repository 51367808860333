import React, { useState, useEffect, useRef } from 'react';
import { Responsive, WidthProvider } from 'react-grid-layout';
import defaultConfig, { monitoringConfig } from './config';

// import FilterWidget from '../../Filter/FilterWidget';

// import { updateFilterInfo } from 'reducers/FilterInfo';
import { useDispatch, useSelector } from 'react-redux';
import { setTagInfos } from 'reducers/TagInfo';
import { setConjuncture, setPatrolState, setPatrolZoneState } from 'reducers/Navy';

import useSocket from 'util/socket/hooks/useSocket';
import { initUUIDv4 } from 'util/common/util';

const ResponsiveGridLayout = WidthProvider(Responsive);
const RENDERING_TIME = 0;

const DashboardFrame = ({ children, layouts, ...rest }) => {
    const dispatch = useDispatch();
    const { socket, setSocketEvent } = useSocket();
    const { enableClosedSidebar } = useSelector(state => state.ThemeOptions);
    // const { filterInfo } = useSelector(state => state.FilterInfo);
    // const { serviceCategoryCodes } = useSelector(state => state.CategoryInfo);
    const { mode } = useSelector(state => state.DashboardFrame);
    const [modeConfig, setModeConfig] = useState({});

    const socketBuffer = useRef({
        interval: null,
        buffer: [],
        locationResult: null,
        conjuncture: null,
        patrolZonePresenceState: null,
    });
    const flushData = () => {
        // current buffer 어레이가 있는 경우에만 실행
        if (socketBuffer.current.buffer.length > 0) {
            dispatch(setTagInfos(socketBuffer.current.buffer.splice(0)));
        }
        if (socketBuffer.current.conjuncture) {
            dispatch(setConjuncture(socketBuffer.current.conjuncture));
            socketBuffer.current.conjuncture = null;
        }
        if (socketBuffer.current.patrolZonePresenceState) {
            const { response } = socketBuffer.current.patrolZonePresenceState;
            if (response === 'Complete') {
                dispatch(setPatrolZoneState(initUUIDv4()));
            }
            if (['Start', 'End'].includes(response)) {
                dispatch(setPatrolState(response));
            }
            socketBuffer.current.patrolZonePresenceState = null;
        }
    };
    const flushDataInterval = () => {
        clearInterval(socketBuffer.current.interval);
        socketBuffer.current.interval = setInterval(() => {
            flushData();
        }, RENDERING_TIME);
    };
    const stopFlushDataInterval = () => {
        flushData();
        clearInterval(socketBuffer.current.interval);
        socketBuffer.current.interval = null;
    };

    useEffect(() => {
        window.dispatchEvent(new Event('resize'));
    }, [enableClosedSidebar]);

    useEffect(() => {
        if (mode !== 'edit') {
            setModeConfig(monitoringConfig);

            flushDataInterval();
            if (socket) {
                setSocketEvent('updateMarker', function (data) {
                    // dispatch(setTagInfo(data));
                    socketBuffer.current.buffer.push(data);
                });
                setSocketEvent('UpdateConjuncture', data => {
                    socketBuffer.current.conjuncture = data;
                });
                setSocketEvent('PatrolZonePresenceState', data => {
                    socketBuffer.current.patrolZonePresenceState = data;
                });
            }
            // updateMarker
            // notification
            // changeSensorValue
        } else {
            setModeConfig({});
        }
        return () => {
            stopFlushDataInterval();
            if (socket) {
                setSocketEvent('updateMarker');
                setSocketEvent('UpdateConjuncture');
                setSocketEvent('PatrolZonePresenceState');
            }
        };
    }, [mode, socket]);

    return (
        <>
            {/*{serviceCategoryCodes && (*/}
            {/*    <FilterWidget*/}
            {/*        filterInfo={filterInfo}*/}
            {/*        filterEditMode*/}
            {/*        handleFilterConditionChange={selected => {*/}
            {/*            dispatch(*/}
            {/*                updateFilterInfo({*/}
            {/*                    filterInfoCondition: filterInfo.filterInfoCondition.map(conditionInfo => {*/}
            {/*                        if (conditionInfo.conditionId === selected.conditionId) {*/}
            {/*                            return { ...conditionInfo, conditionValues: selected.conditionValues };*/}
            {/*                        }*/}
            {/*                        return conditionInfo;*/}
            {/*                    }),*/}
            {/*                }),*/}
            {/*            );*/}
            {/*        }}*/}
            {/*    />*/}
            {/*)}*/}
            <ResponsiveGridLayout key={mode} {...defaultConfig} layouts={layouts} {...rest} {...modeConfig}>
                {children}
            </ResponsiveGridLayout>
        </>
    );
};

export default DashboardFrame;
