import React, { useEffect, useState } from 'react';
import { ModalBody, ModalFooter } from 'reactstrap';

import { useTranslation } from '@hooks';
import { Label, Modal, Select, TimePicker, Button } from '@components';

import { DIVISION_OPTIONS } from './PatrolDailyLogPreviewModal/utils';

import moment from 'moment';

const PatrolDailyLogModal = ({ initModal, toggleModal, handlePatrolDailyLog, request = {} }) => {
    const t = useTranslation('Patrol Log');

    const divisionOptions = DIVISION_OPTIONS.map(option => ({ ...option, label: t(option.label) }));

    const [selectedDivision, setSelectedDivision] = useState(divisionOptions[0]);
    const handleSelectedDivision = selected => {
        setSelectedDivision(selected);
    };

    const [startTime, setStartTime] = useState(moment.unix(request.startDate).valueOf());
    const handleStartTime = selected => {
        setStartTime(selected);
    };

    const handleSubmit = () => {
        const { value, start, end } = selectedDivision;
        handlePatrolDailyLog({
            patrolDivName: value,
            patrolDate: moment(startTime).startOf('day').unix(),
            startDate: moment(startTime).startOf('day').add(start, 'hours').unix(),
            endDate: moment(startTime).startOf('day').add(end, 'hours').unix(),
        });
        toggleModal();
    };

    useEffect(() => {
        if (request.startDate) {
            setStartTime(moment.unix(request.startDate).valueOf());
        }
    }, [request.startDate]);

    return (
        <Modal
            initModal={initModal}
            toggleModal={toggleModal}
            headerTitle={t('Patrol Daily Log')}
            modalFooter={
                <ModalFooter>
                    <Button className="color-secondary" onClick={toggleModal}>
                        {t('Cancel', 'ConfirmModal')}
                    </Button>
                    <Button className="btn-secondary" onClick={handleSubmit}>
                        {t('OK', 'ConfirmModal')}
                    </Button>
                </ModalFooter>
            }
        >
            <ModalBody className="d-flex flex-column gap-2">
                <Label
                    name={t('Patrol Date')}
                    labelGroupClassName="gap-5"
                    value={
                        <TimePicker
                            value={startTime}
                            handleChange={handleStartTime}
                            minDate={moment.unix(request.startDate).valueOf()}
                            maxDate={moment.unix(request.endDate).valueOf()}
                            valueType="ms"
                            withoutTime
                        />
                    }
                />

                <Label
                    name={t('Division')}
                    labelGroupClassName="gap-5"
                    value={
                        <div style={{ width: '15.4rem' }}>
                            <Select
                                name="patrolDivision"
                                className="w-100"
                                value={selectedDivision}
                                options={divisionOptions}
                                onChange={handleSelectedDivision}
                                customOptionStyles={{ width: '100%' }}
                                customControlStyles={{ width: '100%' }}
                                customMenuStyles={{ width: '100%' }}
                            />
                        </div>
                    }
                />
            </ModalBody>
        </Modal>
    );
};

export default PatrolDailyLogModal;
