import React, { useContext, useEffect, useState } from 'react';
import SearchableSelect from '@components/Select/SearchableSelect';
import { useTranslation } from 'react-i18next';
import { RealTimeSensorMonitoringContext, RealTimeSensorMonitoringDispatchContext } from '../index';
import { fetchRealTimeSensorMonitoring, fetchSensingTypes } from '@api/monitoring';
import useAsync from '@hooks/useAsync';
import Button from '@components/Button';
import {
    setSensorList,
    setSensorType,
    setSelectedFloor,
    setSelectedSensorType,
    setSelectedSensingTypes,
} from '../realTimeSensorMonitoringReducer';
import { useSelector } from 'react-redux';
import TreeSelect from '@components/Select/TreeSelect';
import { FilterList } from '@components/FilterSearchGroup/Components/Part';

const SensingFilter = () => {
    const { t } = useTranslation();

    const { floorList } = useSelector(state => state.FloorInfo);
    const { sensorType, selectedFloor, selectedSensingTypes, selectedSensorType } = useContext(
        RealTimeSensorMonitoringContext,
    );
    const dispatch = useContext(RealTimeSensorMonitoringDispatchContext);

    const [filterData, setFilterData] = useState({});

    const { promise: getSensorList } = useAsync({
        promise: fetchRealTimeSensorMonitoring,
        resolve: res => {
            const { rows } = res;

            dispatch(setSensorList(rows));
        },
    });

    useAsync({
        promise: fetchSensingTypes,
        immediate: true,
        resolve: res => {
            const { rows } = res;
            let types = {
                Environment: [],
                Health: [],
                Movement: [],
                Chemistry: [],
                Other: [],
            };

            for (let i = 0; i < rows.length; i++) {
                switch (rows[i].cCD) {
                    case 'A':
                        types['Environment'] = [
                            ...types['Environment'],
                            {
                                value: rows[i].sCD,
                                label: t(`RealTimeSensorMonitoring;${rows[i].sCD}`),
                                type: 'Environment',
                            },
                        ];
                        break;
                    case 'B':
                        types['Health'] = [
                            ...types['Health'],
                            {
                                value: rows[i].sCD,
                                label: t(`RealTimeSensorMonitoring;${rows[i].sCD}`),
                                type: 'Health',
                            },
                        ];
                        break;
                    case 'C':
                        types['Movement'] = [
                            ...types['Movement'],
                            {
                                value: rows[i].sCD,
                                label: t(`RealTimeSensorMonitoring;${rows[i].sCD}`),
                                type: 'Movement',
                            },
                        ];
                        break;
                    case 'D':
                        types['Chemistry'] = [
                            ...types['Chemistry'],
                            {
                                value: rows[i].sCD,
                                label: t(`RealTimeSensorMonitoring;${rows[i].sCD}`),
                                type: 'Chemistry',
                            },
                        ];
                        break;
                    case 'Z':
                        types['Other'] = [
                            ...types['Other'],
                            {
                                value: rows[i].sCD,
                                label: t(`RealTimeSensorMonitoring;${rows[i].sCD}`),
                                type: 'Other',
                            },
                        ];
                        break;
                    default:
                        break;
                }
            }
            setFilterData(types);
        },
    });

    const sensorTypeData = [
        { value: 'F', label: t('RealTimeSensorMonitoring;Fixed Type') },
        { value: 'M', label: t('RealTimeSensorMonitoring;Mobile Type') },
    ];
    useEffect(() => {
        let type = 'ALL TYPE';

        if (selectedSensorType.length > 0) {
            if (selectedSensorType.length === 2) {
                type = 'ALL TYPE';
            } else if (selectedSensorType[0] === 'F') {
                type = 'F';
            } else if (selectedSensorType[0] === 'M') {
                type = 'M';
            }
        }
        dispatch(setSensorType(type));
    }, [selectedSensorType]);

    const handleFloorChange = floorList => {
        dispatch(setSelectedFloor(floorList));
    };

    const handleSensorTypeChange = typeList => {
        dispatch(setSelectedSensorType(typeList.map(v => v.value)));
    };

    const handleSelectChange = (value, e) => {
        dispatch(
            setSelectedSensingTypes(
                e.name,
                value.map(v => v.value),
            ),
        );
    };

    const handleSearchClick = () => {
        let posMode = '';
        let sensingTypes = '';
        let floorIds = '';

        if (selectedSensorType.length === 1) {
            posMode = selectedSensorType[0];
        }

        sensingTypes =
            selectedSensingTypes['Environment'].join(',') +
            selectedSensingTypes['Health'].join(',') +
            selectedSensingTypes['Movement'].join(',') +
            selectedSensingTypes['Chemistry'].join(',') +
            selectedSensingTypes['Other'].join(',');

        floorIds = selectedFloor.join(',');

        if (floorIds === '') {
            getSensorList({ posMode, sensingTypes });
        } else {
            getSensorList({ posMode, sensingTypes, floorIds });
        }
    };

    return (
        <FilterList>
            {(sensorType === 'ALL TYPE' || sensorType === 'F') && (
                <>
                    <TreeSelect
                        data={floorList}
                        title={t('RealTimeSensorMonitoring;Floor')}
                        labelKey={'floorName'}
                        valueKey={'floorId'}
                        parentKey={'upperFloorId'}
                        selected={selectedFloor}
                        onChange={handleFloorChange}
                    />
                </>
            )}
            <SearchableSelect
                title={t('RealTimeSensorMonitoring;Sensor Type')}
                selected={selectedSensorType}
                data={sensorTypeData}
                onChange={handleSensorTypeChange}
            />
            <SearchableSelect
                title={t('RealTimeSensorMonitoring;Environment')}
                selected={selectedSensingTypes['Environment']}
                data={filterData.Environment}
                name={'Environment'}
                onChange={handleSelectChange}
            />
            <SearchableSelect
                title={t('RealTimeSensorMonitoring;Health')}
                selected={selectedSensingTypes['Health']}
                data={filterData.Health}
                name={'Health'}
                onChange={handleSelectChange}
            />
            <SearchableSelect
                title={t('RealTimeSensorMonitoring;Movement')}
                selected={selectedSensingTypes['Movement']}
                data={filterData.Movement}
                name={'Movement'}
                onChange={handleSelectChange}
            />
            <SearchableSelect
                title={t('RealTimeSensorMonitoring;Chemistry')}
                selected={selectedSensingTypes['Chemistry']}
                data={filterData.Chemistry}
                name={'Chemistry'}
                onChange={handleSelectChange}
            />
            <SearchableSelect
                title={t('RealTimeSensorMonitoring;Other')}
                selected={selectedSensingTypes['Other']}
                data={filterData.Other}
                name={'Other'}
                onChange={handleSelectChange}
            />

            <Button className="btn-icon btn-brand" iconName="search" onClick={handleSearchClick}>
                {t('AssetCountStatus;Search')}
            </Button>
        </FilterList>
    );
};

export default SensingFilter;
