import React, { useState, useMemo, useEffect, useContext } from 'react';
import useTranslation from '@hooks/useTranslation';
import { useSelector } from 'react-redux';
import TreeSelectWithOption from '../Components/SelectWithOption/TreeSelectWithOption';
import { FilterGeneratorContext } from '../FilterGenerator';

const CategorySelect = ({ selected, isEditable, handleChange }) => {
    const t = useTranslation('Filter');
    const [selectedOption, setSelectedOption] = useState(selected);

    const { categoryList } = useSelector(state => state.CategoryInfo);
    const { filterInfo, filterEditMode } = useContext(FilterGeneratorContext);

    const treeData = useMemo(() => {
        const filterCategoryList = filterInfo.categoryCodes ? filterInfo.categoryCodes.split(',') : [];
        return filterCategoryList.length
            ? categoryList.reduce((categories, category) => {
                  const { categoryCode, parentCode } = category;
                  if (filterCategoryList.includes(categoryCode) || filterCategoryList.includes(parentCode)) {
                      categories.push({ ...category, categoryCodes: categoryCode });
                  }
                  return categories;
              }, [])
            : categoryList.map(category => ({ ...category, categoryCodes: category.categoryCode }));
    }, [categoryList, filterInfo.categoryCodes]);

    const handleSelectedOptionChange = (selected, isEditable) => {
        handleChange(selected, isEditable);
        setSelectedOption(selected);
    };

    useEffect(() => {
        if (categoryList.length && selected && selected.length) {
            setSelectedOption(selected);
        } else {
            setSelectedOption([]);
        }
    }, [selected, categoryList]);

    return (
        <TreeSelectWithOption
            data={treeData}
            title={t('Asset Categorization')}
            valueKey={'categoryCode'}
            parentKey={'parentCode'}
            labelKey={'categoryName'}
            selected={selectedOption}
            onChange={handleSelectedOptionChange}
            isEditable={isEditable}
            disabled={!filterEditMode}
        />
    );
};

export default CategorySelect;
