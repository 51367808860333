import React, { createContext, useReducer, useState, useRef } from 'react';

import { Card, Page } from '@components';
import { useConfirmModal, useTranslation } from '@hooks';

import IntegratedTable from './Components/IntegratedTable';
import IntegratedMap from './Components/IntegratedMap';
import SailStatus from './Components/SailStatus';

import IntegratedMonitorReducer, { initialState } from './IntegratedMonitorReducer';
import ListToast from './Components/ListToast';

export const IntegratedMonitorStatusContext = createContext(null);
export const IntegratedMonitorDispatchContext = createContext();

const IntegratedMonitor = () => {
    const t = useTranslation('Integrated Monitor');

    const cardRef = useRef(null);
    const [state, dispatch] = useReducer(IntegratedMonitorReducer, initialState);

    const [isOpen, setIsOpen] = useState(false);
    const toggleModal = state => {
        if (typeof state === 'boolean') {
            setIsOpen(state);
            return;
        }
        setIsOpen(!isOpen);
    };

    const { toggleModal: toggleConfirmApprovalModal, Modal: ConfirmApprovalModal } = useConfirmModal({
        initModal: false,
        header: { title: t('Confirm', 'ConfirmModal') },
        confirmText: t('Location view permission required.'),
        removeCancel: true,
    });

    return (
        <IntegratedMonitorDispatchContext.Provider value={dispatch}>
            <IntegratedMonitorStatusContext.Provider value={state}>
                <Page menuPath={[t('Monitoring', 'Widget'), t('Integrated Monitor')]}>
                    <Card innerRef={cardRef} className="integrated-card">
                        <SailStatus />
                        <IntegratedTable toggleModal={toggleModal} />
                        <IntegratedMap />
                    </Card>
                    <ListToast
                        initModal={isOpen}
                        toggleModal={toggleModal}
                        toggleConfirmApprovalModal={toggleConfirmApprovalModal}
                        searchRef={cardRef}
                    />
                    <ConfirmApprovalModal />
                </Page>
            </IntegratedMonitorStatusContext.Provider>
        </IntegratedMonitorDispatchContext.Provider>
    );
};

export default IntegratedMonitor;
