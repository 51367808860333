import { useMemo, ReactElement } from 'react';

import useTranslation from './useTranslation';

export interface Column {
    Header: string | ReactElement;
    accessor: string;
    t?: Function;
    className?: string;
    headerClassName?: string;
    width?: number;
    Cell?: ReactElement;
    formatTitle?: Function;
    restProps?: Array<any>;
}

const makeColumns = (columns: Array<Column>, t: Function): Array<Column> =>
    columns.map((col: Column): Column => {
        const newCol: Column = { ...col };
        if (typeof newCol.Header === 'string') {
            newCol.t = newCol.t || t;
            newCol.Header = newCol['t'](newCol.Header);
        }
        return newCol;
    });

// 번역 우선 순위 : 컬럼 옵션 t > useColumn 파라미터 t > 기본 t
const useColumns = (cols: Column[], t?: Function, deps: Array<any> = []): Column[] => {
    const defaultT: Function = useTranslation('CommonColumn');
    return useMemo(() => makeColumns(cols, t || defaultT), [defaultT, ...deps]);
};

export default useColumns;
