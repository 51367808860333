import { GetByteCalculation } from './type';

const DATA_TYPES = ['bytes', 'KB', 'MB', 'GB'];

export const getByteCalculation: GetByteCalculation = fileSize => {
    const bytes = parseInt(fileSize);
    const typeIndex = Math.floor(Math.log(bytes) / Math.log(1024));

    if (typeIndex === Number.NEGATIVE_INFINITY) {
        return '0 ' + DATA_TYPES[0];
    } else {
        return (bytes / Math.pow(1024, Math.floor(typeIndex))).toFixed(2) + ' ' + DATA_TYPES[typeIndex];
    }
};
