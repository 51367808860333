import React from 'react';
import { useHistory } from 'react-router-dom';

import { Button } from '@components';
import { useTranslation } from '@hooks';

const ListButton = () => {
    const history = useHistory();
    const t = useTranslation('Technical Manual');

    const handleHistoryToList = () => {
        history.push('/manual/search');
    };

    return (
        <Button className="btn-gray btn-icon" iconName="menu" onClick={handleHistoryToList}>
            {t('List')}
        </Button>
    );
};

export default ListButton;
