import reducers from '../reducers';
import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { ACTION_TYPE_LOGOUT } from '../reducers/UserInfo';

const combinedReducer = combineReducers({ ...reducers });

interface Action {
    type: string;
    payload?: Object;
}

type State = Object | undefined;

const reducerProxy = (state: any, action: Action) => {
    if (action.type === ACTION_TYPE_LOGOUT) {
        return combinedReducer(undefined, action);
    }
    return combinedReducer(state, action);
};

export default function createStore() {
    return configureStore({
        reducer: reducerProxy,
        middleware: getDefaultMiddleware => getDefaultMiddleware({ serializableCheck: false }),
        devTools: true,
    });
}

const store = createStore();
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
