import { nullToHyphen } from '@util/common/util';

export const station = ({ ...restProps } = {}) => {
    return {
        Header: 'Station',
        accessor: 'beaconName',
        Cell: ({ value }) => {
            return nullToHyphen(value);
        },
        headerClassName: 'text-ellipsis',
        className: 'text-ellipsis',
        ...restProps,
    };
};

export const patrolName = ({ ...restProps } = {}) => {
    return {
        Header: 'Patrol Name',
        accessor: 'targetName',
        Cell: ({ value }) => {
            return nullToHyphen(value);
        },
        headerClassName: 'text-ellipsis',
        className: 'text-ellipsis',
        ...restProps,
    };
};

export const time = ({ ...restProps } = {}) => {
    return {
        Header: 'Time',
        accessor: 'modDate',
        headerClassName: 'text-ellipsis',
        className: 'text-ellipsis',
        ...restProps,
    };
};

export const floor = ({ ...restProps } = {}) => {
    return {
        Header: 'Floor',
        accessor: 'floorName',
        headerClassName: 'text-ellipsis',
        className: 'text-ellipsis',
        ...restProps,
    };
};

export const completion = ({ ...restProps } = {}) => {
    return {
        Header: 'Completion',
        accessor: 'completeCnt',
        headerClassName: 'text-ellipsis',
        className: 'text-ellipsis',
        ...restProps,
    };
};

export const incompletion = ({ ...restProps } = {}) => {
    return {
        Header: 'Incompletion',
        accessor: 'inCompleteCnt',
        headerClassName: 'text-ellipsis',
        className: 'text-ellipsis',
        ...restProps,
    };
};
