import React, { Children, createElement, isValidElement } from 'react';

import ClickableTable from './ClickableTable';
import CompoundCardHeader from './CompoundCardHeader';
import CompoundCardBodyMain, { CompoundCardPlain, CompoundCardContents, CompoundCardTable } from './CompoundCardBody';

import { CompoundCardContainer } from './styles';

const CompoundCardMain = ({ children, ...restProps }) => {
    const cardHeader = Children.toArray(children).filter(child => {
        if (isValidElement(child)) {
            return child.type === createElement(CompoundCardHeader).type;
        }
        return false;
    });
    const cardBody = Children.toArray(children).filter(child => {
        if (isValidElement(child)) {
            return child.type === createElement(CompoundCardBodyMain).type;
        }
        return false;
    });

    return (
        <CompoundCardContainer {...restProps}>
            {!!cardHeader && cardHeader[0]}
            {!!cardBody && cardBody[0]}
        </CompoundCardContainer>
    );
};

const CompoundCardBody = Object.assign(CompoundCardBodyMain, {
    plain: CompoundCardPlain,
    contents: CompoundCardContents,
    table: CompoundCardTable,
    clickableTable: ClickableTable,
});

const CompoundCard = Object.assign(CompoundCardMain, {
    header: CompoundCardHeader,
    body: CompoundCardBody,
});

export default CompoundCard;
