import React, { useState, useEffect, useContext, useRef, useCallback } from 'react';

import { Card, Button, makeRotatedImageOverlay } from 'Components/Common';
import { useAsync, useColumns, useTranslation } from '@hooks';

import { fetchGeofenceList } from 'api/common';

import PlayController from './PlayController';
import Table from './Table';

import Control from 'react-leaflet-control';
import MapOptionSelect from './MapOptionSelect';
import { Marker, Polyline } from 'react-leaflet';
import Map from 'Components/MainPages/Monitoring/Components/Map';
import L from 'leaflet';

import { useSelector } from 'react-redux';

import {
    DrowningMonitoringDispatchContext,
    DrowningMonitoringStatusContext,
} from 'Components/MainPages/Monitoring/DrowningMonitoring';
import {
    setPlayerState,
    setGeofenceInfo,
} from 'Components/MainPages/Monitoring/DrowningMonitoring/DrowningMonitoringReducer';

import * as column from '../column';

import cx from 'classnames';

const AnalysisByRealTime = () => {
    const t = useTranslation('Drowning Monitoring');

    const mapRef = useRef(null);

    const { floorInfo } = useContext(DrowningMonitoringStatusContext);
    const dispatch = useContext(DrowningMonitoringDispatchContext);

    const { tagListByFloor } = useSelector(state => state.TagInfo);

    const [selectedAsset, setSelectedAsset] = useState(null);

    const [realTimeLog, setRealTimeLog] = useState({});
    const [realTimeLogForAsset, setRealTimeLogForAsset] = useState({ rows: [] });

    const [selectedMapOption, setSelectedMapOption] = useState({ value: '', label: t('None') });
    const handleSelectedMapOption = useCallback(selected => {
        setSelectedMapOption(selected[0]);
    }, []);

    const toggleState = state => {
        dispatch(setPlayerState(state));
    };

    const assetForRealTimeColumn = useColumns(
        [column.name(), column.distance(), column.latitude(), column.longitude(), column.date()],
        t,
    );

    const handleTrClick = tr => {
        const { targetId } = tr;
        setSelectedAsset(selectedAsset === targetId ? null : targetId);
    };

    useEffect(() => {
        if (!floorInfo || !floorInfo.floorId) return;
        if (!tagListByFloor || !tagListByFloor[floorInfo.floorId]) {
            setRealTimeLog({});
            setRealTimeLogForAsset({ rows: [] });
            return;
        }
        const log = realTimeLog;
        const rows = [];
        tagListByFloor[floorInfo.floorId].forEach(tag => {
            const {
                location: { latLng, floor, lat, lng },
                evtTime,
                target,
            } = tag;
            const { targetId } = target;

            // 스파게티 다이어그램 로그
            if (log[targetId]) {
                const result = log[targetId].filter(
                    ([lat, lng, time]) => lat === latLng[0] && lng === latLng[1] && time === evtTime,
                );
                if (!result.length) {
                    log[targetId].push([...latLng, evtTime]);
                }
            } else {
                log[targetId] = [[...latLng, evtTime]];
            }

            // 테이블 정보
            rows.push({ ...target, floorId: floor, lat, lng, regDate: evtTime });
        });
        setRealTimeLog(log);
        setRealTimeLogForAsset({ rows });

        return () => {
            setRealTimeLog({});
            setRealTimeLogForAsset({ rows: [] });
        };
    }, [tagListByFloor]);

    const RealTimeMarker = ({ data }) => {
        const {
            location: { latLng },
            target: { targetId },
        } = data;

        return (
            <Marker
                key={targetId}
                position={latLng}
                icon={L.divIcon({ className: 'simple-marker', html: `<div></div>` })}
            />
        );
    };

    const MakeRealTimeMarker = useCallback(() => {
        if (!floorInfo || !floorInfo.floorId) return null;

        const log = tagListByFloor[floorInfo.floorId];
        if (!log || !log.length) {
            return null;
        }

        if (selectedAsset) {
            const selectedLog = log.find(({ target: { targetId } }) => targetId === selectedAsset);
            if (selectedLog) {
                return <RealTimeMarker data={selectedLog} />;
            }
            setSelectedAsset(null);
        }
        return log.map((popupData, idx) => {
            return <RealTimeMarker key={idx} data={popupData} />;
        });
    }, [floorInfo, tagListByFloor, selectedAsset]);

    const MakeSpaghettiDiagram = useCallback(() => {
        if (!selectedMapOption || selectedMapOption.value !== 'spaghettiDiagram') {
            return null;
        }

        if (selectedAsset && !!realTimeLog[selectedAsset]) {
            return <Polyline positions={realTimeLog[selectedAsset]} />;
        }

        const unselectedAsset = realTimeLogForAsset.rows.map(({ targetId }) => realTimeLog[targetId]);
        return unselectedAsset.map((log, idx) => {
            return <Polyline key={'real-time ' + idx} positions={log} />;
        });
    }, [selectedMapOption, selectedAsset, realTimeLog, realTimeLogForAsset]);

    // geofence 정보 API
    const { promise: getGeofenceList } = useAsync({
        promise: fetchGeofenceList,
        resolve: res => {
            const { rows } = res;
            if (!!rows.length) {
                dispatch(
                    setGeofenceInfo(
                        rows.map(geofence => {
                            return { ...geofence, bounds: geofence.latLngList.map(latLng => [latLng.lat, latLng.lng]) };
                        }),
                    ),
                );
                return;
            }
            dispatch(setGeofenceInfo([]));
        },
        reject: err => {
            console.error(err);
            dispatch(setGeofenceInfo([]));
        },
    });

    // geofence 정보 호출
    useEffect(() => {
        if (floorInfo) {
            getGeofenceList({ floor: floorInfo.floorId || '' });
            const map = mapRef.current.leafletElement;
            if (map) {
                map.eachLayer(layer => {
                    if (layer instanceof L.ImageOverlay) {
                        map.fitBounds(layer.getBounds());
                        return false;
                    }
                });
            }
        }
    }, [floorInfo]);

    return (
        <>
            <Card header={{ title: t('Drowning List') }} className="asset-list">
                <Table
                    key={selectedAsset}
                    data={realTimeLogForAsset}
                    columns={assetForRealTimeColumn}
                    onTrClick={handleTrClick}
                    selected={selectedAsset}
                    paging={false}
                />
            </Card>
            <Card
                header={{
                    title: t('Position History Viewer'),
                    action: (
                        <>
                            <Button className={cx('pnt-btn', 'btn-secondary')}>{t('Real-time')}</Button>
                            <Button
                                className={cx('pnt-btn', 'btn-lightgray')}
                                onClick={() => {
                                    toggleState('track');
                                    setSelectedAsset(null);
                                }}
                            >
                                {t('Track')}
                            </Button>
                        </>
                    ),
                }}
            >
                <div style={{ height: '110px', width: '100%' }}>
                    <PlayController on={false} />
                </div>
                <div style={{ height: 'calc(100% - 130px)', minHeight: '28rem' }}>
                    <Map tile={true} ref={mapRef}>
                        <Control position="topleft" key={`control-${t(selectedMapOption.label)}`}>
                            <MapOptionSelect handleChange={handleSelectedMapOption} value={[selectedMapOption]} />
                        </Control>

                        {makeRotatedImageOverlay(mapRef, floorInfo)}

                        <MakeRealTimeMarker />
                        <MakeSpaghettiDiagram />
                    </Map>
                </div>
            </Card>
        </>
    );
};

export default AnalysisByRealTime;
