import { ReactNode } from 'react';

const WIDGET_CATEGORY_DASHBOARD = 'Dashboard' as const;
const WIDGET_CATEGORY_MONITORING = 'Monitoring' as const;
const WIDGET_CATEGORY_MANUAL_SEARCH = 'Manual search' as const;
const WIDGET_ASSET_MANAGEMENT = 'Asset Management' as const;
const WIDGET_NAVY = 'Navy' as const;

// 위젯 카테고리 목록
export const WIDGET_CATEGORY = {
    DASHBOARD: WIDGET_CATEGORY_DASHBOARD,
    MONITORING: WIDGET_CATEGORY_MONITORING,
    MANUAL_SEARCH: WIDGET_CATEGORY_MANUAL_SEARCH,
    ASSET_MANAGEMENT: WIDGET_ASSET_MANAGEMENT,
    NAVY: WIDGET_NAVY,
} as const;

/**
 * ### 각 위젯들의 정보 Type
 *
 * @author minchan-kim
 */
export default interface Widget {
    type: string;
    category: WidgetCategory;
    name: string;
    desc: string;
    thumbnailURL: string | null;
    Component: ReactNode;
    SettingComponent: ReactNode;
    config: {
        defaultSettings?: Settings;
        settings?: Settings;
    };
    layout: Layout;
}

type WidgetCategory = typeof WIDGET_CATEGORY[keyof typeof WIDGET_CATEGORY];

interface Settings {
    name?: string;
    intervalTime?: number;
    categoryCode?: string;
    dataType?: string;
    chartType?: string;
    floor?: string;
    type?: string;
    targetOption?: string;
    tagOption?: number;
    DataInteger?: boolean;
    option?: string;
    flowNum?: null | number;
    statusCategoryNum?: null | number;
}

interface Layout {
    minW: number;
    w: number;
    maxW?: number;
    minH: number;
    h: number;
    maxH?: number;
}
