import React, { useContext, useState } from 'react';
import LoginTitle from './LoginTitle';
import { useTranslation, useAsync } from '@hooks';
import { LoginDispatchContext, LoginStateContext } from '../index';
import { Button, TextInput } from '@components';
import { setNextStep } from '../reducer';
import { updatePassword } from '@api/login';
import { loginErrorMsg } from '@components/style';

const ChangeForm = () => {
    const t = useTranslation('Login');
    const { email } = useContext(LoginStateContext);
    const dispatch = useContext(LoginDispatchContext);
    const [showVerificationErrorMsg, setShowVerificationErrorMsg] = useState({
        err: false,
        msg: 'Password verification is incorrect.',
    });
    const [password, setPassword] = useState({ new: '', confirm: '' });

    const { promise: changePassword } = useAsync({
        promise: updatePassword,
        resolve: res => {
            if (res.code === '0') {
                dispatch(setNextStep());
            }
        },
        reject: err => {
            console.log(err);
        },
    });

    const handleChangePw = e => {
        const target = e.currentTarget;
        setPassword({ ...password, [target.name]: target.value });
    };
    const handleSubmit = () => {
        if (!(password.new && password.confirm)) {
            setShowVerificationErrorMsg({
                err: true,
                msg: 'Please enter a password.',
            });
            return false;
        } else if (password.new !== password.confirm) {
            setShowVerificationErrorMsg({
                err: true,
                msg: 'Password verification is incorrect.',
            });
            return false;
        }
        if (password.confirm && password.new === password.confirm) {
            changePassword({
                userID: email,
                changePW: password.confirm,
            });
        }
    };
    return (
        <>
            <LoginTitle
                title={t('Change Password')}
                subTitle={t('Authentication is complete. Please enter a new password.')}
            />
            <div className="cont-box">
                <div className="cont-box__wrap flex-col">
                    <div className={'cont--error mb-2'} style={!showVerificationErrorMsg.err ? loginErrorMsg : {}}>
                        <p>* {t(showVerificationErrorMsg.msg)}</p>
                    </div>

                    <div className="cont--input flx-col">
                        <div className="pnt-label--group">
                            <span className="label-main">{t('E-mail')}</span>
                            <span className="label-options">
                                <TextInput
                                    inputGroupClassName={'w-100'}
                                    type={'text'}
                                    name={'email'}
                                    value={email}
                                    disabled
                                />
                            </span>
                        </div>
                        <div className="pnt-label--group">
                            <span className="label-main">{t('New Password')}</span>
                            <span className="label-options">
                                <TextInput
                                    inputGroupClassName={'w-100'}
                                    name={'new'}
                                    type={'password'}
                                    value={password.new}
                                    handleChange={handleChangePw}
                                    maxlength={30}
                                />
                            </span>
                        </div>
                        <div className="pnt-label--group">
                            <span className="label-main">{t('Confirm Password')}</span>
                            <span className="label-options">
                                <TextInput
                                    inputGroupClassName={'w-100'}
                                    name={'confirm'}
                                    type={'password'}
                                    value={password.confirm}
                                    handleChange={handleChangePw}
                                    maxlength={30}
                                />
                            </span>
                        </div>
                    </div>
                </div>

                <div className="cont--btn">
                    <Button className={'btn-brand btn-large w-100'} onClick={handleSubmit}>
                        {t('Change Password')}
                    </Button>
                </div>
            </div>
        </>
    );
};

export default ChangeForm;
