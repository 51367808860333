import React from 'react';

import { Label, Modal } from '@components';
import useTranslation from '@hooks/useTranslation';

import userImg from 'assets/images/unknown_user.png';

const ProfileModal = ({ initModal, userInfo, toggleModal }) => {
    const t = useTranslation('UserBox');
    return (
        <Modal initModal={initModal} toggleModal={toggleModal} headerTitle={t('Profile')} removeCancel>
            <div className="modal-body">
                <div className="d-flex" style={{ columnGap: '1rem' }}>
                    <div className="flx-col">
                        <img src={userImg} alt="profileImg" width="150" height="150" />
                    </div>
                    <div className="flx-col justify-content-around">
                        <Label name={t('Name')} labelValueClassName="color-brand" value={userInfo.userName} />
                        <Label name={t('ID')} labelValueClassName="color-brand" value={userInfo.userID} />
                        <Label name={t('Email')} labelValueClassName="color-brand" value={userInfo.userEmail} />
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default ProfileModal;
