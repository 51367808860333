import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import 'moment/locale/ko';
import cx from 'classnames';

const DashboardTitle = ({ title, className = {}, showSec, ...rest }) => {
    const { lang } = useSelector(state => state.UserInfo);
    const timeFormat = showSec ? 'hh:mm:ss' : 'hh:mm';
    const now = moment().locale(lang);
    const [realTimeDate, setRealTimeDate] = useState(now.format('YYYY.MM.DD ddd A'));
    const [realTime, setRealTime] = useState(now.format(timeFormat));

    useEffect(() => {
        const updateTime = setInterval(() => {
            const now = moment().locale(lang);
            setRealTimeDate(now.format('YYYY.MM.DD ddd A'));
            setRealTime(now.format(timeFormat));
        }, 1000);
        return () => clearInterval(updateTime);
    }, []);

    return (
        <div className={cx('dashboard-title-wrapper', className.wrapper)}>
            <div style={{ textAlign: 'left' }} className="flx-row gap-4">
                <div className="dashboard-logo">IndoorPlus+</div>
                <div className="dashboard-title">
                    {/*<span className="dashboard-sub">대시보드</span>*/}
                    {/*<span className="icon-next icon-small" />*/}
                    <span className={cx('dashboard-main', className.title)}>{title}</span>
                </div>
            </div>
            <div style={{ textAlign: 'right' }}>
                <span className={cx('dashboard-title-date', className.date)}>{realTimeDate}</span>
                <span className={cx('dashboard-title-time', className.time)}>{realTime}</span>
            </div>
        </div>
    );
};

export default DashboardTitle;
