import React, { useEffect, useRef } from 'react';
import { Route, Redirect, withRouter, useRouteMatch, useLocation } from 'react-router-dom';

import AppHeader from './AppHeader';
import AppSidebar from './AppSidebar';
import AppFooter from './AppFooter';

import LocationPopup from './AppHeader/Components/LocationPopup';

import { useDispatch, useSelector } from 'react-redux';
import { getLsUserInfo, getUuid } from 'util/common/util';
import { useUserTokenCheck } from '@hooks/utilHooks';
import { setAdditionalInfo, setOauthInfo, setOauthStatusModal, logOut, setLanguage } from 'reducers/UserInfo';

import { ConfirmModal } from '@components';
import Modules from '@components/Modules';
import Report from 'Components/MainPages/Report';
import { useAsync, useTranslation } from '@hooks';
import { setMarkerConfig } from 'reducers/Common/AppConfig';
import { setAlert } from 'reducers/Notification';
import { Portal } from 'react-overlays';

import { getApiURL } from 'api';
import { fetchCompany } from '@api/login';
import { fetchCategory } from '@api/asset';
import { fetchFloorList, getCategoryPropertiesListApi } from '@api/common';

import { setAllCategoryList, setCategoryPropertiesList } from 'reducers/Common/CategoryInfo';
import { setFloorList } from 'reducers/Common/FloorInfo';
import { setLocationApproval } from 'reducers/Navy';

import socketio from 'socket.io-client';
import { setSocket } from 'reducers/SocketInfo';
import { SOCKET_CONNECTING } from 'util/symbol/window';
import useSocket from 'util/socket/hooks/useSocket';

import Dashboards from 'Components/MainPages/Dashboards';
import Filter from 'Components/MainPages/Filter';
import Settings from 'Components/MainPages/Settings';
import Assets from 'Components/MainPages/Assets';
import AssetInspectionManagement from 'Components/MainPages/AssetInspectionManagement';
import AssetRentalManagement from 'Components/MainPages/AssetRentalManagement';
import Status from 'Components/MainPages/Status';
import Monitoring from 'Components/MainPages/Monitoring';
import { ErrorHandleSwitch } from 'Components/MainPages/Components/Router';

import '@material-design-icons/font';

//Smart Military
import TechnicalManual from 'Components/MainPages/TechnicalManual';

import ToastAlert from 'Components/MainPages/Alert/ToastAlert';

import cx from 'classnames';

const AppMain = () => {
    const dispatch = useDispatch();
    const match = useRouteMatch();
    const location = useLocation();

    const { userInfo, modal } = useSelector(state => state.UserInfo);
    const { fullScreen } = useSelector(state => state.DashboardFrame);

    const t = useTranslation('Login');
    const appMainOuterRef = useRef();
    const portal = document.body;

    const { socket, setSocketEvent, closeSocket } = useSocket();

    const { promise: getMoreUserInfo } = useAsync({
        promise: fetchCompany,
        resolve: companyInfo => {
            dispatch(
                setMarkerConfig({
                    markerTransitionActive: companyInfo.markerTransitionActive,
                    markerPulseActive: companyInfo.markerPulseActive,
                    markerBorderRadius: companyInfo.markerBorderRadius,
                    markerPulseAnimation: companyInfo.markerPulseAnimation,
                    markerPulseRssi: companyInfo.markerPulseRssi,
                    markerPulseColor: companyInfo.markerPulseColor,
                }),
            );
        },
    });

    // 화면 리프레쉬 후 유저의 정보가 사라졌을때
    // 로컬스토리지에 저장된 정보 호출 후 저장
    useEffect(() => {
        if (!userInfo.userName) {
            const { oAuthInfo, userInfo } = getLsUserInfo();
            dispatch(setOauthInfo(oAuthInfo));
            if (userInfo) {
                const { lang, ...restUserInfo } = userInfo;
                dispatch(setAdditionalInfo(restUserInfo));
                dispatch(setLanguage(lang));
            }
            // dispatch(setAdditionalInfo(getLsUserInfo()));
        }
    }, [userInfo]);

    // refresh token의 에러가 발생했을 시 토글 메뉴 실행 후 유저인포 초기화
    // 유저인포의 초기화로 인하여 로그인 페이지로 이동
    const toggleOauthStatus = () => {
        dispatch(logOut());
        dispatch(setOauthStatusModal(!modal.modalOauthStatusOpen));
    };

    useUserTokenCheck();

    useEffect(() => {
        getMoreUserInfo();
        if (!window[SOCKET_CONNECTING]) {
            window[SOCKET_CONNECTING] = true;
            getApiURL().then(({ wmsSocketUrl }) => {
                const uuid = getUuid();
                const ws = socketio(wmsSocketUrl, {
                    transports: ['websocket'],
                    forceNew: true,
                    reconnection: true,
                    reconnectionAttempts: 5,
                    reconnectionDelay: 5000,
                });
                ws.on('connect', function () {
                    console.log('SOCKET_CONNECTED : ', wmsSocketUrl);
                    ws.emit('join', `presence-${uuid}`);
                    dispatch(setSocket(ws));
                });
                ws.on('disconnect', function () {
                    console.log('SOCKET_DISCONNECT : ', wmsSocketUrl);
                    dispatch(setSocket(ws));
                });
                ws.on('reconnect', function () {
                    console.log('SOCKET_RECONNECTED : ', wmsSocketUrl);
                    ws.emit('join', `presence-${uuid}`);
                    dispatch(setSocket(ws));
                });
            });
        }

        return () => {
            closeSocket(socket);
        };
    }, []);

    useEffect(() => {
        if (socket) {
            setSocketEvent('notification', data => {
                const { pathname } = location;
                if (pathname === '/monitoring/integrated') {
                    dispatch(setAlert(data));
                }
            });
            setSocketEvent('locationApprovalResult', function (data) {
                dispatch(setLocationApproval(data === 'Y'));
            });
        }
        return () => {
            setSocketEvent('notification');
            setSocketEvent('locationApprovalResult');
        };
    }, [socket, location]);

    // FloorList 받아서 Redux에 저장
    useAsync({
        promise: fetchFloorList,
        param: { isAll: 'Y' },
        resolve: response => {
            dispatch(setFloorList(response));
        },
        immediate: true,
        deps: [match.params.menuNum],
    });

    // CategoryList 받아서 Redux에 저장
    useAsync({
        promise: fetchCategory,
        param: { isAll: 'Y' },
        resolve: ({ rows }) => {
            dispatch(setAllCategoryList(rows));
        },
        immediate: true,
        deps: [match.params.menuNum],
    });

    useAsync({
        promise: getCategoryPropertiesListApi,
        param: { isAll: 'Y' },
        resolve: ({ rows }) => {
            dispatch(setCategoryPropertiesList(rows));
        },
        immediate: true,
        deps: [match.params.menuNum],
    });

    return (
        <>
            {/*<ThemeOptions />*/}
            <AppHeader />
            <div className="app-main">
                <AppSidebar />
                <div className="app-main__outer" ref={appMainOuterRef}>
                    <div className="app-main__inner">
                        <div className={cx('app-page-content', fullScreen && 'dashboard-fullscreen')}>
                            <ErrorHandleSwitch>
                                <Route
                                    exact
                                    path={'/'}
                                    render={() => {
                                        // return <Redirect to={'/dashboards'} />;
                                        // Smart Military Custom
                                        return <Redirect to={'/monitoring/integrated'} />;
                                    }}
                                />
                                <Route path={'/dashboards'} component={Dashboards} />
                                <Route path={'/filter'} component={Filter} />
                                <Route path={'/report'} component={Report} />
                                <Route path={'/setting'} component={Settings} />
                                <Route exact path={'/modules'} component={Modules} />
                                <Route path={'/assets'} component={Assets} />
                                <Route path={'/asset-inspection'} component={AssetInspectionManagement} />
                                <Route path={'/asset-rental'} component={AssetRentalManagement} />
                                <Route path={'/status'} component={Status} />
                                <Route path={'/monitoring'} component={Monitoring} />

                                {/*Smart Military*/}
                                <Route path={'/manual'} component={TechnicalManual} />
                            </ErrorHandleSwitch>

                            <ConfirmModal
                                initModal={modal.modalOauthStatusOpen}
                                toggleModal={toggleOauthStatus}
                                header={{ title: t('Authentication Error') }}
                                confirmText={
                                    <span>
                                        {t('The authentication information is incorrect. Please log in again.')}
                                    </span>
                                }
                                okCallback={toggleOauthStatus}
                                removeCancel={true}
                            />
                            <Portal container={portal}>
                                <LocationPopup appMainOuterRef={appMainOuterRef} />
                            </Portal>
                        </div>
                        <div id={'inner-selectContainer'} />
                    </div>
                    <AppFooter />
                </div>
            </div>
            <ToastAlert />
            <div id={'selectContainer'} />
        </>
    );
};

export default withRouter(AppMain);
