import React, { useMemo, useCallback, useEffect, useRef } from 'react';

import { useTable, useBlockLayout, usePagination } from 'react-table';
import AutoSizer from 'react-virtualized-auto-sizer';

import cx from 'classnames';

import { LoadingBlock, Button } from '@components';

import styled from 'styled-components';

import NoDataBlock from './NoDataBlock';

const DEFAULT_TH_ROW_HEIGHT = 36;
const DEFAULT_ROW_HEIGHT = 38;

const defaultRowPropGetter = ({ style }) => {
    return { style: { ...style, height: DEFAULT_ROW_HEIGHT } };
};

const makeTitle = ({ className, formatTitle, value, original }) => {
    if (typeof formatTitle === 'function') {
        return formatTitle({ value, original });
    }
    if (className && className.includes('text-ellipsis')) {
        return value;
    }
    return '';
};

// 테이블 너비 형식 변경
export const widthFormatter = width => {
    return typeof width === 'string' ? width.replace('px', '').replace('%', '') : width;
};

function Table({
    columns,
    data: { rows: list, totalCount = 0, totalPage = 0, pageSize = 20, page: pageNum = 1 },
    onPageChange,
    paging = true,
    textAlign = 'center',
    onTrClick,
    manualPagination = true,
    autoResetPage = true,
    getRowProps = defaultRowPropGetter,
    loading,
    dynamicRowHeight,
    noDataMessage,
}) {
    const defaultColumn = useMemo(() => ({ width: 150 }), []);

    // 테이블내의 페이지 변화인 상황인지 아닌지를 판별하기 위한 값
    const pageChanged = useRef(false);

    // const scrollBarSize = useMemo(() => scrollbarWidth(), []);
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        gotoPage,
        nextPage,
        previousPage,
        state: { pageIndex },
        totalColumnsWidth,
        prepareRow,
    } = useTable(
        {
            columns,
            data: list,
            initialState: { pageIndex: pageNum, pageSize },
            defaultColumn,
            manualPagination: manualPagination,
            pageCount: totalPage,
            autoResetPage: autoResetPage,
            // state를 직접 컨토를하기 위해 사용
            // API를 호출하여 리스트의 pageNum을 새롭게 받아왓을 때마다 테이블 내의 pageIndex를 업데이트
            useControlledState: state => {
                useMemo(() => {
                    state.pageIndex = pageNum - 1;
                }, [pageNum]);
                return state;
            },
        },
        usePagination,
        useBlockLayout,
    );

    const RenderRow = useCallback(
        ({ row, style }) => {
            prepareRow(row);
            const { className: rowClassName, ...restRowProps } = getRowProps({ row, style });

            if (dynamicRowHeight && restRowProps.style) {
                restRowProps.style.height = 'fit-content';
            }
            return (
                <div
                    {...row.getRowProps(restRowProps)}
                    className={cx('tr', typeof onTrClick === 'function' && 'clickable', rowClassName)}
                    onClick={e => {
                        if (typeof onTrClick === 'function') {
                            onTrClick(row.original, e);
                        }
                    }}
                >
                    {row.cells.map(cell => {
                        const { style: cellStyle, ...cellProps } = cell.getCellProps({
                            style: cell.column.style,
                        });
                        const {
                            column: { className, formatTitle },
                            row: { original },
                        } = cell;
                        return (
                            <div
                                {...cellProps}
                                className={cx('td', className)}
                                style={{
                                    ...cellStyle,
                                    flex: `${widthFormatter(cellStyle?.width)} 0 auto`,
                                }}
                                title={makeTitle({ className, formatTitle, value: cell.value, original })}
                            >
                                {cell.render('Cell')}
                            </div>
                        );
                    })}
                </div>
            );
        },
        [prepareRow, page],
    );

    useEffect(() => {
        // pageIndex값이 변하더라도 pageIndex가 변한 이유가 페이지 체인지 때문인지를 확인
        // 테이블의 리셋 버튼을 클릭했을 때는 onPageChange가 실행되는 것을 방지
        if (typeof onPageChange === 'function' && pageChanged.current) {
            pageChanged.current = false;
            onPageChange(pageIndex + 1);
        }
    }, [pageIndex]);

    const handlePageChange = (callback, callbackParam) => {
        // 페이지 체인지인 경우에는 current값을 true로 설정
        pageChanged.current = true;
        callback(callbackParam);
    };

    return (
        <LoadingBlock blocking={loading}>
            <TableWrapper className={'flx-col'} textAlign={textAlign}>
                {paging && (
                    <div className="pagination">
                        <div className="flx-row gap-1">
                            <Button
                                className="btn-icon-only btn-tiny btn-lightgray"
                                onClick={() => handlePageChange(gotoPage, 0)}
                            >
                                <span className="icon-back-double opacity-5" />
                            </Button>
                            <Button
                                className="btn-icon-only btn-tiny btn-brand"
                                onClick={() => handlePageChange(previousPage)}
                            >
                                <span className="icon-back icon-filter-white" />
                            </Button>
                            <div className="mx-2">
                                <span className="now">{`${pageIndex * pageSize + (page.length ? 1 : 0)}-${
                                    totalCount < pageIndex * pageSize + page.length
                                        ? totalCount
                                        : pageIndex * pageSize + page.length
                                }`}</span>
                                <span className="whole">{` / ${totalCount}`}</span>
                            </div>
                            <Button
                                className="btn-icon-only btn-tiny btn-brand"
                                onClick={() => handlePageChange(nextPage)}
                            >
                                <span className="icon-next icon-filter-white" />
                            </Button>
                            <Button
                                className="btn-icon-only btn-tiny btn-lightgray"
                                onClick={() => handlePageChange(gotoPage, totalPage - 1)}
                            >
                                <span className="icon-next-double opacity-5" />
                            </Button>
                        </div>
                    </div>
                )}
                <div
                    {...getTableProps()}
                    className="pnt-table table-clickable"
                    style={{
                        height: paging ? 'calc(100% - 30px)' : '100%',
                        width: '100%',
                        color: '#585252',
                        fontSize: '.8rem',
                    }}
                >
                    <div className={'border-box'} style={{ overflow: 'auto hidden' }}>
                        <div className={'thead'} style={{ overflow: 'visible' }}>
                            {headerGroups.map(headerGroup => {
                                const { style: thGroupStyle, ...thGroupProps } = headerGroup.getHeaderGroupProps();
                                return (
                                    <div {...thGroupProps} className="tr" style={{ ...thGroupStyle, width: '100%' }}>
                                        {headerGroup.headers.map(column => {
                                            const { getHeaderProps, headerStyle, headerClassName, render } = column;
                                            const { style: thStyle, ...thProps } = getHeaderProps({
                                                style: headerStyle,
                                            });
                                            return (
                                                <div
                                                    {...thProps}
                                                    className={cx('th', headerClassName)}
                                                    style={{
                                                        ...thStyle,
                                                        flex: `${thStyle.width
                                                            .replace('px', '')
                                                            .replace('%', '')} 0 auto`,
                                                    }}
                                                >
                                                    {render('Header')}
                                                </div>
                                            );
                                        })}
                                    </div>
                                );
                            })}
                        </div>
                        <div
                            {...getTableBodyProps()}
                            className={'tbody'}
                            style={{
                                height: `calc(100% - ${DEFAULT_TH_ROW_HEIGHT}px)`,
                                overflow: `${page.length > 0 ? 'visible' : 'hidden'}`,
                            }}
                        >
                            {page.length > 0 ? (
                                <AutoSizer>
                                    {({ height, width }) => {
                                        return (
                                            <div
                                                style={{
                                                    height,
                                                    width: width > totalColumnsWidth ? width : totalColumnsWidth,
                                                    overflow: 'hidden overlay',
                                                }}
                                            >
                                                {page.map(row =>
                                                    RenderRow({
                                                        row,
                                                        style: {
                                                            width:
                                                                width > totalColumnsWidth ? width : totalColumnsWidth,
                                                        },
                                                    }),
                                                )}
                                            </div>
                                        );
                                    }}
                                </AutoSizer>
                            ) : (
                                <NoDataBlock text={noDataMessage} />
                            )}
                        </div>
                    </div>
                </div>
            </TableWrapper>
        </LoadingBlock>
    );
}

const TableWrapper = styled.div`
    height: 100%;
    .pagination {
        display: flex;
        padding-left: 0;
        list-style: none;
    }
    .pnt-table {
        display: inline-block;
        border-spacing: 0;
        .border-box {
            height: 100%;
            border-top: 2px solid black;
            border-bottom: 2px solid black;

            .tbody {
                .tr {
                    :nth-child(2n) {
                        background-color: rgba(0, 0, 0, 0.02);
                    }
                    :hover {
                        background-color: #ebf0fa;
                    }
                }
                .tr.clickable {
                    :hover {
                        cursor: pointer;
                    }
                }
            }
            .tr {
                :last-child {
                    .td {
                        border-bottom: 0;
                    }
                }
            }
            .th {
                font-weight: 700;
                background-color: #d8e0fe;
                text-align: ${props => props.textAlign};
            }
            .td {
                text-align: ${props => props.textAlign};
            }
            .th,
            .td {
                margin: 0;
                padding: 0.5rem;
                border-bottom: 1px solid #ebebeb;
            }
        }
    }
`;

export default Table;
