import { createSlice } from '@reduxjs/toolkit';

import moment from 'moment';

const now = moment().unix();

export const initialState = {
    floorInfo: {
        floorId: '',
    },
    geofenceInfo: [],
    playerState: 'real-time',
    play: false,
    speed: 1,
    playTime: now,
    startTime: now,
    endTime: now + 1,
};

const { actions, reducer } = createSlice({
    name: 'smartMilitary/drowning',
    initialState,
    reducers: {
        setFloorInfo: (state, action) => {
            state.floorInfo = action.payload;
        },
        setGeofenceInfo: (state, action) => {
            state.geofenceInfo = action.payload;
        },
        setPlayerState: (state, action) => {
            state.playerState = action.payload;
        },
        setInitPlayerState: (state, action) => {
            const { startTime, endTime } = action.payload;

            state.play = false;
            state.speed = 1;
            state.playTime = startTime;
            state.startTime = startTime;
            state.endTime = endTime;
        },
        setPlayTime: (state, action) => {
            // 특정 시간으로 이동
            if (action.payload) {
                state.playTime = action.payload;
            }

            // 1초 증가
            if (!action.payload && state.playTime < state.endTime) {
                state.playTime += 1;
            }
        },
        setPlay: (state, action) => {
            const { playTime, startTime, endTime, speed } = action.payload;
            if (playTime && playTime === endTime) {
                state.playTime = startTime;
            }
            state.play = true;
            if (speed) {
                state.speed = speed;
            }
        },
        setPause: (state, action) => {
            state.play = false;
            if (action.payload && action.payload.speed) {
                state.speed = action.payload.speed;
            }
        },
        setStartTime: (state, action) => {
            const { startTime } = action.payload;
            state.play = false;
            state.playTime = startTime;
        },
        setEndTime: (state, action) => {
            const { endTime } = action.payload;
            state.play = false;
            state.playTime = endTime;
        },
    },
});

export const {
    setFloorInfo,
    setGeofenceInfo,
    setPlayerState,
    setInitPlayerState,
    setPlayTime,
    setPlay,
    setPause,
    setStartTime,
    setEndTime,
} = actions;
export default reducer;
