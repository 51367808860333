export const MAX_SIZE = 12;

export const TWENTY_FOUR_HOURS = 24;
export const TWELVE_HOURS = 12;
export const SIXTY_MINUTES = 60;
export const ONE_THOUSAND = 1000;
export const TWENTY_FOUR_HOURS_TO_MINUTES = TWENTY_FOUR_HOURS * SIXTY_MINUTES;

export const padNumberToString = (number, length = 2, paddingChar = '0') => {
    if (typeof number !== 'number') return '';
    return number.toString().padStart(length, paddingChar);
};

export const convertToOnTime = num => {
    return padNumberToString(num) + ':00';
};

export const convertToOnTimeWithoutColon = num => {
    return padNumberToString(num) + '00';
};
