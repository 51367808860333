import React, { useEffect, useState } from 'react';
import Card from '../../Components/WidgetCard';
import { useSettings } from '../../util/useSettings';
import useMonitor from '../../util/useMonitor';
import { getRealtimeUtilizationStatisticsApi } from '@api/dashboard';
import { getFlowSchemeListInfoApi } from '@api/status';
import moment from 'moment';
import useAsync from '@hooks/useAsync';
import { useSelector } from 'react-redux';
import 'moment/locale/ko';
import RealtimeUtilization from './Components/RealtimeUtilization';

const defaultData = {
    code: 0,
    count: 0,
    result: 'success',
    rows: [
        {
            comNum: 1,
            displayColor: '#3E66FB',
            statusCount: 4,
            statusName: 'In-Use',
            statusNum: 100003,
        },
        {
            comNum: 1,
            displayColor: '#ccc53a',
            statusCount: 20,
            statusName: 'Available',
            statusNum: 100005,
        },
        {
            comNum: 1,
            displayColor: '#3acc5c',
            statusCount: 10,
            statusName: 'Available',
            statusNum: 100004,
        },
    ],
};
const tempData = {
    rows: [
        {
            comNum: 0,
            displayColor: '#3E66FB',
            statusCount: 0,
            statusName: '-',
            statusNum: 0,
        },
        {
            comNum: 0,
            displayColor: '#3acc5c',
            statusCount: 0,
            statusName: '-',
            statusNum: 1,
        },
        {
            comNum: 0,
            displayColor: '#ccc53a',
            statusCount: 0,
            statusName: '-',
            statusNum: 2,
        },
    ],
};
const RealtimeUtilizationWidget = ({ children, widgetInfo, ...restProps }) => {
    let { config } = widgetInfo;
    const { selectedOption } = useSettings(config);
    const [initialConfig, setInitialConfig] = useState(config);
    const [flowList, setFlowList] = useState([]);
    const [flowName, setFlowName] = useState('');
    const { mode } = useSelector(state => state.DashboardFrame);
    const { filterParam } = useSelector(state => state.FilterInfo);
    const { lang } = useSelector(state => state.UserInfo);

    const { promise: getTargetStatusFlowInfo } = useAsync({
        promise: getFlowSchemeListInfoApi,
        fixedParam: { isAll: 'Y' },
        resolve: res => {
            setFlowList(res.rows);
            if (res.rows.length) {
                setInitialConfig({
                    ...config,
                    settings: {
                        ...config.settings,
                        flowNum: res.rows[0].flowNum,
                    },
                });
                setFlowName(res.rows[0].flowName);
            }
        },
    });

    useEffect(() => {
        if (
            config.defaultSettings &&
            !config.defaultSettings.flowNum &&
            (!config.settings || (config.settings && !config.settings.flowNum))
        ) {
            getTargetStatusFlowInfo();
        } else {
            setFlowName(selectedOption && selectedOption.label);
        }
    }, []);

    const { data } = useMonitor({
        config: initialConfig,
        defaultData: mode === 'monitoring' ? tempData : defaultData,
        paramInfo: ['flowNum'],
        dynamicParam: filterParam,
        fetchData: getRealtimeUtilizationStatisticsApi,
        makeData: ({ newData }) => {
            const sortedData =
                newData.rows && newData.rows.length > 0 && newData.rows.sort((a, b) => b.statusCount - a.statusCount);
            return newData.rows && newData.rows.length > 0 ? sortedData : tempData.rows;
        },
    });

    return (
        <Card
            additionalTitle={' - ' + flowName}
            widgetInfo={widgetInfo}
            {...restProps}
            subTitle={moment().locale(lang).format('lll')}
        >
            <RealtimeUtilization data={data} />
            {children}
        </Card>
    );
};

export default RealtimeUtilizationWidget;
