import React, { useCallback } from 'react';
import { useTranslation as useTranslationOrigin } from 'react-i18next';

const useTranslation = (ns: string): Function => {
    const { t } = useTranslationOrigin();
    return useCallback(
        (text: string, overWriteNs: string = ns): string => {
            if (!overWriteNs) {
                return t(text);
            }
            const message = `${overWriteNs};${text}`;
            return message === t(message) ? text : t(message);
        },
        [t],
    );
};

export default useTranslation;
