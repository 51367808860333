import React from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import cx from 'classnames';

import Button from '@components/Button';
import useTranslation from '@hooks/useTranslation';

import styled from 'styled-components';

const SubTitle = styled.p`
    line-height: 1.7;
    font-size: 1rem;
    font-weight: 400;
    text-transform: capitalize;
    color: rgba(0, 0, 0, 0.6);
    margin: 0;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-wrap: normal;
    overflow: hidden;
`;

const ConfirmModal = ({
    initModal = false,
    toggleModal = function () {
        initModal = !initModal;
    },
    okCallback,
    cancelCallback,
    callbackParam,
    header = { title: '', subTitle: '', className: '' },
    confirmText = 'Are you sure?',
    removeCancel,
    removeOk,
    okClassName,
    okButtonTitle,
    className,
    ...restProps
}) => {
    const t = useTranslation('ConfirmModal');

    return (
        <Modal
            container={document.getElementsByClassName('app-container')[0]}
            className={cx('pnt-modal', className)}
            isOpen={initModal}
            toggle={toggleModal}
            {...restProps}
        >
            <ModalHeader className={header.className} toggle={toggleModal}>
                {header.title ? header.title : t('Confirm')}
                {!!header.subTitle && <SubTitle>{header.subTitle}</SubTitle>}
            </ModalHeader>
            <ModalBody>{typeof confirmText === 'string' ? t(confirmText) : confirmText}</ModalBody>
            <ModalFooter>
                {!removeCancel && (
                    <Button
                        className={'color-secondary'}
                        onClick={() => {
                            if (typeof cancelCallback === 'function') {
                                cancelCallback(callbackParam);
                            }
                            toggleModal();
                        }}
                    >
                        {t('Cancel')}
                    </Button>
                )}

                {!removeOk && (
                    <Button
                        className={cx(okClassName, 'btn-secondary')}
                        onClick={e => {
                            if (typeof okCallback === 'function') {
                                okCallback(callbackParam);
                            }
                            toggleModal();
                        }}
                    >
                        {okButtonTitle || t('OK')}
                    </Button>
                )}
            </ModalFooter>
        </Modal>
    );
};

export default ConfirmModal;
