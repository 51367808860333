import React, { useMemo, useEffect, useRef } from 'react';

import { ListGroup, ListGroupItem } from 'reactstrap';

import useTranslation from '@hooks/useTranslation';

import moment from 'moment';

const setScrollTop = (listEl, value) => {
    const selectedEl = listEl.querySelector(`button[value='${Number(value)}']`);
    if (selectedEl) {
        listEl.scrollTop = selectedEl.getBoundingClientRect().top - listEl.getBoundingClientRect().top;
    }
};

const MIN_HOUR = 0;
const MIN_MINUTE = 0;
const MAX_HOUR = 23;
const MAX_MINUTE = 59;

/**
 *  date : 입력한 날짜
 *  value : 입력한 시간
 *  minTime : 최소 시간
 *  maxTime : 최대 시간
 **/
const TimeInput = ({ date, value, onChange, minTime, maxTime, withoutMinute = false }) => {
    const t = useTranslation('DatePicker');
    const hourRef = useRef();
    const minRef = useRef();

    const timeOptions = useMemo(() => {
        const h = [];
        const m = [];

        for (let i = 0; i < 60; i++) {
            if (i < 24) {
                h.push(i);
            }
            m.push(i);
        }
        return { h, m };
    }, []);

    //Time for selected value
    const { selectedH, selectedM } = useMemo(() => {
        const valueArr = value.split(':');
        return {
            selectedH: parseInt(valueArr[0]),
            selectedM: parseInt(valueArr[1]),
        };
    }, [value]);

    // Time for maxTime
    const { maxH, maxM } = useMemo(() => {
        const result = { maxH: MAX_HOUR, maxM: MAX_MINUTE };

        const currentDate = moment(date).format('YYYYMMDD');
        const selectedMaxDate = moment(maxTime).format('YYYYMMDD');

        if (maxTime && currentDate === selectedMaxDate) {
            result.maxH = parseInt(moment(maxTime).format('HH'));
            result.maxM = parseInt(moment(maxTime).format('mm'));
        }
        return result;
    }, [date, maxTime]);

    // Time for minTime
    const { minH, minM } = useMemo(() => {
        const result = { minH: MIN_HOUR, minM: MIN_MINUTE };

        const currentDate = moment(date).format('YYYYMMDD');
        const selectedMinDate = moment(minTime).format('YYYYMMDD');

        if (minTime && currentDate === selectedMinDate) {
            result.minH = parseInt(moment(minTime).format('HH'));
            result.minM = parseInt(moment(minTime).format('mm'));
        }
        return result;
    }, [date, minTime]);

    useEffect(() => {
        if (value) {
            const [h, m] = value.split(':');
            const hourList = hourRef.current;
            const minList = minRef.current;
            if (hourList) {
                setScrollTop(hourList, h);
            }
            if (minList) {
                setScrollTop(minList, m);
            }
        }
    }, []);

    return (
        <>
            <div className={'react-datepicker__header react-datepicker__header--time'}>
                <div className={'react-datepicker-time__header'}>{t('Time')}</div>
                <div className={'time-label-box'}>
                    <div>{t('hour')}</div>
                    {!withoutMinute && <div>{t('min')}</div>}
                </div>
            </div>

            <div className="d-flex">
                <ListGroup vertical={'true'}>
                    <div className={'list-wrap'} ref={hourRef}>
                        {timeOptions.h.map(v => {
                            const isAfterMinHour = minH <= v;
                            const isBeforeMaxHour = maxH >= v;

                            const isDisabled = !(isAfterMinHour && isBeforeMaxHour);

                            return (
                                <ListGroupItem
                                    key={v}
                                    tag={'button'}
                                    value={v}
                                    action
                                    disabled={isDisabled}
                                    onClick={e => {
                                        const currValue = e.currentTarget.value;
                                        const valueArr = value.split(':');
                                        if (parseInt(currValue) === maxH && maxM < selectedM) {
                                            onChange(`${currValue}:${maxM}`);
                                        } else {
                                            valueArr.splice(0, 1, currValue);
                                            onChange(valueArr.join(':'));
                                        }
                                    }}
                                    active={Number(value.split(':')[0]) === v}
                                >
                                    {v}
                                </ListGroupItem>
                            );
                        })}
                    </div>
                </ListGroup>
                {!withoutMinute && (
                    <ListGroup vertical={'true'}>
                        <div className={'list-wrap'} ref={minRef}>
                            {timeOptions.m.map(v => {
                                const isMaxHourSelected = selectedH === maxH;
                                const isMinHourSelected = selectedH === minH;

                                const isBeforeMinMinute = v < minM;
                                const isAfterMaxMinute = maxM < v;

                                const isDisabled =
                                    (isMaxHourSelected && isAfterMaxMinute) || (isMinHourSelected && isBeforeMinMinute);

                                return (
                                    <ListGroupItem
                                        key={v}
                                        tag={'button'}
                                        value={v}
                                        action
                                        disabled={isDisabled}
                                        onClick={e => {
                                            const currValue = e.currentTarget.value;
                                            const valueArr = value.split(':');
                                            valueArr.splice(1, 1, currValue);
                                            if (maxH && maxH < selectedH) {
                                                onChange(`${maxH}:${maxM}`);
                                            } else {
                                                onChange(valueArr.join(':'));
                                            }
                                        }}
                                        active={Number(value.split(':')[1]) === v}
                                    >
                                        {v}
                                    </ListGroupItem>
                                );
                            })}
                        </div>
                    </ListGroup>
                )}
            </div>
        </>
        // <div style={{ height: '100%' }} onClick={handleChange}>
        //     {value}
        // </div>
    );
};

export default TimeInput;
