import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';

import { useAsync, useTranslation } from '@hooks';

import {
    getDashboardMonitoringApprovalStatusAPI,
    getDashboardMonitoringZoneStatisticsAPI,
    postDashboardMonitoringLocationApprovalAPI,
} from '@api/military';

import { Button, makeRotatedImageOverlay, ConfirmModal } from '@components';
import GeofenceLayer from './CustomGeofenceLayer';

import { FeatureGroup, LayersControl } from 'react-leaflet';
import Control from 'react-leaflet-control';

import CompoundCard from 'Components/Widgets/Components/CompoundCard';
import Map from 'Components/MainPages/Monitoring/Components/Map';
import L from 'leaflet';

import { useDispatch, useSelector } from 'react-redux';
import { setLocationApproval } from 'reducers/Navy';

import { IntegratedMonitorDispatchContext, IntegratedMonitorStatusContext } from '../index';
import { setFloorInfo } from '../IntegratedMonitorReducer';

import GeofenceLayerToast from './GeofenceLayerToast';
import PersonnelToast from './PersonnelToast';
import CustomPopup from './CustomPopup';
import CustomMarker from './CustomMarker';
import FloorSelect from './FloorSelect';

import cx from 'classnames';

// 국면 지오펜스
const ICON_HALF_SIZE = 6;

const ENTIRE_FLOOR = 'entire';

const IntegratedMap = () => {
    const t = useTranslation('Integrated Monitor');

    const mapRef = useRef(null);

    const { tagListByFloor } = useSelector(state => state.TagInfo);
    const { selectableFloorList } = useSelector(state => state.FloorInfo);
    const { locationApproval } = useSelector(state => state.Navy);
    const storeDispatch = useDispatch();

    const { floorInfo, geofenceList } = useContext(IntegratedMonitorStatusContext);
    const dispatch = useContext(IntegratedMonitorDispatchContext);

    const [selectedGeofence, setSelectedGeofence] = useState(null);

    const mapGeofenceList = useMemo(() => {
        if (!floorInfo) return [];

        const filteredGeofenceList = geofenceList.filter(({ floor }) => floor === floorInfo.floorId);
        if (floorInfo.floorId !== ENTIRE_FLOOR) return filteredGeofenceList;

        return filteredGeofenceList.map(geofence => {
            const floor = selectableFloorList.find(({ floorName }) => floorName === geofence.fcName);
            return {
                ...geofence,
                statusByFloor: floor && tagListByFloor[floor.floorId] ? tagListByFloor[floor.floorId].length : 0,
            };
        });
    }, [geofenceList, tagListByFloor, selectableFloorList]);

    const [approvalRequestModal, setApprovalRequestModal] = useState(false);
    const toggleApprovalRequestModal = () => {
        setApprovalRequestModal(prevState => !prevState);
    };

    const [approvalConfirmModal, setApprovalConfirmModal] = useState(false);
    const toggleApprovalConfirmModal = () => {
        setApprovalConfirmModal(prevState => !prevState);
    };

    const [requestSuccessModal, setRequestSuccessModal] = useState(false);
    const toggleRequestSuccessModal = () => {
        setRequestSuccessModal(prevState => !prevState);
    };

    const [approvalFailureModal, setApprovalFailureModal] = useState(false);
    const toggleApprovalFailureModal = () => {
        setApprovalFailureModal(prevState => !prevState);
    };

    const [isGeofenceLayerOpen, setIsGeofenceLayerOpen] = useState(false);
    const toggleGeofenceLayerToast = () => {
        if (floorInfo && floorInfo.floorId !== 'entire') {
            setIsGeofenceLayerOpen(!isGeofenceLayerOpen);
            if (selectedGeofence) {
                setSelectedGeofence(null);
            }
        }
    };

    const [personnelData, setPersonnelData] = useState({ count: 0, rows: [] });

    const [personnelToastData, setPersonnelToastData] = useState(null);
    const togglePersonnelToast = data => {
        if (!data) {
            setPersonnelToastData(null);
            return;
        }
        setPersonnelToastData(data);
    };

    // Popup
    const [selectedMarker, setSelectedMarker] = useState(null);

    const handleGeofenceClick = geofence => {
        if (floorInfo && floorInfo.floorId === 'entire') {
            const floor = selectableFloorList.find(({ floorName }) => floorName === geofence.fcName);
            if (!!floor) {
                dispatch(setFloorInfo(floor));
            }
            return;
        }

        if (selectedGeofence && selectedGeofence.fcNum === geofence.fcNum) {
            setSelectedGeofence(null);
            setIsGeofenceLayerOpen(false);
            return;
        }

        setIsGeofenceLayerOpen(true);
        setSelectedGeofence(geofence);

        getDashboardMonitoringZoneStatistics({
            floorId: floorInfo.floorId,
            fcName: geofence.fcName,
        });
    };

    const handleApproveRequest = () => {
        if (!locationApproval) {
            toggleApprovalRequestModal();
        } else {
            toggleApprovalConfirmModal();
        }
    };
    const handleDismissRequest = () => {
        if (locationApproval) {
            toggleApprovalRequestModal();
        } else {
            toggleApprovalConfirmModal();
        }
    };

    useAsync({
        promise: getDashboardMonitoringApprovalStatusAPI,
        immediate: true,
        resolve: res => {
            const { state } = res;
            storeDispatch(setLocationApproval(state === 'Y'));
        },
        reject: err => {
            console.error(err);
        },
    });

    const { promise: postDashboardMonitoringLocationApproval } = useAsync({
        promise: postDashboardMonitoringLocationApprovalAPI,
        resolve: () => {
            toggleRequestSuccessModal();
        },
        reject: err => {
            console.error(err);
            toggleApprovalFailureModal();
        },
    });

    const { promise: getDashboardMonitoringZoneStatistics } = useAsync({
        promise: getDashboardMonitoringZoneStatisticsAPI,
        resolve: res => {
            if (!res) {
                setPersonnelData({ count: 0, rows: [] });
                return;
            }
            const { rows, count } = res;
            setPersonnelData({ count: count || rows.length, rows });
        },
        reject: err => {
            console.error(err);
            setPersonnelData({ count: 0, rows: [] });
        },
    });

    useEffect(() => {
        if (mapRef.current) {
            const leafletElement = mapRef.current.leafletElement;
            if (leafletElement) {
                leafletElement.invalidateSize();
                if (floorInfo.bounds && floorInfo.bounds[0] && floorInfo.bounds[0][0]) {
                    leafletElement.fitBounds(floorInfo.bounds);
                }
            }
        }
    }, [floorInfo]);

    useEffect(() => {
        const debounce = setTimeout(() => {
            if (selectedGeofence) {
                const { floor: floorId, fcName } = selectedGeofence;
                getDashboardMonitoringZoneStatistics({ floorId, fcName });
            }
        }, 400);

        return () => clearTimeout(debounce);
    }, [tagListByFloor, selectedGeofence]);

    const clusteredTagList = useMemo(() => {
        if (!tagListByFloor[floorInfo.floorId]) return {};

        const leafletElement = mapRef.current.leafletElement;

        const clusteredList = {};
        const tagList = tagListByFloor[floorInfo.floorId].slice();

        while (!!tagList.length) {
            const tag = tagList.at(-1);
            const { lat, lng } = tag.location;
            const point = leafletElement.latLngToLayerPoint([lat, lng]);
            const filteredKeys = Object.keys(clusteredList).filter(data => {
                const key = data.split('+').map(Number);
                return L.bounds([
                    [key[0] - ICON_HALF_SIZE, key[1] - ICON_HALF_SIZE],
                    [key[0] + ICON_HALF_SIZE, key[1] + ICON_HALF_SIZE],
                ]).intersects([
                    [point.x - ICON_HALF_SIZE, point.y - ICON_HALF_SIZE],
                    [point.x + ICON_HALF_SIZE, point.y + ICON_HALF_SIZE],
                ]);
            });

            if (!!filteredKeys.length) {
                clusteredList[filteredKeys[0]].push(tag);
            } else {
                clusteredList[`${point.x}+${point.y}`] = [tag];
            }
            tagList.pop();
        }
        return clusteredList;
    }, [tagListByFloor, floorInfo]);

    const ConditionalMarker = () => {
        return Object.entries(clusteredTagList).map(([key, value]) => {
            if (value.length === 1) {
                const {
                    location,
                    targetProperties,
                    markerImgUrl,
                    target: { targetId },
                } = value[0];
                return (
                    <CustomMarker
                        key={targetId}
                        location={location}
                        data={!!targetProperties ? [targetProperties] : []}
                        markerImgUrl={markerImgUrl}
                        toggleModal={togglePersonnelToast}
                        onClick={() => {
                            if (targetProperties) setSelectedMarker(value);
                        }}
                    />
                );
            }

            const leafletElement = mapRef.current.leafletElement;
            const latLng = leafletElement.layerPointToLatLng(key.split('+').map(Number));
            return (
                <CustomMarker
                    key={value[0].target.targetId}
                    location={{ latLng }}
                    data={value.map(({ targetProperties }) => targetProperties).filter(v => !!v)}
                    markerImgUrl={null}
                    toggleModal={togglePersonnelToast}
                    onClick={() => setSelectedMarker(value)}
                />
            );
        });
    };

    return (
        <>
            <CompoundCard
                className="mt-1 d-flex flex-column"
                style={{ height: 'calc(100vh - 32rem)', minHeight: '25rem' }}
            >
                <CompoundCard.header>
                    <>
                        {t('Real-time Status')}
                        {!locationApproval && (
                            <div className="color-danger ms-3">{t('Location view permission required.')}</div>
                        )}
                    </>
                </CompoundCard.header>
                <CompoundCard.body style={{ width: '100%', height: '100%' }}>
                    <CompoundCard.body.plain>
                        <Map ref={mapRef} onPopupClose={() => setSelectedMarker(null)}>
                            <Control position="topleft">
                                <FloorSelect handleChange={() => setIsGeofenceLayerOpen(false)} />
                            </Control>
                            <Control position="topleft">
                                <div className="d-flex gap-2 me-5">
                                    <Button
                                        className={cx(
                                            'pnt-btn font-size-lg',
                                            locationApproval ? 'btn-secondary' : 'btn-lightgray',
                                        )}
                                        onClick={handleApproveRequest}
                                    >
                                        {t('Approve Request')}
                                    </Button>
                                    <Button
                                        className={cx(
                                            'pnt-btn font-size-lg',
                                            !locationApproval ? 'btn-secondary' : 'btn-lightgray',
                                        )}
                                        onClick={handleDismissRequest}
                                    >
                                        {t('Dismiss Request')}
                                    </Button>
                                </div>
                            </Control>

                            {makeRotatedImageOverlay(mapRef, floorInfo)}

                            <LayersControl position="topright">
                                <LayersControl.Overlay checked name={t('Geofence', 'Map')}>
                                    <GeofenceLayer
                                        selectedGeofence={selectedGeofence}
                                        geofenceList={mapGeofenceList}
                                        handleClick={handleGeofenceClick}
                                        statusByFloor={floorInfo.floorId === ENTIRE_FLOOR}
                                    />
                                </LayersControl.Overlay>
                            </LayersControl>

                            {/* Geofence Popup */}
                            {isGeofenceLayerOpen && (
                                <Control position="bottomleft">
                                    <div
                                        style={{
                                            width: '280px',
                                            height: 'calc(100vh - 40rem)',
                                            minHeight: '20rem',
                                        }}
                                    >
                                        <GeofenceLayerToast
                                            innerRef={mapRef}
                                            title={selectedGeofence ? selectedGeofence.fcName : ''}
                                            initModal={isGeofenceLayerOpen}
                                            toggleModal={toggleGeofenceLayerToast}
                                            data={personnelData}
                                        />
                                    </div>
                                </Control>
                            )}

                            {/* Real-Time Marker */}
                            {locationApproval &&
                                floorInfo &&
                                tagListByFloor[floorInfo.floorId] &&
                                tagListByFloor[floorInfo.floorId].length && (
                                    <FeatureGroup>
                                        <ConditionalMarker />
                                    </FeatureGroup>
                                )}

                            {locationApproval && !!selectedMarker && (
                                <CustomPopup selectedMarker={selectedMarker} toggleModal={togglePersonnelToast} />
                            )}

                            {/*Marker Popup */}
                            {!!personnelToastData && (
                                <Control position="topright">
                                    <div
                                        style={{
                                            width: '280px',
                                            height: 'calc(100vh - 40rem)',
                                            minHeight: '20rem',
                                            marginRight: '7rem',
                                        }}
                                    >
                                        <PersonnelToast
                                            innerRef={mapRef}
                                            title={selectedGeofence ? selectedGeofence.fcName : ''}
                                            initModal={!!personnelToastData}
                                            toggleModal={togglePersonnelToast}
                                            data={personnelToastData}
                                        />
                                    </div>
                                </Control>
                            )}
                        </Map>
                    </CompoundCard.body.plain>
                </CompoundCard.body>
            </CompoundCard>
            <ConfirmModal
                initModal={approvalRequestModal}
                toggleModal={toggleApprovalRequestModal}
                header={{ title: t('Confirm', 'ConfirmModal') }}
                confirmText={
                    locationApproval
                        ? t('Do you want to close the authorization?')
                        : t('Do you want to send an approval request?')
                }
                okCallback={() => {
                    postDashboardMonitoringLocationApproval({ type: locationApproval ? 'N' : 'Y' });
                }}
            />
            <ConfirmModal
                initModal={approvalConfirmModal}
                toggleModal={toggleApprovalConfirmModal}
                header={{ title: t('Confirm', 'ConfirmModal') }}
                confirmText={
                    locationApproval
                        ? t('Location view already accepted.')
                        : t('Location view approval has already ended.')
                }
                removeCancel
            />
            <ConfirmModal
                initModal={requestSuccessModal}
                toggleModal={toggleRequestSuccessModal}
                header={{ title: t('Confirm', 'ConfirmModal') }}
                confirmText={t('Your request was successful.', 'Patrol Search')}
                removeCancel
            />
            <ConfirmModal
                initModal={approvalFailureModal}
                toggleModal={toggleApprovalFailureModal}
                header={{ title: t('Fail', 'ConfirmModal') }}
                confirmText={t('The request failed.', 'ErrorHandler')}
                removeCancel
            />
        </>
    );
};

export default IntegratedMap;
