import styled from 'styled-components';

import CompoundCard from '../../Components/CompoundCard';

export const StateCardContainer = styled.div`
    display: grid;
    grid-template-columns: 2fr 7fr;
    gap: 0.25rem;
    height: calc(100% - 6rem);
    margin-top: 0.5rem;
    @media (max-width: 1200px) {
        display: unset;
        flex-direction: column;
        grid-template-columns: unset;
    }
`;

export const SelectContainer = styled.div`
    display: flex;
    gap: 1rem;
    @media (max-width: 1200px) {
        flex-direction: column;
    }
`;

// DetailsByFloor
export const DetailsByFloorContainer = styled(CompoundCard)`
    margin-top: 0.1rem;
    height: calc(100% - 17.5rem);
`;

export const DetailedContainer = styled.div`
    min-height: 180px;
    min-width: 310px;
    border: 1px solid rgb(229, 227, 243);
`;

export const DetailedHeaderWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
`;

export const DetailedHeader = styled.div`
    width: 50%;
    text-align: center;
    padding: 0.4rem;
    color: white !important;
    background-color: ${({ $bgColor }) => ($bgColor ? $bgColor : '#3e66fb')};
`;

export const DetailedBodyWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    min-height: 210px;
    height: calc(100% - 64px);
    overflow: overlay;
`;

export const DetailedBody = styled.div`
    height: fit-content;
    width: 50%;
    display: flex;
    flex-direction: column;
`;

export const DetailedContent = styled.div`
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    padding: 0.4rem;
    border: 1px solid rgb(229, 227, 243);
    border-top: unset;
`;

export const DetailedInputWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    margin-top: 0.5rem;
`;

export const DetailedInput = styled.div`
    width: 100%;
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: center;
`;

export const EntireContainer = styled.div`
    height: 100%;
    .thead > .tr > div {
        color: white !important;
        background-color: #3e66fb !important;
        :last-child {
            background-color: #ff3333 !important;
        }
    }

    .tbody {
        .td:nth-child(2) {
            color: #3e66fb !important;
        }

        .td:nth-child(3) {
            color: #ff3333 !important;
        }

        .td:nth-child(n + 2) {
            font-weight: bold !important;
        }
    }
`;

export const NoDataWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const NoDataComponent = styled.span`
    padding: 2rem;
    background-color: rgba(0, 0, 0, 0.1);
`;
