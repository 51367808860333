import React, { useContext, useEffect } from 'react';

import { useTranslation, useColumns, useAsync } from '@hooks';
import { getHealthCareListAPI, getHealthCareSensorListAPI, getHealthMonitoringSelectAPI } from '@api/military';

import CompoundCard from '../../../Components/CompoundCard';

import * as column from './column';

import { HealthCareMonitoringDispatchContext, HealthCareMonitoringStatusContext } from '../index';
import {
    setInitialSensorData,
    setSelectedTarget,
    setSensorData,
    setHealthCareList,
    setFilterOptions,
    setIsHealthCareListLoading,
} from '../healthCareMonitoringReducer';

import moment from 'moment';

const chartList = [
    { id: 'heartbeat', key: 'HEARTBEAT', label: 'Heart Beat' },
    { id: 'steps', key: 'STEP', label: 'Count Of Steps' },
    { id: 'sleep', key: 'SLEEP', label: 'Sleeping Time' },
    { id: 'calories', key: 'CALORIES', label: 'Calories' },
    { id: 'distance', key: 'DISTANCE', label: 'Distance' },
    { id: 'stress', key: 'STRESS', label: 'Stress Indicator' },
];

const selectList = ['rank', 'department', 'jobtitle', 'status'];

const HealthInformationMonitoringTable = () => {
    const t = useTranslation('Health Care Monitoring');

    const { startDate, endDate, healthCareList, filterOptions, selectedTarget, isHealthCareListLoading } = useContext(
        HealthCareMonitoringStatusContext,
    );
    const dispatch = useContext(HealthCareMonitoringDispatchContext);

    const { rank, department } = filterOptions;

    const columns = useColumns(
        [
            column.id(),
            column.name(),
            column.rank({
                formatTitle: ({ value }) => {
                    const label = rank && value ? rank.find(v => v.value === value)['label'] : null;
                    return label || value;
                },
                Cell: ({ value }) => {
                    const label = rank && value ? rank.find(v => v.value === value)['label'] : null;
                    return label || value;
                },
            }),
            column.department({
                formatTitle: ({ value }) => {
                    const label = department && value ? department.find(v => v.value === value)['label'] : null;
                    return label || value;
                },
                Cell: ({ value }) => {
                    const label = department && value ? department.find(v => v.value === value)['label'] : null;
                    return label || value;
                },
            }),
            column.state({
                formatTitle: ({ value }) => {
                    return value === 'Y' ? t('Agreement') : t('Disagreement');
                },
                Cell: ({ value }) => {
                    return value === 'Y' ? t('Agreement') : t('Disagreement');
                },
            }),
        ],
        t,
        [filterOptions],
    );

    const handleTrClick = row => {
        const { targetId } = row;

        if (selectedTarget && selectedTarget.targetId === targetId) {
            dispatch(setSelectedTarget(null));
        } else {
            dispatch(setSelectedTarget(row));
        }

        getHealthCareSensorList({
            targetId,
            startDate: moment(startDate).unix(),
            endDate: moment(endDate).unix(),
        });
    };

    const { state: healthCareListState } = useAsync({
        promise: getHealthCareListAPI,
        fixedParam: { isAll: 'Y' },
        immediate: true,
        resolve: res => {
            const { rows } = res;
            if (rows) {
                dispatch(setHealthCareList({ rows }));
            }
        },
        reject: err => {
            console.error(err);
        },
    });

    useEffect(() => {
        dispatch(setIsHealthCareListLoading(healthCareListState.isLoading));
    }, [healthCareListState.isLoading]);

    useAsync({
        promise: getHealthMonitoringSelectAPI,
        immediate: true,
        resolve: res => {
            const { inputVal } = res;

            selectList.forEach(element => {
                const list = [{ label: t('All'), value: 'all' }];
                inputVal[element].forEach(({ name, value }) => {
                    list.push({ label: name, value });
                });
                dispatch(setFilterOptions({ key: element, value: list }));
            });
        },
        reject: err => {
            console.error(err);
        },
    });

    const { promise: getHealthCareSensorList, state: healthCareSensorListState } = useAsync({
        promise: getHealthCareSensorListAPI,
        resolve: res => {
            const { rows } = res;
            if (!rows || !rows.length) {
                dispatch(setInitialSensorData());
                return;
            }
            const result = rows.reduce((acc, cur) => {
                const { regDate, dataMap } = cur;
                chartList.forEach(({ id, key }) => {
                    if (acc[id]) {
                        acc[id].push({ name: regDate, value: dataMap[key] });
                    } else {
                        acc[id] = [{ name: regDate, value: dataMap[key] }];
                    }
                });
                return acc;
            }, {});
            dispatch(setSensorData(result));
        },
        reject: err => {
            console.error(err);
        },
    });

    return (
        <CompoundCard className="d-flex flex-column" style={{ height: 'calc(100% - 33rem)' }}>
            <CompoundCard.header>{t('Health Information Monitoring')}</CompoundCard.header>
            <CompoundCard.body className="h-100">
                <CompoundCard.body.table
                    containerClassName="h-100"
                    columns={columns}
                    paging={false}
                    data={healthCareList}
                    onTrClick={handleTrClick}
                    selectedRow={selectedTarget}
                    selectedId="targetId"
                    loading={isHealthCareListLoading || healthCareSensorListState.isLoading}
                />
            </CompoundCard.body>
        </CompoundCard>
    );
};

export default HealthInformationMonitoringTable;
