import { useEffect, useRef, useState } from 'react';

interface UseIntervalProps {
    callback: Function;
    delay: number;
    params: Object;
    initial: boolean;
}

type SaveCallbackRef = Function | null;

const useInterval = ({ callback, delay, params, initial = true }: UseIntervalProps) => {
    const savedCallback = useRef<SaveCallbackRef>(null);
    // intervalState가 false이면 interval이 멈추고 true면 interval이 진행됨
    const [intervalState, setIntervalState] = useState(initial);

    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // intervalState를 toggle하는 함수
    const toggleDelay = () => {
        setIntervalState(!intervalState);
    };

    useEffect(() => {
        const interval = () => {
            if (typeof savedCallback.current === 'function') {
                savedCallback.current(params);
            }
        };

        if (intervalState) {
            const id = setInterval(interval, delay);

            return () => {
                clearInterval(id);
            };
        }
    }, [delay, intervalState, params]);

    return toggleDelay;
};

export default useInterval;
