import React, { useState } from 'react';
import Card from '../../Components/WidgetCard';
import { Row, Col, Table } from 'reactstrap';
import { useSettings } from '../../util/useSettings';
import useMonitor from '../../util/useMonitor';
import ReactResizeDetector from 'react-resize-detector';
import ResponsivePieChart from '@components/Charts/PieChart';
import { useTranslation } from 'react-i18next';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { fetchScannerListApi } from '@api/log';

// const defaultData = [
//     {
//         key: 'on',
//         name: 'On-Line',
//         value: 5,
//     },
//     {
//         key: 'off',
//         name: 'Off-Line',
//         value: 38,
//     },
// ];

const defaultData = {
    totalPage: 0,
    pageSize: 0,
    page: 1,
    totalCount: 37,
    rows: [
        {
            scannerName: 'S24',
            macAddr: '10:CE:A9:32:DD:43',
            scannerType: 'USB',
            floor: 'PNT',
            status: 'A',
            lastDate: 1599275756,
            scannerNum: 103814,
            comNum: 100278,
            majorVer: '23296',
            sid: '',
            lat: 37.507404,
            lng: 127.053527,
            strssi: 10,
            rssi: 0,
            srssi: 30,
            mrssi: -100,
            drssi: 100,
            hwmrssi: -100,
            exMeter: 15,
            calPoint: 4,
            maxSig: 30,
            maxBuf: 20,
            fwVer: '0',
            fwVerReal: '3.11',
            modDate: 1608018383,
            regDate: 1598596738,
            initDate: 1599275607,
            ip: '192.168.31.22',
            cpu: 0,
            memory: 0,
            gatewayNum: null,
            netFwVer: '0',
            netFwVerReal: '3.26',
            configSet: 0,
            sslUpdate: 0,
            firmChecksum: '0',
            netFirmChecksum: '0',
            sslChecksum: '0',
            ubUse: 0,
            ubUUID: '',
            ubMajor: null,
            ubMinor: null,
            ubInterval: null,
            ubTxPower: 0,
            usbBeaconSet: 0,
            uuids: '',
            bypassMacs: '',
            bypassSw: 0,
            pinNumber: '360018',
            wifiSsid: 'PNT_USB',
            wifiPassword: 'PNT100456',
            angle: -1,
            url: 'asia1-rtls.indoorplus.io',
        },
        {
            scannerName: 'S_Test',
            macAddr: '10:CE:A9:D6:8E:AD',
            scannerType: 'USB',
            floor: 'PNT',
            status: 'N',
            lastDate: 1614041481,
            scannerNum: 103793,
            comNum: 100278,
            majorVer: '23296',
            sid: '',
            lat: 37.507409,
            lng: 127.053735,
            strssi: 1,
            rssi: 0,
            srssi: 10,
            mrssi: -100,
            drssi: 100,
            hwmrssi: -100,
            exMeter: 4,
            calPoint: 4,
            maxSig: 30,
            maxBuf: 10,
            fwVer: '3.11',
            fwVerReal: '3.11',
            modDate: 1614041481,
            regDate: 1596094548,
            initDate: 1613718705,
            ip: '192.168.1.140',
            cpu: 0,
            memory: 0,
            gatewayNum: null,
            netFwVer: '3.26',
            netFwVerReal: '3.26',
            configSet: 0,
            sslUpdate: 0,
            firmChecksum: '25',
            netFirmChecksum: '18',
            sslChecksum: '0',
            ubUse: 0,
            ubUUID: '',
            ubMajor: null,
            ubMinor: null,
            ubInterval: null,
            ubTxPower: 0,
            usbBeaconSet: 0,
            uuids: '',
            bypassMacs: '',
            bypassSw: 1,
            pinNumber: '360018',
            wifiSsid: 'pntbiz2G_2',
            wifiPassword: 'pntbiz!@#123',
            angle: -1,
            url: 'asia1-rtls.indoorplus.io',
        },
        {
            scannerName: '스캐너2',
            macAddr: '10:CE:A9:0C:2A:AC',
            scannerType: 'USB',
            floor: 'PNT',
            status: 'A',
            lastDate: 1600315566,
            scannerNum: 103792,
            comNum: 100278,
            majorVer: '23296',
            sid: 'S1',
            lat: 37.507386,
            lng: 127.053545,
            strssi: 1,
            rssi: 1,
            srssi: 10,
            mrssi: -100,
            drssi: 100,
            hwmrssi: -100,
            exMeter: 4,
            calPoint: 4,
            maxSig: 30,
            maxBuf: 10,
            fwVer: '1',
            fwVerReal: '1',
            modDate: 1608018388,
            regDate: 1596094433,
            initDate: 1600315566,
            ip: '',
            cpu: 0,
            memory: 0,
            gatewayNum: null,
            netFwVer: '0',
            netFwVerReal: '0',
            configSet: 0,
            sslUpdate: 0,
            firmChecksum: '0',
            netFirmChecksum: '0',
            sslChecksum: '0',
            ubUse: 0,
            ubUUID: '',
            ubMajor: null,
            ubMinor: null,
            ubInterval: null,
            ubTxPower: 0,
            usbBeaconSet: 0,
            uuids: '',
            bypassMacs: '',
            bypassSw: 1,
            pinNumber: '360018',
            wifiSsid: 'pntbiz2G_2',
            wifiPassword: 'pntbiz!@#123',
            angle: -1,
            url: 'asia1-rtls.indoorplus.io',
        },
        {
            scannerName: 'asia1_ibeacon_test',
            macAddr: '10:CE:A9:32:DA:20',
            scannerType: 'USB',
            floor: 'PNT',
            status: 'A',
            lastDate: 1591177301,
            scannerNum: 103626,
            comNum: 100278,
            majorVer: '23296',
            sid: '',
            lat: 37.507386,
            lng: 127.053504,
            strssi: 10,
            rssi: 0,
            srssi: 30,
            mrssi: -100,
            drssi: 100,
            hwmrssi: -100,
            exMeter: 15,
            calPoint: 4,
            maxSig: 30,
            maxBuf: 20,
            fwVer: '0',
            fwVerReal: '3.1',
            modDate: 1608018370,
            regDate: 1591167415,
            initDate: 1591167431,
            ip: '192.168.31.53',
            cpu: 0,
            memory: 0,
            gatewayNum: null,
            netFwVer: '0',
            netFwVerReal: '2.11',
            configSet: 0,
            sslUpdate: 0,
            firmChecksum: '0',
            netFirmChecksum: '0',
            sslChecksum: '0',
            ubUse: 0,
            ubUUID: '',
            ubMajor: null,
            ubMinor: null,
            ubInterval: null,
            ubTxPower: 0,
            usbBeaconSet: 0,
            uuids: '',
            bypassMacs: '',
            bypassSw: 0,
            pinNumber: '360018',
            wifiSsid: 'PNT_USB',
            wifiPassword: 'PNT100456',
            angle: -1,
            url: 'asia1-rtls2.indoorplus.io',
        },
        {
            scannerName: 'Aruba02',
            macAddr: '48:4A:E9:C7:1F:8A',
            scannerType: 'Aruba',
            floor: 'PNT',
            status: 'A',
            lastDate: 1611292712,
            scannerNum: 103293,
            comNum: 100278,
            majorVer: '16169',
            sid: '',
            lat: 37.50731,
            lng: 127.053673,
            strssi: 10,
            rssi: 0,
            srssi: 30,
            mrssi: -100,
            drssi: 100,
            hwmrssi: -100,
            exMeter: 50,
            calPoint: 4,
            maxSig: 30,
            maxBuf: 20,
            fwVer: '0',
            fwVerReal: '0',
            modDate: 1611292712,
            regDate: 1578894131,
            initDate: 1611292712,
            ip: '',
            cpu: 0,
            memory: 0,
            gatewayNum: null,
            netFwVer: '0',
            netFwVerReal: '0',
            configSet: 0,
            sslUpdate: 0,
            firmChecksum: '0',
            netFirmChecksum: '0',
            sslChecksum: '0',
            ubUse: 0,
            ubUUID: '',
            ubMajor: null,
            ubMinor: null,
            ubInterval: null,
            ubTxPower: 4,
            usbBeaconSet: 0,
            uuids: '',
            bypassMacs: '',
            bypassSw: 0,
            pinNumber: '360018',
            wifiSsid: 'PNT_USB',
            wifiPassword: 'PNT100456',
            angle: -1,
            url: 'asia1-rtls.indoorplus.io',
        },
        {
            scannerName: 'Aruba01',
            macAddr: '48:4A:E9:C6:ED:E6',
            scannerType: 'Aruba',
            floor: 'PNT',
            status: 'A',
            lastDate: 1611292761,
            scannerNum: 103292,
            comNum: 100278,
            majorVer: '16169',
            sid: '',
            lat: 37.507313,
            lng: 127.053571,
            strssi: 10,
            rssi: 6,
            srssi: 30,
            mrssi: -100,
            drssi: 100,
            hwmrssi: -100,
            exMeter: 10,
            calPoint: 4,
            maxSig: 30,
            maxBuf: 20,
            fwVer: '0',
            fwVerReal: '0',
            modDate: 1611292761,
            regDate: 1578894105,
            initDate: 1611292761,
            ip: '',
            cpu: 0,
            memory: 0,
            gatewayNum: null,
            netFwVer: '0',
            netFwVerReal: '0',
            configSet: 0,
            sslUpdate: 0,
            firmChecksum: '0',
            netFirmChecksum: '0',
            sslChecksum: '0',
            ubUse: 0,
            ubUUID: '',
            ubMajor: null,
            ubMinor: null,
            ubInterval: null,
            ubTxPower: 4,
            usbBeaconSet: 0,
            uuids: '',
            bypassMacs: '',
            bypassSw: 0,
            pinNumber: '360018',
            wifiSsid: 'demo_pntbiz@pntbiz.com',
            wifiPassword: 'demodktldk1!',
            angle: -1,
            url: 'asia1-rtls.indoorplus.io',
        },
        {
            scannerName: 'LD00077',
            macAddr: '53:4F:4C:FF:00:77',
            scannerType: 'USB',
            floor: 'PNT',
            status: 'A',
            lastDate: 0,
            scannerNum: 103182,
            comNum: 100278,
            majorVer: '23296',
            sid: '',
            lat: 37.507433,
            lng: 127.05352,
            strssi: 10,
            rssi: 0,
            srssi: 30,
            mrssi: -100,
            drssi: 100,
            hwmrssi: -100,
            exMeter: 15,
            calPoint: 4,
            maxSig: 30,
            maxBuf: 20,
            fwVer: '0',
            fwVerReal: '0',
            modDate: 1608018415,
            regDate: 1576731265,
            initDate: 0,
            ip: '',
            cpu: 0,
            memory: 0,
            gatewayNum: null,
            netFwVer: '0',
            netFwVerReal: '',
            configSet: 0,
            sslUpdate: 0,
            firmChecksum: '0',
            netFirmChecksum: '0',
            sslChecksum: '0',
            ubUse: 0,
            ubUUID: '',
            ubMajor: null,
            ubMinor: null,
            ubInterval: null,
            ubTxPower: null,
            usbBeaconSet: 0,
            uuids: '',
            bypassMacs: '',
            bypassSw: 0,
            pinNumber: '360018',
            wifiSsid: 'PNT_USB',
            wifiPassword: 'PNT100456',
            angle: -1,
            url: 'asia1-rtls.indoorplus.io',
        },
        {
            scannerName: 'LD00076',
            macAddr: '53:4F:4C:FF:00:76',
            scannerType: 'USB',
            floor: 'PNT',
            status: 'A',
            lastDate: 0,
            scannerNum: 103181,
            comNum: 100278,
            majorVer: '23296',
            sid: '',
            lat: 37.507443,
            lng: 127.05352,
            strssi: 10,
            rssi: 0,
            srssi: 30,
            mrssi: -100,
            drssi: 100,
            hwmrssi: -100,
            exMeter: 15,
            calPoint: 4,
            maxSig: 30,
            maxBuf: 20,
            fwVer: '0',
            fwVerReal: '0',
            modDate: 1608018407,
            regDate: 1576731240,
            initDate: 0,
            ip: '',
            cpu: 0,
            memory: 0,
            gatewayNum: null,
            netFwVer: '0',
            netFwVerReal: '',
            configSet: 0,
            sslUpdate: 0,
            firmChecksum: '0',
            netFirmChecksum: '0',
            sslChecksum: '0',
            ubUse: 0,
            ubUUID: '',
            ubMajor: null,
            ubMinor: null,
            ubInterval: null,
            ubTxPower: null,
            usbBeaconSet: 0,
            uuids: '',
            bypassMacs: '',
            bypassSw: 0,
            pinNumber: '360018',
            wifiSsid: 'PNT_USB',
            wifiPassword: 'PNT100456',
            angle: -1,
            url: 'asia1-rtls.indoorplus.io',
        },
        {
            scannerName: 'LD00075',
            macAddr: '53:4F:4C:FF:00:75',
            scannerType: 'USB',
            floor: 'PNT',
            status: 'A',
            lastDate: 0,
            scannerNum: 103180,
            comNum: 100278,
            majorVer: '23296',
            sid: '',
            lat: 37.507443,
            lng: 127.053502,
            strssi: 10,
            rssi: 0,
            srssi: 30,
            mrssi: -100,
            drssi: 100,
            hwmrssi: -100,
            exMeter: 15,
            calPoint: 4,
            maxSig: 30,
            maxBuf: 20,
            fwVer: '0',
            fwVerReal: '0',
            modDate: 1608018404,
            regDate: 1576731212,
            initDate: 0,
            ip: '',
            cpu: 0,
            memory: 0,
            gatewayNum: null,
            netFwVer: '0',
            netFwVerReal: '',
            configSet: 0,
            sslUpdate: 0,
            firmChecksum: '0',
            netFirmChecksum: '0',
            sslChecksum: '0',
            ubUse: 0,
            ubUUID: '',
            ubMajor: null,
            ubMinor: null,
            ubInterval: null,
            ubTxPower: 4,
            usbBeaconSet: 0,
            uuids: '',
            bypassMacs: '',
            bypassSw: 0,
            pinNumber: '360018',
            wifiSsid: 'PNT_USB',
            wifiPassword: 'PNT100456',
            angle: -1,
            url: 'asia1-rtls.indoorplus.io',
        },
        {
            scannerName: 'LD00074',
            macAddr: '53:4F:4C:FF:00:74',
            scannerType: 'USB',
            floor: 'PNT',
            status: 'A',
            lastDate: 0,
            scannerNum: 103179,
            comNum: 100278,
            majorVer: '23296',
            sid: '',
            lat: 37.507433,
            lng: 127.053502,
            strssi: 10,
            rssi: 0,
            srssi: 30,
            mrssi: -100,
            drssi: 100,
            hwmrssi: -100,
            exMeter: 15,
            calPoint: 4,
            maxSig: 30,
            maxBuf: 20,
            fwVer: '0',
            fwVerReal: '0',
            modDate: 1608018410,
            regDate: 1576731180,
            initDate: 0,
            ip: '',
            cpu: 0,
            memory: 0,
            gatewayNum: null,
            netFwVer: '0',
            netFwVerReal: '',
            configSet: 0,
            sslUpdate: 0,
            firmChecksum: '0',
            netFirmChecksum: '0',
            sslChecksum: '0',
            ubUse: 0,
            ubUUID: '',
            ubMajor: null,
            ubMinor: null,
            ubInterval: null,
            ubTxPower: 4,
            usbBeaconSet: 0,
            uuids: '',
            bypassMacs: '',
            bypassSw: 0,
            pinNumber: '360018',
            wifiSsid: 'PNT_USB',
            wifiPassword: 'PNT100456',
            angle: -1,
            url: 'asia1-rtls.indoorplus.io',
        },
        {
            scannerName: 'S09',
            macAddr: '10:CE:A9:0C:26:0D',
            scannerType: 'USB',
            floor: 'PNT',
            status: 'A',
            lastDate: 1610529952,
            scannerNum: 103171,
            comNum: 100278,
            majorVer: '40100',
            sid: '',
            lat: 37.507339,
            lng: 127.053635,
            strssi: 10,
            rssi: -5,
            srssi: 10,
            mrssi: -100,
            drssi: 100,
            hwmrssi: -100,
            exMeter: 4,
            calPoint: 4,
            maxSig: 30,
            maxBuf: 10,
            fwVer: '0',
            fwVerReal: '3.05',
            modDate: 1610529952,
            regDate: 1574870210,
            initDate: 1610502067,
            ip: '192.168.60.9',
            cpu: 0,
            memory: 0,
            gatewayNum: null,
            netFwVer: '0',
            netFwVerReal: '3.06',
            configSet: 0,
            sslUpdate: 0,
            firmChecksum: '0',
            netFirmChecksum: '0',
            sslChecksum: '0',
            ubUse: 0,
            ubUUID: '',
            ubMajor: null,
            ubMinor: null,
            ubInterval: null,
            ubTxPower: 4,
            usbBeaconSet: 0,
            uuids: '',
            bypassMacs: '',
            bypassSw: 0,
            pinNumber: '360018',
            wifiSsid: 'PNT_USB',
            wifiPassword: 'PNT100456',
            angle: -1,
            url: 'asia1-rtls.indoorplus.io',
        },
        {
            scannerName: 'S30',
            macAddr: '58:7A:62:4D:39:7A',
            scannerType: 'USB',
            floor: 'PNT',
            status: 'A',
            lastDate: 1598872213,
            scannerNum: 103170,
            comNum: 100278,
            majorVer: '23296',
            sid: 'S1',
            lat: 37.507403,
            lng: 127.053504,
            strssi: 10,
            rssi: 0,
            srssi: 10,
            mrssi: -100,
            drssi: 100,
            hwmrssi: -100,
            exMeter: 4,
            calPoint: 4,
            maxSig: 30,
            maxBuf: 10,
            fwVer: '3.05',
            fwVerReal: '3.05',
            modDate: 1608018364,
            regDate: 1574858420,
            initDate: 1598872213,
            ip: '',
            cpu: 0,
            memory: 0,
            gatewayNum: null,
            netFwVer: '3.06',
            netFwVerReal: '3.06',
            configSet: 0,
            sslUpdate: 0,
            firmChecksum: '227',
            netFirmChecksum: '249',
            sslChecksum: '0',
            ubUse: 0,
            ubUUID: '',
            ubMajor: null,
            ubMinor: null,
            ubInterval: null,
            ubTxPower: 4,
            usbBeaconSet: 0,
            uuids: '',
            bypassMacs: '',
            bypassSw: 1,
            pinNumber: '360018',
            wifiSsid: 'PNT_USB',
            wifiPassword: 'PNT100456',
            angle: -1,
            url: 'asia1-rtls.indoorplus.io',
        },
        {
            scannerName: 'S29',
            macAddr: '58:7A:62:4D:39:7F',
            scannerType: 'USB',
            floor: 'PNT',
            status: 'A',
            lastDate: 1599631377,
            scannerNum: 103169,
            comNum: 100278,
            majorVer: '23296',
            sid: 'S1',
            lat: 37.507373,
            lng: 127.053711,
            strssi: 2,
            rssi: 3,
            srssi: 10,
            mrssi: -100,
            drssi: 100,
            hwmrssi: -100,
            exMeter: 2,
            calPoint: 4,
            maxSig: 30,
            maxBuf: 10,
            fwVer: '3.05',
            fwVerReal: '3.05',
            modDate: 1608018591,
            regDate: 1574858391,
            initDate: 1599229909,
            ip: '',
            cpu: 0,
            memory: 0,
            gatewayNum: null,
            netFwVer: '3.06',
            netFwVerReal: '3.06',
            configSet: 0,
            sslUpdate: 0,
            firmChecksum: '227',
            netFirmChecksum: '249',
            sslChecksum: '0',
            ubUse: 0,
            ubUUID: '',
            ubMajor: null,
            ubMinor: null,
            ubInterval: null,
            ubTxPower: 4,
            usbBeaconSet: 0,
            uuids: '',
            bypassMacs: '',
            bypassSw: 1,
            pinNumber: '360018',
            wifiSsid: 'PNT_USB',
            wifiPassword: 'PNT100456',
            angle: -1,
            url: 'dev-rtls.indoorplus.io',
        },
        {
            scannerName: 'S28',
            macAddr: '58:7A:62:4D:41:64',
            scannerType: 'USB',
            floor: 'PNT',
            status: 'A',
            lastDate: 1603092267,
            scannerNum: 103168,
            comNum: 100278,
            majorVer: '23296',
            sid: 'S1',
            lat: 37.507397,
            lng: 127.053711,
            strssi: 2,
            rssi: 0,
            srssi: 10,
            mrssi: -100,
            drssi: 100,
            hwmrssi: -100,
            exMeter: 4,
            calPoint: 4,
            maxSig: 30,
            maxBuf: 10,
            fwVer: '3.05',
            fwVerReal: '3.05',
            modDate: 1608018583,
            regDate: 1574858370,
            initDate: 1603092267,
            ip: '192.168.1.64',
            cpu: 0,
            memory: 0,
            gatewayNum: null,
            netFwVer: '3.06',
            netFwVerReal: '3.06',
            configSet: 0,
            sslUpdate: 0,
            firmChecksum: '227',
            netFirmChecksum: '249',
            sslChecksum: '0',
            ubUse: 0,
            ubUUID: '',
            ubMajor: null,
            ubMinor: null,
            ubInterval: null,
            ubTxPower: 4,
            usbBeaconSet: 0,
            uuids: '',
            bypassMacs: '',
            bypassSw: 1,
            pinNumber: '360018',
            wifiSsid: 'PNT_USB',
            wifiPassword: 'PNT100456',
            angle: -1,
            url: 'dev-rtls.indoorplus.io',
        },
        {
            scannerName: 'S27',
            macAddr: '10:CE:A9:32:C9:58',
            scannerType: 'USB',
            floor: 'PNT',
            status: 'A',
            lastDate: 1606899906,
            scannerNum: 103167,
            comNum: 100278,
            majorVer: '',
            sid: 'S1',
            lat: 37.507396,
            lng: 127.05367,
            strssi: 1,
            rssi: -1,
            srssi: 10,
            mrssi: -100,
            drssi: 100,
            hwmrssi: -100,
            exMeter: 4,
            calPoint: 4,
            maxSig: 30,
            maxBuf: 10,
            fwVer: '3.05',
            fwVerReal: '3.05',
            modDate: 1608018576,
            regDate: 1574858331,
            initDate: 1606898106,
            ip: '192.168.1.237',
            cpu: 0,
            memory: 0,
            gatewayNum: null,
            netFwVer: '3.06',
            netFwVerReal: '3.06',
            configSet: 0,
            sslUpdate: 0,
            firmChecksum: '227',
            netFirmChecksum: '249',
            sslChecksum: '0',
            ubUse: 0,
            ubUUID: '',
            ubMajor: null,
            ubMinor: null,
            ubInterval: null,
            ubTxPower: 4,
            usbBeaconSet: 0,
            uuids: '',
            bypassMacs: '',
            bypassSw: 0,
            pinNumber: '360018',
            wifiSsid: 'pntbiz2G_1',
            wifiPassword: 'pntbiz!@#123',
            angle: -1,
            url: 'asia1-rtls.indoorplus.io',
        },
        {
            scannerName: 'S26',
            macAddr: '10:CE:A9:32:C9:72',
            scannerType: 'USB',
            floor: 'PNT',
            status: 'A',
            lastDate: 1607486244,
            scannerNum: 103166,
            comNum: 100278,
            majorVer: '40100',
            sid: 'S1',
            lat: 37.507365,
            lng: 127.05367,
            strssi: 1,
            rssi: 0,
            srssi: 10,
            mrssi: -100,
            drssi: 100,
            hwmrssi: -100,
            exMeter: 4,
            calPoint: 4,
            maxSig: 30,
            maxBuf: 10,
            fwVer: '3.05',
            fwVerReal: '3.05',
            modDate: 1608018569,
            regDate: 1574858305,
            initDate: 1607413679,
            ip: '192.168.1.243',
            cpu: 0,
            memory: 0,
            gatewayNum: null,
            netFwVer: '3.06',
            netFwVerReal: '3.06',
            configSet: 0,
            sslUpdate: 0,
            firmChecksum: '227',
            netFirmChecksum: '249',
            sslChecksum: '0',
            ubUse: 0,
            ubUUID: '',
            ubMajor: null,
            ubMinor: null,
            ubInterval: null,
            ubTxPower: 4,
            usbBeaconSet: 0,
            uuids: '',
            bypassMacs: '',
            bypassSw: 0,
            pinNumber: '360018',
            wifiSsid: 'pntbiz2G_1',
            wifiPassword: 'pntbiz!@#123',
            angle: -1,
            url: 'asia1-rtls.indoorplus.io',
        },
        {
            scannerName: 'S23',
            macAddr: '58:7A:62:4D:39:54',
            scannerType: 'USB',
            floor: 'PNT',
            status: 'A',
            lastDate: 1601345783,
            scannerNum: 103163,
            comNum: 100278,
            majorVer: '23296',
            sid: 'S1',
            lat: 37.507397,
            lng: 127.053834,
            strssi: 1,
            rssi: 2,
            srssi: 10,
            mrssi: -100,
            drssi: 100,
            hwmrssi: -100,
            exMeter: 4,
            calPoint: 4,
            maxSig: 30,
            maxBuf: 10,
            fwVer: '0',
            fwVerReal: '3.11',
            modDate: 1608018641,
            regDate: 1574858192,
            initDate: 1601345783,
            ip: '192.168.1.79',
            cpu: 0,
            memory: 0,
            gatewayNum: null,
            netFwVer: '0',
            netFwVerReal: '3.2',
            configSet: 0,
            sslUpdate: 0,
            firmChecksum: '0',
            netFirmChecksum: '0',
            sslChecksum: '0',
            ubUse: 0,
            ubUUID: '',
            ubMajor: null,
            ubMinor: null,
            ubInterval: null,
            ubTxPower: 4,
            usbBeaconSet: 0,
            uuids: '',
            bypassMacs: '',
            bypassSw: 1,
            pinNumber: '360018',
            wifiSsid: 'PNT_USB',
            wifiPassword: 'PNT100456',
            angle: -1,
            url: 'asia1-rtls2.indoorplus.io',
        },
        {
            scannerName: 'S22',
            macAddr: '10:CE:A9:32:DD:3F',
            scannerType: 'USB',
            floor: 'PNT',
            status: 'A',
            lastDate: 1607486218,
            scannerNum: 103162,
            comNum: 100278,
            majorVer: '40100',
            sid: '',
            lat: 37.507397,
            lng: 127.053759,
            strssi: 10,
            rssi: -1,
            srssi: 10,
            mrssi: -100,
            drssi: 100,
            hwmrssi: -100,
            exMeter: 2,
            calPoint: 4,
            maxSig: 30,
            maxBuf: 10,
            fwVer: '3.05',
            fwVerReal: '3.11',
            modDate: 1608018601,
            regDate: 1574858160,
            initDate: 1607471186,
            ip: '192.168.1.119',
            cpu: 0,
            memory: 0,
            gatewayNum: null,
            netFwVer: '3.06',
            netFwVerReal: '3.2',
            configSet: 0,
            sslUpdate: 0,
            firmChecksum: '227',
            netFirmChecksum: '249',
            sslChecksum: '0',
            ubUse: 0,
            ubUUID: '',
            ubMajor: null,
            ubMinor: null,
            ubInterval: null,
            ubTxPower: 4,
            usbBeaconSet: 0,
            uuids: '',
            bypassMacs: '',
            bypassSw: 0,
            pinNumber: '360018',
            wifiSsid: 'pntbiz2G_1',
            wifiPassword: 'pntbiz!@#123',
            angle: -1,
            url: 'asia1-rtls.indoorplus.io',
        },
        {
            scannerName: 'S21',
            macAddr: '58:7A:62:4D:41:6B',
            scannerType: 'USB',
            floor: 'PNT',
            status: 'A',
            lastDate: 1582626036,
            scannerNum: 103161,
            comNum: 100278,
            majorVer: '23296',
            sid: 'S1',
            lat: 37.507404,
            lng: 127.053545,
            strssi: 3,
            rssi: 0,
            srssi: 10,
            mrssi: -100,
            drssi: 100,
            hwmrssi: -100,
            exMeter: 4,
            calPoint: 4,
            maxSig: 30,
            maxBuf: 10,
            fwVer: '3.05',
            fwVerReal: '3.05',
            modDate: 1608018395,
            regDate: 1574858136,
            initDate: 1582624239,
            ip: '',
            cpu: 0,
            memory: 0,
            gatewayNum: null,
            netFwVer: '3.06',
            netFwVerReal: '3.06',
            configSet: 0,
            sslUpdate: 0,
            firmChecksum: '227',
            netFirmChecksum: '249',
            sslChecksum: '0',
            ubUse: 0,
            ubUUID: '',
            ubMajor: null,
            ubMinor: null,
            ubInterval: null,
            ubTxPower: 4,
            usbBeaconSet: 0,
            uuids: '',
            bypassMacs: '',
            bypassSw: 0,
            pinNumber: '360018',
            wifiSsid: 'PNT_USB',
            wifiPassword: 'PNT100456',
            angle: -1,
            url: 'dev-rtls.indoorplus.io ',
        },
        {
            scannerName: 'S20',
            macAddr: '58:7A:62:4D:3C:9C',
            scannerType: 'USB',
            floor: 'PNT',
            status: 'A',
            lastDate: 1603091093,
            scannerNum: 103160,
            comNum: 100278,
            majorVer: '23296',
            sid: '',
            lat: 37.507367,
            lng: 127.053757,
            strssi: 10,
            rssi: -1,
            srssi: 10,
            mrssi: -100,
            drssi: 100,
            hwmrssi: -100,
            exMeter: 4,
            calPoint: 4,
            maxSig: 30,
            maxBuf: 10,
            fwVer: '3.05',
            fwVerReal: '3.11',
            modDate: 1608018612,
            regDate: 1574858113,
            initDate: 1603091093,
            ip: '192.168.1.87',
            cpu: 0,
            memory: 0,
            gatewayNum: null,
            netFwVer: '3.06',
            netFwVerReal: '3.19',
            configSet: 0,
            sslUpdate: 0,
            firmChecksum: '227',
            netFirmChecksum: '249',
            sslChecksum: '0',
            ubUse: 0,
            ubUUID: '',
            ubMajor: null,
            ubMinor: null,
            ubInterval: null,
            ubTxPower: 4,
            usbBeaconSet: 0,
            uuids: '',
            bypassMacs: '',
            bypassSw: 1,
            pinNumber: '360018',
            wifiSsid: 'PNT_USB',
            wifiPassword: 'PNT100456',
            angle: -1,
            url: 'dev-rtls.indoorplus.io',
        },
        {
            scannerName: 'S19',
            macAddr: '58:7A:62:4D:3C:84',
            scannerType: 'USB',
            floor: 'PNT',
            status: 'A',
            lastDate: 1581502090,
            scannerNum: 103159,
            comNum: 100278,
            majorVer: '23296',
            sid: 'S1',
            lat: 37.507336,
            lng: 127.053835,
            strssi: 4,
            rssi: 0,
            srssi: 10,
            mrssi: -100,
            drssi: 100,
            hwmrssi: -100,
            exMeter: 4,
            calPoint: 4,
            maxSig: 30,
            maxBuf: 10,
            fwVer: '3.05',
            fwVerReal: '3.05',
            modDate: 1608018633,
            regDate: 1574858061,
            initDate: 1581501644,
            ip: '',
            cpu: 0,
            memory: 0,
            gatewayNum: null,
            netFwVer: '3.06',
            netFwVerReal: '3.06',
            configSet: 0,
            sslUpdate: 0,
            firmChecksum: '227',
            netFirmChecksum: '249',
            sslChecksum: '0',
            ubUse: 0,
            ubUUID: '',
            ubMajor: null,
            ubMinor: null,
            ubInterval: null,
            ubTxPower: 4,
            usbBeaconSet: 0,
            uuids: '',
            bypassMacs: '',
            bypassSw: 1,
            pinNumber: '360018',
            wifiSsid: 'PNT_USB',
            wifiPassword: 'PNT100456',
            angle: -1,
            url: 'dev-rtls.indoorplus.io',
        },
        {
            scannerName: 'S18',
            macAddr: '10:CE:A9:32:E0:15',
            scannerType: 'USB',
            floor: 'PNT',
            status: 'A',
            lastDate: 1582618862,
            scannerNum: 103158,
            comNum: 100278,
            majorVer: '23296',
            sid: '',
            lat: 37.507342,
            lng: 127.053758,
            strssi: 10,
            rssi: 0,
            srssi: 10,
            mrssi: -100,
            drssi: 100,
            hwmrssi: -100,
            exMeter: 4,
            calPoint: 4,
            maxSig: 30,
            maxBuf: 10,
            fwVer: '3.05',
            fwVerReal: '3.05',
            modDate: 1608018619,
            regDate: 1574858016,
            initDate: 1582618625,
            ip: '',
            cpu: 0,
            memory: 0,
            gatewayNum: null,
            netFwVer: '3.06',
            netFwVerReal: '3.06',
            configSet: 0,
            sslUpdate: 0,
            firmChecksum: '227',
            netFirmChecksum: '249',
            sslChecksum: '0',
            ubUse: 0,
            ubUUID: '',
            ubMajor: null,
            ubMinor: null,
            ubInterval: null,
            ubTxPower: 4,
            usbBeaconSet: 0,
            uuids: '',
            bypassMacs: '',
            bypassSw: 1,
            pinNumber: '360018',
            wifiSsid: 'PNT_USB',
            wifiPassword: 'PNT100456',
            angle: -1,
            url: 'asia1-rtls.indoorplus.io',
        },
        {
            scannerName: 'S17',
            macAddr: '58:7A:62:4D:3F:04',
            scannerType: 'USB',
            floor: 'PNT',
            status: 'A',
            lastDate: 1582618564,
            scannerNum: 103157,
            comNum: 100278,
            majorVer: '23296',
            sid: 'S1',
            lat: 37.507311,
            lng: 127.053836,
            strssi: 3,
            rssi: 0,
            srssi: 10,
            mrssi: -100,
            drssi: 100,
            hwmrssi: -100,
            exMeter: 4,
            calPoint: 4,
            maxSig: 30,
            maxBuf: 10,
            fwVer: '3.05',
            fwVerReal: '3.05',
            modDate: 1608018625,
            regDate: 1574857805,
            initDate: 1582431270,
            ip: '',
            cpu: 0,
            memory: 0,
            gatewayNum: null,
            netFwVer: '3.06',
            netFwVerReal: '3.06',
            configSet: 0,
            sslUpdate: 0,
            firmChecksum: '227',
            netFirmChecksum: '249',
            sslChecksum: '0',
            ubUse: 0,
            ubUUID: '',
            ubMajor: null,
            ubMinor: null,
            ubInterval: null,
            ubTxPower: 4,
            usbBeaconSet: 0,
            uuids: '',
            bypassMacs: '',
            bypassSw: 1,
            pinNumber: '360018',
            wifiSsid: 'PNT_USB',
            wifiPassword: 'PNT100456',
            angle: -1,
            url: 'dev-rtls.indoorplus.io',
        },
        {
            scannerName: 'S15',
            macAddr: '10:CE:A9:32:E4:04',
            scannerType: 'USB',
            floor: 'PNT',
            status: 'A',
            lastDate: 1607486210,
            scannerNum: 103155,
            comNum: 100278,
            majorVer: '40100',
            sid: '',
            lat: 37.507338,
            lng: 127.053736,
            strssi: 10,
            rssi: 0,
            srssi: 30,
            mrssi: -100,
            drssi: 100,
            hwmrssi: -80,
            exMeter: 4,
            calPoint: 4,
            maxSig: 30,
            maxBuf: 10,
            fwVer: '3.05',
            fwVerReal: '3.05',
            modDate: 1608018563,
            regDate: 1574857601,
            initDate: 1607485508,
            ip: '192.168.31.52',
            cpu: 0,
            memory: 0,
            gatewayNum: null,
            netFwVer: '3.06',
            netFwVerReal: '3.06',
            configSet: 0,
            sslUpdate: 0,
            firmChecksum: '227',
            netFirmChecksum: '249',
            sslChecksum: '0',
            ubUse: 0,
            ubUUID: '',
            ubMajor: null,
            ubMinor: null,
            ubInterval: null,
            ubTxPower: 4,
            usbBeaconSet: 0,
            uuids: '',
            bypassMacs: '',
            bypassSw: 0,
            pinNumber: '360018',
            wifiSsid: 'PNT_USB',
            wifiPassword: 'PNT100456',
            angle: -1,
            url: 'asia1-rtls.indoorplus.io',
        },
        {
            scannerName: 'S14',
            macAddr: '58:7A:62:4D:43:36',
            scannerType: 'USB',
            floor: 'PNT',
            status: 'A',
            lastDate: 1601961703,
            scannerNum: 103154,
            comNum: 100278,
            majorVer: '23296',
            sid: '',
            lat: 37.507322,
            lng: 127.053735,
            strssi: 10,
            rssi: 0,
            srssi: 10,
            mrssi: -100,
            drssi: 100,
            hwmrssi: -100,
            exMeter: 4,
            calPoint: 4,
            maxSig: 30,
            maxBuf: 10,
            fwVer: '3.05',
            fwVerReal: '3.05',
            modDate: 1608018555,
            regDate: 1574857571,
            initDate: 1601961703,
            ip: '192.168.1.125',
            cpu: 0,
            memory: 0,
            gatewayNum: null,
            netFwVer: '3.06',
            netFwVerReal: '3.06',
            configSet: 0,
            sslUpdate: 0,
            firmChecksum: '227',
            netFirmChecksum: '249',
            sslChecksum: '0',
            ubUse: 0,
            ubUUID: '',
            ubMajor: null,
            ubMinor: null,
            ubInterval: null,
            ubTxPower: 4,
            usbBeaconSet: 0,
            uuids: '',
            bypassMacs: '',
            bypassSw: 1,
            pinNumber: '360018',
            wifiSsid: 'PNT_USB',
            wifiPassword: 'PNT100456',
            angle: -1,
            url: 'asia1-rtls2.indoorplus.io',
        },
        {
            scannerName: 'S13',
            macAddr: '10:CE:A9:32:C9:21',
            scannerType: 'USB',
            floor: 'PNT',
            status: 'A',
            lastDate: 1591953220,
            scannerNum: 103153,
            comNum: 100278,
            majorVer: '23296',
            sid: '',
            lat: 37.507307,
            lng: 127.053735,
            strssi: 10,
            rssi: 0,
            srssi: 30,
            mrssi: -100,
            drssi: 100,
            hwmrssi: -100,
            exMeter: 10,
            calPoint: 4,
            maxSig: 30,
            maxBuf: 10,
            fwVer: '3.05',
            fwVerReal: '3.05',
            modDate: 1608018547,
            regDate: 1574857546,
            initDate: 1591925561,
            ip: '192.168.1.75',
            cpu: 0,
            memory: 0,
            gatewayNum: null,
            netFwVer: '3.06',
            netFwVerReal: '3.06',
            configSet: 0,
            sslUpdate: 0,
            firmChecksum: '227',
            netFirmChecksum: '249',
            sslChecksum: '0',
            ubUse: 0,
            ubUUID: '',
            ubMajor: null,
            ubMinor: null,
            ubInterval: null,
            ubTxPower: 4,
            usbBeaconSet: 0,
            uuids: '',
            bypassMacs: '',
            bypassSw: 1,
            pinNumber: '360018',
            wifiSsid: 'PNT_USB',
            wifiPassword: 'PNT100456',
            angle: -1,
            url: 'asia1-rtls2.indoorplus.io',
        },
        {
            scannerName: 'S12',
            macAddr: '58:7A:62:4D:41:31',
            scannerType: 'USB',
            floor: 'PNT',
            status: 'A',
            lastDate: 1610529952,
            scannerNum: 103152,
            comNum: 100278,
            majorVer: '40100',
            sid: '',
            lat: 37.507339,
            lng: 127.053685,
            strssi: 10,
            rssi: 2,
            srssi: 10,
            mrssi: -100,
            drssi: 100,
            hwmrssi: -100,
            exMeter: 4,
            calPoint: 4,
            maxSig: 30,
            maxBuf: 4,
            fwVer: '3.05',
            fwVerReal: '3.05',
            modDate: 1610529952,
            regDate: 1574857514,
            initDate: 1610508413,
            ip: '192.168.60.5',
            cpu: 0,
            memory: 0,
            gatewayNum: null,
            netFwVer: '3.06',
            netFwVerReal: '3.06',
            configSet: 0,
            sslUpdate: 0,
            firmChecksum: '227',
            netFirmChecksum: '249',
            sslChecksum: '0',
            ubUse: 0,
            ubUUID: '',
            ubMajor: null,
            ubMinor: null,
            ubInterval: null,
            ubTxPower: 4,
            usbBeaconSet: 0,
            uuids: '',
            bypassMacs: '',
            bypassSw: 0,
            pinNumber: '360018',
            wifiSsid: 'pntbiz2G_2',
            wifiPassword: 'pntbiz!@#123',
            angle: -1,
            url: 'asia1-rtls.indoorplus.io',
        },
        {
            scannerName: 'S11',
            macAddr: '10:CE:A9:32:E0:77',
            scannerType: 'USB',
            floor: 'PNT',
            status: 'A',
            lastDate: 1607082861,
            scannerNum: 103151,
            comNum: 100278,
            majorVer: '23296',
            sid: '',
            lat: 37.507322,
            lng: 127.053685,
            strssi: 10,
            rssi: 2,
            srssi: 10,
            mrssi: -100,
            drssi: 100,
            hwmrssi: -100,
            exMeter: 4,
            calPoint: 4,
            maxSig: 30,
            maxBuf: 10,
            fwVer: '0',
            fwVerReal: '3.05',
            modDate: 1608018520,
            regDate: 1574857489,
            initDate: 1607079340,
            ip: '192.168.1.111',
            cpu: 0,
            memory: 0,
            gatewayNum: null,
            netFwVer: '0',
            netFwVerReal: '3.06',
            configSet: 0,
            sslUpdate: 0,
            firmChecksum: '0',
            netFirmChecksum: '0',
            sslChecksum: '0',
            ubUse: 0,
            ubUUID: '',
            ubMajor: null,
            ubMinor: null,
            ubInterval: null,
            ubTxPower: 4,
            usbBeaconSet: 0,
            uuids: '',
            bypassMacs: '',
            bypassSw: 1,
            pinNumber: '360018',
            wifiSsid: 'PNT_USB',
            wifiPassword: 'PNT100456',
            angle: -1,
            url: 'asia1-rtls2.indoorplus.io',
        },
        {
            scannerName: 'S10',
            macAddr: '58:7A:62:4D:39:36',
            scannerType: 'USB',
            floor: 'PNT',
            status: 'A',
            lastDate: 1609743326,
            scannerNum: 103150,
            comNum: 100278,
            majorVer: '40100',
            sid: '',
            lat: 37.507307,
            lng: 127.053685,
            strssi: 10,
            rssi: 0,
            srssi: 10,
            mrssi: -100,
            drssi: 100,
            hwmrssi: -100,
            exMeter: 4,
            calPoint: 4,
            maxSig: 30,
            maxBuf: 10,
            fwVer: '3.05',
            fwVerReal: '3.05',
            modDate: 1609743326,
            regDate: 1574857460,
            initDate: 1609732908,
            ip: '192.168.60.65',
            cpu: 0,
            memory: 0,
            gatewayNum: null,
            netFwVer: '3.06',
            netFwVerReal: '3.06',
            configSet: 0,
            sslUpdate: 0,
            firmChecksum: '227',
            netFirmChecksum: '249',
            sslChecksum: '0',
            ubUse: 0,
            ubUUID: '',
            ubMajor: null,
            ubMinor: null,
            ubInterval: null,
            ubTxPower: 4,
            usbBeaconSet: 0,
            uuids: '',
            bypassMacs: '',
            bypassSw: 0,
            pinNumber: '360018',
            wifiSsid: 'PNT_USB',
            wifiPassword: 'PNT100456',
            angle: -1,
            url: 'asia1-rtls.indoorplus.io',
        },
        {
            scannerName: 'S08',
            macAddr: '10:CE:A9:32:C3:55',
            scannerType: 'USB',
            floor: 'PNT',
            status: 'A',
            lastDate: 1598945387,
            scannerNum: 103148,
            comNum: 100278,
            majorVer: '23296',
            sid: '',
            lat: 37.507322,
            lng: 127.053635,
            strssi: 10,
            rssi: 0,
            srssi: 30,
            mrssi: -100,
            drssi: 100,
            hwmrssi: -100,
            exMeter: 4,
            calPoint: 4,
            maxSig: 30,
            maxBuf: 10,
            fwVer: '3.05',
            fwVerReal: '3.05',
            modDate: 1608018489,
            regDate: 1574857404,
            initDate: 1598945222,
            ip: '',
            cpu: 0,
            memory: 0,
            gatewayNum: null,
            netFwVer: '3.06',
            netFwVerReal: '3.06',
            configSet: 0,
            sslUpdate: 0,
            firmChecksum: '227',
            netFirmChecksum: '249',
            sslChecksum: '0',
            ubUse: 0,
            ubUUID: '',
            ubMajor: null,
            ubMinor: null,
            ubInterval: null,
            ubTxPower: 4,
            usbBeaconSet: 0,
            uuids: '',
            bypassMacs: '',
            bypassSw: 1,
            pinNumber: '360018',
            wifiSsid: 'PNT_USB',
            wifiPassword: 'PNT100456',
            angle: -1,
            url: 'dev-rtls.indoorplus.io ',
        },
        {
            scannerName: 'S07',
            macAddr: '58:7A:62:4D:39:4A',
            scannerType: 'USB',
            floor: 'PNT',
            status: 'A',
            lastDate: 1599494064,
            scannerNum: 103147,
            comNum: 100278,
            majorVer: '23296',
            sid: '',
            lat: 37.507309,
            lng: 127.053634,
            strssi: 10,
            rssi: 0,
            srssi: 10,
            mrssi: -100,
            drssi: 100,
            hwmrssi: -100,
            exMeter: 4,
            calPoint: 4,
            maxSig: 30,
            maxBuf: 10,
            fwVer: '0',
            fwVerReal: '3.05',
            modDate: 1608018480,
            regDate: 1574857365,
            initDate: 1599494009,
            ip: '192.168.1.84',
            cpu: 0,
            memory: 0,
            gatewayNum: null,
            netFwVer: '0',
            netFwVerReal: '3.06',
            configSet: 0,
            sslUpdate: 0,
            firmChecksum: '0',
            netFirmChecksum: '0',
            sslChecksum: '0',
            ubUse: 0,
            ubUUID: '',
            ubMajor: null,
            ubMinor: null,
            ubInterval: null,
            ubTxPower: 4,
            usbBeaconSet: 0,
            uuids: '',
            bypassMacs: '',
            bypassSw: 1,
            pinNumber: '360018',
            wifiSsid: 'PNT_USB',
            wifiPassword: 'PNT100456',
            angle: -1,
            url: 'asia1-rtls2.indoorplus.io',
        },
        {
            scannerName: 'S06',
            macAddr: '58:7A:62:4D:3C:DF',
            scannerType: 'USB',
            floor: 'PNT',
            status: 'A',
            lastDate: 1610529976,
            scannerNum: 103146,
            comNum: 100278,
            majorVer: '40100',
            sid: '',
            lat: 37.50734,
            lng: 127.053581,
            strssi: 10,
            rssi: 0,
            srssi: 10,
            mrssi: -100,
            drssi: 100,
            hwmrssi: -100,
            exMeter: 4,
            calPoint: 4,
            maxSig: 30,
            maxBuf: 10,
            fwVer: '0',
            fwVerReal: '3.05',
            modDate: 1610529976,
            regDate: 1574857331,
            initDate: 1610501847,
            ip: '192.168.60.7',
            cpu: 0,
            memory: 0,
            gatewayNum: null,
            netFwVer: '0',
            netFwVerReal: '3.06',
            configSet: 0,
            sslUpdate: 0,
            firmChecksum: '0',
            netFirmChecksum: '0',
            sslChecksum: '0',
            ubUse: 0,
            ubUUID: '',
            ubMajor: null,
            ubMinor: null,
            ubInterval: null,
            ubTxPower: 4,
            usbBeaconSet: 0,
            uuids: '',
            bypassMacs: '',
            bypassSw: 0,
            pinNumber: '360018',
            wifiSsid: 'PNT_USB',
            wifiPassword: 'PNT100456',
            angle: -1,
            url: 'asia1-rtls.indoorplus.io',
        },
        {
            scannerName: 'S05',
            macAddr: '10:CE:A9:32:D0:DD',
            scannerType: 'USB',
            floor: 'PNT',
            status: 'A',
            lastDate: 1606468545,
            scannerNum: 103145,
            comNum: 100278,
            majorVer: '',
            sid: '',
            lat: 37.507326,
            lng: 127.053581,
            strssi: 10,
            rssi: 0,
            srssi: 30,
            mrssi: -100,
            drssi: 100,
            hwmrssi: -100,
            exMeter: 4,
            calPoint: 4,
            maxSig: 30,
            maxBuf: 10,
            fwVer: '0',
            fwVerReal: '3.09',
            modDate: 1608018458,
            regDate: 1574857247,
            initDate: 1606378085,
            ip: '192.168.31.199',
            cpu: 0,
            memory: 0,
            gatewayNum: null,
            netFwVer: '0',
            netFwVerReal: '2.11',
            configSet: 0,
            sslUpdate: 0,
            firmChecksum: '0',
            netFirmChecksum: '0',
            sslChecksum: '0',
            ubUse: 0,
            ubUUID: '',
            ubMajor: null,
            ubMinor: null,
            ubInterval: null,
            ubTxPower: 4,
            usbBeaconSet: 0,
            uuids: '',
            bypassMacs: '',
            bypassSw: 1,
            pinNumber: '360018',
            wifiSsid: 'PNT_USB',
            wifiPassword: 'PNT100456',
            angle: -1,
            url: 'asia1-rtls.indoorplus.io',
        },
        {
            scannerName: 'S04',
            macAddr: '10:CE:A9:0C:2A:93',
            scannerType: 'USB',
            floor: 'PNT',
            status: 'A',
            lastDate: 1609840946,
            scannerNum: 103144,
            comNum: 100278,
            majorVer: '40100',
            sid: '',
            lat: 37.507304,
            lng: 127.053588,
            strssi: 10,
            rssi: 0,
            srssi: 10,
            mrssi: -100,
            drssi: 100,
            hwmrssi: -100,
            exMeter: 4,
            calPoint: 4,
            maxSig: 30,
            maxBuf: 10,
            fwVer: '3.05',
            fwVerReal: '3.05',
            modDate: 1609840946,
            regDate: 1574857187,
            initDate: 1609840946,
            ip: '192.168.31.53',
            cpu: 0,
            memory: 0,
            gatewayNum: null,
            netFwVer: '3.06',
            netFwVerReal: '3.06',
            configSet: 0,
            sslUpdate: 0,
            firmChecksum: '227',
            netFirmChecksum: '249',
            sslChecksum: '0',
            ubUse: 0,
            ubUUID: '',
            ubMajor: null,
            ubMinor: null,
            ubInterval: null,
            ubTxPower: 4,
            usbBeaconSet: 0,
            uuids: '',
            bypassMacs: '',
            bypassSw: 0,
            pinNumber: '360018',
            wifiSsid: 'PNT_USB',
            wifiPassword: 'PNT100456',
            angle: -1,
            url: 'asia1-rtls.indoorplus.io',
        },
        {
            scannerName: 'S03',
            macAddr: '10:CE:A9:32:E0:73',
            scannerType: 'USB',
            floor: 'PNT',
            status: 'A',
            lastDate: 1599494064,
            scannerNum: 103143,
            comNum: 100278,
            majorVer: '23296',
            sid: '',
            lat: 37.50734,
            lng: 127.053531,
            strssi: 7,
            rssi: 0,
            srssi: 10,
            mrssi: -100,
            drssi: 100,
            hwmrssi: -100,
            exMeter: 4,
            calPoint: 4,
            maxSig: 30,
            maxBuf: 10,
            fwVer: '3.05',
            fwVerReal: '3.05',
            modDate: 1608018436,
            regDate: 1574857152,
            initDate: 1599494009,
            ip: '',
            cpu: 0,
            memory: 0,
            gatewayNum: null,
            netFwVer: '3.06',
            netFwVerReal: '3.06',
            configSet: 0,
            sslUpdate: 0,
            firmChecksum: '227',
            netFirmChecksum: '249',
            sslChecksum: '0',
            ubUse: 0,
            ubUUID: '',
            ubMajor: null,
            ubMinor: null,
            ubInterval: null,
            ubTxPower: 4,
            usbBeaconSet: 0,
            uuids: '',
            bypassMacs: '',
            bypassSw: 1,
            pinNumber: '360018',
            wifiSsid: 'PNT_USB',
            wifiPassword: 'PNT100456',
            angle: -1,
            url: 'asia1-rtls.indoorplus.io',
        },
        {
            scannerName: 'S02',
            macAddr: '58:7A:62:4D:3C:9A',
            scannerType: 'USB',
            floor: 'PNT',
            status: 'A',
            lastDate: 1578628346,
            scannerNum: 103142,
            comNum: 100278,
            majorVer: '23296',
            sid: '',
            lat: 37.507325,
            lng: 127.053531,
            strssi: 10,
            rssi: 0,
            srssi: 10,
            mrssi: -100,
            drssi: 100,
            hwmrssi: -100,
            exMeter: 4,
            calPoint: 4,
            maxSig: 30,
            maxBuf: 10,
            fwVer: '3.05',
            fwVerReal: '3.05',
            modDate: 1608018432,
            regDate: 1574857127,
            initDate: 1578628306,
            ip: '',
            cpu: 0,
            memory: 0,
            gatewayNum: null,
            netFwVer: '3.06',
            netFwVerReal: '3.06',
            configSet: 0,
            sslUpdate: 0,
            firmChecksum: '227',
            netFirmChecksum: '249',
            sslChecksum: '0',
            ubUse: 0,
            ubUUID: '',
            ubMajor: null,
            ubMinor: null,
            ubInterval: null,
            ubTxPower: 4,
            usbBeaconSet: 0,
            uuids: '',
            bypassMacs: '',
            bypassSw: 1,
            pinNumber: '360018',
            wifiSsid: 'PNT_USB',
            wifiPassword: 'PNT100456',
            angle: -1,
            url: 'asia1-rtls.indoorplus.io',
        },
        {
            scannerName: 'S01',
            macAddr: '10:CE:A9:31:D2:1A',
            scannerType: 'USB',
            floor: 'PNT',
            status: 'A',
            lastDate: 1578637923,
            scannerNum: 103141,
            comNum: 100278,
            majorVer: '23296',
            sid: '',
            lat: 37.507304,
            lng: 127.053537,
            strssi: 7,
            rssi: 0,
            srssi: 10,
            mrssi: -100,
            drssi: 100,
            hwmrssi: -100,
            exMeter: 4,
            calPoint: 4,
            maxSig: 30,
            maxBuf: 10,
            fwVer: '3.05',
            fwVerReal: '3.05',
            modDate: 1608018424,
            regDate: 1574857096,
            initDate: 1578637923,
            ip: '',
            cpu: 0,
            memory: 0,
            gatewayNum: null,
            netFwVer: '3.06',
            netFwVerReal: '3.06',
            configSet: 0,
            sslUpdate: 0,
            firmChecksum: '227',
            netFirmChecksum: '249',
            sslChecksum: '0',
            ubUse: 0,
            ubUUID: '',
            ubMajor: null,
            ubMinor: null,
            ubInterval: null,
            ubTxPower: 4,
            usbBeaconSet: 0,
            uuids: '',
            bypassMacs: '',
            bypassSw: 1,
            pinNumber: '360018',
            wifiSsid: 'PNT_USB',
            wifiPassword: 'PNT100456',
            angle: -1,
            url: 'asia1-rtls.indoorplus.io',
        },
    ],
};

const ScannerStatus = ({ children, widgetInfo, ...restProps }) => {
    const { config } = widgetInfo;
    const { t } = useTranslation();
    const settings = useSettings(config);
    const [tableHeight, setTableHeight] = useState();

    const { data } = useMonitor({
        config,
        defaultData,
        fetchData: fetchScannerListApi,
        makeData: ({ newData }) => {
            const scannerList = newData.rows;
            const chartData = [];
            chartData.push({
                key: 'on',
                name: t('ScannerStatus;On-Line'),
                value: scannerList ? scannerList.filter(v => v.status === 'N').length : 0,
            });
            chartData.push({
                key: 'off',
                name: t('ScannerStatus;Off-Line'),
                value: scannerList ? scannerList.filter(v => v.status === 'A').length : 0,
            });
            return chartData;
        },
    });

    // const { ToggleLegendRender, filteredData, disable } = useToggleLegend({ data });

    return (
        <Card widgetInfo={widgetInfo} {...restProps}>
            <div className={'scrollbar-container'}>
                <PerfectScrollbar>
                    <Row style={{ height: '15rem' }}>
                        <ResponsivePieChart data={data} settings={settings} />
                    </Row>
                    <Row>
                        <ReactResizeDetector
                            handleHeight
                            onResize={(width, height) => {
                                setTableHeight(height);
                            }}
                        >
                            <Col>
                                {t('ScannerStatus;Current / Count')}
                                <Table className={'mb-0'}>
                                    <thead>
                                        <tr>
                                            <th className={'text-center'} style={{ width: '33.3%' }}>
                                                {t('ScannerStatus;All Scanner')}
                                            </th>
                                            <th className={'text-center'} style={{ width: '33.3%' }}>
                                                {t('ScannerStatus;On-Line')}
                                            </th>
                                            <th className={'text-center'} style={{ width: '33.3%' }}>
                                                {t('ScannerStatus;Off-Line')}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className={'text-center'} style={{ width: '33.3%' }}>
                                                {data[0].value + data[1].value}
                                            </td>
                                            <td className={'text-center'} style={{ width: '33.3%' }}>
                                                {data[0].value}
                                            </td>
                                            <td className={'text-center'} style={{ width: '33.3%' }}>
                                                {data[1].value}
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </Col>
                        </ReactResizeDetector>
                    </Row>
                </PerfectScrollbar>
            </div>
            {children}
        </Card>
    );
};

export default ScannerStatus;
