import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    flowSchemeInfo: {},
    flowDrawJson: null,
    flowStatusList: [],
    flowTransitionList: [],
};

const { actions, reducer } = createSlice({
    name: 'flowScheme',
    initialState,
    reducers: {
        setFlowScheme: (state, action) => {
            const flowSchemeInfo = action.payload;
            state.flowSchemeInfo = flowSchemeInfo;
            try {
                state.flowDrawJson = JSON.parse(flowSchemeInfo.flowDrawJson);
            } catch (e) {
                console.log(e);
                state.flowDrawJson = null;
            }
        },
        setFlowStatusList: (state, action) => {
            state.flowStatusList = action.payload;
        },
        setFlowTransitionList: (state, action) => {
            state.flowTransitionList = action.payload;
        },
        setFlowDrawJson: (state, action) => {
            try {
                state.flowDrawJson = JSON.parse(action.payload);
            } catch (e) {
                console.log(e);
                state.flowDrawJson = null;
            }
        },
    },
});

export const { setFlowScheme, setFlowStatusList, setFlowTransitionList, setFlowDrawJson } = actions;
export default reducer;
