import React, { useMemo } from 'react';

import CompoundCard from '../../../Components/CompoundCard';

import useTranslation from '@hooks/useTranslation';

const watchLabels = [
    { id: 'all', label: 'All' },
    { id: 'wear', label: 'Wear' },
    { id: 'notWorn', label: 'Not Worn' },
    { id: 'charging', label: 'Charging' },
    { id: 'unconnected', label: 'Unconnected' },
];

const SmartWatchStatus = ({ data = {}, selected, handleClick }) => {
    const t = useTranslation('Smart Watch Health Monitoring');

    const handleClickedState = data => {
        const { value } = data;

        if (!value) return;

        if (typeof handleClick === 'function') {
            handleClick(data);
        }
    };

    // smartWatchStatus의 label을 번역하는 변수
    const translatedSmartWatchStatus = useMemo(() => {
        return watchLabels.map(({ id, label }) => {
            return { id, value: data[id] || 0, label: t(label) };
        });
    }, [t, data]);

    return (
        <CompoundCard className="mb-1">
            <CompoundCard.header>{t('Smart Watch Status')}</CompoundCard.header>
            <CompoundCard.body>
                <CompoundCard.body.clickableTable
                    clicked={selected && selected.id}
                    handleClick={handleClickedState}
                    data={translatedSmartWatchStatus}
                />
            </CompoundCard.body>
        </CompoundCard>
    );
};

export default SmartWatchStatus;
