import { createSlice } from '@reduxjs/toolkit';

const initialSelectedFilterOption = {
    bookmark: false,
    category: { label: 'People', value: 'PEOPLE' },
    rank: { label: 'All', value: 'all' },
    department: { label: 'All', value: 'all' },
    jobtitle: { label: 'All', value: 'all' },
    status: { label: 'All', value: 'all' },
};

export const initialState = {
    searchInput: '',
    searchInputOption: null,
    selectedFilterOption: initialSelectedFilterOption,
    filterOptions: {
        category: [{ label: 'People', value: 'PEOPLE' }],
        rank: null,
        department: null,
        jobtitle: null,
        status: null,
    },
    healthInfoList: { rows: [] },
    heartbeatEvent: 0,
    heartBeat: false,
};

const { actions, reducer } = createSlice({
    name: 'healthInformationProvision',
    initialState,
    reducers: {
        initialize: state => {
            state.searchInput = '';
            state.searchInputOption = null;
            state.selectedFilterOption = initialSelectedFilterOption;
            state.healthInfoList = { rows: [] };
            state.heartBeat = false;
        },
        setSearchInput: (state, action) => {
            state.searchInput = action.payload;
        },
        setSearchInputOption: (state, action) => {
            state.searchInputOption = action.payload;
        },
        setFilterOptions: (state, action) => {
            const { key, value } = action.payload;
            state.filterOptions[key] = value;
        },
        setSelectedFilterOption: (state, action) => {
            const option = action.payload;
            state.selectedFilterOption = { ...state.selectedFilterOption, ...option };
        },
        setHealthInfoList: (state, action) => {
            state.healthInfoList = action.payload;
        },
        setHeartbeatEvent: (state, action) => {
            state.heartbeatEvent = action.payload;
        },
        setHeartBeat: (state, action) => {
            state.heartBeat = !!action.payload;
        },
    },
});

export const {
    initialize,
    setSearchInput,
    setSearchInputOption,
    setFilterOptions,
    setSelectedFilterOption,
    setHealthInfoList,
    setHeartbeatEvent,
    setHeartBeat,
} = actions;
export default reducer;
