import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    floorInfo: {
        floorId: 'entire',
    },
    geofenceList: [],
    stateConversionList: [],
    selectedGeofence: {},
    selectedTag: null,
    tagStatus: {},
};

const { actions, reducer } = createSlice({
    name: 'smartMilitary/floorInfo',
    initialState,
    reducers: {
        setFloorInfo: (state, action) => {
            state.floorInfo = action.payload;
        },
        setGeofenceList: (state, action) => {
            state.geofenceList = action.payload.map(geofence => {
                return {
                    ...geofence,
                    bounds: [geofence.latLngList.map(({ lat, lng }) => [lat, lng])],
                };
            });
        },
        setStateConversionList: (state, action) => {
            state.stateConversionList = action.payload.map(geofence => {
                return { ...geofence, bounds: [geofence.latLngList.map(({ lat, lng }) => [lat, lng])] };
            });
        },
        setSelectedGeofence: (state, action) => {
            state.selectedGeofence = action.payload;
        },
        setTagStatus: (state, action) => {
            state.tagStatus = action.payload;
        },
        setSelectedTag: (state, action) => {
            state.selectedTag = action.payload;
        },
    },
});

export const {
    setFloorInfo,
    setGeofenceList,
    setStateConversionList,
    setSelectedGeofence,
    setTagStatus,
    setSelectedTag,
} = actions;
export default reducer;
