import React, { useState, useEffect, useContext, useRef, useCallback } from 'react';

import { Card, Button, makeRotatedImageOverlay } from '@components';
import { useAsync, useColumns, useTranslation } from '@hooks';

import PlayController from './PlayController';
import Table from './Table';

import {
    DrowningMonitoringDispatchContext,
    DrowningMonitoringStatusContext,
} from 'Components/MainPages/Monitoring/DrowningMonitoring';
import {
    setPlayerState,
    setPause,
    setGeofenceInfo,
} from 'Components/MainPages/Monitoring/DrowningMonitoring/DrowningMonitoringReducer';

import * as column from '../column';

import cx from 'classnames';

import { Marker, Polyline } from 'react-leaflet';
import L from 'leaflet';
import { fetchGeofenceList } from '@api/common';
import Map from 'Components/MainPages/Monitoring/Components/Map';
import Control from 'react-leaflet-control';
import MapOptionSelect from './MapOptionSelect';

const SPAGHETTI_DIAGRAM = 'spaghettiDiagram';

const AnalysisByTrack = ({ targetLocationLog = [], lostSignalLog = [] }) => {
    const t = useTranslation('Drowning Monitoring');

    const mapRef = useRef(null);

    const { floorInfo, playerState, playTime } = useContext(DrowningMonitoringStatusContext);
    const dispatch = useContext(DrowningMonitoringDispatchContext);

    const [selectedAsset, setSelectedAsset] = useState(null);
    const [spaghettiLogInfoBeforePlayTime, setSpaghettiLogInfoBeforePlayTime] = useState([]);

    const [playTimeMarkerLog, setPlayTimeMarkerLog] = useState({});
    const [locationLogListInfo, setLocationLogListInfo] = useState({ rows: [] });
    const [playTimeTableList, setPlayTimeTableList] = useState({ rows: [] });

    const [selectedMapOption, setSelectedMapOption] = useState({ value: '', label: t('None') });
    const handleSelectedMapOption = useCallback(selected => {
        setSelectedMapOption(selected[0]);
    }, []);

    const toggleState = state => {
        dispatch(setPlayerState(state));
    };

    const assetColumn = useColumns(
        [column.name(), column.distance(), column.latitude(), column.longitude(), column.date()],
        t,
    );

    const handleTrClick = tr => {
        const { targetId } = tr;
        setSelectedAsset(selectedAsset === targetId ? null : targetId);
    };

    useEffect(() => {
        const logList = [];
        const playTimeMarker = {};

        targetLocationLog.forEach(log => {
            const { targetId, lat, lng, regDate } = log;

            if (!playTimeMarker[targetId]) {
                // lost signal 마지막 값과 마커 마지막 위치 사이 값 보정
                const lostSignal = lostSignalLog
                    .filter(lost => lost.targetId === targetId && regDate < lost.regDate)
                    .sort(({ regDate: a_date }, { regDate: b_date }) => a_date - b_date);

                if (!!lostSignal.length) {
                    const result = [];
                    for (let time = lostSignal[0].regDate; time >= regDate; time--) {
                        result.push([lat, lng, time]);
                    }
                    playTimeMarker[targetId] = result;
                } else {
                    playTimeMarker[targetId] = [[lat, lng, regDate]];
                }
            }

            if (!!playTimeMarker[targetId] && !!playTimeMarker[targetId].length) {
                const [_, __, nextDate] = playTimeMarker[targetId].at(-1);

                //마커 깜빡거림 보정
                const lostSignal = lostSignalLog
                    .filter(
                        ({ targetId, regDate }) =>
                            targetId === log.targetId && log.regDate < regDate && regDate < nextDate,
                    )
                    .sort(({ regDate: a_date }, { regDate: b_date }) => a_date - b_date);

                let time = !!lostSignal.length ? lostSignal[0].regDate : nextDate - 1;
                for (; time >= regDate; time--) {
                    playTimeMarker[targetId].push([lat, lng, time]);
                }
            }

            // 테이블에 보여줄 정보
            if (!logList.find(v => v.targetId === targetId)) {
                logList.push(log);
            }
        });

        setPlayTimeMarkerLog(playTimeMarker);
        setLocationLogListInfo({ rows: logList });
        setPlayTimeTableList({ rows: logList });
    }, [targetLocationLog, lostSignalLog]);

    /**
     * 현재 플레이 시간 기준에서의 스파게티 다이어그램
     */
    useEffect(() => {
        // 현재 플레이 시간 기준에서 보이는 자산 목록
        const tableList = {};
        Object.entries(playTimeMarkerLog).forEach(([targetId, value]) => {
            const targetedValue = value.find(([_, __, regDate]) => regDate === playTime);
            if (!targetedValue) return;
            const list = locationLogListInfo.rows.find(data => data.targetId === targetId);
            tableList[targetId] = { ...list, lat: targetedValue[0], lng: targetedValue[1], regDate: targetedValue[2] };
        });
        setPlayTimeTableList({ rows: Object.values(tableList) });

        // 선택된 자산이 있는 경우
        if (selectedAsset) {
            if (tableList[selectedAsset]) {
                const lostSignal = lostSignalLog
                    .filter(({ targetId, regDate }) => targetId === selectedAsset && regDate <= playTime)
                    .sort(({ regDate: a_date }, { regDate: b_date }) => a_date - b_date);

                const lostTime = !!lostSignal.length ? lostSignal.at(-1).regDate : 0;
                const selectedDiagram = playTimeMarkerLog[selectedAsset].filter(
                    ([_, __, regDate]) => lostTime < regDate && regDate <= playTime,
                );
                setSpaghettiLogInfoBeforePlayTime([selectedDiagram]);
                return;
            }
            setSelectedAsset(null);
        }

        const diagram = Object.entries(playTimeMarkerLog)
            .map(([targetId, log]) => {
                if (!tableList[targetId]) return null;
                const lostSignal = lostSignalLog
                    .filter(lost => lost.targetId === targetId && lost.regDate <= playTime)
                    .sort(({ regDate: a_date }, { regDate: b_date }) => a_date - b_date);

                const lostTime = !!lostSignal.length ? lostSignal.at(-1).regDate : 0;
                return log.filter(([_, __, regDate]) => lostTime < regDate && regDate <= playTime);
            })
            .filter(value => !!value);
        setSpaghettiLogInfoBeforePlayTime(diagram);
    }, [playTime, selectedAsset]);

    const PlayTimeMarker = () => {
        return Object.entries(playTimeMarkerLog).map(([targetId, value]) => {
            if (selectedAsset && selectedAsset !== targetId) {
                return null;
            }

            const location = value.find(([_, __, regDate]) => regDate === playTime);
            if (!location) return null;

            const [lat, lng] = location;
            return (
                <Marker
                    key={targetId}
                    position={[lat, lng]}
                    icon={L.divIcon({
                        className: 'simple-marker',
                        html: `<div></div>`,
                    })}
                />
            );
        });
    };

    const MakeSpaghettiDiagram = () => {
        if (!selectedMapOption || selectedMapOption.value !== SPAGHETTI_DIAGRAM) {
            return null;
        }
        return spaghettiLogInfoBeforePlayTime.map((log, idx) => {
            return <Polyline key={'track ' + idx} positions={log} />;
        });
    };

    // geofence 정보 API
    const { promise: getGeofenceList } = useAsync({
        promise: fetchGeofenceList,
        resolve: res => {
            const { rows } = res;
            if (!!rows.length) {
                const geofence = rows.map(geofence => {
                    return { ...geofence, bounds: geofence.latLngList.map(latLng => [latLng.lat, latLng.lng]) };
                });
                dispatch(setGeofenceInfo(geofence));
                return;
            }
            dispatch(setGeofenceInfo([]));
        },
        reject: err => {
            console.error(err);
            dispatch(setGeofenceInfo([]));
        },
    });

    // geofence 정보 호출
    useEffect(() => {
        if (floorInfo) {
            getGeofenceList({ floor: floorInfo.floorId || '' });
            const map = mapRef.current.leafletElement;
            if (map) {
                map.eachLayer(layer => {
                    if (layer instanceof L.ImageOverlay) {
                        map.fitBounds(layer.getBounds());
                        return false;
                    }
                });
            }
        }
    }, [floorInfo]);

    return (
        <>
            <Card header={{ title: t('Drowning List') }} className="asset-list">
                <Table
                    key={selectedAsset}
                    data={playTimeTableList}
                    columns={assetColumn}
                    onTrClick={handleTrClick}
                    selected={selectedAsset}
                    paging={false}
                />
            </Card>
            <Card
                header={{
                    title: t('Position History Viewer'),
                    action: (
                        <>
                            <Button
                                className={cx(
                                    'pnt-btn',
                                    playerState === 'real-time' ? 'btn-secondary' : 'btn-lightgray',
                                )}
                                onClick={() => {
                                    toggleState('real-time');
                                    dispatch(setPause());
                                    setSelectedAsset(null);
                                }}
                            >
                                {t('Real-time')}
                            </Button>
                            <Button
                                className={cx('pnt-btn', playerState === 'track' ? 'btn-secondary' : 'btn-lightgray')}
                                onClick={() => {
                                    toggleState('track');
                                    setSelectedAsset(null);
                                }}
                            >
                                {t('Track')}
                            </Button>
                        </>
                    ),
                }}
            >
                <div style={{ height: '110px', width: '100%' }}>
                    <PlayController on={playerState === 'track' && !!locationLogListInfo.rows.length} />
                </div>
                <div style={{ height: 'calc(100% - 130px)', minHeight: '28rem' }}>
                    <Map tile={true} ref={mapRef}>
                        <Control position="topleft" key={`control-${t(selectedMapOption.label)}`}>
                            <MapOptionSelect handleChange={handleSelectedMapOption} value={[selectedMapOption]} />
                        </Control>

                        {makeRotatedImageOverlay(mapRef, floorInfo)}

                        <PlayTimeMarker />
                        <MakeSpaghettiDiagram />
                    </Map>
                </div>
            </Card>
        </>
    );
};

export default AnalysisByTrack;
