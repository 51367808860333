import React, { useMemo, useState } from 'react';

import useTranslation from '@hooks/useTranslation';

import { Select as ReactSelect, TextInput, Button } from '@components';

import { InputGroup, SelectGroup, InputWrap, SearchWrap } from '@components/FilterSearchGroup/Components/Part';
import FilterSearchGroup from '@components/FilterSearchGroup';
import SearchGroup from '@components/FilterSearchGroup/Components/SearchGroup';
import SearchButtonArea from '@components/FilterSearchGroup/Components/SearchButtonArea';

import styled from 'styled-components';

const Search = ({ searchOption, handleSearchClick }) => {
    const t = useTranslation('Status Of Entry And Exit Of Vessel');

    const { selectedOption, searchInput } = searchOption;

    const searchOptions = useMemo(() => {
        return [
            { value: 'ALL', label: t('All') },
            { value: 'BOARDING_ON', label: t('Boarding') },
            { value: 'BOARDING_OFF', label: t('No Boarding') },
        ];
    }, [t]);

    const [option, setOption] = useState(selectedOption || searchOptions[0]);
    const [inputState, setInputState] = useState(searchInput || '');

    const handleInputChange = e => {
        const { value } = e.target;
        setInputState(value);
    };

    const handleSearch = () => {
        handleSearchClick({ option, inputValue: inputState });
    };

    return (
        <FilterSearchGroupContainer label={t('Search', 'Search')} className="card">
            <SearchGroup className="bg-brand" label={t('Search', 'Search')}>
                <SearchWrap>
                    <SelectGroup>
                        <ReactSelect
                            name="selectInput"
                            placeholder={t('Search Option', 'Search')}
                            value={option}
                            options={searchOptions}
                            onChange={setOption}
                            customControlStyles={{
                                width: '100%',
                            }}
                            customMenuStyles={{ width: '100%' }}
                            customOptionStyles={{ width: '100%' }}
                        />
                    </SelectGroup>
                    <InputWrap>
                        <InputGroup>
                            <TextInput
                                style={{ borderRadius: '5px' }}
                                type="text"
                                size={60}
                                placeholder={t('Enter the IoT item name Or ID', 'IotItemStatus')}
                                name="name"
                                value={inputState}
                                handleChange={handleInputChange}
                            />
                        </InputGroup>
                    </InputWrap>
                </SearchWrap>
                <SearchButtonArea>
                    <Button className="btn-brand btn-icon" iconName="search" onClick={handleSearch}>
                        {t('Search', 'Search')}
                    </Button>
                </SearchButtonArea>
            </SearchGroup>
        </FilterSearchGroupContainer>
    );
};

const FilterSearchGroupContainer = styled(FilterSearchGroup)`
    position: absolute;
    width: 100%;
    padding: 1rem;
`;

export default Search;
