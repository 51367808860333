import React from 'react';

import styled, { css } from 'styled-components';

import { layoutSize } from '../../../../util/common/util';
import { ClickableProps } from './type';

import cx from 'classnames';

const ClickableTable = ({
    data = [],
    handleClick,
    headerClassName = {},
    bodyClassName = {},
    clicked = null,
}: ClickableProps) => {
    const clickable = typeof handleClick === 'function';
    return (
        <TableContainer>
            {data.map(element => {
                const { id, label, value } = element;
                return (
                    <div className="w-100" key={id}>
                        <TableHeaderContainer title={label} className={headerClassName[id]}>
                            {label}
                        </TableHeaderContainer>
                        <TableBodyContainer
                            title={value}
                            className={cx(value && bodyClassName[id])}
                            $clickable={clickable && !!value}
                            $clicked={!!clicked && clicked === id}
                            onClick={() => {
                                if (typeof handleClick === 'function') {
                                    handleClick(element);
                                }
                            }}
                        >
                            {value}
                        </TableBodyContainer>
                    </div>
                );
            })}
        </TableContainer>
    );
};

const TableContainer = styled.div`
    border: 1px solid rgb(229, 227, 243);
    border-right: none;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
    width: 100%;
`;

const TableHeaderContainer = styled.div`
    background-color: rgba(229, 227, 244, 0.7) !important;
    border-right: 1px solid rgb(229, 227, 243);
    border-bottom: 1px solid rgb(229, 227, 243);

    text-align: center;
    padding: 0.6rem 0.4rem;
    color: black !important;
    font-weight: bold;

    //text-ellipsis
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`;

const TableBodyContainer = styled.div<{ $clickable: boolean; $clicked: boolean }>`
    border-right: 1px solid rgb(229, 227, 243);
    border-bottom: 1px solid rgba(229, 227, 243, 0.5);
    font-size: 3rem;
    text-align: center;

    //text-ellipsis
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    @media (${layoutSize.laptop}) {
        font-size: 2rem;
    }

    ${({ $clickable }) =>
        $clickable &&
        css`
            :hover {
                background-color: #eceaf2;
                cursor: pointer;
            }
        `}

    ${({ $clicked }) =>
        $clicked &&
        css`
            background-color: #eceaf2;
        `};
`;

export default ClickableTable;
