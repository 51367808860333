import React from 'react';

import { useColumns, useTranslation } from '@hooks';

import CompoundCard from '../../../Components/CompoundCard';

import * as column from './column';

const PassengersDetail = ({ tableData, loading = false }) => {
    const t = useTranslation('Status Of Entry And Exit Of Vessel');

    const columns = useColumns(
        [
            column.rank({
                width: 80,
            }),
            column.name(),
            column.department(),
            column.jobtitle(),
            column.status({
                width: 340,
            }),
        ],
        t,
    );

    return (
        <CompoundCard className="mt-2 d-flex flex-column" style={{ height: 'calc(100% - 12.5rem)' }}>
            <CompoundCard.header>{t('Passengers Details')}</CompoundCard.header>
            <CompoundCard.body className="h-100">
                <CompoundCard.body.table
                    containerClassName="h-100"
                    paging={false}
                    columns={columns}
                    data={tableData}
                    loading={loading}
                />
            </CompoundCard.body>
        </CompoundCard>
    );
};

export default PassengersDetail;
