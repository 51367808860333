import TemporaryWidget, { WIDGET_TYPE_TEMP } from './common/TemporaryWidget';

//smart hospital widgets
import shWidgetList from './sh';

//asset management widgets
import amWidgetList from './am';

//smart military widgets
import smWidgetList from './sm';

// export const testComponent = {
//     type: 'testComponent',
//     // name: '테스트 위젯',
//     name: '테스트 위젯',
//     desc: '테스트 위젯',
//     thumbnailURL: null,
//     Component: TestComponent,
//     SettingComponent: null,
//     config: { defaultSettings: { name: '테스트 위젯' } },
//     layout: {
//         w: 6,
//         h: 27,
//         minW: 4,
//         minH: 21,
//     },
// };

export const temp = {
    type: WIDGET_TYPE_TEMP,
    name: 'Widget Name',
    desc: '',
    thumbnailURL: null,
    Component: TemporaryWidget,
    SettingComponent: null,
    config: {},
    layout: {
        w: 4,
        h: 15,
        minW: 2,
        minH: 9,
    },
};

const widgetList = {
    temp,
    ...shWidgetList,
    ...amWidgetList,
    ...smWidgetList,
};

export default widgetList;
