import React from 'react';

import cx from 'classnames';

const cellColor = {
    NORMAL: 'bg-success',
    WARNING: 'bg-warning',
    CRITICAL: 'bg-danger',
    OUTOFRANGE: 'bg-gray',
};

export const id = ({ ...restProps } = {}) => {
    return {
        Header: 'ID',
        accessor: 'targetId',
        className: 'text-ellipsis',
        headerClassName: 'text-ellipsis',
        width: 70,
        ...restProps,
    };
};

export const name = ({ ...restProps } = {}) => {
    return {
        Header: 'Name',
        accessor: 'targetName',
        className: 'text-ellipsis',
        headerClassName: 'text-ellipsis',
        width: 100,
        ...restProps,
    };
};

export const rank = ({ ...restProps } = {}) => {
    return {
        Header: 'rank',
        accessor: 'rank',
        className: 'text-ellipsis',
        headerClassName: 'text-ellipsis',
        width: 80,
        ...restProps,
    };
};

export const department = ({ ...restProps } = {}) => {
    return {
        Header: 'department',
        accessor: 'department',
        className: 'text-ellipsis',
        headerClassName: 'text-ellipsis',
        width: 90,
        ...restProps,
    };
};

export const state = ({ ...restProps } = {}) => {
    return {
        Header: 'State',
        accessor: 'agreeState',
        className: 'text-ellipsis p-0',
        headerClassName: 'text-ellipsis',
        width: 80,
        ...restProps,
    };
};

export const heartbeat = ({ ...restProps } = {}) => {
    return {
        Header: 'Heart Beat',
        accessor: 'heartbeatStatus',
        className: 'text-ellipsis p-0',
        headerClassName: 'text-ellipsis',
        width: 80,
        formatTitle: ({ original }) => {
            return original['heartbeat'];
        },
        Cell: ({ value, row }) => {
            const { original } = row;
            return <div className={cx('text-white p-2', cellColor[value])}>{original['heartbeat']}</div>;
        },
        ...restProps,
    };
};

export const sleepingTime = ({ ...restProps } = {}) => {
    return {
        Header: 'Sleeping Time',
        accessor: 'sleepStatus',
        className: 'text-ellipsis p-0',
        headerClassName: 'text-ellipsis',
        width: 90,
        formatTitle: ({ original }) => {
            return original['sleep'];
        },
        Cell: ({ value, row }) => {
            const { original } = row;
            return <div className={cx('text-white p-2', cellColor[value])}>{original['sleep']}</div>;
        },
        ...restProps,
    };
};

export const steps = ({ ...restProps } = {}) => {
    return {
        Header: 'Count Of Steps',
        accessor: 'stepStatus',
        className: 'text-ellipsis p-0',
        headerClassName: 'text-ellipsis',
        width: 90,
        formatTitle: ({ original }) => {
            return original['step'];
        },
        Cell: ({ value, row }) => {
            const { original } = row;
            return <div className={cx('text-white p-2', cellColor[value])}>{original['step']}</div>;
        },
        ...restProps,
    };
};

export const distance = ({ ...restProps } = {}) => {
    return {
        Header: 'Distance',
        accessor: 'distanceStatus',
        className: 'text-ellipsis p-0',
        headerClassName: 'text-ellipsis',
        width: 100,
        formatTitle: ({ original }) => {
            return original['distance'];
        },
        Cell: ({ value, row }) => {
            const { original } = row;
            return <div className={cx('text-white p-2', cellColor[value])}>{original['distance']}</div>;
        },
        ...restProps,
    };
};

export const calories = ({ ...restProps } = {}) => {
    return {
        Header: 'Calories',
        accessor: 'caloriesStatus',
        className: 'text-ellipsis p-0',
        headerClassName: 'text-ellipsis',
        width: 100,
        formatTitle: ({ original }) => {
            return original['calories'];
        },
        Cell: ({ value, row }) => {
            const { original } = row;
            return <div className={cx('text-white p-2', cellColor[value])}>{original['calories']}</div>;
        },
        ...restProps,
    };
};

export const stress = ({ ...restProps } = {}) => {
    return {
        Header: 'Stress Indicator',
        accessor: 'stressStatus',
        className: 'text-ellipsis p-0',
        headerClassName: 'text-ellipsis',
        width: 100,
        formatTitle: ({ original }) => {
            return original['stress'];
        },
        Cell: ({ value, row }) => {
            const { original } = row;
            return <div className={cx('text-white p-2', cellColor[value])}>{original['stress']}</div>;
        },
        ...restProps,
    };
};
