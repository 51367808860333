import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';

import { Tab, Button, LoadingBlock } from '@components';

import { useAsync, useTranslation } from '@hooks';

import { getSelectedAssetInfoApi } from 'api/asset';
import { getStatisticsLogApi } from 'api/dashboard';
import { getCategoryPropertiesListApi } from 'api/common';

import { getStatusListInfoApi, getTransitionInfoByCategoryApi } from 'api/status';
import { updateAssetStatusApi } from 'api/asset';

import { returnCategoryProperty } from 'util/common/util';

import AssetDetailInformation from './Components/AssetDetailInformation';
import StatusJournalTab from './Components/StatusJournalTab';
import LocationTab from './Components/LocationTab';

import cx from 'classnames';

import styled from 'styled-components';

const AssetDetail = ({ givenTargetNum }) => {
    const t = useTranslation('Asset');

    const history = useHistory();
    const { targetNum } = useParams();

    const [open, setOpen] = useState(false);
    const [assetInfo, setAssetInfo] = useState({});
    const [statusLogInfo, setStatusLogInfo] = useState([]);
    const [statusColorInfo, setStatusColorInfo] = useState({});
    const [assetCategoryPropertyInfo, setAssetCategoryPropertyInfo] = useState([]);
    const [selectedAssetStatus, setSelectedAssetStatus] = useState([]);

    // 선택된 자산 정보
    const { promise: getSelectedAssetInfo, state: selectedAssetInfoState } = useAsync({
        promise: getSelectedAssetInfoApi,
        fixedParam: { targetKey: targetNum || Number(givenTargetNum) },
        resolve: res => {
            setAssetInfo(res || {});
        },
    });

    const { promise: getCategoryPropertyInfo, state: categoryPropertyInfoState } = useAsync({
        promise: getCategoryPropertiesListApi,
        fixedParam: { isAll: 'Y' },
        resolve: res => {
            const filteredCategoryPropertyList = res.rows.filter(prop => prop.isListProperty === 'Y');

            if (assetInfo && assetInfo.properties && !!filteredCategoryPropertyList.length) {
                const result_map = Object.entries(assetInfo.properties).map(([key, value]) => ({ id: key, value }));
                const tempList = returnCategoryProperty(result_map, filteredCategoryPropertyList);
                setAssetCategoryPropertyInfo(tempList);
            }
        },
    });

    // 대상 상태 리스트 조회
    const { promise: getStatusInfo } = useAsync({
        promise: getStatusListInfoApi,
        fixedParam: { isAll: 'Y' },
        resolve: res => {
            const statusToColor = {};
            if (res.rows) {
                res.rows.forEach(v => {
                    statusToColor[v.statusNum] = v.displayColor;
                });
                setStatusColorInfo(statusToColor);
            }
        },
    });

    // 카테고리별 가능한 액션 조회
    const { promise: getTransitionInfoByCategory } = useAsync({
        promise: getTransitionInfoByCategoryApi,
        resolve: res => {
            setSelectedAssetStatus(res.rows || []);
        },
    });

    // 대상 상태 로그 정보
    const {
        promise: getAssetStatusLog,
        state: assetStatusLogState,
        abort: abortAssetStatusLog,
    } = useAsync({
        promise: getStatisticsLogApi,
        fixedParam: { isAll: 'Y' },
        resolve: res => {
            setStatusLogInfo(res.rows || []);
        },
    });

    // 대상 상태 정보 변경
    const { promise: updateAssetStatus } = useAsync({
        promise: updateAssetStatusApi,
        resolve: () => {
            if (targetNum) {
                getSelectedAssetInfo();
            }
        },
    });

    useEffect(() => {
        if (targetNum || givenTargetNum) {
            getSelectedAssetInfo();
        }
    }, [targetNum, givenTargetNum]);

    useEffect(() => {
        if (!assetInfo) {
            return;
        }
        getStatusInfo();

        const { statusNum, categoryCode, targetNum } = assetInfo;

        if (categoryCode) {
            getCategoryPropertyInfo({ categoryCode });
        }

        if (statusNum && categoryCode) {
            getTransitionInfoByCategory({ statusNum, categoryCode });
        }

        if (targetNum) {
            abortAssetStatusLog();
            getAssetStatusLog({ targetNum });
        }
    }, [assetInfo]);

    const click = () => {
        setOpen(!open);
    };
    const handleActionBtnClick = action => {
        setSelectedAssetStatus([]);
        updateAssetStatus({
            targetNum: assetInfo.targetNum,
            actionNum: action.actionNum,
            statusNum: action.endStatusNum,
            flowNum: action.flowNum,
        });
    };

    return (
        <>
            {!!targetNum && (
                <div className="d-flex justify-content-between mb-3">
                    <div className="card-header__title">
                        <h3 className="title__main">{t('Asset - Information')}</h3>
                    </div>
                    <CardActionWrapper className="card-header__function">
                        <Button className="btn-gray" onClick={() => history.goBack()}>
                            {t('Back', 'Button')}
                        </Button>
                        <Dropdown isOpen={open} toggle={click} disabled={!selectedAssetStatus.length}>
                            <DropdownToggle
                                className={cx(
                                    'pnt-btn btn-darkgray',
                                    'd-flex align-items-center gap-2',
                                    !selectedAssetStatus.length && 'form-disable',
                                )}
                            >
                                {t('Action')}
                                <span className="material-icons-round color-lightgray">keyboard_arrow_down</span>
                            </DropdownToggle>
                            <DropdownMenu right className="dropdown-menu-sm">
                                {selectedAssetStatus.map(status => {
                                    const { actionNum, actionName } = status;
                                    return (
                                        <DropdownItem key={actionNum} onClick={() => handleActionBtnClick(status)}>
                                            {actionName}
                                        </DropdownItem>
                                    );
                                })}
                            </DropdownMenu>
                        </Dropdown>
                    </CardActionWrapper>
                </div>
            )}
            <div className="grid-layout-container-for-assetDetail w-100">
                <div className="margin-bottom-under-770 flx-1">
                    <AssetDetailInformation
                        assetInfo={assetInfo}
                        assetCategoryPropertyInfo={assetCategoryPropertyInfo}
                        isLoading={selectedAssetInfoState.isLoading || categoryPropertyInfoState.isLoading}
                    />
                </div>
                <TabContainer>
                    <Tab
                        activeTabId="location"
                        styles={{ header: { justifyContent: 'center' }, headerItem: { width: '50%' } }}
                        tabs={[
                            {
                                tabId: 'location',
                                title: t('Location'),
                                content: (
                                    <LocationTab assetInfo={assetInfo} isLoading={selectedAssetInfoState.isLoading} />
                                ),
                            },
                            {
                                tabId: 'statusJournal',
                                title: t('Status journal'),
                                content: (
                                    <StatusJournalTab
                                        statusLogInfo={statusLogInfo}
                                        statusColorInfo={statusColorInfo}
                                        isLoading={assetStatusLogState.isLoading}
                                    />
                                ),
                            },
                        ]}
                    />
                </TabContainer>
            </div>
        </>
    );
};

const CardActionWrapper = styled.div`
    display: flex;
    gap: 10px;
    justify-content: center;
`;

const TabContainer = styled.div`
    flex: 1;
    .card-body {
        height: 100%;
        @media (max-width: 1150px) {
            height: unset;
        }
    }
`;

export default AssetDetail;
