import React from 'react';

import useTranslation from '@hooks/useTranslation';

import FilterGroup from '@components/FilterSearchGroup/Components/FilterGroup';
import { FilterList } from '@components/FilterSearchGroup/Components/Part';
import FilterSearchGroup from '@components/FilterSearchGroup';
import FilterButtonArea from '@components/FilterSearchGroup/Components/FilterButtonArea';

import FilterGenerator from './FilterGenerator';

const FilterContents = ({
    filterInfo,
    handleFilterChange,
    handleFilterConditionChange,
    changeableFilter,
    filterEditMode,
}) => {
    const t = useTranslation('Filter');
    return (
        <FilterGroup label={t('Filter')} className={'bg-secondary'}>
            <FilterList>
                <FilterGenerator
                    filterInfo={filterInfo}
                    filterEditMode={filterEditMode}
                    monitoringMode
                    handleChange={(selected, isEditable, conditionInfo) => {
                        if (typeof handleFilterConditionChange === 'function') {
                            handleFilterConditionChange({
                                conditionValues: selected,
                                isEditable,
                                conditionId: conditionInfo.conditionId,
                            });
                        }
                    }}
                />
            </FilterList>
            <FilterButtonArea />
        </FilterGroup>
    );
};

const FilterWidget = ({
    withContainer = true,
    changeableFilter = true,
    filterInfo,
    handleFilterChange,
    handleFilterConditionChange,
    filterEditMode = false,
}) => {
    return withContainer ? (
        <FilterSearchGroup>
            <FilterContents
                changeableFilter={changeableFilter}
                filterInfo={filterInfo}
                handleFilterChange={handleFilterChange}
                handleFilterConditionChange={handleFilterConditionChange}
                filterEditMode={filterEditMode}
            />
        </FilterSearchGroup>
    ) : (
        <FilterContents
            changeableFilter={changeableFilter}
            filterInfo={filterInfo}
            handleFilterChange={handleFilterChange}
            handleFilterConditionChange={handleFilterConditionChange}
            filterEditMode={filterEditMode}
        />
    );
};

export default FilterWidget;
