import { useSettings } from '../../util/useSettings';
import { useDispatch } from 'react-redux';
import { editWidgetSettings } from '@reducer/Dashboards/DashboardEdit';
import React, { useState } from 'react';
import useTranslation from '@hooks/useTranslation';
import WidgetSettingModal from '../../Components/WidgetSettingModal';
import Label from '@components/Label';
import SingleSelect from '@components/Select/SingleSelect';

const RealTimeLocationSetting = ({ widgetInfo, ...restProps }) => {
    const { config } = widgetInfo;
    const settings = useSettings(config);
    const dispatch = useDispatch();
    const { settings: customSettings } = config;
    const t = useTranslation('RealTimeLocationStatus');
    const options = [
        { value: 'ALL TARGET', label: t('All Target') },
        { value: 'SINGLE TARGET', label: t('Single Target') },
    ];

    const [updatedWidgetInfo, setUpdatedWidgetInfo] = useState({});

    const handleTargetOptionChange = selected => {
        setUpdatedWidgetInfo(prevState => {
            return { ...prevState, targetOption: selected };
        });
    };

    const handleSubmitClick = data => {
        if (customSettings) {
            dispatch(
                editWidgetSettings({
                    id: widgetInfo.id,
                    settings: { ...customSettings, ...data, ...updatedWidgetInfo },
                }),
            );
        } else {
            dispatch(
                editWidgetSettings({
                    id: widgetInfo.id,
                    settings: { ...data, ...updatedWidgetInfo },
                }),
            );
        }
    };

    const handleCancelClick = () => {
        setUpdatedWidgetInfo({});
    };

    return (
        <WidgetSettingModal
            headerTitle={t('Setting For Monitoring Realtime Location')}
            widgetInfo={widgetInfo}
            socketWidget
            hiddenHeader
            okCallback={handleSubmitClick}
            cancelCallback={handleCancelClick}
            {...restProps}
        >
            <Label
                name={t('Target Monitoring Option')}
                value={
                    <SingleSelect
                        className={'w-100'}
                        options={options}
                        value={updatedWidgetInfo.targetOption ? updatedWidgetInfo.targetOption : settings.targetOption}
                        onChange={handleTargetOptionChange}
                    />
                }
            />
        </WidgetSettingModal>
    );
};

export default RealTimeLocationSetting;
