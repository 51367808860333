import React from 'react';

import { RotatedImageOverlay } from '@components';

const ImageOverlay = ({ mapRef, floorInfo, floorList }) => {
    let selectedFloorInfo = floorInfo;

    if (!!floorInfo && !floorInfo.imgURL && !!floorList) {
        selectedFloorInfo = floorList.find(({ floorId }) => floorId === floorInfo.floorId);
    }

    if (selectedFloorInfo && selectedFloorInfo.imgURL && selectedFloorInfo.bounds && selectedFloorInfo.bounds.length) {
        return (
            <RotatedImageOverlay
                key={selectedFloorInfo.floorId}
                url={selectedFloorInfo.imgURL}
                deg={selectedFloorInfo.deg}
                bounds={selectedFloorInfo.bounds}
                onLoad={() => {
                    if (mapRef && mapRef.current) {
                        const map = mapRef.current.leafletElement;
                        if (map) {
                            map.fitBounds(selectedFloorInfo.bounds);
                        }
                    }
                }}
            />
        );
    }
    return <></>;
};

export default ImageOverlay;
