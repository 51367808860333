import React, { createContext, useReducer, useEffect, useContext } from 'react';
import styled from 'styled-components';
import Button from '../../../../Common/Button';
import { Button as RsButton } from 'reactstrap';
import { initialState, reducer, goItem, checkItem, addComeItems } from './reducer';
import { CustomInput } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faTimes } from '@fortawesome/free-solid-svg-icons';

export const StateContext = createContext();
export const DispatchContext = createContext();

const BasketWrapper = styled.div`
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 30px 1fr;
    align-items: center;
`;

const StyledBasket = styled.div`
    border-radius: 5px;
    border: 1px solid #999;
    box-shadow: 1px 1px 3px #999;
    overflow: auto;
    min-height: 150px;
    height: 150px;
`;

const Item = styled.div`
    display: grid;
    align-items: center;
    min-height: 35px;
    color: #000;
    border: 1px solid #999;
    font-size: 1rem;
    padding: 3px;
`;

const ComeItem = ({ item }) => {
    const dispatch = useContext(DispatchContext);
    const { labelKey, onDelete } = useContext(StateContext);
    return (
        <Item style={{ gridTemplateColumns: 'auto min-content' }}>
            <span className={'text-ellipsis'} title={item[labelKey]}>
                {item[labelKey]}
            </span>
            <RsButton
                className={'btn-danger'}
                style={{ padding: 0, height: '20px', width: '20px', lineHeight: 1.2 }}
                onClick={e => {
                    if (typeof onDelete === 'function') {
                        onDelete(item);
                    } else {
                        dispatch(goItem(item));
                    }
                }}
            >
                <FontAwesomeIcon icon={faTimes} />
            </RsButton>
        </Item>
    );
};

const GoItem = ({ item }) => {
    const dispatch = useContext(DispatchContext);
    const { valueKey, labelKey } = useContext(StateContext);
    return (
        <Item style={{ gridTemplateColumns: 'min-content auto' }}>
            <CustomInput
                type="checkbox"
                name="comeCandidate"
                id={`${item[valueKey]}_comeCandidate`}
                onChange={e => {
                    dispatch(checkItem(item[valueKey]));
                }}
                checked={!!item.checked}
            />
            <span className={'text-ellipsis'} title={item[labelKey]}>
                {item[labelKey]}
            </span>
        </Item>
    );
};

const Basket = ({ isCome, items }) => {
    const { valueKey } = useContext(StateContext);
    return (
        <StyledBasket>
            {isCome
                ? items.map(v => <ComeItem key={v[valueKey]} item={v} />)
                : items.map(v => <GoItem key={v[valueKey]} item={v} />)}
        </StyledBasket>
    );
};

const ComeGoBasket = ({
    initComeItems = [],
    initGoItems = [],
    valueKey = 'value',
    labelKey = 'label',
    onChange,
    onDelete,
}) => {
    const [state, dispatch] = useReducer(reducer, {
        ...initialState,
        comeItems: initComeItems,
        goItems: initGoItems,
        valueKey,
        labelKey,
        onDelete,
    });
    const { comeItems, goItems } = state;

    useEffect(() => {
        if (typeof onChange === 'function') {
            onChange({ comeItems: comeItems, goItems: goItems });
        }
    }, [comeItems, goItems]);

    return (
        <DispatchContext.Provider value={dispatch}>
            <StateContext.Provider value={state}>
                <BasketWrapper>
                    <Basket isCome items={comeItems} />
                    <Button
                        className="btn-trans min-w-none"
                        onClick={e => {
                            dispatch(addComeItems());
                        }}
                        iconName="keyboard_arrow_left"
                    >
                        {/*<FontAwesomeIcon icon={faChevronLeft} />*/}
                    </Button>
                    <Basket items={goItems} />
                </BasketWrapper>
            </StateContext.Provider>
        </DispatchContext.Provider>
    );
};

export default ComeGoBasket;
