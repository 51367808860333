import React, { useState, useEffect, useCallback, useRef } from 'react';
// import { Button } from 'reactstrap';
import { Button } from '@components';

import moment from 'moment';
import cx from 'classnames';

import PageTitle from '../../Components/PageTitle';
import DashboardFrame from '../DashboardFrame';
import useWidgetGenerator from '../util/useWidgetGenerator';
import { getMaxY } from '../util';

import { useDispatch, useSelector } from 'react-redux';
import { setEditDashboard, editWidgets, editLayout, setTempWidgetLayout } from '@reducer/Dashboards/DashboardEdit';
import { setMode } from '@reducer/Dashboards/DashboardFrame';

import { temp } from '../../../Widgets';
import PalettePopup from '../../../Widgets/common/PalettePopup';

import { getDashboardApi, updateDashboardApi } from '@api/dashboard';
import { ConfirmModal } from '@components';
import { useTranslation, useAsync } from '@hooks';

import { FiEdit } from 'react-icons/fi';

const DashboardEdit = ({ history, match }) => {
    const t = useTranslation('Dashboard');

    const dispatch = useDispatch();

    const { promise: getDashboardInfo } = useAsync({
        promise: getDashboardApi,
        param: { menuNum: Number(match.params.menuNum) },
        resolve: response => {
            dispatch(setEditDashboard(response));
        },
    });

    const { promise: saveWidgetInfo } = useAsync({
        promise: updateDashboardApi,
        resolve: () => {
            const location = history.location;
            if (location.state && location.state.redirect) {
                const currMenuNum = Number(match.params.menuNum);
                history.push(`/dashboards/${currMenuNum}`);
            } else {
                history.goBack();
            }
        },
    });

    const [preView, setPreView] = useState(false);
    const handlePreviewToggle = () => {
        const mode = preView ? 'edit' : 'preview';
        dispatch(setMode(mode));
        setPreView(!preView);
    };

    const [addWidgetPopup, setAddWidgetPopup] = useState(false);
    const handleAddWidgetPopup = () => {
        setAddWidgetPopup(!addWidgetPopup);
        dispatch(setTempWidgetLayout(null));
    };

    const [saveModal, setSaveModal] = useState(false);
    const toggleSaveModal = () => {
        setSaveModal(!saveModal);
    };

    const [cancelModal, setCancelModal] = useState(false);
    const toggleCancelModal = () => {
        setCancelModal(!cancelModal);
    };

    const [resetModal, setResetModal] = useState(false);
    const toggleResetModal = () => {
        setResetModal(!resetModal);
    };

    const handleSave = dashboardInfo => {
        const idPrefix = `d${dashboardInfo.menuNum}_`;
        const updateTime = moment().valueOf();
        saveWidgetInfo({
            ...dashboardInfo,
            widgetInfo: JSON.stringify(
                dashboardInfo.widgetInfo.reduce((acc, curr, i) => {
                    const newId = idPrefix + (updateTime + i).toString(36);
                    if (curr.type !== 'temp') {
                        acc.push({ ...curr, id: newId, layout: { ...curr.layout, i: newId } });
                    }
                    return acc;
                }, []),
            ),
        });
    };

    const editTimeout = useRef();
    const { origin, editable, tempWidgetLayout } = useSelector(state => state.DashboardEdit);
    const { layouts, Widgets } = useWidgetGenerator(editable.widgetInfo);

    const handleEditLayout = layout => {
        if (editTimeout.current) {
            clearTimeout(editTimeout.current);
            editTimeout.current = null;
        }
        editTimeout.current = setTimeout(() => {
            dispatch(editLayout(layout));
        }, 200);
    };

    const addPanel = () => {
        const id = moment().valueOf().toString(36);
        dispatch(
            editWidgets([
                ...editable.widgetInfo,
                {
                    id,
                    type: temp.type,
                    config: temp.config,
                    layout: {
                        ...temp.layout,
                        i: id,
                        x: 0,
                        y: getMaxY(layouts.lg),
                    },
                },
            ]),
        );
    };

    const backToOrigin = useCallback(() => {
        dispatch(editWidgets(origin.widgetInfo));
    }, [origin]);

    useEffect(() => {
        dispatch(setMode('edit'));

        if (!editable.menuNum || editable.menuNum !== Number(match.params.menuNum)) {
            dispatch(editWidgets([]));
            getDashboardInfo();
        }

        return () => {
            dispatch(setEditDashboard());
        };
    }, [match.url]);

    useEffect(() => {
        if (tempWidgetLayout) {
            setAddWidgetPopup(true);
        } else {
            setAddWidgetPopup(false);
        }
        return () => {
            setAddWidgetPopup(false);
        };
    }, [tempWidgetLayout]);

    // 패널 추가했을 때 스크롤 bottom으로 스크롤 되기 위한 로직 추가
    const widgetScrollTimeoutRef = useRef(null);
    const widgetLengthRef = useRef(0);

    const appPageContent = document.querySelector('.app-page-content');
    useEffect(() => {
        if (!Widgets) {
            return;
        }

        if (Widgets.length && widgetLengthRef.current && Widgets.length > widgetLengthRef.current) {
            widgetScrollTimeoutRef.current = setTimeout(() => {
                appPageContent.scrollIntoView({
                    block: 'end',
                    behavior: 'smooth',
                });
            }, 150);
        }
        widgetLengthRef.current = Widgets.length;

        return () => {
            if (editTimeout.current) {
                clearTimeout(editTimeout.current);
                editTimeout.current = null;
            }

            if (widgetScrollTimeoutRef.current) {
                clearTimeout(widgetScrollTimeoutRef.current);
                widgetScrollTimeoutRef.current = null;
            }
        };
    }, [Widgets]);

    return (
        <>
            <PageTitle
                className={cx(!preView && 'd-none', 'dashboard-edit-title')}
                customIcon={
                    <div className={'edit-icon-container'}>
                        <FiEdit color={'white'} />
                    </div>
                }
                heading={t('Preview')}
                enablePageTitleSubheading={true}
                subheading={t('It is a Dashboard Preview Page. Sample data is shown on this page.')}
                titleAction={
                    <Button className="me-2 btn-line btn-secondary" onClick={handlePreviewToggle}>
                        {t('End preview')}
                    </Button>
                }
            />

            <PageTitle
                className={cx(preView && 'd-none', 'dashboard-edit-title')}
                customIcon={
                    <div className={'edit-icon-container'}>
                        <FiEdit color={'white'} />
                    </div>
                }
                heading={t('Edit dashboard')}
                enablePageTitleSubheading={true}
                subheading={t('It is a Dashboard Edit Page. Sample data is shown on this page.')}
                titleAction={
                    <>
                        <Button className="me-2 btn-line btn-secondary" onClick={handlePreviewToggle}>
                            {t('Preview')}
                        </Button>
                        <Button className="me-2 btn-secondary" onClick={toggleResetModal}>
                            {t('Revert to the first')}
                        </Button>
                        <Button className="me-2 btn-secondary" onClick={toggleCancelModal}>
                            {t('Cancel')}
                        </Button>
                        <Button className="me-2 btn-secondary" onClick={addPanel}>
                            {t('Add panel')}
                        </Button>
                        <Button className="btn-icon btn-danger" iconName="save" onClick={toggleSaveModal}>
                            {t('Save')}
                        </Button>
                    </>
                }
            />
            <DashboardFrame layouts={layouts} onDragStop={handleEditLayout} onResizeStop={handleEditLayout}>
                {Widgets}
            </DashboardFrame>
            <PalettePopup initOpen={addWidgetPopup} toggleModal={handleAddWidgetPopup} layout={tempWidgetLayout} />
            <ConfirmModal
                initModal={saveModal}
                toggleModal={toggleSaveModal}
                confirmText={t('Do you want to save the layout?')}
                okCallback={handleSave}
                callbackParam={editable}
            />
            <ConfirmModal
                initModal={cancelModal}
                toggleModal={toggleCancelModal}
                confirmText={t('Do you want to exit editing mode?')}
                okCallback={() => {
                    backToOrigin();
                    history.goBack();
                }}
            />
            <ConfirmModal
                initModal={resetModal}
                toggleModal={toggleResetModal}
                confirmText={t('Do you want to reset edits?')}
                okCallback={() => {
                    backToOrigin();
                }}
            />
        </>
    );
};

export default DashboardEdit;
