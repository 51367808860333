import React from 'react';

import { useTranslation } from '@hooks';
import { dateToFormat } from '@util/common/util';
import { LoadingBlock } from '@components';

import PreviewModal from '../PreviewModal';

import ApprovalTable from './ApprovalTable';
import CheckListHeader from './CheckListHeader';
import PatrolDailyLogTable from './PatrolDailyLogTable';
import TakeOverTable from './TakeOverTable';

import { ONE_THOUSAND } from '../../utils';
import { DIVISION_OPTIONS, PATROL_DIVISION_PAGE_OPTIONS, TAKE_OVER_TABLE_COUNT } from './utils';

import cx from 'classnames';

const PatrolDailyLogPreviewModal = ({ initModal = false, toggleModal, request = {}, data = {}, isLoading = false }) => {
    const t = useTranslation('Patrol Log');

    const { patrolDivName, startDate } = request || {};

    const { note, rules, bedrooms, bilgeSoundings, patrolDailyReports } = data;

    const divisionOptions = DIVISION_OPTIONS.map(option => ({ ...option, label: t(option.label) }));

    const title = divisionOptions.find(({ value }) => value === patrolDivName) || divisionOptions[0];

    return (
        <PreviewModal
            initModal={initModal}
            toggleModal={toggleModal}
            className=""
            bodyClassName={cx(isLoading ? 'h-100' : 'd-flex flex-column gap-1 w-100 px-1')}
            header={{ title: title.label }}
            disabled={isLoading}
            modalSize={{ maxWidth: '60vw', height: '60vh' }}
            pageOptions={PATROL_DIVISION_PAGE_OPTIONS}
        >
            {isLoading ? (
                <LoadingBlock blocking={isLoading} />
            ) : (
                <>
                    <div className="d-flex">
                        <div className="w-100 d-grid align-content-between">
                            <h4 className="font-weight-bold text-center">{`${t('Safety Watch')} ${t(
                                'Patrol Daily Log',
                            )}[${title.label}]`}</h4>
                            <span className="color-black mt-1">{`${dateToFormat(
                                startDate * ONE_THOUSAND,
                                'LL',
                            )} ${dateToFormat(startDate * ONE_THOUSAND, 'dddd')}`}</span>
                        </div>
                        <div className="d-flex justify-content-end">
                            <ApprovalTable />
                        </div>
                    </div>
                    <div>
                        <CheckListHeader note={note} rules={rules} />
                        <PatrolDailyLogTable
                            patrolDivName={patrolDivName}
                            bedrooms={bedrooms}
                            bilgeSoundings={bilgeSoundings}
                            patrolDailyReports={patrolDailyReports}
                        />
                        <TakeOverTable cnt={TAKE_OVER_TABLE_COUNT[patrolDivName] || 3} />

                        {/* 침실 온도 라벨 표시 */}
                        <div className="d-flex gap-2 mt-1" style={{ fontSize: '0.6rem' }}>
                            <span className="color-black font-weight-bold">{t('Bedroom Temperature')}</span>
                            {bedrooms?.slice(0, 6).map(({ beaconNum, beaconName }, idx) => {
                                return (
                                    <div key={beaconNum} className="d-flex gap-2 border-bottom">
                                        <span className="color-black px-1" style={{ backgroundColor: 'lightgrey' }}>
                                            {idx + 1}
                                        </span>
                                        <span className="color-black me-1">{beaconName}</span>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </>
            )}
        </PreviewModal>
    );
};

export default PatrolDailyLogPreviewModal;
