import React from 'react';
import { Button, Col, Form, Input, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import useTranslation from '@hooks/useTranslation';
import { editWidgetSettings } from '@reducer/Dashboards/DashboardEdit';
import { Floor, WidgetName } from '../../Components/WidgetSettingOptions';
import { useSettings } from '../../util/useSettings';

const FloorSummarySetting = ({
    modal = false,
    toggleModal = function () {
        modal = !modal;
    },
    widgetInfo,
    ...restProps
}) => {
    const { config, layout } = widgetInfo;
    const settings = useSettings(config);
    const dispatch = useDispatch();
    const { register, handleSubmit } = useForm();
    const t = useTranslation('Button');
    const onSubmit = data => {
        dispatch(
            editWidgetSettings({
                id: widgetInfo.id,
                settings: data,
            }),
        );
        toggleModal();
    };

    return (
        <Modal isOpen={modal} toggle={toggleModal} {...restProps}>
            <ModalHeader toggle={toggleModal}>층 요약 정보 설정</ModalHeader>
            <ModalBody>
                <Form
                    onSubmit={e => {
                        e.preventDefault();
                        handleSubmit(onSubmit)(e);
                    }}
                >
                    <Input type="hidden" name="id" innerRef={register()} defaultValue={layout.i} />
                    <Col>
                        <WidgetName innerRef={register({ required: true })} defaultValue={settings.name} />
                    </Col>
                    {/*<Col>*/}
                    {/*    <IntervalTime innerRef={register({ required: true })} defaultValue={settings.intervalTime} />*/}
                    {/*</Col>*/}
                    <Col>
                        <Floor innerRef={register()} defaultValue={settings.floor} />
                    </Col>
                </Form>
            </ModalBody>
            <ModalFooter>
                <Button
                    type="submit"
                    color="primary"
                    onClick={e => {
                        e.preventDefault();
                        handleSubmit(onSubmit)(e);
                    }}
                >
                    {t('Ok')}
                </Button>
                <Button
                    onClick={e => {
                        e.preventDefault();
                        toggleModal();
                    }}
                >
                    {t('Cancel')}
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default FloorSummarySetting;
