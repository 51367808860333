import React, { useEffect, useState, useRef } from 'react';
import GridLayoutCard from '../../Components/GridLayoutCard';
import styled from 'styled-components';
import moment from 'moment';
import cx from 'classnames';

import { useTranslation, useAsync } from '@hooks';
import { Button, DatePicker, Select } from '@components';
import WidgetConfirmModal from '../../Components/WidgetConfirmModal';

import { createPatrolDailyRegAPI, getPatrolDailyPatroldivAPI, getPatrolDailySearchAPI } from '@api/military';

const PatrolManagement = ({ children, widgetInfo, ...restProps }) => {
    const t = useTranslation('Patrol Search');
    const patrolInputRef = useRef('');

    const currentDate = moment().startOf('day').unix();

    const [searchDate, setSearchDate] = useState(currentDate);
    const [inputValue, setInputValue] = useState('');
    const [selectOptions, setSelectOptions] = useState([]);
    const [selectedOption, setSelectedOption] = useState({});
    const [isUpdate, setIsUpdate] = useState(false);

    // 모달 상태들
    const [successModal, setSuccessModal] = useState(false);
    const [failModal, setFailModal] = useState(false);

    useAsync({
        promise: getPatrolDailyPatroldivAPI,
        immediate: true,
        resolve: res => {
            const { rows } = res;
            setSelectOptions(
                rows.map(({ patrolDiv, patrolDivName }) => {
                    return {
                        label: patrolDivName,
                        value: patrolDiv,
                    };
                }),
            );
        },
        reject: err => {
            console.error(err);
        },
    });

    const { promise: createPatrolDailyReg } = useAsync({
        promise: createPatrolDailyRegAPI,
        resolve: () => {
            toggleSuccessModal();
            getPatrolDailySearch({
                patrolDiv: selectedOption.value,
                searchDate,
            });
        },
        reject: err => {
            console.error(err);
        },
    });

    const { promise: getPatrolDailySearch, state: patrolDailSearchState } = useAsync({
        promise: getPatrolDailySearchAPI,
        resolve: res => {
            const { note } = res;
            setInputValue(note || '');
            setIsUpdate(note !== undefined);

            // 새로고침시 inputArea 초기화
            const {
                request: { patrolDiv, searchDate },
            } = patrolDailSearchState;
            if (patrolDiv === selectOptions[0].value && searchDate === currentDate && note) {
                patrolInputRef.current = note;
            }
        },
        reject: err => {
            console.error(err);
        },
    });

    useEffect(() => {
        if (selectedOption.label && searchDate) {
            getPatrolDailySearch({
                patrolDiv: selectedOption.value,
                searchDate,
            });
        }
    }, [selectedOption, searchDate]);

    const handleInputValue = event => {
        const { value } = event.target;
        setInputValue(value.slice(0, 500));
    };

    const handleSaveClick = () => {
        createPatrolDailyReg({
            note: inputValue,
            patrolDiv: selectedOption.value,
            patrolDivName: selectedOption.label,
            patrolDate: searchDate,
        });
    };

    const handleRefreshClick = () => {
        setSearchDate(currentDate);
        if (selectOptions.length) {
            setSelectedOption(selectOptions[0]);
        }
        setInputValue(patrolInputRef.current);
    };

    const toggleSuccessModal = () => {
        setSuccessModal(!successModal);
    };

    const toggleFailModal = () => {
        setFailModal(!failModal);
    };

    useEffect(() => {
        if (selectOptions.length) {
            setSelectedOption(selectOptions[0]);
        }
    }, [selectOptions]);

    return (
        <>
            <GridLayoutCard
                widgetInfo={widgetInfo}
                {...restProps}
                headerAction={
                    <>
                        <Button onClick={handleRefreshClick} className="btn-danger btn-icon-only" iconName="refresh" />
                        <div className="pnt-border border-h" />
                        <Button
                            onClick={handleSaveClick}
                            className={cx(isUpdate ? 'btn-lightgray' : 'btn-secondary', 'btn-icon')}
                            iconName={isUpdate ? 'edit' : 'save'}
                        >
                            {t(isUpdate ? 'Edit' : 'Save')}
                        </Button>
                    </>
                }
            >
                <SearchContainer>
                    <div className="d-flex gap-1">
                        <DatePickerContainer>
                            <DatePicker
                                value={searchDate}
                                handleChange={selected => setSearchDate(selected)}
                                withoutTime
                            />
                        </DatePickerContainer>
                        <Select
                            name={'status'}
                            value={selectedOption}
                            options={selectOptions}
                            onChange={selected => {
                                setSelectedOption(selected);
                            }}
                        />
                    </div>
                </SearchContainer>
                <InputArea value={inputValue} onChange={handleInputValue} className="pnt-input" />
                <div>{`${inputValue.length} / 500`}</div>
                {children}
            </GridLayoutCard>
            <WidgetConfirmModal
                initModal={successModal}
                toggleModal={toggleSuccessModal}
                header={{ title: t('Notification', 'ConfirmModal') }}
                confirmText={t('Your request was successful.')}
                removeCancel
            />
            <WidgetConfirmModal
                initModal={failModal}
                toggleModal={toggleFailModal}
                header={{ title: t('Notification', 'ConfirmModal') }}
                confirmText={t('Your request was failed.')}
                removeCancel
            />
        </>
    );
};

const SearchContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;

const DatePickerContainer = styled.div`
    width: 10rem;
`;

const InputArea = styled.textarea`
    width: 100%;
    height: calc(100% - 4.6rem);
    padding: 0.5rem;
    resize: none;
`;

export default PatrolManagement;
