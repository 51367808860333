import React, { useEffect, useState } from 'react';

import { useAsync, useTranslation } from '@hooks';

import { getUtilizationStatisticsApi } from '@api/dashboard';
import { getStatusCategoryInfoApi } from '@api/status';

import { useSelector } from 'react-redux';

import Card from '../../Components/WidgetCard';
import UtilizationBigChartVer from './Components/UtilizationBigChartVer';
import { useSettings } from '../../util/useSettings';
import useMonitor from '../../util/useMonitor';

import { secToHms, secDisplayFormat } from '@util/common/util';

const defaultData = {
    code: 0,
    count: 0,
    result: 'success',
    rows: [
        {
            averageCount: 3.2,
            averageLeadTime: 23456,
            averageTime: 12345,
            comNum: 100003,
            countByStatus: 104,
            serviceCode: 'AM01',
            statusId: 'in-use',
            statusName: 'In Use',
            statusNum: 1004,
            totalCount: 256,
            displayColor: '#3E66FB',
        },
    ],
};
const tempData = {
    rows: [
        {
            totalCount: 0,
            countByStatus: 0,
            averageCount: 0,
            averageTime: null,
            averageLeadTime: null,
            displayColor: '#3E66FB',
        },
    ],
};
const UtilizationBigChartVerWidget = ({ children, widgetInfo, ...restProps }) => {
    let { config } = widgetInfo;
    const { selectedOption } = useSettings(config);
    const [initialConfig, setInitialConfig] = useState(config);
    const [categoryName, setCategoryName] = useState('');
    const { mode } = useSelector(state => state.DashboardFrame);
    const { filterParam } = useSelector(state => state.FilterInfo);
    const t = useTranslation('');

    const { promise: getStatusCategory } = useAsync({
        promise: getStatusCategoryInfoApi,
        fixedParam: { isAll: 'Y' },
        resolve: res => {
            if (res.rows.length) {
                setInitialConfig({
                    ...config,
                    settings: {
                        ...config.settings,
                        statusCategoryNum: res.rows[0].statusCategoryNum,
                    },
                });
                setCategoryName(res.rows[0].statusCategoryName);
            }
        },
    });

    useEffect(() => {
        if (
            config.defaultSettings &&
            !config.defaultSettings.statusCategoryNum &&
            (!config.settings || (config.settings && !config.settings.statusCategoryNum))
        ) {
            getStatusCategory();
        } else {
            setCategoryName(selectedOption && selectedOption.label);
        }
    }, []);

    const {
        data: { totalCount, countByStatus, averageCount, averageTime, averageLeadTime, displayColor },
    } = useMonitor({
        config: initialConfig,
        dynamicParam: filterParam,
        defaultData: mode === 'monitoring' ? tempData : defaultData,
        paramInfo: ['statusCategoryNum'],
        fetchData: getUtilizationStatisticsApi,
        makeData: ({ newData }) => {
            return newData.rows && newData.rows.length > 0 ? newData.rows[0] : tempData.rows[0];
        },
    });

    return (
        <Card additionalTitle={' - ' + categoryName} widgetInfo={widgetInfo} {...restProps}>
            <UtilizationBigChartVer
                avgCount={averageCount || 0}
                avgTime={secDisplayFormat(secToHms(averageTime, true, t, true))}
                avgLeadTime={secDisplayFormat(secToHms(averageLeadTime, true, t, true))}
                usedNum={countByStatus}
                totalNum={totalCount}
                mainColor={displayColor}
            />
            {children}
        </Card>
    );
};

export default UtilizationBigChartVerWidget;
