import { createSlice } from '@reduxjs/toolkit';
import cloneDeep from 'lodash/cloneDeep';

import { defaultStaticFilterInfo } from '../Components/MainPages/Filter/FilterWidget/Option';
import { makeFilterParam } from '../Components/MainPages/Filter/reducer';

export const initialFilterInfo = {
    filterInfoCondition: defaultStaticFilterInfo,
};

const initialState = {
    filterList: [],
    updatedFilterList: [],
    filterInfo: initialFilterInfo,
    filterParam: {},
};

const { actions, reducer } = createSlice({
    name: 'filterInfo',
    initialState,
    reducers: {
        setFilterList: (state, action) => {
            state.filterList = action.payload;
        },
        setUpdatedFilterList: (state, action) => {
            state.updatedFilterList = action.payload;
        },
        setSelectedFilter: (state, action) => {
            let filterInfo = cloneDeep(action.payload);
            const { filterInfoCondition } = filterInfo;

            filterInfo.filterInfoCondition = filterInfoCondition.map(filterInfo => {
                const filter = initialFilterInfo.filterInfoCondition.find(
                    defaultFilter => filterInfo.conditionId === defaultFilter.conditionId,
                );
                return { ...filter, ...filterInfo };
            });

            state.filterInfo = filterInfo;
            if (filterInfo && filterInfo.filterInfoCondition) {
                state.filterParam = makeFilterParam(filterInfo.filterInfoCondition);
            } else {
                state.filterParam = {};
            }
        },
        updateFilterInfo: (state, action) => {
            const filterInfo = action.payload;
            if (filterInfo && filterInfo.filterInfoCondition) {
                state.filterInfo.filterInfoCondition = filterInfo.filterInfoCondition;
                state.filterParam = makeFilterParam(filterInfo.filterInfoCondition);
            } else {
                state.filterInfo.filterInfoCondition = initialFilterInfo.filterInfoCondition;
                state.filterParam = {};
            }
        },
    },
});

export const { setFilterList, setUpdatedFilterList, setSelectedFilter, updateFilterInfo } = actions;
export default reducer;
