import React from 'react';
import { Route } from 'react-router-dom';

import { fetchCategory } from 'api/asset';
import useAsync from '@hooks/useAsync';

import { ErrorHandleSwitch } from '../Components/Router';

import { useDispatch, useSelector } from 'react-redux';
import { setAllCategoryList } from 'reducers/Common/CategoryInfo';

import LocationLog from './LocationLog';
import LocationLogFileList from './LocationLogFileList';
import SensorLog from './SensorLog';
import ScannerStateLog from './ScannerStateLog';
import GeofenceLog from './GeofenceLog';
import IotItemInfoLog from './IotItemInfoLog';
import IotItemMappingLog from './IotItemMappingLog';
import LoginHistory from './LoginHistory';

import LocationApprovalLog from './LocationApprovalLog';
import PatrolLog from './PatrolLog';

const Report = ({ match }) => {
    const storeDispatch = useDispatch();
    const { serviceCategory } = useSelector(state => state.CategoryInfo);

    useAsync({
        promise: fetchCategory,
        param: { isAll: 'Y', parentCode: serviceCategory },
        resolve: ({ rows }) => {
            storeDispatch(setAllCategoryList(rows));
        },
        immediate: true,
    });

    return (
        <ErrorHandleSwitch>
            <Route exact path={`${match.path}/location-log`} component={LocationLog} />
            <Route exact path={`${match.path}/location-log/list`} component={LocationLogFileList} />
            <Route exact path={`${match.path}/scanner-log/`} component={ScannerStateLog} />
            <Route exact path={`${match.path}/sensor-log`} component={SensorLog} />
            <Route exact path={`${match.path}/geofence-log`} component={GeofenceLog} />
            <Route exact path={`${match.path}/iotItem-log/list`} component={IotItemInfoLog} />
            <Route exact path={`${match.path}/iotItem-mapping-log/list`} component={IotItemMappingLog} />
            <Route exact path={`${match.path}/login-log/list`} component={LoginHistory} />

            {/* Smart Military */}
            <Route exact path={`${match.path}/location-approval-log`} component={LocationApprovalLog} />
            <Route exact path={`${match.path}/patrol-log`} component={PatrolLog} />
        </ErrorHandleSwitch>
    );
};

export default Report;
