import React, { useState, forwardRef } from 'react';
import { Card as RSCard, CardBody, CardHeader } from 'reactstrap';
import cx from 'classnames';

import { useDispatch, useSelector } from 'react-redux';
import { deleteWidget } from '@reducer/Dashboards/DashboardEdit';
import { SCREEN_MODE_EDIT } from '@reducer/Dashboards/DashboardFrame';

import { ConfirmModal, Button } from '@components';
import { getUsableWidgetInfo } from '../../MainPages/Dashboards/util';
import { useSettings } from '../util/useSettings';
import useTranslation from '@hooks/useTranslation';
import { WIDGET_TYPE_TEMP } from '../common/TemporaryWidget';

const HeaderButton = ({ children, ...restProps }) => {
    return (
        <Button className="btn-lightgray btn-icon-only ms-2" iconName="menu" {...restProps}>
            {children}
        </Button>
    );
};

const Card = (
    { children, subTitle, additionalTitle = '', widgetInfo, headerAction, className, bodyClassName, ...restProps },
    ref,
) => {
    children = [...children];
    const { id, type, config, name } = widgetInfo;
    const t = useTranslation('Widget');
    const resizeHandler = children.pop();
    const { settings: customSettings } = config;
    const settings = useSettings(config);
    const { SettingComponent } = getUsableWidgetInfo(type);
    const dispatch = useDispatch();
    const { mode } = useSelector(state => state.DashboardFrame);

    const [deleteModal, setDeleteModal] = useState(false);
    const toggleDeleteModal = () => {
        setDeleteModal(!deleteModal);
    };
    const handleDelete = () => {
        dispatch(deleteWidget(id));
    };

    const [settingModal, setSettingModal] = useState(false);
    const toggleSettingModal = () => {
        setSettingModal(!settingModal);
    };

    const displayName = () => {
        const { name: settingName } = settings;

        let title = '';
        if (customSettings && customSettings.name && customSettings.name !== t(settingName)) {
            title = customSettings.name;
        } else {
            title = type !== WIDGET_TYPE_TEMP ? t(name) : t('New widget');
        }

        return title + additionalTitle;
    };

    return (
        <>
            <RSCard {...restProps} className={cx(className, 'widget-card')} innerRef={ref}>
                <CardHeader
                    className={cx(
                        'widget-header',
                        mode !== SCREEN_MODE_EDIT && settings.hiddenHeader && 'hidden-header',
                    )}
                >
                    <div className={'card-header__title'}>
                        <div className={'title-wrap'}>
                            <h3 className={'title__main'}>{displayName()}</h3>
                            {!!subTitle && <p className={'title__sub'}>{subTitle}</p>}
                        </div>
                    </div>
                    {headerAction && <div className={'card-header__function'}>{headerAction}</div>}
                    {mode === SCREEN_MODE_EDIT && (
                        <div className={'widget-edit-btn'}>
                            {SettingComponent && <HeaderButton onClick={toggleSettingModal} />}
                            <Button
                                className="btn-lightgray btn-icon-only ms-2"
                                iconClassName="icon-close"
                                iconName="icon-close"
                                onClick={toggleDeleteModal}
                            />
                        </div>
                    )}
                </CardHeader>
                <CardBody className={bodyClassName}>{children}</CardBody>
                {resizeHandler}
            </RSCard>
            {mode === SCREEN_MODE_EDIT && (
                <ConfirmModal
                    initModal={deleteModal}
                    toggleModal={toggleDeleteModal}
                    header={{ title: t('Confirm', 'ConfirmModal') }}
                    confirmText={t('Do you want to delete the widget?')}
                    okCallback={handleDelete}
                />
            )}
            {SettingComponent && (
                <SettingComponent widgetInfo={widgetInfo} modal={settingModal} toggleModal={toggleSettingModal} />
            )}
        </>
    );
};

export default forwardRef(Card);
