import React, { useEffect, useState } from 'react';

import { Label } from '@components';

import { useTranslation } from '@hooks';

import DraggableToast from './DraggableToast';

import styled from 'styled-components';
import cx from 'classnames';

const POPUP_LABELS = [
    { label: 'Name', value: 'targetName' },
    { label: 'Rank', value: 'rank' },
    { label: 'Department', value: 'department' },
    { label: 'Jobtitle', value: 'jobtitle' },
    { label: 'LTE Number', value: 'lte' },
    { label: 'Normal Number', value: 'telephone' },
];

const GeofenceLayerToast = ({ innerRef, title, bounds, initModal = false, toggleModal, data: { count, rows } }) => {
    const t = useTranslation('Integrated Monitor');

    const [selected, setSelected] = useState(null);

    const handlePersonnelClick = row => {
        if (selected && selected.targetId === row.targetId) {
            setSelected(null);
            return;
        }
        setSelected(row);
    };

    useEffect(() => {
        if (selected) {
            const idx = rows.findIndex(({ targetId }) => targetId === selected.targetId);
            if (idx === -1) {
                setSelected(null);
            }
        }
    }, [rows]);

    return (
        <DraggableToast
            bounds={bounds}
            initModal={initModal}
            toggleModal={toggleModal}
            title={title}
            className="geofence-toast-layout"
            bodyClassName="p-0 d-flex flex-column"
            innerRef={innerRef}
        >
            <CountContainer className="color-secondary">{count}</CountContainer>
            <PersonnelContentContainer>
                {rows &&
                    rows.map(row => {
                        const { rank, targetName, jobtitle, department, targetId } = row;
                        const value = `${rank || ''} ${targetName}(${jobtitle || ''}/${department || ''})`;
                        return (
                            <PersonnelContainer
                                className={cx(
                                    'cursor-pointer',
                                    selected && selected.targetName === targetName && 'bg-lightgray',
                                )}
                                key={targetId}
                                title={value}
                                onClick={() => handlePersonnelClick(row)}
                            >
                                {value}
                            </PersonnelContainer>
                        );
                    })}
            </PersonnelContentContainer>
            {selected && (
                <ContentContainer>
                    {POPUP_LABELS.map(labels => {
                        const { label, value } = labels;
                        return (
                            <Label
                                labelValueClassName="color-darkgray"
                                name={t(label)}
                                key={value}
                                value={selected[value]}
                            />
                        );
                    })}
                </ContentContainer>
            )}
        </DraggableToast>
    );
};

const CountContainer = styled.div`
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
`;

const PersonnelContentContainer = styled.div`
    border-top: 1px solid;
    flex: 1;
    overflow-y: auto;
`;

const ContentContainer = styled(PersonnelContentContainer)`
    padding: 1rem;
`;

const PersonnelContainer = styled.div`
    padding: 0.4rem 1rem;
    border-bottom: 1px solid lightgray;

    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    :hover {
        background-color: lightgray;
    }
`;

export default GeofenceLayerToast;
