import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { falsyToHyphen } from '@util/common/util';

const StyledStatus = styled.span`
    max-width: 100%;
    height: 25px;
    color: ${props => props.color} !important;
    font-weight: bold;
`;

export const StyledStatusLabel = ({ statusName, displayColor, className }) => {
    return (
        <StyledStatus
            className={`pnt-txt txt-border text-ellipsis ${className}`}
            color={displayColor}
            title={statusName}
        >
            {statusName}
        </StyledStatus>
    );
};

export const departmentName = ({ ...restProps } = {}) => {
    const { t } = useTranslation();
    return {
        Header: t('Table;Name'),
        width: 100,
        accessor: 'groupName',
        ...restProps,
    };
};

export const departmentID = ({ ...restProps } = {}) => {
    const { t } = useTranslation();
    return {
        Header: t('Table;ID'),
        width: 100,
        accessor: 'groupId',
        ...restProps,
    };
};

export const departmentPath = ({ ...restProps } = {}) => {
    const { t } = useTranslation();
    return {
        Header: t('Table;Department path'),
        accessor: 'groupNamePath',
        ...restProps,
    };
};

export const userName = ({ ...restProps } = {}) => {
    const { t } = useTranslation();
    return {
        Header: t('Table;User name'),
        width: 100,
        accessor: 'userName',
        ...restProps,
    };
};

export const userID = ({ ...restProps } = {}) => {
    const { t } = useTranslation();
    return {
        Header: t('Table;ID'),
        accessor: 'userID',
        className: 'text-ellipsis',
        ...restProps,
    };
};

export const userTeam = ({ ...restProps } = {}) => {
    const { t } = useTranslation();
    return {
        Header: t('Table;Team'),
        accessor: 'groupName',
        ...restProps,
    };
};

export const checkBox = ({ ...restProps } = {}) => {
    return {
        Header: ({}) => {
            return <input type="checkbox" />;
        },
        width: 50,
        accessor: 'checkBox',
        Cell: ({ row: { original } }) => {
            return (
                <input
                    type="checkbox"
                    name={'categoryCode'}
                    onClick={e => {
                        e.stopPropagation();
                    }}
                />
            );
        },
        ...restProps,
    };
};
export const category = ({ ...restProps } = {}) => {
    return {
        Header: 'Category',
        accessor: 'categoryNamePath',
        className: 'text-ellipsis',
        headerClassName: 'text-ellipsis',
        ...restProps,
    };
};

export const name = ({ ...restProps } = {}) => {
    return {
        Header: 'Name',
        accessor: 'targetName',
        className: 'text-ellipsis',
        headerClassName: 'text-ellipsis',
        ...restProps,
    };
};

export const filterName = ({ ...restProps } = {}) => {
    const { t } = useTranslation();
    return {
        Header: t('Table;Filter Name'),
        accessor: 'filterName',
        width: 100,
        ...restProps,
    };
};

export const num = ({ ...restProps } = {}) => {
    return {
        Header: 'Number',
        accessor: 'targetNum',
        headerClassName: 'text-ellipsis',
        className: 'text-ellipsis',
        ...restProps,
    };
};

export const location = ({ ...restProps } = {}) => {
    return {
        Header: 'Location',
        accessor: 'floorNamePath',
        headerClassName: 'text-ellipsis',
        className: 'text-ellipsis',
        ...restProps,
    };
};

export const status = ({ ...restProps } = {}) => {
    return {
        Header: 'Status',
        accessor: 'statusId',
        headerClassName: 'text-ellipsis',
        className: 'text-ellipsis',
        ...restProps,
    };
};

export const owner = ({ ...restProps } = {}) => {
    return {
        Header: 'Owner',
        accessor: 'properties',
        headerClassName: 'text-ellipsis',
        className: 'text-ellipsis',
        Cell: ({ value }) => {
            if (value.pic_id) {
                return <span>{value.pic_id}</span>;
            } else {
                return <span />;
            }
        },
        ...restProps,
    };
};

export const ownerForFilter = ({ ...restProps } = {}) => {
    const { t } = useTranslation();
    return {
        Header: t('Table;Owner'),
        accessor: 'groupNamesForOwner',
        Cell: ({ value }) => {
            if (value === '-1') {
                return <span>{t('Filter;All users')}</span>;
            } else {
                return <span>{value}</span>;
            }
        },
        ...restProps,
    };
};

export const accessRights = ({ ...restProps } = {}) => {
    const { t } = useTranslation();
    return {
        Header: t('Table;Access rights'),
        accessor: 'groupNamesForAccessRight',
        Cell: ({ value }) => {
            if (value === '-1') {
                return <span>{t('Filter;All users')}</span>;
            } else {
                return <span>{value}</span>;
            }
        },
        ...restProps,
    };
};

export const favorite = ({ handleOpenModal, ...restProps } = {}) => {
    const { t } = useTranslation();
    return {
        Header: t('Table;Favorite'),
        accessor: 'favorite',
        width: 50,
        Cell: ({ value }) => {
            return (
                <button
                    onClick={e => {
                        e.stopPropagation();
                        handleOpenModal();
                    }}
                >
                    {value ? (
                        <span className="icon-star icon-filter-red" />
                    ) : (
                        <span className="icon-star icon-filter-gray" />
                    )}
                </button>
            );
        },
        ...restProps,
    };
};

export const operator = ({ ...restProps } = {}) => {
    return {
        Header: 'Operator',
        accessor: 'properties',
        headerClassName: 'text-ellipsis',
        className: 'text-ellipsis',
        Cell: ({ value }) => {
            const { rank, jobtitle, operator } = value || {};
            return [falsyToHyphen(jobtitle), falsyToHyphen(rank), falsyToHyphen(operator)].join(' / ');
        },
        formatTitle: ({ value }) => {
            const { rank, jobtitle, operator } = value || {};
            return [falsyToHyphen(jobtitle), falsyToHyphen(rank), falsyToHyphen(operator)].join(' / ');
        },
        ...restProps,
    };
};
