import React, { useEffect, useState } from 'react';

import Button from '@components/Button';
import { useAsync, useTranslation } from '@hooks';

import { getBoardingListAPI } from '@api/military';

import NumberOfPassengers from './Components/NumberOfPassengers';
import PassengersDetail from './Components/PassengersDetail';
import Search from './Components/Search';
import GridLayoutCard from '../../Components/GridLayoutCard';

import moment from 'moment';

const StatusOfEntryAndExitOfVessel = ({ children, widgetInfo, ...restProps }) => {
    const t = useTranslation('Status Of Entry And Exit Of Vessel');

    const { config } = widgetInfo;

    const [searchOption, setSearchOption] = useState({
        selectedOption: { value: 'ALL', label: t('All') },
        searchInput: '',
    });

    const [lastSearch, setLastSearch] = useState(null);
    const [loading, setLoading] = useState(false);

    const [showSearchArea, setShowSearchArea] = useState(false);
    const toggleSearchArea = () => {
        if (loading) {
            return setShowSearchArea(false);
        }
        setShowSearchArea(prevState => !prevState);
    };

    const handleSearchClick = ({ option, inputValue }) => {
        setSearchOption({ selectedOption: option, searchInput: inputValue });
        setShowSearchArea(false);
    };

    const [tableData, setTableData] = useState({
        rows: [],
    });

    const { promise: getBoardingList, abort: abortBoardingList } = useAsync({
        promise: getBoardingListAPI,
        immediate: true,
        fixedParam: { isAll: 'Y' },
        resolve: res => {
            setTableData(res);
            setLastSearch(moment.now());
            setLoading(false);
        },
        reject: err => {
            if (err.message && err.message === 'canceled') return;

            console.error(err);
            setLoading(false);
        },
    });

    useEffect(() => {
        if (searchOption) {
            const { selectedOption, searchInput } = searchOption;
            abortBoardingList();
            setLoading(true);
            getBoardingList({
                containsStatus: !selectedOption || selectedOption['value'] === 'ALL' ? null : selectedOption['value'],
                target: searchInput,
            });
        }
    }, [searchOption]);

    return (
        <GridLayoutCard
            widgetInfo={widgetInfo}
            {...restProps}
            headerAction={
                <Button className="btn-secondary" onClick={toggleSearchArea} disabled={loading}>
                    {t('Search', 'Button')}
                </Button>
            }
            searchFilter={
                showSearchArea && <Search searchOption={searchOption} handleSearchClick={handleSearchClick} />
            }
        >
            <div className="font-size-lg font-weight-bold">
                {`${t('Lookup Time')}: ${!lastSearch ? '-' : moment(lastSearch).format('YYYY-MM-DD HH:mm:ss')}`}
            </div>
            <NumberOfPassengers config={config} searchOption={searchOption} handleSearchClick={handleSearchClick} />
            <PassengersDetail tableData={tableData} loading={loading} />
            {children}
        </GridLayoutCard>
    );
};

export default StatusOfEntryAndExitOfVessel;
