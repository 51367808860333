import React from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import cx from 'classnames';

import Button from '@components/Button';
import { useTranslation } from '@hooks';

import { usePDF, Margin } from 'react-to-pdf';

const PreviewModal = ({
    initModal = false,
    toggleModal,
    className,
    bodyClassName,
    children,
    filename = 'patrolReport.pdf',
    pageOptions = {
        margin: Margin.MEDIUM,
    },
    disabled = false,
    modalSize = { maxWidth: '42vw', height: '75vh' },
}) => {
    const t = useTranslation('Dashboard');

    const { toPDF, targetRef } = usePDF({
        filename,
        page: pageOptions,
        method: 'open',
    });

    const handlePrint = () => {
        if (typeof toPDF === 'function') {
            toPDF();
        }
        if (typeof toggleModal === 'function') {
            toggleModal();
        }
    };

    return (
        <Modal
            container={document.getElementsByClassName('app-container')[0]}
            className={cx('pnt-modal', className)}
            isOpen={initModal}
            toggle={toggleModal}
            style={{ maxWidth: modalSize.maxWidth, minWidth: '32rem' }}
        >
            <ModalHeader toggle={toggleModal}>{t('Preview')}</ModalHeader>
            <ModalBody style={{ height: modalSize.height, overflowY: 'scroll' }}>
                <div ref={targetRef} className={cx(bodyClassName)}>
                    {children}
                </div>
            </ModalBody>
            <ModalFooter>
                <Button className="btn-trans" onClick={toggleModal}>
                    {t('Cancel', 'Button')}
                </Button>
                <Button className="btn-brand" onClick={handlePrint} disabled={disabled}>
                    {t('Ok', 'Button')}
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default PreviewModal;
