import React, { useEffect, useState } from 'react';
import useTranslation from '../../../../util/hooks/useTranslation';
import Table from '../../../Common/Table';
import useAsync from '../../../../util/hooks/useAsync';
import { useHistory } from 'react-router-dom';
import Label from '../../../Common/Label';
import { getFlowSchemeListInfoApi } from '../../../../api/status';
import { getStatusFlowCategoryMapInfoApi } from '../../../../api/status';
import useColumns from '../../../../util/hooks/useColumns';
import { fetchCategory } from '../../../../api/asset';
import { useDispatch, useSelector } from 'react-redux';
import { setFlowSchemeListInfo, setStatusFlowCategoryMapInfo } from '../../../../reducers/Common/StatusInfo';
import { setCategoryList } from '../../../../reducers/Common/CategoryInfo';
import Card from '../../../Common/Card';

const AllocateAssets = () => {
    const dispatch = useDispatch();
    const t = useTranslation('Status');
    const { flowSchemeListInfo, flowCategoryMappedStatusInfo } = useSelector(state => state.StatusInfo);
    const { serviceCategory, categoryList } = useSelector(state => state.CategoryInfo);
    const [dataRows, setDataRows] = useState({ rows: [] });
    const history = useHistory();

    const columns = useColumns([
        {
            Header: 'Status Flow Scheme',
            className: 'flex-center',
            Cell: ({ row: { original } }) => {
                return (
                    <div>
                        <div style={{ fontWeight: 'bold' }}>{original.flowName}</div>
                        <div>{original.description}</div>
                    </div>
                );
            },
        },
        {
            Header: 'Allocate Asset Categories',
            accessor: 'allocateAssetCategory',
            className: 'flex-center',
            Cell: ({ value }) => {
                return (
                    <div>
                        {value.map((data, idx) => {
                            const { categoryCode, categoryNamePath } = data;
                            return (
                                categoryNamePath && (
                                    <div key={categoryCode + idx}>
                                        <Label name={categoryNamePath} labelValueClassName={'label-dot'} />
                                    </div>
                                )
                            );
                        })}
                    </div>
                );
            },
        },
        {
            Header: 'Asset Count',
            accessor: 'itemCount',
            className: 'flex-center',
        },
    ]);

    const { promise: getCategory } = useAsync({
        promise: fetchCategory,
        fixedParam: { isAll: 'Y', parentCode: serviceCategory },
        resolve: res => {
            dispatch(setCategoryList(res.rows));
        },
    });

    const { promise: getTargetStatusFlowInfo } = useAsync({
        promise: getFlowSchemeListInfoApi,
        fixedParam: { isAll: 'Y' },
        resolve: res => {
            dispatch(setFlowSchemeListInfo(res));
        },
    });

    const { promise: getStatusFlowCategoryMapInfo } = useAsync({
        promise: getStatusFlowCategoryMapInfoApi,
        fixedParam: { isAll: 'Y' },
        resolve: res => {
            dispatch(setStatusFlowCategoryMapInfo(res));
        },
    });

    useEffect(() => {
        getStatusFlowCategoryMapInfo();
        getTargetStatusFlowInfo();
        getCategory();
    }, []);

    useEffect(() => {
        const rows = [];
        flowSchemeListInfo.rows.forEach(singleTarget => {
            rows.push({
                flowNum: singleTarget.flowNum,
                description: singleTarget.description,
                flowName: singleTarget.flowName,
                itemCount: singleTarget.itemCount,
                allocateAssetCategory: flowCategoryMappedStatusInfo.rows
                    .filter(({ flowNum }) => {
                        return flowNum === singleTarget.flowNum;
                    })
                    .map(mappingInfo => {
                        if (categoryList.length) {
                            const categoryInfo = categoryList.find(v => v.categoryCode === mappingInfo.categoryCode);
                            if (categoryInfo) {
                                return {
                                    ...mappingInfo,
                                    categoryNamePath: categoryInfo.categoryNamePath,
                                };
                            }
                        }
                        return mappingInfo;
                    }),
            });
        });
        setDataRows({ rows });
    }, [flowCategoryMappedStatusInfo.rows, flowSchemeListInfo, categoryList]);

    return (
        <Card
            header={{
                title: t('Allocate Assets'),
                subTitle: t('It is a function of allocating assets to the Status flow Scheme.'),
            }}
        >
            <div style={{ height: '43rem' }}>
                <Table
                    data={{ ...dataRows, pageSize: flowSchemeListInfo.totalCount }}
                    columns={columns}
                    paging={false}
                    onTrClick={info => history.push(`/status/allocate/${info.flowNum}`)}
                    dynamicRowHeight
                />
            </div>
        </Card>
    );
};

export default AllocateAssets;
