import React, { useContext, useEffect, useMemo, useState } from 'react';

import useTranslation from '@hooks/useTranslation';
import { SearchableSelect, TimePicker, Button } from '@components';

import FilterSearchGroup from '@components/FilterSearchGroup';
import { FilterList, SelectGroup } from '@components/FilterSearchGroup/Components/Part';
import FilterGroup from '@components/FilterSearchGroup/Components/FilterGroup';
import FilterButtonArea from '@components/FilterSearchGroup/Components/FilterButtonArea';

import { SensorLogStateContext } from '../index';

import moment from 'moment';

const Search = ({ getLocationLogList }) => {
    const t = useTranslation('Sensor Log');

    const { selectableSensorList } = useContext(SensorLogStateContext);

    const searchOptions = useMemo(() => {
        return [
            { value: '', label: t('Search Option', 'Search') },
            { value: 'targetName', label: t('Target Name') },
            { value: 'tagName', label: t('Device Name') },
        ];
    }, [t]);
    const [selectedOption, setSelectedOption] = useState(searchOptions[0]);

    const [searchInput, setSearchInput] = useState([]);

    const [startDate, setStartDate] = useState(moment().startOf('day').valueOf());
    const [endDate, setEndDate] = useState(moment().endOf('day').valueOf());

    useEffect(() => {
        if (selectedOption) {
            setSelectedOption(searchOptions.find(v => v.value === selectedOption.value));
        }
    }, [searchOptions]);

    // useEffect(() => {
    //     getLocationLogList({
    //         startDate: moment(startDate).unix(),
    //         endDate: moment(endDate).unix(),
    //     });
    // }, []);

    const handleRefreshClick = () => {
        setSelectedOption(searchOptions[0]);
        setSearchInput([]);

        const startDate = moment().startOf('day').valueOf();
        const endDate = moment().endOf('day').valueOf();

        setStartDate(startDate);
        setEndDate(endDate);
        getLocationLogList({
            startDate: startDate ? moment(startDate).unix() : null,
            endDate: endDate ? moment(endDate).unix() : null,
        });
    };

    const handleSelectChange = selected => {
        setSearchInput(selected.map(({ value }) => value));
    };

    const handleSearchClick = () => {
        if (searchInput) {
            getLocationLogList({
                sensorIds: searchInput.length ? searchInput.join(',') : null,
                startDate: startDate ? moment(startDate).unix() : null,
                endDate: endDate ? moment(endDate).unix() : null,
            });
        } else {
            getLocationLogList({
                startDate: startDate ? moment(startDate).unix() : null,
                endDate: endDate ? moment(endDate).unix() : null,
            });
        }
    };

    const handleYesterdayButtonClick = () => {
        const start = moment().subtract(1, 'day').startOf('day').valueOf();
        const end = moment().subtract(1, 'day').endOf('day').valueOf();

        setStartDate(start);
        setEndDate(end);
    };

    return (
        <FilterSearchGroup>
            <FilterGroup className="bg-brand" label={t('Search', 'Search')}>
                <FilterList>
                    <SelectGroup>
                        <div className="datePicker-container">
                            <TimePicker
                                value={startDate}
                                handleChange={selected => setStartDate(selected)}
                                valueType="ms"
                                maxDate={endDate || moment.now()}
                                showTimeInput
                            />
                        </div>
                    </SelectGroup>
                    <div className="text-center"> ~ </div>
                    <SelectGroup>
                        <div className="datePicker-container">
                            <TimePicker
                                value={endDate}
                                handleChange={selected => setEndDate(selected)}
                                valueType="ms"
                                minDate={startDate}
                                maxDate={moment.now()}
                                showTimeInput
                            />
                        </div>
                    </SelectGroup>
                    <SelectGroup>
                        <SearchableSelect
                            data={selectableSensorList}
                            selected={searchInput}
                            onChange={handleSelectChange}
                            title={t('Sensor')}
                        />
                    </SelectGroup>
                    <div className="d-flex gap-1 justify-content-end">
                        <Button
                            className="btn-secondary btn-yesterday"
                            style={{ width: 'fit-content' }}
                            onClick={handleYesterdayButtonClick}
                        >
                            {t('Yesterday', 'Button')}
                        </Button>
                        <Button className="btn-brand btn-icon" iconName="search" onClick={handleSearchClick}>
                            {t('Search', 'Search')}
                        </Button>
                        <Button className="btn-danger btn-icon-only" iconName="replay" onClick={handleRefreshClick} />
                    </div>
                </FilterList>
                <FilterButtonArea />
            </FilterGroup>
        </FilterSearchGroup>
    );
};
export default Search;
