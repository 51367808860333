import React from 'react';

import { Popup } from 'react-leaflet';

import { Button, Label } from '@components';
import { useTranslation } from '@hooks';

const popupLabels = [
    { label: 'Name', value: 'targetName' },
    { label: 'Rank', value: 'rank' },
    { label: 'Department', value: 'department' },
    { label: 'Jobtitle', value: 'jobtitle' },
    { label: 'LTE Number', value: 'lte' },
    { label: 'Normal Number', value: 'telephone' },
];

const CustomPopup = ({ selectedMarker, toggleModal }) => {
    const t = useTranslation('Integrated Monitor');
    const {
        location: { latLng },
        targetProperties,
    } = selectedMarker[0];

    return (
        <Popup position={latLng}>
            {selectedMarker.length === 1 ? (
                <>
                    <div className="p-2">
                        {popupLabels.map(labels => {
                            const { label, value } = labels;
                            return (
                                <Label
                                    labelValueClassName="color-darkgray"
                                    key={value}
                                    name={t(label)}
                                    value={!!targetProperties ? targetProperties[value] : ''}
                                />
                            );
                        })}
                    </div>
                    <div className="d-flex justify-content-end">
                        <Button onClick={() => toggleModal(targetProperties)}>{t('More')}</Button>
                    </div>
                </>
            ) : (
                <div
                    className="py-4 d-flex flex-column gap-1"
                    style={{ minWidth: '8rem', maxHeight: '10rem', overflow: 'overlay' }}
                >
                    {selectedMarker.map(marker => {
                        const { targetProperties, target } = marker;
                        const name = !!targetProperties ? targetProperties.targetName : target.targetName;
                        return (
                            <div
                                key={name}
                                className="cursor-pointer"
                                onClick={() => (!!targetProperties ? toggleModal(targetProperties) : null)}
                            >
                                <Label labelValueClassName="label-dot color-black" key={name} name={name} />
                            </div>
                        );
                    })}
                </div>
            )}
        </Popup>
    );
};

export default CustomPopup;
