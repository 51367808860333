import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    conjuncture: null,
    patrolZoneState: null,
    patrolState: null,
    locationApproval: false,
};

const { actions, reducer } = createSlice({
    name: 'navy',
    initialState,
    reducers: {
        setConjuncture: (state, action) => {
            state.conjuncture = action.payload;
        },
        setPatrolZoneState: (state, action) => {
            state.patrolZoneState = action.payload;
        },
        setPatrolState: (state, action) => {
            state.patrolState = action.payload;
        },
        setLocationApproval: (state, action) => {
            state.locationApproval = action.payload;
        },
    },
});

export const { setConjuncture, setPatrolZoneState, setPatrolState, setLocationApproval } = actions;
export default reducer;
