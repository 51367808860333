import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import moment from 'moment';

import { DatePicker, Card, Table, Page } from 'Components/Common';
import { getAssetRentalRequestListApi } from 'api/asset';
import { useAsync, useColumns, useTranslation } from '@hooks';

import Search from '../../Components/Search';
import DepartmentSelect from '../Components/DepartmentSelect';
import ApplicationStatusSelect from '../Components/ApplicationStatusSelect';

import * as column from 'Components/MainPages/util/grid/column';

const AssetRentalProcess = () => {
    const t = useTranslation('AssetRentalProcess');

    const { groupId, groupNum } = useSelector(state => state.UserInfo.userInfo);
    const { categoryList } = useSelector(state => state.CategoryInfo);

    const history = useHistory();

    const [tableKey, setTableKey] = useState(0);
    const [selectedSearchOption, setSelectedSearchOption] = useState({
        requestingDepartment: [],
        applicationStatus: [],
    });
    const [assetRentalProcessList, setAssetRentalProcessList] = useState({
        totalCount: 0,
        totalPage: 1,
        rows: [],
    });
    const [startDate, setStartDate] = useState(moment().startOf('day').valueOf());
    const [endDate, setEndDate] = useState(moment().endOf('day').valueOf());

    const columns = useColumns(
        [
            column.assetRentalRequestNum({
                width: 90,
            }),
            column.assetRentalRequestDepart({
                width: 100,
            }),
            column.assetRentalApplicant({
                width: 100,
            }),
            column.assetRentalDepartment({
                width: 100,
            }),
            column.applicationDate(),
            column.rentalPeriod({
                width: 200,
                Cell: ({ row }) => {
                    const { original } = row;

                    return (
                        <div>
                            {moment.unix(original.startDate).format('YYYY-MM-DD')} ~
                            {moment.unix(original.endDate).format('YYYY-MM-DD')}
                        </div>
                    );
                },
            }),
            column.applicationStatus({
                width: 90,
                Cell: ({ value }) => {
                    return <div>{t(value, 'AssetRentalRequest')}</div>;
                },
            }),
            column.dateChanged(),
            column.category({
                Cell: ({ value }) => {
                    const category = categoryList.find(({ categoryCode }) => categoryCode === value);
                    if (category) {
                        return <div>{category.categoryNamePath}</div>;
                    } else {
                        return <div>-</div>;
                    }
                },
            }),
            column.quantity({
                width: 90,
            }),
        ],
        null,
        [categoryList],
    );

    const { promise: getAssetRentalRequestList, state: assetRentalRequestInfo } = useAsync({
        promise: getAssetRentalRequestListApi,
        fixedParam: { pageSize: 20, ownDepNums: groupNum },
        param: { startDate: moment(startDate).unix(), endDate: moment(endDate).unix() },
        keepState: true,
        immediate: true,
        deps: [groupNum],
        resolve: res => {
            setAssetRentalProcessList(res);
        },
    });

    const handleSearchClick = searchInput => {
        setTableKey(prevState => prevState + 1);
        getAssetRentalRequestList({
            keyword: searchInput,
            startDate: moment(startDate).unix(),
            endDate: moment(endDate).unix(),
            statusList: selectedSearchOption.applicationStatus.length
                ? selectedSearchOption.applicationStatus.join(',')
                : null,
            usingDepNums: selectedSearchOption.requestingDepartment.length
                ? selectedSearchOption.requestingDepartment.join(',')
                : null,
        });
    };

    const handleRefreshClick = () => {
        setTableKey(prevState => prevState + 1);
        setSelectedSearchOption({
            requestingDepartment: [],
            applicationStatus: [],
        });
        setStartDate(moment().startOf('day').valueOf());
        setEndDate(moment().endOf('day').valueOf());
        getAssetRentalRequestList({
            startDate: moment().startOf('day').unix(),
            endDate: moment().endOf('day').unix(),
        });
    };

    const handlePageChange = pageIndex => {
        if (assetRentalRequestInfo.request) {
            getAssetRentalRequestList({ ...assetRentalRequestInfo.request, page: pageIndex });
        } else {
            getAssetRentalRequestList({
                startDate: moment(startDate).unix(),
                endDate: moment(endDate).unix(),
                page: pageIndex,
            });
        }
    };

    const handleRentalRequestRowClick = selected => {
        history.push(`/asset-rental/process/${selected.reqNum}`);
    };

    return (
        <Page menuPath={[t('Asset Rental Management', 'Menu'), t('Asset Rental Process', 'Menu')]}>
            <div className="grid-layout-container">
                <Search
                    searchInputPlaceholder={t('Please enter the applicant or id.', 'AssetRentalRequest')}
                    handleSearchClick={handleSearchClick}
                    handleRefreshClick={handleRefreshClick}
                >
                    <DepartmentSelect
                        title={t('Requesting department')}
                        logInUserDepartment={groupId}
                        selectedOption={selectedSearchOption.requestingDepartment}
                        onChange={selected => {
                            setSelectedSearchOption(prevState => {
                                return { ...prevState, requestingDepartment: selected };
                            });
                        }}
                    />
                    <ApplicationStatusSelect
                        selectedOption={selectedSearchOption.applicationStatus}
                        onChange={selected => {
                            setSelectedSearchOption(prevState => {
                                return { ...prevState, applicationStatus: selected };
                            });
                        }}
                    />
                    <DatePicker
                        value={startDate}
                        valueType={'ms'}
                        handleChange={selected => setStartDate(selected)}
                        withoutTime
                        maxDate={moment.now()}
                    />
                    <DatePicker
                        value={endDate}
                        valueType={'ms'}
                        minDate={startDate}
                        maxDate={moment.now()}
                        handleChange={selected => setEndDate(selected)}
                        withoutTime
                    />
                </Search>

                <Card
                    header={{
                        title: t('Asset Rental Process List'),
                    }}
                >
                    <div className={'table-container'}>
                        <Table
                            key={tableKey}
                            data={{ ...assetRentalProcessList, pageSize: 20 }}
                            columns={columns}
                            onPageChange={handlePageChange}
                            onTrClick={handleRentalRequestRowClick}
                            loading={assetRentalRequestInfo.isLoading}
                        />
                    </div>
                </Card>
            </div>
        </Page>
    );
};

export default AssetRentalProcess;
