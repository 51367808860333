import React, { useContext, useMemo, useState, useEffect } from 'react';

import cx from 'classnames';

import { getHealthMonitoringSelectAPI } from '@api/military';
import { useTranslation, useAsync } from '@hooks';
import { Select as ReactSelect, TextInput, Button, Label, Checkbox } from '@components';

import {
    InputGroup,
    SelectGroup,
    InputWrap,
    SearchWrap,
    FilterList,
} from '@components/FilterSearchGroup/Components/Part';
import FilterSearchGroup from '@components/FilterSearchGroup';
import SearchGroup from '@components/FilterSearchGroup/Components/SearchGroup';
import SearchButtonArea from '@components/FilterSearchGroup/Components/SearchButtonArea';
import FilterButtonArea from '@components/FilterSearchGroup/Components/FilterButtonArea';
import FilterGroup from '@components/FilterSearchGroup/Components/FilterGroup';

import { HealthCareMonitoringStatusContext, HealthCareMonitoringDispatchContext } from '../index';
import {
    setFilterOptions,
    setSearchInput,
    setSearchInputOption,
    setSelectedFilterOption,
} from '../healthCareMonitoringReducer';

import styled from 'styled-components';

const SELECT_LIST = ['rank', 'department', 'jobtitle', 'status'];

const ConditionalSearch = ({ children, handleSearchClick, className }) => {
    const t = useTranslation('Health Care Monitoring');

    const dispatch = useContext(HealthCareMonitoringDispatchContext);
    const { searchInputOption, searchInput, filterOptions, selectedFilterOption, isHealthCareListLoading } = useContext(
        HealthCareMonitoringStatusContext,
    );

    const [tempSelectedFilterOption, setTempSelectedFilterOption] = useState({});

    const searchInputOptions = useMemo(() => {
        return [
            { value: 'all', label: t('All') },
            { value: 'targetId', label: t('ID') },
            { value: 'targetName', label: t('Name') },
        ];
    }, [t]);

    const [inputValue, setInputValue] = useState(searchInput || '');
    const handleInputChange = e => {
        const { value } = e.target;
        setInputValue(value);
    };

    const [selectedInputOption, setSelectedInputOption] = useState(searchInputOption || searchInputOptions[0]);
    const handleInputOptionChange = value => {
        setSelectedInputOption(value);
    };

    const handleOptionChange = (key, value) => {
        setTempSelectedFilterOption(prevState => ({
            ...prevState,
            [key]: value,
        }));
    };

    const handleSearchButton = () => {
        handleSearchClick(selectedInputOption, inputValue, tempSelectedFilterOption);
        dispatch(setSearchInput(inputValue));
        dispatch(setSearchInputOption(selectedInputOption));
        dispatch(setSelectedFilterOption(tempSelectedFilterOption));
    };

    const handleBookmarkButton = () => {
        setTempSelectedFilterOption(prevState => ({
            ...prevState,
            bookmark: !prevState['bookmark'],
        }));
    };

    useEffect(() => {
        setTempSelectedFilterOption(selectedFilterOption);
    }, []);

    useAsync({
        promise: getHealthMonitoringSelectAPI,
        immediate: true,
        resolve: res => {
            const { inputVal } = res;

            SELECT_LIST.forEach(element => {
                const list = [{ label: t('All'), value: 'all' }];
                inputVal[element].forEach(({ name, value }) => {
                    list.push({ label: name, value });
                });
                dispatch(setFilterOptions({ key: element, value: list }));
            });
        },
        reject: err => {
            console.error(err);
        },
    });

    return (
        <FilterSearchGroupContainer
            label={t('Search', 'Search')}
            className={cx('card', isHealthCareListLoading && 'd-none')}
        >
            <FilterGroup className="bg-secondary" label={t('Filter', 'Filter')}>
                <FilterList className="d-flex gap-3">
                    <Label
                        name={t('category')}
                        labelValueClassName="min-w-none label-dot-black"
                        value={
                            <TextInput
                                name="selectCategory"
                                value={selectedFilterOption['category'].value}
                                inputGroupClassName="form-disable"
                                style={{ width: '80%', minWidth: '10rem' }}
                                readOnly
                            />
                        }
                    />
                    <Label
                        name={t('Bookmark', 'Filter')}
                        labelValueClassName="label-dot-black label-main label-info"
                        infoBoxValue={t('Bookmark Only', 'Filter')}
                        value={
                            <Checkbox
                                checked={tempSelectedFilterOption['bookmark'] || false}
                                handleChecked={handleBookmarkButton}
                            />
                        }
                    />
                </FilterList>
                <FilterButtonArea />
            </FilterGroup>
            <FilterGroup>
                <FilterList className="d-flex gap-2">
                    {SELECT_LIST.map(select => {
                        return (
                            <Label
                                key={select}
                                name={t(select)}
                                labelValueClassName="min-w-none label-dot-black"
                                value={
                                    <div style={{ minWidth: '9rem' }}>
                                        <ReactSelect
                                            name={`select${select}`}
                                            key={`select${select}`}
                                            value={tempSelectedFilterOption[select]}
                                            options={filterOptions[select] || []}
                                            placeholder={t('All')}
                                            onChange={value => handleOptionChange(select, value)}
                                            className="w-100"
                                            customControlStyles={{ width: '100%' }}
                                            customMenuStyles={{ width: '100%' }}
                                            customOptionStyles={{ width: '100%' }}
                                        />
                                    </div>
                                }
                            />
                        );
                    })}
                </FilterList>
            </FilterGroup>

            {children && (
                <FilterGroup>
                    <FilterList className={cx('d-flex gap-1', className)}>{children}</FilterList>
                </FilterGroup>
            )}

            <SearchGroup className="bg-secondary" label={t('Search', 'Search')}>
                <SearchWrap className="border-0 bg-light">
                    <SelectGroup>
                        <ReactSelect
                            name="selectInput"
                            placeholder={t('Search Option', 'Search')}
                            value={selectedInputOption}
                            options={searchInputOptions}
                            onChange={handleInputOptionChange}
                            customControlStyles={{ width: '100%' }}
                            customMenuStyles={{ width: '100%' }}
                            customOptionStyles={{ width: '100%' }}
                        />
                    </SelectGroup>
                    <InputWrap>
                        <InputGroup>
                            <TextInput
                                style={{ borderRadius: '5px' }}
                                type="text"
                                size={60}
                                placeholder={t(
                                    'Please Enter your search keyword after selecting search option.',
                                    'Search',
                                )}
                                name="name"
                                value={inputValue}
                                handleChange={handleInputChange}
                            />
                        </InputGroup>
                    </InputWrap>
                </SearchWrap>

                <FilterButtonArea />
                <SearchButtonArea>
                    <Button
                        className="btn-secondary btn-icon"
                        iconName="search"
                        onClick={handleSearchButton}
                        disabled={isHealthCareListLoading}
                    >
                        {t('Search', 'Search')}
                    </Button>
                </SearchButtonArea>
            </SearchGroup>
        </FilterSearchGroupContainer>
    );
};

const FilterSearchGroupContainer = styled(FilterSearchGroup)`
    position: absolute;
    width: 100%;
    padding: 1rem;
    z-index: 1011;
`;

export default ConditionalSearch;
