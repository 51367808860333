import Widget, { WIDGET_CATEGORY } from '../staticInfo';

import AvailableAsset from './AvailableAsset';
import AvailableAssetSetting from './AvailableAsset/AvailableAssetSetting';
import StatusTimeLine from './StatusTimeLine';
import StatusTimeLineSetting from './StatusTimeLine/StatusTimeLineSetting';
import StatusRateByTime from './StatusRateByTime';
import StatusByTimeSetting from './StatusRateByTime/StatusByTimeSetting';
import StatusBy from './StatusBy';
import StatusBySetting from './StatusBy/StatusBySetting';
import RealtimeUtilizationWidget from './RealtimeUtilizationWidget';
import RealtimeUtilizationSetting from './RealtimeUtilizationWidget/RealtimeUtilizationSetting';
import UtilizationBigChartVerWidget from './UtilizationBigChartVerWidget';
import UtilizationBigChartSetting from './UtilizationBigChartVerWidget/UtilizationBigChartSetting';
import UtilizationSmallChartVerWidget from './UtilizationSmallChartVerWidget';
import UtilizationSmallChartSetting from './UtilizationSmallChartVerWidget/UtilizationSmallChartSetting';

// 미사용 위젯
import AssetChart from './AssetChart';
import AssetChartSetting from './AssetChart/AssetChartSetting';
import AssetLocationStatus from './AssetLocationStatus';
import AssetStatusGrid from './AssetStatusGrid';

export const availableAsset: Widget = {
    type: 'availableAsset',
    category: WIDGET_CATEGORY.ASSET_MANAGEMENT,
    name: 'Available Asset',
    desc: 'This graph shows the available assets classified by labels',
    thumbnailURL: null,
    Component: AvailableAsset,
    SettingComponent: AvailableAssetSetting,
    config: { defaultSettings: { intervalTime: 10000 } },
    layout: {
        w: 6,
        h: 16,
        minW: 4,
        minH: 12,
    },
};
export const statusTimeLine: Widget = {
    type: 'statusTimeLine',
    category: WIDGET_CATEGORY.ASSET_MANAGEMENT,
    name: 'Status rate - by time',
    desc: 'This widget shows time line of asset status',
    thumbnailURL: null,
    Component: StatusTimeLine,
    SettingComponent: StatusTimeLineSetting,
    config: { defaultSettings: { intervalTime: 10000 } },
    layout: {
        w: 4,
        h: 23,
        minW: 3,
        minH: 16,
    },
};

export const statusRateByTime: Widget = {
    type: 'statusRateByTime',
    category: WIDGET_CATEGORY.ASSET_MANAGEMENT,
    name: 'Status rate - by time',
    desc: 'This widget shows line chart of asset status',
    thumbnailURL: null,
    Component: StatusRateByTime,
    SettingComponent: StatusByTimeSetting,
    config: { defaultSettings: { intervalTime: 10000 } },
    layout: {
        w: 6,
        h: 18,
        minW: 4,
        minH: 14,
    },
};

export const statusByBarChartVer: Widget = {
    type: 'statusByBarChartVer',
    category: WIDGET_CATEGORY.ASSET_MANAGEMENT,
    name: 'Status by',
    desc: 'This widget shows bar chart of asset status',
    thumbnailURL: null,
    Component: StatusBy,
    SettingComponent: StatusBySetting,
    config: { defaultSettings: { intervalTime: 10000, option: 'category' } },
    layout: {
        w: 6,
        h: 17,
        minW: 4,
        minH: 14,
    },
};

export const realtimeUtilization: Widget = {
    type: 'realtimeUtilization',
    category: WIDGET_CATEGORY.ASSET_MANAGEMENT,
    name: 'Realtime Utilization',
    desc: 'This widget displays bar chart of real-time asset status',
    thumbnailURL: null,
    Component: RealtimeUtilizationWidget,
    SettingComponent: RealtimeUtilizationSetting,
    config: { defaultSettings: { intervalTime: 10000, flowNum: null } },
    layout: {
        w: 6,
        h: 15,
        minW: 4,
        minH: 13,
    },
};

export const utilizationBigChartVer: Widget = {
    type: 'utilizationBigChartVer',
    category: WIDGET_CATEGORY.ASSET_MANAGEMENT,
    name: 'Utilization',
    desc: 'This widget displays big pie chart of asset status',
    thumbnailURL: null,
    Component: UtilizationBigChartVerWidget,
    SettingComponent: UtilizationBigChartSetting,
    config: { defaultSettings: { intervalTime: 10000, statusCategoryNum: null } },
    layout: {
        w: 6,
        h: 12,
        minW: 4,
        minH: 12,
    },
};

export const utilizationSmallChartVer: Widget = {
    type: 'utilizationSmallChartVer',
    category: WIDGET_CATEGORY.ASSET_MANAGEMENT,
    name: 'Utilization',
    desc: 'This widget displays small pie chart of asset status',
    thumbnailURL: null,
    Component: UtilizationSmallChartVerWidget,
    SettingComponent: UtilizationSmallChartSetting,
    config: { defaultSettings: { intervalTime: 10000, statusCategoryNum: null } },
    layout: {
        w: 5,
        h: 12,
        minW: 4,
        minH: 12,
    },
};

// 미사용 위젯
export const assetChart = {
    type: 'assetChart',
    name: 'Asset Chart',
    desc: 'It monitors asset status in chart form',
    thumbnailURL: null,
    Component: AssetChart,
    SettingComponent: AssetChartSetting,
    config: { defaultSettings: { intervalTime: 10000, dataType: 'scanner' } },
    layout: {
        w: 4,
        h: 21,
        minW: 2,
        minH: 12,
    },
};

export const assetLocationStatus = {
    type: 'assetLocationStatus',
    name: 'Asset Location Status',
    desc: 'It monitors asset location status',
    thumbnailURL: null,
    Component: AssetLocationStatus,
    SettingComponent: null,
    config: { defaultSettings: { intervalTime: 10000 } },
    layout: {
        w: 6,
        h: 27,
        minW: 4,
        minH: 15,
    },
};
export const assetStatusGrid = {
    type: 'assetStatusGrid',
    name: 'Asset Condition Details',
    desc: 'It monitors asset condition details',
    thumbnailURL: null,
    Component: AssetStatusGrid,
    SettingComponent: null,
    config: { defaultSettings: { intervalTime: 10000 } },
    layout: {
        w: 6,
        h: 25,
        minW: 4,
        minH: 15,
    },
};

const amWidgetList = {
    availableAsset,
    statusTimeLine,
    statusRateByTime,
    statusByBarChartVer,
    realtimeUtilization,
    utilizationBigChartVer,
    utilizationSmallChartVer,

    // 미사용 위젯
    // assetChart,
    // assetStatusGrid,
    // assetLocationStatus,
};

export default amWidgetList;
