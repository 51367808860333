import React, { useMemo } from 'react';

import L from 'leaflet';
import { Marker } from 'react-leaflet';

import redDot from 'assets/images/red-dot.svg';
import greenDot from 'assets/images/green-dot.svg';

const RedDot = new L.Icon({
    iconUrl: redDot,
    iconSize: [12, 12],
});

const CustomMarker = ({ location, data = [], markerImgUrl, onClick }) => {
    const { latLng } = location;

    const Icon = useMemo(() => {
        if (markerImgUrl) {
            return new L.Icon({
                iconUrl: markerImgUrl,
                iconSize: [20, 20],
            });
        }

        if (!data.length) return RedDot;

        const { targetName } = data[0];
        if (data.length > 1) {
            return new L.DivIcon({
                className: 'category-marker',
                html: `
                        <img alt="redDot" src="${redDot}" />
                        <div class="marker-label text-white bg-dark px-1">${targetName}(${data.length})</div>`,
                iconSize: [12, 12],
            });
        }

        return new L.DivIcon({
            className: 'category-marker',
            html: `
                        ${
                            data[0] && data[0].state === 'Y'
                                ? `<img alt="redDot" src="${redDot}" />`
                                : `<img alt="greenDot" src="${greenDot}" />`
                        }
                        <div class="marker-label text-white bg-dark px-1">${targetName}</div>`,
            iconSize: [12, 12],
        });
    }, [data, markerImgUrl]);

    return <Marker position={latLng} icon={Icon} onClick={onClick} />;
};

export default CustomMarker;
