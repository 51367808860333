import React, { useMemo, useCallback, useEffect, useRef } from 'react';

import { isMobile } from 'react-device-detect';
import RDatePicker, { registerLocale } from 'react-datepicker';

import { InputGroup, InputGroupAddon } from 'reactstrap';

import useTranslation from '@hooks/useTranslation';

import { dateToFormat } from 'util/common/util';
import TimeInput from './Components/TimeInput';

import { useSelector } from 'react-redux';

import moment from 'moment';
import cx from 'classnames';

import 'assets/main/datepicker.scss';
import 'react-datepicker/dist/react-datepicker.min.css';

import ko from 'date-fns/locale/ko';
import en from 'date-fns/locale/en-US';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/fontawesome-free-solid';

registerLocale('en', en);
registerLocale('ko', ko);

const TimePicker = ({
    value,
    handleChange,
    withoutTime,
    withoutDay,
    valueType,
    minDate,
    maxDate,
    readOnly,
    withoutMinute = false,
    ...restProps
}) => {
    const t = useTranslation('DatePicker');
    const labelRef = useRef(null);
    const { lang } = useSelector(state => state.UserInfo);
    const dateFormat = useMemo(() => {
        if (withoutTime) {
            return {
                datepicker: 'yyyy-MM-dd',
                title: 'YYYY-MM-DD',
                placeholderText: '---- - -- - --',
            };
        }
        if (withoutDay) {
            return {
                datepicker: 'yyyy-MM',
                title: 'YYYY-MM',
                placeholderText: '---- - --',
            };
        }
        return {
            datepicker: 'yyyy-MM-dd HH:mm',
            title: 'YYYY-MM-DD HH:mm',
            placeholderText: '---- - -- - -- -- : --',
        };
    }, [withoutTime, withoutDay]);
    const handleChangeDate = useCallback(
        selected => {
            let selectedDate = moment(selected).valueOf();
            if (minDate) {
                selectedDate = selectedDate < minDate ? minDate : selectedDate;
            }
            if (maxDate) {
                selectedDate = selectedDate > maxDate ? maxDate : selectedDate;
            }

            switch (valueType) {
                case 'ms':
                    selectedDate = moment(selectedDate).valueOf();
                    break;
                case 's':
                default:
                    selectedDate = moment(selectedDate).unix();
                    break;
            }
            handleChange(selectedDate);
        },
        [valueType, minDate, maxDate],
    );
    const selectedValue = useMemo(() => {
        let selectedDate = value;
        switch (valueType) {
            case 'ms':
                break;
            case 's':
            default:
                selectedDate = value * 1000;
                break;
        }
        return selectedDate;
    }, [value, valueType]);

    useEffect(() => {
        if (!readOnly) {
            labelRef.current.querySelector('.react-datepicker__input-container>input').setAttribute('readonly', true);
        }
    }, [readOnly]);

    return (
        <label
            ref={labelRef}
            className={cx(
                'pnt-datepicker-container',
                restProps.showTimeInput && 'time-input',
                restProps.showTimeSelect && 'time-select',
                withoutTime && 'time-none',
            )}
        >
            <InputGroup>
                <RDatePicker
                    locale={lang}
                    className={cx('form-control', readOnly && 'cursor-default bg-lightgray bg-light')}
                    autoComplete="off"
                    selected={selectedValue}
                    onChange={handleChangeDate}
                    dateFormat={dateFormat.datepicker}
                    title={!!selectedValue ? dateToFormat(selectedValue, dateFormat.title) : ''}
                    withPortal={isMobile}
                    placeholderText={dateFormat.placeholderText}
                    popperClassName={'datepicker-popper-display'}
                    // popperContainer={CalendarContainer}
                    popperModifiers={[
                        { name: 'offset', options: { offset: [0, 0] } },
                        // {
                        //     preventOverflow: {
                        //         enabled: true,
                        //         escapeWithReference: false,
                        //         padding: 30,
                        //         // boundariesElement: "viewport"
                        //     },
                        // },
                    ]}
                    shouldCloseOnSelect={!!withoutTime}
                    // name={''}
                    minDate={minDate}
                    maxDate={maxDate}
                    // startDate={null}
                    // endDate={null}
                    // selectsStart

                    // showTimeInput
                    timeInputLabel={t('Time')}
                    customTimeInput={
                        <TimeInput date={value} minTime={minDate} maxTime={maxDate} withoutMinute={withoutMinute} />
                    }
                    // showTimeSelect
                    timeFormat={'HH:mm'}
                    timeIntervals={5}
                    readOnly={readOnly}
                    // minTime={moment().startOf('minutes').subtract(20, 'minutes').valueOf()}
                    // maxTime={moment().startOf('minutes').subtract(5, 'minutes').valueOf()}
                    {...restProps}
                />
                <InputGroupAddon addonType="append">
                    <div className="input-group-text">
                        <FontAwesomeIcon icon={faCalendarAlt} />
                    </div>
                </InputGroupAddon>
            </InputGroup>
        </label>
    );
};

export default TimePicker;
