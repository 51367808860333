import React, { useMemo, useCallback } from 'react';

import { isMobile } from 'react-device-detect';
import { InputGroup, InputGroupAddon } from 'reactstrap';
import RDatePicker, { registerLocale } from 'react-datepicker';
import moment from 'moment';
import { useSelector } from 'react-redux';
import cx from 'classnames';

import { dateToFormat } from 'util/common/util';
import useTranslation from '@hooks/useTranslation';
import TimeInput from './Components/TimeInput';

import 'react-datepicker/dist/react-datepicker.min.css';
import 'assets/main/datepicker.scss';

import ko from 'date-fns/locale/ko';
import en from 'date-fns/locale/en-US';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/fontawesome-free-solid';

registerLocale('en', en);
registerLocale('ko', ko);

const DatePicker = ({ value, handleChange, withoutTime, valueType, readOnly, ...restProps }) => {
    const t = useTranslation('DatePicker');
    const { lang } = useSelector(state => state.UserInfo);
    const dateFormat = useMemo(() => {
        if (withoutTime) {
            return {
                datepicker: 'yyyy-MM-dd',
                title: 'YYYY-MM-DD',
                placeholderText: '---- - -- - --',
            };
        }
        return {
            datepicker: 'yyyy-MM-dd HH:mm',
            title: 'YYYY-MM-DD HH:mm',
            placeholderText: '---- - -- - -- -- : --',
        };
    }, [withoutTime]);
    const handleChangeDate = useCallback(
        selected => {
            let selectedDate = selected;
            switch (valueType) {
                case 'ms':
                    selectedDate = moment(selectedDate).valueOf();
                    break;
                case 's':
                default:
                    selectedDate = moment(selectedDate).unix();
                    break;
            }
            handleChange(selectedDate);
        },
        [valueType],
    );
    const selectedValue = useMemo(() => {
        let selectedDate = value;
        switch (valueType) {
            case 'ms':
                break;
            case 's':
            default:
                selectedDate = value * 1000;
                break;
        }
        return selectedDate;
    }, [value, valueType]);

    return (
        <label
            className={cx(
                'pnt-datepicker-container',
                restProps.showTimeInput && 'time-input',
                restProps.showTimeSelect && 'time-select',
                withoutTime && 'time-none',
            )}
        >
            <InputGroup>
                <RDatePicker
                    locale={lang}
                    className={cx('form-control', readOnly && 'cursor-default')}
                    autoComplete="off"
                    selected={selectedValue}
                    onChange={handleChangeDate}
                    dateFormat={dateFormat.datepicker}
                    title={dateToFormat(selectedValue, dateFormat.title)}
                    withPortal={isMobile}
                    placeholderText={dateFormat.placeholderText}
                    popperClassName={'datepicker-popper-display'}
                    // popperContainer={CalendarContainer}
                    popperModifiers={[
                        { name: 'offset', options: { offset: [0, 0] } },
                        // {
                        //     preventOverflow: {
                        //         enabled: true,
                        //         escapeWithReference: false,
                        //         padding: 30,
                        //         // boundariesElement: "viewport"
                        //     },
                        // },
                    ]}
                    shouldCloseOnSelect={!!withoutTime}
                    // name={''}
                    // minDate={null}
                    // maxDate={null}
                    // startDate={null}
                    // endDate={null}
                    // selectsStart

                    // showTimeInput
                    timeInputLabel={t('Time')}
                    customTimeInput={<TimeInput />}
                    // showTimeSelect
                    timeFormat={'HH:mm'}
                    timeIntervals={5}
                    readOnly={readOnly}
                    // minTime={moment().startOf('minutes').subtract(20, 'minutes').valueOf()}
                    // maxTime={moment().startOf('minutes').subtract(5, 'minutes').valueOf()}

                    {...restProps}
                />
                <InputGroupAddon addonType="append">
                    <div className="input-group-text">
                        <FontAwesomeIcon icon={faCalendarAlt} />
                    </div>
                </InputGroupAddon>
            </InputGroup>
        </label>
    );
};

export default DatePicker;
