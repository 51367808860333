import React, { useEffect, useRef, useState } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';

import styled from 'styled-components';

import { getAssetListApi } from 'api/asset';
import { useAsync, useTranslation } from '@hooks';

import cx from 'classnames';

const ASSET_NAME_PAGE_SIZE = 20;

/**
 * Input box에 작성되는 키워드에 따라 전체 asset list에서 알맞는 정보를 가져오고
 * 유저에게 그 키워드에 맞는 리스트를 보여줌으로서 정확하게 보고싶은 정보를 선택할 수 있게 함
 *
 * @param handleSelectedKeyword function / 유저가 선택한 키워드를 부모 컴포넌트에게 전달하는 역할
 * @param emptyInput boolean type / clear method를 실행하기 위한 조건 변수
 *
 */
const SearchAutocomplete = ({ option, handleSelectedKeyword, emptyInput, filterParam = {}, disabled = false }) => {
    const t = useTranslation('SearchAutoComplete');

    const [itemList, setItemList] = useState([]);
    const [keyword, setKeyword] = useState('');
    const filterRef = useRef(JSON.stringify(filterParam));

    const autocompleteRef = useRef();
    const getListRef = useRef();

    const { promise: getAssetList } = useAsync({
        promise: getAssetListApi,
        fixedParam: { pageSize: ASSET_NAME_PAGE_SIZE, ...filterParam },
        resolve: res => {
            if (!keyword) {
                setItemList([]);
                return;
            }

            const tempList = res.rows.map(row => ({
                label: option.value === 'targetName' ? row.targetName : row.targetId,
                value: row.targetId,
                targetNum: row.targetNum,
            }));

            if (res.page === 1) {
                setItemList(tempList);
            } else {
                setItemList(itemList.concat(tempList));
            }

            const autocompleteRefElement = autocompleteRef.current;
            if (!autocompleteRefElement.state.showMenu && typeof autocompleteRefElement.toggleMenu === 'function') {
                autocompleteRefElement.toggleMenu();
            }
        },
        reject: error => {
            console.error(error);
        },
    });

    useEffect(() => {
        if (!keyword) return;
        getAssetList({ opt: option.value, keyword });
    }, [option]);

    useEffect(() => {
        if (!keyword) return;

        const filterParamStr = JSON.stringify(filterParam);
        if (filterParamStr === filterRef.current) return;

        filterRef.current = filterParamStr;
        getAssetList({ opt: option.value, keyword });
    }, [filterParam]);

    return (
        <WrappedTypeahead>
            <Typeahead
                id="targetId"
                ref={autocompleteRef}
                style={{ width: '100%', fontSize: '0.8rem' }}
                className={cx('typeahead-custom', disabled && 'disabled')}
                multiple={false}
                allowNew={false}
                onInputChange={text => {
                    setKeyword(text);
                    if (getListRef.current) {
                        clearTimeout(getListRef.current);
                        getListRef.current = null;
                    }
                    getListRef.current = setTimeout(() => {
                        if (!text) {
                            setItemList([]);
                            return;
                        }
                        getAssetList({ opt: option.value, keyword: text });
                    }, 500);
                }}
                onChange={handleSelectedKeyword}
                options={itemList}
                placeholder={t('Input asset ID or name')}
                emptyLabel={t('No matches found')}
                paginationText={t('Display additional results...')}
                maxResults={ASSET_NAME_PAGE_SIZE - 1}
                filterBy={['label', 'value']}
                onPaginate={(e, shownResults) => {
                    const page = Math.ceil(shownResults / ASSET_NAME_PAGE_SIZE);
                    getAssetList({
                        page: page,
                        opt: option.value,
                        keyword: keyword,
                    });
                }}
                disabled={disabled}
            />
        </WrappedTypeahead>
    );
};

const WrappedTypeahead = styled.div`
    @media (max-width: 690px) {
        & #targetName {
            left: 24%;
            top: 44%;
            width: 50%;
        }
    }

    & .form-control {
        font-size: 0.8rem;
        height: 38px;
        border: 2px solid orange;
    }

    & .rbt-input-main.form-control.rbt-input {
        //border: 1px solid orange;
        height: 38px;
        border-radius: 5px;
    }

    .pnt-input--group & span {
        display: inline;
        position: relative;
        opacity: 1;
        transform: initial;
        top: initial;
    }
`;

export default SearchAutocomplete;
