import React, { useEffect, useState } from 'react';
import { Bar, BarChart, XAxis, YAxis, LabelList, ResponsiveContainer } from 'recharts';

const renderCustomizedLabel = (info, data, totalNum) => {
    const [countData, setCountData] = useState(0);
    const { width, y } = info;

    useEffect(() => {
        let tempData = 0;
        data.map(data => (tempData += data.statusCount));
        setCountData(tempData);
    }, [data]);

    return (
        <>
            <g>
                <text
                    x={countData === 0 ? width + 5 : width - 3}
                    y={y - 15}
                    className={'font-color-fill'}
                    textAnchor="middle"
                    dominantBaseline="middle"
                    style={{ fontSize: '1rem', fontWeight: 'bold' }}
                >
                    {data[0] && data[0].statusCount && `${((data[0].statusCount / totalNum) * 100).toFixed(1)}%`}
                </text>
                <rect x={width + 27} y={y - 25} width="2" height="18" style={{ fill: '#a3a3a3' }} />
            </g>
        </>
    );
};

const RealtimeBarChart = ({ data, totalNum }) => {
    let filteredData = data.reduce((a, b, i) => {
        return { ...a, [i]: b.statusCount };
    }, []);
    filteredData = [{ ...filteredData }];

    const hexToRgb = hex => {
        let nonSpaceHex = hex.replace(/\s+/g, '');
        let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(nonSpaceHex);
        return result
            ? {
                  r: parseInt(result[1], 16),
                  g: parseInt(result[2], 16),
                  b: parseInt(result[3], 16),
                  a: 0.3,
              }
            : null;
    };

    return (
        <ResponsiveContainer width={'100%'}>
            <BarChart layout="vertical" width={400} height={100} data={filteredData} margin={{ top: 20, left: 30 }}>
                <XAxis type="number" hide={true} />
                <YAxis type="category" hide={true} />
                {Object.keys(filteredData[0]).map(key => {
                    if (parseInt(key) === 0) {
                        return (
                            <Bar
                                key={key}
                                isAnimationActive={false}
                                dataKey={key}
                                fill={data[key].displayColor}
                                stackId="a"
                                barSize={10}
                                radius={[10, 0, 0, 10]}
                            >
                                <LabelList
                                    dataKey={key}
                                    content={info => renderCustomizedLabel(info, data, totalNum)}
                                />
                            </Bar>
                        );
                    } else if (parseInt(key) === data.length - 1) {
                        const color = hexToRgb(data[key].displayColor);
                        return (
                            <Bar
                                key={key}
                                dataKey={key}
                                fill={color && `rgba(${color.r},${color.g},${color.b},${color.a})`}
                                stackId="a"
                                barSize={10}
                                radius={[0, 10, 10, 0]}
                            />
                        );
                    } else {
                        const color = hexToRgb(data[key].displayColor);
                        return (
                            <Bar
                                key={key}
                                dataKey={key}
                                fill={color && `rgba(${color.r},${color.g},${color.b},${color.a})`}
                                stackId="a"
                                barSize={10}
                            />
                        );
                    }
                })}
            </BarChart>
        </ResponsiveContainer>
    );
};

export default RealtimeBarChart;
