import React, { useMemo, useRef, useState } from 'react';
import { Portal } from 'react-overlays';

import { fetchExcelLogApi } from '@api/log';
import { getPatrolDailyAPI, getPatrolLogAPI, getPatrolReportAPI, postPatrolLogExportAPI } from '@api/military';

import { useAsync, useColumns, useConfirmModal, useTranslation } from '@hooks';
import { Button, Card, LoadingBlock, Page, Table } from '@components';

import Search from './Components/Search';
import PatrolReportModal from './Components/PatrolReportModal';
import PatrolReportPreviewModal from './Components/PatrolReportPreviewModal';
import PatrolDailyLogModal from './Components/PatrolDailyLogModal';
import PatrolDailyLogPreviewModal from './Components/PatrolDailyLogPreviewModal';

import { excelExport } from '../util/util';

import * as column from './column';

import moment from 'moment';

import {
    TableData,
    ExcelExportResponse,
    SearchClick,
    PatrolReportParams,
    PatrolReportResponse,
    PatrolDailyLogParams,
    PatrolDailyLog,
} from './types';

import styled from 'styled-components';

const PAGE_SIZE = 12;
const INTERVAL_TIME = 1000;
const PATROL_EMPTY_CODE = '7011';

const PatrolLog = () => {
    const t = useTranslation('Patrol Log');

    const [patrolReport, setPatrolReport] = useState(false);
    const togglePatrolReportModal = () => {
        setPatrolReport(prevState => !prevState);
    };

    const [patrolReportForm, setPatrolReportForm] = useState<PatrolReportParams>({});
    const [patrolReportData, setPatrolReportData] = useState<PatrolReportResponse[]>([]);

    const [patrolDailyLog, setPatrolDailyLog] = useState(false);
    const togglePatrolDailyLog = () => {
        setPatrolDailyLog(prevState => !prevState);
    };

    const handlePatrolDailyLog = (selected: PatrolDailyLogParams) => {
        getPatrolDaily(selected);
    };

    const [dailyLog, setDailyLog] = useState({});

    const [dailyLogPreview, setDailyLogPreview] = useState(false);
    const toggleDailyLogPreview = () => {
        setDailyLogPreview(prevState => !prevState);
    };

    const [patrolAllLog, setPatrolAllLog] = useState({ rows: [], page: 1, totalCount: 0, totalPage: 0 });

    const handlePatrolReportForm = (selected: PatrolReportParams) => {
        setPatrolReportForm({ ...getPatrolLogState.request, ...selected });

        const { patrol, division, startDate, endDate } = selected;
        const { floor, keyword, opt } = getPatrolLogState.request;
        getPatrolReport({
            startDate,
            endDate,
            keyword,
            opt,
            floor,
            targetId: patrol?.value,
            patrolDivName: division?.value,
        });

        toggleReportPreview();
    };

    const [reportPreview, setReportPreview] = useState(false);
    const toggleReportPreview = () => {
        setReportPreview(prevState => !prevState);
    };

    const excelTimerRef = useRef<ReturnType<typeof setInterval> | null>(null);

    const [tableData, setTableData] = useState({ rows: [], page: 1, totalCount: 0, totalPage: 0, pageSize: PAGE_SIZE });

    const columns = useColumns(
        [
            column.division(),
            column.startDate(),
            column.endDate(),
            column.floor(),
            column.patrolArea(),
            column.registrationDate(),
            column.modificationDate(),
            column.patrolId(),
            column.patrolName(),
            column.checkList(),
            column.result(),
            column.contents(),
        ],
        t,
    );

    const columnMetas = useMemo(() => {
        return columns.map(({ Header, accessor }) => {
            if (accessor.includes('Date')) {
                return { key: accessor + 'Str', name: Header };
            }
            return { key: accessor, name: Header };
        });
    }, [columns]);

    const [startDate, setStartDate] = useState(moment().startOf('day').valueOf());
    const [endDate, setEndDate] = useState(moment.now().valueOf());

    const handleDateCallback = ({ selected, type }: { selected: number; type: string }) => {
        switch (type) {
            case 'startDate':
                setStartDate(selected);
                break;
            case 'endDate':
                setEndDate(selected);
                break;
            case 'yesterday':
                const yesterday = moment().subtract(1, 'day');
                setStartDate(yesterday.startOf('day').valueOf());
                setEndDate(yesterday.endOf('day').valueOf());
                break;
            case 'reset':
                setStartDate(moment().startOf('day').valueOf());
                setEndDate(moment.now().valueOf());
                break;
            default:
                break;
        }
    };

    const handleSearchClick = ({ selectedFloorOption, selectedInputOption, searchInput }: SearchClick) => {
        if (getPatrolLogState.isLoading) return;

        getPatrolLog({
            floor: selectedFloorOption.floorId || 'All',
            opt: selectedInputOption.value || 'All',
            keyword: searchInput || '',
        });
    };

    const handlePageChange = (page: number) => {
        const { request } = getPatrolLogState;
        if (request) {
            getPatrolLog({ ...request, page });
        } else {
            getPatrolLog({ page });
        }
    };

    const handleExportFileClick = () => {
        if (!tableData.rows.length) {
            toggleExcelDownloadNoDataModal();
            return;
        }

        const { request } = getPatrolLogState;
        if (request) {
            delete request.page;
            delete request.pageSize;
            request.isAll = 'Y';
            postPatrolLogExport({ ...request });
        }
    };

    const downloadWithURL = (url: string, reqNum: number) => {
        clearExcelTimer();
        excelTimerRef.current = setInterval(() => {
            fetchExcelLog({ reqNum });
        }, INTERVAL_TIME);
    };

    const clearExcelTimer = () => {
        if (!!excelTimerRef.current) {
            clearInterval(excelTimerRef.current);
            excelTimerRef.current = null;
        }
    };

    const { promise: postPatrolLogExport, state: patrolLogExportState } = useAsync({
        promise: postPatrolLogExportAPI,
        param: {},
        fixedParam: {
            startDate: moment(startDate).unix(),
            endDate: moment(endDate).unix(),
            zipFileName: 'Patrol_Log',
            fileName: 'Patrol_Log',
            columnMetas,
        },
        resolve: (res: ExcelExportResponse) => {
            const { filePath: url, reqNum } = res;
            if (url) {
                downloadWithURL(url, reqNum);
            }
        },
        reject: (err: Error) => {
            console.error(err);
        },
    });

    const { promise: fetchExcelLog } = useAsync({
        promise: fetchExcelLogApi,
        param: {},
        resolve: (res: ExcelExportResponse) => {
            const { dataSettingStatusType, filePath: url, fileName } = res;
            if (dataSettingStatusType === 'COMPLETED') {
                clearExcelTimer();
                excelExport(url, fileName);
            } else if (dataSettingStatusType === 'FAILED') {
                clearExcelTimer();
                toggleExcelDownloadResultModal();
            }
        },
        reject: (err: Error) => {
            console.error(err);
        },
    });

    const { promise: getPatrolLog, state: getPatrolLogState } = useAsync({
        promise: getPatrolLogAPI,
        immediate: true,
        param: {},
        fixedParam: {
            pageSize: PAGE_SIZE,
            startDate: moment(startDate).unix(),
            endDate: moment(endDate).unix(),
        },
        resolve: (res: TableData<never>) => {
            setTableData({ ...res, pageSize: PAGE_SIZE });
        },
        reject: (err: Error) => {
            console.error(err);
            setTableData(prevState => ({ ...prevState, rows: [] }));
        },
    });

    const { promise: getPatrolLogAll, state: getPatrolLogAllState } = useAsync({
        promise: getPatrolLogAPI,
        param: {},
        fixedParam: { isAll: 'Y' },
        resolve: (res: TableData<never>) => {
            setPatrolAllLog(res);
        },
        reject: (err: Error) => {
            console.error(err);
            setTableData(prevState => ({ ...prevState, rows: [] }));
            toggleFailureModal();
        },
    });

    const { promise: getPatrolReport, state: getPatrolReportState } = useAsync({
        promise: getPatrolReportAPI,
        param: {},
        fixedParam: { isAll: 'Y' },
        resolve: (res: TableData<PatrolReportResponse>) => {
            setPatrolReportData(res?.rows || []);
        },
        reject: (err: Error) => {
            console.error(err);
            setPatrolReportData([]);
        },
    });

    const { promise: getPatrolDaily, state: patrolDailyState } = useAsync({
        promise: getPatrolDailyAPI,
        param: {},
        resolve: (res: PatrolDailyLog) => {
            const { bedrooms, bilgeSoundings } = res;

            if (!bedrooms.length || !bilgeSoundings.length) {
                togglePatrolEmptyModal();
                return;
            }

            setDailyLog(res);
            toggleDailyLogPreview();
        },
        reject: (err: Error) => {
            console.error(err);
            // @ts-ignore
            if (err.data?.code === PATROL_EMPTY_CODE) {
                togglePatrolEmptyModal();
            } else {
                toggleFailureModal();
            }
        },
    });

    const { Modal: ExcelDownloadResultModal, toggleModal: toggleExcelDownloadResultModal } = useConfirmModal({
        initModal: false,
        header: { title: t('Result', 'ConfirmModal') },
        confirmText: t('Failed to download the excel.', 'ConfirmModal'),
        removeCancel: true,
    });

    const { Modal: ExcelDownloadNoDataModal, toggleModal: toggleExcelDownloadNoDataModal } = useConfirmModal({
        initModal: false,
        header: { title: t('Notification', 'ConfirmModal') },
        confirmText: t('There is no data.'),
        removeCancel: true,
    });

    const { Modal: PatrolEmptyModal, toggleModal: togglePatrolEmptyModal } = useConfirmModal({
        initModal: false,
        header: { title: t('Notification', 'ConfirmModal') },
        confirmText: t("Today's Patrol is empty."),
        removeCancel: true,
    });

    const { Modal: FailureModal, toggleModal: toggleFailureModal } = useConfirmModal({
        initModal: false,
        header: { title: t('Notification', 'ConfirmModal') },
        confirmText: t('The request failed.', 'ErrorHandler'),
        removeCancel: true,
    });

    const handlePatrolReportModal = () => {
        if (!tableData.rows.length) {
            toggleExcelDownloadNoDataModal();
            return;
        }
        getPatrolLogAll({ ...getPatrolLogState.request, pageSize: null });
        togglePatrolReportModal();
    };

    const handlePatrolDailyLogModal = () => {
        if (!tableData.rows.length) {
            toggleExcelDownloadNoDataModal();
            return;
        }
        togglePatrolDailyLog();
    };

    // Loading Block
    const portal = document.body;
    const blocking = patrolLogExportState.isLoading || getPatrolLogAllState.isLoading || patrolDailyState.isLoading;

    // @ts-ignore
    return (
        <Page menuPath={[t('Report', 'Menu'), t('Patrol Log', 'Menu')]}>
            <Search
                startDate={startDate}
                endDate={endDate}
                handleDateCallback={handleDateCallback}
                handleSearchClick={handleSearchClick}
                getPatrolLog={getPatrolLog}
            />
            <Card
                className="report-container"
                header={{
                    title: t('Patrol Log', 'Menu'),
                    action: (
                        <>
                            <Button className="btn-brand color-white" onClick={handlePatrolDailyLogModal}>
                                {t('Patrol Daily Log')}
                            </Button>
                            <Button className="btn-success color-white" onClick={handlePatrolReportModal}>
                                {t('Patrol Report')}
                            </Button>
                            <Button className="btn-brand" onClick={handleExportFileClick}>
                                {t('Export', 'Button')}
                            </Button>
                        </>
                    ),
                }}
            >
                <div style={{ height: 'calc(100vh - 24.5rem)', minHeight: '25rem' }}>
                    {/*@ts-ignore*/}
                    <Table
                        data={tableData}
                        columns={columns}
                        onPageChange={handlePageChange}
                        loading={getPatrolLogState.isLoading}
                    />
                </div>
            </Card>
            <ExcelDownloadResultModal />
            <ExcelDownloadNoDataModal />
            <FailureModal />
            <PatrolEmptyModal />

            {blocking && (
                <Portal container={portal}>
                    <LoadingScreen>
                        {/* @ts-ignore */}
                        <LoadingBlock blocking={blocking} />
                    </LoadingScreen>
                </Portal>
            )}

            {/* Patrol Report */}
            {!getPatrolLogAllState.isLoading && (
                <PatrolReportModal
                    initModal={patrolReport}
                    toggleModal={togglePatrolReportModal}
                    handlePatrolReportForm={handlePatrolReportForm}
                    data={patrolAllLog}
                    request={getPatrolLogState.request}
                />
            )}

            <PatrolReportPreviewModal
                initModal={reportPreview}
                toggleModal={toggleReportPreview}
                request={patrolReportForm}
                data={patrolReportData}
                isLoading={getPatrolReportState.isLoading}
            />

            {/* Patrol Daily Log */}
            <PatrolDailyLogModal
                initModal={patrolDailyLog}
                toggleModal={togglePatrolDailyLog}
                handlePatrolDailyLog={handlePatrolDailyLog}
                request={getPatrolLogState.request || {}}
            />

            {!patrolDailyState.isLoading && (
                <PatrolDailyLogPreviewModal
                    initModal={dailyLogPreview}
                    toggleModal={toggleDailyLogPreview}
                    request={patrolDailyState.request}
                    data={dailyLog}
                    isLoading={patrolDailyState.isLoading}
                />
            )}
        </Page>
    );
};

const LoadingScreen = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
`;

export default PatrolLog;
