import React from 'react';

import { useTranslation } from '@hooks';

import styled from 'styled-components';

const ApprovalTable = () => {
    const t = useTranslation('Patrol Log');

    const sectionList = [
        { value: 'director', label: t('Director') },
        { value: 'captain', label: t('Captain') },
    ];

    return (
        <table>
            <thead>
                <tr>
                    {sectionList.map(({ value, label }) => {
                        return <StyledHeader key={`header_${value}`}>{label}</StyledHeader>;
                    })}
                </tr>
                <tr>
                    {sectionList.map((_, idx) => {
                        return <StyledTd key={`secondTd_${idx}`}></StyledTd>;
                    })}
                </tr>
            </thead>
        </table>
    );
};

const StyledTd = styled.td`
    border-bottom: 1px solid black;
    border-right: 1px solid black;
    width: 8rem;
    height: 3rem;
    text-align: center;
    background-color: white;
    :first-child {
        border-left: 1px solid black;
    }
`;

const StyledHeader = styled(StyledTd)`
    background-color: lightgrey;
    height: 1.4rem;
    padding: 0.2rem;

    color: black;
    font-size: 0.8rem;
    font-weight: bold;
    border-top: 1px solid black;
`;

export default ApprovalTable;
