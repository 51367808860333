import React, { useContext } from 'react';

import { Card, LoadingBlock } from '../../../../Common';
import { useTranslation } from '../../../../../util/hooks';

import ManualItem from './ManualItem';
import Pagination from './Pagination';

import { TechnicalManualDispatchContext, TechnicalManualStateContext } from '../../index';
import { setPaging } from '../../technicalManualReducer';

import { UseAsyncState } from '../../type';

import styled from 'styled-components';

const ManualList = ({
    getManualList,
    getManualListState,
}: {
    getManualList: Function;
    getManualListState: UseAsyncState;
}) => {
    const t = useTranslation('Technical Manual');

    const dispatch = useContext(TechnicalManualDispatchContext);
    const { manualList, paging, selectedCategory, selectedSearchOption, searchInput } =
        useContext(TechnicalManualStateContext);

    const { page, totalPage, pageSize, totalCount } = paging;

    const handlePage = (num: number) => {
        if (num <= 0) {
            num = 0;
        } else if (totalPage - 1 <= num) {
            num = totalPage - 1;
        }

        if (dispatch) {
            dispatch(setPaging({ page: num + 1 }));
        }
        if (num + 1 !== page) {
            const param = {
                page: num + 1,
                pageSize,
                category: selectedCategory ? selectedCategory.value : 'All',
                opt: selectedSearchOption ? selectedSearchOption.value : 'All',
                keyword: searchInput,
            };
            getManualList(param);
        }
    };

    return (
        <Card header={{ title: t('Technical Manual Search', 'Menu') }}>
            {/*@ts-ignore*/}
            <LoadingBlock blocking={getManualListState.isLoading}>
                <Pagination
                    gotoPage={handlePage}
                    pageIndex={page - 1}
                    pageSize={pageSize}
                    totalCount={totalCount}
                    totalPage={totalPage}
                />
                <ListContainer>
                    {manualList && !!manualList.length ? (
                        <ListWrapper>
                            {manualList.map(manual => {
                                const { iconURL, manualTitle, manualNum } = manual;
                                return (
                                    <ManualItem key={manualNum} icon={iconURL} title={manualTitle} num={manualNum} />
                                );
                            })}
                        </ListWrapper>
                    ) : (
                        <NoDataWrapper>
                            <NoDataComponent>{t('No data', 'Table')}</NoDataComponent>
                        </NoDataWrapper>
                    )}
                </ListContainer>
            </LoadingBlock>
        </Card>
    );
};

const ListContainer = styled.div`
    border-top: 1px solid lightgrey;
    border-bottom: 1px solid lightgrey;
    min-height: 32rem;
    height: calc(100vh - 24rem);
    overflow-y: auto;
    margin: 0.5rem 0;
`;

const ListWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(4, minmax(160px, 240px));
    justify-content: space-around;
    padding: 1rem;

    @media (max-width: 698px) {
        grid-template-columns: 100%;
        align-content: start;
        padding: 0.4rem;
    }
`;

const NoDataWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const NoDataComponent = styled.span`
    padding: 2rem;
    background-color: rgba(0, 0, 0, 0.1);
`;

export default ManualList;
