import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';

export const initialState = {
    selectableSensorList: [],
    startDate: moment().startOf('day').valueOf(),
    endDate: moment().endOf('day').valueOf(),
};
const { actions, reducer } = createSlice({
    name: 'sensorLog',
    initialState,
    reducers: {
        setSensorList: (state, action) => {
            const sensorList = action.payload.rows.map(item => {
                const { sensorName, targetId } = item;
                return { label: sensorName, value: targetId };
            });
            state.selectableSensorList = sensorList;
        },
        setStartDate: (state, action) => {
            state.startDate = action.payload;
        },
        setEndDate: (state, action) => {
            state.endDate = action.payload;
        },
    },
});

export const { setSensorList, setStartDate, setEndDate } = actions;
export default reducer;
