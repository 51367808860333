import React, { useEffect, useState } from 'react';

import { Button, TimePicker } from '@components';
import useTranslation from '@hooks/useTranslation';

import FilterSearchGroup from '@components/FilterSearchGroup';
import { SelectGroup, FilterList } from '@components/FilterSearchGroup/Components/Part';
import FilterGroup from '@components/FilterSearchGroup/Components/FilterGroup';
import FilterButtonArea from '@components/FilterSearchGroup/Components/FilterButtonArea';

import moment from 'moment';

const Search = ({ getExcelLogList }) => {
    const t = useTranslation('Search');

    const [startDate, setStartDate] = useState(moment().startOf('day').valueOf());
    const [endDate, setEndDate] = useState(moment().endOf('day').valueOf());

    const handleSearchClick = () => {
        getExcelLogList({
            startDate: startDate ? moment(startDate).unix() : null,
            endDate: endDate ? moment(endDate).unix() : null,
        });
    };

    const handleRefreshClick = e => {
        const startDate = moment().startOf('day').valueOf();
        const endDate = moment().endOf('day').valueOf();
        setStartDate(startDate);
        setEndDate(endDate);
        getExcelLogList({
            startDate: startDate ? moment(startDate).unix() : null,
            endDate: endDate ? moment(endDate).unix() : null,
        });
    };

    const handleYesterdayButtonClick = () => {
        const start = moment().subtract(1, 'day').startOf('day').valueOf();
        const end = moment().subtract(1, 'day').endOf('day').valueOf();

        setStartDate(start);
        setEndDate(end);
    };

    useEffect(() => {
        handleSearchClick();
    }, []);

    return (
        <FilterSearchGroup>
            <FilterGroup className={'bg-brand'} label={t('Search', 'Search')}>
                <FilterList>
                    <SelectGroup>
                        <div className="datePicker-container">
                            <TimePicker
                                value={startDate}
                                handleChange={selected => setStartDate(selected)}
                                valueType="ms"
                                maxDate={endDate || moment.now()}
                                showTimeInput
                            />
                        </div>
                    </SelectGroup>
                    <div className="text-center"> ~ </div>
                    <SelectGroup>
                        <div className="datePicker-container">
                            <TimePicker
                                value={endDate}
                                handleChange={selected => setEndDate(selected)}
                                valueType={'ms'}
                                minDate={startDate}
                                maxDate={moment.now()}
                                showTimeInput
                            />
                        </div>
                    </SelectGroup>
                    <div className="d-flex gap-1 justify-content-end">
                        <Button
                            className="btn-secondary btn-yesterday"
                            style={{ width: 'fit-content' }}
                            onClick={handleYesterdayButtonClick}
                        >
                            {t('Yesterday', 'Button')}
                        </Button>
                        <Button className="btn-brand btn-icon" iconName="search" onClick={handleSearchClick}>
                            {t('Search', 'Search')}
                        </Button>
                        <Button className="btn-danger btn-icon-only" iconName="replay" onClick={handleRefreshClick} />
                    </div>
                </FilterList>
                <FilterButtonArea />
            </FilterGroup>
        </FilterSearchGroup>
    );
};

export default Search;
