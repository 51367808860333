import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';

import Label from '@components/Label/CustomLabel';
import { Select as SingleSelect, Button, TextInput } from '@components';
import WidgetConfirmModal from '../../../Components/WidgetConfirmModal';

import { useTranslation, useAsync } from '@hooks';

import { stateOptions, timeConverter } from './util';

import { getBattleEndAPI, getBattleGeofenceListAPI, getBattleStartAPI, getBattleStatusAPI } from '@api/military';

import {
    setStartTime,
    setBattleNum,
    setConjuncture,
    setConjunctureStatus,
    setGeofenceList,
    setSelectedGeofence,
    setConjuncturePersonnelByFloor,
    setConjunctureGeofence,
    setSituation,
    setConjunctureCount,
    setConjunctureAllCount,
    INITIAL_CONJUNCTURE_PERSONNEL_BY_FLOOR,
} from '../stateConversionReducer';
import { StateConversionDispatchContext, StateConversionStateContext } from '../index';

import cx from 'classnames';

import moment from 'moment';

import { SelectContainer } from '../style';

import { useSelector } from 'react-redux';

import { dateToFormat } from '@util/common/util';

const SelectBar = ({ currentTime, handleStartTime }) => {
    const t = useTranslation('State Conversion');

    const timeIntervalRef = useRef(null);

    const [timeDiff, setTimeDiff] = useState(0);

    const [confirmConjunctureStart, setConfirmConjunctureStart] = useState(false);
    const [confirmConjunctureEnd, setConfirmConjunctureEnd] = useState(false);

    const [failedRequest, setFailedRequest] = useState(false);
    const [failedConjunctureAlreadyBegun, setFailedConjunctureAlreadyBegun] = useState(false);
    const [failedConjunctureAlreadyOver, setFailedConjunctureAlreadyOver] = useState(false);
    const [failedConjunctureStartWithNoGeofence, setFailedConjunctureStartWithNoGeofence] = useState(false);

    const { conjuncture } = useSelector(state => state.Navy);

    const dispatch = useContext(StateConversionDispatchContext);
    const { lastConjunctureStatus, startTime, endTime, conjunctureStatus, situation, floorInfo, conjunctureGeofence } =
        useContext(StateConversionStateContext);
    const { situation: lastSituation, startTime: lastStartTime, endTime: lastEndTime } = lastConjunctureStatus;
    const serverDate = moment().unix() - moment.unix(currentTime).unix();

    const start = useCallback(() => {
        if (timeIntervalRef.current) {
            clearInterval(timeIntervalRef.current);
        }

        timeIntervalRef.current = setInterval(() => {
            const offsetTime = moment().unix() - serverDate;

            const time = offsetTime - startTime;
            setTimeDiff(time < 0 ? 0 : time);
        }, 1000);
    }, [startTime, currentTime]);

    const end = () => {
        if (timeIntervalRef.current) {
            clearInterval(timeIntervalRef.current);
            timeIntervalRef.current = null;
        }
    };

    const handleConversionStart = () => {
        if (conjunctureStatus === 'N') {
            setConfirmConjunctureStart(true);
        }
    };

    const handleConversionEnd = () => {
        if (conjunctureStatus === 'N') {
            return;
        }
        setConfirmConjunctureEnd(true);
    };

    const handleChangeOption = select => {
        if (select && select.value) {
            dispatch(setSituation(select));
            getBattleGeofenceList({ situation: select.value });
        }
    };

    const { promise: getBattleStart } = useAsync({
        promise: getBattleStartAPI,
        resolve: res => {
            const { regDate, battleNum, serverDate } = res;
            handleStartTime(serverDate);
            dispatch(setStartTime(regDate));
            dispatch(setBattleNum(battleNum));
            dispatch(setConjunctureStatus('Y'));
            start();
        },
        reject: err => {
            const {
                data: { code },
            } = err;

            if (code === '1101') {
                setFailedConjunctureAlreadyBegun(true);
            } else if (code === '1102') {
                setFailedConjunctureStartWithNoGeofence(true);
            } else {
                setFailedRequest(true);
                console.error(err);
            }
        },
    });

    const { promise: getBattleEnd } = useAsync({
        promise: getBattleEndAPI,
        resolve: () => {
            dispatch(setStartTime(null));
            getBattleStatus();
            end();
        },
        reject: err => {
            const {
                data: { code },
            } = err;
            if (code === '1101') {
                setFailedConjunctureAlreadyOver(true);
            } else {
                setFailedRequest(true);
                console.error(err);
            }
        },
    });

    const { promise: getBattleStatus } = useAsync({
        promise: getBattleStatusAPI,
        resolve: res => {
            dispatch(setConjuncture(res));
            if (res.conjunctureStatus === 'Y') {
                dispatch(setStartTime(res.startTime));
            }
        },
        reject: err => {
            console.error(err);
        },
    });

    const { promise: getBattleGeofenceList } = useAsync({
        promise: getBattleGeofenceListAPI,
        resolve: res => {
            const {
                geofenceList,
                floorCompleteCnt: complete,
                floorNotCompleteCnt: inComplete,
                allCompleteCnt,
                allNotCompleteCnt,
            } = res;

            if (!geofenceList) return;
            const result = geofenceList.map(row => {
                return {
                    ...row,
                    bounds: [row.latLngList.map(({ lat, lng }) => [lat, lng])],
                };
            });
            dispatch(setConjunctureGeofence(result));
            dispatch(setConjunctureCount({ complete, inComplete }));
            dispatch(setConjunctureAllCount({ allCompleteCnt, allNotCompleteCnt }));

            if (floorInfo && floorInfo.floorId !== 'entire') {
                const geofence = result.filter(list => list.floor === floorInfo.floorId);
                dispatch(setGeofenceList(geofence));
            }
        },
        reject: err => {
            console.error(err);
        },
    });

    useEffect(() => {
        if (conjunctureStatus === 'Y') {
            start();
        }

        return () => {
            end();
        };
    }, [conjunctureStatus, start]);

    useEffect(() => {
        if (conjuncture) {
            const { endTime, ConjunctureOn: conjunctureOn } = conjuncture;
            if (conjunctureOn) {
                getBattleStatus();
                if (floorInfo.floorId !== 'entire' && !!conjunctureGeofence) {
                    dispatch(setGeofenceList(conjunctureGeofence));
                }
                return;
            }
            if (endTime) {
                end();
                getBattleStatus();
                dispatch(setSelectedGeofence(null));
                dispatch(setConjuncturePersonnelByFloor(INITIAL_CONJUNCTURE_PERSONNEL_BY_FLOOR));
            } else {
                dispatch(setConjuncture(conjuncture));
            }
        }
    }, [conjuncture]);

    useEffect(() => {
        if (lastSituation) {
            getBattleGeofenceList({ situation: lastSituation.value });
            dispatch(setSituation(lastSituation));
        }
    }, [lastSituation]);

    return (
        <>
            <SelectContainer>
                <div className="d-flex">
                    <Label
                        name={t('Select state') + ' :'}
                        labelGroupClassName="me-2 state-conversion-label"
                        labelValueClassName="min-w-none w-none"
                    />
                    {conjunctureStatus === 'Y' ? (
                        <TextInput
                            name="conversionInput"
                            value={situation && situation['label'] ? t(situation['label']) : ''}
                            inputGroupClassName="form-disable w-px-150"
                            readOnly
                        />
                    ) : (
                        <SingleSelect
                            name="stateSelect"
                            value={situation ? { label: t(situation.label), value: situation.value } : null}
                            options={stateOptions.map(({ label, value }) => ({ label: t(label), value }))}
                            onChange={handleChangeOption}
                        />
                    )}
                </div>
                <div className="flx-1 d-flex flex-column gap-1">
                    <div className="d-flex gap-3">
                        <Button
                            className={cx('btn-secondary', conjunctureStatus === 'Y' && 'form-disable')}
                            onClick={handleConversionStart}
                        >
                            {t('Start state conversion')}
                        </Button>
                        <div className="d-flex align-items-center">
                            <div className="pnt-txt txt-bold me-1">{`${t('Start Time')} : `}</div>

                            {conjunctureStatus === 'N' &&
                                (lastSituation && lastSituation.value === situation.value && lastStartTime
                                    ? dateToFormat(moment.unix(lastStartTime))
                                    : '-')}
                            {conjunctureStatus === 'Y' && (startTime ? dateToFormat(moment.unix(startTime)) : '-')}
                        </div>
                    </div>
                    <div className="d-flex gap-3">
                        <Button
                            className={cx('btn-lightgray', conjunctureStatus !== 'Y' && 'form-disable')}
                            onClick={handleConversionEnd}
                        >
                            {t('End state conversion')}
                        </Button>
                        <div className="d-flex align-items-center">
                            <div className="pnt-txt txt-bold me-1">{`${t('End Time')} : `}</div>
                            {conjunctureStatus === 'N' &&
                                (lastSituation && lastSituation.value === situation.value && lastEndTime
                                    ? dateToFormat(moment.unix(lastEndTime))
                                    : '-')}
                            {conjunctureStatus === 'Y' && (endTime ? dateToFormat(moment.unix(endTime)) : '-')}
                        </div>
                    </div>
                </div>
                <div className="flx-1 d-flex gap-3 align-items-center font-size-lg">
                    <div className="pnt-txt p1-n txt-bold">{t('State conversion progress time') + ' : '}</div>
                    <div className="pnt-txt h3-n">
                        {conjunctureStatus === 'Y' && `${timeConverter(timeDiff, t)} ${t('Pass')}`}
                        {conjunctureStatus === 'N' &&
                            (lastSituation && lastSituation.value === situation.value
                                ? `${timeConverter(lastEndTime - lastStartTime, t)}`
                                : `${timeConverter(0, t)}`)}
                    </div>
                </div>
            </SelectContainer>
            <WidgetConfirmModal
                initModal={confirmConjunctureStart}
                toggleModal={() => setConfirmConjunctureStart(!confirmConjunctureStart)}
                confirmText={
                    <>
                        <div>{t('Confirm Contents For Warning')}</div>
                        <div>{t('Confirm Contents For Recheck')}</div>
                        <div>{t('Confirm Contents For Start')}</div>
                    </>
                }
                okCallback={() => {
                    setTimeDiff(0);
                    if (situation) {
                        getBattleStart({ situation: situation['value'] });
                    }
                }}
            />
            <WidgetConfirmModal
                initModal={confirmConjunctureEnd}
                toggleModal={() => setConfirmConjunctureEnd(!confirmConjunctureEnd)}
                confirmText={t('Are you sure you want to end the conjuncture?')}
                okCallback={() => {
                    getBattleEnd({ situation: situation['value'] });
                    end();
                    dispatch(setSelectedGeofence(null));
                    dispatch(setConjuncturePersonnelByFloor(INITIAL_CONJUNCTURE_PERSONNEL_BY_FLOOR));
                }}
            />
            <WidgetConfirmModal
                initModal={failedRequest}
                toggleModal={() => setFailedRequest(!failedRequest)}
                confirmText={t('Your request was failed.')}
                removeCancel
            />
            <WidgetConfirmModal
                initModal={failedConjunctureAlreadyBegun}
                toggleModal={() => setFailedConjunctureAlreadyBegun(!failedConjunctureAlreadyBegun)}
                confirmText={t('The State Conversion has already begun.')}
                removeCancel
            />
            <WidgetConfirmModal
                initModal={failedConjunctureAlreadyOver}
                toggleModal={() => setFailedConjunctureAlreadyOver(!failedConjunctureAlreadyOver)}
                confirmText={t('The State Conversion has already over.')}
                removeCancel
            />
            <WidgetConfirmModal
                initModal={failedConjunctureStartWithNoGeofence}
                toggleModal={() => setFailedConjunctureStartWithNoGeofence(!failedConjunctureStartWithNoGeofence)}
                confirmText={t('There is no geofence registered for that conjuncture.')}
                removeCancel
            />
        </>
    );
};

export default SelectBar;
