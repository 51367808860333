import axios from 'axios';
import envKeys from '../environment';
import { checkResponseErr, getLsUserInfo } from '../util/common/util';
import { store } from '../index';
import { setError } from '../reducers/ErrorHandler';

axios.defaults.headers.common['Content-Type'] = 'application/json; charset=UTF-8';

const { userInfoKey } = envKeys;

const URL_KEY_OAUTH = 'oAuthUrl';
const URL_KEY_WMS = 'wmsApiUrl';

export const getApiURL = async function () {
    let apiFile = 'api.json';
    if (process.env.NODE_ENV === 'development') {
        apiFile = 'devApi.json';
    }

    return await fetch(`config/${apiFile}`)
        .then(response => {
            return response.json();
        })
        .then(config => {
            return config;
        })
        .catch(error => {
            console.log({ error });
            return {};
        });
};

const apiUrl = getApiURL();

const createConfigHandler = apiURLKey => async request => {
    const baseURL = await apiUrl;
    if (baseURL && baseURL[apiURLKey]) {
        request.baseURL = baseURL[apiURLKey];
        if (apiURLKey === URL_KEY_OAUTH) {
            request.data = {
                grant_type: 'password',
                client_id: baseURL.clientId,
                client_secret: baseURL.clientSecret,
                ...request.data,
            };
        }
    }
    if (localStorage.getItem(userInfoKey)) {
        const { oAuthInfo, userInfo } = getLsUserInfo();
        if (oAuthInfo && oAuthInfo.access_token) {
            request.headers['UUID'] = oAuthInfo.scope;
            request.headers['Authorization'] = `${oAuthInfo.token_type} ${oAuthInfo.access_token}`;
        }
        if (userInfo && userInfo.companyInfo && userInfo.companyInfo.uuid) {
            request.headers['UUID'] = userInfo.companyInfo.uuid;
            request.headers['token'] = userInfo.companyInfo.secret;
        }
    }
    return request;
};

const errHandler = error => {
    return Promise.reject(error);
};

const errorResponseHandler = response => {
    if (checkResponseErr(response)) {
        store.dispatch(setError(response));
    }
    return response;
};

const errorRejectHandler = error => {
    let { response } = error;
    let errMsg;

    if (!response) {
        const message = error.message ? error.message : 'Unknown message.';
        response = { data: { result: 'error', message } };
    }

    if (checkResponseErr(response) && response.data.message) {
        errMsg = response;
    } else {
        errMsg = {
            ...response,
            data: { ...response.data, message: 'The request failed.' },
        };
    }
    store.dispatch(setError(errMsg));

    return Promise.reject(error);
};

const wmsAxios = axios.create({
    baseURL: process.env.REACT_APP_WMS_API_URL,
});
wmsAxios.interceptors.request.use(createConfigHandler(URL_KEY_WMS), errHandler);
wmsAxios.interceptors.response.use(errorResponseHandler, errorRejectHandler);

const oAuthAxios = axios.create({
    baseURL: process.env.REACT_APP_OAUTH_URL,
});
oAuthAxios.interceptors.request.use(createConfigHandler(URL_KEY_OAUTH), errHandler);
oAuthAxios.interceptors.response.use(errorResponseHandler, errorRejectHandler);

const injectAbortController = ({ httpRequest, url, body, config }) => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    const fetch = () => {
        if (body) {
            return httpRequest(url, body, { ...config, signal });
        }
        return httpRequest(url, { ...config, signal });
    };

    return {
        fetch,
        abort: () => {
            abortController.abort();
        },
    };
};

export const reqGet = (url, param) => {
    return injectAbortController({
        httpRequest: wmsAxios.get,
        url,
        config: { params: param },
    });
};
export const reqPost = (url, param, reqConfig) => {
    return injectAbortController({
        httpRequest: wmsAxios.post,
        url,
        body: param,
        config: { params: reqConfig },
    });
};
export const reqPut = (url, param) => {
    return injectAbortController({
        httpRequest: wmsAxios.put,
        url,
        body: param,
    });
};
export const reqPatch = (url, param) => {
    return injectAbortController({
        httpRequest: wmsAxios.patch,
        url,
        body: param,
    });
};
export const reqDelete = (url, param) => {
    return injectAbortController({
        httpRequest: wmsAxios.delete,
        url,
        config: { params: param },
    });
};
export const oAuthPost = (url, param) => {
    return injectAbortController({
        httpRequest: oAuthAxios.post,
        url,
        body: param,
    });
};

export default {
    reqGet,
    reqPost,
    reqPut,
    reqPatch,
    reqDelete,
    oAuthPost,
};
