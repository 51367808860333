import React, { useReducer, useRef, useState, createContext } from 'react';
import { useRouteMatch } from 'react-router-dom';

import { fetchFloorList } from 'api/common';
import { fetchExcelLogApi, fetchExcelSensorLogExport, fetchSensorList, fetchSensorLogList } from 'api/log';

import { useConfirmModal, useColumns, useAsync, useTranslation } from '@hooks';
import { Table, Button, Card, Page } from '@components';

import Search from './Components/Search';
import { excelExport } from '../util/util';
import * as column from '../../util/grid/column';

import { useDispatch } from 'react-redux';
import { setFloorList } from 'reducers/Common/FloorInfo';
import sensorLogReducer, { initialState, setSensorList } from './sensorLogReducer';

import moment from 'moment';

const INTERVAL_TIME = 1000;

export const SensorLogDispatchContext = createContext();
export const SensorLogStateContext = createContext(null);

const SensorLog = () => {
    const t = useTranslation('Sensor Log');
    const columnT = useTranslation('CommonColumn');

    const storeDispatch = useDispatch();
    const [state, dispatch] = useReducer(sensorLogReducer, initialState);
    const { startDate, endDate } = state;

    const searchRef = useRef();
    const excelTimerRef = useRef();

    const match = useRouteMatch();

    useAsync({
        promise: fetchFloorList,
        resolve: response => {
            storeDispatch(setFloorList(response));
        },
        immediate: true,
        deps: [match.params.menuNum],
    });

    useAsync({
        promise: fetchSensorList,
        resolve: response => {
            dispatch(setSensorList(response));
        },
        immediate: true,
    });

    const columns = useColumns([
        column.number(),
        column.sensorName(),
        column.sensorId(),
        column.sensorType(),
        column.calories({
            width: 90,
        }),
        column.stepCount(),
        column.sleepIndex(),
        column.stress(),
        column.heartbeat({
            width: 80,
        }),
        column.distance(),
        column.registeredDate(),
    ]);

    const [sensorLogData, setSensorLogData] = useState({
        totalCount: 0,
        totalPage: 1,
        rows: [],
    });

    const { promise: getSensorLogList, state: sensorLogListInfo } = useAsync({
        promise: fetchSensorLogList,
        keepState: true,
        immediate: true,
        fixedParam: { pageSize: 15 },
        resolve: res => {
            const data = res.rows.map(log => {
                return {
                    comNum: log.comNum,
                    logNum: log.logNum,
                    targetId: log.targetId,
                    targetName: log.targetName,
                    deviceId: log.deviceId,
                    deviceName: log.deviceName,
                    deviceType: log.deviceType,
                    floorId: log.floorId,
                    // ...JSON.parse(log.data),
                    ...log.dataMap,
                    regDate: log.regDate,
                    ...log,
                };
            });
            setSensorLogData({ ...res, rows: data });
        },
    });

    const { promise: createNewLogFile } = useAsync({
        promise: fetchExcelSensorLogExport,
        fixedParam: {
            startDate: startDate ? moment(startDate).unix() : 0,
            endDate: endDate ? moment(endDate).unix() : moment().unix(),
            zipFileName: 'Sensor_Log',
            fileName: 'Sensor_Log',
            columnMetas: [
                {
                    key: 'logNum',
                    name: columnT('No.'),
                },
                {
                    key: 'deviceName',
                    name: columnT('Sensor Name'),
                },
                {
                    key: 'deviceId',
                    name: columnT('Sensor Id'),
                },
                {
                    key: 'deviceType',
                    name: columnT('Sensor Type'),
                },
                {
                    key: 'CALORIES',
                    name: columnT('Calories'),
                },
                {
                    key: 'STEP',
                    name: columnT('Step Count'),
                },
                {
                    key: 'SLEEP',
                    name: columnT('Sleep Index'),
                },
                {
                    key: 'STRESS',
                    name: columnT('Stress'),
                },
                {
                    key: 'HEARTBEAT',
                    name: columnT('Heartbeat'),
                },
                {
                    key: 'DISTANCE',
                    name: columnT('Distance'),
                },
                {
                    key: 'regDate',
                    name: columnT('Registered Date'),
                    isDateFormat: 'Y',
                },
            ],
        },
        resolve: res => {
            const { filePath: url, reqNum } = res;
            if (url) {
                downloadWithURL(url, reqNum);
            }
        },
        reject: err => {
            toggleExcelModal();
            console.error(err);
        },
    });

    const { promise: fetchExcelLog } = useAsync({
        promise: fetchExcelLogApi,
        resolve: res => {
            const { dataSettingStatusType, filePath: url, fileName } = res;
            if (dataSettingStatusType === 'COMPLETED') {
                clearExcelTimer();
                excelExport(url, fileName);
            } else if (dataSettingStatusType === 'FAILED') {
                clearExcelTimer();
                toggleExcelDownloadResultModal();
            }
        },
    });

    const downloadWithURL = (url, reqNum) => {
        clearExcelTimer();
        excelTimerRef.current = setInterval(() => {
            fetchExcelLog({ reqNum: reqNum });
        }, INTERVAL_TIME);
    };

    const clearExcelTimer = () => {
        if (!!excelTimerRef.current) {
            clearInterval(excelTimerRef.current);
            excelTimerRef.current = null;
        }
    };
    const handlePageChange = pageIndex => {
        if (
            sensorLogListInfo.request &&
            (sensorLogListInfo.request.keyword ||
                sensorLogListInfo.request.startDate ||
                sensorLogListInfo.request.endDate)
        ) {
            getSensorLogList({ ...sensorLogListInfo.request, page: pageIndex });
        } else {
            getSensorLogList({ page: pageIndex });
        }
    };

    const handleExportFileClick = () => {
        if (!sensorLogData.rows || !sensorLogData.rows.length) {
            toggleExcelDownloadNoDataModal();
            return;
        }

        const { request } = sensorLogListInfo;
        delete request.page;
        delete request.pageSize;
        request.isAll = 'Y';
        createNewLogFile({ ...request });
    };

    const { Modal: ExcelDownloadResultModal, toggleModal: toggleExcelDownloadResultModal } = useConfirmModal({
        initModal: false,
        header: { title: t('Result', 'ConfirmModal') },
        confirmText: t('Failed to download the excel.', 'ConfirmModal'),
    });

    const { toggleModal: toggleExcelModal, Modal: ExcelModal } = useConfirmModal({
        initModal: false,
        header: {
            title: t('Excel Download', 'Sensor Log List'),
        },
        confirmText: t(
            'You can see and download the Excel File that you have requested under `Report > Sensor Log List` menu.',
            'Sensor Log List',
        ),
        removeCancel: true,
    });

    const { Modal: ExcelDownloadNoDataModal, toggleModal: toggleExcelDownloadNoDataModal } = useConfirmModal({
        initModal: false,
        header: { title: t('Notification', 'ConfirmModal') },
        confirmText: t('There is no data.', 'ConfirmModal'),
        removeCancel: true,
    });

    return (
        <SensorLogDispatchContext.Provider value={dispatch}>
            <SensorLogStateContext.Provider value={state}>
                <Page menuPath={[t('Report', 'Menu'), t('Sensor Log', 'Menu')]} className="h-100">
                    <Search getLocationLogList={getSensorLogList} />
                    <Card
                        innerRef={searchRef}
                        className="report-container"
                        header={{
                            title: t('Sensor Log List'),
                            action: (
                                <Button className="btn-secondary" onClick={handleExportFileClick}>
                                    {t('Export', 'Button')}
                                </Button>
                            ),
                        }}
                    >
                        <div style={{ height: '35rem' }}>
                            <Table
                                data={{ ...sensorLogData, pageSize: 15 }}
                                columns={columns}
                                onPageChange={handlePageChange}
                                loading={sensorLogListInfo.isLoading}
                            />
                        </div>
                    </Card>
                    <ExcelModal />
                    <ExcelDownloadResultModal />
                    <ExcelDownloadNoDataModal />
                </Page>
            </SensorLogStateContext.Provider>
        </SensorLogDispatchContext.Provider>
    );
};

export default SensorLog;
