import styled, { css } from 'styled-components';

import { layoutSize } from '@util/common/util';

// PatrolMonitoring.jsx
export const PatrolMonitoringContainer = styled.div`
    display: grid;
    height: 100%;
    grid-template-columns: 1fr 3fr;
    gap: 1rem;

    @media (${layoutSize.desktopLarge}) {
        grid-template-columns: 1fr;
    }
`;

// CountItem.tsx
export const CountItemContainer = styled.div<{ $selected: boolean }>`
    ${({ $selected }) =>
        $selected &&
        css`
            box-shadow: rgba(50, 50, 93, 0.25) 0 3px 6px, rgba(0, 0, 0, 0.23) 0 3px 6px;
        `}
`;

export const ContainerParent = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

// @ts-ignore
export const HeaderContainer = styled(ContainerParent)<{ $complete: boolean }>`
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    padding: 0.3rem;
    color: white !important;
    font-weight: bold;
    border-bottom: 1px solid black;

    background-color: ${({ $complete }) => ($complete ? '#3e66fb' : '#ff3333')};
`;

export const BodyContainer = styled(ContainerParent)<{ $complete: boolean; $selected: boolean }>`
    font-size: 1.6rem;
    height: calc(100% - 31.2px);

    color: ${({ $complete }) => ($complete ? '#3e66fb' : '#ff3333')} !important;
    ${({ $selected }) =>
        $selected &&
        css`
            font-weight: bold;
        `}
`;

// PatrolDuty.jsx
export const PatrolDutyContainer = styled.div`
    display: flex;
    gap: 1rem;
    flex-shrink: 0;
    height: 85px;
    width: 100%;
    border-bottom: 1px solid rgb(229, 227, 243);
    padding: 0.5rem;

    @media (${layoutSize.desktopLarge}) {
        flex-direction: column;
        height: 180px;
        gap: 0.6rem;
    }
`;

export const PatrolMapContainer = styled.div`
    flex-grow: 1;
    @media (${layoutSize.desktopLarge}) {
        flex-grow: 0;
        height: 30rem;
    }
`;

export const ButtonContainer = styled.div`
    display: flex;
    flex: 2;
    gap: 0.5rem;
`;

export const TimeContainer = styled.div`
    display: flex;
    flex: 3;
    flex-direction: column;
    font-weight: bold;

    > div {
        display: flex;
        flex: 1;
        align-items: center;
    }
`;

export const PatrolStatusContainer = styled.div<{ $isEntire: boolean }>`
    display: grid;
    height: 100%;
    grid-template-rows: ${({ $isEntire }) => ($isEntire ? '1fr' : '200px 1fr')};
    gap: 0.5rem;
`;

export const EntireContainer = styled.div`
    height: 100%;
    .thead > .tr > div {
        color: white !important;
        background-color: #3e66fb !important;
        :last-child {
            background-color: #ff3333 !important;
        }
    }

    .tbody {
        .td:nth-child(2) {
            color: #3e66fb !important;
        }

        .td:nth-child(3) {
            color: #ff3333 !important;
        }

        .td:nth-child(n + 2) {
            font-weight: bold !important;
        }
    }
`;
