import React, { useMemo } from 'react';

import { useTranslation } from '@hooks';

import moment from 'moment';

import { MAX_SIZE, TWENTY_FOUR_HOURS, padNumberToString } from '../../utils';

import styled from 'styled-components';

const PatrolResultTable = ({ size = MAX_SIZE, data, start, timeList, interval = 1 }) => {
    const t = useTranslation('Patrol Log');

    const startPoint = start * MAX_SIZE;
    const sectionArray = Array(size > MAX_SIZE ? MAX_SIZE : size).fill(0);

    const arrangedData = useMemo(() => {
        return data
            .map(row => {
                const { beaconName, beaconId, patrolCompleteDates } = row;

                const filteredDates = sectionArray.reduce((acc, _, idx) => {
                    const currentTime = timeList[startPoint + idx];

                    const filteredCompleteDates = patrolCompleteDates
                        .filter(({ startTime }) => startTime >= currentTime && startTime < currentTime + interval)
                        .map(({ dates }) => dates)
                        .flat();

                    if (filteredCompleteDates.length)
                        acc.push({ startTime: currentTime, dates: filteredCompleteDates });
                    return acc;
                }, []);

                const cnt = filteredDates.reduce((acc, cur) => Math.max(acc, cur.dates.length), 0);
                const arraySize = cnt === 0 ? 1 : cnt;

                return Array(arraySize)
                    .fill(0)
                    .map((_, idx) => {
                        return {
                            beaconName: !idx ? beaconName : '',
                            beaconId: `${beaconId}_${idx}`,
                            patrolCompleteDates: timeList.map(v => {
                                const foundDates = filteredDates.find(({ startTime }) => startTime === v);
                                return foundDates?.dates[idx] || 0;
                            }),
                            // 첫번째 Row에서만 보이도록 값 수정
                            size: !idx ? arraySize : 0,
                        };
                    });
            })
            .flat();
    }, [data, start, timeList, interval]);

    return (
        <table>
            <thead>
                <tr>
                    <StyledHeader className="font-weight-bold">{t('Location/Time')}</StyledHeader>
                    {sectionArray.map((_, idx) => {
                        const start = timeList[startPoint + idx];
                        const startIdx = start >= TWENTY_FOUR_HOURS ? start - TWENTY_FOUR_HOURS : start;

                        const end = startIdx + interval;
                        const endIdx = end > TWENTY_FOUR_HOURS ? end - TWENTY_FOUR_HOURS : end;

                        return (
                            <StyledHeader key={`header_${startIdx}`}>{`${padNumberToString(
                                startIdx,
                            )}-${padNumberToString(endIdx)}`}</StyledHeader>
                        );
                    })}
                </tr>
            </thead>
            <tbody>
                {arrangedData.map(({ beaconName, beaconId, patrolCompleteDates, size }) => {
                    return (
                        <tr key={beaconId}>
                            {!!size && <StyledFirstRow rowSpan={size}>{beaconName}</StyledFirstRow>}
                            {sectionArray.map((value, idx) => {
                                const sectionData = patrolCompleteDates[startPoint + idx];
                                const date = sectionData?.date ? moment.unix(sectionData.date).format('HH:mm:ss') : '';
                                return (
                                    <StyledTd
                                        key={`${beaconId}_${startPoint + idx}`}
                                        $selected={sectionData?.problem === 'Y'}
                                    >
                                        {date}
                                    </StyledTd>
                                );
                            })}
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
};

const StyledTd = styled.td`
    border-bottom: 1px solid black;
    border-right: 1px solid black;
    width: 3rem;
    height: 1rem;
    text-align: center;
    background-color: ${({ $selected }) => ($selected ? 'lightcoral' : 'white')};
    font-size: 0.6rem;
    font-weight: bold;
    color: black;
    padding: 2px 0;
`;

const StyledFirstRow = styled(StyledTd)`
    width: 15%;
    border-left: 1px solid black;
`;

const StyledHeader = styled(StyledTd)`
    background-color: lightgrey;
    padding: 4px 0;
    font-size: 0.6rem;
    border-top: 1px solid black;

    :first-child {
        border-left: 1px solid black;
    }
`;

export default PatrolResultTable;
