import React, { useContext } from 'react';

import Nouislider from 'nouislider-react';
import 'nouislider/distribute/nouislider.css';

import { DrowningMonitoringDispatchContext } from 'Components/MainPages/Monitoring/DrowningMonitoring';
import { setPlayTime, setPlay } from 'Components/MainPages/Monitoring/DrowningMonitoring/DrowningMonitoringReducer';

const PlayBar = ({ start, stop, speed, playTime, startTime, endTime }) => {
    const dispatch = useContext(DrowningMonitoringDispatchContext);

    return (
        <Nouislider
            className={'play-bar'}
            animate={false}
            connect={[true, false]}
            start={[playTime]}
            step={1}
            style={{ height: '0.3rem', zIndex: 0 }}
            range={{
                min: startTime,
                max: endTime,
            }}
            onStart={(render, handle, value, un, percent) => {
                stop();
            }}
            onEnd={(render, handle, value, un, percent) => {
                start();
                dispatch(setPlayTime(value[0]));
                dispatch(setPlay({ speed }));
            }}
        />
    );
};

export default PlayBar;
