import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { editWidgetSettings } from '@reducer/Dashboards/DashboardEdit';
import { TagState } from '../../Components/WidgetSettingOptions';
import useTranslation from '@hooks/useTranslation';
import WidgetSettingModal from '../../Components/WidgetSettingModal';

const TagStateAlarmListSetting = ({ widgetInfo, ...restProps }) => {
    const t = useTranslation('TagStateAlarmList');
    const { config } = widgetInfo;
    const { settings: customSettings } = config;
    const dispatch = useDispatch();

    const [updatedWidgetInfo, setUpdatedWidgetInfo] = useState({});

    const handleTagStateSelectChange = selected => {
        setUpdatedWidgetInfo(prevState => {
            return { ...prevState, tagState: selected };
        });
    };

    const handleSubmitClick = data => {
        if (customSettings) {
            dispatch(
                editWidgetSettings({
                    id: widgetInfo.id,
                    settings: { ...customSettings, ...data, ...updatedWidgetInfo },
                }),
            );
        } else {
            dispatch(
                editWidgetSettings({
                    id: widgetInfo.id,
                    settings: { ...data, ...updatedWidgetInfo },
                }),
            );
        }
    };

    const handleCancelClick = () => {
        setUpdatedWidgetInfo({});
    };

    return (
        <WidgetSettingModal
            headerTitle={t('Tag State Alarm List Setting')}
            widgetInfo={widgetInfo}
            socketWidget
            hiddenHeader
            okCallback={handleSubmitClick}
            cancelCallback={handleCancelClick}
            {...restProps}
        >
            <TagState
                value={
                    updatedWidgetInfo.tagState
                        ? updatedWidgetInfo.tagState
                        : customSettings && customSettings.tagState
                        ? customSettings.tagState
                        : null
                }
                onChange={handleTagStateSelectChange}
            />
        </WidgetSettingModal>
    );
};

export default TagStateAlarmListSetting;
