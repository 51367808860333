import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { Page, Card, LoadingBlock } from '../../../Common';
import { useTranslation, useAsync } from '../../../../util/hooks';
import { getManualInfoAPI } from '../../../../api/military';

import FileList from './Components/FileList';
import ListButton from './Components/ListButton';

import ImageNotFound from '../../../../assets/images/image-not-found.svg';

import { ManualContentState, ManualInfoType, ManualDetailsParam } from '../type';

const NoImage = () => (
    <NoImgContainer>
        <ImgNotFoundContainer src={ImageNotFound} alt="no-image" />
    </NoImgContainer>
);

const DEFAULT_TABLE_CONTENT = [
    { title: 'Content Name', content: '' },
    { title: 'Category', content: '' },
    { title: 'Description', content: '' },
    { title: 'Icon', content: <NoImage /> },
    { title: 'Content File', content: '' },
];

const TechnicalManualDetails = () => {
    const t = useTranslation('Technical Manual');
    const history = useHistory();

    const { manualNum }: ManualDetailsParam = useParams();

    const [tableContent, setTableContent] = useState<ManualContentState[]>(DEFAULT_TABLE_CONTENT);

    const { promise: getManualInfo, state: manualInfoState } = useAsync({
        promise: getManualInfoAPI,
        param: {},
        resolve: (res: ManualInfoType) => {
            const { manualTitle, category, manualContent, iconURL, fileList } = res;
            const contentList = [manualTitle, category, manualContent, iconURL, fileList];

            const responseContentList = DEFAULT_TABLE_CONTENT.map((defaultContent, index) => {
                const { title } = defaultContent;

                let responseContent = null;

                if (title === 'Icon') {
                    responseContent = iconURL ? <img src={iconURL} alt="icon-image" /> : <NoImage />;
                } else if (title === 'Content File') {
                    responseContent = <FileList fileList={fileList} />;
                } else {
                    responseContent = contentList[index] || '';
                }

                return { title, content: responseContent };
            });

            setTableContent(responseContentList);
        },
        reject: (err: ErrorEvent) => {
            console.error(err);
            history.push('/manual/search');
        },
    });

    useEffect(() => {
        if (!!manualNum) {
            getManualInfo({
                manualNum,
            });
        }
    }, [manualNum]);

    return (
        <Page menuPath={[t('Technical Manual', 'Menu'), t('Technical Manual Details', 'Menu')]}>
            <Card
                header={{
                    title: t('Content Inquiry'),
                    action: <ListButton />,
                }}
                className="manual-container"
            >
                <LoadingBlock blocking={manualInfoState.isLoading}>
                    <ContentTableContainer>
                        <tbody>
                            {tableContent.map(({ title, content }) => (
                                <tr key={title}>
                                    <th>{t(title)}</th>
                                    {title === 'Icon' ? <TdContainer>{content}</TdContainer> : <td>{content}</td>}
                                </tr>
                            ))}
                        </tbody>
                    </ContentTableContainer>
                </LoadingBlock>
            </Card>
        </Page>
    );
};

const ContentTableContainer = styled.table`
    margin: 0.5rem 0;
    width: 100%;
    table-layout: fixed;
    th,
    td {
        border: 1px solid #afafaf;
        font-size: 1.5rem;
        color: #676767;
        padding: 0.5rem 10px;
    }

    th {
        background-color: #ebebeb;
        width: 12rem;
    }

    td > img {
        min-width: 50px;
        min-height: 50px;
        max-width: 200px;
        max-height: 200px;
        border: 1px dotted darkgray;
        padding: 7px;
    }

    @media (max-width: 800px) {
        tr {
            display: flex;
            flex-direction: column;
            th {
                width: 100%;
                display: flex;
                justify-content: center;
            }
        }
    }
`;

const NoImgContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px;
    height: 200px;
    border: 1px dotted darkgray;
    padding: 7px;
    filter: invert(0.7);
`;

const ImgNotFoundContainer = styled.img`
    width: 40px;
    height: 40px;
`;

const TdContainer = styled.td`
    @media (max-width: 800px) {
        display: flex;
        justify-content: center;
    }
`;

export default TechnicalManualDetails;
