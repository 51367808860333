import Widget, { WIDGET_CATEGORY } from '../staticInfo';

// Dashboard
import CategoryChart from './CategoryChart';
import CategoryChartSetting from './CategoryChart/CategoryChartSetting';
import FloorPermissionStatus from './FloorPermissionStatus';
import FloorSummary from './FloorSummary';
import FloorSummarySetting from './FloorSummary/FloorSummarySetting';
import GeofenceCongestionStatus from './GeofenceCongestionStatus';
import GeofenceStatus from './GeofenceStatus';
import GeofenceStatusSetting from './GeofenceStatus/GeofenceStatusSetting';
import IotItemCountStatus from './IotItemCountStatus';
import ItemDetailStatus from './ItemDetailStatus';
import ItemDetailStatusSetting from './ItemDetailStatus/ItemDetailStatusSetting';
import ScannerStatus from './ScannerStatus';
import SensorStatus from './SensorStatus';
import SensorStatusSetting from './SensorStatus/SensorStatusSetting';
import TagStateAlarmList from './TagStateAlarmList';
import TagStateAlarmListSetting from './TagStateAlarmList/TagStateAlarmListSetting';
import TagBatteryStatus from './TagBatteryStatus';
import TagStatus from './TagStatus';
import TagStatusSetting from './TagStatus/TagStatusSetting';
import TriggeredAlertStatus from './TriggeredAlertStatus';
import IotItemStatusSetting from './IotItemStatus/IotItemStatusSetting';

// Monitoring
import IotItemStatus from './IotItemStatus';
import MassRealTimeLocationStatus from './MassRealTimeLocationStatus';
import RealTimeLocationStatus from './RealTimeLocationStatus';
import RealTimeLocationSetting from './RealTimeLocationStatus/RealTimeLocationSetting';
import RealTimeSensorMonitoring from './RealTimeSensorMonitoring';
import RealTimeTagMonitoring from './RealTimeTagMonitoring';
import RealTimeTagMonitoringSetting from './RealTimeTagMonitoring/RealTimeTagMonitoringSetting';

// Manual Search
import AnalysisHeatMap from './AnalysisHeatMap';
import CongestionStatus from './CongestionStatus';
import CongestionStatusSetting from './CongestionStatus/Component/CongestionStatusSetting';
import HourlyCongestionStatus from './HourlyCongestionStatus';
import HourlyCongestionStatusSetting from './HourlyCongestionStatus/Component/HourlyCongestionStatusSetting';
import TopFiveCongestedGeofence from './TopFiveCongestedGeofence';
import TopFiveCongestedGeofenceSetting from './TopFiveCongestedGeofence/Component/TopFiveCongestedGeofenceSetting';

// 미사용 위젯
import PatientAbnormalSigns from './PatientAbnormalSigns';
import PatientState from './PatientState';

//// Dashboard
export const categoryChart: Widget = {
    type: 'categoryChart',
    category: WIDGET_CATEGORY.DASHBOARD,
    name: 'Category Chart',
    desc: 'It shows the ratio of registered targets by category in charts.',
    thumbnailURL: null,
    Component: CategoryChart,
    SettingComponent: CategoryChartSetting,
    config: { defaultSettings: { chartType: 'pie' } },
    layout: {
        minW: 2,
        w: 4,
        maxW: 5,
        minH: 15,
        h: 18,
        maxH: 20,
    },
};

export const floorPermissionStatus: Widget = {
    type: 'floorPermissionStatus',
    category: WIDGET_CATEGORY.DASHBOARD,
    name: 'Permitted/Restricted Floor Graph of Tag',
    desc: 'The graph shows the permitted/restricted number of beacons for each floor.',
    thumbnailURL: null,
    Component: FloorPermissionStatus,
    SettingComponent: null,
    config: {},
    layout: {
        minW: 7,
        w: 12,
        maxW: 16,
        minH: 14,
        h: 16,
        maxH: 18,
    },
};

export const floorSummary: Widget = {
    type: 'floorSummary',
    category: WIDGET_CATEGORY.DASHBOARD,
    name: 'Summary Of Floor',
    desc: 'It shows the list of registered floors. If you choose a specific floor, the map will appear for the selected floor.',
    thumbnailURL: null,
    Component: FloorSummary,
    SettingComponent: FloorSummarySetting,
    config: { defaultSettings: { floor: '' } },
    layout: {
        w: 6,
        h: 21,
        minW: 4,
        minH: 15,
    },
};

// 가장 혼잡합 지오펜스 Top 5
export const geofenceCongestionStatus: Widget = {
    type: 'geofenceCongestionStatus',
    category: WIDGET_CATEGORY.DASHBOARD,
    name: 'Top 5 Most Congested Geofence',
    desc: 'It shows the chart and the table describing the top 5 geofence with most number of entry event today.',
    thumbnailURL: null,
    Component: GeofenceCongestionStatus,
    SettingComponent: null,
    config: { defaultSettings: { intervalTime: 20000 } },
    layout: {
        minW: 4,
        w: 5,
        maxW: 6,
        minH: 25,
        h: 30,
        maxH: 33,
    },
};

export const geofenceStatus: Widget = {
    type: 'geofenceStatus',
    category: WIDGET_CATEGORY.DASHBOARD,
    name: 'Geofence Status',
    desc: 'The map shows the geofence for the selected floor.',
    thumbnailURL: null,
    Component: GeofenceStatus,
    SettingComponent: GeofenceStatusSetting,
    config: {},
    layout: {
        minW: 4,
        w: 6,
        maxW: 9,
        minH: 21,
        h: 27,
        maxH: 35,
    },
};

export const iotItemCountStatus: Widget = {
    type: 'iotItemCountStatus',
    category: WIDGET_CATEGORY.DASHBOARD,
    name: 'Number Of All IoT Item',
    desc: 'It shows the number of IoT Items that the user has registered in graphs and tables.',
    thumbnailURL: null,
    Component: IotItemCountStatus,
    SettingComponent: null,
    config: { defaultSettings: { intervalTime: 20000 } },
    layout: {
        minW: 3,
        w: 4,
        maxW: 6,
        minH: 20,
        h: 23,
        maxH: 26,
    },
};

// WMS상 카테고리별 자산 , 사용자 상세 정보
export const itemDetailStatus: Widget = {
    type: 'itemDetailStatus',
    category: WIDGET_CATEGORY.DASHBOARD,
    name: 'IoT Item Details By Category',
    desc: 'The chart and the table shows the current Top 5 by category of assets and people.',
    thumbnailURL: null,
    Component: ItemDetailStatus,
    SettingComponent: ItemDetailStatusSetting,
    config: { defaultSettings: { intervalTime: 20000, categoryCode: 'ASSET' } },
    layout: {
        minW: 4,
        w: 5,
        maxW: 6,
        minH: 25,
        h: 30,
        maxH: 33,
    },
};

export const scannerStatus: Widget = {
    type: 'scannerStatus',
    category: WIDGET_CATEGORY.DASHBOARD,
    name: 'Scanner Status',
    desc: 'It shows the number of all the online or the offline registered scanners.',
    thumbnailURL: null,
    Component: ScannerStatus,
    SettingComponent: null,
    config: { defaultSettings: { intervalTime: 20000 } },
    layout: {
        minW: 3,
        w: 4,
        maxW: 6,
        minH: 20,
        h: 23,
        maxH: 28,
    },
};

export const sensorStatus: Widget = {
    type: 'sensorStatus',
    category: WIDGET_CATEGORY.DASHBOARD,
    name: 'Sensor Status',
    desc: 'This widget shows the status of the sensors',
    thumbnailURL: null,
    Component: SensorStatus,
    SettingComponent: SensorStatusSetting,
    config: { defaultSettings: { intervalTime: 10000 } },
    layout: {
        minW: 8,
        w: 12,
        maxW: 12,
        minH: 26,
        h: 46,
        maxH: 54,
    },
};

export const tagBatteryStatus: Widget = {
    type: 'tagBatteryStatus',
    category: WIDGET_CATEGORY.DASHBOARD,
    name: 'Tag Battery Status',
    desc: 'It shows battery information of all tags with icons and tables.',
    thumbnailURL: null,
    Component: TagBatteryStatus,
    SettingComponent: null,
    config: { defaultSettings: { intervalTime: 20000 } },
    layout: {
        minW: 3,
        w: 4,
        maxW: 6,
        minH: 23,
        h: 25,
        maxH: 29,
    },
};

export const tagStateAlarmList: Widget = {
    type: 'tagStateAlarmList',
    category: WIDGET_CATEGORY.DASHBOARD,
    name: 'List Of Triggered Event',
    desc: 'It monitors the status of tags.',
    thumbnailURL: null,
    Component: TagStateAlarmList,
    SettingComponent: TagStateAlarmListSetting,
    config: {},
    layout: {
        minW: 6,
        w: 8,
        maxW: 11,
        minH: 18,
        h: 22,
        maxH: 26,
    },
};

export const tagStatus: Widget = {
    type: 'tagStatus',
    category: WIDGET_CATEGORY.DASHBOARD,
    name: 'Overview Of Tag Status',
    desc: 'The graph shows the beacon in use, the lost signal beacon, the beacon in the SOS state, the beacon in the low battery state, and the beacon that has not moved for more than 1 minute.',
    thumbnailURL: null,
    Component: TagStatus,
    SettingComponent: TagStatusSetting,
    config: {},
    layout: {
        minW: 6,
        w: 10,
        maxW: 13,
        minH: 10,
        h: 12,
        maxH: 14,
    },
};

export const triggeredAlertStatus: Widget = {
    type: 'triggeredAlertStatus',
    category: WIDGET_CATEGORY.DASHBOARD,
    name: 'Triggered Alarm Status',
    desc: 'It shows the type and the number of notifications that occurred today in graphs and tables.',
    thumbnailURL: null,
    Component: TriggeredAlertStatus,
    SettingComponent: null,
    config: { defaultSettings: { intervalTime: 20000 } },
    layout: {
        minW: 5,
        w: 7,
        maxW: 12,
        minH: 22,
        h: 23,
        maxH: 25,
    },
};

//// Monitoring
//자산 현황
export const iotItemStatus: Widget = {
    type: 'iotItemStatus',
    category: WIDGET_CATEGORY.MONITORING,
    name: 'Iot Item Status',
    desc: 'It shows the current status of the assets selecting all of them or some of them by their status.',
    thumbnailURL: null,
    Component: IotItemStatus,
    SettingComponent: IotItemStatusSetting,
    config: { defaultSettings: { intervalTime: 10000, categoryCode: 'ASSET' } },
    layout: {
        minW: 8,
        w: 12,
        maxW: 12,
        minH: 26,
        h: 46,
        maxH: 54,
    },
};

//대량 실시간 위치현황
export const massRealTimeLocationStatus: Widget = {
    type: 'massRealTimeLocationStatus',
    category: WIDGET_CATEGORY.MONITORING,
    name: 'Mass RealTime Location Status',
    desc: 'It monitors the registered tags in a line chart format.',
    thumbnailURL: null,
    Component: MassRealTimeLocationStatus,
    SettingComponent: RealTimeTagMonitoringSetting,
    config: { defaultSettings: { targetOption: 'ALL TARGET', floor: '' } },
    layout: {
        minW: 8,
        w: 10,
        maxW: 12,
        minH: 20,
        h: 26,
        maxH: 38,
    },
};

//실시간 위치현황
export const realTimeLocationStatus: Widget = {
    type: 'realTimeLocationStatus',
    category: WIDGET_CATEGORY.MONITORING,
    name: 'Realtime Location Status',
    desc: 'The map shows the targets that are located in the selected floor or in the geofence. You can also search a specific target and get detailed information about that specific target.',
    thumbnailURL: null,
    Component: RealTimeLocationStatus,
    SettingComponent: RealTimeLocationSetting,
    config: { defaultSettings: { targetOption: 'ALL TARGET', floor: '' } },
    layout: {
        minW: 6,
        w: 8,
        maxW: 12,
        minH: 23,
        h: 30,
        maxH: 45,
    },
};

//실시간 센서 모니터링
export const realTimeSensorMonitoring: Widget = {
    type: 'realTimeSensorMonitoring',
    category: WIDGET_CATEGORY.MONITORING,
    name: 'Realtime Sensor Monitoring',
    desc: 'It monitors the registered sensors.',
    thumbnailURL: null,
    Component: RealTimeSensorMonitoring,
    SettingComponent: null,
    config: {},
    layout: {
        minW: 6,
        w: 8,
        maxW: 12,
        minH: 23,
        h: 27,
        maxH: 39,
    },
};

// 실시간 태그 모니터링
export const realTimeTagMonitoring: Widget = {
    type: 'realTimeTagMonitoring',
    category: WIDGET_CATEGORY.MONITORING,
    name: 'Realtime Tag Monitoring',
    desc: 'It monitors the registered tags in a line chart format.',
    thumbnailURL: null,
    Component: RealTimeTagMonitoring,
    SettingComponent: RealTimeTagMonitoringSetting,
    config: { defaultSettings: { tagOption: 20, DataInteger: false } },
    layout: {
        minW: 8,
        w: 10,
        maxW: 12,
        minH: 20,
        h: 26,
        maxH: 38,
    },
};

//// Manual Search
//분석 - 히트맵
export const heatMap = {
    type: 'heatMap',
    category: WIDGET_CATEGORY.MANUAL_SEARCH,
    name: 'Heat Map',
    desc: 'It monitors the registered tags in a line chart format.',
    thumbnailURL: null,
    Component: AnalysisHeatMap,
    //SettingComponent: RealTimeTagMonitoringSetting,
    config: { defaultSettings: { tagOption: 20, DataInteger: false } },
    layout: {
        minW: 8,
        w: 12,
        maxW: 12,
        minH: 20,
        h: 40,
        maxH: 38,
    },
};

// 혼잡도 위젯
export const congestionStatus: Widget = {
    type: 'congestionStatus',
    category: WIDGET_CATEGORY.MANUAL_SEARCH,
    name: 'Congestion Status',
    desc: 'It displays congestion of each geofence',
    thumbnailURL: null,
    Component: CongestionStatus,
    SettingComponent: CongestionStatusSetting,
    config: {},
    layout: {
        minW: 8,
        w: 10,
        maxW: 12,
        minH: 40,
        h: 40,
        maxH: 50,
    },
};

// 시간별 혼잡도
export const hourlyCongestionStatus: Widget = {
    type: 'hourlyCongestionStatus',
    category: WIDGET_CATEGORY.MANUAL_SEARCH,
    name: 'Hourly congestion status',
    desc: 'This widget shows congestion status hourly',
    thumbnailURL: null,
    Component: HourlyCongestionStatus,
    SettingComponent: HourlyCongestionStatusSetting,
    config: {},
    layout: {
        minW: 8,
        w: 10,
        maxW: 12,
        minH: 50,
        h: 55,
        maxH: 60,
    },
};

// 혼잡한 지오펜스 top 5 위젯
export const topFiveCongestedGeofence: Widget = {
    type: 'topFiveCongestedGeofence',
    category: WIDGET_CATEGORY.MANUAL_SEARCH,
    name: 'Top five congested geofence',
    desc: 'It shows the top five crowded geofence by category or authorization',
    thumbnailURL: null,
    Component: TopFiveCongestedGeofence,
    SettingComponent: TopFiveCongestedGeofenceSetting,
    config: { defaultSettings: { type: 'category' } },
    layout: {
        minW: 8,
        w: 10,
        maxW: 12,
        minH: 20,
        h: 20,
        maxH: 50,
    },
};

//// 미사용 위젯
export const patientAbnormalSigns = {
    type: 'patientAbnormalSigns',
    name: 'Patient Abnormal Signs',
    desc: "It monitors the patient's abnormal signs",
    thumbnailURL: null,
    Component: PatientAbnormalSigns,
    SettingComponent: null,
    config: { defaultSettings: { intervalTime: 10000 } },
    layout: {
        w: 6,
        h: 27,
        minW: 5,
        minH: 21,
    },
};
export const patientState = {
    type: 'patientState',
    name: 'Patient Condition Status',
    desc: "It monitors the patient's condition",
    thumbnailURL: null,
    Component: PatientState,
    SettingComponent: null,
    config: { defaultSettings: { intervalTime: 10000 } },
    layout: {
        w: 6,
        h: 27,
        minW: 5,
        minH: 21,
    },
};

const shWidgetList = {
    // Dashboard
    categoryChart,
    floorPermissionStatus,
    floorSummary,
    geofenceCongestionStatus,
    geofenceStatus,
    iotItemCountStatus,
    itemDetailStatus,
    scannerStatus,
    sensorStatus,
    tagBatteryStatus,
    tagStateAlarmList,
    tagStatus,
    triggeredAlertStatus,

    // Monitoring
    iotItemStatus,
    massRealTimeLocationStatus,
    realTimeLocationStatus,
    realTimeSensorMonitoring,
    realTimeTagMonitoring,

    // Manual Search
    heatMap,
    congestionStatus,
    hourlyCongestionStatus,
    topFiveCongestedGeofence,

    // 미사용 위젯
    // patientAbnormalSigns,
    // patientState,
};

export default shWidgetList;
