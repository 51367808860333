import React, { useState } from 'react';

import { Label, SearchableSelect } from '@components';
import useTranslation from '@hooks/useTranslation';

import { useDispatch, useSelector } from 'react-redux';
import { editWidgetSettings } from '@reducer/Dashboards/DashboardEdit';

import { ChartType } from '../../Components/WidgetSettingOptions';
import WidgetSettingModal from '../../Components/WidgetSettingModal';
import { useSettings } from '../../util/useSettings';

const CategoryChartSetting = ({ widgetInfo, ...restProps }) => {
    const dispatch = useDispatch();
    const { topCategoryList } = useSelector(state => state.CategoryInfo);
    const t = useTranslation('CategoryChart');
    const { config } = widgetInfo;
    const settings = useSettings(config);
    const { settings: customSettings } = config;

    const [updatedWidgetInfo, setUpdatedWidgetInfo] = useState({});

    const handleSubmitClick = data => {
        if (customSettings) {
            dispatch(
                editWidgetSettings({
                    id: widgetInfo.id,
                    settings: { ...customSettings, ...data, ...updatedWidgetInfo },
                }),
            );
        } else {
            dispatch(
                editWidgetSettings({
                    id: widgetInfo.id,
                    settings: { ...data, ...updatedWidgetInfo },
                }),
            );
        }
    };

    const handleCancelClick = () => {
        setUpdatedWidgetInfo({});
    };

    const handleChartTypeChange = selectedChartType => {
        setUpdatedWidgetInfo(prevState => {
            return { ...prevState, chartType: selectedChartType };
        });
    };

    const handleCategoryTypeChange = selectedCategoryType => {
        setUpdatedWidgetInfo(prevState => {
            return { ...prevState, categoryType: selectedCategoryType.map(v => v.value) };
        });
    };

    return (
        <WidgetSettingModal
            headerTitle={t('Category Chart Setting')}
            widgetInfo={widgetInfo}
            socketWidget
            hiddenHeader
            okCallback={handleSubmitClick}
            cancelCallback={handleCancelClick}
            {...restProps}
        >
            <ChartType
                value={updatedWidgetInfo.chartType ? updatedWidgetInfo.chartType : settings.chartType}
                onChange={handleChartTypeChange}
            />

            <Label
                name={t('Type of Category')}
                value={
                    <SearchableSelect
                        data={topCategoryList}
                        selected={
                            updatedWidgetInfo.categoryType
                                ? updatedWidgetInfo.categoryType
                                : customSettings && customSettings.categoryType
                                ? customSettings.categoryType
                                : null
                        }
                        onChange={handleCategoryTypeChange}
                        title={t('Category')}
                    />
                }
            />
        </WidgetSettingModal>
    );
};

export default CategoryChartSetting;
