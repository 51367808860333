import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SOCKET_CONNECTING } from '../util/symbol/window';

import { Socket, SocketInfoState, EventHandlerAction } from './types';

const initialState: SocketInfoState = {
    socket: null,
};

const { actions, reducer } = createSlice({
    name: 'socketInfo',
    initialState,
    reducers: {
        setSocket: (state, action: PayloadAction<Socket>) => {
            state.socket = action.payload;
            window[SOCKET_CONNECTING] = false;
        },
        setEventHandler: (state, action: PayloadAction<EventHandlerAction>) => {
            const { messageType, callback } = action.payload;
            if (state.socket && typeof state.socket.on === 'function' && typeof state.socket.off === 'function') {
                if (typeof callback === 'function') {
                    state.socket.on(messageType, function (data: Object) {
                        callback(data);
                    });
                } else {
                    state.socket.off(messageType);
                }
            }
        },
    },
});

export const { setSocket, setEventHandler } = actions;
export default reducer;
