import React from 'react';
import SearchableSelectWithOption from './Components/SelectWithOption/SearchableSelectWithOption';
import DatePicker from '../../../Common/DatePicker';
import TextInput from '../../../Common/Input/TextInput';

export const TYPE_TO_SELECT = ['checkbox', 'radio', 'select', 'login', 'login_group'];
export const TYPE_TO_DATE = ['date'];
export const TYPE_TO_TEXT = ['text', 'textarea'];
export const TYPE_TO_NULL = ['image'];

export const TYPE_SELECT = 'select';
export const TYPE_DATE = 'date';
export const TYPE_TEXT = 'text';

export const TYPE_TO_COMPONENT_ID = {
    checkbox: TYPE_SELECT,
    radio: TYPE_SELECT,
    select: TYPE_SELECT,
    login: TYPE_SELECT,
    login_group: TYPE_SELECT,
    date: TYPE_DATE,
    text: TYPE_TEXT,
    textarea: TYPE_TEXT,
};

export const TYPE_TO_COMPONENT = {
    checkbox: SearchableSelectWithOption,
    radio: SearchableSelectWithOption,
    select: SearchableSelectWithOption,
    login: SearchableSelectWithOption,
    login_group: SearchableSelectWithOption,
    date: DatePicker,
    text: TextInput,
    textarea: TextInput,
};

// comNum(pin):1
// categoryCode(pin):"normal"
// categoryName(pin):"normal"
// propertyId(pin):"prop"
// displayName(pin):"prop"
// type(pin):null
// inputType(pin):"text"
// inputValues(pin):
// validRule(pin):null
// isRequired(pin):"N"
// isListProperty(pin):"N"
// isSearchable(pin):"N"
// isKey(pin):"N"
// sortOrder(pin):1
// modDate(pin):0
// regDate(pin):1655082409
