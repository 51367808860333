import React from 'react';
import { useHistory } from 'react-router-dom';

import noImage from '@asset/images/image-not-found.svg';

import styled from 'styled-components';

const ManualItem = ({ icon, title, num }: { icon: string; title: string; num: number }) => {
    const history = useHistory();

    const handleItemClick = () => {
        history.push(`/manual/details/${num}`);
    };

    return (
        <ItemContainer onClick={handleItemClick}>
            <ImageContainer>
                {icon ? (
                    <img src={icon} alt={title} width="100%" height="100%" />
                ) : (
                    <img src={noImage} alt={title} style={{ filter: 'invert(0.7)' }} width="40px" height="40px" />
                )}
            </ImageContainer>
            <TextContainer>{title}</TextContainer>
        </ItemContainer>
    );
};

const ItemContainer = styled.div`
    height: fit-content;
    max-width: 240px;
    padding: 0.5rem;
    margin: 0.5rem;
    box-shadow: 0 1px 4px lightgrey;
    :hover {
        cursor: pointer;
        box-shadow: 0 2px 6px lightgrey;
    }

    @media (max-width: 698px) {
        display: flex;
        align-items: center;
        max-width: none;
        gap: 1rem;
        margin: 0.1rem;
    }
`;

const ImageContainer = styled.div`
    height: 160px;
    display: grid;
    align-items: center;
    justify-content: center;

    img {
        height: 160px;
    }
    @media (max-width: 698px) {
        height: 80px;
        img {
            width: 80px;
            height: 80px;
        }
    }
`;

const TextContainer = styled.div`
    text-align: center;
    text-decoration-color: black;
    color: black !important;
    margin-top: 0.4rem;
    font-size: 1rem;

    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    @media (max-width: 698px) {
        font-size: 1.2rem;
    }
`;

export default ManualItem;
