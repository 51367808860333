import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const AvgSection = ({ avgCount, avgTime, avgLeadTime }) => {
    const { t } = useTranslation();
    const AvgValue = styled.span`
        font-weight: bold;
        font-size: 1rem;
    `;
    const AvgTitle = styled.span`
        font-size: 1px;
        color: #d4d4d4;
    `;
    return (
        <div className="d-flex justify-content-between mt-1">
            <div className="d-flex flex-md-column">
                <AvgValue>{avgCount}</AvgValue>
                <AvgTitle className={'exclude-font-theme'}>{t('Common;Count')}</AvgTitle>
                <AvgTitle className={'exclude-font-theme'}>({t('Overview;Avg.')})</AvgTitle>
            </div>
            <div className="d-flex flex-md-column">
                <AvgValue>{avgTime}</AvgValue>
                <AvgTitle className={'exclude-font-theme'}>{t('Common;Time')}</AvgTitle>
                <AvgTitle className={'exclude-font-theme'}>({t('Overview;Avg.')})</AvgTitle>
            </div>
            <div className="d-flex flex-md-column">
                <AvgValue>{avgLeadTime}</AvgValue>
                <AvgTitle className={'exclude-font-theme'}>{t('Overview;Lead Time')}</AvgTitle>
                <AvgTitle className={'exclude-font-theme'}>({t('Overview;Avg.')})</AvgTitle>
            </div>
        </div>
    );
};

export default AvgSection;
