import { reqGet, reqPost, reqPatch, reqDelete } from './index';

export const getDashboardListApi = param =>
    reqGet('/v3/api/common/user/widget/menu', { ...param, isAll: 'Y', pageSize: 0 });
export const getDashboardApi = param => reqGet(`/v3/api/common/user/widget/menu/${param.menuNum}`, param);
export const createDashboardApi = param => reqPost('/v3/api/common/user/widget/menu', param);
export const updateDashboardApi = param => reqPatch(`/v3/api/common/user/widget/menu/${param.menuNum}`, param);
export const deleteDashboardApi = param => reqDelete(`/v3/api/common/user/widget/menu/${param.menuNum}`);

//대상 상태별 이용률 통계 API
export const getUtilizationStatisticsApi = param =>
    reqGet('/v3/api/common/target/status/statistics/utilization', param);

//실시간 대상 이용률 조회 API
export const getRealtimeUtilizationStatisticsApi = param =>
    reqGet('/v3/api/common/target/status/statistics/realtime-utilization', param);

//카테고리별 대상 상태 통계 조회 API
export const getCategoryStatisticApi = param => reqGet('/v3/api/common/target/status/statistics/category', param);

//부서별 대상 상태 통계 조회 API
export const getDepartmentStatisticApi = param => reqGet('/v3/api/common/target/status/statistics/department', param);

export const getStatisticsLogApi = param => reqGet('/v3/api/common/target/status/statistics/log', param);
