import React from 'react';
import { useTranslation } from '@hooks';

import styled from 'styled-components';

const CheckListHeader = ({ note, rules }) => {
    const t = useTranslation('Patrol Log');

    return (
        <div className="d-flex h-100">
            <LeftContainer>
                <div>
                    <StyledHeader>{t('Check Points Of The Duty Commander')}</StyledHeader>
                    <StyledPre>{note}</StyledPre>
                </div>
                <Container>
                    <StyledSpan>{t('Duty Command')}</StyledSpan>
                    <StyledSpan>{`${t('Position')} :`}</StyledSpan>
                    <StyledSpan>{`${t('Rank')} :`}</StyledSpan>
                    <StyledSpan>{`${t('Name')} :`}</StyledSpan>
                    <StyledSpan>{`(${t('Signature')})`}</StyledSpan>
                </Container>
            </LeftContainer>
            <div className="flx-1">
                <StyledHeader>{t('Rules Of Duty For Safety Workers')}</StyledHeader>
                <StyledRule>{rules}</StyledRule>
            </div>
        </div>
    );
};

const LeftContainer = styled.div`
    flex: 1;
    border-left: 1px solid black;
`;

const BorderContainer = styled.div`
    border-bottom: 1px solid black;
    border-right: 1px solid black;
`;

const StyledHeader = styled(BorderContainer)`
    background-color: lightgrey;
    font-size: 0.6rem;
    font-weight: bold;
    text-align: center;
    padding: 4px;
    border-top: 1px solid black;
    color: black;
`;

const StyledPre = styled.pre`
    word-break: break-all;
    white-space: pre-wrap;
    margin-bottom: 0;
    border-bottom: 1px solid black;
    border-right: 1px solid black;
    height: 3.5rem;
    padding: 0.4rem;
    overflow-y: hidden;
    color: black;
    font-size: 0.6rem;
`;

const StyledRule = styled(StyledPre)`
    height: calc(100% - 1.5rem);
`;

const StyledSpan = styled.span`
    font-weight: bold;
    font-size: 0.6rem;
    color: black;
    :last-child {
        font-weight: normal;
    }
`;

const Container = styled(BorderContainer)`
    display: flex;
    justify-content: space-between;
    padding: 0.4rem 1rem 0.4rem 0.4rem;
    margin: 0;
`;

export default CheckListHeader;
