export const CustomNav = [
    {
        id: 'dashboard',
        icon: 'icon-menu-first',
        label: 'Dashboards',
        content: [
            {
                label: 'Dashboard List',
                to: '#/dashboards/list',
            },
        ],
    },
    {
        id: 'monitoring',
        icon: 'icon-menu-fifth',
        label: 'Monitoring',
        content: [
            {
                label: 'Integrated Monitor',
                to: '#/monitoring/integrated',
            },
            {
                label: 'Drowning Monitoring',
                to: '#/monitoring/drowning',
            },
        ],
    },
    {
        icon: 'icon-menu-second',
        id: 'assets',
        label: 'Assets',
        content: [
            {
                label: 'Quick Search',
                to: '#/assets/quickSearch',
            },
        ],
    },
    {
        id: 'filter',
        icon: 'icon-menu-third',
        label: 'Filters',
        content: [
            {
                label: 'Filter List',
                to: '#/filter/list',
            },
        ],
    },
    {
        id: 'assetInspectionManagement',
        icon: 'icon-menu-fifth',
        label: 'Asset Inspection Management',
        content: [
            {
                label: 'Asset Inspection Scheduled',
                to: '#/asset-inspection/schedule',
            },
            {
                label: 'Asset Inspection List',
                to: '#/asset-inspection/list',
            },
        ],
    },
    // {
    //     id: 'assetRentalManagement',
    //     icon: 'icon-menu-fifth',
    //     label: 'Asset Rental Management',
    //     content: [
    //         {
    //             label: 'Asset Rental Request',
    //             to: '#/asset-rental/request',
    //         },
    //         {
    //             label: 'Borrowed Assets',
    //             to: '#/asset-rental/import',
    //         },
    //         {
    //             label: 'Asset Rental Process',
    //             to: '#/asset-rental/process',
    //         },
    //         {
    //             label: 'Asset Lending',
    //             to: '#/asset-rental/export',
    //         },
    //     ],
    // },
    {
        id: 'report',
        icon: 'icon-menu-second',
        label: 'Report',
        content: [
            {
                label: 'Location Log',
                to: '#/report/location-log',
            },
            {
                label: 'Saved Location Log List',
                to: '#/report/location-log/list',
            },
            {
                label: 'Iot Item Information Log',
                to: '#/report/iotItem-log/list',
            },
            {
                label: 'Iot Item Mapping Log',
                to: '#/report/iotItem-mapping-log/list',
            },
            {
                label: 'Login History',
                to: '#/report/login-log/list',
            },
            {
                label: 'Scanner State Log',
                to: '#/report/scanner-log',
            },

            {
                label: 'Sensor Log',
                to: '#/report/Sensor-log',
            },
            {
                label: 'Geofence Log',
                to: '#/report/geofence-log',
            },
            {
                label: 'Location Approval Log',
                to: '#/report/location-approval-log',
            },
            {
                label: 'Patrol Log',
                to: '#/report/patrol-log',
            },
        ],
    },
    {
        id: 'manual',
        icon: 'icon-manual-gray',
        label: 'Technical Manual',
        content: [
            {
                label: 'Technical Manual Search',
                to: '#/manual/search',
            },
        ],
    },
];

export const SettingsNav = [
    {
        id: 'status',
        icon: 'icon-menu-third',
        label: 'Status',
        content: [
            {
                label: 'Status flow Scheme',
                to: '#/status/flow-scheme',
            },
            {
                label: 'Status Scheme',
                to: '#/status/scheme',
            },
            {
                label: '(De)allocate Assets',
                to: '#/status/allocate',
            },
        ],
    },
    {
        id: 'wmsConnection',
        icon: 'icon-menu-fourth',
        label: 'Move to WMS',
        to: '',
    },
];
