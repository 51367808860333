import React from 'react';

import useTranslation from '@hooks/useTranslation';

import DraggableToast from './DraggableToast';

import styled from 'styled-components';

import unknownUser from 'assets/images/unknown_user.png';

const tableList = [
    { label: 'Rank', value: 'rank' },
    { label: 'Department', value: 'department' },
    { label: 'Jobtitle', value: 'jobtitle' },
    { label: 'Absence Status', value: 'status' },
    { label: 'LTE Number', value: 'lte' },
    { label: 'Normal Number', value: 'telephone' },
];

const PersonnelToast = ({ innerRef, bounds, initModal = false, toggleModal, data = {} }) => {
    const { targetName, rank, picture } = data;

    const t = useTranslation('Integrated Monitor');

    return (
        <DraggableToast
            bounds={bounds}
            initModal={initModal}
            toggleModal={() => toggleModal(null)}
            headerClassName="personnel-toast-header"
            bodyClassName="personnel-toast-body"
            innerRef={innerRef}
            title={`People: ${rank || ''} ${targetName || ''}`}
        >
            <TableContainer>
                {tableList.map(({ label, value }) => {
                    return (
                        <RowContainer key={value}>
                            <LabelContainer className="bg-lightgray">{t(label)}</LabelContainer>
                            <ColContainer title={data[value]}>{data[value]}</ColContainer>
                        </RowContainer>
                    );
                })}
                <RowContainer>
                    <LabelContainer className="bg-lightgray">{t('Image')}</LabelContainer>
                    <ColContainer>
                        <img alt="asset-image" src={picture || unknownUser} width="100%" height="100%" />
                    </ColContainer>
                </RowContainer>
            </TableContainer>
        </DraggableToast>
    );
};

export default PersonnelToast;

const TableContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0.5rem;
`;

const RowContainer = styled.div`
    border-bottom: 1px solid;
    display: flex;
    flex-direction: row;
    flex: 1;
    &:first-child {
        border-top: 1px solid;
    }
`;

const ColContainer = styled.div`
    border-left: 1px solid;
    padding: 0.5rem;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 60%;
    &:last-child {
        border-right: 1px solid;
    }
`;

const LabelContainer = styled(ColContainer)`
    width: 40%;
`;
