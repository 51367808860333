import React, { useContext, useEffect, useRef, useState, useMemo } from 'react';
import moment from 'moment';

import { useTranslation } from '@hooks';

import { PatrolDutyStateContext } from '../index';

const PatrolTime = () => {
    const { patrolStatus, patrolInfo } = useContext(PatrolDutyStateContext);
    const t = useTranslation('Patrol On Duty');

    const progressIntervalRef = useRef(null);
    const [progressTime, setProgressTime] = useState(0);

    useEffect(() => {
        if (!!progressIntervalRef.current || patrolStatus === 'N') {
            clearInterval(progressIntervalRef.current);
            progressIntervalRef.current = null;
            setProgressTime(0);
        }

        if (patrolStatus === 'Y') {
            progressIntervalRef.current = setInterval(() => {
                const { patrolStartDate, patrolEndDate } = patrolInfo;
                if (patrolStartDate && !patrolEndDate) {
                    const startUnixTime = moment(patrolStartDate, 'yyyy.MM.DD HH:mm:ss').unix();
                    const currentUnixTime = moment().unix();
                    setProgressTime(currentUnixTime - startUnixTime);
                }
            }, 1000);
        }

        return () => {
            clearInterval(progressIntervalRef.current);
            progressIntervalRef.current = null;
        };
    }, [patrolInfo.patrolStartDate, patrolStatus]);

    const patrolProgressTime = useMemo(() => {
        return patrolStatus === 'N'
            ? t('Patrol is not in progress.')
            : progressTime + t('Seconds', 'State Conversion') + t('Pass', 'State Conversion');
    }, [patrolStatus, progressTime, t]);

    return <div className="text-ellipsis">{t('Patrol Progress Time') + ' : ' + patrolProgressTime}</div>;
};

export default PatrolTime;
