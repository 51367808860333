import React from 'react';
import { ErrorHandleSwitch } from '../Components/Router';
import { Route } from 'react-router-dom';
import AssetImport from './AssetImport';
import AssetRentalRequest from './AssetRentalRequest';
import AssetRentalProcess from './AssetRentalProcess';
import AssetExport from './AssetExport';
import AssetRentalRequestApplication from './AssetRentalRequest/AssetRentalRequestApplication';
import AssetRentalRequestView from './AssetRentalRequest/AssetRentalRequestView';
import AssetRentalProcessView from './AssetRentalProcess/AssetRentalProcessView';
import useAsync from '@hooks/useAsync';
import { fetchCategory } from 'api/asset';
import { setAllCategoryList } from 'reducers/Common/CategoryInfo';
import { useDispatch, useSelector } from 'react-redux';

const AssetRentalManagement = ({ match }) => {
    const dispatch = useDispatch();
    const { serviceCategory } = useSelector(state => state.CategoryInfo);

    useAsync({
        promise: fetchCategory,
        param: { isAll: 'Y', parentCode: serviceCategory },
        resolve: ({ rows }) => {
            dispatch(setAllCategoryList(rows));
        },
        immediate: true,
    });

    return (
        <ErrorHandleSwitch>
            <Route exact path={`${match.path}/request`} component={AssetRentalRequest} />
            <Route exact path={`${match.path}/request/application`} component={AssetRentalRequestApplication} />
            <Route exact path={`${match.path}/request/:reqNum([0-9]+)`} component={AssetRentalRequestView} />
            <Route exact path={`${match.path}/import`} component={AssetImport} />
            <Route exact path={`${match.path}/process`} component={AssetRentalProcess} />
            <Route exact path={`${match.path}/process/:reqNum([0-9]+)`} component={AssetRentalProcessView} />
            <Route exact path={`${match.path}/export`} component={AssetExport} />
        </ErrorHandleSwitch>
    );
};

export default AssetRentalManagement;
