import React, { useEffect, useMemo, useState } from 'react';

import useTranslation from '@hooks/useTranslation';
import { Button, TextInput, Select as ReactSelect } from '@components';

import { InputGroup, InputWrap, SelectGroup, SearchWrap } from '@components/FilterSearchGroup/Components/Part';
import FilterSearchGroup from '@components/FilterSearchGroup';
import SearchGroup from '@components/FilterSearchGroup/Components/SearchGroup';
import SearchButtonArea from '@components/FilterSearchGroup/Components/SearchButtonArea';

const Search = ({ getIotItemMappingLog }) => {
    const t = useTranslation('Iot Item Mapping Log');

    const searchOptions = useMemo(() => {
        return [
            { value: '', label: t('Search Option', 'Search') },
            { value: 'targetNum', label: t('IoT Item No.', 'CommonColumn') },
            { value: 'targetId', label: t('IoT Item ID', 'CommonColumn') },
            { value: 'targetName', label: t('IoT Item Name', 'CommonColumn') },
        ];
    }, [t]);

    const mappingOptions = useMemo(() => {
        return [
            { value: '', label: t('Mapping/Unmapping') },
            { value: 'INSERT', label: t('INSERT') },
            { value: 'DELETE', label: t('DELETE') },
        ];
    }, [t]);

    const [selectedOption, setSelectedOption] = useState(searchOptions[0]);
    const [manipulationType, setManipulationType] = useState(mappingOptions[0]);
    const [searchInput, setSearchInput] = useState('');
    const [searchInputDisabled, setSearchInputDisabled] = useState(true);

    useEffect(() => {
        if (selectedOption) {
            setSelectedOption(searchOptions.find(v => v.value === selectedOption.value));
        }
    }, [searchOptions]);

    useEffect(() => {
        if (manipulationType) {
            setManipulationType(mappingOptions.find(v => v.value === selectedOption.value));
        }
    }, [mappingOptions]);

    const handleSelectValueChange = value => {
        setSelectedOption(value);
        if (value.value !== '') {
            setSearchInputDisabled(false);
        } else {
            setSearchInputDisabled(true);
            setSearchInput('');
        }
    };

    const handleRefreshClick = e => {
        setSelectedOption(searchOptions[0]);
        setManipulationType(mappingOptions[0]);

        setSearchInput('');

        setSearchInputDisabled(true);
        getIotItemMappingLog();
    };

    const handleSearchClick = () => {
        if (searchInput) {
            getIotItemMappingLog({
                manipulationType: manipulationType.value,
                opt: selectedOption.value,
                keyword: searchInput,
            });
        } else {
            getIotItemMappingLog({
                manipulationType: manipulationType.value,
            });
        }
    };

    const handleInputChange = e => {
        const { value } = e.target;
        setSearchInput(value);
    };

    return (
        <FilterSearchGroup>
            <SearchGroup className="bg-brand" label={t('Search', 'Search')}>
                <div style={{ minWidth: '9rem' }}>
                    <ReactSelect
                        name="manipulationType"
                        value={manipulationType}
                        options={mappingOptions}
                        onChange={selected => setManipulationType(selected)}
                        customControlStyles={{ width: '100%' }}
                        customMenuStyles={{ width: '100%' }}
                        customOptionStyles={{ width: '100%' }}
                    />
                </div>
                <SearchWrap>
                    <SelectGroup>
                        <ReactSelect
                            name="selectInput"
                            value={selectedOption}
                            options={searchOptions}
                            onChange={handleSelectValueChange}
                            customControlStyles={{ width: '100%' }}
                            customMenuStyles={{ width: '100%' }}
                            customOptionStyles={{ width: '100%' }}
                        />
                    </SelectGroup>
                    <InputWrap>
                        <InputGroup>
                            <TextInput
                                style={{ borderRadius: '5px' }}
                                type="text"
                                size={60}
                                placeholder={t(
                                    'Please Enter your search keyword after selecting search option.',
                                    'Search',
                                )}
                                name="name"
                                value={searchInput}
                                handleChange={handleInputChange}
                                disabled={searchInputDisabled}
                            />
                        </InputGroup>
                    </InputWrap>
                </SearchWrap>
                <SearchButtonArea>
                    <Button className="btn-brand btn-icon" iconName="search" onClick={handleSearchClick}>
                        {t('Search', 'Search')}
                    </Button>
                    <Button className="btn-danger btn-icon-only" iconName="replay" onClick={handleRefreshClick} />
                </SearchButtonArea>
            </SearchGroup>
        </FilterSearchGroup>
    );
};

export default Search;
