import React from 'react';
import { Route } from 'react-router-dom';
import { ErrorHandleSwitch } from '../Components/Router';
import MenuAccessRights from './MenuAccessRights';

const Settings = ({ match }) => {
    return (
        <ErrorHandleSwitch>
            <Route exact path={`${match.path}/menu`} component={MenuAccessRights} />
        </ErrorHandleSwitch>
    );
};

export default Settings;
