import React, { useContext, useMemo, useState } from 'react';

import useTranslation from '@hooks/useTranslation';
import { TextInput, Button, Select as ReactSelect } from 'Components/Common';

import { useSelector } from 'react-redux';
import { TriggerDispatchContext, TriggerStateContext } from './index';
import {
    addTrigger,
    deleteTrigger,
    TRIGGER_SUB_TYPE_FLOOR,
    TRIGGER_SUB_TYPE_GEOFENCE,
    TRIGGER_TYPE_LOCATION,
    TRIGGER_VALUE_TYPE_IN,
    TRIGGER_VALUE_TYPE_OUT,
} from './triggerReducer';

const LocationInputGroup = ({ groupKey }) => {
    const t = useTranslation('Status');
    const { floorList } = useSelector(state => state.FloorInfo);
    const dispatch = useContext(TriggerDispatchContext);
    const { triggerRelationJson, geofenceList } = useContext(TriggerStateContext);
    const { triggers } = triggerRelationJson.data[groupKey] ? triggerRelationJson.data[groupKey] : { triggers: [] };

    const [inAndOutSelected, setInAndOutSelected] = useState({ value: TRIGGER_VALUE_TYPE_IN, label: t('In') });
    const [locationTypeSelected, setLocationTypeSelected] = useState({
        value: TRIGGER_SUB_TYPE_FLOOR,
        label: t('Floor'),
    });

    const [selectedGeofence, setSelectedGeofence] = useState(null);
    const [selectedFloor, setSelectedFloor] = useState(null);
    const [focusedSelect, setFocusedSelect] = useState(false);

    const inAndOutOptions = useMemo(() => {
        return [
            { value: TRIGGER_VALUE_TYPE_IN, label: t('In') },
            { value: TRIGGER_VALUE_TYPE_OUT, label: t('Out') },
        ];
    }, [t]);

    const locationTypeOptions = useMemo(() => {
        return [
            { value: TRIGGER_SUB_TYPE_FLOOR, label: t('Floor') },
            { value: TRIGGER_SUB_TYPE_GEOFENCE, label: t('Geofence') },
        ];
    }, [t]);

    const handleAddManualClick = type => {
        if (type === TRIGGER_SUB_TYPE_GEOFENCE) {
            if (selectedGeofence) {
                dispatch(
                    addTrigger({
                        groupKey,
                        triggerType: TRIGGER_TYPE_LOCATION,
                        triggerSubType: locationTypeSelected.value,
                        triggerValue: { type: inAndOutSelected.value, value: selectedGeofence.fcNum },
                    }),
                );
                setFocusedSelect(false);
                setSelectedGeofence(null);
            } else {
                setFocusedSelect(true);
            }
        } else if (type === TRIGGER_SUB_TYPE_FLOOR) {
            if (selectedFloor) {
                dispatch(
                    addTrigger({
                        groupKey,
                        triggerType: TRIGGER_TYPE_LOCATION,
                        triggerSubType: locationTypeSelected.value,
                        triggerValue: { type: inAndOutSelected.value, value: selectedFloor.floorId },
                    }),
                );
                setFocusedSelect(false);
                setSelectedFloor(null);
            } else {
                setFocusedSelect(true);
            }
        }
    };

    return (
        <div>
            <div className="location-input-group">
                <ReactSelect
                    name="inAndOut"
                    value={inAndOutSelected}
                    options={inAndOutOptions}
                    onChange={selected => setInAndOutSelected(selected)}
                    className="w-100"
                    customControlStyles={{ width: '100%' }}
                    customMenuStyles={{ width: '100%' }}
                    customOptionStyles={{ width: '100%' }}
                />
                <ReactSelect
                    name="locationType"
                    value={locationTypeSelected}
                    options={locationTypeOptions}
                    onChange={selected => setLocationTypeSelected(selected)}
                    className="w-100"
                    customControlStyles={{ width: '100%' }}
                    customMenuStyles={{ width: '100%' }}
                    customOptionStyles={{ width: '100%' }}
                />

                {locationTypeSelected.value === TRIGGER_SUB_TYPE_GEOFENCE ? (
                    <ReactSelect
                        name={'geofenceSelect'}
                        value={selectedGeofence}
                        options={geofenceList.filter(geofence => {
                            return !triggers.find(trigger => {
                                return (
                                    trigger.triggerSubType === TRIGGER_SUB_TYPE_GEOFENCE &&
                                    trigger.triggerValue.value === geofence.value
                                );
                            });
                        })}
                        onChange={selected => setSelectedGeofence(selected)}
                        disabled={!geofenceList.length}
                        className="w-100"
                        customControlStyles={
                            focusedSelect ? { border: '2px solid #FF7800', width: '100%' } : { width: '100%' }
                        }
                        customOptionStyles={{ width: '100%' }}
                        customMenuStyles={{ width: '100%' }}
                    />
                ) : (
                    <ReactSelect
                        name="floorSelect"
                        value={selectedFloor}
                        options={floorList}
                        onChange={setSelectedFloor}
                        valueKey="floorId"
                        labelKey="floorName"
                        disabled={!floorList.length}
                        className="w-100"
                        customControlStyles={
                            focusedSelect ? { border: '2px solid #FF7800', width: '100%' } : { width: '100%' }
                        }
                        customOptionStyles={{ width: '100%' }}
                        customMenuStyles={{ width: '100%' }}
                    />
                )}
                <Button
                    className="btn-secondary btn-icon-only"
                    iconName="add"
                    onClick={() => {
                        if (locationTypeSelected.value === TRIGGER_SUB_TYPE_GEOFENCE) {
                            handleAddManualClick(TRIGGER_SUB_TYPE_GEOFENCE);
                        } else {
                            handleAddManualClick(TRIGGER_SUB_TYPE_FLOOR);
                        }
                    }}
                />
            </div>
            {triggers.map(trigger => {
                const { triggerKey, triggerValue, triggerSubType, groupKey } = trigger;
                let target;
                if (triggerSubType === TRIGGER_SUB_TYPE_GEOFENCE) {
                    const geofenceInfo = geofenceList.find(v => v.fcNum === triggerValue.value);
                    target = geofenceInfo ? geofenceInfo.fcName : '';
                } else {
                    const floorInfo = floorList.find(v => v.floorId === triggerValue.value);
                    target = floorInfo ? floorInfo.floorName : '';
                }
                return (
                    <div className="location-input-group" key={triggerKey}>
                        <TextInput
                            inputGroupClassName="width-150px"
                            type="text"
                            name="manual"
                            value={inAndOutOptions.find(v => v.value === triggerValue.type).label}
                            disabled
                        />
                        <TextInput
                            inputGroupClassName="width-150px"
                            type="text"
                            name="type"
                            value={locationTypeOptions.find(v => v.value === triggerSubType).label}
                            disabled
                        />
                        <TextInput
                            inputGroupClassName="width-220px"
                            type="text"
                            name="target"
                            value={target}
                            disabled
                        />
                        <Button
                            iconClassName="icon-close"
                            iconName="icon-close"
                            className="btn-danger btn-icon-only"
                            onClick={() => {
                                dispatch(deleteTrigger({ groupKey, triggerKey }));
                            }}
                        />
                    </div>
                );
            })}
        </div>
    );
};

export default LocationInputGroup;
