import React, { useState } from 'react';
import moment from 'moment';

import FilterSearchGroup from '@components/FilterSearchGroup';
import { InputGroup, InputWrap, SelectGroup, SearchWrap } from '@components/FilterSearchGroup/Components/Part';
import { TextInput, Button, DatePicker, Select as ReactSelect } from '@components';
import SearchGroup from '@components/FilterSearchGroup/Components/SearchGroup';
import SearchButtonArea from '@components/FilterSearchGroup/Components/SearchButtonArea';

import useTranslation from '@hooks/useTranslation';

const Search = ({ getLoginLogList }) => {
    const t = useTranslation('Login History Log');

    const typeOptions = [
        { value: '', label: t('Log Type') },
        { value: 'F', label: t('Failure') },
        { value: 'S', label: t('Success') },
    ];

    const searchOptions = [
        { value: '', label: t('Search Option', 'Search') },
        { value: 'ip', label: t('IP') },
        { value: 'regDate', label: t('Date') },
    ];

    const [selectedTypeOption, setSelectedTypeOption] = useState(typeOptions[0]);
    const [searchInput, setSearchInput] = useState('');
    const [searchInputDisabled, setSearchInputDisabled] = useState(true);
    const [selectedOption, setSelectedOption] = useState(searchOptions[0]);
    const [date, setDate] = useState(moment().startOf('day').valueOf());

    const handleRefreshClick = () => {
        setSelectedTypeOption(typeOptions[0]);
        setSelectedOption(searchOptions[0]);

        setSearchInput('');
        setDate(moment().startOf('day').valueOf());

        setSearchInputDisabled(true);
        getLoginLogList();
    };

    const handleSearchClick = () => {
        if (selectedOption) {
            getLoginLogList({
                type: selectedTypeOption.value,
                opt: selectedOption.value,
                keyword: selectedOption && selectedOption.value === 'ip' ? searchInput : moment(date).unix(),
            });
        } else {
            getLoginLogList({
                type: selectedTypeOption.value,
            });
        }
    };

    const handleSelectValueChange = value => {
        setSelectedOption(value);
        if (value.value !== '') {
            setSearchInputDisabled(false);
        } else {
            setSearchInputDisabled(true);
            setSearchInput('');
        }
    };

    const handleInputChange = e => {
        const { value } = e.target;
        setSearchInput(value);
    };

    return (
        <FilterSearchGroup>
            <SearchGroup className={'bg-brand'} label={t('Search', 'Search')}>
                <div style={{ minWidth: '9rem' }}>
                    <ReactSelect
                        name="manipulationType"
                        placeholder={t('Log Type')}
                        value={selectedTypeOption}
                        options={typeOptions}
                        onChange={selected => setSelectedTypeOption(selected)}
                        className="w-100"
                        customControlStyles={{ width: '100%' }}
                        customMenuStyles={{ width: '100%' }}
                        customOptionStyles={{ width: '100%' }}
                    />
                </div>
                <SearchWrap>
                    <SelectGroup>
                        <ReactSelect
                            name="selectInput"
                            placeholder={t('Search Option', 'Search')}
                            value={selectedOption}
                            options={searchOptions}
                            onChange={handleSelectValueChange}
                            customControlStyles={{ width: '100%' }}
                            customMenuStyles={{ width: '100%' }}
                            customOptionStyles={{ width: '100%' }}
                        />
                    </SelectGroup>
                    <InputWrap>
                        <InputGroup>
                            {!selectedOption || selectedOption.value === 'regDate' ? (
                                <div className={'datePicker-container child-label-w-100'}>
                                    <DatePicker
                                        value={date}
                                        handleChange={selected => setDate(selected)}
                                        valueType="ms"
                                        maxDate={moment().valueOf()}
                                        withoutTime
                                    />
                                </div>
                            ) : (
                                <TextInput
                                    style={{ borderRadius: '5px' }}
                                    type={'text'}
                                    size={60}
                                    placeholder={t(
                                        'Please Enter your search keyword after selecting search option.',
                                        'Search',
                                    )}
                                    name="name"
                                    value={searchInput}
                                    handleChange={handleInputChange}
                                    disabled={searchInputDisabled}
                                />
                            )}
                        </InputGroup>
                    </InputWrap>
                </SearchWrap>
                <SearchButtonArea>
                    <Button className="btn-brand btn-icon" iconName="search" onClick={handleSearchClick}>
                        {t('Search', 'Search')}
                    </Button>
                    <Button className="btn-danger btn-icon-only" iconName="replay" onClick={handleRefreshClick} />
                </SearchButtonArea>
            </SearchGroup>
        </FilterSearchGroup>
    );
};

export default Search;
