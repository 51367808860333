import React, { useState, useEffect, useContext } from 'react';

import { getStatusCategoryInfoApi } from 'api/status';

import { useTranslation, useAsync } from '@hooks';

import SearchableSelectWithOption from '../Components/SelectWithOption/SearchableSelectWithOption';
import { FilterGeneratorContext } from '../FilterGenerator';

const AssetStatusSelect = ({ selected, isEditable, handleChange, className }) => {
    const t = useTranslation('Filter');

    const { filterEditMode } = useContext(FilterGeneratorContext);

    const [assetStatusList, setAssetStatusList] = useState([]);
    const [selectedOption, setSelectedOption] = useState(selected);

    useAsync({
        promise: getStatusCategoryInfoApi,
        immediate: true,
        fixedParam: { isAll: 'Y' },
        resolve: res => {
            setAssetStatusList(
                res.rows.map(v => {
                    return { ...v, statusCategoryNums: v.statusCategoryNum.toString() };
                }),
            );
        },
    });

    const handleSelectedOptionChange = (selected, isEditable) => {
        handleChange(selected, isEditable);
        setSelectedOption(selected);
    };

    useEffect(() => {
        if (assetStatusList.length && selected && selected.length) {
            setSelectedOption(selected);
        } else {
            setSelectedOption([]);
        }
    }, [selected, assetStatusList]);

    return (
        <SearchableSelectWithOption
            data={assetStatusList}
            onChange={handleSelectedOptionChange}
            title={t('Asset Status')}
            selected={selectedOption}
            labelKey={'statusCategoryName'}
            valueKey={'statusCategoryNums'}
            className={className}
            isEditable={isEditable}
            disabled={!filterEditMode}
        />
    );
};

export default AssetStatusSelect;
