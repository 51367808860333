import React, { useState, useEffect, useContext, useMemo } from 'react';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';

import { Select, TextInput, Button } from 'Components/Common';
import { useAsync, useConfirmModal, useTranslation } from '@hooks';
import { createTransitionInfoApi, getTransitionListInfoApi, updateTransitionInfoApi } from 'api/status';

import { useDispatch, useSelector } from 'react-redux';
import { setTransitionList } from 'reducers/Common/StatusInfo';
import { FlowSchemeStateContext } from '../index';

import DetailModalHeader from '../../../Components/DetailModalHeader';
import DetailLabel from '../../../Components/DetailLabel';
import ColorPicker from '../../../Components/ColorPicker';

import TransitionTriggerModal from './TransitionTriggerModal';

const INPUT_WIDTH = 330;

const initStatusDetailInfo = {
    actionName: '',
    actionId: '',
    description: '',
    displayColor: '#000000',
    startStatusNum: null,
    endStatusNum: null,
};

const TransitionDetail = ({ isOpen, toggle, transitionInfo }) => {
    const dispatch = useDispatch();
    const t = useTranslation('Status');
    const buttonT = useTranslation('Button');
    const { flowSchemeInfo, flowStatusList, flowTransitionList } = useContext(FlowSchemeStateContext);
    const { serviceCode } = useSelector(state => state.AppConfig);
    const [transitionDetailInfo, setTransitionDetailInfo] = useState(initStatusDetailInfo);

    const startStatusOptionList = useMemo(() => {
        const excludeStatus = flowTransitionList.reduce((acc, transition) => {
            if (transition.endStatusNum === transitionDetailInfo.endStatusNum) {
                acc.push(transition.startStatusNum);
            }
            return acc;
        }, []);
        return flowStatusList.reduce((acc, curr) => {
            if (
                (curr.statusNum !== transitionDetailInfo.endStatusNum && !excludeStatus.includes(curr.statusNum)) ||
                (transitionInfo && transitionInfo.startStatusNum === curr.statusNum)
            ) {
                acc.push({ value: curr.statusNum, label: curr.statusName });
            }

            return acc;
        }, []);
    }, [flowStatusList, flowTransitionList, transitionInfo, transitionDetailInfo]);

    const endStatusOptionList = useMemo(() => {
        const excludeStatus = flowTransitionList.reduce((acc, transition) => {
            if (transition.startStatusNum === transitionDetailInfo.startStatusNum) {
                acc.push(transition.endStatusNum);
            }
            return acc;
        }, []);
        return flowStatusList.reduce((acc, curr) => {
            if (
                (curr.statusNum !== transitionDetailInfo.startStatusNum && !excludeStatus.includes(curr.statusNum)) ||
                (transitionInfo && transitionInfo.endStatusNum === curr.statusNum)
            ) {
                acc.push({ value: curr.statusNum, label: curr.statusName });
            }

            return acc;
        }, []);
    }, [flowStatusList, flowTransitionList, transitionInfo, transitionDetailInfo]);

    const [triggerModal, setTriggerModal] = useState({ isOpen: false, transitionInfo: null });
    const toggleTriggerModal = transitionInfo => {
        setTriggerModal({
            isOpen: !triggerModal.isOpen,
            transitionInfo,
        });
    };

    const { promise: getTransitionListInfo } = useAsync({
        promise: getTransitionListInfoApi,
        fixedParam: { isAll: 'Y' },
        resolve: res => {
            dispatch(setTransitionList(res));
        },
    });

    useEffect(() => {
        if (transitionInfo && transitionInfo.actionNum) {
            setTransitionDetailInfo(transitionInfo);
        } else {
            setTransitionDetailInfo(initStatusDetailInfo);
        }
    }, [transitionInfo]);

    // actionId 같아서 실패할 경우 처리 필요함
    const { promise: createTransitionInfo } = useAsync({
        promise: createTransitionInfoApi,
        resolve: res => {
            toggleSaveCompleteModal();
        },
        reject: err => {
            console.log({ err });
            if (err.data) {
                const { code, errorResponse } = err.data;
                if (code === '1101' && errorResponse.includes('actionId')) {
                    toggleDuplicatedIdModal();
                }
            }
        },
    });
    const { promise: updateTransitionInfo } = useAsync({
        promise: updateTransitionInfoApi,
        resolve: res => {
            toggleSaveCompleteModal();
        },
        reject: err => {
            console.log({ err });
        },
    });

    const { toggleModal: toggleSaveConfirmModal, Modal: SaveConfirmModal } = useConfirmModal({
        initModal: false,
        confirmText: t('Do you want to save?'),
        okCallback: () => {
            const { statusCategoryName, actionNum, comNum, modDate, modUserNum, regDate, regUserNum, ...detailInfo } =
                transitionDetailInfo;
            if (actionNum) {
                updateTransitionInfo({
                    ...detailInfo,
                    actionNum,
                    flowNum: flowSchemeInfo.flowNum,
                    serviceCode,
                });
            } else {
                createTransitionInfo({
                    ...detailInfo,
                    flowNum: flowSchemeInfo.flowNum,
                    serviceCode,
                });
            }
        },
    });
    const { toggleModal: toggleSaveCompleteModal, Modal: SaveCompleteModal } = useConfirmModal({
        initModal: false,
        removeCancel: true,
        confirmText: t('Save is complete.'),
        okCallback: () => {
            toggle();
            getTransitionListInfo();
        },
    });
    const { toggleModal: toggleRequestRequiredModal, Modal: RequestRequiredModal } = useConfirmModal({
        initModal: false,
        removeCancel: true,
        confirmText: t('Please fill in the required fields. ( Display Name, ID )'),
    });
    const { toggleModal: toggleDuplicatedIdModal, Modal: DuplicatedIdModal } = useConfirmModal({
        initModal: false,
        removeCancel: true,
        confirmText: t('The entered ID is already in use. Please enter a different ID.'),
    });

    const handleChange = e => {
        const { value, name } = e.currentTarget;
        setTransitionDetailInfo({ ...transitionDetailInfo, [name]: value });
    };

    return (
        <>
            <Modal
                container={document.getElementsByClassName('app-container')[0]}
                className={'pnt-modal'}
                isOpen={isOpen}
                toggle={toggle}
            >
                <DetailModalHeader
                    title={`${t('Transition')} - ${transitionInfo ? t('Edit') : t('Add')}`}
                    subTitle={
                        transitionInfo
                            ? t('This is a function to change the status transition.')
                            : t('This is a function to add the status transition.')
                    }
                />
                <ModalBody style={{ display: 'grid', rowGap: '10px' }}>
                    <DetailLabel
                        required
                        name={t('Display name')}
                        value={
                            <TextInput
                                style={{ width: INPUT_WIDTH }}
                                inputGroupClassName="w-auto form-must"
                                type="text"
                                maxlength={50}
                                name="actionName"
                                handleChange={handleChange}
                                value={transitionDetailInfo.actionName}
                            />
                        }
                    />
                    <DetailLabel
                        required
                        name={t('ID')}
                        value={
                            <TextInput
                                type={'text'}
                                inputGroupClassName={'w-auto form-must'}
                                maxlength={50}
                                disabled={transitionInfo}
                                className={transitionInfo ? 'disabled' : ''}
                                style={
                                    transitionInfo
                                        ? { backgroundColor: '#e3e3e3', width: INPUT_WIDTH }
                                        : { width: INPUT_WIDTH }
                                }
                                name={'actionId'}
                                handleChange={handleChange}
                                value={transitionDetailInfo.actionId}
                            />
                        }
                    />
                    <DetailLabel
                        required
                        name={t('From → to')}
                        value={
                            <div style={{ display: 'grid', gridTemplateColumns: '150px 30px 150px' }}>
                                <Select
                                    value={flowStatusList.reduce((acc, curr) => {
                                        if (curr.statusNum === transitionDetailInfo.startStatusNum) {
                                            acc = { value: curr.statusNum, label: curr.statusName };
                                        }
                                        return acc;
                                    }, {})}
                                    name={'startStatusNum'}
                                    className="form-must w-100"
                                    options={startStatusOptionList}
                                    onChange={selected => {
                                        if (selected.value === transitionDetailInfo.endStatusNum) {
                                            setTransitionDetailInfo({
                                                ...transitionDetailInfo,
                                                startStatusNum: selected.value,
                                                endStatusNum: null,
                                            });
                                        } else {
                                            setTransitionDetailInfo({
                                                ...transitionDetailInfo,
                                                startStatusNum: selected.value,
                                            });
                                        }
                                    }}
                                    customControlStyles={{ width: '100%' }}
                                    customMenuStyles={{ width: '100%' }}
                                    customOptionStyles={{ width: '100%' }}
                                />
                                <div className="flex-center">
                                    <span className="material-icons-round">arrow_forward</span>
                                </div>
                                <Select
                                    value={flowStatusList.reduce((acc, curr) => {
                                        if (curr.statusNum === transitionDetailInfo.endStatusNum) {
                                            acc = { value: curr.statusNum, label: curr.statusName };
                                        }
                                        return acc;
                                    }, {})}
                                    name="endStatusNum"
                                    className="form-must w-100"
                                    options={endStatusOptionList}
                                    onChange={selected => {
                                        setTransitionDetailInfo({
                                            ...transitionDetailInfo,
                                            endStatusNum: selected.value,
                                        });
                                    }}
                                    customControlStyles={{ width: '100%' }}
                                    customMenuStyles={{ width: '100%' }}
                                    customOptionStyles={{ width: '100%' }}
                                />
                            </div>
                        }
                    />
                    <div className={'pnt-border border-w mb-2 mt-2'} />
                    <DetailLabel
                        name={t('Description')}
                        value={
                            <TextInput
                                style={{ width: INPUT_WIDTH }}
                                inputGroupClassName={'w-auto'}
                                type={'text'}
                                maxlength={200}
                                name={'description'}
                                handleChange={handleChange}
                                value={transitionDetailInfo.description}
                            />
                        }
                    />
                    <DetailLabel
                        name={t('Display Color')}
                        value={
                            <ColorPicker
                                inputStyle={{ width: INPUT_WIDTH }}
                                inputGroupClassName={'w-auto'}
                                name={'displayColor'}
                                selected={transitionDetailInfo.displayColor}
                                onChange={selected => {
                                    handleChange({ currentTarget: { value: selected, name: 'displayColor' } });
                                }}
                            />
                        }
                    />
                </ModalBody>
                <ModalFooter>
                    <Button className="color-brand" onClick={toggle}>
                        {buttonT('Cancel')}
                    </Button>
                    {transitionInfo && (
                        <Button
                            className="btn-secondary"
                            onClick={() => {
                                toggleTriggerModal(transitionInfo);
                            }}
                        >
                            {t('Trigger Setup')}
                        </Button>
                    )}
                    <Button
                        className="btn-brand flex-center gap-1"
                        iconName="check"
                        onClick={() => {
                            if (
                                transitionDetailInfo.actionName &&
                                transitionDetailInfo.actionId &&
                                transitionDetailInfo.startStatusNum &&
                                transitionDetailInfo.endStatusNum
                            ) {
                                toggleSaveConfirmModal();
                            } else {
                                toggleRequestRequiredModal();
                            }
                        }}
                    >
                        {buttonT('Ok')}
                    </Button>
                </ModalFooter>
            </Modal>
            <SaveConfirmModal />
            <SaveCompleteModal />
            <RequestRequiredModal />
            <DuplicatedIdModal />
            <TransitionTriggerModal
                isOpen={triggerModal.isOpen}
                selectedTransition={triggerModal.transitionInfo}
                toggle={toggleTriggerModal}
            />
        </>
    );
};

export default TransitionDetail;
