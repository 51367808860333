import React, { useMemo, useState } from 'react';

import { useTranslation } from '@hooks';

import { TimePicker, Select as ReactSelect, Button } from '@components';

import FilterSearchGroup from '@components/FilterSearchGroup';
import FilterGroup from '@components/FilterSearchGroup/Components/FilterGroup';
import { SelectGroup, FilterList } from '@components/FilterSearchGroup/Components/Part';
import FilterButtonArea from '@components/FilterSearchGroup/Components/FilterButtonArea';

import moment from 'moment';

interface SearchProps {
    startDate: number;
    endDate: number;
    handleDateCallback: Function;
    getLocationApprovalLog: Function;
}

const SELECT_OPTIONS = [
    { value: 'All', label: 'All' },
    { value: 'approve', label: 'Approval' },
    { value: 'refuse', label: 'Refusal' },
    { value: 'quit', label: 'Finish' },
];

const Search = ({ startDate, endDate, handleDateCallback, getLocationApprovalLog }: SearchProps) => {
    const t = useTranslation('Location Approval Log');

    const handleSearchClick = () => {
        getLocationApprovalLog({ opt: selectedOption.value });
    };

    const translatedSelectOptions = useMemo(() => {
        return SELECT_OPTIONS.map(({ value, label }) => ({ value, label: t(label) }));
    }, [t]);
    const [selectedOption, setSelectedOption] = useState(translatedSelectOptions[0]);

    return (
        <FilterSearchGroup className="">
            <FilterGroup className="bg-brand" label={t('Search', 'Search')}>
                <FilterList className="">
                    <ReactSelect
                        name="selectOptions"
                        value={selectedOption}
                        options={translatedSelectOptions}
                        // @ts-ignore
                        onChange={setSelectedOption}
                        customControlStyles={{ width: '100%' }}
                        customMenuStyles={{ width: '100%' }}
                        customOptionStyles={{ width: '100%' }}
                    />
                    <SelectGroup className="">
                        <div className="datePicker-container">
                            {/*@ts-ignore*/}
                            <TimePicker
                                value={startDate}
                                handleChange={(selected: number) => handleDateCallback({ type: 'startDate', selected })}
                                valueType="ms"
                                maxDate={endDate || moment.now()}
                                showTimeInput
                            />
                        </div>
                    </SelectGroup>
                    <div className="text-center">~</div>
                    <SelectGroup className="">
                        <div className="datePicker-container">
                            {/*@ts-ignore*/}
                            <TimePicker
                                value={endDate}
                                handleChange={(selected: number) => handleDateCallback({ type: 'endDate', selected })}
                                valueType="ms"
                                minDate={startDate}
                                maxDate={moment.now()}
                                showTimeInput
                            />
                        </div>
                    </SelectGroup>
                    <div className="d-flex gap-1 justify-content-end">
                        <Button
                            className="btn-secondary btn-yesterday"
                            style={{ width: 'fit-content' }}
                            onClick={() => handleDateCallback({ type: 'yesterday' })}
                        >
                            {t('Yesterday', 'Button')}
                        </Button>
                        <Button
                            className="btn-brand btn-icon"
                            title={t('Search', 'Search')}
                            iconName="search"
                            onClick={handleSearchClick}
                        >
                            {t('Search', 'Search')}
                        </Button>
                        <Button
                            className="btn-danger btn-icon-only"
                            iconName="replay"
                            onClick={() => {
                                handleDateCallback({ type: 'reset' });
                                setSelectedOption(translatedSelectOptions[0]);
                                getLocationApprovalLog({
                                    opt: translatedSelectOptions[0].value,
                                    startDate: moment().startOf('day').valueOf(),
                                    endDate: moment.now().valueOf(),
                                });
                            }}
                        />
                    </div>
                </FilterList>
                <FilterButtonArea children={null} />
            </FilterGroup>
        </FilterSearchGroup>
    );
};

export default Search;
