import React from 'react';
import styled from 'styled-components';
import { Col, Row } from 'reactstrap';
import CustomPieChart from '../../../Components/Chart/CustomePieChart';
import AvgSection from '../../../Components/Chart/AvgSection';

const UsedNumSpan = styled.span`
    color: ${props => props.color};
    font-size: 2rem;
    font-weight: bold;
`;

const UtilizationSmallChartVer = ({ avgTime, avgCount, avgLeadTime, mainColor, totalNum, usedNum }) => {
    return (
        <div>
            <div className={'upper-section-in-utilization'}>
                <Row>
                    <Col xl={'8'} style={{ height: '52px' }}>
                        <UsedNumSpan color={mainColor} className={'exclude-font-theme'}>
                            {usedNum}
                        </UsedNumSpan>
                        <span className={'totalNum exclude-font-theme'}>/{totalNum}</span>
                    </Col>
                    <Col xl={'4'}>
                        <CustomPieChart
                            fontColor={'#ababab'}
                            labelClassName={'font-color-fill'}
                            graphColor={mainColor}
                            totalNum={totalNum}
                            usedNum={usedNum}
                            innerRadius={20}
                            outerRadius={25}
                            fontSize={'0.7rem'}
                        />
                    </Col>
                </Row>
            </div>
            <div className="w-60">
                <AvgSection avgCount={avgCount} avgTime={avgTime} avgLeadTime={avgLeadTime} />
            </div>
        </div>
    );
};

export default UtilizationSmallChartVer;
