import React, { useContext, useEffect, useState } from 'react';

import { useTranslation } from '@hooks';

import { getAbnormalHeartBeatAPI } from '@api/military';

import CompoundCard from '../../../Components/CompoundCard';
import useMonitor from '../../../util/useMonitor';

import { setHeartBeat, setHeartbeatEvent } from '../healthInformationProvisionReducer';
import { HealthInformationProvisionDispatchContext, HealthInformationProvisionStatusContext } from '../index';

const AbnormalHeartbeat = ({ config }) => {
    const t = useTranslation('Health Information Provision');

    const { heartBeat, heartbeatEvent } = useContext(HealthInformationProvisionStatusContext);
    const dispatch = useContext(HealthInformationProvisionDispatchContext);

    const [heartBeatButton, setHeartBeatButton] = useState(heartBeat);

    const handleClick = () => {
        setHeartBeatButton(!heartBeatButton);
    };

    useEffect(() => {
        const debounce = setTimeout(() => {
            dispatch(setHeartBeat(heartBeatButton));
        }, 300);
        return () => clearTimeout(debounce);
    }, [heartBeatButton]);

    useMonitor({
        config,
        fetchData: getAbnormalHeartBeatAPI,
        makeData: ({ newData }) => {
            if (!newData) return;

            const { pkValue } = newData;
            dispatch(setHeartbeatEvent(pkValue));
        },
        errorHandler: err => {
            console.error(err);
        },
    });

    return (
        <CompoundCard>
            <CompoundCard.header>{t('Real-time Abnormal Heartbeats')}</CompoundCard.header>
            <CompoundCard.body>
                <CompoundCard.body.contents
                    handleClick={handleClick}
                    clicked={heartBeat}
                    clickedColor="#ffce2e"
                    hoverColor="#ffce2e"
                >
                    {heartbeatEvent || 0}
                </CompoundCard.body.contents>
            </CompoundCard.body>
        </CompoundCard>
    );
};

export default AbnormalHeartbeat;
