import React, { useContext } from 'react';

import Button from '@components/Button';
import useTranslation from '@hooks/useTranslation';

import { setFilterOpen } from '../filterSearchGroupReducer';
import { FilterSearchGroupDispatchContext, FilterSearchGroupStateContext } from '../index';

const FilterButtonArea = ({ children, widgetSearch = false }) => {
    const { filterOpen } = useContext(FilterSearchGroupStateContext);
    const dispatch = useContext(FilterSearchGroupDispatchContext);
    const handleSearchBoxToggle = () => dispatch(setFilterOpen(!filterOpen));
    const t = useTranslation('Search');
    return (
        <div className="filter-function show__under-mobile">
            <Button
                className="btn-gray btn-icon show__under-mobile"
                iconName="arrow_drop_down"
                onClick={handleSearchBoxToggle}
            >
                {t('More')}
            </Button>
            {children}
        </div>
    );
};

export default FilterButtonArea;
