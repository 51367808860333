import React, { useState } from 'react';

import useTranslation from '@hooks/useTranslation';
import { TextInput, Button } from '@components';

import FilterSearchGroup from '@components/FilterSearchGroup';
import SearchGroup from '@components/FilterSearchGroup/Components/SearchGroup';
import SearchButtonArea from '@components/FilterSearchGroup/Components/SearchButtonArea';
import { InputGroup, InputWrap, SearchWrap } from '@components/FilterSearchGroup/Components/Part';

const SEARCH_OPTION = 'filterName';

const Search = ({ fetchListData }) => {
    const t = useTranslation('Filter');

    const [searchInput, setSearchInput] = useState('');

    const handleRefreshClick = e => {
        setSearchInput('');
        fetchListData();
    };

    const handleSearchClick = () => {
        if (searchInput && searchInput.trim()) {
            fetchListData({
                opt: SEARCH_OPTION,
                keyword: searchInput,
            });
        } else {
            fetchListData();
        }
    };

    const handleInputChange = e => {
        const { value } = e.currentTarget;
        setSearchInput(value);
    };

    return (
        <FilterSearchGroup>
            <SearchGroup className="bg-brand" label={t('Search', 'Search')}>
                <SearchWrap>
                    <InputWrap>
                        <InputGroup>
                            <TextInput
                                style={{ borderRadius: '5px' }}
                                type="text"
                                size={60}
                                placeholder={t('Input Filter Name')}
                                name="name"
                                value={searchInput}
                                handleChange={handleInputChange}
                            />
                        </InputGroup>
                    </InputWrap>
                </SearchWrap>
                <SearchButtonArea>
                    <Button className="btn-brand btn-icon" iconName="search" onClick={handleSearchClick}>
                        {t('Search', 'Search')}
                    </Button>
                    <Button className="btn-danger btn-icon-only" iconName="replay" onClick={handleRefreshClick} />
                </SearchButtonArea>
            </SearchGroup>
        </FilterSearchGroup>
    );
};

export default Search;
