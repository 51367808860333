import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Card from '../../Components/WidgetCard';
import { Row, Col } from 'reactstrap';
import CountUp from 'react-countup';
import { useTranslation } from 'react-i18next';
import PerfectScrollbar from 'react-perfect-scrollbar';

const CountWrapper = ({ children }) => {
    return <Col>{children}</Col>;
};

const TagCount = ({ start = 0, end = 0, label }) => {
    return (
        <div className={'flex-center'} style={{ flexDirection: 'column', height: '100%' }}>
            <CountUp
                className={'font-weight-bold'}
                style={{ fontSize: '2.5rem' }}
                start={start}
                end={end}
                separator=""
                decimals={0}
                decimal=","
                prefix=""
                useEasing={false}
                suffix=""
                duration="1"
            />
            <h6
                className={'mt-2'}
                style={{ fontWeight: 500, fontSize: '0.88rem', textAlign: 'center', wordBreak: 'keep-all' }}
            >
                {label}
            </h6>
        </div>
    );
};

const TagStatus = ({ children, widgetInfo, ...restProps }) => {
    // const { config } = widgetInfo;
    const { tagList, tagStatus } = useSelector(state => state.TagInfo);
    const { t } = useTranslation();

    const [status, setStatus] = useState({
        recognized: 0,
        sos: 0,
        lostSignal: 0,
        lowBattery: 0,
        longStay: 0,
    });
    const [countData, setCountData] = useState({ prev: status, curr: status });

    useEffect(() => {
        setCountData(prev => ({
            prev: prev.curr,
            curr: status,
        }));
    }, [status]);

    useEffect(() => {
        setStatus({
            recognized: Object.keys(tagList).length,
            sos: tagStatus.sos.length,
            lostSignal: tagStatus.lostSignal.length,
            lowBattery: tagStatus.battery.length,
            longStay: tagStatus.longStay.length,
        });
    }, [tagList, tagStatus]);

    return (
        <>
            <Card widgetInfo={widgetInfo} {...restProps}>
                <div className={'scrollbar-container'}>
                    <PerfectScrollbar>
                        <Row className={'flex-center'} style={{ height: '100%' }}>
                            <div style={{ width: '100%', display: 'flex', alignItems: 'start' }}>
                                <CountWrapper>
                                    <TagCount
                                        start={countData.prev.recognized}
                                        end={countData.curr.recognized}
                                        label={t('TagStatus;Total number of detected tags')}
                                    />
                                </CountWrapper>
                                <CountWrapper>
                                    <TagCount
                                        start={countData.prev.sos}
                                        end={countData.curr.sos}
                                        label={t('TagStatus;SOS')}
                                    />
                                </CountWrapper>
                                <CountWrapper>
                                    <TagCount
                                        start={countData.prev.lostSignal}
                                        end={countData.curr.lostSignal}
                                        label={t('TagStatus;Lost Signal')}
                                    />
                                </CountWrapper>
                                <CountWrapper>
                                    <TagCount
                                        start={countData.prev.lowBattery}
                                        end={countData.curr.lowBattery}
                                        label={t('TagStatus;Low Battery')}
                                    />
                                </CountWrapper>
                                <CountWrapper>
                                    <TagCount
                                        start={countData.prev.longStay}
                                        end={countData.curr.longStay}
                                        label={t('TagStatus;Long Stay > 30 Seconds')}
                                    />
                                </CountWrapper>
                            </div>
                        </Row>
                    </PerfectScrollbar>
                </div>
                {children}
            </Card>
        </>
    );
};

export default TagStatus;
