import React, { useContext } from 'react';

import { layoutSize } from '@util/common/util';
import { useTranslation } from '@hooks';

import CompoundCard from '../../../Components/CompoundCard';
import ConditionalRender from '../../../Components/CompoundCard/ConditionalRender';

import { HealthCareMonitoringStatusContext } from '../index';

import CustomBarChart from './CustomBarChart';

import moment from 'moment';

import styled from 'styled-components';

const chartList = [
    { id: 'heartbeat', key: 'HEARTBEAT', label: 'Heart Beat' },
    { id: 'steps', key: 'STEP', label: 'Count Of Steps' },
    { id: 'sleep', key: 'SLEEP', label: 'Sleeping Time' },
    { id: 'calories', key: 'CALORIES', label: 'Calories' },
    { id: 'distance', key: 'DISTANCE', label: 'Distance' },
    { id: 'stress', key: 'STRESS', label: 'Stress Indicator' },
];

const HealthCareDetails = () => {
    const t = useTranslation('Health Care Monitoring');

    const { sensorData } = useContext(HealthCareMonitoringStatusContext);

    return (
        <ConditionalRender condition={!!sensorData}>
            <HealthCareDetailsContainer>
                {chartList.map(chartData => {
                    const { id, label } = chartData;
                    const data = !sensorData[id]
                        ? null
                        : sensorData[id]
                              .slice()
                              .sort((a, b) => a.name - b.name)
                              .map(({ name, value }) => {
                                  return { name: moment.unix(name).format('YYYY-MM-DD'), value };
                              });
                    return (
                        <CompoundCard key={id} style={{ height: '16rem' }}>
                            <CompoundCard.header title={t(label)}>{t(label)}</CompoundCard.header>
                            <CompoundCard.body style={{ height: 'calc(100% - 41px)' }}>
                                <CompoundCard.body.plain>
                                    <ConditionalRender condition={!!(data && data.length)}>
                                        <CustomBarChart data={data} />
                                    </ConditionalRender>
                                </CompoundCard.body.plain>
                            </CompoundCard.body>
                        </CompoundCard>
                    );
                })}
            </HealthCareDetailsContainer>
        </ConditionalRender>
    );
};

const HealthCareDetailsContainer = styled.div`
    display: grid;
    gap: 0.5rem;
    margin-top: 0.5rem;
    grid-template-columns: repeat(3, 1fr);
    @media (${layoutSize.laptop}) {
        grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
    }
`;

export default HealthCareDetails;
