import Widget, { WIDGET_CATEGORY } from '../staticInfo';

import StateConversion from './StateConversion';
import StateConversionSetting from './StateConversion/StateConversionSetting';
import StatusOfEntryAndExitOfVessel from './StatusOfEntryAndExitOfVessel';
import StatusOfEntryAndExitOfVesselSetting from './StatusOfEntryAndExitOfVessel/StatusOfEntryAndExitOfVesselSetting';
import PatrolOnDuty from './PatrolOnDuty';
import PatrolOnDutySetting from './PatrolOnDuty/PatrolOnDutySetting';
import HealthCareMonitoring from './HealthCareMonitoring';
import HealthCareMonitoringSetting from './HealthCareMonitoring/HealthCareMonitoringSetting';
import HealthInformationProvision from './HealthInformationProvision';
import HealthInformationProvisionSetting from './HealthInformationProvision/HealthInformationProvisionSetting';
import SmartWatchHealthMonitoring from './SmartWatchHealthMonitoring';
import SmartWatchHealthMonitoringSetting from './SmartWatchHealthMonitoring/SmartWatchHealthMonitoringSetting';
import PatrolManagement from './PatrolManagement';
import PatrolRulesOfDuty from './PatrolRulesOfDuty';

export const stateConversion: Widget = {
    type: 'stateConversion',
    category: WIDGET_CATEGORY.NAVY,
    name: 'State Conversion',
    desc: 'This widget monitors the state conversion.',
    thumbnailURL: null,
    Component: StateConversion,
    SettingComponent: StateConversionSetting,
    config: {
        defaultSettings: { name: '국면전환' },
    },
    layout: {
        minW: 12,
        w: 12,
        minH: 47,
        h: 47,
        // maxH: 50,
    },
};

export const statusOfEntryAndExitOfVessel: Widget = {
    type: 'statusOfEntryAndExitOfVessel',
    category: WIDGET_CATEGORY.NAVY,
    name: 'Status Of Entry And Exit Of Vessel',
    desc: 'This widget monitors status of entry and exit of vessel.',
    thumbnailURL: null,
    Component: StatusOfEntryAndExitOfVessel,
    SettingComponent: StatusOfEntryAndExitOfVesselSetting,
    config: {
        defaultSettings: { intervalTime: 10000, name: '함정출입현황' },
    },
    layout: {
        minW: 10,
        w: 12,
        maxW: 12,
        minH: 36,
        h: 41,
        maxH: 52,
    },
};

export const patrolOnDuty: Widget = {
    type: 'patrolOnDuty',
    category: WIDGET_CATEGORY.NAVY,
    name: 'Patrol On Duty',
    desc: 'This widget monitors patrol duty.',
    thumbnailURL: null,
    Component: PatrolOnDuty,
    SettingComponent: PatrolOnDutySetting,
    config: { defaultSettings: { name: '당직순찰' } },
    layout: {
        minW: 12,
        w: 12,
        minH: 47,
        h: 47,
        // maxH: 50,
    },
};

export const healthCareMonitoring: Widget = {
    type: 'healthCareMonitoring',
    category: WIDGET_CATEGORY.NAVY,
    name: 'Health Care Monitoring',
    desc: 'This widget monitors the health of unit members.',
    thumbnailURL: null,
    Component: HealthCareMonitoring,
    SettingComponent: HealthCareMonitoringSetting,
    config: {
        defaultSettings: { name: '건강관리 모니터링' },
    },
    layout: {
        minW: 10,
        w: 12,
        maxW: 12,
        minH: 46,
        h: 48,
        maxH: 52,
    },
};

export const healthInformationProvision: Widget = {
    type: 'healthInformationProvision',
    category: WIDGET_CATEGORY.NAVY,
    name: 'Status Of Health Information Provision',
    desc: 'This widget shows the status of health information provision.',
    thumbnailURL: null,
    Component: HealthInformationProvision,
    SettingComponent: HealthInformationProvisionSetting,
    config: {
        defaultSettings: { intervalTime: 10000, name: '건강정보 제공현황' },
    },
    layout: {
        minW: 10,
        w: 12,
        maxW: 12,
        minH: 36,
        h: 41,
        maxH: 52,
    },
};

export const smartWatchHealthMonitoring: Widget = {
    type: 'smartWatchHealthMonitoring',
    category: WIDGET_CATEGORY.NAVY,
    name: 'Crew Information Monitoring',
    desc: 'This widget monitors the status of the crew in real time through smart watch.',
    thumbnailURL: null,
    Component: SmartWatchHealthMonitoring,
    SettingComponent: SmartWatchHealthMonitoringSetting,
    config: {
        defaultSettings: { intervalTime: 10000, name: '승조원 정보 모니터링' },
    },
    layout: {
        minW: 10,
        w: 12,
        maxW: 12,
        minH: 36,
        h: 41,
        maxH: 52,
    },
};

export const patrolManagement: Widget = {
    type: 'patrolManagement',
    category: WIDGET_CATEGORY.NAVY,
    name: 'Patrol Management',
    desc: 'This widget manages patrols.',
    thumbnailURL: null,
    Component: PatrolManagement,
    SettingComponent: null,
    config: {
        defaultSettings: { name: '일일순찰 중점사항' },
    },
    layout: {
        minW: 4,
        w: 6,
        maxW: 8,
        minH: 19,
        h: 24,
        maxH: 40,
    },
};

export const patrolRulesOfDuty: Widget = {
    type: 'patrolRulesOfDuty',
    category: WIDGET_CATEGORY.NAVY,
    name: 'Patrol Rules Of Duty',
    desc: 'This widget manages rules of duty.',
    thumbnailURL: null,
    Component: PatrolRulesOfDuty,
    SettingComponent: null,
    config: {
        defaultSettings: { name: '당직근무수칙' },
    },
    layout: {
        minW: 4,
        w: 6,
        maxW: 8,
        minH: 19,
        h: 24,
        maxH: 40,
    },
};

const smWidgetList = {
    stateConversion,
    statusOfEntryAndExitOfVessel,
    patrolOnDuty,
    healthCareMonitoring,
    healthInformationProvision,
    smartWatchHealthMonitoring,
    patrolManagement,
    patrolRulesOfDuty,
};

export default smWidgetList;
