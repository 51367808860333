import React, { useContext, useState, useMemo, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Row, Col } from 'reactstrap';

import { Button, Card, SearchableSelect, Label, TextInput, ConfirmModal, ToggleButton } from '@components';
import { createFilterApi, editFilterApi, getFilterListApi } from '@api/filter';
import { useAsync, useConfirmModal, useTranslation } from '@hooks';

import RightSelect from './RightSelect';

import { useSelector, useDispatch } from 'react-redux';
import { FilterDetailDispatchContext, FilterDetailStateContext } from '../index';
import { staticFilterInfo, defaultFilterInfo, defaultDynamicSelectFilterInfo } from '../../FilterWidget/Option';
import { setEditMode, updateFilter } from '../reducer';
import { setUpdatedFilterList } from '@reducer/FilterInfo';
import FilterGenerator from '../../FilterWidget/FilterGenerator';

const DropdownButton = ({ onClick }) => {
    const t = useTranslation('Filter');
    return (
        <Button className="btn-secondary w-px-150 btn-icon" iconName="add" onClick={onClick}>
            {t('Add filter')}
        </Button>
    );
};

const SERVICE_CODE = 'AM01';

const EditView = () => {
    const history = useHistory();
    const { filterNum } = useParams();
    const { searchableCategoryPropertiesList, topCategoryList } = useSelector(state => state.CategoryInfo);
    const dispatch = useContext(FilterDetailDispatchContext);
    const { editing } = useContext(FilterDetailStateContext);
    const t = useTranslation('Filter');
    const buttonT = useTranslation('Button');
    const storeDispatch = useDispatch();

    const [validationMsg, setValidationMsg] = useState([]);
    const [saveSuccessModal, setSaveSuccessModal] = useState(false);

    // Smart Military Custom - PEOPLE 카테고리 제외
    const [filteredTopCategoryList, setFilteredTopCategoryList] = useState(topCategoryList);

    // Smart Military Custom - PEOPLE 카테고리 제외
    useEffect(() => {
        if (topCategoryList && !!topCategoryList.length) {
            setFilteredTopCategoryList(topCategoryList.filter(({ value }) => value !== 'PEOPLE'));
        }
    }, [topCategoryList]);

    const toggleSaveSuccessModal = () => {
        setSaveSuccessModal(!saveSuccessModal);
        history.push('/filter/list');
    };
    const handleSelectValueChange = selected => {
        const selectedCondition = selected.reduce((selectedList, { value }) => {
            const existFilter = editing.filterInfoCondition.find(filter => filter.conditionId === value);
            if (existFilter) {
                selectedList.push(existFilter);
            } else {
                let addedFilterInitValue = defaultFilterInfo.find(filter => filter.conditionId === value);
                if (!addedFilterInitValue) {
                    const categoryProp = searchableCategoryPropertiesList.find(prop => prop.propertyId === value);
                    if (categoryProp) {
                        addedFilterInitValue = {
                            ...defaultDynamicSelectFilterInfo,
                            conditionId: categoryProp.propertyId,
                        };
                    }
                }

                if (addedFilterInitValue) {
                    selectedList.push(addedFilterInitValue);
                }
            }
            return selectedList;
        }, []);
        dispatch(updateFilter({ filterInfoCondition: selectedCondition }));
    };

    const { promise: getFilterList } = useAsync({
        promise: getFilterListApi,
        fixedParam: { isAll: 'Y' },
        resolve: ({ rows }) => {
            storeDispatch(setUpdatedFilterList(rows || []));
        },
    });

    const saveSuccessCallback = () => {
        setSaveSuccessModal(true);
        getFilterList();
    };

    const { promise: createFilter } = useAsync({
        promise: createFilterApi,
        resolve: saveSuccessCallback,
        reject: () => {
            toggleSaveFailModal();
        },
    });

    const { promise: editFilter } = useAsync({
        promise: editFilterApi,
        resolve: saveSuccessCallback,
        reject: () => {
            toggleSaveFailModal();
        },
    });

    const { Modal: ConfirmSaveModal, toggleModal: toggleConfirmSaveModal } = useConfirmModal({
        confirmText: filterNum ? t('Do you want to save information?') : t('Do you want to create filter?'),
        okCallback: () => {
            // Smart Military Custom - PEOPLE 카테고리 제외
            const filteredEditing = {
                ...editing,
                categoryCodes: editing.categoryCodes || filteredTopCategoryList.map(({ value }) => value).join(','),
                serviceCode: SERVICE_CODE,
            };

            const validation = checkValidation(filteredEditing);

            if (!validation) {
                return toggleValidationModal();
            }

            if (filterNum) {
                editFilter(filteredEditing);
            } else {
                createFilter(filteredEditing);
            }
        },
    });

    const { Modal: ValidationModal, toggleModal: toggleValidationModal } = useConfirmModal({
        header: { title: t('Validation Fail') },
        confirmText: (
            <>
                {validationMsg.map((msg, i) => {
                    return (
                        <li key={`validation_${i}`}>
                            <span className="pnt-label--group">
                                <div
                                    className="label-main label-dot w-100"
                                    style={{ fontWeight: 'bold', fontSize: '0.9rem' }}
                                >
                                    {msg}
                                </div>
                            </span>
                        </li>
                    );
                })}
            </>
        ),
    });

    const { Modal: SaveFailModal, toggleModal: toggleSaveFailModal } = useConfirmModal({
        confirmText: t('The request failed.', 'ErrorHandler'),
    });

    const checkValidation = filterInfo => {
        const errMsg = [];

        const { filterName, description, filterInfoCondition, filterInfoAccessRight = [] } = filterInfo;
        const ownership = filterInfoAccessRight.filter(v => v.accessScope === 'W');
        const accessRight = filterInfoAccessRight.filter(v => v.accessScope === 'R');

        if (filterName && filterName.length > 15) {
            errMsg.push(t('Filter name should be under 15 characters'));
        }
        if (!filterName || !filterName.trim().length) {
            errMsg.push(t('Filter name is required'));
        }
        if (!description || !description.length) {
            errMsg.push(t('Filter description is required'));
        }
        if (description && description.length > 100) {
            errMsg.push(t('Filter description should be under 100 characters'));
        }
        if (filterInfoCondition.length === 0) {
            errMsg.push(t('At least one filter should be selected'));
        }
        if (!ownership.length) {
            errMsg.push(t('Owner is required'));
        }
        if (!accessRight.length) {
            errMsg.push(t('Access right is required'));
        }

        setValidationMsg(errMsg);

        return !errMsg.length;
    };

    const filterConditionList = useMemo(() => {
        return [
            ...staticFilterInfo.map(v => ({
                value: v.conditionId,
                label: t(v.name),
            })),
            ...searchableCategoryPropertiesList.reduce(
                (categoryPropertiesList, { categoryCode, propertyId, displayName }) => {
                    if (editing.categoryCodes === categoryCode) {
                        categoryPropertiesList.push({
                            value: propertyId,
                            label: displayName,
                        });
                    }
                    return categoryPropertiesList;
                },
                [],
            ),
        ];
    }, [editing.categoryCodes, staticFilterInfo, searchableCategoryPropertiesList]);

    useEffect(() => {
        const selectableConditionList = filterConditionList.map(condition => condition.value);
        dispatch(
            updateFilter({
                filterInfoCondition: editing.filterInfoCondition.filter(condition =>
                    selectableConditionList.includes(condition.conditionId),
                ),
            }),
        );
    }, [filterConditionList]);

    return (
        <>
            <Card
                header={{
                    title: `${t('Filter')} - ${filterNum ? t('Edit') : t('Create')}`,
                    titleIcon: filterNum ? 'icon-edit' : 'icon-create',
                    action: (
                        <>
                            <Button
                                className="btn-gray"
                                onClick={() => {
                                    if (filterNum) {
                                        dispatch(setEditMode(false));
                                    } else {
                                        history.goBack();
                                    }
                                }}
                            >
                                {buttonT('Cancel')}
                            </Button>
                            <Button className="btn-icon btn-brand" iconName="check" onClick={toggleConfirmSaveModal}>
                                {buttonT('Save')}
                            </Button>
                        </>
                    ),
                }}
                bodyClassName="overflow-visible"
            >
                <div className="d-flex mb-3 pnt-label--group">
                    <Label
                        name={t('Name')}
                        labelValueClassName="label-dot me-5"
                        value={
                            <TextInput
                                inputGroupClassName="w-100 form-must"
                                name="filterName"
                                value={editing.filterName}
                                placeholder={t('Please enter a name for the filter')}
                                handleChange={e => dispatch(updateFilter({ filterName: e.target.value }))}
                            />
                        }
                    />
                </div>
                <div className="d-flex mb-3 pnt-label--group">
                    <Label
                        name={t('Description')}
                        labelValueClassName="label-dot me-5"
                        value={
                            <TextInput
                                inputGroupClassName="w-100"
                                name="description"
                                value={editing.description}
                                placeholder={t('Please enter a description')}
                                handleChange={e => dispatch(updateFilter({ description: e.target.value }))}
                            />
                        }
                    />
                </div>
                <div className="d-flex pnt-label--group">
                    <Label name={t('Menu')} labelValueClassName="label-dot me-5" />
                    <ToggleButton
                        checked={editing.isApplyMenu === 'Y'}
                        handleChecked={() => {
                            const checked = editing.isApplyMenu === 'Y';
                            dispatch(updateFilter({ isApplyMenu: !checked ? 'Y' : 'N' }));
                        }}
                    />
                    {editing.isApplyMenu === 'Y' ? (
                        <span className="pnt-txt txt-tiny ms-3">{t('Marking as a asset menu')}</span>
                    ) : (
                        <span className="pnt-txt txt-tiny ms-3">{t('Not marking as a asset menu')}</span>
                    )}
                </div>
                <div className="pnt-border border-w" />
                <div className="d-flex">
                    <Label
                        name={t('Filter Type')}
                        infoBoxValue={t(
                            'If more than one filter type is selected, category property filters are disabled and selected category property filters are deleted.',
                        )}
                        labelValueClassName="label-dot label-info me-5 text-nowrap"
                    />
                    <SearchableSelect
                        title={t('Category')}
                        data={filteredTopCategoryList}
                        selected={(editing.categoryCodes || '').split(',')}
                        onChange={selected => {
                            dispatch(
                                updateFilter({
                                    categoryCodes: selected.map(category => category.value).join(','),
                                }),
                            );
                        }}
                    />
                </div>
                <div className="pnt-border border-w" />
                <div className="d-flex">
                    <Label name={t('Filter setting')} labelValueClassName="label-dot me-5" />
                    <div className="d-flex justify-content-between w-100 align-items-center ms-2 flex-wrap gap-2">
                        <div className="d-flex flex-wrap gap-1">
                            <FilterGenerator
                                // Smart Military Custom - PEOPLE 카테고리 제외
                                filterInfo={{
                                    ...editing,
                                    categoryCodes:
                                        editing.categoryCodes ||
                                        filteredTopCategoryList.map(({ value }) => value).join(','),
                                }}
                                filterEditMode
                                handleChange={(selected, isEditable, conditionInfo) => {
                                    dispatch(
                                        updateFilter({
                                            filterInfoCondition: editing.filterInfoCondition.map(filter => {
                                                if (filter.conditionId === conditionInfo.conditionId) {
                                                    return {
                                                        ...filter,
                                                        isEditable,
                                                        conditionValues: selected,
                                                    };
                                                }
                                                return filter;
                                            }),
                                        }),
                                    );
                                }}
                            />
                        </div>
                        <SearchableSelect
                            keepSortOrder
                            className="align-right"
                            data={filterConditionList}
                            selected={editing.filterInfoCondition.map(v => v.conditionId)}
                            onChange={handleSelectValueChange}
                            DropdownControlComponent={DropdownButton}
                        />
                    </div>
                </div>
                <div className="pnt-border border-w" />
                <div className="d-flex pnt-label--group">
                    <Label name={t('Access Right')} labelValueClassName="label-dot me-5" />
                    <Row className="w-100" style={{ rowGap: '0.5rem' }}>
                        <Col xl={6}>
                            <RightSelect
                                title={t('Owner')}
                                accessScope="W"
                                list={editing.filterInfoAccessRight.filter(v => v.accessScope === 'W')}
                                handleChange={changedList => {
                                    dispatch(
                                        updateFilter({
                                            filterInfoAccessRight: [
                                                ...editing.filterInfoAccessRight.filter(v => v.accessScope === 'R'),
                                                ...changedList,
                                            ],
                                        }),
                                    );
                                }}
                            />
                        </Col>
                        <Col xl={6}>
                            <RightSelect
                                title={t('Access rights')}
                                accessScope="R"
                                list={editing.filterInfoAccessRight.filter(v => v.accessScope === 'R')}
                                handleChange={changedList => {
                                    dispatch(
                                        updateFilter({
                                            filterInfoAccessRight: [
                                                ...editing.filterInfoAccessRight.filter(v => v.accessScope === 'W'),
                                                ...changedList,
                                            ],
                                        }),
                                    );
                                }}
                            />
                        </Col>
                    </Row>
                </div>
            </Card>
            <ConfirmSaveModal />
            <ValidationModal />
            <SaveFailModal />
            <ConfirmModal
                initModal={saveSuccessModal}
                toggleModal={toggleSaveSuccessModal}
                confirmText={filterNum ? t('Filter is changed successfully') : t('Filter is created successfully')}
                removeCancel
            />
        </>
    );
};

export default EditView;
