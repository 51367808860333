import React from 'react';
import { DateInputProps } from '../type';

const DateInput = ({ className, value, name, handleDateChange, min, max }: DateInputProps): JSX.Element => {
    return (
        <div className={`pnt-input--group ${className}`}>
            <input
                onChange={handleDateChange}
                className={`pnt-input`}
                type={'datetime-local'}
                value={value}
                name={name}
                min={min}
                max={max}
            />
        </div>
    );
};

export default DateInput;
