import React, { useState, useContext, useEffect, useMemo } from 'react';

import { useTranslation, useColumns } from '@hooks';

import { Table } from '@components';
import CompoundCard from '../../../Components/CompoundCard';

import DetailsByFloor from './DetailsByFloor';

import { StateConversionDispatchContext, StateConversionStateContext } from '../index';
import { setFloorInfo } from '../stateConversionReducer';

import { useSelector } from 'react-redux';

import * as column from '../column';

import cx from 'classnames';

import { EntireContainer } from '../style';

const floorClassName = {
    headerClassName: {
        complete: 'bg-brand color-white cursor-default',
        incomplete: 'bg-danger color-white cursor-default',
    },
    bodyClassName: {
        complete: 'color-brand cursor-default',
        incomplete: 'color-danger cursor-default',
    },
};

const wholeFloorLabels = [
    { id: 'complete', label: 'All Floor State Conversion Completion' },
    { id: 'incomplete', label: 'All Floor State Conversion InCompletion' },
];

const floorLables = [
    { id: 'complete', label: 'State Conversion Completion' },
    { id: 'incomplete', label: 'State Conversion InCompletion' },
];

const StateConversionPersonnel = ({ loading }) => {
    const t = useTranslation('State Conversion');

    const { floorList } = useSelector(state => state.FloorInfo);

    const { floorInfo, conjunctureComplete, conjunctureInComplete, conjuncturePersonnel } =
        useContext(StateConversionStateContext);
    const dispatch = useContext(StateConversionDispatchContext);

    const columns = useColumns(
        [column.floor(), column.completion({ width: 80 }), column.incompletion({ width: 80 })],
        t,
        [floorInfo.floorId],
    );

    const [floorData, setFloorData] = useState({
        complete: 0,
        incomplete: 0,
    });

    const tableData = useMemo(() => {
        if (!floorList.length) {
            return { rows: [] };
        }

        const rows = floorList.map(floor => {
            const { floorId, floorName } = floor;
            const completion = conjunctureComplete[floorId] || 0;
            const incompletion = conjunctureInComplete[floorId] || 0;
            return { completion, incompletion, floorName, floorId };
        });
        return { ...conjuncturePersonnel, rows };
    }, [floorList, conjunctureComplete, conjunctureInComplete]);

    const wholeFloorData = useMemo(() => {
        return wholeFloorLabels.map(labels => {
            const { id, label } = labels;
            const value = id === 'complete' ? conjunctureComplete['entire'] : conjunctureInComplete['entire'];
            return { id, label: t(label), value: value || 0 };
        });
    }, [t, conjunctureComplete, conjunctureInComplete]);

    const currentFloorData = useMemo(() => {
        return floorLables.map(labels => {
            const { id, label } = labels;
            return { id, label: t(label), value: floorData[id] };
        });
    }, [t, floorData]);

    const handleTrClick = tr => {
        if (!floorList || !floorList.length) return;

        const { floorId } = tr;
        const floor = floorList.find(list => list.floorId === floorId);
        if (floor) {
            dispatch(setFloorInfo(floor));
        }
    };

    useEffect(() => {
        const floorId = floorInfo.floorId;
        if (floorId) {
            const complete = conjunctureComplete[floorId] || 0;
            const incomplete = conjunctureInComplete[floorId] || 0;
            setFloorData({ complete, incomplete });
        }
    }, [conjunctureComplete, conjunctureInComplete, floorInfo]);

    return (
        <div className="mt-2">
            <CompoundCard className={cx(floorInfo.floorId === 'entire' && 'h-100')}>
                <CompoundCard.header>{t('State conversion personnel')}</CompoundCard.header>
                <CompoundCard.body className="state-conversion-table gap-0">
                    <CompoundCard.body.plain>
                        {floorInfo.floorId === 'entire' ? (
                            <EntireContainer>
                                <Table columns={columns} data={tableData} paging={false} onTrClick={handleTrClick} />
                            </EntireContainer>
                        ) : (
                            <>
                                <CompoundCard.body.clickableTable
                                    data={wholeFloorData}
                                    headerClassName={floorClassName.headerClassName}
                                    bodyClassName={floorClassName.bodyClassName}
                                />
                                <CompoundCard.body.clickableTable
                                    data={currentFloorData}
                                    headerClassName={floorClassName.headerClassName}
                                    bodyClassName={floorClassName.bodyClassName}
                                />
                            </>
                        )}
                    </CompoundCard.body.plain>
                </CompoundCard.body>
            </CompoundCard>
            {floorInfo.floorId !== 'entire' && <DetailsByFloor loading={loading} />}
        </div>
    );
};

export default StateConversionPersonnel;
