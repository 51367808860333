import React, { useEffect, useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import {
    deleteFlowSchemeInfosApi,
    getFlowSchemeListInfoApi,
    getStatusFlowCategoryMapInfoApi,
    getStatusSchemeListInfoApi,
} from 'api/status';
import { useColumns, useAsync, useTranslation } from '@hooks';
import { ConfirmModal, TextInput, Select as ReactSelect, Table, Card, Button } from '@components';

import { useDispatch, useSelector } from 'react-redux';
import {
    setFlowSchemeListInfo,
    setStatusFlowCategoryMapInfo,
    setStatusSchemeListInfo,
} from 'reducers/Common/StatusInfo';

import { InputGroup, InputWrap, SelectGroup, SearchWrap } from '@components/FilterSearchGroup/Components/Part';
import SearchGroup from '@components/FilterSearchGroup/Components/SearchGroup';
import FilterSearchGroup from 'Components/Common/FilterSearchGroup';

const FlowScheme = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const t = useTranslation('Status');
    const filterT = useTranslation('Filter');

    const { flowSchemeListInfo } = useSelector(state => state.StatusInfo);

    const [filteredFlowSchemeListInfo, setFilteredFlowSchemeListInfo] = useState(flowSchemeListInfo);

    const searchOptions = useMemo(() => {
        return [
            { value: 'flowName', label: t('Name') },
            { value: 'isActive', label: t('Activation') },
        ];
    }, [t]);

    const isActiveOptions = [
        { value: null, label: t('All') },
        { value: 'Y', label: t('Activated') },
        { value: 'N', label: t('Deactivated') },
    ];
    const [selectedOption, setSelectedOption] = useState(searchOptions[0]);
    const [activeOption, setActiveOption] = useState(isActiveOptions[0]);
    const [keyword, setKeyword] = useState('');
    const [searchParam, setSearchParam] = useState({});

    const [checked, setChecked] = useState([]);
    const [deleteModal, setDeleteModal] = useState(false);
    const [reConfirmModal, setReConfirmModal] = useState(false);
    const [deleteConfirmModal, setDeleteConfirmModal] = useState(false);

    const columns = useColumns(
        [
            {
                Header: 'Select',
                width: 50,
                accessor: 'checkBox',
                Cell: ({ row: { original } }) => {
                    return (
                        <input
                            style={{ marginLeft: '0.5rem' }}
                            type="checkbox"
                            name={'categoryCode'}
                            onClick={e => {
                                e.stopPropagation();
                                setChecked(prevState => {
                                    const checkBoxIdx = prevState.indexOf(original);
                                    if (checkBoxIdx === -1) {
                                        return [...prevState, original];
                                    } else {
                                        prevState.splice(checkBoxIdx, 1);
                                        return prevState;
                                    }
                                });
                            }}
                        />
                    );
                },
            },
            {
                Header: 'Name',
                accessor: 'flowName',
                className: 'flex-center',
                Cell: ({ value }) => {
                    return (
                        <div className={'text-ellipsis'} title={value}>
                            {value}
                        </div>
                    );
                },
            },
            {
                Header: 'Status scheme',
                accessor: 'schemeName',
                className: 'flex-center',
                Cell: ({ value }) => {
                    return (
                        <div className={'text-ellipsis'} title={value}>
                            {value}
                        </div>
                    );
                },
            },
            {
                Header: 'Activation',
                accessor: 'isActive',
                className: 'flex-center',
                Cell: ({ value }) => {
                    if (value === 'Y') {
                        return t('Activated');
                    }
                    return t('Deactivated');
                },
            },
            {
                Header: 'Assets',
                accessor: 'itemCount',
                className: 'flex-center',
            },
        ],
        t,
        [t],
    );

    const { promise: getFlowSchemeListInfo } = useAsync({
        promise: getFlowSchemeListInfoApi,
        fixedParam: { isAll: 'Y' },
        resolve: res => {
            dispatch(setFlowSchemeListInfo(res));
        },
    });

    const { promise: getStatusSchemeListInfo } = useAsync({
        promise: getStatusSchemeListInfoApi,
        fixedParam: { isAll: 'Y' },
        resolve: res => {
            dispatch(setStatusSchemeListInfo(res));
        },
    });

    const { promise: getStatusFlowCategoryMapInfo } = useAsync({
        promise: getStatusFlowCategoryMapInfoApi,
        fixedParam: { isAll: 'Y' },
        resolve: res => {
            dispatch(setStatusFlowCategoryMapInfo(res));
        },
    });

    const { promise: deleteTargetStatusFlowInfo } = useAsync({
        promise: deleteFlowSchemeInfosApi,
        resolve: res => {
            getFlowSchemeListInfo();
        },
    });

    useEffect(() => {
        getFlowSchemeListInfo();
        getStatusSchemeListInfo();
        getStatusFlowCategoryMapInfo();
    }, []);

    useEffect(() => {
        setSelectedOption(searchOptions.find(v => v.value === selectedOption.value));
        setActiveOption(isActiveOptions.find(v => v.value === activeOption.value));
    }, [t]);

    const handleSelect = selected => {
        setSelectedOption(selected);
    };

    const handleIsActiveOption = selected => {
        setActiveOption(selected);
    };

    const handleInputChange = e => {
        setKeyword(e.target.value);
    };

    const handleModal = () => {
        if (checked.length) {
            setDeleteModal(prevState => !prevState);
        } else {
            setReConfirmModal(prevState => !prevState);
        }
    };

    const handleDeleteConfirmModal = () => {
        setDeleteConfirmModal(prevState => !prevState);
    };

    const handleDelete = () => {
        const checkedList = checked.reduce((acc, cur) => acc + cur.flowNum + ',', '').slice(0, -1);
        deleteTargetStatusFlowInfo({ flowNums: checkedList });
        setChecked([]);
        setDeleteConfirmModal(prevState => !prevState);
    };

    const handleGetTargetStatusFlowApi = () => {
        // getFlowSchemeListInfo(searchParam);
        // =>
        // setFilteredFlowSchemeListInfo();
    };

    const handleSearchClick = () => {
        if (selectedOption.value === 'isActive') {
            setSearchParam({ key: selectedOption.value, value: activeOption.value });
        } else {
            setSearchParam({ key: selectedOption.value, value: keyword });
        }
    };

    useEffect(() => {
        if (flowSchemeListInfo.rows && flowSchemeListInfo.rows.length) {
            const { key, value } = searchParam;
            let filteredFlowList = flowSchemeListInfo.rows;
            if (key && value) {
                if (key === 'flowName') {
                    filteredFlowList = flowSchemeListInfo.rows.filter(flowScheme =>
                        flowScheme[key].toUpperCase().includes(value.toUpperCase()),
                    );
                } else {
                    filteredFlowList = flowSchemeListInfo.rows.filter(flowScheme => flowScheme[key] === value);
                }
            }
            setFilteredFlowSchemeListInfo({
                ...flowSchemeListInfo,
                rows: filteredFlowList,
                pageSize: filteredFlowList.length,
            });
        }
    }, [flowSchemeListInfo, searchParam]);

    return (
        <>
            <FilterSearchGroup>
                <SearchGroup className={'bg-brand'} label={t('Search', 'Search')}>
                    <SearchWrap>
                        <SelectGroup>
                            <ReactSelect
                                name={'searchOption'}
                                onChange={handleSelect}
                                value={selectedOption}
                                options={searchOptions}
                                placeholder={filterT('Select...')}
                                customControlStyles={{ width: '100%' }}
                                customMenuStyles={{ width: '100%' }}
                                customOptionStyles={{ width: '100%' }}
                            />
                        </SelectGroup>
                        <InputWrap>
                            <InputGroup>
                                {selectedOption.value === 'flowName' ? (
                                    <TextInput
                                        type={'text'}
                                        name={'textInput'}
                                        value={keyword}
                                        placeholder={filterT('Input keyword')}
                                        handleChange={handleInputChange}
                                        size={100}
                                    />
                                ) : (
                                    <ReactSelect
                                        name={'isActive'}
                                        onChange={handleIsActiveOption}
                                        value={activeOption}
                                        options={isActiveOptions}
                                        placeholder={filterT('Select...')}
                                        className="w-100"
                                        customControlStyles={{ width: '100%' }}
                                        customMenuStyles={{ width: '100%' }}
                                        customOptionStyles={{ width: '100%' }}
                                    />
                                )}
                            </InputGroup>
                            <Button className="btn-brand btn-icon" iconName="search" onClick={handleSearchClick}>
                                {t('Search', 'Search')}
                            </Button>
                        </InputWrap>
                    </SearchWrap>
                </SearchGroup>
            </FilterSearchGroup>
            <Card
                header={{
                    title: t('Status flow scheme list'),
                    subTitle: t('It is the Status flow Scheme inquiry function.'),
                    action: (
                        <>
                            <Button className="btn-icon-only btn-danger" iconName="delete" onClick={handleModal} />
                            <div className="pnt-border border-h" />
                            <Button
                                className="btn-brand flex-center"
                                iconName="add"
                                onClick={() => history.push('/status/flow-scheme/create')}
                            >
                                {t('Create Status Flow Scheme')}
                            </Button>
                        </>
                    ),
                }}
            >
                <div style={{ height: '37rem' }}>
                    <Table
                        data={{
                            rows: filteredFlowSchemeListInfo.rows,
                            pageSize: (filteredFlowSchemeListInfo.rows || []).length,
                        }}
                        columns={columns}
                        paging={false}
                        dynamicRowHeight
                        onTrClick={selected => {
                            history.push(`/status/flow-scheme/${selected.flowNum}`);
                        }}
                    />
                </div>
            </Card>
            <ConfirmModal
                initModal={deleteModal}
                toggleModal={handleModal}
                header={{ title: t('ConfirmModal;Confirm') }}
                confirmText={t('ConfirmModal;Do you want to delete the category?')}
                okCallback={handleDelete}
                callbackParam={'Click HandleDelete'}
            />
            <ConfirmModal
                initModal={reConfirmModal}
                toggleModal={handleModal}
                header={{ title: t('ConfirmModal;Notification') }}
                confirmText={t('ConfirmModal;Please select at least one category.')}
            />
            <ConfirmModal
                initModal={deleteConfirmModal}
                toggleModal={handleDeleteConfirmModal}
                header={{ title: t('ConfirmModal;Notification') }}
                confirmText={t('ConfirmModal;The deletion has been completed.')}
                okCallback={handleGetTargetStatusFlowApi}
                cancelCallback={handleGetTargetStatusFlowApi}
            />
        </>
    );
};

export default FlowScheme;
