const EVT_TYPE = {
    FLOOR_IN: 'Floor In',
    FLOOR_OUT: 'Floor Out',
    GEOFENCE_IN: 'Geofence In',
    GEOFENCE_OUT: 'Geofence Out',
    RESTRICTION_IN: 'Restriction In',
    RESTRICTION_OUT: 'Restriction Out',
    LOW_BATTERY: 'Low Battery',
    LOST_SIGNAL: 'Lost Signal',
    SOS_ON: 'SOS On',
    SOS_OFF: 'SOS Off',
    LEAVEALONE_ON: 'Leave Alone On',
    LEAVEALONE_OFF: 'Leave Alone Off',
    LONGSTAY_ON: 'Long Stay On',
    LONGSTAY_OFF: 'Long Stay Off',
    SENSOR_NORMAL: 'Sensor : Normal',
    SENSOR_WARNING: 'Sensor : Warning',
    SENSOR_CRITICAL: 'Sensor : Critical',
    SENSOR_OUTOFRANGE: 'Sensor : Out of Range',
    NOBODY_AROUND_ON: 'Nobody Around On',
    NOBODY_AROUND_OFF: 'Nobody Around Off',
    OUTSIDE_ON: 'Outside On',
    OUTSIDE_OFF: 'Outside Off',
    BOARDING_ON: 'Boarding On',
    BOARDING_OFF: 'Boarding Off',
    GPS_ON: 'GPS On',
    GPS_LOSTSIGNAL: 'GPS Lost Signal',
};

export default EVT_TYPE;
