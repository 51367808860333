export const id = ({ ...restProps } = {}) => {
    return {
        Header: 'ID',
        accessor: 'targetId',
        className: 'text-ellipsis',
        headerClassName: 'text-ellipsis',
        ...restProps,
    };
};

export const name = ({ ...restProps } = {}) => {
    return {
        Header: 'Name',
        accessor: 'targetName',
        className: 'text-ellipsis',
        headerClassName: 'text-ellipsis',
        ...restProps,
    };
};

export const rank = ({ ...restProps } = {}) => {
    return {
        Header: 'rank',
        accessor: 'rank',
        className: 'text-ellipsis',
        headerClassName: 'text-ellipsis',
        ...restProps,
    };
};

export const department = ({ ...restProps } = {}) => {
    return {
        Header: 'department',
        accessor: 'department',
        className: 'text-ellipsis',
        headerClassName: 'text-ellipsis',
        ...restProps,
    };
};

export const state = ({ ...restProps } = {}) => {
    return {
        Header: 'State',
        accessor: 'agreeState',
        className: 'text-ellipsis',
        headerClassName: 'text-ellipsis',
        ...restProps,
    };
};
