import React, { forwardRef, useEffect, useRef } from 'react';
import 'leaflet/dist/leaflet.css';
import 'assets/main/map.scss';
import { LayersControl, Map as LeafletMap, ZoomControl, FeatureGroup, Marker } from 'react-leaflet';
import Control from 'react-leaflet-control';
import GTileLayer from '../Layers/GTileLayer';
import ResizeDetector from 'react-resize-detector';
import GeofenceLayer from '../Layers/GeofenceLayer';
import L from 'leaflet';
import { useTranslation } from 'react-i18next';
import { defaultOptions } from '../config';
import { useSelector } from 'react-redux';

const CustomMap = (
    {
        tile = true,
        children,
        customLayers,
        onMoveend,
        onZoomstart,
        onZoomend,
        geofenceList,
        handleGeofenceClick,
        markerLngLat,
        markerPopup,
        ...restProps
    },
    ref,
) => {
    const { t } = useTranslation();
    const mapRef = useRef();
    const resizeTO = useRef();
    const handleResize = () => {
        if (resizeTO.current) {
            clearTimeout(resizeTO.current);
            resizeTO.current = null;
        }
        resizeTO.current = setTimeout(() => {
            if (mapRef.current) {
                mapRef.current.leafletElement.invalidateSize();
            }
            resizeTO.current = null;
        }, 200);
    };
    const { lang } = useSelector(state => state.UserInfo);

    useEffect(() => {
        if (markerLngLat[0]) {
            mapRef.current.leafletElement.setView(markerLngLat, 19);
        }
    }, [markerLngLat]);

    return (
        <ResizeDetector handleWidth handleHeight onResize={handleResize}>
            <div
                style={{ width: '100%', height: '100%' }}
                onMouseDown={e => {
                    e.stopPropagation();
                }}
            >
                <LeafletMap
                    style={{ zIndex: '0' }}
                    {...defaultOptions}
                    onClick={e => {
                        console.log(e);
                    }}
                    onMoveEnd={onMoveend}
                    onZoomstart={onZoomstart}
                    onZoomend={onZoomend}
                    ref={reference => {
                        if (ref) {
                            ref.current = reference;
                        }
                        mapRef.current = reference;
                    }}
                    whenReady={({ target }) => {
                        L.DomEvent.disableScrollPropagation(target._controlContainer);
                    }}
                    {...restProps}
                >
                    <FeatureGroup>
                        <ZoomControl
                            key={'zoom-control-' + lang}
                            zoomInTitle={t('Zoom in')}
                            zoomOutTitle={t('Zoom out')}
                        />
                        {!!markerLngLat[0] && !!markerLngLat[1] && (
                            <Control
                                key={'aim-control-' + lang}
                                position="topleft"
                                className="leaflet-bar custom-leaflet-control"
                            >
                                <a
                                    style={{ width: '100px' }}
                                    href={'#;'}
                                    role="button"
                                    onClick={e => {
                                        e.preventDefault();
                                        mapRef.current.leafletElement.setView(markerLngLat, 19);

                                        return null;
                                    }}
                                >
                                    <span>{t('Asset;Show in center')}</span>
                                </a>
                            </Control>
                        )}
                    </FeatureGroup>
                    {tile && <GTileLayer />}
                    {(!!(geofenceList && geofenceList.length > 0) || !!(markerLngLat[0] && markerLngLat[1])) && (
                        <LayersControl position="topright">
                            {geofenceList && geofenceList.length > 0 && (
                                <LayersControl.Overlay key={t('Map;Geofence')} name={t('Map;Geofence')} checked={false}>
                                    <GeofenceLayer geofenceList={geofenceList} handleClick={handleGeofenceClick} />
                                </LayersControl.Overlay>
                            )}
                            {markerLngLat[0] && markerLngLat[1] && (
                                <LayersControl.Overlay key={t('Map;Marker')} name={t('Map;Marker')} checked={true}>
                                    <FeatureGroup>
                                        <Marker
                                            position={{
                                                lat: markerLngLat[0],
                                                lng: markerLngLat[1],
                                            }}
                                            icon={L.divIcon({
                                                className: 'simple-marker',
                                                html: `<div></div>`,
                                            })}
                                        >
                                            {markerPopup}
                                        </Marker>
                                    </FeatureGroup>
                                </LayersControl.Overlay>
                            )}
                        </LayersControl>
                    )}

                    {children}
                </LeafletMap>
            </div>
        </ResizeDetector>
    );
};

export default forwardRef(CustomMap);
