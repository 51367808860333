import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DashboardFrameState, DashboardMode } from '../types';

export const SCREEN_MODE_MONITORING = 'monitoring';
export const SCREEN_MODE_EDIT = 'edit';
export const SCREEN_MODE_PREVIEW = 'preview';

const initialState: DashboardFrameState = {
    mode: null,
    fullScreen: false,
};

const { actions, reducer } = createSlice({
    name: 'dashboardFrame',
    initialState,
    reducers: {
        setMode: (state, action: PayloadAction<DashboardMode>) => {
            state.mode = action.payload;
        },
        setFullScreen: (state, action: PayloadAction<boolean>) => {
            state.fullScreen = action.payload;
        },
    },
});

export const { setMode, setFullScreen } = actions;
export default reducer;
