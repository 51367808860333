import React from 'react';
import cx from 'classnames';

const PageTitle = ({
    heading,
    subheading,
    enablePageTitleIcon,
    enablePageTitleSubheading,
    titleAction,
    customIcon,
    ...rest
}) => {
    return (
        <div className={cx('app-page-title', rest.className)} style={rest.style}>
            <div className="page-title-wrapper">
                <div className="page-title-heading">
                    <div className={cx('page-title-icon')}>
                        <span className="material-icons-round">border_color</span>
                    </div>
                    <div style={{ color: '#bcc0bd' }}>
                        {heading}
                        <div
                            style={{ color: '#bcc0bd' }}
                            className={cx('page-title-subheading', { 'd-none': !enablePageTitleSubheading })}
                        >
                            {subheading}
                        </div>
                    </div>
                </div>
                <div className="page-title-actions">{titleAction}</div>
            </div>
        </div>
    );
};

export default PageTitle;
