import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { Card as RSCard, CardBody } from 'reactstrap';

const TabWithTitle = ({ tabs = [{}], styles = {}, handleTabChange = () => {}, title, subTitle }) => {
    const [tabOn, setTabOn] = useState(tabs[0].tabId);
    useEffect(() => {
        handleTabChange(tabOn);
    }, [tabOn]);

    return (
        <RSCard>
            <div className="card-header">
                <div className="card-header__title">
                    <div className="title-wrap">
                        <h3 className="title__main">{title}</h3>
                        <p className="title__sub">{subTitle}</p>
                    </div>
                </div>
                <div className="card-header__tab tab-list2">
                    <ul style={{ ...{ padding: '.5rem .5rem 0', ...styles.header } }}>
                        {tabs.map((data, index) => {
                            return (
                                <li
                                    id={data.tabId}
                                    className={cx(tabOn === data.tabId && 'on', 'cursor-pointer')}
                                    style={{ ...styles.headerItem }}
                                    key={data.tabId + '_' + index}
                                    onClick={e => {
                                        e.preventDefault();
                                        setTabOn(e.target.id);
                                    }}
                                >
                                    <a
                                        href={'#!'}
                                        id={data.tabId}
                                        onClick={e => {
                                            e.preventDefault();
                                            setTabOn(e.target.id);
                                        }}
                                    >
                                        {data.title}
                                    </a>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </div>
            <CardBody>
                {tabs.map((data, index) => {
                    return (
                        <div key={data.tabId + '_' + index} className={cx(tabOn !== data.tabId && 'd-none')}>
                            {data.content}
                        </div>
                    );
                })}
            </CardBody>
        </RSCard>
    );
};

export default TabWithTitle;
