import { dateToFormat } from 'util/common/util';

export const name = ({ ...restProps } = {}) => {
    return {
        Header: 'Asset Name',
        accessor: 'targetName',
        headerClassName: 'text-ellipsis',
        className: 'text-ellipsis',
        width: 90,
        ...restProps,
    };
};

export const category = ({ ...restProps } = {}) => {
    return {
        Header: 'Category',
        accessor: 'categoryName',
        headerClassName: 'text-ellipsis',
        className: 'text-ellipsis',
        ...restProps,
    };
};

export const categoryCode = ({ ...restProps } = {}) => {
    return {
        Header: 'Category',
        accessor: 'categoryCode',
        headerClassName: 'text-ellipsis',
        className: 'text-ellipsis',
        ...restProps,
    };
};

export const date = ({ ...restProps } = {}) => {
    return {
        Header: 'Date',
        accessor: 'regDate',
        headerClassName: 'text-ellipsis',
        className: 'text-ellipsis',
        width: 60,
        formatTitle: ({ value }) => {
            return value ? dateToFormat(value * 1000, 'HH:mm:ss') : '';
        },
        Cell: ({ value }) => {
            return value ? dateToFormat(value * 1000, 'HH:mm:ss') : '';
        },
        ...restProps,
    };
};

export const distance = ({ ...restProps } = {}) => {
    return {
        Header: 'Distance',
        accessor: 'distance',
        headerClassName: 'text-ellipsis',
        className: 'text-ellipsis',
        width: 80,
        formatTitle: ({ value }) => {
            return value || 'N/A';
        },
        Cell: ({ value }) => {
            return value || 'N/A';
        },
        ...restProps,
    };
};

export const latitude = ({ ...restProps } = {}) => {
    return {
        Header: 'Latitude',
        accessor: 'lat',
        headerClassName: 'text-ellipsis',
        className: 'text-ellipsis',
        width: 90,
        formatTitle: ({ value }) => {
            return value.toFixed(6);
        },
        Cell: ({ value }) => {
            return value.toFixed(6);
        },
        ...restProps,
    };
};

export const longitude = ({ ...restProps } = {}) => {
    return {
        Header: 'Longitude',
        accessor: 'lng',
        headerClassName: 'text-ellipsis',
        className: 'text-ellipsis',
        width: 90,
        formatTitle: ({ value }) => {
            return value.toFixed(6);
        },
        Cell: ({ value }) => {
            return value.toFixed(6);
        },
        ...restProps,
    };
};
