import React from 'react';
import ResizeDetector from 'react-resize-detector';

import { RESPONSIVE_FILTER_WIDTH } from './FilterGroup';
import cx from 'classnames';

const SearchGroup = ({ label, children, className }) => {
    return (
        <>
            <ResizeDetector
                handleWidth
                render={({ width }) => {
                    const isSmall = width < RESPONSIVE_FILTER_WIDTH;
                    return (
                        <div className={cx('search-box flx-row flx-top gap-3')}>
                            {label && <div className={cx('filter-label bg-l-2', className)}>{label}</div>}
                            {children}
                        </div>
                    );
                }}
            />
        </>
    );
};
export default SearchGroup;
