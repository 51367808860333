import React, { useContext, useMemo, useState } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';

import useTranslation from '@hooks/useTranslation';
import { TextInput, Select as ReactSelect, TimePicker, Button, SearchableSelect } from '@components';

import FilterGroup from '@components/FilterSearchGroup/Components/FilterGroup';
import SearchGroup from '@components/FilterSearchGroup/Components/SearchGroup';
import FilterButtonArea from '@components/FilterSearchGroup/Components/FilterButtonArea';
import FilterSearchGroup from '@components/FilterSearchGroup';
import {
    FilterList,
    InputGroup,
    InputWrap,
    SearchWrap,
    SelectGroup,
} from '@components/FilterSearchGroup/Components/Part';

import { GeofenceLogDispatchContext, GeofenceLogStateContext } from '../index';

import { setStartDate, setEndDate } from '../geofenceLogReducer';

const Search = ({ getGeofenceLogList }) => {
    const t = useTranslation('Geofence Log');
    const patrolT = useTranslation('Patrol Log');
    const dispatch = useContext(GeofenceLogDispatchContext);

    const { floorList } = useSelector(state => state.FloorInfo);

    const { selectableLGeofenceList, startDate, endDate } = useContext(GeofenceLogStateContext);

    const filteredFloorList = useMemo(() => {
        return [
            { floorId: '', floorName: patrolT('All') },
            ...floorList.filter(({ floorId }) => floorId !== 'entire' && floorId !== 'pnt_outdoor'),
        ];
    }, [floorList, patrolT]);
    const [selectedFloorOption, setSelectedFloorOption] = useState(filteredFloorList[0]);

    const searchOptions = [
        { value: '', label: t('Search Option', 'Search') },
        { value: 'targetName', label: t('Target Name') },
        { value: 'targetId', label: t('Target ID') },
    ];
    const [selectedOption, setSelectedOption] = useState(searchOptions[0]);

    const [keyword, setKeyword] = useState('');
    const [searchInputDisabled, setSearchInputDisabled] = useState(true);

    const [selectedFloorId, setSelectedFloorId] = useState({
        floorId: '',
        floorName: '',
    });

    const [selectedFloorIdPath, setSelectedFloorIdPath] = useState(null);
    const handleSelectedFloor = ({ floorId, floorIdPath, floorName }) => {
        setSelectedFloorId({ floorId, floorName });
        setSelectedFloorIdPath(floorIdPath);
        setSelectedFloorOption(filteredFloorList.find(floor => floor.floorId === floorId) || filteredFloorList[0]);
    };

    const [selectedGeofence, setSelectedGeofence] = useState([]);
    const handleSelectedGeofence = selected => {
        setSelectedGeofence(selected.map(({ value }) => value));
    };

    const handleSelectValueChange = selected => {
        const { value } = selected;
        setSelectedOption(selected);
        if (value !== '') {
            setSearchInputDisabled(false);
        } else {
            setSearchInputDisabled(true);
            setKeyword('');
        }
    };

    const handleRefreshClick = () => {
        setSelectedOption(searchOptions[0]);
        setKeyword('');
        setSelectedFloorId({ floorId: '', floorName: '' });
        setSelectedFloorIdPath(null);
        setSelectedGeofence([]);
        setSelectedFloorOption(filteredFloorList[0]);

        const startDate = moment().startOf('day').valueOf();
        const endDate = moment().endOf('day').valueOf();

        dispatch(setStartDate(startDate));
        dispatch(setEndDate(endDate));
        setSearchInputDisabled(true);
        getGeofenceLogList({
            startDate: moment(startDate).unix(),
            endDate: moment(endDate).unix(),
        });
    };

    const handleSearchInputChange = e => {
        const { value } = e.target;
        setKeyword(value);
    };

    const makeGeofenceParameter = () => {
        const param = {
            startDate: startDate ? moment(startDate).unix() : null,
            endDate: endDate ? moment(endDate).unix() : null,
            keyword,
            opt: selectedOption && selectedOption.value ? selectedOption.value : null,
            fcNums: selectedGeofence.length ? selectedGeofence.join(',') : null,
            floorIds: selectedFloorId.floorId,
            floorIdPath: selectedFloorIdPath,
        };

        if (!keyword) {
            delete param['opt'];
            delete param['keyword'];
        }

        // NULL value 삭제
        Object.entries(param).map(([key, value]) => {
            if (value === null) {
                delete param[key];
            }
        });

        return param;
    };

    const handleSearchClick = () => {
        getGeofenceLogList(makeGeofenceParameter());
    };

    const handleYesterdayButtonClick = () => {
        const start = moment().subtract(1, 'day').startOf('day').valueOf();
        const end = moment().subtract(1, 'day').endOf('day').valueOf();

        dispatch(setStartDate(start));
        dispatch(setEndDate(end));
    };

    return (
        <FilterSearchGroup>
            <FilterGroup className="bg-brand" label={t('Filter', 'Filter')}>
                <FilterList>
                    <SelectGroup>
                        <ReactSelect
                            name="selectOptions"
                            value={selectedFloorOption}
                            options={filteredFloorList}
                            // @ts-ignore
                            onChange={handleSelectedFloor}
                            valueKey="floorId"
                            labelKey="floorName"
                            customControlStyles={{ width: '100%' }}
                            customMenuStyles={{ width: '100%' }}
                            customOptionStyles={{ width: '100%' }}
                        />
                    </SelectGroup>
                    <SelectGroup>
                        <SearchableSelect
                            selected={selectedGeofence}
                            onChange={handleSelectedGeofence}
                            data={selectableLGeofenceList}
                            title={t('Geofence')}
                        />
                    </SelectGroup>

                    <SelectGroup>
                        <div className="datePicker-container">
                            <TimePicker
                                value={startDate}
                                handleChange={selected => dispatch(setStartDate(selected))}
                                valueType="ms"
                                maxDate={endDate || moment.now()}
                                showTimeInput
                            />
                        </div>
                    </SelectGroup>
                    <div className="text-center"> ~ </div>
                    <SelectGroup>
                        <div className="datePicker-container">
                            <TimePicker
                                value={endDate}
                                handleChange={selected => dispatch(setEndDate(selected))}
                                valueType="ms"
                                minDate={startDate}
                                maxDate={moment.now()}
                                showTimeInput
                            />
                        </div>
                    </SelectGroup>
                    <div className="d-flex gap-1 justify-content-end">
                        <Button
                            className="btn-secondary btn-yesterday"
                            style={{ width: 'fit-content' }}
                            onClick={handleYesterdayButtonClick}
                        >
                            {t('Yesterday', 'Button')}
                        </Button>
                        <Button className="btn-danger btn-icon-only" iconName="replay" onClick={handleRefreshClick} />
                    </div>
                </FilterList>
                <FilterButtonArea />
            </FilterGroup>
            <SearchGroup className="bg-secondary" label={t('Search', 'Search')}>
                <SearchWrap>
                    <SelectGroup>
                        <ReactSelect
                            name="selectInput"
                            placeholder={t('Search Option', 'Search')}
                            value={selectedOption}
                            options={searchOptions}
                            onChange={handleSelectValueChange}
                            customControlStyles={{ width: '100%' }}
                            customMenuStyles={{ width: '100%' }}
                            customOptionStyles={{ width: '100%' }}
                        />
                    </SelectGroup>
                    <InputWrap>
                        <InputGroup>
                            <TextInput
                                type="text"
                                size={60}
                                placeholder={t(
                                    'Please Enter your search keyword after selecting search option.',
                                    'Search',
                                )}
                                name="name"
                                value={keyword}
                                handleChange={handleSearchInputChange}
                                disabled={searchInputDisabled}
                            />
                        </InputGroup>
                        <Button className="btn-brand btn-icon" iconName="search" onClick={handleSearchClick}>
                            {t('Search', 'Search')}
                        </Button>
                    </InputWrap>
                </SearchWrap>
            </SearchGroup>
        </FilterSearchGroup>
    );
};
export default Search;
