import React, { useContext, useEffect, useMemo, useState } from 'react';

import useTranslation from '@hooks/useTranslation';
import { Button, SearchableSelect, TimePicker, Select as ReactSelect } from '@components';

import FilterSearchGroup from '@components/FilterSearchGroup';
import { SelectGroup, FilterList } from '@components/FilterSearchGroup/Components/Part';
import FilterGroup from '@components/FilterSearchGroup/Components/FilterGroup';
import FilterButtonArea from '@components/FilterSearchGroup/Components/FilterButtonArea';

import { useSelector } from 'react-redux';
import { ScannerStateLogDispatchContext, ScannerStateLogStateContext } from '../index';
import { setEndDate, setStartDate } from '../scannerLogReducer';

import moment from 'moment';

const Search = ({ getScannerStateLogList }) => {
    const t = useTranslation('Scanner State Log');
    const patrolT = useTranslation('Patrol Log');

    const { floorList } = useSelector(state => state.FloorInfo);

    const dispatch = useContext(ScannerStateLogDispatchContext);
    const { scannerList, startDate, endDate } = useContext(ScannerStateLogStateContext);

    const filteredFloorList = useMemo(() => {
        return [
            { floorId: 'All', floorName: patrolT('All') },
            ...floorList.filter(({ floorId }) => floorId !== 'entire' && floorId !== 'pnt_outdoor'),
        ];
    }, [floorList, patrolT]);
    const [selectedFloorOption, setSelectedFloorOption] = useState(filteredFloorList[0]);

    const [selectedOption, setSelectedOption] = useState({ value: '', label: t('Search Option', 'Search') });
    const searchOptions = useMemo(() => {
        return [
            { value: '', label: t('Search Option', 'Search') },
            { value: 'targetName', label: t('Target Name') },
            { value: 'targetId', label: t('Target ID') },
        ];
    }, [t]);

    useEffect(() => {
        if (selectedOption) {
            setSelectedOption(searchOptions.find(v => v.value === selectedOption.value));
        }
    }, [searchOptions]);

    const handleRefreshClick = () => {
        setSelectedOption(searchOptions[0]);

        setSelectedFloorId({ floorId: '', floorName: '' });
        setSelectedFloorIdPath(null);
        setSelectedScanner([]);
        setSelectedFloorOption(filteredFloorList[0]);

        const startDate = moment().startOf('day').valueOf();
        const endDate = moment().endOf('day').valueOf();

        dispatch(setStartDate(startDate));
        dispatch(setEndDate(endDate));
        getScannerStateLogList({
            startDate: startDate ? moment(startDate).unix() : null,
            endDate: endDate ? moment(endDate).unix() : null,
            floor: 'All',
        });
    };

    const handleSearchClick = () => {
        getScannerStateLogList({
            startDate: startDate ? moment(startDate).unix() : null,
            endDate: endDate ? moment(endDate).unix() : null,
            floor: selectedFloorId.floorId ? selectedFloorId.floorId : null,
            floorPath: selectedFloorIdPath,
            scannerNums: selectedScanner.length ? selectedScanner.join(',') : null,
        });
    };

    const handleYesterdayButtonClick = () => {
        const start = moment().subtract(1, 'day').startOf('day').valueOf();
        const end = moment().subtract(1, 'day').endOf('day').valueOf();

        dispatch(setStartDate(start));
        dispatch(setEndDate(end));
    };

    const [selectedFloorId, setSelectedFloorId] = useState({ floorId: '', floorName: '' });
    const [selectedFloorIdPath, setSelectedFloorIdPath] = useState(null);
    const handleSelectedFloor = ({ floorId, floorIdPath, floorName }) => {
        setSelectedFloorId({ floorId, floorName });
        setSelectedFloorIdPath(floorIdPath);
        setSelectedFloorOption(filteredFloorList.find(floor => floor.floorId === floorId) || filteredFloorList[0]);
    };

    const [selectedScanner, setSelectedScanner] = useState([]);
    const handleSelectedScanner = selected => {
        setSelectedScanner(selected.map(({ value }) => value));
    };
    return (
        <FilterSearchGroup>
            <FilterGroup className={'bg-brand'} label={t('Search', 'Search')}>
                <FilterList>
                    <SelectGroup>
                        <div className="datePicker-container" style={{ minWidth: '8rem' }}>
                            <TimePicker
                                value={startDate}
                                handleChange={selected => dispatch(setStartDate(selected))}
                                valueType="ms"
                                maxDate={endDate || moment.now()}
                                showTimeInput
                            />
                        </div>
                    </SelectGroup>
                    <div className="text-center">~</div>
                    <SelectGroup>
                        <div className="datePicker-container">
                            <TimePicker
                                value={endDate}
                                handleChange={selected => dispatch(setEndDate(selected))}
                                valueType="ms"
                                minDate={startDate}
                                maxDate={moment.now()}
                                showTimeInput
                            />
                        </div>
                    </SelectGroup>
                    <SelectGroup>
                        <ReactSelect
                            name="selectOptions"
                            value={selectedFloorOption}
                            options={filteredFloorList}
                            // @ts-ignore
                            onChange={handleSelectedFloor}
                            valueKey="floorId"
                            labelKey="floorName"
                            customControlStyles={{ width: '100%' }}
                            customMenuStyles={{ width: '100%' }}
                            customOptionStyles={{ width: '100%' }}
                        />
                    </SelectGroup>
                    <SelectGroup>
                        <SearchableSelect
                            selected={selectedScanner}
                            onChange={handleSelectedScanner}
                            data={scannerList}
                            title={t('Scanner')}
                        />
                    </SelectGroup>
                    <div className="d-flex gap-1 justify-content-end">
                        <Button
                            className="btn-secondary btn-yesterday"
                            style={{ width: 'fit-content' }}
                            onClick={handleYesterdayButtonClick}
                        >
                            {t('Yesterday', 'Button')}
                        </Button>
                        <Button
                            className="btn-brand btn-icon"
                            title={t('Search', 'Search')}
                            iconName="search"
                            onClick={handleSearchClick}
                        >
                            {t('Search', 'Search')}
                        </Button>
                        <Button className="btn-danger btn-icon-only" iconName="replay" onClick={handleRefreshClick} />
                    </div>
                </FilterList>
                <FilterButtonArea />
            </FilterGroup>
        </FilterSearchGroup>
    );
};
export default Search;
