import React, { useMemo, useState, useContext } from 'react';

import { getManualCategoryAPI } from '@api/military';

import { useAsync, useTranslation } from '@hooks';
import { Select as ReactSelect, TextInput, Button } from '@components';

import FilterSearchGroup from '@components/FilterSearchGroup';
import FilterGroup from '@components/FilterSearchGroup/Components/FilterGroup';
import {
    FilterList,
    InputGroup,
    InputWrap,
    SearchWrap,
    SelectGroup,
} from '@components/FilterSearchGroup/Components/Part';
import SearchGroup from '@components/FilterSearchGroup/Components/SearchGroup';
import FilterButtonArea from '@components/FilterSearchGroup/Components/FilterButtonArea';

import {
    searchOptionInitial,
    setCategoryList,
    setSearchInput,
    setSelectedCategory,
    setSelectedSearchOption,
} from '../../technicalManualReducer';
import { TechnicalManualDispatchContext, TechnicalManualStateContext } from '../../index';

const Search = ({ getManualList }: { getManualList: Function }) => {
    const t = useTranslation('Technical Manual');

    const {
        categoryList,
        searchOptionList,
        selectedCategory,
        selectedSearchOption,
        searchInput,
        paging: { pageSize },
    } = useContext(TechnicalManualStateContext);
    const dispatch = useContext(TechnicalManualDispatchContext);

    const filteredCategoryList = useMemo(() => {
        if (!categoryList) return [{ value: 'All', label: t('All') }];
        return [{ value: 'All', label: t('All') }, ...categoryList];
    }, [t, categoryList]);

    const filteredSearchOptionList = useMemo(() => {
        if (!searchOptionList) return [{ value: 'All', label: t('All') }];
        return searchOptionList.map(({ value, label }) => {
            return { value, label: t(label) };
        });
    }, [t]);

    const [searchInputState, setSearchInputState] = useState(searchInput);

    const handleSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchInputState(event.target.value);
    };

    const handleSearchClick = () => {
        const param = {
            page: 1,
            pageSize,
            category: selectedCategory ? selectedCategory.value : 'All',
            opt: selectedSearchOption ? selectedSearchOption.value : 'All',
            keyword: searchInputState,
        };

        getManualList(param);

        if (dispatch) {
            dispatch(setSearchInput(searchInputState));
        }
    };

    const handleKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            handleSearchClick();
        }
    };

    const handleRefresh = () => {
        if (dispatch) {
            setSearchInputState('');

            dispatch(setSearchInput(''));
            dispatch(setSelectedCategory(searchOptionInitial[0]));
            dispatch(setSelectedSearchOption(searchOptionInitial[0]));

            getManualList({
                page: 1,
                pageSize,
                category: 'All',
                opt: 'All',
                keyword: '',
            });
        }
    };

    useAsync({
        promise: getManualCategoryAPI,
        immediate: true,
        param: {},
        resolve: (res: { category: string[] }) => {
            const { category } = res;
            const list = category.map(name => ({ value: name, label: name }));

            if (dispatch) {
                dispatch(setCategoryList(list));
            }
        },
        reject: (err: ErrorEvent) => {
            console.error(err);
        },
    });

    return (
        <FilterSearchGroup className="">
            <FilterGroup className="bg-brand" label={t('Filter', 'Filter')}>
                <FilterList className="">
                    <ReactSelect
                        name="categorySelect"
                        value={selectedCategory}
                        options={filteredCategoryList}
                        placeholder={t('Category Select')}
                        onChange={selected => (dispatch ? dispatch(setSelectedCategory(selected)) : '')}
                        customControlStyles={{ width: '100%' }}
                        customMenuStyles={{ width: '100%' }}
                        customOptionStyles={{ width: '100%' }}
                    />
                    <SearchGroup className="" label="">
                        <SearchWrap className="">
                            <SelectGroup className="">
                                <ReactSelect
                                    name="searchOptionSelect"
                                    value={selectedSearchOption}
                                    options={filteredSearchOptionList}
                                    placeholder={t('Search Option', 'Search')}
                                    onChange={selected => (dispatch ? dispatch(setSelectedSearchOption(selected)) : '')}
                                    customControlStyles={{ width: '100%' }}
                                    customMenuStyles={{ width: '100%' }}
                                    customOptionStyles={{ width: '100%' }}
                                />
                            </SelectGroup>
                            <InputWrap>
                                <InputGroup>
                                    <TextInput
                                        type="text"
                                        size={60}
                                        placeholder={t(
                                            'Please Enter your search keyword after selecting search option.',
                                            'Search',
                                        )}
                                        name="name"
                                        value={searchInputState}
                                        handleChange={handleSearchInputChange}
                                        handleKeyUp={handleKeyUp}
                                    />
                                </InputGroup>
                                <Button className="btn-brand btn-icon" iconName="search" onClick={handleSearchClick}>
                                    {t('Search', 'Search')}
                                </Button>
                                <Button iconName="refresh" className="btn-danger" onClick={handleRefresh} />
                            </InputWrap>
                        </SearchWrap>
                    </SearchGroup>
                </FilterList>
                <FilterButtonArea children={null} />
            </FilterGroup>
        </FilterSearchGroup>
    );
};

export default Search;
