export const watchStateList = {
    ALL: 'all',
    WEAR: 'wear',
    NOT_WEAR: 'notWorn',
    CHARGING: 'charging',
    NOT_CONNECTED: 'unconnected',
};

export const absenceStateList = {
    출장: 'businessTrip',
    휴가: 'vacation',
    외출: 'goingOut',
    외박: 'stayOutOvernight',
    파견: 'dispatch',
    교육: 'training',
    입실: 'entry',
    당직: 'onDuty',
    비번: 'offDuty',
    작업: 'work',
    기타: 'etc',
    승선: 'embarkation',
};
