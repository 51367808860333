import React from 'react';
import { ErrorHandleSwitch } from '../Components/Router';
import { Redirect, Route } from 'react-router-dom';
import DashboardsList from './DashboardList';
import { isMobile } from 'react-device-detect';
import DashboardEdit from './DashboardEdit';
import CustomDashboard from './CustomDashboard';
import { getHome } from '../../../util/common/util';

const Dashboards = ({ match }) => {
    return (
        <ErrorHandleSwitch>
            <Route exact path={`${match.path}/list`} component={DashboardsList} />
            {!isMobile && <Route exact path={`${match.path}/edit/:menuNum([0-9]+)`} component={DashboardEdit} />}
            <Route exact path={`${match.path}/:menuNum([0-9]+)`} component={CustomDashboard} />
            <Route
                exact
                path={'/dashboards'}
                render={() => {
                    const home = getHome();
                    if (home) {
                        return <Redirect to={`/dashboards/${home}`} />;
                    }
                    return <Redirect to={'/dashboards/list'} />;
                }}
            />
        </ErrorHandleSwitch>
    );
};

export default Dashboards;
