import React, { useContext, useEffect } from 'react';

import moment from 'moment';

import { getTargetListWithLocationApi, getIotItemApi } from '@api/asset';
import { Button, Card, Table } from '@components';
import { useAsync, useColumns, useTranslation } from '@hooks';

import { useSelector } from 'react-redux';
import { IotItemStatusStateContext, IotItemStatusDispatchContext } from '../index';
import { setIotItemList, setSelectedIotItem, setFloorInfo, setIotItemListParam } from '../iotItemStatusReducer';
import IotItemStatusDetails from './IotItemStatusDetails';

import useMonitor from '../../../util/useMonitor';
import { useSettings } from '../../../util/useSettings';

import * as column from '@util/grid/column';

import styled from 'styled-components';

const defaultData = {
    page: 1,
    totalCount: 2,
    totalPage: 1,
    rows: [
        {
            targetNum: 12878,
            comNum: 100003,
            categoryCode: 'ca',
            categoryName: '카테고리30자',
            lastDate: 1624514302,
            lat: 37.507794,
            lng: 127.052798,
            floor: 'b-1f',
            floorName: '1F',
            targetId: 'ba',
            targetName: 'ba',
            isBookmark: 'N',
            status: ['Lost Signal', 'Low Battery'],
            geofences: [
                {
                    fcNum: 1,
                    fcName: 'test',
                },
            ],
            properties: {
                dd: '',
                img: '',
                test001: '',
                checktest: '["t","a"]',
            },
        },
        {
            targetNum: 12878,
            comNum: 100003,
            categoryCode: 'preview',
            categoryName: '미리보기',
            lastDate: 1624514302,
            lat: 37.507794,
            lng: 127.052798,
            floor: 'b-3f',
            floorName: '3F',
            targetId: 'ba',
            targetName: 'ba',
            isBookmark: 'N',
            status: ['Normal'],
            geofences: [
                {
                    fcNum: 1,
                    fcName: 'test',
                },
            ],
            properties: {
                dd: '',
                img: '',
                test001: '',
                checktest: '["t","a"]',
            },
        },
    ],
};

const BodyContainer = styled.div`
    height: 33rem;
`;

const IotItemStatusList = ({ widgetInfo }) => {
    const t = useTranslation('IotItemStatus');
    const { floorList } = useSelector(state => state.FloorInfo);
    const {
        iotItemCategoryOptions,
        iotItemList,
        iotItemListParam,
        selectedIotItem,
        selectedIotItemStatus,
        searchInput,
    } = useContext(IotItemStatusStateContext);
    const dispatch = useContext(IotItemStatusDispatchContext);
    const { mode } = useSelector(state => state.DashboardFrame);

    const { config } = widgetInfo;
    const settings = useSettings(config);
    const { categoryCode: parentCategory } = settings;

    const columns = useColumns([
        column.categoryName(),
        column.targetName({
            Header: 'Name',
        }),
        column.floorName({
            accessor: 'floorName',
            Cell: ({ value }) => {
                if (value) {
                    return <div>{value}</div>;
                } else {
                    return <div>-</div>;
                }
            },
        }),
        column.location({
            Cell: ({ row: { original } }) => {
                if (original.geofences) {
                    const geofences = original.geofences
                        .map(geofence => {
                            return geofence.fcName;
                        })
                        .join(', ');
                    return <div>{geofences}</div>;
                }
                return <div>-</div>;
            },
        }),
        column.signalStatus({
            width: 200,
            Cell: ({ value }) => {
                let status = value.map((status, index) => {
                    if (index !== 0) {
                        return `, ${t(status)}`;
                    }
                    return t(status);
                });

                if (status[0] !== '정상') {
                    return <div className={'bg-brand color-white h-100 flex-center text-ellipsis'}>{status}</div>;
                } else {
                    return <div className={'h-100 flex-center'}>{status}</div>;
                }
            },
        }),
        column.lastDate({
            Cell: ({ value }) => {
                if (value) {
                    return <div>{moment.unix(value).format('YYYY-MM-DD HH:mm:ss')}</div>;
                } else {
                    return <div>-</div>;
                }
            },
        }),
    ]);

    const { monitor: iotItemListMonitor, stop: iotItemListMonitorStop } = useMonitor({
        config,
        defaultData,
        dynamicParam: iotItemListParam,
        fetchData: getTargetListWithLocationApi,
        manualMonitor: true,
        makeData: ({ newData }) => {
            if (newData.rows) {
                dispatch(setIotItemList(newData));
            }
        },
    });

    useEffect(() => {
        iotItemListMonitorStop();
        // 테이블 페이지 사이즈
        let param = { pageSize: 15, page: 1, isIncludeConfig: 'Y' };

        // 카테고리 하나만 선택됐을 때
        if (searchInput.categoryCode !== parentCategory && searchInput.categoryCode) {
            param.categoryCodes = searchInput.categoryCode;
        } else if (iotItemCategoryOptions.length) {
            const categoryCodesList = iotItemCategoryOptions.map(({ categoryCode }) => categoryCode);
            param.categoryCodes = categoryCodesList.join(',');
        }

        // 자산 상태별로 조회할 때
        if (selectedIotItemStatus) {
            param.status = selectedIotItemStatus;
        } else {
            param.status = '';
        }

        // 검색조건에 검색된 환자가 존재할 때
        if (searchInput.target) {
            param.target = searchInput.target;
        } else {
            param.target = '';
        }

        if (searchInput.floorIds) {
            param.floorIds = searchInput.floorIds;
        } else {
            param.floorIds = '';
        }

        dispatch(setIotItemListParam(param));
    }, [parentCategory, iotItemCategoryOptions, selectedIotItemStatus, searchInput]);

    const { promise: getAsset } = useAsync({
        promise: getIotItemApi,
        resolve: res => {
            dispatch(setSelectedIotItem(res));

            const floor = floorList.find(floor => floor.floorId === res.floorId);
            dispatch(setFloorInfo(floor));
        },
    });

    useEffect(() => {
        iotItemListMonitorStop();
        if (iotItemListParam.categoryCodes && mode === 'monitoring') {
            iotItemListMonitor();
        }
        return () => {
            iotItemListMonitorStop();
        };
    }, [iotItemListParam]);

    const handlePageChange = pageIndex => {
        dispatch(setIotItemListParam({ page: pageIndex }));
    };

    const handleAssetClick = selectedPatient => {
        const { targetNum } = selectedPatient;
        if (targetNum) {
            getAsset({ targetNum });
        }
        iotItemListMonitorStop();
    };

    const handleGoBackClick = () => {
        dispatch(setSelectedIotItem(null));
        iotItemListMonitor();
    };

    return (
        <Card
            header={{
                title: !selectedIotItem ? t('Iot Item List') : t('Selected Item Information'),
                action: !selectedIotItem ? null : (
                    <Button className={'btn-brand'} onClick={handleGoBackClick}>
                        {t('Back', 'Button')}
                    </Button>
                ),
            }}
        >
            {!selectedIotItem ? (
                <BodyContainer>
                    <Table
                        columns={columns}
                        data={iotItemList}
                        onPageChange={handlePageChange}
                        onTrClick={handleAssetClick}
                    />
                </BodyContainer>
            ) : (
                <BodyContainer>
                    <IotItemStatusDetails />
                </BodyContainer>
            )}
        </Card>
    );
};

export default IotItemStatusList;
