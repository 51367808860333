import React, { useContext, useEffect, useState } from 'react';

import useTranslation from '@hooks/useTranslation';

import { Button } from '@components';

import DraggableToast from './DraggableToast';

import { IntegratedMonitorStatusContext } from '../index';

import { useDispatch, useSelector } from 'react-redux';
import { setSelectedAlertInfo } from 'reducers/Notification';

import cx from 'classnames';

import styled from 'styled-components';

const TAG_LABELS = {
    total: 'All',
    inside: 'Inside Of Ship',
    notBoarding: 'No Boarding',
    outside: 'Deck',
    drowning: 'Drowning',
    sos: 'SOS',
    lostSignal: 'Lost Signal',
    absence: 'Absence',
};

const ListModal = ({ initModal = false, toggleModal, toggleConfirmApprovalModal, searchRef }) => {
    const t = useTranslation('Integrated Monitor');

    const [locationList, setLocationList] = useState([]);

    const { tagStatus, selectedTag } = useContext(IntegratedMonitorStatusContext);
    const { tagListByFloor } = useSelector(state => state.TagInfo);
    const { floorList } = useSelector(state => state.FloorInfo);

    const storeDispatch = useDispatch();
    const { locationApproval } = useSelector(state => state.Navy);

    const handleLocationPopup = location => {
        if (!locationApproval) {
            toggleConfirmApprovalModal();
            return;
        }
        const { lat, lng } = location;
        if (lat && lng) {
            storeDispatch(setSelectedAlertInfo({ ...location, latLng: [lat, lng] }));
        } else {
            storeDispatch(setSelectedAlertInfo(null));
        }
        toggleModal();
    };

    useEffect(() => {
        if (!selectedTag) return;

        if (TAG_LABELS[selectedTag]) {
            setLocationList(tagStatus[selectedTag]);
            return;
        }

        const floor = floorList.find(({ floorName }) => floorName === selectedTag);
        if (!tagListByFloor[floor.floorId]) return;
        const result = tagListByFloor[floor.floorId].map(tag => {
            const {
                targetProperties,
                location: { lat, lng, floor },
            } = tag;
            return { ...targetProperties, lat, lng, floorId: floor };
        });
        setLocationList(result);
    }, [tagStatus, selectedTag, tagListByFloor, floorList]);

    return (
        <DraggableToast
            bounds={'.integrated-card'}
            initModal={initModal}
            toggleModal={toggleModal}
            title={
                <HeaderContainer>
                    <HeaderLabel className="bg-secondary" />
                    {TAG_LABELS[selectedTag] ? t(TAG_LABELS[selectedTag]) : selectedTag}
                </HeaderContainer>
            }
            className="draggable-toast-body-height"
            headerClassName="py-1 bg-lightgray"
            bodyClassName="list-toast-body"
            innerRef={searchRef}
        >
            {locationList.map(location => {
                const { targetName, department, jobtitle, rank, absenceName, floorId } = location;
                const title = `${rank || ''} ${targetName || '-'} (${jobtitle || ''}/${department || ''})`;
                return (
                    <Container key={targetName}>
                        <TitleContainer title={title}>{title}</TitleContainer>
                        <ButtonContainer>
                            {TAG_LABELS[selectedTag] === 'Absence' && <>{absenceName}</>}
                            <Button
                                className={cx('pnt-btn btn-lightgray', !floorId && 'color-lightgray')}
                                onClick={() => handleLocationPopup(location)}
                                disabled={!floorId}
                            >
                                {t('Location')}
                            </Button>
                        </ButtonContainer>
                    </Container>
                );
            })}
        </DraggableToast>
    );
};

export default ListModal;

const Container = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 3fr 1fr;
    border-bottom: 1px solid;
    justify-content: center;
    align-items: center;
`;

const TitleContainer = styled.div`
    border-right: 1px solid;
    padding: 1rem;
    text-align: center;

    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`;

const ButtonContainer = styled.div`
    display: grid;
    justify-content: center;
`;

const HeaderLabel = styled.div`
    width: 7px;
    height: 7px;
    margin-left: 10px;
`;

const HeaderContainer = styled.div`
    color: black !important;
    display: flex;
    align-items: center;
    gap: 0.4rem;
    padding: 0.6rem 0.4rem;
    font-weight: bold;
`;
