export const floor = ({ ...restProps } = {}) => {
    return {
        Header: 'Floor',
        headerClassName: 'text-ellipsis',
        accessor: 'floorName',
        className: 'text-ellipsis',
        ...restProps,
    };
};

export const completion = ({ ...restProps } = {}) => {
    return {
        Header: 'Completion',
        headerClassName: 'text-ellipsis',
        accessor: 'completion',
        className: 'text-ellipsis',
        ...restProps,
    };
};

export const incompletion = ({ ...restProps } = {}) => {
    return {
        Header: 'Incompletion',
        headerClassName: 'text-ellipsis',
        accessor: 'incompletion',
        className: 'text-ellipsis',
        ...restProps,
    };
};
