import React, { Fragment, useCallback, useContext, useEffect, useState } from 'react';
import Draggable from '@components/Draggable';
import { Toast, ToastBody, ToastHeader } from 'reactstrap';
import { SensorStatusDispatchContext, SensorStatusStateContext } from '../../../index';
import Table from '@components/Table';
import { useTranslation, useColumns } from '@hooks';
import * as column from '../../../../../../MainPages/util/grid/column';
import { setReportType, setSelectedSensor, setSelectedStatus } from '../../../sensorStatusReducer';

// 전체 상태 별 측정항목 수에서 각 상태를 눌렀을때 나오는 센서 항목 팝업
const SelectedSensorStatusListPopup = ({ modal, toggleModal, cardRef, selectedSensorStatusListInfo }) => {
    const t = useTranslation('SensorStatus');
    const commonColumnT = useTranslation('CommonColumn');

    const { selectedStatus, selectedSensorStatusList, sensingTypeOptions } = useContext(SensorStatusStateContext);
    const dispatch = useContext(SensorStatusDispatchContext);

    const [position, setPosition] = useState({
        x: 0,
        y: 0,
    });
    const [popupSize, setPopupSize] = useState({
        width: 0,
        height: 0,
    });

    const popupRef = useCallback(node => {
        if (node !== null) {
            const { width, height } = node.getBoundingClientRect();

            setPopupSize({
                width,
                height,
            });
        }
    }, []);

    const columns = useColumns(
        [
            column.targetName({
                width: 100,
            }),
            column.targetId({
                Header: 'Target Id',
                width: 100,
            }),
            column.floor({
                width: 60,
            }),
            column.registeredDate({
                width: 100,
            }),
            column.sensorItems({
                Cell: ({ value, row: original }) => {
                    if (value && value.length) {
                        return value.map((sensorItem, index) => {
                            const sensingType = sensingTypeOptions.find(
                                sensingType => sensingType.sCD === sensorItem.sensingType,
                            );
                            if (sensingType) {
                                return (
                                    <Fragment key={sensingType.sCD + index + original.targetNum}>
                                        <div className={'pnt-txt txt-border txt-bold custom-action-btn'}>
                                            {sensingType.sName}
                                        </div>
                                    </Fragment>
                                );
                            }
                        });
                    } else {
                        return <div>-</div>;
                    }
                },
            }),
        ],
        commonColumnT,
        [sensingTypeOptions],
    );

    useEffect(() => {
        const widgetPosition = getWidgetPosition();

        const x = (widgetPosition.width - popupSize.width) / 2 - 20;
        const y = -widgetPosition.height + 60;
        setPosition({
            x: x,
            y: y,
        });
    }, [popupSize]);

    const getWidgetPosition = () => {
        const widgetPosition = { x: 0, y: 0 };
        if (cardRef.current) {
            const container = cardRef.current;
            const { offsetWidth: width, offsetHeight: height } = container;

            widgetPosition.width = width;
            widgetPosition.height = height;
        }
        return widgetPosition;
    };

    const handleSensorClick = sensor => {
        dispatch(setSelectedSensor(sensor));
        dispatch(setReportType('daily'));
    };

    return (
        <Draggable key={JSON.stringify(position)} bounds=".sensor-status-container" defaultPosition={position}>
            <Toast isOpen={modal} className={'popup-open'} innerRef={popupRef}>
                <ToastHeader
                    toggle={() => {
                        dispatch(setSelectedStatus(null));
                        toggleModal();
                    }}
                >
                    [{t(selectedStatus)}] {t('Sensor Items')}
                </ToastHeader>
                <ToastBody>
                    <div style={{ padding: '0.5rem 0', height: '100%' }}>
                        <Table
                            loading={selectedSensorStatusListInfo.isLoading}
                            columns={columns}
                            data={selectedSensorStatusList}
                            paging={false}
                            onTrClick={handleSensorClick}
                        />
                    </div>
                </ToastBody>
            </Toast>
        </Draggable>
    );
};

export default SelectedSensorStatusListPopup;
