import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import BlockUi from 'react-block-ui';
import { Loader } from 'react-loaders';

const StyledBlockUi = styled(BlockUi)`
    & .block-ui-overlay {
        border-radius: 0.25rem;
    }
`;

export const LoadingBlockBorder = ({ blocking, children }) => {
    const [_blocking, setBlocking] = useState(false);
    useEffect(() => {
        if (_blocking === blocking) {
            return;
        }
        setBlocking(blocking);
    }, [blocking, _blocking, setBlocking]);
    return (
        <StyledBlockUi
            tag="div"
            blocking={_blocking}
            className="block-overlay-dark"
            loader={<Loader color="#ffffff" active type="line-scale" />}
        >
            {children}
        </StyledBlockUi>
    );
};

export const LoadingBlock = ({ blocking, children }) => {
    const [_blocking, setBlocking] = useState(false);
    useEffect(() => {
        if (_blocking === blocking) {
            return;
        }
        setBlocking(blocking);
    }, [blocking, _blocking, setBlocking]);
    return (
        <BlockUi
            tag="div"
            blocking={_blocking}
            className="block-overlay-dark"
            loader={<Loader color="#ffffff" active type="line-scale" />}
        >
            {children}
        </BlockUi>
    );
};
