import React, { useEffect, useMemo, useState } from 'react';

import useTranslation from '@hooks/useTranslation';
import { TimePicker, TextInput, Button, Select as ReactSelect } from '@components';

import FilterSearchGroup from '@components/FilterSearchGroup';
import {
    SelectGroup,
    SearchWrap,
    InputWrap,
    FilterList,
    InputGroup,
} from '@components/FilterSearchGroup/Components/Part';
import FilterGroup from '@components/FilterSearchGroup/Components/FilterGroup';
import SearchGroup from '@components/FilterSearchGroup/Components/SearchGroup';
import FilterButtonArea from '@components/FilterSearchGroup/Components/FilterButtonArea';

import moment from 'moment';

const Search = ({ getLocationLogList }) => {
    const t = useTranslation('Location Log');
    const [selectedOption, setSelectedOption] = useState({ value: '', label: t('Search Option', 'Search') });
    const searchOptions = useMemo(() => {
        return [
            { value: '', label: t('Search Option', 'Search') },
            { value: 'targetName', label: t('Target Name') },
            { value: 'tagName', label: t('Device Name') },
        ];
    }, [t]);
    const [searchInput, setSearchInput] = useState('');
    const [searchInputDisabled, setSearchInputDisabled] = useState(true);
    const [startDate, setStartDate] = useState(moment().startOf('day').valueOf());
    const [endDate, setEndDate] = useState(moment().endOf('day').valueOf());

    const handleSelectValueChange = value => {
        setSelectedOption(value);
        if (value.value !== '') {
            setSearchInputDisabled(false);
        } else {
            setSearchInputDisabled(true);
            setSearchInput('');
        }
    };
    const handleRefreshClick = e => {
        setSelectedOption(searchOptions[0]);
        setSearchInput('');

        const startDate = moment().startOf('day').valueOf();
        const endDate = moment().endOf('day').valueOf();

        setStartDate(startDate);
        setEndDate(endDate);

        setSearchInputDisabled(true);
        getLocationLogList({
            startDate: moment(startDate).unix(),
            endDate: moment(endDate).unix(),
        });
    };
    const handleSearchInputChange = e => {
        setSearchInput(e.target.value);
    };
    const handleSearchClick = () => {
        if (searchInput) {
            getLocationLogList({
                opt: selectedOption.value,
                keyword: searchInput,
                startDate: startDate ? moment(startDate).unix() : null,
                endDate: endDate ? moment(endDate).unix() : null,
            });
        } else {
            getLocationLogList({
                startDate: startDate ? moment(startDate).unix() : null,
                endDate: endDate ? moment(endDate).unix() : null,
            });
        }
    };
    const handleYesterdayButtonClick = () => {
        const start = moment().subtract(1, 'day').startOf('day').valueOf();
        const end = moment().subtract(1, 'day').endOf('day').valueOf();

        setStartDate(start);
        setEndDate(end);
    };

    useEffect(() => {
        getLocationLogList({
            startDate: moment(startDate).unix(),
            endDate: moment(endDate).unix(),
        });
    }, []);

    useEffect(() => {
        if (selectedOption) {
            setSelectedOption(searchOptions.find(v => v.value === selectedOption.value));
        }
    }, [searchOptions]);

    return (
        <FilterSearchGroup>
            <FilterGroup className="bg-brand" label={t('Search', 'Search')}>
                <FilterList>
                    <SelectGroup>
                        <div className={'datePicker-container'}>
                            <TimePicker
                                value={startDate}
                                handleChange={selected => setStartDate(selected)}
                                valueType="ms"
                                maxDate={endDate || moment.now()}
                                showTimeInput
                            />
                        </div>
                    </SelectGroup>
                    <div className={'text-center'}> ~ </div>
                    <SelectGroup>
                        <div className={'datePicker-container'}>
                            <TimePicker
                                value={endDate}
                                handleChange={selected => setEndDate(selected)}
                                valueType="ms"
                                minDate={startDate}
                                maxDate={moment.now()}
                                showTimeInput
                            />
                        </div>
                    </SelectGroup>
                    <div className="d-flex gap-1 justify-content-end">
                        <Button
                            className="btn-secondary btn-yesterday"
                            style={{ width: 'fit-content' }}
                            onClick={handleYesterdayButtonClick}
                        >
                            {t('Yesterday', 'Button')}
                        </Button>
                        <Button className="btn-danger btn-icon-only" iconName="replay" onClick={handleRefreshClick} />
                    </div>
                </FilterList>
                <FilterButtonArea />
            </FilterGroup>
            <SearchGroup className="bg-secondary" label={t('Filter', 'Filter')}>
                <SearchWrap>
                    <SelectGroup>
                        <ReactSelect
                            name="selectInput"
                            placeholder={t('Search Option', 'Search')}
                            value={selectedOption}
                            options={searchOptions}
                            onChange={handleSelectValueChange}
                            customControlStyles={{ width: '100%' }}
                            customMenuStyles={{ width: '100%' }}
                            customOptionStyles={{ width: '100%' }}
                        />
                    </SelectGroup>
                    <InputWrap>
                        <InputGroup>
                            <TextInput
                                type="text"
                                size={60}
                                placeholder={t(
                                    'Please Enter your search keyword after selecting search option.',
                                    'Search',
                                )}
                                name="name"
                                value={searchInput}
                                handleChange={handleSearchInputChange}
                                disabled={searchInputDisabled}
                            />
                        </InputGroup>
                        <Button className="btn-brand btn-icon" iconName="search" onClick={handleSearchClick}>
                            {t('Search', 'Search')}
                        </Button>
                    </InputWrap>
                </SearchWrap>
            </SearchGroup>
        </FilterSearchGroup>
    );
};

export default Search;
