import React, { Children, createElement, isValidElement } from 'react';

import { CardBodyProps, DefaultProps, ClickableProps, CardTableProps } from './type';

import Table from './PagingButtonCustomTable';
import ClickableTable from './ClickableTable';

import { CompoundCardBodyContainer, CompoundCardContentsContainer, CompoundCardTableContainer } from './styles';

const CompoundCardPlain = ({ children }: DefaultProps) => {
    return <>{children}</>;
};

const CompoundCardContents = ({
    children,
    handleClick,
    title,
    clicked = false,
    hoverColor = null,
    clickedColor = null,
}: ClickableProps) => {
    const clickable = typeof handleClick === 'function';
    return (
        <CompoundCardContentsContainer
            $clickable={clickable}
            $clicked={clickable && !!clicked}
            onClick={handleClick}
            title={title}
            $hoverColor={hoverColor}
            $clickedColor={clickedColor}
        >
            {children}
        </CompoundCardContentsContainer>
    );
};

const CompoundCardTable = ({
    containerClassName,
    data,
    columns = [],
    paging = true,
    onTrClick,
    selectedRow,
    selectedId,
    loading,
    onPageChange,
}: CardTableProps) => {
    return (
        <CompoundCardTableContainer className={containerClassName}>
            {/*@ts-ignore*/}
            <Table
                data={data}
                paging={paging}
                columns={columns}
                onTrClick={onTrClick}
                onPageChange={onPageChange}
                selectedRow={selectedRow}
                selectedId={selectedId}
                loading={loading}
            />
        </CompoundCardTableContainer>
    );
};

const CardBodyList = [
    createElement(CompoundCardPlain),
    createElement(CompoundCardContents),
    createElement(CompoundCardTable),
    createElement(ClickableTable),
];

const CompoundCardBodyMain = ({ children, className, title, style }: CardBodyProps) => {
    const cardBody = Children.toArray(children).filter(child => {
        if (isValidElement(child)) {
            return !!CardBodyList.find(body => child.type === body.type);
        }
        return false;
    });
    return (
        <CompoundCardBodyContainer className={className} title={title} style={style}>
            {!!cardBody && cardBody}
        </CompoundCardBodyContainer>
    );
};

export default CompoundCardBodyMain;
export { CompoundCardPlain, CompoundCardContents, CompoundCardTable };
