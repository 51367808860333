export const name = ({ ...restProps } = {}) => {
    return {
        Header: 'Name',
        accessor: 'targetName',
        headerClassName: 'text-ellipsis',
        className: 'text-ellipsis',
        Cell: ({ value }) => {
            return value || '-';
        },
        ...restProps,
    };
};

export const department = ({ ...restProps } = {}) => {
    return {
        Header: 'Department',
        accessor: 'department',
        headerClassName: 'text-ellipsis',
        className: 'text-ellipsis',
        Cell: ({ value }) => {
            return value || '-';
        },
        ...restProps,
    };
};

export const jobtitle = ({ ...restProps } = {}) => {
    return {
        Header: 'Jobtitle',
        accessor: 'jobtitle',
        headerClassName: 'text-ellipsis',
        className: 'text-ellipsis',
        Cell: ({ value }) => {
            return value || '-';
        },
        ...restProps,
    };
};

export const status = ({ ...restProps } = {}) => {
    return {
        Header: 'Note',
        accessor: 'status',
        headerClassName: 'text-ellipsis',
        className: 'text-ellipsis',
        Cell: ({ value }) => {
            return value || '-';
        },
        ...restProps,
    };
};

export const rank = ({ ...restProps } = {}) => {
    return {
        Header: 'Rank',
        accessor: 'rank',
        headerClassName: 'text-ellipsis',
        className: 'text-ellipsis',
        Cell: ({ value }) => {
            return value || '-';
        },
        ...restProps,
    };
};
