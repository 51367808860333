import React, { useContext } from 'react';

import { useTranslation, useColumns } from '@hooks';

import CompoundCard from '../../../Components/CompoundCard';

import { HealthInformationProvisionStatusContext } from '../index';

import * as column from './column';

const HealthInformation = ({ isLoading, handlePageChange }) => {
    const t = useTranslation('Health Information Provision');

    const { healthInfoList, filterOptions } = useContext(HealthInformationProvisionStatusContext);
    const { rank: rankOptions, department: departmentOptions } = filterOptions;

    const columns = useColumns(
        [
            column.id(),
            column.name(),
            column.rank({
                formatTitle: ({ value }) => {
                    const label = rankOptions && value ? rankOptions.find(v => v.value === value)['label'] : null;
                    return label || value;
                },
                Cell: ({ value }) => {
                    const label = rankOptions && value ? rankOptions.find(v => v.value === value)['label'] : null;
                    return label || value;
                },
            }),
            column.department({
                formatTitle: ({ value }) => {
                    const label =
                        departmentOptions && value ? departmentOptions.find(v => v.value === value)['label'] : null;
                    return label || value;
                },
                Cell: ({ value }) => {
                    const label =
                        departmentOptions && value ? departmentOptions.find(v => v.value === value)['label'] : null;
                    return label || value;
                },
            }),
            column.state({
                formatTitle: ({ value }) => {
                    return value === 'Y' ? t('Agreement') : t('Disagreement');
                },
                Cell: ({ value }) => {
                    return value === 'Y' ? (
                        <div className="text-white p-2 bg-success">{t('Agreement')}</div>
                    ) : (
                        <div className="text-white p-2 bg-danger">{t('Disagreement')}</div>
                    );
                },
            }),
            column.heartbeat(),
            column.sleepingTime(),
            column.steps(),
            column.distance(),
            column.calories(),
            column.stress(),
        ],
        t,
        [filterOptions],
    );

    return (
        <CompoundCard className="mt-2 d-flex flex-column" style={{ height: 'calc(100% - 8rem)' }}>
            <CompoundCard.header>{t('Status Of Health Information Provision')}</CompoundCard.header>
            <CompoundCard.body className="h-100 pt-2">
                <CompoundCard.body.table
                    containerClassName="health-information-table"
                    columns={columns}
                    data={healthInfoList}
                    paging={true}
                    onPageChange={handlePageChange}
                    loading={isLoading}
                />
            </CompoundCard.body>
        </CompoundCard>
    );
};

export default HealthInformation;
