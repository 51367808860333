import { createSlice } from '@reduxjs/toolkit';

import moment from 'moment';

export const initialState = {
    searchInput: '',
    searchInputOption: null,
    startDate: moment().subtract(6, 'day').valueOf(),
    endDate: moment().valueOf(),
    selectedFilterOption: {
        bookmark: false,
        category: { label: 'People', value: 'PEOPLE' },
    },
    filterOptions: {
        category: [{ label: 'People', value: 'PEOPLE' }],
        rank: null,
        department: null,
        jobtitle: null,
        status: null,
    },
    selectedTarget: null,
    sensorData: {},
    healthCareList: { rows: [] },
    isHealthCareListLoading: false,
};

const { actions, reducer } = createSlice({
    name: 'healthCareMonitoring',
    initialState,
    reducers: {
        initialize: state => {
            state.searchInput = '';
            state.searchInputOption = null;
            state.startDate = moment().subtract(6, 'day').valueOf();
            state.endDate = moment().valueOf();
            state.selectedFilterOption = {
                bookmark: false,
                category: { label: 'People', value: 'PEOPLE' },
            };
            state.sensorData = {};
            state.selectedTarget = null;
        },
        setSearchInput: (state, action) => {
            state.searchInput = action.payload;
        },
        setSearchInputOption: (state, action) => {
            state.searchInputOption = action.payload;
        },
        setStartDate: (state, action) => {
            state.startDate = action.payload;
        },
        setEndDate: (state, action) => {
            state.endDate = action.payload;
        },
        setFilterOptions: (state, action) => {
            const { key, value } = action.payload;
            state.filterOptions[key] = value;
        },
        setSelectedFilterOption: (state, action) => {
            state.selectedFilterOption = action.payload;
        },
        setSelectedTarget: (state, action) => {
            state.selectedTarget = action.payload;
        },
        setSensorData: (state, action) => {
            state.sensorData = action.payload;
        },
        setInitialSensorData: state => {
            state.sensorData = {};
        },
        setHealthCareList: (state, action) => {
            state.healthCareList = action.payload;
        },
        setIsHealthCareListLoading: (state, action) => {
            state.isHealthCareListLoading = action.payload;
        },
    },
});

export const {
    initialize,
    setSearchInput,
    setSearchInputOption,
    setStartDate,
    setEndDate,
    setFilterOptions,
    setSelectedFilterOption,
    setSelectedTarget,
    setSensorData,
    setInitialSensorData,
    setHealthCareList,
    setIsHealthCareListLoading,
} = actions;
export default reducer;
