import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Col, Row } from 'reactstrap';
import RealtimeBarChart from './RealtimeBarChart';

const UsedNumSpan = styled.span`
    color: ${props => props.color};
    font-size: 2rem;
    font-weight: bold;
`;

const RealtimeUtilization = ({ data = [] }) => {
    let totalNum = data.reduce((a, b) => {
        return a + b.statusCount;
    }, 0);

    const { t } = useTranslation();
    return (
        <>
            <div className={'borderBottom'}>
                <Row>
                    <Col xl={'4'}>
                        <UsedNumSpan
                            color={data[0] ? data[0].displayColor : '#3E66FB'}
                            className={'exclude-font-theme'}
                        >
                            {data[0] ? data[0].statusCount : 0}
                        </UsedNumSpan>
                        <span className={'totalNum'}>/{totalNum}</span>
                    </Col>
                    <Col xl={'8'}>
                        <RealtimeBarChart data={data} totalNum={totalNum} />
                    </Col>
                </Row>
            </div>
            <div className={'d-flex align-items-center justify-content-between mb-4'}>
                <div className={'w-20 d-flex justify-content-between'}>
                    <span>{t('Overview;Etc.')}</span>
                    <div>
                        <span className={'totalEtc exclude-font-theme'}>
                            {totalNum - (data[0] ? data[0].statusCount : 0)}
                        </span>
                        <span className={'totalNumEtc exclude-font-theme'}>/{totalNum}</span>
                    </div>
                </div>
                <div className="w-60 am__graph--horizon-line__label">
                    {data &&
                        data.map(({ statusCount, statusName, displayColor }, index) => {
                            return (
                                <div className="label-elem" key={index}>
                                    <div className="flx-row">
                                        <span className="label-bg" style={{ backgroundColor: displayColor }} />
                                        <span className="pnt-txt txt-bold">{statusCount}</span>
                                    </div>
                                    <div className="label-small">{statusName}</div>
                                </div>
                            );
                        })}
                </div>
            </div>
        </>
    );
};

export default RealtimeUtilization;
