import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import moment from 'moment';

import { Alert, NotificationState, CheckedInterfaceCommandType, SelectedAlertInfo } from './types';

const initialState: NotificationState = {
    alert: null,
    toastConfig: {
        isAlert: true,
        limit: 5,
        autoClose: 5000,
    },
    checkedInterfaceCommandType: [],
    selectedAlertInfo: null,
};

const { actions, reducer } = createSlice({
    name: 'notification',
    initialState,
    reducers: {
        setAlert: {
            reducer: (state, action: PayloadAction<Alert>) => {
                if (state.checkedInterfaceCommandType.includes(action.payload.interfaceCommandType)) {
                    state.alert = action.payload;
                }
            },
            prepare: ({
                notificationLog: {
                    targetName,
                    interfaceCommandType,
                    regDate,
                    targetNum,
                    floor: floorId,
                    floorName,
                    lat,
                    lng,
                    sensingType,
                    eventDataMap,
                },
            }) => {
                const alertDate = moment(regDate * 1000).format('YYYY-MM-DD HH:mm:ss');
                const sensingValue = eventDataMap ? eventDataMap.currentValue || 0 : 0;
                return {
                    payload: {
                        alertDate,
                        floorId,
                        floorName,
                        interfaceCommandType,
                        targetName,
                        targetNum,
                        lat,
                        lng,
                        sensingType,
                        sensingValue,
                    },
                };
            },
        },
        setCheckedInterfaceCommandType: (state, action: PayloadAction<Array<CheckedInterfaceCommandType>>) => {
            state.checkedInterfaceCommandType = action.payload.reduce(
                (acc: Array<string>, cur: CheckedInterfaceCommandType) => {
                    if (cur.isChecked === 'Y') {
                        acc.push(cur.interfaceCommandType);
                    }
                    return acc;
                },
                [],
            );
        },
        setSelectedAlertInfo: (state, action: PayloadAction<SelectedAlertInfo | null>) => {
            state.selectedAlertInfo = action.payload;
        },
    },
});

export const { setAlert, setCheckedInterfaceCommandType, setSelectedAlertInfo } = actions;
export default reducer;
