import React, { useContext, useEffect, useState } from 'react';

import { Button, Card, Table } from '@components';

import { useTranslation, useAsync, useColumns } from '@hooks';

import { getAssetListApi } from '@api/asset';

import { makeFilterParam } from '../reducer';
import { FilterDetailStateContext } from './index';

import * as col from '../../util/grid/column/filter';

const PREVIEW_LIST_PAGE_SIZE = 10;
const tableInitData = { rows: [], pageSize: PREVIEW_LIST_PAGE_SIZE };
const FilterPreview = () => {
    const { editing, editMode } = useContext(FilterDetailStateContext);
    const t = useTranslation('Filter');
    const buttonT = useTranslation('Button');
    const [assetListInfo, setAssetListInfo] = useState(tableInitData);
    const [beforeFilterNum, setBeforeFilterNum] = useState(0);

    const assetListColumns = useColumns([col.category(), col.name(), col.num(), col.itemOwner(), col.location()], t);

    const {
        promise: getAssetList,
        state: { isLoading: assetListLoading },
    } = useAsync({
        promise: getAssetListApi,
        fixedParam: { pageSize: PREVIEW_LIST_PAGE_SIZE, categoryCodes: editing.categoryCodes },
        resolve: res => {
            let listData = tableInitData;
            if (res && res.rows) {
                listData = res;
            }
            setAssetListInfo({ ...listData, pageSize: PREVIEW_LIST_PAGE_SIZE });
        },
    });

    const handlePageChange = pageIndex => {
        getAssetList({
            ...makeFilterParam(editing.filterInfoCondition, editing.categoryCodes),
            pageSize: PREVIEW_LIST_PAGE_SIZE,
            page: pageIndex,
        });
    };

    useEffect(() => {
        if (editing.filterNum && editing.filterNum !== beforeFilterNum) {
            getAssetList({
                ...makeFilterParam(editing.filterInfoCondition, editing.categoryCodes),
                pageSize: PREVIEW_LIST_PAGE_SIZE,
            });
        }
        return () => {
            if (editing.filterNum) {
                setBeforeFilterNum(editing.filterNum);
            }
        };
    }, [editing]);

    return (
        <Card
            header={{
                title: t('Preview of search result'),
                subTitle: t('This function shows the list of assets that fits the filter conditions.'),
                action: editMode ? (
                    <>
                        <Button
                            className="btn-icon btn-secondary"
                            onClick={() => {
                                getAssetList({
                                    ...makeFilterParam(editing.filterInfoCondition, editing.categoryCodes),
                                    pageSize: PREVIEW_LIST_PAGE_SIZE,
                                });
                            }}
                            iconName="refresh"
                        >
                            {buttonT('Refresh')}
                        </Button>
                    </>
                ) : null,
            }}
        >
            <div style={{ height: '300px' }}>
                <Table
                    data={assetListInfo}
                    columns={assetListColumns}
                    onPageChange={handlePageChange}
                    loading={assetListLoading}
                />
            </div>
        </Card>
    );
};

export default FilterPreview;
