import React, { useState } from 'react';

import { fetchCategory } from 'api/asset';
import { useSelector } from 'react-redux';

import { useTranslation, useAsync } from 'util/hooks';
import { TreeSelect } from 'Components/Common';

const InspectionCategorySearchableSelect = ({ selected, handleChange, hideOption }) => {
    const t = useTranslation('AssetInspectionList');

    const { serviceCategory } = useSelector(state => state.CategoryInfo);

    const [categoryList, setCategoryList] = useState([]);

    useAsync({
        promise: fetchCategory,
        fixedParam: { isAll: 'Y', parentCode: serviceCategory },
        immediate: true,
        resolve: res => {
            const filteredRows = res.rows.filter(
                ({ categoryCode, parentCode }) =>
                    (categoryCode && categoryCode.toUpperCase() === 'ASSET') ||
                    (parentCode && parentCode.toUpperCase() === 'ASSET'),
            );
            setCategoryList(filteredRows);

            const initialList = filteredRows.map(({ categoryCode }) => categoryCode);
            handleChange(initialList);
        },
    });

    return (
        <TreeSelect
            data={categoryList}
            title={t('Asset Categorization')}
            valueKey={'categoryCode'}
            parentKey={'parentCode'}
            labelKey={'categoryName'}
            selected={selected}
            onChange={handleChange}
            disabled={hideOption}
        />
    );
};

export default InspectionCategorySearchableSelect;
