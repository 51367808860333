import React from 'react';
import ConfirmModal from '@components/ConfirmModal';
import useTranslation from '@hooks/useTranslation';

const LogoutModal = ({ initModal, toggleModal, handleLogout }) => {
    const t = useTranslation('ConfirmModal');

    return (
        <ConfirmModal
            header={{ title: t('Logout') }}
            confirmText={t('Do you want to log out?')}
            initModal={initModal}
            toggleModal={toggleModal}
            okCallback={handleLogout}
        />
    );
};

export default LogoutModal;
