import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Card, Button, Table } from 'Components/Common';
import { useTranslation, useAsync, useColumns } from '@hooks';

import { getFlowSchemeListInfoApi, getMappedStatusListInfoApi, getStatusSchemeListInfoApi } from 'api/status';

import StatusSchemeDetail from './StatusSchemeDetail';
import { setFlowSchemeListInfo, setMappedStatusListInfo, setStatusSchemeListInfo } from 'reducers/Common/StatusInfo';

import { StyledStatusLabel } from '../../../Components/Grid/column';
import TransitionBatchModal from './TransitionBatchModal';

const StatusScheme = () => {
    const dispatch = useDispatch();
    const t = useTranslation('Status');
    const { statusSchemeListInfo } = useSelector(state => state.StatusInfo);
    const [detailModal, setDetailModal] = useState({ isOpen: false, statusInfo: null });
    const toggleDetailModal = statusInfo => {
        setDetailModal({
            isOpen: !detailModal.isOpen,
            statusInfo,
        });
    };

    const [transitionBatchModal, setTransitionBatchModal] = useState(false);
    const toggleTransitionBatchModal = () => {
        setTransitionBatchModal(!transitionBatchModal);
    };

    const statusColumns = useColumns(
        [
            {
                Header: 'Name',
                accessor: 'schemeName',
                className: 'flex-center',
                Cell: ({ value, row: { original } }) => {
                    return (
                        <div
                            style={{
                                display: 'grid',
                                rowGap: '5px',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <span style={{ fontWeight: 'bold' }}>{value}</span>
                            <span style={{ fontSize: '.7rem' }}>{original.description}</span>
                        </div>
                    );
                },
            },
            {
                Header: 'Status',
                accessor: 'mappedStatus',
                className: 'flex-center flex-wrap',
                style: { gap: '5px 10px' },
                Cell: ({ value }) => {
                    if (value) {
                        return value.map(v => (
                            <StyledStatusLabel
                                key={v.statusNum}
                                statusName={v.statusName}
                                displayColor={v.displayColor}
                            />
                        ));
                    }
                    return null;
                },
            },
            {
                Header: 'Status Flow Scheme',
                accessor: 'flowName',
                className: 'flex-center',
                Cell: ({ value }) => {
                    return value || null;
                },
            },
            {
                Header: 'Edit',
                accessor: 'schemeNum',
                className: 'flex-center',
                width: 50,
                Cell: ({ row: { original } }) => {
                    return (
                        <Button
                            className="btn-icon-only btn-brand scale-08"
                            iconName="border_color"
                            onClick={() => {
                                toggleDetailModal(original);
                            }}
                        />
                    );
                },
            },
        ],
        t,
    );

    const { promise: getStatusSchemeListInfo } = useAsync({
        promise: getStatusSchemeListInfoApi,
        fixedParam: { isAll: 'Y' },
        resolve: res => {
            dispatch(setStatusSchemeListInfo(res));
        },
    });

    const { promise: getMappedStatusListInfo } = useAsync({
        promise: getMappedStatusListInfoApi,
        fixedParam: { isAll: 'Y' },
        resolve: res => {
            dispatch(setMappedStatusListInfo(res));
        },
    });

    const { promise: getFlowSchemeListInfo } = useAsync({
        promise: getFlowSchemeListInfoApi,
        fixedParam: { isAll: 'Y' },
        resolve: res => {
            dispatch(setFlowSchemeListInfo(res));
        },
    });

    useEffect(() => {
        getStatusSchemeListInfo();
        getMappedStatusListInfo();
        getFlowSchemeListInfo();
    }, []);

    return (
        <div>
            <Card
                header={{
                    title: `${t('List')} : ${t('Status Scheme')}`,
                    subTitle: t('This is the asset status scheme list inquiry function.'),
                    action: (
                        <>
                            <Button className="btn-secondary fs-6" onClick={toggleTransitionBatchModal}>
                                {t('Status batch transition')}
                            </Button>
                            <Button
                                className="btn-brand fs-6"
                                iconName="add"
                                onClick={() => {
                                    toggleDetailModal(null);
                                }}
                            >
                                {t('Add Status scheme')}
                            </Button>
                        </>
                    ),
                }}
            >
                <div
                    style={{
                        minHeight: '300px',
                        maxHeight: 'calc(100vh - 340px)',
                        height: 'calc(100vh - 340px)',
                        display: 'grid',
                        gridTemplateRows: 'min',
                    }}
                >
                    <Table
                        data={{ ...statusSchemeListInfo, pageSize: statusSchemeListInfo.totalCount }}
                        columns={statusColumns}
                        paging={false}
                        dynamicRowHeight
                    />
                </div>
            </Card>
            <StatusSchemeDetail
                isOpen={detailModal.isOpen}
                statusInfo={detailModal.statusInfo}
                toggle={toggleDetailModal}
            />
            <TransitionBatchModal isOpen={transitionBatchModal} toggle={toggleTransitionBatchModal} />
            {/*<ConfirmModal initModal={detailModal} toggleModal={toggleDetailModal} confirmText={<>test</>} />*/}
        </div>
    );
};

export default StatusScheme;
