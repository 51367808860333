import React, { useState, Fragment } from 'react';

import { useTranslation, useConfirmModal } from 'util/hooks';
import { Button, TextInput } from 'Components/Common';
import {
    SelectGroup,
    SearchWrap,
    InputGroup,
    FilterList,
    InputWrap,
} from 'Components/Common/FilterSearchGroup/Components/Part';
import FilterSearchGroup from 'Components/Common/FilterSearchGroup';
import FilterGroup from 'Components/Common/FilterSearchGroup/Components/FilterGroup';
import SearchGroup from 'Components/Common/FilterSearchGroup/Components/SearchGroup';
import FilterButtonArea from 'Components/Common/FilterSearchGroup/Components/FilterButtonArea';
import SearchButtonArea from '@components/FilterSearchGroup/Components/SearchButtonArea';

const Search = ({ searchInputPlaceholder, handleSearchClick, handleRefreshClick, children }) => {
    const t = useTranslation('Search');

    const [searchInput, setSearchInput] = useState('');

    const handleSearchInputChange = e => {
        setSearchInput(e.target.value);
    };

    const handleRefreshBtnClick = () => {
        setSearchInput('');
        handleRefreshClick();
    };

    const { toggleModal: toggleRefreshModal, Modal: RefreshModal } = useConfirmModal({
        confirmText: t('Do you want to refresh?'),
        okCallback: () => {
            handleRefreshBtnClick();
        },
    });

    return (
        <>
            <FilterSearchGroup>
                <FilterGroup className="bg-brand" label={t('Filter', 'Filter')}>
                    <FilterList>
                        {children.map((child, idx) => {
                            if (idx === 1) {
                                return (
                                    <Fragment key={child.type.name + idx}>
                                        <SelectGroup>{child}</SelectGroup>
                                        <div className="text-center"> ~ </div>
                                    </Fragment>
                                );
                            }
                            return <SelectGroup key={child.type.name + idx}>{child}</SelectGroup>;
                        })}
                    </FilterList>
                    <FilterButtonArea />
                </FilterGroup>
                <SearchGroup className="bg-secondary" label={t('Search', 'Search')}>
                    <SearchWrap>
                        <InputWrap>
                            <InputGroup>
                                <TextInput
                                    type="text"
                                    placeholder={searchInputPlaceholder}
                                    name={'name'}
                                    value={searchInput}
                                    handleChange={handleSearchInputChange}
                                />
                            </InputGroup>
                            <Button
                                className="btn-brand btn-icon"
                                iconName="search"
                                onClick={() => handleSearchClick(searchInput)}
                            >
                                {t('Search', 'Search')}
                            </Button>
                            <Button className="btn-danger" iconName="refresh" onClick={toggleRefreshModal} />
                        </InputWrap>
                    </SearchWrap>
                </SearchGroup>
            </FilterSearchGroup>
            <RefreshModal />
        </>
    );
};

export default Search;
