import React, { useMemo } from 'react';
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Text, XAxis, YAxis } from 'recharts';
import { useTranslation } from 'react-i18next';
const color = ['#3E66FB', '#3acc5c', '#ff3333', '#ffc107', '#cccccc'];

const CustomizedXaxis = () => {
    const { t } = useTranslation();
    return (
        <Text x={230} y={210} style={{ fontSize: '0.8rem' }}>
            {t('Overview;count of assets')}
        </Text>
    );
};

const CustomizedYTick = ({ x, y, dx, width, height, fill, stroke, style, textAnchor, verticalAnchor, payload }) => {
    const positionProps = { x, y, dx, width, height, fill, stroke, style, textAnchor, verticalAnchor };
    return (
        // 15 = barSize, 7.5 = barSize / 2, 위치 정보 그대로 foreignObject에 먹일 경우 마지막 tick이 덮어 씌워져서 마지막 tooltip만 작동함
        <foreignObject {...positionProps} x={0} y={y - 7.5} height={15}>
            <div className={'text-ellipsis'} style={{ width: '100%' }} title={payload.value}>
                {payload.value}
            </div>
        </foreignObject>
    );
};

const AvailableBarChart = ({ data, statusList }) => {
    // const assetStatus = [
    //     { name: t('Overview;In use'), value: 'inUse' },
    //     { name: t('Overview;Available'), value: 'available' },
    //     { name: t('Overview;Broken'), value: 'broken' },
    //     { name: t('Overview;Repair'), value: 'repair' },
    //     { name: t('Overview;Others'), value: 'others' },
    // ];
    const assetStatus = useMemo(() => {
        return statusList.map(status => ({ name: status.statusName, value: status.statusNum }));
    }, [statusList]);

    const renderLegend = (value, entry) => {
        const { color } = entry;
        let nameObj = assetStatus.find(status => status.value === value);
        return (
            <span
                style={{
                    color: color,
                    display: 'inline-block',
                    fontSize: '0.7rem',
                    maxWidth: '70px',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                }}
                title={nameObj.name}
            >
                {' '}
                {nameObj.name}
            </span>
        );
    };
    return (
        <ResponsiveContainer width={'100%'}>
            <BarChart data={data} width={570} height={200} layout="vertical">
                <Legend
                    wrapperStyle={{
                        paddingLeft: '20px',
                        height: '100%',
                        overflow: 'auto',
                    }}
                    layout="vertical"
                    iconType="square"
                    align="right"
                    verticalAlign="top"
                    formatter={renderLegend}
                />
                <CartesianGrid strokeDasharray="2" />
                <XAxis
                    style={{ fontSize: '0.7rem' }}
                    stroke="#b5b5b5"
                    tickLine={false}
                    type="number"
                    // label={<CustomizedXaxis />}
                />
                <YAxis
                    dataKey="name"
                    type="category"
                    tickLine={false}
                    style={{ fontSize: '0.7rem', fill: '#000000' }}
                    dx={-5}
                    stroke="#b5b5b5"
                    tick={<CustomizedYTick />}
                />
                {statusList.map(status => {
                    return (
                        <Bar
                            key={status.statusNum}
                            dataKey={status.statusNum}
                            stackId={'a'}
                            fill={status.displayColor}
                            barSize={15}
                        />
                    );
                })}
                {/*<Bar dataKey="inUse" stackId="a" fill={color[0]} barSize={15} />*/}
                {/*<Bar dataKey="available" stackId="a" fill={color[1]} barSize={15} />*/}
                {/*<Bar dataKey="broken" stackId="a" fill={color[2]} barSize={15} />*/}
                {/*<Bar dataKey="repair" stackId="a" fill={color[3]} barSize={15} />*/}
                {/*<Bar dataKey="others" stackId="a" fill={color[4]} barSize={15} />*/}
            </BarChart>
        </ResponsiveContainer>
    );
};

export default AvailableBarChart;
