import React from 'react';
import moment from 'moment';
import styled from 'styled-components';

const StyledStatus = styled.span`
    height: 25px;
    color: ${props => props.color} !important;
    font-weight: bold;
`;

// Location-Log Table
export const number = ({ ...restProps } = {}) => {
    return {
        Header: 'No.',
        headerClassName: 'flex-center',
        accessor: 'logNum',
        className: 'flex-center',
        width: 80,
        ...restProps,
    };
};

export const targetName = ({ ...restProps } = {}) => {
    return {
        Header: 'Target Name',
        headerClassName: 'text-center text-ellipsis',
        accessor: 'targetName',
        className: 'text-center text-ellipsis',
        ...restProps,
    };
};

export const deviceName = ({ ...restProps } = {}) => {
    return {
        Header: 'Device Name',
        headerClassName: 'flex-center',
        accessor: 'tagName',
        className: 'text-center text-ellipsis',
        ...restProps,
    };
};

export const floor = ({ ...restProps } = {}) => {
    return {
        Header: 'Floor',
        headerClassName: 'text-ellipsis',
        accessor: 'floorName',
        className: 'text-ellipsis',
        ...restProps,
    };
};

export const location = ({ handleLatLngClick, t, ...restProps } = {}) => {
    return {
        Header: 'Location',
        headerClassName: 'flex-center',
        accessor: 'latLng',
        className: 'flex-center',
        width: 250,
        Cell: ({ value, row }) => {
            let [lat, lng] = value;
            const { original } = row;

            lat = parseFloat(lat).toFixed(6);
            lng = parseFloat(lng).toFixed(6);

            return (
                <>
                    <div
                        className={'pnt-txt txt-border txt-bold custom-action-btn'}
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                            if (typeof handleLatLngClick === 'function') {
                                handleLatLngClick(original);
                            }
                        }}
                    >
                        {t('Latitude')} {lat}
                    </div>
                    <div
                        className={'pnt-txt txt-border txt-bold custom-action-btn'}
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                            if (typeof handleLatLngClick === 'function') {
                                handleLatLngClick(original);
                            }
                        }}
                    >
                        {t('Longitude')} {lng}
                    </div>
                </>
            );
        },
        t,
        ...restProps,
    };
};

export const registeredDate = ({ ...restProps } = {}) => {
    return {
        Header: 'Registered Date',
        headerClassName: 'text-center text-ellipsis',
        accessor: 'regDate',
        className: 'text-center text-ellipsis',
        formatTitle: ({ value }) => {
            return value ? moment.unix(value).format('YYYY-MM-DD HH:mm:ss') : '-';
        },
        Cell: ({ value }) => {
            return value ? moment.unix(value).format('YYYY-MM-DD HH:mm:ss') : '-';
        },
        ...restProps,
    };
};

//Location-Log-File Table
export const downloadLog = ({ t, ...restProps }) => {
    return {
        Header: 'Download',
        headerClassName: 'flex-center',
        className: 'flex-center',
        accessor: 'filePath',
        Cell: ({ value, row }) => {
            const { original } = row;
            if (original.dataSettingStatusType !== 'COMPLETED') {
                return <div className={'pnt-txt txt-bold'}>-</div>;
            } else {
                return (
                    <a href={value} download={original.fileName}>
                        <div className={'pnt-txt txt-border txt-bold custom-action-btn'} style={{ cursor: 'pointer' }}>
                            {t('Download')}
                        </div>
                    </a>
                );
            }
        },
        t,
        ...restProps,
    };
};

export const reqNum = ({ ...restProps } = {}) => {
    return {
        Header: 'No.',
        headerClassName: 'text-center',
        className: 'text-center',
        accessor: 'reqNum',
        ...restProps,
    };
};

export const fileName = ({ ...restProps } = {}) => {
    return {
        Header: 'File Name',
        headerClassName: 'text-center',
        className: 'text-center text-ellipsis',
        accessor: 'fileName',
        ...restProps,
    };
};

export const dataStatus = ({ t, ...restProps } = {}) => {
    return {
        Header: 'Data Status',
        headerClassName: 'text-center',
        className: 'text-center',
        accessor: 'dataSettingStatusType',
        Cell: ({ value, row }) => {
            if (value === 'COMPLETED')
                return (
                    <div className={'pnt-txt txt-border txt-bold'} style={{ color: '#000' }}>
                        {t('COMPLETED')}
                    </div>
                );
            else if (value === 'FAILED')
                return (
                    <div className={'pnt-txt txt-border txt-bold'} style={{ color: '#ff3333' }}>
                        {t('FAILED')}
                    </div>
                );
            else if (value === 'APPENDING')
                return (
                    <div className={'pnt-txt txt-border txt-bold'} style={{ color: '#FFC107' }}>
                        {t('APPENDING')}
                    </div>
                );
            else if (value === 'PROGRESSING') {
                return (
                    <div className={'pnt-txt txt-border txt-bold'} style={{ color: '#3E66FB' }}>
                        {t('PROGRESSING')}
                    </div>
                );
            }
        },
        t,
        ...restProps,
    };
};
// geofence log table
export const categoryName = ({ ...restProps } = {}) => {
    return {
        Header: 'Category Name',
        headerClassName: 'text-center',
        className: 'text-center text-ellipsis',
        accessor: 'categoryCode',
    };
};
export const targetId = ({ ...restProps } = {}) => {
    return {
        Header: 'Target ID',
        headerClassName: 'text-center',
        className: 'text-center text-ellipsis',
        accessor: 'targetId',
    };
};
export const geofence = ({ ...restProps } = {}) => {
    return {
        Header: 'Geofence',
        headerClassName: 'text-center',
        className: 'text-center text-ellipsis',
        accessor: 'fcName',
    };
};
export const authorizedNotAuthorized = ({ ...restProps } = {}) => {
    return {
        Header: 'Authorized Not Authorized',
        headerClassName: 'text-center text-ellipsis',
        className: 'text-center text-ellipsis',
        accessor: 'permitted',
        ...restProps,
    };
};
export const inDate = ({ ...restProps } = {}) => {
    return {
        Header: 'In Date',
        headerClassName: 'text-center',
        className: 'text-center text-ellipsis',
        accessor: 'inDate',
        formatTitle: ({ value }) => {
            return value ? moment(value * 1000).format('YYYY-MM-DD HH:mm:ss') : '-';
        },
        Cell: ({ value }) => {
            return value ? moment(value * 1000).format('YYYY-MM-DD HH:mm:ss') : '-';
        },
        ...restProps,
    };
};
export const inScannerName = ({ ...restProps } = {}) => {
    return {
        Header: 'In Scanner Name',
        headerClassName: 'text-center',
        className: 'text-center text-ellipsis',
        accessor: 'inScannerName',
    };
};
// 스캐너 상태 로그
export const scannerName = ({ ...restProps } = {}) => {
    return {
        Header: 'Scanner Name',
        headerClassName: 'text-center',
        accessor: 'scannerName',
        className: 'text-center',
        ...restProps,
    };
};
export const macAddress = ({ ...restProps } = {}) => {
    return {
        Header: 'Mac Address',
        headerClassName: 'text-center',
        accessor: 'macAddr',
        className: 'text-center',
        ...restProps,
    };
};

export const startDate = ({ ...restProps } = {}) => {
    return {
        Header: 'Start Date',
        headerClassName: 'text-center',
        accessor: 'startDate',
        className: 'text-center',
        formatTitle: ({ value }) => {
            return value ? moment(value * 1000).format('YYYY-MM-DD HH:mm:ss') : '-';
        },
        Cell: ({ value }) => {
            return value ? moment(value * 1000).format('YYYY-MM-DD HH:mm:ss') : '-';
        },
        ...restProps,
    };
};
export const endDate = ({ ...restProps } = {}) => {
    return {
        Header: 'End Date',
        headerClassName: 'text-center',
        accessor: 'endDate',
        className: 'text-center',
        formatTitle: ({ value }) => {
            return value ? moment(value * 1000).format('YYYY-MM-DD HH:mm:ss') : '-';
        },
        Cell: ({ value }) => {
            return value ? moment(value * 1000).format('YYYY-MM-DD HH:mm:ss') : '-';
        },
        ...restProps,
    };
};

//sensor log
export const sensorName = ({ ...restProps } = {}) => {
    return {
        Header: 'Sensor Name',
        headerClassName: 'text-center',
        accessor: 'sensorName',
        className: 'text-center text-ellipsis',
        ...restProps,
    };
};

export const sensorId = ({ ...restProps } = {}) => {
    return {
        Header: 'Sensor Id',
        headerClassName: 'text-center',
        accessor: 'sensorId',
        className: 'text-center text-ellipsis',
        ...restProps,
    };
};
export const sensorType = ({ ...restProps } = {}) => {
    return {
        Header: 'Sensor Type',
        headerClassName: 'text-center',
        accessor: 'sensorType',
        className: 'text-center text-ellipsis',
        ...restProps,
    };
};

export const injectionVolume = ({ ...restProps } = {}) => {
    return {
        Header: 'Injection Volume',
        headerClassName: 'text-center',
        className: 'text-center text-ellipsis',
        accessor: 'VTBI',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const totalDose = ({ ...restProps } = {}) => {
    return {
        Header: 'Total Dose',
        headerClassName: 'text-center',
        className: 'text-center text-ellipsis',
        accessor: 'TOTAL_DOSAGE',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const stepCount = ({ ...restProps } = {}) => {
    return {
        Header: 'Step Count',
        headerClassName: 'text-center',
        className: 'text-center text-ellipsis',
        accessor: 'STEP',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const bloodOxygenSaturation = ({ ...restProps } = {}) => {
    return {
        Header: 'Blood Oxygen Saturation',
        headerClassName: 'text-center',
        className: 'text-center text-ellipsis',
        accessor: 'SPO2',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const noise = ({ ...restProps } = {}) => {
    return {
        Header: 'Noise',
        headerClassName: 'text-center',
        className: 'text-center text-ellipsis',
        accessor: 'SOUND_NOISE',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const so2 = ({ ...restProps } = {}) => {
    return {
        Header: 'SO2',
        headerClassName: 'text-center',
        className: 'text-center text-ellipsis',
        accessor: 'SO2',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const sleepIndex = ({ ...restProps } = {}) => {
    return {
        Header: 'Sleep Index',
        headerClassName: 'text-center',
        className: 'text-center text-ellipsis',
        accessor: 'SLEEP',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const earthquakeIntensity = ({ ...restProps } = {}) => {
    return {
        Header: 'Earthquake Intensity',
        headerClassName: 'text-center',
        className: 'text-center text-ellipsis',
        accessor: 'SEISMIC_INTENS',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const relativeHumidity = ({ ...restProps } = {}) => {
    return {
        Header: 'Relative Humidity',
        headerClassName: 'text-center',
        className: 'text-center text-ellipsis',
        accessor: 'R_HUMIDITY',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const respiratoryRate = ({ ...restProps } = {}) => {
    return {
        Header: 'Respiratory Rate',
        headerClassName: 'text-center',
        className: 'text-center text-ellipsis',
        accessor: 'RESPIRATION_RATE',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};

export const infusionRate = ({ ...restProps } = {}) => {
    return {
        Header: 'Infusion Rates',
        headerClassName: 'text-center',
        className: 'text-center text-ellipsis',
        accessor: 'RATE',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const pulseRate = ({ ...restProps } = {}) => {
    return {
        Header: 'Pulse Rate',
        headerClassName: 'text-center',
        className: 'text-center text-ellipsis',
        accessor: 'PULSE_RATE',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const oneTimePcaInjection = ({ ...restProps } = {}) => {
    return {
        Header: 'One Time PCA Injection',
        headerClassName: 'text-center',
        className: 'text-center text-ellipsis',
        accessor: 'PCA_BOLUS',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const numberOfPacketTransmissions = ({ ...restProps } = {}) => {
    return {
        Header: 'Number Of Packet Transmissions',
        headerClassName: 'text-center',
        className: 'text-center text-ellipsis',
        accessor: 'PACKET_COUNTER',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const o2 = ({ ...restProps } = {}) => {
    return {
        Header: 'O2',
        headerClassName: 'text-center',
        className: 'text-center text-ellipsis',
        accessor: 'O2',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const diaperUrine = ({ ...restProps } = {}) => {
    return {
        Header: 'Diaper-Urine',
        headerClassName: 'text-center',
        className: 'text-center text-ellipsis',
        accessor: 'NAPPY_GAS',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const diaperFart = ({ ...restProps } = {}) => {
    return {
        Header: 'Diaper-Fart',
        headerClassName: 'text-center',
        className: 'text-center text-ellipsis',
        accessor: 'NAPPY_SMALL',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const diaperFeces = ({ ...restProps } = {}) => {
    return {
        Header: 'Diaper-Feces',
        headerClassName: 'text-center',
        className: 'text-center text-ellipsis',
        accessor: 'NAPPY_BIG',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const movement = ({ ...restProps } = {}) => {
    return {
        Header: 'Movement',
        headerClassName: 'text-center',
        className: 'text-center text-ellipsis',
        accessor: 'MOVEMENT',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const diastolic = ({ ...restProps } = {}) => {
    return {
        Header: 'Diastolic',
        headerClassName: 'text-center',
        className: 'text-center text-ellipsis',
        accessor: 'H_BLOODPRESSURE',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};

export const geofenceName = ({ ...restProps }) => {
    return {
        Header: 'Geofence Name',
        headerClassName: 'text-center',
        className: 'text-center text-ellipsis',
        accessor: 'fcName',
        ...restProps,
    };
};

export const countOfEntries = ({ ...restProps }) => {
    return {
        Header: 'Count of entries',
        headerClassName: 'text-center',
        className: 'text-center text-ellipsis',
        accessor: 'entryCount',
        ...restProps,
    };
};

export const countOfUnique = ({ ...restProps }) => {
    return {
        Header: 'Count of unique',
        headerClassName: 'text-center',
        className: 'text-center text-ellipsis',
        accessor: 'uniqueCount',
        ...restProps,
    };
};

export const averageStayOfEntry = ({ ...restProps }) => {
    return {
        Header: 'Average length of stay per entry',
        headerClassName: 'text-center',
        className: 'text-center text-ellipsis',
        accessor: 'avgStayEntryTime',
        Cell: ({ value }) => {
            return <span>{`${moment(value * 1000).format('m')}m ${moment(value * 1000).format('ss')}s`}</span>;
        },
        ...restProps,
    };
};

export const averageStayOfUnique = ({ ...restProps }) => {
    return {
        Header: 'Average length of stay per unique',
        headerClassName: 'text-center',
        className: 'text-center text-ellipsis',
        accessor: 'avgStayUniqueTime',
        Cell: ({ value }) => {
            return <span>{`${moment(value * 1000).format('m')}m ${moment(value * 1000).format('ss')}s`}</span>;
        },
        ...restProps,
    };
};

// iot Item Information Log Table
export const dataType = ({ ...restProps }) => {
    return {
        Header: 'Type of Data Revision',
        headerClassName: 'text-ellipsis',
        accessor: 'manipulationType',
        className: 'text-center text-ellipsis',
        ...restProps,
    };
};

export const targetNum = ({ ...restProps }) => {
    return {
        Header: 'IoT Item No.',
        headerClassName: 'text-ellipsis',
        accessor: 'targetNum',
        className: 'text-center text-ellipsis',
        ...restProps,
    };
};

export const iotItemId = ({ ...restProps }) => {
    return {
        Header: 'IoT Item ID',
        headerClassName: 'text-ellipsis',
        accessor: 'targetId',
        className: 'text-center text-ellipsis',
        ...restProps,
    };
};

export const metaData = ({ ...restProps }) => {
    return {
        Header: 'Meta Data',
        headerClassName: 'text-ellipsis',
        accessor: 'metadata',
        className: 'text-overflow',
        ...restProps,
    };
};

export const deviceType = ({ ...restProps }) => {
    return {
        Header: 'Device Type',
        headerClassName: 'text-ellipsis',
        accessor: 'deviceType',
        className: 'text-ellipsis',
        ...restProps,
    };
};
export const deviceNum = ({ ...restProps }) => {
    return {
        Header: 'Device No.',
        headerClassName: 'text-ellipsis',
        accessor: 'deviceNum',
        className: 'text-ellipsis',
        ...restProps,
    };
};
export const deviceId = ({ ...restProps }) => {
    return {
        Header: 'Device ID',
        headerClassName: 'text-ellipsis',
        accessor: 'deviceId',
        className: 'text-ellipsis',
        ...restProps,
    };
};

export const userId = ({ ...restProps }) => {
    return {
        Header: 'User ID',
        headerClassName: 'text-ellipsis',
        accessor: 'userID',
        className: 'text-ellipsis',
        ...restProps,
    };
};

export const userName = ({ ...restProps }) => {
    return {
        Header: 'User Name',
        headerClassName: 'text-ellipsis',
        accessor: 'userName',
        className: 'text-ellipsis',
        ...restProps,
    };
};

export const type = ({ ...restProps }) => {
    return {
        Header: 'Log Type',
        headerClassName: 'text-ellipsis',
        accessor: 'type',
        className: 'text-ellipsis',
        ...restProps,
    };
};
export const description = ({ ...restProps }) => {
    return {
        Header: 'Reason for Login Failure',
        headerClassName: 'text-ellipsis',
        accessor: 'description',
        className: 'text-ellipsis',
        ...restProps,
    };
};
export const ip = ({ ...restProps }) => {
    return {
        Header: 'IP',
        headerClassName: 'text-ellipsis',
        accessor: 'ip',
        className: 'text-ellipsis',
        ...restProps,
    };
};

export const lel = ({ ...restProps } = {}) => {
    return {
        Header: 'LEL',
        headerClassName: 'text-center',
        className: 'text-center text-ellipsis',
        accessor: 'LEL',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const injectedAmount = ({ ...restProps } = {}) => {
    return {
        Header: 'Injected Amount',
        headerClassName: 'text-center',
        className: 'text-center text-ellipsis',
        accessor: 'INFUSED_VOL',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const systolicBloodPressure = ({ ...restProps } = {}) => {
    return {
        Header: 'Systolic Blood Pressure',
        headerClassName: 'text-center',
        className: 'text-center text-ellipsis',
        accessor: 'L_BLOODPRESSURE',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const humidity = ({ ...restProps } = {}) => {
    return {
        Header: 'Humidity',
        headerClassName: 'text-center',
        className: 'text-center text-ellipsis',
        accessor: 'HUMIDITY',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const height = ({ ...restProps } = {}) => {
    return {
        Header: 'Height',
        headerClassName: 'text-center',
        className: 'text-center text-ellipsis',
        accessor: 'HEIGHT',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const heatstroke = ({ ...restProps } = {}) => {
    return {
        Header: 'Heatstroke',
        headerClassName: 'text-center',
        className: 'text-center text-ellipsis',
        accessor: 'HEAT_STROKE',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const heartRate = ({ ...restProps } = {}) => {
    return {
        Header: 'Heart Rate',
        headerClassName: 'text-center',
        className: 'text-center text-ellipsis',
        accessor: 'HEART_RATE',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const weight = ({ ...restProps } = {}) => {
    return {
        Header: 'Weight',
        headerClassName: 'text-center',
        className: 'text-center text-ellipsis',
        accessor: 'WEIGHT',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const h2s = ({ ...restProps } = {}) => {
    return {
        Header: 'H2S',
        headerClassName: 'text-center',
        className: 'text-center text-ellipsis',
        accessor: 'H2S',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};

export const volatileOrganicCompounds = ({ ...restProps } = {}) => {
    return {
        Header: 'Volatile Organic Compounds',
        headerClassName: 'text-center',
        className: 'text-center text-ellipsis',
        accessor: 'ETVOC',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const equivalentCarbonDioxide = ({ ...restProps } = {}) => {
    return {
        Header: 'Equivalent Carbon Dioxide',
        headerClassName: 'text-center',
        className: 'text-center text-ellipsis',
        accessor: 'ECO2',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const dust = ({ ...restProps } = {}) => {
    return {
        Header: 'Dust',
        headerClassName: 'text-center',
        className: 'text-center text-ellipsis',
        accessor: 'DUST',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const dropRaid = ({ ...restProps } = {}) => {
    return {
        Header: 'Drop Raid',
        headerClassName: 'text-center',
        className: 'text-center text-ellipsis',
        accessor: 'DROP_RATE',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const dropWater = ({ ...restProps } = {}) => {
    return {
        Header: 'Drop Water',
        headerClassName: 'text-center',
        className: 'text-center text-ellipsis',
        accessor: 'DROP_COUNT',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const dosage = ({ ...restProps } = {}) => {
    return {
        Header: 'Dosage',
        headerClassName: 'text-center',
        className: 'text-center text-ellipsis',
        accessor: 'DOSAGE',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const distance = ({ ...restProps } = {}) => {
    return {
        Header: 'Distance',
        headerClassName: 'text-ellipsis',
        className: 'text-ellipsis',
        accessor: 'DISTANCE',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const discomfortIndex = ({ ...restProps } = {}) => {
    return {
        Header: 'Discomfort Index',
        headerClassName: 'text-center',
        className: 'text-center text-ellipsis',
        accessor: 'DISCOMFORT_IDX',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const crack = ({ ...restProps } = {}) => {
    return {
        Header: 'Crack',
        headerClassName: 'text-center',
        className: 'text-center text-ellipsis',
        accessor: 'CRACK',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const density = ({ ...restProps } = {}) => {
    return {
        Header: 'Density',
        headerClassName: 'text-center',
        className: 'text-center text-ellipsis',
        accessor: 'CONC',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const co = ({ ...restProps } = {}) => {
    return {
        Header: 'CO',
        headerClassName: 'text-center',
        className: 'text-center text-ellipsis',
        accessor: 'CO',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const capacity = ({ ...restProps } = {}) => {
    return {
        Header: 'Capacity',
        headerClassName: 'text-center',
        className: 'text-center text-ellipsis',
        accessor: 'CAPACITY',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const calories = ({ ...restProps } = {}) => {
    return {
        Header: 'Calories',
        headerClassName: 'text-center',
        className: 'text-center text-ellipsis',
        accessor: 'CALORIES',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const buttonTwoClicks = ({ ...restProps } = {}) => {
    return {
        Header: 'Button Two Clicks',
        headerClassName: 'text-center',
        className: 'text-center text-ellipsis',
        accessor: 'BUTTON2_COUNT',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const buttonOneClicks = ({ ...restProps } = {}) => {
    return {
        Header: 'Button One Clicks',
        headerClassName: 'text-center',
        className: 'text-center text-ellipsis',
        accessor: 'BUTTON1_COUNT',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const battery = ({ ...restProps } = {}) => {
    return {
        Header: 'Battery',
        headerClassName: 'text-center',
        className: 'text-center text-ellipsis',
        accessor: 'BATTERY',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const atmosphericPressure = ({ ...restProps } = {}) => {
    return {
        Header: 'Atmospheric Pressure',
        headerClassName: 'text-center',
        className: 'text-center text-ellipsis',
        accessor: 'BAROM_PRESURE',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const zAxisAngularVelocity = ({ ...restProps } = {}) => {
    return {
        Header: 'Z-Axis Angular Velocity',
        headerClassName: 'text-center',
        className: 'text-center text-ellipsis',
        accessor: 'ACCELER_Z_AXIS',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const yAxisAngularVelocity = ({ ...restProps } = {}) => {
    return {
        Header: 'Y-Axis Angular Velocity',
        headerClassName: 'text-center',
        className: 'text-center text-ellipsis',
        accessor: 'ACCELER_Y_AXIS',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const xAxisAngularVelocity = ({ ...restProps } = {}) => {
    return {
        Header: 'X-Axis Angular Velocity',
        headerClassName: 'text-center',
        className: 'text-center text-ellipsis',
        accessor: 'ACCELER_X_AXIS',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const illuminance = ({ ...restProps } = {}) => {
    return {
        Header: 'Ambient Light',
        headerClassName: 'text-center',
        className: 'text-center text-ellipsis',
        accessor: 'ANBIENT_LIGHT',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const zSideAcceleration = ({ ...restProps } = {}) => {
    return {
        Header: 'Z Side Acceleration',
        headerClassName: 'text-center',
        className: 'text-center text-ellipsis',
        accessor: 'ANGULAR_Z_AXIS',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const YSideAcceleration = ({ ...restProps } = {}) => {
    return {
        Header: 'Y Side Acceleration',
        headerClassName: 'text-center',
        className: 'text-center text-ellipsis',
        accessor: 'ANGULAR_Y_AXIS',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const xSideAcceleration = ({ ...restProps } = {}) => {
    return {
        Header: 'X Side Acceleration',
        headerClassName: 'text-center',
        className: 'text-center text-ellipsis',
        accessor: 'ANGULAR_X_AXIS',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const lastReceivedDate = ({ ...restProps } = {}) => {
    return {
        Header: 'Last Received Date',
        headerClassName: 'text-center',
        className: 'text-center text-ellipsis',
        accessor: '',
        ...restProps,
    };
};
export const temperature = ({ ...restProps } = {}) => {
    return {
        Header: 'Temperature',
        headerClassName: 'text-center',
        className: 'text-center text-ellipsis',
        accessor: 'TEMPERATURE',
        ...restProps,
    };
};

export const outDate = ({ ...restProps } = {}) => {
    return {
        Header: 'Out Date',
        headerClassName: 'text-center',
        className: 'text-center text-ellipsis',
        accessor: 'outDate',
        formatTitle: ({ value }) => {
            return value ? moment(value * 1000).format('YYYY-MM-DD HH:mm:ss') : '-';
        },
        Cell: ({ value }) => {
            return value ? moment(value * 1000).format('YYYY-MM-DD HH:mm:ss') : '-';
        },
        ...restProps,
    };
};
export const outScannerName = ({ ...restProps } = {}) => {
    return {
        Header: 'Out Scanner Name',
        headerClassName: 'text-center',
        className: 'text-center text-ellipsis',
        accessor: 'outScannerName',
    };
};

export const sensorMacAddress = ({ ...restProps }) => {
    return {
        Header: 'Sensor Mac Address',
        headerClassName: 'text-ellipsis',
        accessor: 'sensorId',
        className: 'text-ellipsis',
        ...restProps,
    };
};

export const sensorItems = ({ ...restProps }) => {
    return {
        Header: 'Measurement Item',
        headerClassName: 'text-ellipsis',
        accessor: 'sensorItems',
        className: 'text-overflow',
        ...restProps,
    };
};

export const data = ({ ...restProps }) => {
    return {
        Header: 'Value',
        headerClassName: 'text-ellipsis',
        accessor: 'data',
        ...restProps,
    };
};

export const remarks = ({ ...restProps }) => {
    return {
        Header: 'Remarks',
        headerClassName: 'text-ellipsis',
        accessor: 'remarks',
        className: 'text-ellipsis p-1',
        ...restProps,
    };
};

//자산 점검 기록
export const assetCategory = ({ ...restProps }) => {
    return {
        Header: 'Asset category',
        accessor: 'assetCategory',
        ...restProps,
    };
};
export const assetNumber = ({ ...restProps }) => {
    return {
        Header: 'Asset Number',
        headerClassName: 'text-ellipsis',
        accessor: 'targetNum',
        width: 90,
        className: 'text-ellipsis',
        ...restProps,
    };
};
export const ownershipDepartment = ({ ...restProps }) => {
    return {
        Header: 'Ownership department',
        accessor: 'usingDepartmentName',
        ...restProps,
    };
};
export const assetInspectionDate = ({ ...restProps }) => {
    return {
        Header: 'Asset inspection date',
        accessor: 'lastInspectionDate',
        ...restProps,
    };
};
export const assetInspectionStatus = ({ ...restProps }) => {
    return {
        Header: 'Asset inspection status',
        accessor: 'assetInspectionStatus',
        ...restProps,
    };
};
export const inspectionNote = ({ ...restProps }) => {
    return {
        Header: 'Inspection note',
        accessor: 'remarks',
        ...restProps,
    };
};

//자산대여요청목록
export const assetRentalRequestNum = ({ ...restProps }) => {
    return {
        Header: 'No.',
        accessor: 'reqNum',
        headerClassName: 'text-ellipsis',
        classname: 'text-ellipsis',
        ...restProps,
    };
};
export const assetRentalRequestDepart = ({ ...restProps }) => {
    return {
        Header: 'Requesting department',
        accessor: 'usingDepNm',
        width: 90,
        headerClassName: 'text-ellipsis',
        className: 'text-ellipsis',
        ...restProps,
    };
};
export const assetRentalApplicant = ({ ...restProps }) => {
    return {
        Header: 'Applicant',
        accessor: 'userName',
        headerClassName: 'text-ellipsis',
        className: 'text-ellipsis',
        ...restProps,
    };
};
export const assetRentalDepartment = ({ ...restProps }) => {
    return {
        Header: 'Lending department',
        accessor: 'ownDepNm',
        width: 90,
        headerClassName: 'text-ellipsis',
        className: 'text-ellipsis',
        ...restProps,
    };
};
export const applicationDate = ({ ...restProps }) => {
    return {
        Header: 'Application date',
        accessor: 'regDate',
        headerClassName: 'text-ellipsis',
        classname: 'text-ellipsis',
        Cell: ({ value }) => {
            return <div>{value ? moment.unix(value).format('YYYY-MM-DD') : '-'}</div>;
        },
        ...restProps,
    };
};
export const rentalPeriod = ({ ...restProps }) => {
    return {
        Header: 'Rental period',
        accessor: 'rentalPeriod',
        headerClassName: 'text-ellipsis',
        classname: 'text-ellipsis',
        ...restProps,
    };
};
export const applicationStatus = ({ ...restProps }) => {
    return {
        Header: 'Application status',
        accessor: 'status',
        headerClassName: 'text-ellipsis',
        classname: 'text-ellipsis',
        ...restProps,
    };
};
export const dateChanged = ({ ...restProps }) => {
    return {
        Header: 'Date of application status change',
        accessor: 'modDate',
        headerClassName: 'text-ellipsis',
        classname: 'text-ellipsis',
        Cell: ({ value }) => {
            return <div>{value ? moment.unix(value).format('YYYY-MM-DD') : '-'}</div>;
        },
        ...restProps,
    };
};
export const category = ({ ...restProps }) => {
    return {
        Header: 'Category',
        headerClassName: 'text-ellipsis',
        accessor: 'categoryCode',
        width: 140,
        className: 'text-ellipsis',
        ...restProps,
    };
};
export const quantity = ({ ...restProps }) => {
    return {
        Header: 'Quantity',
        headerClassName: 'text-ellipsis',
        classname: 'text-ellipsis',
        accessor: 'count',
        ...restProps,
    };
};
export const assetName = ({ ...restProps }) => {
    return {
        Header: 'Asset Name',
        headerClassName: 'text-ellipsis',
        accessor: 'targetName',
        width: 90,
        className: 'text-ellipsis',
        ...restProps,
    };
};
export const rentalStatus = ({ ...restProps }) => {
    return {
        Header: 'Rental Status',
        headerClassName: 'text-ellipsis',
        accessor: 'status',
        width: 90,
        className: 'text-ellipsis',
        ...restProps,
    };
};
export const action = ({ ...restProps }) => {
    return {
        Header: 'Action',
        headerClassName: 'text-ellipsis',
        classname: 'text-ellipsis',
        accessor: 'action',
        ...restProps,
    };
};

//자산 점검 예정

export const assetCategorization = ({ ...restProps }) => {
    return {
        Header: 'Asset category',
        accessor: 'categoryName',
        ...restProps,
    };
};

export const assetTargetNumber = ({ ...restProps }) => {
    return {
        Header: 'Asset number',
        accessor: 'targetNum',
        ...restProps,
    };
};

export const assetLocation = ({ ...restProps }) => {
    return {
        Header: 'Asset location',
        accessor: 'floorName',
        ...restProps,
    };
};
export const scheduledInspectionDate = ({ ...restProps }) => {
    return {
        Header: 'Scheduled inspection date',
        accessor: 'properties.scheduled_inspection_date',
        headerClassName: 'text-ellipsis',
        Cell: ({ value }) => {
            let data;
            data = value ? <div>{moment.unix(value).format('YYYY-MM-DD')}</div> : '';
            return data;
        },
        ...restProps,
    };
};
export const ownDepartmentName = ({ ...restProps }) => {
    return {
        Header: 'Owning department name',
        accessor: 'properties.own_dep_nm',
        headerClassName: 'text-ellipsis',
        ...restProps,
    };
};

export const assetStatus = ({ ...restProps }) => {
    return {
        Header: 'Asset status',
        accessor: 'statusId',
        Cell: row => {
            let selectedStatus;
            if (restProps.assetStatusList && restProps.assetStatusList.rows) {
                selectedStatus = restProps.assetStatusList.rows.find(status => status.statusId === row.value);
            }

            return selectedStatus ? (
                <StyledStatus className="pnt-txt txt-border" color={selectedStatus.displayColor}>
                    {selectedStatus.statusName}
                </StyledStatus>
            ) : (
                <span></span>
            );
        },
        ...restProps,
    };
};

// 자산 점검 이력

export const assetCategoryCode = ({ ...restProps }) => {
    return {
        Header: 'Asset category',
        accessor: 'categoryCode',
        ...restProps,
    };
};

export const assetCategoryName = ({ ...restProps }) => {
    return {
        Header: 'Asset category',
        accessor: 'categoryName',
        ...restProps,
    };
};

export const ownershipDepartmentName = ({ ...restProps }) => {
    return {
        Header: 'Ownership department',
        accessor: 'groupName',
        headerClassName: 'text-ellipsis',
        ...restProps,
    };
};

export const inspectionStatus = ({ ...restProps }) => {
    return {
        Header: 'Inspection status',
        headerClassName: 'text-ellipsis',
        classname: 'text-ellipsis',
        accessor: 'inspectStatus',
        ...restProps,
    };
};

export const inspectionDate = ({ ...restProps }) => {
    return {
        Header: 'Inspection date',
        headerClassName: 'text-ellipsis',
        accessor: 'regDate',
        Cell: ({ value }) => {
            let data;
            data = value ? <div>{moment.unix(value).format('YYYY-MM-DD HH:mm:ss')}</div> : '';
            return data;
        },
        ...restProps,
    };
};

export const regUserName = ({ ...restProps }) => {
    return {
        Header: 'Inspector',
        headerClassName: 'text-ellipsis',
        accessor: 'regUserName',
        Cell: ({ row: { original } }) => {
            return `${original.regGroupName ? original.regGroupName : ''} > ${
                original.regUserName ? original.regUserName : ''
            } [${original.regUserId ? original.regUserId : ''}]`;
        },
        className: 'text-ellipsis',
        ...restProps,
    };
};
export const assetDescription = ({ ...restProps }) => {
    return {
        Header: 'Description',
        headerClassName: 'text-ellipsis',
        accessor: 'description',
        className: 'text-ellipsis',
        ...restProps,
    };
};

export const completion = ({ ...restProps } = {}) => {
    return {
        Header: 'Completion',
        headerClassName: 'text-ellipsis',
        accessor: 'completion',
        className: 'text-ellipsis',
        ...restProps,
    };
};

export const incompletion = ({ ...restProps } = {}) => {
    return {
        Header: 'Incompletion',
        headerClassName: 'text-ellipsis',
        accessor: 'incompletion',
        className: 'text-ellipsis',
        ...restProps,
    };
};

export const stress = ({ ...restProps } = {}) => {
    return {
        Header: 'Stress',
        accessor: 'STRESS',
        headerClassName: 'text-ellipsis',
        className: 'text-ellipsis',
        ...restProps,
    };
};

export const heartbeat = ({ ...restProps } = {}) => {
    return {
        Header: 'Heartbeat',
        accessor: 'dataMap.HEARTBEAT',
        headerClassName: 'text-ellipsis',
        className: 'text-ellipsis',
        ...restProps,
    };
};
