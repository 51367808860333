import React, { useCallback, useState } from 'react';

const ColorPicker = ({ name, selected = '#000000', inputStyle, onChange, inputGroupClassName }) => {
    const [color, setColor] = useState(selected);
    const handleChange = useCallback(
        e => {
            const { value } = e.currentTarget;
            setColor(value);
            if (typeof onChange === 'function') {
                onChange(value);
            }
        },
        [onChange],
    );
    return (
        <div className={`pnt-input--group ${inputGroupClassName ? inputGroupClassName : ''}`}>
            <input
                type={'text'}
                className={'pnt-input'}
                style={inputStyle}
                maxLength={9}
                value={color}
                onChange={handleChange}
            />
            {/*<div*/}
            {/*    className={'pnt-input'}*/}
            {/*    style={{*/}
            {/*        display: 'grid',*/}
            {/*        gridTemplateColumns: 'minmax(142px, auto) 38px',*/}
            {/*        gap: 0,*/}
            {/*        paddingRight: 0,*/}
            {/*        alignItems: 'center',*/}
            {/*    }}*/}
            {/*>*/}
            {/*    <div style={{ display: 'flex', alignItems: 'center' }}>{color}</div>*/}
            <div
                className={'d-flex align-item-center'}
                style={{
                    border: '1px solid #999',
                    width: 'fit-content',
                    height: 'fit-content',
                    borderRadius: '8px',
                    position: 'absolute',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    right: '5px',
                    // boxShadow: '0 0 3px #999',
                }}
            >
                <label
                    htmlFor={'displayColor'}
                    style={{
                        position: 'relative',
                        width: '25px',
                        height: '25px',
                        backgroundColor: color,
                        marginBottom: 0,
                        borderRadius: '8px',
                        border: '5px solid #eee',
                    }}
                >
                    <input
                        id={'displayColor'}
                        style={{ width: 0, height: 0, border: 'none', overflow: 'hidden' }}
                        name={name}
                        type={'color'}
                        onChange={handleChange}
                        value={color}
                    />
                </label>
            </div>
            {/*</div>*/}
        </div>
    );
};

export default ColorPicker;
