import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';

import moment from 'moment';

import { useInterval, useTranslation, useAsync } from '@hooks';
import { Button, Card, DatePicker, Label, ConfirmModal, Select as ReactSelect } from '@components';
import { excelExport } from '../../../../../../MainPages/Report/util/util';

import { SelectGroup } from '@components/FilterSearchGroup/Components/Part';
import SearchButtonArea from '@components/FilterSearchGroup/Components/SearchButtonArea';
import SearchGroup from '@components/FilterSearchGroup/Components/SearchGroup';
import FilterSearchGroup from '@components/FilterSearchGroup';

import { SensorStatusDispatchContext, SensorStatusStateContext } from '../../../index';
import { INTERVAL_TIME, setLocationPopup, setReportType, setSensorDetailsList } from '../../../sensorStatusReducer';

import {
    fetchExcelLogApi,
    fetchSensorLogList,
    getMappedSensorMonthlyReportApi,
    getSensorMonthlyReportApi,
} from '@api/log';

import SensorLineGraph from './SensorLineGraph';

// 센서 일별 / 월별 리포트
const SensorReport = () => {
    const t = useTranslation('SensorStatus');
    const commonColumn = useTranslation('CommonColumn');

    const { selectedSensor, sensingTypeOptions, reportType, sensorDetailsList, locationPopup, sensorLineChartData } =
        useContext(SensorStatusStateContext);
    const dispatch = useContext(SensorStatusDispatchContext);

    const cardRef = useRef();

    const timeOptions = useMemo(() => {
        return [
            { value: 300, label: t('5min') },
            { value: 3600, label: t('1hr') },
            { value: 7200, label: t('2hrs') },
            { value: 10800, label: t('3hrs') },
        ];
    }, [t]);

    const [selectedTimeOption, setSelectedTimeOption] = useState(timeOptions[0]);
    const [selectedSensingType, setSelectedSensingType] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [selectedSensorSensingTypeOptions, setSelectedSensorSensingTypeOptions] = useState([]);
    const [excelInfo, setExcelInfo] = useState(null);
    const [modal, setModal] = useState({
        noData: false,
        excelResult: false,
        dateSearchResult: false,
    });

    useEffect(() => {
        if (selectedSensor) {
            setSelectedSensorSensingTypeOptions(
                sensingTypeOptions.filter(sensorItem => {
                    if (selectedSensor.sensorItems.find(sensor => sensor.sensingType === sensorItem.sCD)) {
                        return sensorItem;
                    }
                }),
            );
        }
    }, [selectedSensor]);

    useEffect(() => {
        if (selectedSensorSensingTypeOptions.length) {
            setSelectedSensingType(selectedSensorSensingTypeOptions[0]);
        }
    }, [selectedSensorSensingTypeOptions]);

    useEffect(() => {
        // 엑셀 다운로드를 눌러서 엑셀 정보가 들어왔을 때 다운 받는 api 호출
        if (excelInfo) {
            toggleDelay();
        }
    }, [excelInfo]);

    useEffect(() => {
        if (reportType === 'monthly') {
            setStartDate(moment().subtract(1, 'months').unix());
            setEndDate(moment().unix());
        }
    }, [reportType]);

    const toggleDelay = useInterval({
        callback: param => {
            fetchExcelLog(param);
        },
        delay: INTERVAL_TIME,
        params: excelInfo ? { reqNum: excelInfo.reqNum } : {},
        initial: false,
    });

    const { promise: fetchSensorLog, state: sensorLogInfo } = useAsync({
        promise: fetchSensorLogList,
        fixedParam: { isAll: 'Y' },
        resolve: res => {
            const sensingType = selectedSensingType.sCD;
            const chartData = res.rows
                .map(element => {
                    return {
                        ...element,
                        data: JSON.parse(element.data)[sensingType],
                        regDate: moment.unix(element.regDate).format('HH:mm:ss'),
                        date: element.regDate,
                    };
                })
                .filter(element => element.data);

            if (!res.rows.length || !chartData.length) {
                handleModal('noData');
            } else {
                dispatch(
                    setSensorDetailsList({ data: res, chartData, loading: sensorLogInfo.isLoading, sensorLogInfo }),
                );
            }
        },
    });

    const { promise: getMappedSensorMonthlyReport, state: sensorMonthlyReportLogInfo } = useAsync({
        promise: getMappedSensorMonthlyReportApi,
        resolve: res => {
            const sensingType = selectedSensingType.sCD;
            const chartData = res.rows[0].sensorLogs
                .sort((a, b) => a.regDate - b.regDate)
                .map(element => {
                    return {
                        ...element,
                        data: JSON.parse(element.data)[sensingType],
                        date: moment.unix(element.regDate).format('DD dd'),
                    };
                });

            if (!res.rows[0] || !chartData.length) {
                handleModal('noData');
            } else {
                dispatch(
                    setSensorDetailsList({
                        data: res,
                        chartData,
                        loading: sensorLogInfo.isLoading,
                        sensorLogInfo,
                    }),
                );
            }
        },
    });

    const { promise: getSensorMonthlyReport } = useAsync({
        promise: getSensorMonthlyReportApi,
        fixedParam: {
            excelDownloadRequest: {
                zipFileName: 'Sensor_Status_Monthly_Log',
                fileName: 'Sensor_Status_Monthly_Log',
                columnMetas: [
                    {
                        key: 'targetName',
                        name: commonColumn('Target Name'),
                    },
                    {
                        key: 'deviceId',
                        name: commonColumn('Sensor Mac Address'),
                    },
                    {
                        key: 'data',
                        name: commonColumn('Value'),
                    },
                    {
                        key: 'floorId',
                        name: commonColumn('Floor'),
                    },
                    {
                        key: 'regDate',
                        name: commonColumn('Registered Date'),
                    },
                ],
            },
        },
        resolve: res => {
            const { filePath: url, reqNum } = res;

            if (url) {
                setExcelInfo({
                    url,
                    reqNum,
                });
            }
        },
    });

    const { promise: fetchExcelLog } = useAsync({
        promise: fetchExcelLogApi,
        resolve: res => {
            const { dataSettingStatusType, filePath: url, fileName } = res;
            if (dataSettingStatusType === 'COMPLETED') {
                setExcelInfo(null);
                toggleDelay();
                excelExport(url, fileName);
            } else if (dataSettingStatusType === 'FAILED') {
                setExcelInfo(null);
                toggleDelay();
                handleModal('excelResult');
            }
        },
    });

    const handleSearchClick = () => {
        if (reportType === 'monthly') {
            // 검색 가능 최대 주기는 31일
            if (moment.unix(endDate).diff(moment.unix(startDate), 'days') > 31) {
                handleModal('dateSearchResult');
            } else {
                getMappedSensorMonthlyReport({
                    list: [
                        {
                            deviceId: selectedSensor.sensorId,
                            targetId: selectedSensor.targetId,
                            targetName: selectedSensor.targetName,
                            deviceNum: selectedSensor.sensorNum,
                            sensingType: selectedSensingType.sCD,
                        },
                    ],
                    startDate,
                    endDate,
                });
            }
        } else {
            fetchSensorLog({
                sensingType: selectedSensingType.sCD,
                startDate,
                stepTime: selectedTimeOption.value,
                targetId: selectedSensor.targetId,
            });
        }
    };

    const handleMonthlyReportClick = () => {
        dispatch(setReportType('monthly'));
    };

    const handleSensingTypeSelectChange = selected => {
        setSelectedSensingType(selected);
    };

    // const handlePrintButtonClick = () => {
    //     const { request } = sensorLogInfo;
    //     if (!sensorDetailsList.rows.length || !request) {
    //         handleModal('noData');
    //     } else {
    //         const html = document.querySelector('html');
    //         const printContents = document.getElementById('printArea');
    //         printContents.classList.add('print');
    //         const printDiv = document.createElement('DIV');
    //         printDiv.className = 'print-div';
    //
    //         html.appendChild(printDiv);
    //         printDiv.innerHTML = printContents.innerHTML;
    //         document.body.style.display = 'none';
    //         window.print();
    //         document.body.style.display = 'block';
    //         printDiv.style.display = 'none';
    //     }
    // };

    const handleSeeLocationClick = () => {
        dispatch(setLocationPopup(!locationPopup));
    };

    const handleExportButtonClick = () => {
        const { request } = sensorMonthlyReportLogInfo;

        if (!sensorLineChartData.length || !request) {
            handleModal('noData');
        } else {
            const sensingType = request.list[0].sensingType;
            delete request.list[0].sensingType;

            getSensorMonthlyReport({ targetDeviceLogSearchParam: { sensingType, ...request } });
        }
    };

    const handleModal = name => {
        setModal(prev => {
            return {
                ...prev,
                [name]: !prev[name],
            };
        });
    };

    return (
        <>
            <Card
                innerRef={cardRef}
                className={'sensor-report-card'}
                header={{ title: reportType === 'daily' ? t('Daily Sensor Report') : t('Monthly Sensor Report') }}
            >
                <div className={'h-100'}>
                    <FilterSearchGroup label={t('Search', 'Search')} className={'search-group-print'}>
                        <SearchGroup className={'bg-brand'} label={t('Search', 'Search')}>
                            <SelectGroup>
                                <DatePicker
                                    value={startDate}
                                    handleChange={selected => setStartDate(selected)}
                                    valueType={'s'}
                                    maxDate={moment.now()}
                                    withoutTime
                                />
                            </SelectGroup>
                            {reportType === 'monthly' && (
                                <SelectGroup>
                                    <DatePicker
                                        value={endDate}
                                        handleChange={selected => setEndDate(selected)}
                                        valueType={'s'}
                                        maxDate={moment.now()}
                                        withoutTime
                                    />
                                </SelectGroup>
                            )}
                            <SelectGroup>
                                <ReactSelect
                                    name={'sensingTypeSelect'}
                                    value={selectedSensingType}
                                    options={selectedSensorSensingTypeOptions}
                                    valueKey={'sCD'}
                                    labelKey={'sName'}
                                    onChange={handleSensingTypeSelectChange}
                                    customControlStyles={{
                                        width: '100%',
                                    }}
                                    customMenuStyles={{ width: '100%' }}
                                    customOptionStyles={{ width: '100%' }}
                                />
                            </SelectGroup>
                            {reportType === 'daily' && (
                                <SelectGroup>
                                    <ReactSelect
                                        name={'selectInput'}
                                        value={selectedTimeOption}
                                        options={timeOptions}
                                        onChange={selected => setSelectedTimeOption(selected)}
                                        customControlStyles={{
                                            width: '100%',
                                        }}
                                        customMenuStyles={{ width: '100%' }}
                                        customOptionStyles={{ width: '100%' }}
                                    />
                                </SelectGroup>
                            )}

                            <SearchButtonArea>
                                <Button className="btn-brand btn-icon" iconName="search" onClick={handleSearchClick}>
                                    {t('Search', 'Search')}
                                </Button>
                            </SearchButtonArea>

                            <div className={'widget-header-right'}>
                                {reportType !== 'monthly' ? (
                                    <Button
                                        className="btn-secondary btn-icon"
                                        iconName="menu"
                                        onClick={handleMonthlyReportClick}
                                    >
                                        {t('Monthly Report')}
                                    </Button>
                                ) : (
                                    <Button className={'btn-secondary'} onClick={handleExportButtonClick}>
                                        {t('Export', 'Button')}
                                    </Button>
                                )}
                                {/*<Button className={'btn-secondary btn-icon ms-1'} onClick={handlePrintButtonClick}>*/}
                                {/*    {t('Print')}*/}
                                {/*</Button>*/}
                            </div>
                        </SearchGroup>
                    </FilterSearchGroup>

                    <div className={'sensor-report-container'}>
                        <div className={'sensor-report-item'}>
                            {!sensorDetailsList.rows.length ? (
                                <>
                                    <span className={'icon-search'} />
                                    {t('Click the search button after selecting the date and sensor type')}
                                </>
                            ) : (
                                <SensorLineGraph
                                    dataKey={reportType === 'monthly' ? 'date' : 'regDate'}
                                    selectedSensingType={selectedSensingType.sCD}
                                />
                            )}
                        </div>
                        <div className={'flx-col gap-4 pnt-label-5 sensor-report-item'}>
                            <Label name={t('Target Id', 'CommonColumn')} value={selectedSensor.targetId} />
                            <Label
                                name={t('Target Name', 'CommonColumn')}
                                value={selectedSensor.targetName}
                                labelGroupClassName={'sensor-name'}
                            />
                            <Label name={t('Sensor Mac Address', 'CommonColumn')} value={selectedSensor.targetId} />
                            <Label name={t('Sensor Name', 'CommonColumn')} value={selectedSensor.sensorName} />
                            {!!selectedSensor.lat && !!selectedSensor.lng && (
                                <Label
                                    name={t('Location', 'CommonColumn')}
                                    value={
                                        <Button onClick={handleSeeLocationClick} className="btn-brand">
                                            {t('See Location', 'RealTimeSensorMonitoring')}
                                        </Button>
                                    }
                                />
                            )}
                        </div>
                    </div>
                </div>
            </Card>

            <ConfirmModal
                initModal={modal.noData}
                toggleModal={() => handleModal('noData')}
                header={{ title: t('Alert') }}
                confirmText={<span>{t('There is no data')}</span>}
                removeCancel={true}
            />
            <ConfirmModal
                initModal={modal.excelResult}
                toggleModal={() => handleModal('excelResult')}
                header={{ title: t('Result', 'ConfirmModal') }}
                confirmText={<span>{t('Failed to download the excel.', 'ConfirmModal')}</span>}
                removeCancel={true}
            />
            <ConfirmModal
                initModal={modal.dateSearchResult}
                toggleModal={() => handleModal('dateSearchResult')}
                header={{ title: t('Result', 'ConfirmModal') }}
                confirmText={<span>{t('The maximum searchable period is 31 days.', 'ConfirmModal')}</span>}
                removeCancel={true}
            />
        </>
    );
};

export default SensorReport;
