import React, { useState } from 'react';

import { fetchExcelLogList } from '@api/log';

import { Page, Card, Table } from '@components';
import { useTranslation, useColumns, useAsync } from '@hooks';

import Search from './Components/Search';

import * as column from '../../util/grid/column';

const LocationLogFileList = () => {
    const t = useTranslation('Location Log List');
    const columnT = useTranslation('CommonColumn');

    const [excelLogData, setExcelLogData] = useState({
        totalCount: 0,
        totalPage: 1,
        rows: [],
    });

    const columns = useColumns(
        [
            column.reqNum(),
            column.fileName(),
            column.dataStatus({ t }),
            column.downloadLog({ t }),
            column.registeredDate({ t: columnT }),
        ],
        t,
    );

    const { promise: getExcelLogList, state: excelLogInfo } = useAsync({
        promise: fetchExcelLogList,
        keepState: true,
        fixedParam: { pageSize: 15, exportLogType: 'LOCATION' },
        resolve: res => {
            setExcelLogData(res);
        },
    });

    const handlePageChange = pageIndex => {
        if (excelLogInfo.request && (excelLogInfo.request.startDate || excelLogInfo.request.endDate)) {
            getExcelLogList({ ...excelLogInfo.request, page: pageIndex });
        } else {
            getExcelLogList({ page: pageIndex });
        }
    };

    return (
        <Page menuPath={[t('Report', 'Menu'), t('Saved Location Log List')]} className={'h-100'}>
            <Search getExcelLogList={getExcelLogList} />
            <Card header={{ title: t('Saved Location Log List') }}>
                <div style={{ height: '35rem' }}>
                    <Table
                        data={{
                            ...excelLogData,
                            pageSize: 15,
                        }}
                        columns={columns}
                        onPageChange={handlePageChange}
                        loading={excelLogInfo.isLoading}
                    />
                </div>
            </Card>
        </Page>
    );
};

export default LocationLogFileList;
