import React, { useContext } from 'react';

import { useAsync, useTranslation } from '../../../../util/hooks';
import { Page } from '../../../Common';

import { getManualListAPI } from '../../../../api/military';

import Search from './Components/Search';
import ManualList from './Components/ManualList';

import { setManualList, setPaging } from '../technicalManualReducer';
import { TechnicalManualDispatchContext, TechnicalManualStateContext } from '../index';

import { ManualListRow, TableProps } from '../type';

const TechnicalManualSearch = () => {
    const t = useTranslation('Technical Manual');

    const dispatch = useContext(TechnicalManualDispatchContext);
    const {
        paging: { page, pageSize },
        selectedCategory,
        selectedSearchOption,
        searchInput,
    } = useContext(TechnicalManualStateContext);

    const { promise: getManualList, state: getManualListState } = useAsync({
        promise: getManualListAPI,
        fixedParam: { page, pageSize },
        param: {
            category: selectedCategory ? selectedCategory.value : 'All',
            opt: selectedSearchOption ? selectedSearchOption.value : 'All',
            keyword: searchInput,
        },
        immediate: true,
        resolve: (res: TableProps<ManualListRow>) => {
            const { page, rows, totalCount, totalPage } = res;
            if (dispatch) {
                dispatch(setManualList(rows));
                dispatch(setPaging({ page, totalCount, totalPage }));
            }
        },
        reject: (err: ErrorEvent) => {
            console.error(err);
        },
    });

    return (
        <Page menuPath={[t('Technical Manual', 'Menu'), t('Technical Manual Search', 'Menu')]}>
            <Search getManualList={getManualList} />
            <ManualList getManualList={getManualList} getManualListState={getManualListState} />
        </Page>
    );
};

export default TechnicalManualSearch;
